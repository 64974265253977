import {
  EmailSignInResponse,
  FacebookSignInProps,
  ResponseWithData,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';

import axios from 'axios';
import { FB_SIGN_IN } from '../apis';

async function facebookSignIn(
  props: FacebookSignInProps,
): Promise<ResponseWithData<EmailSignInResponse>> {
  return axios.post(FB_SIGN_IN, props).then((res) => res.data);
}

export function usePostFacebookSignIn(
  options?: UseApi<ResponseWithData<EmailSignInResponse>, FacebookSignInProps>,
) {
  return useMutation({
    mutationKey: ['facebookSignIn'],
    mutationFn: (props: FacebookSignInProps) => facebookSignIn(props),
    onSuccess(data, variables) {
      if (data?.data?.accessToken) {
        localStorage.setItem('token', data.data.accessToken);
      }
      if (data?.data?.projectId) {
        sessionStorage.setItem('project-id', data.data.projectId);
      }
      if (options?.onSuccess) options.onSuccess(data, variables);
    },
    onError: options?.onError,
  });
}

export default usePostFacebookSignIn;
