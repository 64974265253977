import { EllipsisMenu, MenuItem, Typography } from '@frontend/components/ui';
import { EditorLabel } from '@frontend/editor/ui';
import {
  EditorMenuItemType,
  FlowType,
  PersistentMenuPublishErrorsItemType,
} from '@frontend/sorghum/interface';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { get, isEmpty, isUndefined } from 'lodash';
import { FC, MutableRefObject, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersistentMenuItemFlowStatus } from '../persistent-menu-item-flow-status/persistent-menu-item-flow-status';

interface PersistentMenuItemHeaderProps {
  handleMoveUp?: () => void;
  handleMoveDown?: () => void;
  index: number;
  textFieldRef: MutableRefObject<null>;
  isEditable: boolean;
  id: string;
  titles: string[];
  menuItemData: EditorMenuItemType;
  publishErrors: PersistentMenuPublishErrorsItemType[];
  openDeleteModal: (index: number) => void;
  setFieldValue: (
    id: string | undefined,
    fieldName: string,
    value: string | null | number,
  ) => void;
}

const TitleStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginLeft: '6px',
  width: '322px',
}));

const PersistentMenuItemHeaderStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const RightTitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const LinkContainerStyled = styled(Box)(({ theme }) => ({
  fontSize: '12px',
  cursor: 'pointer',
  a: {
    color: theme.palette['info'].main,
  },
}));

const PersistentMenuItemHeader: FC<PersistentMenuItemHeaderProps> = ({
  id,
  index,
  titles,
  handleMoveUp,
  handleMoveDown,
  textFieldRef,
  isEditable,
  menuItemData,
  publishErrors,
  openDeleteModal,
  setFieldValue,
}) => {
  const [t] = useTranslation();
  const [titleError, setTitleError] = useState('');
  const [isFocusTextField, setIsFocusTextField] = useState(false);

  const checkDuplicate = useCallback(
    (value: string | undefined) => {
      if (!isUndefined(value) && titles.findIndex((t) => t === value) !== -1) {
        setTitleError(t('basicSetting.persistentMenu.titleDuplicate'));
      } else {
        setTitleError('');
      }
    },
    [t, titles],
  );

  const flowData: FlowType = get(menuItemData, 'flow', {
    id: '',
    name: '',
    publishStatus: 1,
  });

  const url = get(menuItemData, 'url', '');

  return (
    <PersistentMenuItemHeaderStyled>
      <TitleStyled>
        <EditorLabel
          inputRef={textFieldRef}
          disabled={!isEditable}
          size="subtitle2"
          onBlur={(newValue: string) => {
            checkDuplicate(newValue);
            setFieldValue(id, 'title', newValue);
            setIsFocusTextField(false);
          }}
          defaultValue={menuItemData.title}
          onFocusLabel={(newValue: boolean) => setIsFocusTextField(newValue)}
          isShowTooltips
          maxLength={20}
          placeholder={t('basicSetting.persistentMenu.titlePlaceholder')}
          error={!!titleError || isEmpty(menuItemData.title)}
          helperText={!isFocusTextField ? titleError : ''}
        />
      </TitleStyled>
      <RightTitleContainerStyled>
        <LinkContainerStyled sx={{ mr: 2, zIndex: 20 }}>
          {menuItemData.type.toString() === '1' && (
            <PersistentMenuItemFlowStatus flow={flowData} />
          )}
          {menuItemData.type.toString() === '2' && (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                window.open(url, '_blank');
              }}
            >
              {t('basicSetting.persistentMenu.item.link')}
            </Link>
          )}
        </LinkContainerStyled>
        <Box
          sx={{ zIndex: 20, height: '24px' }}
          onClick={(e) => e.stopPropagation()}
        >
          <EllipsisMenu>
            <MenuItem
              onClick={handleMoveUp}
              disabled={!handleMoveUp}
              content={<Typography>{t('common.moveUp')}</Typography>}
            />
            <MenuItem
              onClick={handleMoveDown}
              disabled={!handleMoveDown}
              content={<Typography>{t('common.moveDown')}</Typography>}
            />
            <MenuItem
              onClick={() => openDeleteModal(index)}
              content={
                <Typography color="error">{t('common.delete')}</Typography>
              }
            />
          </EllipsisMenu>
        </Box>
      </RightTitleContainerStyled>
    </PersistentMenuItemHeaderStyled>
  );
};

export default PersistentMenuItemHeader;
