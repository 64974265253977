import originalDayjs from 'dayjs';
import 'dayjs/locale/zh-tw';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

declare module 'dayjs' {
  export function utcOffset(): number;
  interface Dayjs {
    locale(locale: Partial<ILocale>): Dayjs;
  }
  interface ILocale {
    meridiem?: (hour: number, minute: number, isLowercase: boolean) => string;
  }
}

// 新增時間計算插件
originalDayjs.extend(relativeTime);
// 新增時區插件
originalDayjs.extend(timezone);
originalDayjs.extend(utc);
// 自定義中文格式，顯示 AM/PM
originalDayjs.locale({
  ...originalDayjs.Ls['zh-tw'],
  meridiem: (hour: number, minute: number, isLowercase: boolean): string => {
    return hour < 12 ? (isLowercase ? 'am' : 'AM') : isLowercase ? 'pm' : 'PM';
  },
});

export { originalDayjs as dayjs };
