import { REDIRECT } from '../apis';
import axios from 'axios';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';

async function postRedirect(
  endPoint: string,
  params: object,
): Promise<Response> {
  // redirect 的 API 在不同 domain
  axios.defaults.baseURL = endPoint;

  return axios
    .post(REDIRECT, { data: params })
    .then((res) => res.data)
    .catch((err) => ({ code: 400, msg: err }));
}
export function usePostRedirect(endPoint: string) {
  return useMutation({
    mutationKey: ['post-redirect'],
    mutationFn: (params: object) => postRedirect(endPoint, params),
  });
}
