import { globalTheme } from '@frontend/components/external-providers';
import CloseIcon from '@mui/icons-material/Close';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';

export type ChipProps = MuiChipProps;

const ChipStyled = styled(MuiChip)<{
  $color: string;
  $disabled?: boolean;
}>(({ theme, $color, $disabled }) => ({
  backgroundColor: $disabled
    ? theme.palette['background']['black'][10]
    : `${theme.palette['primary']['main']}0D`,
  borderRadius: '4px',
  color: $disabled ? theme.palette['grey'][900] : theme.palette[$color]['main'],
  fontSize: '16px',
  border: `1px solid ${
    $disabled ? theme.palette['background']['black'][10] : 'transparent'
  }`,
  '&:hover': {
    border: `1px solid ${theme.palette[$color]['main']}40`,
  },
  WebkitTextFillColor: 'initial !important',
}));

export function Chip(props: ChipProps) {
  const color = props.color || 'primary';
  return (
    <ChipStyled
      {...props}
      $color={color}
      $disabled={props.disabled}
      deleteIcon={
        <CloseIcon
          style={{
            color: props.disabled
              ? get(globalTheme, `palette.grey.600`, '')
              : get(globalTheme, `palette.${color}.main`, 'primary'),
            fontSize: '18',
          }}
        />
      }
    />
  );
}

export default Chip;
