import { usePostGoogleAccount } from '@frontend/editor/data-access';
import { GoogleAccount, ResponseWithData } from '@frontend/editor/interface';
import { FullScreenLoading } from '@frontend/sorghum/ui';
import { useEffect, useState } from 'react';

export function SorghumFeaturesGoogleAuth() {
  const projectID = localStorage.getItem('project-id');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { mutate: addGoogleAccount } = usePostGoogleAccount(
    projectID as string,
  );
  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const error = searchParams.get('error');

  useEffect(() => {
    if (code && projectID && !isLoaded) {
      setIsLoaded(true);
      addGoogleAccount(code, {
        onSuccess: (res: ResponseWithData<GoogleAccount>) => {
          localStorage.setItem('refresh-google-code', 'true');
          localStorage.setItem('google-id', res.data.id);
          window.close();
        },
        onError: () => {
          localStorage.setItem('refresh-google-code', 'true');
          window.close();
        },
      });
    }
  }, [addGoogleAccount, code, isLoaded, projectID]);

  useEffect(() => {
    if (error) {
      window.close();
    }
  }, [error]);

  return <FullScreenLoading />;
}

export default SorghumFeaturesGoogleAuth;
