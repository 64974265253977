import {
  AutocompleteV2,
  Dialog,
  OptionTypeV2,
  Typography,
} from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetEditorFlowStatus,
  useGetEditorOptionFlow,
  usePostEditorFlow,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ModalTypesEnum,
  PostEditorFlowResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import LinkIcon from '@mui/icons-material/OpenInNew';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface FlowSelectProps {
  value: string | null;
  socialType: number;
  disabled?: boolean;
  error?: string;
  onlineFlow?: string;
  linkIconDisplay?: boolean;
  onFocus?: () => void;
  onChange: (newValue: OptionTypeV2 | null) => void;
  onUpdateFlowID: (data: { flowId: string; name: string }) => void;
  placeholder?: string;
  label?: string;
}

interface FlowProps {
  flowID?: string;
  flowName?: string | undefined;
}

const FlowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '& > div': {
    width: '100%',
  },
  '& > svg': {
    position: 'relative',
    marginLeft: '11px',
    right: '0',
    cursor: 'pointer',
  },
}));

const ErrorMessage: FC<FlowProps> = ({ flowID, flowName }: FlowProps) => {
  const { navigateToProjectPage } = usePath();

  const onClick = useCallback(() => {
    if (flowID) {
      navigateToProjectPage(`${PAGE_FLOW}/${flowID}`);
    }
  }, [flowID, navigateToProjectPage]);
  if (!flowID) {
    return null;
  }

  return (
    <Box>
      <Typography variant="body2" color="error.main">
        <Trans i18nKey="validate.flowUnpublishedPrefix" />
        <Link sx={{ cursor: 'pointer' }} onClick={onClick}>
          {flowName}
        </Link>
        <Trans i18nKey="validate.flowUnpublishedSuffix" />
      </Typography>
    </Box>
  );
};

/* FlowSelect 邏輯:
 * 下拉選單資料從 useGetEditorOptionFlow 抓取 
 * 如果已經有 onlineFlow，onChange 或按 X 清除 flow 時會跳出確認視窗
 * 

*/
export const FlowSelect: FC<FlowSelectProps> = ({
  value,
  socialType,
  disabled,
  error,
  onlineFlow, // 目前選擇並且已經 publish 的 flow
  linkIconDisplay = true,
  placeholder,
  label,
  onFocus,
  onChange,
  onUpdateFlowID,
}: FlowSelectProps) => {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: flows, refetch: refetchFlows } = useGetEditorOptionFlow({
    projectID: projectID as string,
    socialType,
  });
  const { mutate: createFlow } = usePostEditorFlow(projectID as string, 1, {
    onSuccess: (data: ResponseWithData<PostEditorFlowResponse>) => {
      if (data.code === 20000) {
        uiState.handleSetID(data.data.flowId);
        if (data.data) {
          onUpdateFlowID(data.data);
          navigateToProjectPage(`${PAGE_FLOW}/${data.data.flowId}`);
          uiState.openModal(ModalTypesEnum.RENAME_FLOW);
        }
      }
    },
  });

  const { data: flowStatus } = useGetEditorFlowStatus({ flowID: value ?? '' });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [tempData, setTempData] = useState<OptionTypeV2 | null>({
    label: '',
    value: '',
  });
  const [selectValue, setSelectValue] = useState<string | null>(value ?? null);

  const handleCreateFlow = useCallback(() => {
    if (onlineFlow) {
      setIsOpenCreateModal(true);
    } else {
      const newName = t('flows.leftSidebar.flowDefaultName');
      createFlow({ name: newName });
    }
  }, [onlineFlow, t, createFlow]);

  const newOptionFlows = useMemo(() => {
    const newOptionFlows: OptionTypeV2[] = [];
    newOptionFlows.push({
      label: `+ ${t('flows.leftSidebar.createNewFlow')}`,
      value: 'create new flow',
      divider: true,
      exclude: true,
      onClick: handleCreateFlow,
    });

    if (flows && flows.length > 0) {
      flows[flows.length - 1].flows.forEach((i) => {
        newOptionFlows.push({
          label: i.name as string,
          value: i.id,
        });
      });
      flows.forEach((i) => {
        if (i.groupName && i.flows) {
          i.flows.forEach((j) => {
            newOptionFlows.push({
              group: i.groupName,
              label: j.name as string,
              value: j.id,
            });
          });
        }
      }, []);
    }
    return newOptionFlows;
  }, [flows, handleCreateFlow, t]);

  const handleChange = useCallback(
    (_: object, newValue: OptionTypeV2 | null) => {
      if (onlineFlow && newValue?.value !== onlineFlow) {
        setIsOpenModal(true);
        const option = newOptionFlows.find((x) => x.value === selectValue);
        setTempData(option ?? null);
      } else {
        onChange(newValue);
      }
      setSelectValue(newValue?.value ?? null);
    },
    [newOptionFlows, onChange, onlineFlow, selectValue],
  );

  // refetch flows to make sure getting the latest flow list
  useEffect(() => {
    refetchFlows();
  }, [refetchFlows]);

  return (
    <FlowWrapper>
      <AutocompleteV2
        value={selectValue}
        disabled={disabled}
        onFocus={onFocus}
        onChange={handleChange}
        noOptionsText={t('select.flow.noData')}
        placeholder={placeholder ? placeholder : t('select.flow.placeholder')}
        options={newOptionFlows}
        errorMessage={error}
        label={label}
        {...(flowStatus === 1 && {
          customMessage: (
            <ErrorMessage
              flowID={value ?? ''}
              flowName={newOptionFlows.find((x) => x.value === value)?.label}
            />
          ),
        })}
      />
      {linkIconDisplay && flowStatus && flowStatus >= 2 && (
        <LinkIcon
          sx={{ color: 'info.main' }}
          onClick={() => navigateToProjectPage(`${PAGE_FLOW}/${value}`)}
        />
      )}
      {/* dialog for flow change */}
      <Dialog
        size="xs"
        color="error"
        title={t('basicSetting.changeFlowModal.title')}
        handleClose={() => {
          setIsOpenModal(false);
          setSelectValue(tempData?.value ?? null);
        }}
        handleConfirm={() => {
          setIsOpenModal(false);
          const data = newOptionFlows.find((i) => i.value === selectValue);
          onChange(data ?? null);
        }}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        open={isOpenModal}
        isNegative
      >
        <Box>
          <Typography color="grey.800" variant="body1">
            {t('basicSetting.changeFlowModal.content', {
              flowName: newOptionFlows.find((i) => i.value === value)?.label,
            })}
          </Typography>
        </Box>
      </Dialog>
      {/* dialog for create new flow */}
      <Dialog
        size="xs"
        color="error"
        title={t('basicSetting.changeFlowModal.title')}
        handleClose={() => setIsOpenCreateModal(false)}
        handleConfirm={() => {
          setIsOpenCreateModal(false);
          createFlow({
            name: t('flows.leftSidebar.flowDefaultName'),
          });
        }}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        open={isOpenCreateModal}
        isNegative
      >
        <Box>
          <Typography color="grey.800" variant="body1">
            {t('basicSetting.changeFlowModal.content', {
              flowName: newOptionFlows.find((i) => i.value === value)?.label,
            })}
          </Typography>
        </Box>
      </Dialog>
    </FlowWrapper>
  );
};

export default FlowSelect;
