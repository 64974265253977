import {
  GetAccountResetVerifyResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_RESET_VERIFY } from '../apis';

interface UseAccountSignupVerifyProps {
  token: string;
}

async function getAccountResetVerify({
  token,
}: UseAccountSignupVerifyProps): Promise<
  ResponseWithData<GetAccountResetVerifyResponse>
> {
  return axios
    .get(`${ACCOUNT_RESET_VERIFY}?token=${token}`)
    .then((res) => res.data)
    .catch((err) => {
      const res: ResponseWithData<GetAccountResetVerifyResponse> = {
        code: 400,
        msg: err.data.msg,
        data: err.data.data,
      };
      return res;
    });
}

export function useAccountResetVerify(props: UseAccountSignupVerifyProps) {
  return useMutation({
    mutationKey: ['accountResetVerify'],
    mutationFn: () => getAccountResetVerify(props),
  });
}

export default useAccountResetVerify;
