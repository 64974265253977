import { queryClient } from '@frontend/sorghum/external-providers';
import {
  ProjectConnectProps,
  Response,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PROJECT_CONNECT } from '../apis';

async function getProjectConnect(
  props: ProjectConnectProps,
): Promise<Response> {
  return axios.post(PROJECT_CONNECT, props).then((res) => res.data);
}

export function useProjectConnect(
  options?: UseApi<Response, ProjectConnectProps>,
) {
  const [t] = useTranslation();
  return useMutation({
    mutationKey: ['project-connect'],
    mutationFn: (props: ProjectConnectProps) => getProjectConnect(props),
    onSuccess: (data, variables) => {
      if (data.code === 20000) {
        queryClient.invalidateQueries(['facebook-pages']);
        queryClient.invalidateQueries(['project-linked']);
        queryClient.invalidateQueries(['get-project-list']);
        queryClient.invalidateQueries(['get-account-info']);
        queryClient.invalidateQueries([
          'get-project',
          { projectID: variables.projectId },
        ]);

        if (options?.onSuccess) {
          options.onSuccess(data, variables);
        }
      } else {
        throw Error();
      }
    },
    onError: (data) => {
      toast.warning(t('pages.modal.error'));
      if (options?.onError) {
        options.onError(data);
      }
    },
  });
}

export default useProjectConnect;
