import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';

import {
  Button,
  PhonePreviewHeader,
  Typography,
} from '@frontend/components/ui';
import { Trans } from 'react-i18next';
import Gosky from '../../images/gosky.svg';

import { WelcomeMessageForm } from '@frontend/sorghum/interface';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useForm } from 'react-hook-form';

interface WelcomeMessagePreviewProps {
  step: 'flow' | 'greeting';
  setStepToFlow: () => void;
}

const PreviewContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: '200px',
  height: '495px',
  borderRadius: ' 0 0 20px 20px',
  backgroundColor: theme.palette['grey'][50],
}));

const MessageWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'column',
}));

const MessageStyled = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 12px',
  margin: '4px',
  maxWidth: '145px',
  width: 'fit-content',
}));

const RightSideMessageStyled = styled(MessageStyled)(({ theme }) => ({
  backgroundColor: theme.palette['primary'].main,
  color: theme.palette['grey']['white'],
  alignSelf: 'end',
}));

const LeftSideMessageStyled = styled(MessageStyled)(({ theme }) => ({
  backgroundColor: theme.palette['grey'][200],
  color: theme.palette['grey']['black'],
  alignItems: 'center',
}));

const BottomWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  height: '90px',
  borderTop: `2px solid ${theme.palette['grey'][50]}`,
  width: '100%',
  bottom: 0,
}));

export const WelcomeMessagePreview: FC<WelcomeMessagePreviewProps> = ({
  step,
  setStepToFlow,
}) => {
  const { getValues } = useForm();
  const formData = getValues() as WelcomeMessageForm;
  const isSetFlow = step === 'flow';

  return (
    <PreviewContainerStyled>
      <PhonePreviewHeader />
      <MessageWrapperStyled>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar
            src={Gosky}
            sx={{
              width: '30px',
              height: '30px',
              marginBottom: '3px',
              marginTop: '30px',
            }}
          />
          <Typography fontSize="small" variant="subtitle2" color="grey.900">
            <Trans i18nKey="basicSetting.welcomeMessage.preview.userName" />
          </Typography>
          <Typography
            fontSize="small"
            variant="body2"
            color="grey.500"
            sx={{
              fontSize: '10px',
            }}
          >
            <Trans i18nKey="basicSetting.welcomeMessage.preview.type" />
          </Typography>
          <br />
          {isSetFlow && (
            <Typography
              fontSize="small"
              variant="body2"
              color="grey.500"
              sx={{
                fontSize: '10px',
                marginBottom: '8px',
              }}
            >
              <Trans i18nKey="basicSetting.welcomeMessage.preview.time" />
            </Typography>
          )}
        </Box>
        {!isSetFlow ? (
          <Box pl={2} pr={2}>
            <Box display="flex" alignItems="center">
              <ChatBubbleOutlineIcon color="primary" fontSize="medium" />
              <Typography
                fontSize="small"
                variant="caption"
                color="grey.900"
                ml={1}
              >
                <Trans i18nKey="basicSetting.welcomeMessage.preview.reply" />
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <InfoOutlinedIcon color="primary" fontSize="medium" />
              <Box>
                <Typography
                  fontSize="small"
                  variant="caption"
                  color="grey.900"
                  ml={1}
                  mt={1}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'inline-block',
                    width: '160px',
                  }}
                >
                  {formData.message && formData.message}
                  {!formData.message && (
                    <Trans i18nKey="basicSetting.welcomeMessage.preview.default" />
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <RightSideMessageStyled>
              <Typography fontSize="small" variant="body2">
                <Trans i18nKey="basicSetting.welcomeMessage.preview.start" />
              </Typography>
            </RightSideMessageStyled>

            <Box display="inline-flex">
              <Box justifyContent="end" display="flex" ml={2} mb={1}>
                <Avatar
                  src={Gosky}
                  sx={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              </Box>
              <LeftSideMessageStyled>
                <Box display="inline-flex">
                  <Typography
                    lineHeight="14px"
                    variant="body2"
                    ml={1}
                    sx={{
                      letterSpacing: '-0.41px',
                      fontSize: '12px',
                    }}
                  >
                    <Trans i18nKey="basicSetting.welcomeMessage.preview.welcome" />
                  </Typography>
                </Box>

                <Box
                  mt={1}
                  borderRadius="10px"
                  padding="8px 12px"
                  whiteSpace="nowrap"
                  width="fit-content"
                  sx={{
                    backgroundColor: 'grey.white',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      letterSpacing: '-0.41px',
                    }}
                    color="grey.black"
                    fontSize="small"
                    variant="body2"
                  >
                    <Trans i18nKey="basicSetting.welcomeMessage.preview.button" />
                  </Typography>
                </Box>
              </LeftSideMessageStyled>
            </Box>
          </>
        )}
      </MessageWrapperStyled>
      {!isSetFlow && (
        <BottomWrapper>
          <Typography
            align="center"
            color="grey.500"
            sx={{
              fontSize: '10px',
              marginBottom: 1,
            }}
          >
            <Trans i18nKey="basicSetting.welcomeMessage.preview.buttonInfo" />
          </Typography>

          <Button
            onClick={setStepToFlow}
            fullWidth
            variant="contained"
            color="primary"
          >
            <Trans i18nKey="basicSetting.welcomeMessage.preview.getStarted" />
          </Button>
        </BottomWrapper>
      )}
    </PreviewContainerStyled>
  );
};

export default WelcomeMessagePreview;
