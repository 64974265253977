import { Loading } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import { LoadingModalTypeEnum } from '@frontend/sorghum/interface';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC, useContext } from 'react';
import { Box } from '../box/box';

// mask 透明遮罩 / block 非透明遮罩
const ContainerStyled = styled(Box)<{ $type: LoadingModalTypeEnum }>(
  ({ theme, $type }) => ({
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor:
      $type === LoadingModalTypeEnum.MASK
        ? 'rgba(0, 0, 0, 0.54)'
        : get(theme, 'palette.grey.400'),
    zIndex: '1000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export const LoadingModal: FC = () => {
  const UIState = useContext(UICtx);

  if (!UIState.isLoading) {
    return null;
  }

  return (
    <ContainerStyled $type={UIState.loadingModalType}>
      <Loading />
    </ContainerStyled>
  );
};

export default LoadingModal;
