import { Textarea, Typography } from '@frontend/components/ui';
import {
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  CollectUserAnswerType,
  DataFlowChartCell,
  OptionCellType,
} from '@frontend/editor/interface';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip } from '@mui/material';
import { get } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OptionButton } from '../option-button/option-button';
import {
  AttributeContainerStyled,
  ButtonContainerStyled,
  ContainerStyled,
  DataContainerStyled,
  FreeUserInputButtonStyled,
  FreeUserInputContainerStyled,
  HintTextContainerStyled,
  OptionButtonContainerStyled,
  OptionContainerStyled,
  TitleContainerStyled,
  TitleWrapperStyled,
} from './styles';

const OptionButtonWrapper = ({
  id,
  numberData,
}: {
  id: string;
  numberData: DataFlowChartCell;
}) => {
  const state = useContext(EditorCtx);

  const itemData = state.getElement(id) as OptionCellType;

  if (!itemData) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: '4px' }}>
      <OptionButton
        id={itemData.outputID}
        value={itemData?.data?.title || ''}
        cvrData={numberData?.uniqCvr || 0}
        readonly
      />
    </Box>
  );
};

export const EditorViewCollectUserAnswer = ({
  element,
}: {
  element: CollectUserAnswerType;
}) => {
  const [t] = useTranslation();
  const { id: flowID } = useParams();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });

  const children = get(element, 'children', []);

  return (
    <Box sx={{ marginTop: '10px' }}>
      <ContainerStyled>
        <TitleWrapperStyled>
          <Typography variant="subtitle2" color="grey.900">
            {t('canvas.collectUserAnswer.title')}
          </Typography>
        </TitleWrapperStyled>
        <Textarea
          defaultValue={element?.data?.text || ''}
          limit={640}
          readonly
          placeholder={t('canvas.collectUserAnswer.questionPlaceholder')}
          labelItem={[]}
        />
        <OptionContainerStyled>
          <Box>
            <TitleContainerStyled>
              <Typography variant="body2" color="grey.700">
                {t('canvas.collectUserAnswer.answerSetting')}
              </Typography>
            </TitleContainerStyled>
            <AttributeContainerStyled>
              <Typography variant="caption" color="bluegrey.400">
                {t('canvas.collectUserAnswer.saveAttribute')}{' '}
              </Typography>
              <Typography variant="caption" color="primary.main">
                {element.data?.attributeName}
              </Typography>
            </AttributeContainerStyled>
          </Box>
          <OptionButtonContainerStyled>
            {children.map((item) => {
              return (
                <OptionButtonWrapper
                  key={item}
                  id={item}
                  numberData={
                    (viewData?.cells[item] as DataFlowChartCell) ||
                    ({} as DataFlowChartCell)
                  }
                />
              );
            })}
          </OptionButtonContainerStyled>
          <ButtonContainerStyled>
            {element.data?.isAllowFreeInput && (
              <FreeUserInputContainerStyled>
                <Tooltip
                  placement="top"
                  title={t('canvas.collectUserAnswer.freeTextInputTooltip')}
                >
                  <InfoIcon
                    sx={{
                      color: 'bluegrey.200',
                      width: '20px',
                      height: '20px',
                      marginRight: '8px',
                    }}
                  />
                </Tooltip>
                <FreeUserInputButtonStyled>
                  <Typography variant="body2" color="secondary.dark">
                    {t('canvas.collectUserAnswer.view.freeUserInputButton')}
                  </Typography>
                  <DataContainerStyled>
                    <Typography variant="caption" color="bluegrey.700">
                      {(
                        viewData?.cells[
                          element.data?.freeInputID
                        ] as DataFlowChartCell
                      )?.uniqCvr || 0}
                      %
                    </Typography>
                  </DataContainerStyled>
                </FreeUserInputButtonStyled>
              </FreeUserInputContainerStyled>
            )}
          </ButtonContainerStyled>
        </OptionContainerStyled>
      </ContainerStyled>
      <HintTextContainerStyled>
        <Typography variant="body3" color="warning.dark">
          {t('canvas.collectUserAnswer.hintText')}
        </Typography>
      </HintTextContainerStyled>
    </Box>
  );
};
