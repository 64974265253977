import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import Button from '../button/button';

export interface UploadFileButtonProps {
  disabled?: boolean;
  loading?: boolean;
  accept?: string;
  onFileChange: (file: Blob) => void;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export function UploadFileButton({
  children,
  disabled,
  accept,
  loading,
  onFileChange,
}: PropsWithChildren<UploadFileButtonProps>) {
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        if (onFileChange) {
          onFileChange(file);
        }
      };
    }
  };

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      startIcon={<UploadFileOutlinedIcon />}
      component="label"
      loading={loading}
    >
      {children}
      <VisuallyHiddenInput
        type="file"
        accept={accept}
        disabled={disabled}
        onChange={handleUploadFile}
      />
    </Button>
  );
}

export default UploadFileButton;
