import { Suspense, SuspenseProps } from 'react';
import { CircularProgress } from '@mui/material';

export function SuspenseWrapper(props: SuspenseProps) {
  return <Suspense {...props} />;
}
SuspenseWrapper.defaultProps = {
  fallback: (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ),
};

export default SuspenseWrapper;
