import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { AccountQuestionResponse } from '@frontend/sorghum/interface';
import { ACCOUNT_QUESTION } from '../apis';

async function getAccountQuestion(): Promise<AccountQuestionResponse> {
  return axios
    .get(ACCOUNT_QUESTION)
    .then((res) => res.data.data)
    .catch((err) => {
      return {
        userName: '',
        pageName: '',
      };
    });
}

export function useAccountQuestion() {
  return useQuery<AccountQuestionResponse>({
    queryKey: ['account-question'],
    queryFn: () => getAccountQuestion(),
  });
}

export default useAccountQuestion;
