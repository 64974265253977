import { Typography } from '@frontend/components/ui';
import { GetEditorMenuType } from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MobileItemTopWrapper = styled(Box)(({ theme }) => ({
  height: '4px',
  width: '68px',
  borderRadius: '61px',
  background: theme.palette['background']['black']['5'],
  marginTop: '6px',
  marginBottom: '12px',
}));

const Divider = styled(Box)(({ theme }) => ({
  height: '1px',
  width: '100%',
  background: theme.palette['background']['black']['5'],
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
  padding: '4px 16px',
  width: '100%',
  display: 'flex',
  justifyContent: 'left',
}));

interface PersistentMenuPreviewProps {
  menu: GetEditorMenuType;
}

export const PersistentMenuPreview: FC<PersistentMenuPreviewProps> = ({
  menu,
}: PersistentMenuPreviewProps) => {
  const [t] = useTranslation();
  const [titleList, setTitleList] = useState<string[]>([]);

  useEffect(() => {
    if (menu.items) {
      const list: string[] = [];
      menu.items.forEach((item) => {
        if (item.title) {
          list.push(item.title);
        }
      });
      setTitleList(list);
    }
  }, [menu]);

  return (
    <>
      <MobileItemTopWrapper />
      <Divider />
      <ItemWrapper>
        <Typography variant="body2" color="grey.500">
          {t('basicSetting.persistentMenu.preview.initialMessage')}
        </Typography>
      </ItemWrapper>
      {titleList.map(
        (title, index) =>
          title && (
            <Box
              key={`persistent_menu_mobile_item_${index}_${title}`}
              width="100%"
            >
              <Divider />
              <ItemWrapper>
                <Typography variant="body2" color="grey.900">
                  {title}
                </Typography>
              </ItemWrapper>
            </Box>
          ),
      )}
    </>
  );
};

export default PersistentMenuPreview;
