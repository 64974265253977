import { GoogleAccount, ResponseWithData } from '@frontend/editor/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { GOOGLE_ACCOUNT } from '../apis';

async function postGoogleAccount(
  projectID: string,
  code: string,
): Promise<ResponseWithData<GoogleAccount>> {
  return axios
    .post(`${GOOGLE_ACCOUNT}?projectId=${projectID}`, { code })
    .then((res) => res.data);
}

export function usePostGoogleAccount(projectID: string) {
  return useMutation({
    mutationKey: ['post-google-account', { projectID }],
    mutationFn: (code: string) => postGoogleAccount(projectID, code),
  });
}

export default usePostGoogleAccount;
