import { globalTheme } from '@frontend/components/external-providers';
import { EditorCtx } from '@frontend/editor/external-providers';
import { sendGAEvent } from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener, styled } from '@mui/material';
import { get } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useViewport,
} from 'reactflow';

// 外層透明的邊，讓觸發範圍比畫面範圍大一些
const EdgeHoverWrapperStyled = styled('path')(({ theme }) => ({
  strokeWidth: '12px',
  stroke: 'transparent',
  fill: 'transparent',

  '&:hover': {
    '& + path': {
      stroke: get(globalTheme, 'palette.blue.600'),
    },
  },
}));

const EdgeStyled = styled('path')(({ theme }) => ({
  strokeWidth: '2px',

  '&.react-flow__edge-path-focus': {
    stroke: get(globalTheme, 'palette.blue.600'),
    fill: 'none',
    markerEnd: 'url(#arrow-closed-focus)',
  },
  '&.react-flow__edge-path': {
    '&:hover': {
      stroke: get(globalTheme, 'palette.blue.600'),
      markerEnd: 'url(#arrow-closed-focus)',
    },
  },
  markerEnd: 'url(#arrow-closed)',
}));

export const CustomFocusMarker = () => {
  return (
    <svg width="0" height="0">
      <defs>
        <marker
          id="arrow-closed-focus"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke={get(globalTheme, 'palette.blue.600')}
            stroke-linecap="round"
            stroke-linejoin="round"
            fill={get(globalTheme, 'palette.blue.600')}
            points="-5,-4 0,0 -5,4 -5,-4"
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
};

export const CustomMarker = () => {
  return (
    <svg width="0" height="0">
      <defs>
        <marker
          id="arrow-closed"
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke="#b1b1b7"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#b1b1b7"
            points="-5,-4 0,0 -5,4 -5,-4"
          ></polyline>
        </marker>
      </defs>
    </svg>
  );
};

const EdgeDeleteIconStyled = styled('div')<{
  $labelX: number;
  $labelY: number;
  $zoom: number;
}>(({ $labelX, $labelY, $zoom }) => ({
  position: 'absolute',
  transform: `translate(-50%, -50%) translate(${$labelX}px,${$labelY}px)`,
  // everything inside EdgeLabelRenderer has no pointer events by default
  // if you have an interactive element, set pointer-events: all
  pointerEvents: 'all',
  background: get(globalTheme, 'palette.grey.white'),
  color: get(globalTheme, 'palette.grey.600'),
  border: `${get(globalTheme, 'palette.grey.white')} ${2 / $zoom}px solid`,
  fontSize: `${16 / $zoom}px`,
  borderRadius: '50%',
  width: `${28 / $zoom}px`,
  height: `${28 / $zoom}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: `${10 / $zoom}px`,
  cursor: 'pointer',
  boxShadow: get(globalTheme, 'shadows.8', ''),
  '&:hover': {
    border: `${get(globalTheme, 'palette.blue.600')} 2px solid`,
  },
}));

const EditorCustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  source,
  sourceHandleId,
  target,
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const state = useContext(EditorCtx);
  const [isFocus, setIsFocus] = useState(false);
  const [isFocusEdge, setIsFocusEdge] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [edgeData, setEdgeData] = useState<Edge<any> | undefined>({
    id: '',
    source: '',
    sourceHandle: '',
    target: '',
    targetHandle: '',
    updatable: 'target',
  });

  const { zoom } = useViewport();

  const handleDeleteClick = useCallback(() => {
    sendGAEvent(
      'Chat Flow Edit',
      'Delete connector',
      'Chat Flow Edit - connector- delete - click',
      '',
    );

    // 有 sourceHandleId 的代表是內層的 Element / Cell，sourceHandleId 會是該 Element / Cell 的 outputID
    if (sourceHandleId) {
      const element = state.getElementByOutputID(sourceHandleId);
      if (element) {
        state.removeConnect(element?.outputID);
      }
      // 沒有 sourceHandleId 的代表是最外層的 Block，source 會是該 Block 的 outputID
    } else {
      const element = state.getElement(source);
      if (element) {
        state.removeConnect(element?.outputID);
      }
    }
    sendGAEvent(
      'Chat Flow Edit',
      'Delete connector',
      'Chat Flow Edit - connector- delete - click',
      '',
    );
  }, [source, sourceHandleId, state]);

  useEffect(() => {
    if (state.edges.find((item) => item.id === id)) {
      setEdgeData(state.edges.find((item) => item.id === id));
    }
  }, [id, state.edges]);

  useEffect(() => {
    if (state.onFocusCellID && edgeData?.sourceHandle) {
      const element = state.getElementByOutputID(edgeData.sourceHandle);
      if (element && element.id === state.onFocusCellID) {
        setIsFocus(true);
      } else {
        setIsFocus(false);
      }
    } else {
      if (state.onFocusID) {
        const blockData = state.getElement(state.onFocusID);

        if (target === state.onFocusID || source === blockData?.id) {
          setIsFocus(true);
        } else {
          setIsFocus(false);
        }
      }
    }
  }, [edgeData, edgeData?.sourceHandle, source, state, target]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsFocusEdge(false)}>
        <EdgeHoverWrapperStyled
          id={id}
          d={edgePath}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={() => setIsFocusEdge(true)}
        />
      </ClickAwayListener>

      <EdgeStyled
        className={
          isFocus || isFocusEdge || isHover
            ? 'react-flow__edge-path-focus'
            : 'react-flow__edge-path'
        }
        d={edgePath}
      />
      {!state.readonly &&
        !state.tourMode &&
        (isHover || isFocus || isFocusEdge) && (
          <EdgeLabelRenderer>
            <EdgeDeleteIconStyled
              $labelX={labelX}
              $labelY={labelY}
              $zoom={zoom}
              onClick={handleDeleteClick}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <CloseIcon
                style={{
                  fontSize: '1em',
                }}
              />
            </EdgeDeleteIconStyled>
          </EdgeLabelRenderer>
        )}
    </>
  );
};

export default EditorCustomEdge;
