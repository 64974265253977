import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface TemplateDataType {
  id: number;
  title: string;
  tagList: { id: number; label: string }[];
  buildTime: number;
}

export const useTemplateData = () => {
  const [t] = useTranslation();

  const getTemplateData = useCallback(
    (templateIndex: number): TemplateDataType => {
      switch (templateIndex) {
        case 4:
          return {
            id: 4,
            title: t('templateSelection.aiGuided.step2Templates.template1'),
            buildTime: 6,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.luckyDraw'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.autoReply'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.socialMediaMarketing'),
              },
            ],
          };
        case 5:
          return {
            id: 5,
            title: t('templateSelection.aiGuided.step2Templates.template2'),
            buildTime: 15,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.interactiveQuiz'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.autoReply'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.socialMediaMarketing'),
              },
            ],
          };
        case 6:
          return {
            id: 6,
            title: t('templateSelection.aiGuided.step2Templates.template3'),
            buildTime: 8,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.eventActivity'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.coupon'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.autoReply'),
              },
              {
                id: 4,
                label: t('templateSelection.tags.socialMediaMarketing'),
              },
            ],
          };
        case 7:
          return {
            id: 7,
            title: t('templateSelection.aiGuided.step2Templates.template4'),
            buildTime: 8,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.fansLoyalty'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.specificContent'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.discount'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.loyaltyProgram'),
              },
              {
                id: 4,
                label: t('templateSelection.tags.autoReply'),
              },
            ],
          };
        case 8:
          return {
            id: 8,
            title: t('templateSelection.aiGuided.step2Templates.template5'),
            buildTime: 15,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.infoCollection'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.whitePaperRequest'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.leadsCollection'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.autoReply'),
              },
              {
                id: 4,
                label: t('templateSelection.tags.eventMarketing'),
              },
            ],
          };
        case 9:
          return {
            id: 9,
            title: t('templateSelection.aiGuided.step2Templates.template6'),
            buildTime: 15,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.trafficGeneration'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.replyAutomation'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.autoReply'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.personalization'),
              },
              {
                id: 4,
                label: t('templateSelection.tags.contentMarketing'),
              },
            ],
          };
        case 10:
          return {
            id: 10,
            title: t('templateSelection.aiGuided.step2Templates.template7'),
            buildTime: 6,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.personalization'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.productSales'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.knowYourUsers'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.userConversion'),
              },
            ],
          };
        case 11:
          return {
            id: 11,
            title: t('templateSelection.aiGuided.step2Templates.template8'),
            buildTime: 6,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.emailSubscription'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.registerForDiscounts'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.trafficGeneration'),
              },
            ],
          };
        case 12:
          return {
            id: 12,
            title: t('templateSelection.aiGuided.step2Templates.template9'),
            buildTime: 6,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.crossSelling'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.personalizeContent'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.productSales'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.boostReturnRate'),
              },
              {
                id: 4,
                label: t('templateSelection.tags.restaurantMarketing'),
              },
            ],
          };
        case 13:
          return {
            id: 13,
            title: t('templateSelection.aiGuided.step2Templates.template10'),
            buildTime: 15,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.restaurantMarketing'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.menuDisplay'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.returningCustomer'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.discountPromotion'),
              },
            ],
          };
        case 2:
          return {
            id: 2,
            title: t('templateSelection.aiGuided.step2Templates.template11'),
            buildTime: 12,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.autoReply'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.replyAutomation'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.faq'),
              },
            ],
          };
        case 3:
          return {
            id: 3,
            title: t('templateSelection.aiGuided.step2Templates.template12'),
            buildTime: 15,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.coupons'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.leadsCollection'),
              },
            ],
          };
        case 14:
          return {
            id: 14,
            title: t('templateSelection.aiGuided.step2Templates.template13'),
            buildTime: 20,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.psychologicalTest'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.socialMediaMarketing'),
              },
            ],
          };
        case 15:
          return {
            id: 15,
            title: t('templateSelection.aiGuided.step2Templates.template14'),
            buildTime: 10,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.increaseConversion'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.customerService'),
              },
            ],
          };
        case 16:
          return {
            id: 16,
            title: t('templateSelection.aiGuided.step2Templates.template15'),
            buildTime: 8,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.subscription'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.incentive'),
              },
              {
                id: 3,
                label: t('templateSelection.tags.gallery'),
              },
            ],
          };
        case 17:
          return {
            id: 17,
            title: t('templateSelection.aiGuided.step2Templates.template16'),
            buildTime: 15,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.fansInteraction'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.subscription'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.infoCollection'),
              },
            ],
          };
        case 1:
          return {
            id: 1,
            title: t('templateSelection.aiGuided.step2Templates.template17'),
            buildTime: 8,
            tagList: [
              {
                id: 0,
                label: t('templateSelection.tags.userInfoCollection'),
              },
              {
                id: 1,
                label: t('templateSelection.tags.knowYourUsers'),
              },
              {
                id: 2,
                label: t('templateSelection.tags.increaseSubscription'),
              },
            ],
          };
        default:
          return {
            id: 0,
            title: '',
            buildTime: 0,
            tagList: [],
          };
      }
    },
    [t],
  );

  return { getTemplateData };
};
