import { GetBroadcastDataType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST } from '../apis';

const defaultData: GetBroadcastDataType = {
  id: '',
  name: '',
  target: {
    topic: {
      id: '',
      name: '',
    },
  },
  publishStatus: 1,
  status: 1,
  schedule: {
    type: 1,
  },
  flow: {
    id: '',
    block: {
      blockId: '',
      starter: false,
      data: { title: '' },
    },
  },
};

const getBroadcastData = async (
  projectID: string,
  broadcastID: string,
): Promise<GetBroadcastDataType> =>
  axios
    .get(`${BROADCAST}?projectId=${projectID}&broadcastId=${broadcastID}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });

export function useGetBroadcastData(projectID: string, broadcastID: string) {
  return useQuery<GetBroadcastDataType>({
    queryKey: ['get-broadcast-data', { projectID, broadcastID }],
    queryFn: () => {
      if (projectID && broadcastID) {
        return getBroadcastData(projectID, broadcastID);
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetBroadcastData;
