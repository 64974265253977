import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST_CONTACTS } from '../apis';

interface ConditionType {
  logicalOperator?: number;
  filterType?: number;
  compareType?: number;
  value: string;
}

interface ParamsType {
  projectId: string;
  condition?: ConditionType[];
}

interface OptionType {
  onSuccess: (data: any) => void;
}

interface ResponseType {
  code: number;
  msg: string;
  data: {
    count: number;
  };
}

async function postBroadcastContacts(params: ParamsType) {
  return axios
    .post(BROADCAST_CONTACTS, {
      condition: params.condition,
      projectId: params.projectId,
    })
    .then((res) => res.data);
}

export function usePostBroadcastContacts(options: OptionType) {
  return useMutation({
    mutationKey: ['post-broadcast-contacts'],
    mutationFn: (params: ParamsType) => postBroadcastContacts(params),
    onSuccess: (data: ResponseType) => {
      if (data.code === 20000) {
        return options.onSuccess(data.data.count);
      } else {
        return options.onSuccess(0);
      }
    },
  });
}
