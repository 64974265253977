import { AppBar, Toolbar as MuiToolbar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { Breadcrumbs, Button } from '@frontend/components/ui';
import { BreadcrumbsItem } from '@frontend/components/interface';
import { Trans } from 'react-i18next';

/* eslint-disable-next-line */
export interface ToolbarProps {
  status: number;
  breadcrumbItems: BreadcrumbsItem[];
  breadcrumbNow: BreadcrumbsItem;
  disabled?: boolean;
  onPublish: () => void;
  onSave: () => void;
}

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  height: '60px',
  background: theme.palette['background']['primary']['5'],
  boxShadow: 'none',
  display: 'flex',
}));

const BreadcrumbsWrapper = styled(Box)`
  flex-grow: 1;
`;

export const Toolbar: FC<ToolbarProps> = ({
  status,
  breadcrumbItems,
  breadcrumbNow,
  disabled = false,
  onSave,
  onPublish,
}: ToolbarProps) => {
  return (
    <AppBarStyled position="static" color="inherit">
      <MuiToolbar>
        <BreadcrumbsWrapper>
          <Breadcrumbs history={breadcrumbItems} now={breadcrumbNow} />
        </BreadcrumbsWrapper>
        <Button
          onClick={onSave}
          color="primary"
          sx={{ textTransform: 'uppercase' }}
        >
          <Trans i18nKey="common.save" />
        </Button>
        <Button
          onClick={onPublish}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'uppercase' }}
          disabled={status === 2 || disabled}
        >
          {status <= 1 ? (
            <Trans i18nKey="common.publish" />
          ) : (
            <Trans i18nKey="common.update" />
          )}
        </Button>
      </MuiToolbar>
    </AppBarStyled>
  );
};

export default Toolbar;
