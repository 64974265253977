import { BroadcastOptionType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST_OPTION } from '../apis';

const defaultData: BroadcastOptionType[] = [];

const getBroadcastOption = async (
  projectID: string,
): Promise<BroadcastOptionType[]> =>
  axios
    .get(`${BROADCAST_OPTION}?projectId=${projectID}`)
    .then((res) => res.data.data)
    .catch((_) => defaultData);

export function useGetBroadcastOption(projectID: string) {
  return useQuery({
    queryKey: ['get-broadcast-option', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getBroadcastOption(projectID);
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetBroadcastOption;
