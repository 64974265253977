import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { FC } from 'react';

interface TooltipProps extends MuiTooltipProps {
  // true 時不會出現 tooltip
  disabled?: boolean;
}

/**
 * Renders a tooltip component.
 * @param {boolean} props.disabled - disabled tooltip.
 * @return {React.ReactNode} The rendered tooltip component.
 */
export const Tooltip: FC<TooltipProps> = ({
  disabled,
  children,
  ...props
}: TooltipProps) => {
  if (disabled) {
    return children;
  } else {
    return <MuiTooltip {...props}>{children}</MuiTooltip>;
  }
};

export default Tooltip;
