import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_INCENTIVE } from '../apis';

interface PropsType {
  incentiveID: string;
}

async function deleteCouponIncentive(
  projectID: string,
  couponID: string,
  props: PropsType,
): Promise<Response> {
  return axios
    .delete(
      `${COUPON_INCENTIVE}?projectId=${projectID}&couponId=${couponID}&incentiveId=${props.incentiveID}`,
    )
    .then((res) => res.data)
    .catch((err) => {
      const res: Response = {
        code: 400,
        msg: err.data,
      };
      return res;
    });
}

export function useDeleteCouponIncentive(
  projectID: string,
  couponID: string,
  option?: UseApi<Response, PropsType>,
) {
  return useMutation({
    mutationKey: ['delete-coupon-incentive'],
    mutationFn: (props: PropsType) => {
      return deleteCouponIncentive(projectID, couponID, props);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['get-coupon', { projectID, couponID }]);
      if (option?.onSuccess) {
        option?.onSuccess(data, variables);
      }
    },
  });
}
