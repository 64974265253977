import { Dialog } from '@frontend/components/ui';
import {
  useGetEditorUniqueName,
  usePostFlowGroupCopy,
} from '@frontend/sorghum/data-access';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../form-input/form-input';
import ProjectSelect from '../select/project';

/* eslint-disable-next-line */
export interface CopyGroupModalProps {
  open: boolean;
  projectId: string;
  groupName: string;
  groupId: string;
  handleClose: () => void;
}

const formName = 'name';

type FormValues = {
  name: string;
  projectId: string;
};

export const CopyGroupModal: FC<CopyGroupModalProps> = ({
  projectId,
  groupId,
  groupName,
  open,
  handleClose,
}) => {
  const [t] = useTranslation();
  const { getUniqueName } = useGetEditorUniqueName();
  const { mutate: copyGroup } = usePostFlowGroupCopy();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: 'name',
      projectId: projectId,
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (open) {
      getUniqueName({
        projectId: projectId,
        type: 'flowGroup',
        name: groupName + ' copy',
      }).then((data) => {
        reset({ name: data.data.name, projectId: projectId });
      });
    }
  }, [open, projectId, groupName, getUniqueName, reset]);

  const onConfirm = useCallback(
    (data: FormValues) => {
      copyGroup({
        flowGroupId: groupId,
        originProjectId: projectId,
        name: data.name,
        newProjectId: data.projectId,
        flowSuffix: 'copy',
      });
      handleClose();
    },
    [copyGroup, groupId, handleClose, projectId],
  );

  const onSubmit = handleSubmit(onConfirm);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Dialog
          size="xs"
          title={t('flows.modal.copy.copyGroup')}
          open={open}
          handleClose={handleClose}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          handleConfirm={onSubmit}
        >
          <FormInput
            formName={formName}
            formError={errors}
            label={t('flows.modal.flowGroupNameLabel')}
            register={register(formName, {
              required: t('common.required'),
            })}
          />
          <ProjectSelect />
        </Dialog>
      </form>
    </FormProvider>
  );
};

export default CopyGroupModal;
