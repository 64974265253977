export enum CellButtonTypeEnum {
  REDIRECT = 'redirectButton',
  URL = 'urlButton',
  CALL = 'callButton',
}

interface CellButtonType {
  title: string;
}
export interface RedirectButtonType extends CellButtonType {
  flowId: string;
  flowName: string;
}

export interface UrlButtonType extends CellButtonType {
  url: string;
  size: number | string;
}

export interface CallButtonType extends CellButtonType {
  tel: string;
}

export interface CellType {
  cellId: string;
  type: CellButtonTypeEnum;
  data: RedirectButtonType | UrlButtonType | CallButtonType;
}
