import {
  BREAK_POINT,
  PAGE_DESKTOP_ONLY,
  usePath,
  useRwd,
} from '@frontend/sorghum/utils';

export const useMobile = () => {
  const { breakPoint } = useRwd();
  const { navigate } = usePath();
  if (breakPoint <= BREAK_POINT.SM) {
    navigate(PAGE_DESKTOP_ONLY);
  }
};
