import { useCanvasUpdate } from '@frontend/editor/data-access';
import { TextButtonCellType } from '@frontend/editor/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditorInput from '../editor-input/editor-input';
import { ToggleIconType } from './text-button-cell-modal';

export const PhoneSetting = ({
  elementID,
  getTargetElement,
}: ToggleIconType) => {
  const [t] = useTranslation();
  const elementData =
    (getTargetElement(elementID) as TextButtonCellType) ||
    ({} as TextButtonCellType);

  const { canvasUpdateData } = useCanvasUpdate();

  const [phoneInput, setPhoneInput] = useState(elementData.data.tel);

  return (
    <EditorInput
      placeholder={t('canvas.modal.editTextButton.phoneCode')}
      value={phoneInput}
      onChange={(val: string) => {
        setPhoneInput(val);
        canvasUpdateData(elementID, 'data.tel', val);
      }}
      helperText={t('canvas.modal.editTextButton.phoneHelperText')}
    />
  );
};
