import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_RN_COUNT } from '../apis';

async function getProjectRNCount(): Promise<number> {
  return axios
    .get(PROJECT_RN_COUNT)
    .then((res) => {
      if (res.data.data.count) return res.data.data.count;
      return 0;
    })
    .catch((err) => 0);
}

export function useGetProjectRNCount(projectID: string) {
  return useQuery<number>({
    queryKey: ['get-project-rn-count', { projectID }],
    queryFn: getProjectRNCount,
  });
}

export default useGetProjectRNCount;
