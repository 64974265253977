import { EditorCtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  ActionCellBaseType,
  ActionCellTypesEnum,
  ActionSequenceType,
  ActionSheetType,
  BaseElement,
  BlockTypesEnum,
  CollectUserAnswerType,
  ConditionCellType,
  CouponCellType,
  CouponCellTypesEnum,
  CouponType,
  CouponTypesEnum,
  ElementTypesEnum,
  EntryPointBotLink,
  EntryPointShareLink,
  EntryPointStatusItem,
  EntryPointsEnum,
  GalleryImageType,
  ImageType,
  JumpToFlowType,
  OptionCellType,
  PublishError,
  PublishErrorsEnum,
  RecurringNotificationType,
  Rules,
  ShareLinkType,
  TextButtonCellType,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { isValueInEnum } from '@frontend/editor/utils';
import { sendGAEvent } from '@frontend/sorghum/utils';
import { useParams } from 'react-router-dom';
import { useGetEditorFlowEntry } from '../use-get-editor-flow-entry/use-get-editor-flow-entry';

import {
  REGEX_CHECK_TAIWAN_PHONE_NUMBER,
  REGEX_CHECK_URL,
} from '@frontend/editor/utils';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProjectID } from '../use-get-project-id/use-get-project-id';

export function useCanvasCollect() {
  const state = useContext(EditorCtx);
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { id } = useParams();
  const { data: entries } = useGetEditorFlowEntry(
    1,
    id as string,
    projectID as string,
    state.readonly,
  );

  /** check elements and return all errors of publish **/
  const validate = useCallback((): PublishError[] => {
    const newErrors: PublishError[] = [];
    const publishStatusText =
      state.publishStatus === 1 ? t('common.publish') : t('common.update');
    state.elements.forEach((el) => {
      switch (el.elementType) {
        case ElementTypesEnum.ENTRY_POINT: {
          if (!el.targetID) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.ENTRY_POINT_NO_TARGET,
              message: t('canvas.alert.entryPoint.targetEmpty'),
            });
          }
          break;
        }
        case ElementTypesEnum.BLOCK: {
          if (el.children.length <= 0) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.BLOCK_EMPTY,
              message: t('canvas.alert.blockEmpty'),
            });
          }
          break;
        }
        case ElementTypesEnum.TEXT_BUTTON: {
          if (!el.label) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.TEXTAREA_LABEL_EMPTY,
              message: t('canvas.alert.textButton.labelEmpty'),
            });
          }
          break;
        }
        case ElementTypesEnum.TEXT_BUTTON_CELL: {
          const cellButton = el as TextButtonCellType;

          if (!el.label) {
            newErrors.push({
              elementID: cellButton.id,
              type: PublishErrorsEnum.TEXT_BUTTON_CELL_LABEL_EMPTY,
              message: t('canvas.alert.textButton.labelEmpty'),
            });
          }

          switch (cellButton.buttonType) {
            case TextButtonCellTypesEnum.BLOCK: {
              if (!cellButton.targetID) {
                newErrors.push({
                  elementID: cellButton.id,
                  type: PublishErrorsEnum.TEXT_BUTTON_CELL_BLOCK_NO_TARGET_ID,
                  message: t('canvas.alert.textButton.blockNoTarget'),
                });
              }
              break;
            }
            case TextButtonCellTypesEnum.PHONE: {
              if (cellButton.data.tel) {
                if (!REGEX_CHECK_TAIWAN_PHONE_NUMBER.test(cellButton.data.tel)) {
                  newErrors.push({
                    elementID: cellButton.id,
                    type: PublishErrorsEnum.TEXT_BUTTON_CELL_PHONE_WRONG_FORMAT,
                    message: t('canvas.alert.textButton.phoneFormatError'),
                  });
                }
              } else {
                newErrors.push({
                  elementID: cellButton.id,
                  type: PublishErrorsEnum.TEXT_BUTTON_CELL_PHONE_NO_NUMBER,
                  message: t('canvas.alert.textButton.phoneEmpty'),
                });
              }
              break;
            }
            case TextButtonCellTypesEnum.URL: {
              if (cellButton.data.url) {
                const regex = new RegExp(REGEX_CHECK_URL);
                if (!regex.test(cellButton.data.url)) {
                  newErrors.push({
                    elementID: cellButton.id,
                    type: PublishErrorsEnum.TEXT_BUTTON_CELL_URL_WRONG_FORMAT,
                    message: t('canvas.alert.textButton.urlFormatError'),
                  });
                }
              } else {
                newErrors.push({
                  elementID: cellButton.id,
                  type: PublishErrorsEnum.TEXT_BUTTON_CELL_URL_EMPTY,
                  message: t('canvas.alert.textButton.urlEmpty'),
                });
              }
              break;
            }
            case TextButtonCellTypesEnum.FLOW: {
              if (!cellButton.data.flowID) {
                newErrors.push({
                  elementID: cellButton.id,
                  type: PublishErrorsEnum.TEXT_BUTTON_CELL_FLOW_EMPTY,
                  message: t('canvas.alert.textButton.flowEmpty'),
                });
              }
              break;
            }
          }
          break;
        }
        case ElementTypesEnum.SHARE_LINK: {
          const data = el.data as ShareLinkType;
          if (!data.fileUrl) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.SHARE_LINK_IMAGE_EMPTY,
              message: t('canvas.alert.shareLink.imageEmpty'),
            });
          }
          if (!data.title) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.SHARE_LINK_TITLE_EMPTY,
              message: t('canvas.alert.shareLink.titleEmpty'),
            });
          }
          if (!data.buttonText) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.SHARE_LINK_TEXT_BUTTON_EMPTY,
              message: t('canvas.alert.shareLink.textButtonEmpty'),
            });
          }
          break;
        }
        case ElementTypesEnum.COUPON: {
          const data = el as CouponType;
          if (!data.couponValue) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.COUPON_VALUE_EMPTY,
              message: t('canvas.alert.coupon.valueEmpty'),
            });
          }

          data.children?.forEach((i) => {
            const cellData = state.getElement(i) as CouponCellType;
            switch (data.type) {
              case CouponTypesEnum.SEND: {
                if (!cellData.targetID) {
                  switch (cellData.cellType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_SEND_SUCCESS_TARGET_EMPTY,
                        message: t(
                          'canvas.alert.coupon.send.successTargetEmpty',
                          {
                            status: publishStatusText,
                          },
                        ),
                      });
                      break;
                    }
                    case CouponCellTypesEnum.EXHAUSTED: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_SEND_EXHAUSTED_TARGET_EMPTY,
                        message: t(
                          'canvas.alert.coupon.send.exhaustedTargetEmpty',
                          {
                            status: publishStatusText,
                          },
                        ),
                      });
                      break;
                    }
                    case CouponCellTypesEnum.LIMIT: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_SEND_LIMIT_TARGET_EMPTY,
                        message: t(
                          'canvas.alert.coupon.send.limitTargetEmpty',
                          {
                            status: publishStatusText,
                          },
                        ),
                      });
                      break;
                    }
                  }
                }
                break;
              }
              case CouponTypesEnum.SHOW: {
                if (!cellData.targetID) {
                  switch (cellData.cellType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_SHOW_SUCCESS_TARGET_EMPTY,
                        message: t(
                          'canvas.alert.coupon.show.successTargetEmpty',
                          {
                            status: publishStatusText,
                          },
                        ),
                      });
                      break;
                    }
                    case CouponCellTypesEnum.FAIL: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_SHOW_FAIL_TARGET_EMPTY,
                        message: t('canvas.alert.coupon.show.failTargetEmpty', {
                          status:
                            state.publishStatus === 1
                              ? t('common.publish')
                              : t('common.update'),
                        }),
                      });
                      break;
                    }
                  }
                }
                break;
              }
              case CouponTypesEnum.REDEEM: {
                if (!cellData.targetID) {
                  switch (cellData.cellType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_REDEEM_SUCCESS_TARGET_EMPTY,
                        message: t(
                          'canvas.alert.coupon.redeem.successTargetEmpty',
                          {
                            status: publishStatusText,
                          },
                        ),
                      });
                      break;
                    }
                    case CouponCellTypesEnum.FAIL: {
                      newErrors.push({
                        elementID: cellData.id,
                        type: PublishErrorsEnum.COUPON_REDEEM_FAIL_TARGET_EMPTY,
                        message: t(
                          'canvas.alert.coupon.redeem.failTargetEmpty',
                          {
                            status: publishStatusText,
                          },
                        ),
                      });
                      break;
                    }
                  }
                }
                break;
              }
            }
          });
          break;
        }
        case ElementTypesEnum.RECURRING_NOTIFICATION: {
          const data = el as RecurringNotificationType;
          // if (!data.topic) {
          //   newErrors.push({
          //     elementID: el.id,
          //     type: PublishErrorsEnum.RECURRING_NOTIFICATION_TOPIC_EMPTY,
          //     message: t('canvas.alert.recurringNotification.topicEmpty'),
          //   });
          // }
          if (!data.fileID) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.RECURRING_NOTIFICATION_IMAGE_EMPTY,
              message: t('canvas.alert.recurringNotification.imageEmpty'),
            });
          }
          if (!data.title) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.RECURRING_NOTIFICATION_TITLE_EMPTY,
              message: t('canvas.alert.recurringNotification.titleEmpty'),
            });
          }
          break;
        }
        case ElementTypesEnum.IMAGE: {
          const data = el as ImageType;
          if (!data.fileUrl) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.IMAGE_FILE_EMPTY,
              message: t('canvas.alert.image.fileEmpty'),
            });
          }
          break;
        }
        case ElementTypesEnum.ACTION_ELEMENT: {
          const data = el as ActionCellBaseType;
          switch (data.actionType) {
            case ActionCellTypesEnum.SET: {
              const cellData = data as ActionAttributeType;
              if (!cellData.attributeID) {
                newErrors.push({
                  elementID: el.id as string,
                  type: PublishErrorsEnum.ACTION_SET_ATTRIBUTE_KEY_EMPTY,
                  message: t('canvas.alert.action.nameEmpty'),
                });
              } else if (!cellData.valueID) {
                newErrors.push({
                  elementID: el.id,
                  type: PublishErrorsEnum.ACTION_SET_ATTRIBUTE_VALUE_EMPTY,
                  message: t('canvas.alert.action.valueEmpty'),
                });
              }
              break;
            }
            case ActionCellTypesEnum.REMOVE: {
              const cellData = data as ActionAttributeType;
              if (!cellData.attributeID) {
                newErrors.push({
                  elementID: el.id,
                  type: PublishErrorsEnum.ACTION_REMOVE_ATTRIBUTE_VALUE_EMPTY,
                  message: t('canvas.alert.action.nameEmpty'),
                });
              }
              break;
            }
            case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE:
            case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
              const cellData = data as ActionSequenceType;
              if (!cellData.sequence) {
                newErrors.push({
                  elementID: el.id as string,
                  type: PublishErrorsEnum.ACTION_SEQUENCE_EMPTY,
                  message: t('canvas.alert.action.sequenceEmpty'),
                });
              }
              break;
            }
            case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
              const cellData = data as ActionSheetType;
              if (
                !cellData.data.accountID ||
                !cellData.data.sheetID ||
                !cellData.data.properties
              ) {
                newErrors.push({
                  elementID: el.id as string,
                  type: PublishErrorsEnum.ACTION_SHEET_NOT_SET_YET,
                  message: t('canvas.alert.action.sheetNotSetYet', {
                    status: publishStatusText,
                  }),
                });
              }
              break;
            }
          }
          break;
        }
        case ElementTypesEnum.CONDITION_CELL: {
          const data = el as ConditionCellType;
          if (data.rule !== Rules.ELSE) {
            if (!data.categoryType) {
              newErrors.push({
                elementID: el.id,
                type: PublishErrorsEnum.CONDITION_CATEGORY_TYPE_EMPTY,
                message: t('canvas.alert.condition.categoryEmpty'),
              });
            }
            if (!data.targetID) {
              newErrors.push({
                elementID: el.id,
                type: PublishErrorsEnum.CONDITION_MATCH_NEXT_STEP_EMPTY,
                message: t('canvas.alert.condition.matchNextStepEmpty'),
              });
            }
          }
          break;
        }
        case ElementTypesEnum.GALLERY_IMAGE: {
          const data = el as GalleryImageType;
          // 標題不能為空
          if (!data.title) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.GALLERY_TITLE_EMPTY,
              message: t('canvas.alert.gallery.error', {
                status: publishStatusText,
              }),
            });
          }
          // 至少有一項有值
          if (!data.url && !data.subtitle && data.children.length <= 0) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.GALLERY_DATA_ERROR,
              message: t('canvas.alert.gallery.error', {
                status: publishStatusText,
              }),
            });
          }
          break;
        }
        case ElementTypesEnum.COLLECT_USER_ANSWER: {
          const data = el as CollectUserAnswerType;

          // 標題不能為空
          if (!data.data?.text) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.COLLECT_USER_ANSWER_TITLE_EMPTY,
              message: t('canvas.alert.collectUserAnswer.error', {
                status: publishStatusText,
              }),
            });
            sendGAEvent(
              'Chat Flow Edit - collect user answer',
              'collect user answer error toast-popup',
              'Chat Flow Edit - collect user answer - error toast - popup',
              '',
            );
          }
          if (!data.data?.attributeId) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.COLLECT_USER_ANSWER_ATTRIBUTE_EMPTY,
              message: t('canvas.alert.collectUserAnswer.error', {
                status: publishStatusText,
              }),
            });
            sendGAEvent(
              'Chat Flow Edit - collect user answer',
              'collect user answer error toast-popup',
              'Chat Flow Edit - collect user answer - error toast - popup',
              '',
            );
          }
          // 沒有 option 且沒有開啟 user free input
          if (data.children.length === 0 && !data.data?.isAllowFreeInput) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.COLLECT_USER_ANSWER_NO_OPTION,
              message: t('canvas.alert.collectUserAnswer.error', {
                status: publishStatusText,
              }),
            });
            sendGAEvent(
              'Chat Flow Edit - collect user answer',
              'collect user answer error toast-popup',
              'Chat Flow Edit - collect user answer - error toast - popup',
              '',
            );
          }
          break;
        }
        case ElementTypesEnum.OPTION_CELL: {
          const data = el as OptionCellType;
          if (!data.data?.title) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.COLLECT_USER_ANSWER_OPTION_TITLE_EMPTY,
              message: t('canvas.alert.collectUserAnswer.error', {
                status: publishStatusText,
              }),
            });
            sendGAEvent(
              'Chat Flow Edit - collect user answer',
              'collect user answer error toast-popup',
              'Chat Flow Edit - collect user answer - error toast - popup',
              '',
            );
          }
          break;
        }
        case ElementTypesEnum.JUMP_TO_FLOW: {
          const data = el as JumpToFlowType;
          if (!data.data.flowID) {
            newErrors.push({
              elementID: el.id,
              type: PublishErrorsEnum.JUMP_TO_FLOW_TARGET_EMPTY,
              message: t('canvas.alert.action.flowNotExist', {
                status:
                  state.publishStatus === 1
                    ? t('common.publish')
                    : t('common.update'),
              }),
            });
            sendGAEvent(
              'Chat Flow Edit - jump to flow',
              'jump to flow error toast-popup',
              'Chat Flow Edit - jump to flow - error toast - popup',
              '',
            );
          }
          break;
        }
      }
    });
    return newErrors;
  }, [state, t]);

  const getErrorStatus = useCallback(
    (elementID: string, errorType: PublishErrorsEnum) => {
      const errors = validate();
      const error = errors.find(
        (err) => err.elementID === elementID && err.type === errorType,
      );

      if (error) {
        return error;
      } else {
        return null;
      }
    },
    [validate],
  );

  const getErrorStatusAfterPublished = useCallback(
    (elementID: string, errorType: PublishErrorsEnum) => {
      const errors = state.publishErrors;
      const error = errors.find(
        (err) => err.elementID === elementID && err.type === errorType,
      );

      if (error) {
        return error;
      } else {
        return null;
      }
    },
    [state],
  );

  // 新增 entry point 的下拉選單
  const entryPointMenu = useMemo(() => {
    const newMenu = {
      growth: [] as EntryPointStatusItem[],
      basic: [] as EntryPointStatusItem[],
    };
    newMenu.growth.push({
      id: 'option_botlink',
      i18nKey: 'botLink',
      isUsed: false,
      type: EntryPointsEnum.BOT_LINK,
    } as EntryPointStatusItem);
    if (state.entryPoints.welcomeMessage) {
      newMenu.basic.push({
        ...state.entryPoints.welcomeMessage,
        id: 'option_welcome',
      });
    }
    newMenu.basic.push({
      id: 'option_menu',
      i18nKey: 'persistentMenu',
      isUsed: false,
      type: EntryPointsEnum.PERSISTENT_MENU,
    } as EntryPointStatusItem);
    if (state.entryPoints.defaultAnswer) {
      newMenu.basic.push({
        ...state.entryPoints.defaultAnswer,
        id: 'option_default',
      });
    }
    return newMenu;
  }, [state]);

  const getErrors = useCallback(() => {
    const errors = validate();
    state.setPublishErrors(errors);
    return errors;
  }, [state, validate]);

  // 由 flow 產生的 entry-point ex: share-link
  const systemEntryPoint = useMemo(() => {
    return state.elements
      .filter((i) => i.elementType === ElementTypesEnum.SHARE_LINK)
      .map((i) => {
        return {
          id: i.id,
          label: i.label as string,
          type: i.elementType,
          createDate: i.createDate,
          shareLinkID: (i as ShareLinkType).shareLinkID,
        } as EntryPointShareLink;
      });
  }, [state]);

  const getBotLink = useCallback(
    (id: string) => {
      const botLink = state.flowEntryPoints.find((i) => i.id === id);
      if (botLink) {
        return botLink as EntryPointBotLink;
      } else {
        return null;
      }
    },
    [state.flowEntryPoints],
  );

  const checkDuplicateBotLinkRef = useCallback(
    (ref: string) => {
      return state.flowEntryPoints.find(
        (i) => (i as EntryPointBotLink).ref === ref,
      );
    },
    [state.flowEntryPoints],
  );

  const { mediumOptions, sourceOptions, paidOptions } = useMemo(() => {
    const mediumOptions = [
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.direct'),
        value: 1,
      },
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.referral'),
        value: 2,
      },
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.paid'),
        value: 3,
      },
    ];

    const sourceOptions = [
      {
        label: 'Facebook',
        value: 1,
      },
      {
        label: 'Line',
        value: 2,
      },
      {
        label: 'QR-code',
        value: 3,
      },
      {
        label: 'Youtube',
        value: 4,
      },
      {
        label: 'Instagram',
        value: 5,
      },
      {
        label: 'KOL',
        value: 6,
      },
    ];

    const paidOptions = [
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.facebookAD'),
        value: 7,
      },
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.googleAD'),
        value: 8,
      },
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.youtubeAD'),
        value: 9,
      },
      {
        label: t('canvas.entryPoint.addMenu.botLink.option.offlineAD'),
        value: 10,
      },
      {
        label: 'KOL',
        value: 6,
      },
    ];

    return {
      mediumOptions,
      sourceOptions,
      paidOptions,
    };
  }, [t]);

  return {
    blocks: state.elements.filter((el) =>
      isValueInEnum(el.elementType, BlockTypesEnum),
    ) as BaseElement[],
    entryPointMenu,
    flowEntryPoint: state.flowEntryPoints,
    systemEntryPoint,
    broadcastEntryPoint: entries?.broadcasts ?? [],
    redirectFlowEntryPoint: entries?.redirectFlows ?? [],
    sequenceEntryPoint: entries?.sequences ?? [],
    commentAutoReplyEntryPoint: entries?.commentReplies ?? [],
    mediumOptions,
    sourceOptions,
    paidOptions,
    checkDuplicateBotLinkRef,
    getBotLink,
    getErrors,
    getErrorStatus,
    getErrorStatusAfterPublished,
  };
}

export default useCanvasCollect;
