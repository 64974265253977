import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Response } from '@frontend/sorghum/interface';
import { FLOW_GROUP_COPY } from '../apis';
import { toast } from 'react-toastify';
import { queryClient } from '@frontend/sorghum/external-providers';

async function postFlowGroupCopy(params: {
  flowGroupId: string;
  originProjectId: string;
  name: string;
  newProjectId: string;
  flowSuffix: string;
}): Promise<Response> {
  return axios
    .post(FLOW_GROUP_COPY, params)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostFlowGroupCopy() {
  return useMutation({
    mutationKey: ['post-flow-group-copy'],
    mutationFn: (params: {
      flowGroupId: string;
      originProjectId: string;
      name: string;
      newProjectId: string;
      flowSuffix: string;
    }) => postFlowGroupCopy(params),
    onSuccess: (data: Response) => {
      queryClient.refetchQueries(['editor-flow-group']);
      toast.success(data.msg);
    },
  });
}

export default usePostFlowGroupCopy;
