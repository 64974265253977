import { queryClient } from '@frontend/sorghum/external-providers';
import { ResponseWithData } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE_MESSAGE } from '../apis';

export interface SequenceMessageResponseType {
  id: string;
  enable: boolean;
  setting: {
    unit: number;
    value: number;
  };
}

async function postSequenceMessage(
  projectID: string,
  sequenceID: string,
): Promise<ResponseWithData<SequenceMessageResponseType>> {
  return axios
    .post(`${SEQUENCE_MESSAGE}?projectId=${projectID}&sequenceId=${sequenceID}`)
    .then((res) => res.data);
}

export function usePostSequenceMessage(
  projectID: string,
  sequenceID: string,
  option?: any,
) {
  return useMutation({
    mutationKey: ['post-sequence-message'],
    mutationFn: () => postSequenceMessage(projectID, sequenceID),
    onSuccess: (data, variables) => {
      if (data.code === 20000) {
        if (option?.onSuccess) {
          option.onSuccess(data.data);
        }
        queryClient.refetchQueries(['get-sequence', { projectID, sequenceID }]);
      }
    },
  });
}
