import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT } from '../apis';

interface RequestType {
  id: string;
  name: string;
}

async function patchProject(props: RequestType) {
  return axios
    .patch(PROJECT, {
      id: props.id,
      name: props.name,
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchProject() {
  return useMutation({
    mutationKey: ['patch-project'],
    mutationFn: (props: RequestType) => patchProject(props),
    onSuccess: (data, variables) => {
      if (data.code === 20000) {
        queryClient.invalidateQueries(['get-project-list']);
        queryClient.invalidateQueries([
          'get-project',
          { projectID: variables.id },
        ]);
      }
    },
  });
}
