import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface DividerProps extends MuiDividerProps {}

export const Divider: FC<DividerProps> = ({
  children,
  ...props
}: DividerProps) => {
  return <MuiDivider {...props}>{children}</MuiDivider>;
};

export default Divider;
