import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchEditorMenuType, Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_MENU } from '../apis';

async function patchEditorMenu(
  projectId: string,
  socialType: number,
  params: PatchEditorMenuType,
): Promise<Response> {
  return axios
    .patch(
      `${EDITOR_MENU}?projectId=${projectId}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorMenu(projectId: string, socialType: number) {
  return useMutation({
    mutationKey: ['patch-editor-menu', { projectId, socialType }],
    mutationFn: (params: PatchEditorMenuType) =>
      patchEditorMenu(projectId, socialType, params),
    onSuccess: (data: Response) => {
      queryClient.refetchQueries([
        'get-editor-menu',
        { projectID: projectId, socialType },
      ]);
      queryClient.invalidateQueries(['get-editor-flow-entry']);
      queryClient.invalidateQueries(['get-editor-entry-status']);
    },
  });
}

export default usePatchEditorMenu;
