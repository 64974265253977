import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_ACTIVATE } from '../apis';

interface SettingType {
  id: string;
  name: string;
  probability: number;
  type: number;
}

interface PropsType {
  id: string;
  name: string;
  setting: SettingType[];
}

const defaultData = {
  code: 40000,
  msg: 'error!',
};

async function postCouponActivate(
  projectID: string,
  props: PropsType,
): Promise<Response> {
  return axios
    .post(`${COUPON_ACTIVATE}?projectId=${projectID}`, {
      id: props.id,
      name: props.name,
      setting: props.setting,
    })
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostCouponActivate(projectID: string) {
  return useMutation({
    mutationKey: ['post-coupon-activate'],
    mutationFn: (props: PropsType) => postCouponActivate(projectID, props),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        'get-coupon',
        { projectID, couponID: variables.id },
      ]);
      queryClient.invalidateQueries([
        'get-coupon-reward-count',
        { projectID, couponID: variables.id },
      ]);
    },
  });
}
