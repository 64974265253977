import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, WelcomeMessageForm } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_WELCOME } from '../apis';

async function patchEditorWelcome(
  projectId: string,
  socialType: number,
  params: {
    greeting: {
      message: string;
      enable: boolean;
    };
    startFlow: {
      id?: string;
      enable: boolean;
    };
  },
): Promise<Response> {
  if (projectId) {
    return axios
      .patch(
        `${EDITOR_WELCOME}?projectId=${projectId}&socialType=${socialType}`,
        params,
      )
      .then((res) => res.data)
      .catch((err) => ({
        code: 400,
        msg: err,
      }));
  } else throw new Error('missing project id');
}

export function usePatchEditorWelcome(projectId: string, socialType: number) {
  return useMutation({
    mutationKey: ['patch-editor-welcome', { projectId, socialType }],
    mutationFn: (params: WelcomeMessageForm) =>
      patchEditorWelcome(projectId, socialType, {
        greeting: {
          message: params.message,
          enable: params.status,
        },
        startFlow: {
          ...(params.flowID && { id: params.flowID }),
          enable: params.flowEnable,
        },
      }),
    onSuccess: (data: Response, variables) => {
      if (data.code === 20000) {
        queryClient.refetchQueries([
          'get-editor-welcome',
          { projectID: projectId, socialType },
        ]);
        queryClient.invalidateQueries(['get-editor-flow-entry']);
        queryClient.invalidateQueries(['get-editor-entry-status']);
      }
    },
  });
}

export default usePatchEditorWelcome;
