import { MenuItem, Tooltip, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

export interface EditorMenuItemList {
  title?: string;
  dropdownItems: Array<{
    onClick?: () => void;
    icon: JSX.Element;
    title: string;
    disabled?: boolean;
    tooltip?: JSX.Element | string;
    id?: string;
  }>;
}

export interface EditorMenuProps {
  id?: string;
  itemList: EditorMenuItemList[];
  scrollDisabled?: boolean;
}

const ContainerStyled = styled(Box)<{ $scrollDisabled?: boolean }>(
  ({ theme, $scrollDisabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.04),0px 24px 38px 3px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '4px',
    width: '314px',
    padding: '8px 0',
    overflow: $scrollDisabled ? 'hidden' : 'auto',
    maxHeight: '400px',
  }),
);

const MenuItemContainerStyled = styled(MenuItem)(({ theme }) => ({
  padding: '0',
}));

const MenuItemStyled = styled(Box)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 16px',
    '& > svg': {
      marginRight: '32px',
    },
    ...($disabled && {
      cursor: 'not-allowed',
      color: theme.palette['grey'][400],
      '& > svg > path': {
        fill: theme.palette['grey'][400],
      },
    }),
  }),
);

export function EditorMenu({ id, itemList, scrollDisabled }: EditorMenuProps) {
  return (
    <ContainerStyled id={id} $scrollDisabled={scrollDisabled}>
      {itemList.map((item, index: number) => {
        return (
          <Box key={index}>
            {item.title && (
              <Typography variant="caption" px={2} py={1} color="bluegrey.400">
                {item.title}
              </Typography>
            )}
            {item.dropdownItems.map((dropItem, _index: number) => {
              if (dropItem.tooltip) {
                return (
                  <Tooltip
                    key={`${dropItem.title}_${_index}`}
                    placement="right"
                    title={dropItem.tooltip}
                  >
                    <MenuItemContainerStyled
                      id={dropItem.id}
                      onClick={dropItem.onClick}
                    >
                      <MenuItemStyled $disabled={!!dropItem.disabled}>
                        {dropItem.icon}
                        <Typography variant="body1">
                          {dropItem.title}
                        </Typography>
                      </MenuItemStyled>
                    </MenuItemContainerStyled>
                  </Tooltip>
                );
              }
              return (
                <MenuItemContainerStyled
                  id={dropItem.id}
                  key={`${dropItem.title}_${_index}`}
                  onClick={dropItem.onClick}
                >
                  <MenuItemStyled $disabled={!!dropItem.disabled}>
                    {dropItem.icon}
                    <Typography variant="body1">{dropItem.title}</Typography>
                  </MenuItemStyled>
                </MenuItemContainerStyled>
              );
            })}
          </Box>
        );
      })}
    </ContainerStyled>
  );
}

export default EditorMenu;
