// emoji 套件會有 eslint error, 先行移除
// import Picker from '@emoji-mart/react';
import { Box, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, useCallback, useState } from 'react';

/* eslint-disable-next-line */
export interface EmojiProps {
  insertEmoji: (emoji: string) => void;
  renderButton?: React.ReactNode;
  isDisabled?: boolean;
  onEmojiClose?: () => void;
  onEmojiOpen?: () => void;
}

const EmojiSelectorWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '0',
  top: '0',
  zIndex: 99,
}));

export const Emoji: FC<EmojiProps> = ({
  insertEmoji,
  renderButton,
  isDisabled = false,
  onEmojiClose,
  onEmojiOpen,
}: EmojiProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    if (!isDisabled) {
      setIsOpen(true);
      if (onEmojiOpen) {
        onEmojiOpen();
      }
    }
  };
  const handleClose = () => {
    setIsOpen(false);
    if (onEmojiClose) onEmojiClose();
  };

  const onEmojiClick = useCallback(
    (data: { native: string }) => {
      if (!isDisabled) {
        insertEmoji(data.native);
      }
    },
    [insertEmoji, isDisabled],
  );

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
      onClick={handleClick}
    >
      {renderButton}
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <EmojiSelectorWrapperStyled>
            {/* <Picker
              set="facebook"
              theme="light"
              onEmojiSelect={onEmojiClick}
              disableAutoFocus
            /> */}
          </EmojiSelectorWrapperStyled>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default Emoji;
