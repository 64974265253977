import { BreadcrumbsItem } from '@frontend/components/interface';
import { EllipsisItemProps, Typography } from '@frontend/components/ui';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import FullScreenLoading from '../full-screen-loading/full-screen-loading';
import TopBar from '../top-bar/top-bar';

export interface PageContainerProps {
  history: BreadcrumbsItem[];
  now: BreadcrumbsItem;
  lastModified?: string;
  menuItems?: EllipsisItemProps[];
  onPublish?: () => void;
  topBarChildren?: React.ReactNode;
  children: React.ReactNode;
  savingText?: React.ReactNode;
  customButton?: React.ReactNode;
  isShowMoreButton?: boolean;
  hasTitle?: boolean;
  title?: string;
  hasTitleTooltip?: boolean;
  titleTooltipText?: string;
  topBarChildrenCondition?: boolean;
  titleChildren?: React.ReactNode;
  titleChildrenCondition?: boolean;
  isActiveLoading?: boolean;
  isShowNoticeBox?: boolean;
  noticeBoxChildren?: React.ReactNode;
}

const PageContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const PageContentStyled = styled(Box)(({ theme }) => ({
  padding: '0 40px 40px 40px',
  height: 'fit-content',
  minHeight: '100%',
  width: '100%',
  backgroundColor: theme.palette['grey'][100],

  '&.no-title': {
    paddingTop: '87px',
  },
}));

const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '87px 40px 20px 40px',
  backgroundColor: theme.palette['grey'][100],

  '.header': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const NoticeBoxContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  width: '100%',
  top: '87px',
}));

export function PageContainer({
  history,
  now,
  customButton,
  children,
  hasTitle = false,
  title,
  hasTitleTooltip = false,
  titleTooltipText = '',
  isShowMoreButton = false,
  menuItems = [],
  savingText,
  topBarChildren,
  topBarChildrenCondition = false,
  titleChildren,
  titleChildrenCondition,
  lastModified,
  isActiveLoading = false,
  isShowNoticeBox = false,
  noticeBoxChildren,
}: PageContainerProps) {
  return (
    <PageContainerStyled>
      {isActiveLoading && <FullScreenLoading />}
      <TopBar
        history={history}
        now={now}
        customButton={customButton}
        isShowMoreButton={isShowMoreButton}
        menuItems={menuItems}
        savingText={savingText}
        lastModified={lastModified}
      >
        {topBarChildren && topBarChildrenCondition && topBarChildren}
      </TopBar>
      {isShowNoticeBox && (
        <NoticeBoxContainerStyled>{noticeBoxChildren}</NoticeBoxContainerStyled>
      )}
      {hasTitle && (
        <TitleContainerStyled>
          <Box className="header">
            <Typography variant="h5">{title}</Typography>
            {!isEmpty(titleTooltipText) && (
              <Tooltip placement="top" title={titleTooltipText}>
                <InfoIcon sx={{ color: 'bluegrey.200', ml: '8px' }} />
              </Tooltip>
            )}
          </Box>
          {titleChildren && titleChildrenCondition && titleChildren}
        </TitleContainerStyled>
      )}
      <PageContentStyled className={`${!hasTitle && 'no-title'}`}>
        {children}
      </PageContentStyled>
    </PageContainerStyled>
  );
}

export default PageContainer;
