import { GetCommentReplyListResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_LIST } from '../apis';

const defaultData = {
  totalCount: 0,
  filterCount: 0,
  commentReplies: [],
  limit: 0,
  offset: 0,
};

async function getCommentReplyList(
  limit: number,
  channelID?: string,
  keyword?: string,
  offset?: number,
): Promise<GetCommentReplyListResponseType> {
  return axios
    .get(
      `${COMMENT_REPLY_LIST}?limit=${limit}${
        channelID ? `&projectChannelId=${channelID}` : ''
      }${keyword ? `&keyword=${keyword}` : ''}${
        offset ? `&offset=${offset}` : ''
      }`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCommentReplyList(
  limit: number,
  channelID?: string,
  keyword?: string,
  offset?: number,
) {
  return useQuery({
    queryKey: [
      'get-comment-reply-list',
      { limit, projectChannelID: channelID, keyword, offset },
    ],
    queryFn: () => {
      if (limit) {
        return getCommentReplyList(limit, channelID, keyword, offset);
      } else {
        return defaultData;
      }
    },
  });
}
