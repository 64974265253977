import { IconSizeType } from '@frontend/components/interface';
import { Button, IconButton, Typography } from '@frontend/components/ui';
import {
  useCanvasCollect,
  useGetProjectID,
  useIsDrawerDataUpdate,
  usePatchEditorFlowEntry,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum, EntryPointsEnum } from '@frontend/editor/interface';
import { REGEX_CHECK_SPECIAL_CHARACTERS } from '@frontend/editor/utils';
import { copyToClipboard } from '@frontend/sorghum/utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TextField, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Autocomplete from '../autocomplete/autocomplete';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import EditorLabel from '../editor-label/editor-label';

const TitleWrapperStyled = styled('div')(({ theme }) => ({
  marginBottom: '20px',
}));

const BotLinkTitleWrapperStyled = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const UrlWrapperStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette['grey'][500],
  marginBottom: '27px',
  marginTop: '8px',
  justifyContent: 'space-between',
}));

const InfoIconWrapperStyled = styled('div')(({ theme }) => ({
  color: theme.palette['grey'][500],
  '& > svg': {
    fontSize: '20px',
  },
}));

const IconWrapperStyled = styled('div')(({ theme }) => ({
  fontSize: '24px',
  marginLeft: '4px',
}));

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  padding: '24px',
  width: '360px',
}));

const TopWrapperStyled = styled('div')(({ theme }) => ({}));

const ApplyButtonStyled = styled('div')(({ theme }) => ({
  width: '100%',
  bottom: '0',
}));

export const DrawerBotLink = () => {
  const { data: projectID } = useGetProjectID();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const [parameter, setParameter] = useState<string>('');
  const {
    sourceOptions,
    paidOptions,
    mediumOptions,
    getBotLink,
    checkDuplicateBotLinkRef,
  } = useCanvasCollect();
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [title, setTitle] = useState('');
  const [medium, setMedium] = useState<number>();
  const [source, setSource] = useState<number | null>();

  const botLink = getBotLink(state.onFocusCellID);

  const { mutate: updateFlowEntryPoint } = usePatchEditorFlowEntry(
    flowID as string,
    1,
    projectID as string,
  );

  const { isDrawerDataUpdate } = useIsDrawerDataUpdate();

  const handleApply = useCallback(() => {
    if (botLink) {
      if (!parameter) {
        setError(true);
        setMessage(t('canvas.entryPoint.addMenu.botLink.parameterRequired'));
        return;
      }

      const checkSpecialCharacterRegex = new RegExp(
        REGEX_CHECK_SPECIAL_CHARACTERS,
      );

      if (!parameter.match(checkSpecialCharacterRegex)) {
        setError(true);
        setMessage(t('canvas.entryPoint.addMenu.botLink.parameterRequired'));
        return;
      }

      // 資料沒變則直接關閉 drawer
      if (
        botLink.ref === parameter &&
        title === botLink.title &&
        medium === botLink.medium &&
        source === botLink.source
      ) {
        uiState.setDrawerType(DrawerTypesEnum.CLOSE);
        return;
      }
      //檢查 flow 內的 bot-link ref 是否有重複
      const duplicate = checkDuplicateBotLinkRef(parameter);
      // 如果有改名而且有重複
      if (duplicate && botLink.ref !== parameter) {
        setError(true);
        setMessage(t('canvas.entryPoint.addMenu.botLink.parameterDuplicate'));
      } else {
        updateFlowEntryPoint(
          {
            title,
            id: botLink.id,
            type: EntryPointsEnum.BOT_LINK,
            ref: parameter,
            enable: botLink.enable,
            ...(source && { source }),
            ...(medium && { medium }),
          },
          {
            onSuccess: (data) => {
              if (data.code === 20000) {
                uiState.setDrawerType(DrawerTypesEnum.CLOSE);
                // 與其他的 flow 的 bot-link ref 重複
              } else if (data.code === 40000) {
                setError(true);
                setMessage(
                  t('canvas.entryPoint.addMenu.botLink.parameterDuplicate'),
                );
              }
            },
          },
        );
      }
    }
  }, [
    botLink,
    checkDuplicateBotLinkRef,
    medium,
    parameter,
    source,
    t,
    title,
    uiState,
    updateFlowEntryPoint,
  ]);

  const url = useMemo(() => {
    if (botLink) {
      const temp = botLink?.url.split('?ref=');
      if (temp && temp?.length > 0) {
        return `${temp[0]}?ref=${parameter}`;
      } else {
        return botLink?.url;
      }
    } else {
      return '';
    }
  }, [botLink, parameter]);

  useEffect(() => {
    setMessage(t('canvas.entryPoint.addMenu.botLink.parameterInfo'));
    setError(false);
  }, [parameter, t]);

  useEffect(() => {
    if (botLink && uiState.drawerType === DrawerTypesEnum.BOT_LINK) {
      setParameter(botLink.ref);
      setTitle(botLink.title);
      setMedium(botLink.medium);
      if (botLink.source) {
        setSource(botLink.source);
      } else {
        setSource(null);
      }
    }
  }, [botLink, uiState.drawerType]);

  useEffect(() => {
    if (botLink && uiState.drawerType === DrawerTypesEnum.BOT_LINK) {
      //FIXME: source 改成 B 再改回 A 會被視為有 update（一個是 undefined，一個是 null）
      if (
        isDrawerDataUpdate(
          {
            parameter: botLink.ref,
            title: botLink.title,
            medium: botLink.medium,
            source: botLink.source,
          },
          { parameter, title, medium, source },
        )
      ) {
        state.setIsDrawerUpdate(true);
      } else {
        state.setIsDrawerUpdate(false);
      }
    }
  }, [
    botLink,
    medium,
    parameter,
    source,
    state,
    title,
    uiState.drawerType,
    isDrawerDataUpdate,
  ]);

  if (botLink) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.BOT_LINK}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Tooltip title={title} placement="bottom-end">
              <TitleWrapperStyled>
                <EditorLabel
                  size="h6"
                  defaultValue={title}
                  onBlur={(val) => setTitle(val)}
                  error={!title ? 'empty' : ''}
                />
              </TitleWrapperStyled>
            </Tooltip>
            <BotLinkTitleWrapperStyled>
              <Typography variant="subtitle2" color="grey.black">
                <Trans i18nKey="canvas.entryPoint.addMenu.botLink.info" />
              </Typography>
            </BotLinkTitleWrapperStyled>
            <UrlWrapperStyled>
              <Typography variant="body2">{url}</Typography>
              <IconWrapperStyled
                onClick={() => copyToClipboard(url)}
                sx={{
                  marginLeft: '4px',
                }}
              >
                <IconButton $size={IconSizeType.S}>
                  <ContentCopyIcon
                    sx={{
                      fontSize: '14px',
                    }}
                  />
                </IconButton>
              </IconWrapperStyled>
            </UrlWrapperStyled>
            <TextField
              sx={{
                marginBottom: '40px',
              }}
              onChange={(e) => setParameter(e.target.value)}
              size="small"
              label={t('canvas.entryPoint.addMenu.botLink.parameter')}
              fullWidth
              value={parameter}
              placeholder={t('canvas.entryPoint.addMenu.botLink.parameter')}
              error={error}
              helperText={message}
            />
            <BotLinkTitleWrapperStyled>
              <Typography variant="subtitle2" color="grey.black">
                <Trans i18nKey="canvas.entryPoint.addMenu.botLink.advanceInfo" />
              </Typography>
              <Tooltip
                placement="top"
                title={t('canvas.entryPoint.addMenu.botLink.advanceTooltip')}
              >
                <InfoIconWrapperStyled>
                  <InfoOutlinedIcon />
                </InfoIconWrapperStyled>
              </Tooltip>
            </BotLinkTitleWrapperStyled>
            <Autocomplete
              label={t('canvas.entryPoint.addMenu.botLink.medium')}
              value={medium}
              options={mediumOptions}
              onChange={(option) => {
                setMedium(option?.value as number);
                setSource(undefined);
              }}
            />

            <Autocomplete
              label={t('canvas.entryPoint.addMenu.botLink.source')}
              value={source}
              options={medium === 3 ? paidOptions : sourceOptions}
              onChange={(option) => {
                setSource(option?.value as number);
              }}
            />
          </TopWrapperStyled>
          <ApplyButtonStyled>
            <Button
              onClick={handleApply}
              size="large"
              variant="contained"
              fullWidth
              disabled={!state.isDrawerUpdate}
            >
              <Trans i18nKey="common.apply" />
            </Button>
          </ApplyButtonStyled>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerBotLink);
