import { uuid } from '@frontend/components/utils';
import { EditorCtx } from '@frontend/editor/external-providers';
import {
  ActionCellTypesEnum,
  CouponCellTypesEnum,
  CouponTypesEnum,
  ElementTypesEnum,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { XYPosition } from 'reactflow';

import {
  ActionType,
  BaseElement,
  ElementCollectType,
  TextButtonCellType,
} from '@frontend/editor/interface';

export const TOUR_ONBOARDING_TEMPLATE_PROPS = {
  FIRST_BLOCK_ID: '093c2041-246c-430d-8923-a3ad2835f3d2',
  SECOND_BLOCK_ID: 'e64c3d32-a7d7-4de3-8b03-b8a170448825',
  ENTRY_POINT_OUTPUT_ID: '13da240d-d23c-412b-85f4-b539f9c418de',
  RN_BLOCK_ID: '67fd5c62-81dd-4909-bb4d-ad7b9479a48c',
  ACTION_BLOCK_ID: 'e4818180-dcb2-43ba-aa39-5788767d8427',
  ACTION_FIRST_SET_ID: 'a1fe8c7d-3233-4a08-9ac9-070b0a613b17',
};

const RN_TEMPLATE_IMAGE_URL = '/static/video/onboarding/upload-image.svg';

export const useCanvasTemplate = () => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);

  const addSendCouponTemplate = (newPosition?: XYPosition) => {
    const position = newPosition || {
      x: Math.random() * window.innerWidth - 100,
      y: Math.random() * window.innerHeight,
    };

    const couponID = uuid();

    const couponCell1 = uuid();
    const couponCell2 = uuid();
    const couponCell3 = uuid();

    const blockID1 = uuid();
    const blockID2 = uuid();
    const blockID3 = uuid();

    const textButtonID1 = uuid();
    const textButtonID2 = uuid();
    const textButtonID3 = uuid();

    const textButtonCellID1 = uuid();
    const textButtonCellID3 = uuid();
    let newSerialNumber = state.blockSerialNumber;

    const template = [
      {
        id: couponID,
        data: {},
        type: CouponTypesEnum.SEND,
        label: t('canvas.growthToolDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [couponCell1, couponCell2, couponCell3],
        position: position,
        targetID: '',
        elementID: couponID,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON,
      },
      {
        id: couponCell1,
        index: 0,
        label: t('canvas.coupon.send.success'),
        nodeID: couponID,
        cellType: CouponCellTypesEnum.SUCCESS,
        outputID: uuid(),
        targetID: blockID1,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON_CELL,
      },
      {
        id: couponCell2,
        index: 1,
        label: t('canvas.coupon.send.exhausted'),
        nodeID: couponID,
        cellType: CouponCellTypesEnum.EXHAUSTED,
        outputID: uuid(),
        targetID: blockID2,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON_CELL,
      },
      {
        id: couponCell3,
        index: 2,
        label: t('canvas.coupon.send.limit'),
        nodeID: couponID,
        cellType: CouponCellTypesEnum.LIMIT,
        outputID: uuid(),
        targetID: blockID3,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON_CELL,
      },
      {
        id: blockID1,
        data: {},
        label: t('canvas.blockDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [textButtonID1],
        outputID: uuid(),
        targetID: '',
        position: {
          x: position.x + 500,
          y: position.y,
        },
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: textButtonID1,
        data: {},
        index: 0,
        label: t('canvas.coupon.send.template.success.label'),
        nodeID: blockID1,
        children: [textButtonCellID1],
        outputID: uuid(),
        parentID: blockID1,
        targetID: '',
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON,
      },
      {
        id: textButtonCellID1,
        data: {},
        index: 0,
        label: t('canvas.coupon.send.template.success.button'),
        nodeID: blockID1,
        children: [],
        outputID: uuid(),
        parentID: textButtonID1,
        targetID: '',
        buttonData: {},
        buttonType: TextButtonCellTypesEnum.BLOCK,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
      },
      {
        id: blockID2,
        data: {},
        label: t('canvas.blockDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [textButtonID2],
        outputID: uuid(),
        targetID: '',
        position: {
          x: position.x + 500,
          y: position.y + 600,
        },
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: textButtonID2,
        data: {},
        index: 0,
        label: t('canvas.coupon.send.template.exhausted.label'),
        nodeID: blockID2,
        children: [],
        outputID: uuid(),
        parentID: blockID2,
        targetID: '',
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON,
      },
      {
        id: blockID3,
        data: {},
        label: t('canvas.blockDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [textButtonID3],
        outputID: uuid(),
        targetID: '',
        position: {
          x: position.x + 500,
          y: position.y + 1000,
        },
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: textButtonID3,
        data: {},
        index: 0,
        label: t('canvas.coupon.send.template.limit.label'),
        nodeID: blockID3,
        children: [textButtonCellID3],
        outputID: uuid(),
        parentID: blockID3,
        targetID: '',
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON,
      },
      {
        id: textButtonCellID3,
        data: {},
        index: 0,
        label: t('canvas.coupon.send.template.limit.button'),
        nodeID: blockID3,
        children: [],
        outputID: uuid(),
        parentID: textButtonID3,
        targetID: '',
        buttonData: {},
        buttonType: ElementTypesEnum.BLOCK,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
      },
    ];

    state.addElements(template);
    state.setBlockSerialNumber(newSerialNumber);

    return template;
  };
  const addShowCouponTemplate = (newPosition?: XYPosition) => {
    const position = newPosition || {
      x: Math.random() * window.innerWidth - 100,
      y: Math.random() * window.innerHeight,
    };

    const couponID = uuid();

    const couponCell1 = uuid();
    const couponCell2 = uuid();

    const blockID1 = uuid();
    const blockID2 = uuid();

    const textButtonID1 = uuid();
    const textButtonID2 = uuid();

    const textButtonCellID1 = uuid();
    const textButtonCellID2 = uuid();
    let newSerialNumber = state.blockSerialNumber;

    const template = [
      {
        id: couponID,
        data: {},
        type: CouponTypesEnum.SHOW,
        label: t('canvas.growthToolDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [couponCell1, couponCell2],
        position: position,
        targetID: '',
        elementID: couponID,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON,
      },
      {
        id: couponCell1,
        index: 0,
        label: t('canvas.coupon.show.success'),
        nodeID: couponID,
        cellType: CouponCellTypesEnum.SUCCESS,
        outputID: uuid(),
        targetID: blockID1,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON_CELL,
      },
      {
        id: couponCell2,
        index: 1,
        label: t('canvas.coupon.show.fail'),
        nodeID: couponID,
        cellType: CouponCellTypesEnum.FAIL,
        outputID: uuid(),
        targetID: blockID2,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.COUPON_CELL,
      },
      {
        id: blockID1,
        data: {},
        label: t('canvas.blockDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [textButtonID1],
        outputID: uuid(),
        targetID: '',
        position: {
          x: position.x + 500,
          y: position.y,
        },
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: textButtonID1,
        data: {},
        index: 0,
        label: t('canvas.coupon.show.template.success.label'),
        nodeID: blockID1,
        children: [textButtonCellID1],
        outputID: uuid(),
        parentID: blockID1,
        targetID: '',
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON,
      },
      {
        id: textButtonCellID1,
        data: {},
        index: 0,
        label: t('canvas.coupon.show.template.success.button'),
        nodeID: blockID1,
        children: [],
        outputID: uuid(),
        parentID: textButtonID1,
        targetID: '',
        buttonData: {},
        buttonType: TextButtonCellTypesEnum.BLOCK,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
      },
      {
        id: blockID2,
        data: {},
        label: t('canvas.blockDefaultTitle', {
          count: newSerialNumber++,
        }),
        inputID: uuid(),
        children: [textButtonID2],
        outputID: uuid(),
        targetID: '',
        position: {
          x: position.x + 500,
          y: position.y + 600,
        },
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: textButtonID2,
        data: {},
        index: 0,
        label: t('canvas.coupon.show.template.fail.label'),
        nodeID: blockID2,
        children: [textButtonCellID2],
        outputID: uuid(),
        parentID: blockID2,
        targetID: '',
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON,
      },
      {
        id: textButtonCellID2,
        data: {},
        index: 0,
        label: t('canvas.coupon.show.template.fail.button'),
        nodeID: blockID2,
        children: [],
        outputID: uuid(),
        parentID: textButtonID2,
        targetID: '',
        buttonData: {},
        buttonType: TextButtonCellTypesEnum.BLOCK,
        createDate: new Date().getTime(),
        elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
      },
    ];

    state.addElements(template);
    state.setBlockSerialNumber(newSerialNumber);

    return template;
  };

  const addRedeemCouponTemplate = useCallback(
    (newPosition?: XYPosition) => {
      const position = newPosition || {
        x: Math.random() * window.innerWidth - 100,
        y: Math.random() * window.innerHeight,
      };

      const couponID = uuid();

      const couponCell1 = uuid();
      const couponCell2 = uuid();

      const blockID1 = uuid();
      const blockID2 = uuid();

      const textButtonID1 = uuid();
      const textButtonID2 = uuid();

      const textButtonCellID1 = uuid();
      const textButtonCellID2 = uuid();
      let newSerialNumber = state.blockSerialNumber;
      const template = [
        {
          id: couponID,
          elementID: uuid(),
          data: {},
          type: CouponTypesEnum.REDEEM,
          label: t('canvas.growthToolDefaultTitle', {
            count: newSerialNumber++,
          }),
          inputID: uuid(),
          children: [couponCell1, couponCell2],
          outputID: uuid(),
          targetID: '',
          position: {
            x: position.x,
            y: position.y,
          },
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.COUPON,
        },
        {
          id: couponCell1,
          data: {},
          index: 0,
          label: t('canvas.coupon.redeem.success'),
          nodeID: couponID,
          outputID: uuid(),
          targetID: blockID1,
          cellType: CouponCellTypesEnum.SUCCESS,
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.COUPON_CELL,
        },
        {
          id: couponCell2,
          data: {},
          index: 0,
          label: t('canvas.coupon.redeem.fail'),
          nodeID: couponID,
          outputID: uuid(),
          targetID: blockID2,
          cellType: CouponCellTypesEnum.FAIL,
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.COUPON_CELL,
        },
        {
          id: blockID1,
          data: {},
          label: t('canvas.blockDefaultTitle', {
            count: newSerialNumber++,
          }),
          inputID: uuid(),
          children: [textButtonID1],
          outputID: uuid(),
          targetID: '',
          position: {
            x: position.x + 500,
            y: position.y,
          },
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.BLOCK,
        },
        {
          id: textButtonID1,
          data: {},
          index: 0,
          label: t('canvas.coupon.redeem.template.success.label'),
          nodeID: blockID1,
          children: [textButtonCellID1],
          outputID: uuid(),
          parentID: textButtonID1,
          targetID: '',
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.TEXT_BUTTON,
        },
        {
          id: textButtonCellID1,
          data: {},
          index: 0,
          label: t('canvas.coupon.redeem.template.success.button'),
          nodeID: blockID1,
          children: [],
          outputID: uuid(),
          parentID: textButtonID1,
          targetID: '',
          buttonData: {},
          buttonType: TextButtonCellTypesEnum.BLOCK,
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
        },
        {
          id: blockID2,
          data: {},
          label: t('canvas.blockDefaultTitle', {
            count: newSerialNumber++,
          }),
          inputID: uuid(),
          children: [textButtonID2],
          outputID: uuid(),
          targetID: '',
          position: {
            x: position.x + 500,
            y: position.y + 400,
          },
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.BLOCK,
        },
        {
          id: textButtonID2,
          data: {},
          index: 0,
          label: t('canvas.coupon.redeem.template.fail.label'),
          nodeID: blockID2,
          children: [textButtonCellID2],
          outputID: uuid(),
          parentID: textButtonID2,
          targetID: '',
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.TEXT_BUTTON,
        },
        {
          id: textButtonCellID2,
          data: {},
          index: 0,
          label: t('canvas.coupon.redeem.template.fail.button'),
          nodeID: blockID2,
          children: [],
          outputID: uuid(),
          parentID: textButtonID2,
          targetID: '',
          buttonData: {},
          buttonType: TextButtonCellTypesEnum.BLOCK,
          createDate: new Date().getTime(),
          elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
        },
      ];
      state.addElements(template);
      state.setBlockSerialNumber(newSerialNumber);
      return template;
    },
    [state, t],
  );

  const addTourOnboardingTemplate = useCallback(() => {
    const TOUR_ONBOARDING_TEMPLATE: ElementCollectType[] = [
      {
        id: 'd2a17f4a-2045-462b-bd79-13e57bfb5ccc',
        data: {},
        children: [],
        outputID: TOUR_ONBOARDING_TEMPLATE_PROPS.ENTRY_POINT_OUTPUT_ID,
        position: {
          x: -266,
          y: 0,
        },
        createDate: 1706607249871,
        elementType: ElementTypesEnum.ENTRY_POINT,
      },
      {
        id: TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID,
        data: {},
        label: t('tour.onboarding.template.label1'),
        inputID: '51ce2a5a-fe88-4b2f-87dc-f7eafcdf99ee',
        children: ['a1bf6d35-fddc-4507-b222-d9bae45670e5'],
        outputID: '0f863dd1-bbf0-46c1-9d37-88b1c130cd8b',
        position: {
          x: 187.46126086475738,
          y: -11.167840579855834,
        },
        targetID: '',
        createDate: 1706607249871,
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: 'a1bf6d35-fddc-4507-b222-d9bae45670e5',
        data: {},
        index: 0,
        label: t('tour.onboarding.template.label2'),
        nodeID: TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID,
        children: ['fd0da420-9d1a-46df-830c-ff91201fa35d'],
        outputID: '09e77a01-7e19-49c7-9414-fbd88ea194d8',
        parentID: TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID,
        targetID: '',
        createDate: 1706607332327,
        elementType: ElementTypesEnum.TEXT_BUTTON,
        position: {
          x: 187.46126086475738,
          y: -11.167840579855834,
        },
      },
      {
        id: 'fd0da420-9d1a-46df-830c-ff91201fa35d',
        data: {},
        index: 0,
        label: t('tour.onboarding.template.label3'),
        nodeID: TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID,
        children: [],
        outputID: '8b047430-4613-4c18-b0b6-3b2cf0991253',
        parentID: 'a1bf6d35-fddc-4507-b222-d9bae45670e5',
        position: { x: 0, y: 0 },
        buttonType: TextButtonCellTypesEnum.BLOCK,
        createDate: 1706607334034,
        elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
      },
      {
        id: '28365263-3704-4d17-881a-f7439238ba93',
        data: {},
        label: t('tour.onboarding.template.label4'),
        inputID: '5f37313b-5a28-4339-8bb4-e5a1a3c8cc30',
        children: ['59b11422-973c-4a16-ac69-a06ac04f9519'],
        outputID: 'd878a9b4-2e7b-4aac-acc2-eda449ccf120',
        position: {
          x: 2002.8413500277793,
          y: -8.261753817156887,
        },
        targetID: '',
        createDate: 1706607357137,
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: '59b11422-973c-4a16-ac69-a06ac04f9519',
        data: {},
        index: 0,
        label: t('tour.onboarding.template.label5'),
        nodeID: '28365263-3704-4d17-881a-f7439238ba93',
        children: [],
        outputID: 'a0babe7c-aa32-4c4a-826a-8de9b2e5cf21',
        parentID: '28365263-3704-4d17-881a-f7439238ba93',
        targetID: '',
        createDate: 1706607357137,
        elementType: ElementTypesEnum.TEXT_BUTTON,
      } as BaseElement,
      {
        id: 'f5c5f30d-3996-477c-9822-22012e78071c',
        label: t('tour.onboarding.template.label6'),
        outputID: 'cc193439-6576-41d6-9022-455c0335c989',
        position: {
          x: -268.00866322276175,
          y: -194.3014562100717,
        },
        createDate: 1706607468148,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: 'dd98431c-e3b2-4a8c-b304-c3a40755fc80',
        label: t('tour.onboarding.template.label7'),
        outputID: '02f812de-1c9a-4544-bc98-3bb25870736a',
        position: {
          x: 187.93866139024533,
          y: -141.79998867200732,
        },
        createDate: 1706607526723,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: 'd440a47f-b0fa-4c05-939c-cb9fc5896475',
        label: t('tour.onboarding.template.label8'),
        outputID: 'a65c9c18-79bd-4644-af79-63e5e6f99974',
        position: {
          x: 653.4041143397172,
          y: -204.22723492837324,
        },
        createDate: 1706607549621,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: 'e10c08fe-88b7-4b1a-975d-7bf997dc8a57',
        label: t('tour.onboarding.template.label9'),
        outputID: 'b0473c08-aff0-452e-afbe-5c9831cada0e',
        position: {
          x: 1112.7450963721722,
          y: -136.49036791502635,
        },
        createDate: 1706608330831,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: 'f37a1ec5-5b59-4282-94d7-c0e0cb9c2c2e',
        label: t('tour.onboarding.template.label10'),
        outputID: '038f2be6-9e50-4f30-a0a1-ffd7c43c947c',
        position: {
          x: 1991.9622009958375,
          y: -111.9260815430518,
        },
        createDate: 1706608364933,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_BLOCK_ID,
        data: {},
        label: t('tour.onboarding.template.label11'),
        inputID: '2963359f-0b19-4741-bd7e-30830ccdc405',
        children: [TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_FIRST_SET_ID],
        outputID: '26243d5c-bc82-4515-ac56-ca7a5b0cfdfe',
        position: {
          x: 1547.3050044666923,
          y: 4.176232797968282,
        },
        targetID: '28365263-3704-4d17-881a-f7439238ba93',
        createDate: 1707208441633,
        elementType: ElementTypesEnum.ACTION,
      },
      {
        id: TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_FIRST_SET_ID,
        index: 0,
        nodeID: TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_BLOCK_ID,
        parentID: TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_BLOCK_ID,
        actionType: ActionCellTypesEnum.SET,
        createDate: 1707208441634,
        elementType: ElementTypesEnum.ACTION_ELEMENT,
        outputID: '',
        children: [],
      } as ActionType,
      {
        id: TOUR_ONBOARDING_TEMPLATE_PROPS.SECOND_BLOCK_ID,
        data: {},
        label: t('tour.onboarding.template.label12'),
        inputID: 'b4c9bf53-0888-4ae1-89be-eb2e6bc4abc6',
        children: ['9833e5c7-4232-4a21-969b-3cbe12cd657b'],
        outputID: 'b637f704-20ae-484d-ad4d-27102f93b15e',
        position: {
          x: 1119.6463442018987,
          y: 0.3762720568087161,
        },
        targetID: '',
        createDate: 1711939468593,
        elementType: ElementTypesEnum.BLOCK,
      },
      {
        id: '9833e5c7-4232-4a21-969b-3cbe12cd657b',
        data: {},
        index: 0,
        label: t('tour.onboarding.template.label13'),
        nodeID: TOUR_ONBOARDING_TEMPLATE_PROPS.SECOND_BLOCK_ID,
        children: ['ac474321-89f4-4035-b1a0-6b130b64bf33'],
        outputID: '9f535450-9da0-4134-a1a0-7018d2b7f176',
        parentID: TOUR_ONBOARDING_TEMPLATE_PROPS.SECOND_BLOCK_ID,
        targetID: '',
        createDate: 1711939468596,
        elementType: ElementTypesEnum.TEXT_BUTTON,
      },
      {
        id: 'ac474321-89f4-4035-b1a0-6b130b64bf33',
        data: {
          url: 'https://platform.goskyai.com/',
        },
        index: 0,
        label: t('tour.onboarding.template.label14'),
        nodeID: TOUR_ONBOARDING_TEMPLATE_PROPS.SECOND_BLOCK_ID,
        children: [],
        outputID: '1f239a83-5475-4e11-81c9-ab233d2f2fe2',
        parentID: '9833e5c7-4232-4a21-969b-3cbe12cd657b',
        targetID: TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_BLOCK_ID,
        buttonData: {},
        buttonType: TextButtonCellTypesEnum.URL,
        createDate: 1711939483671,
        elementType: ElementTypesEnum.TEXT_BUTTON_CELL,
      } as TextButtonCellType,
      {
        id: '53b1a7c1-13b4-4ffd-82c5-4af9a2587ef1',
        label: t('tour.onboarding.template.label15'),
        outputID: '710387ae-5d6d-43af-afe3-d9ebe8756d2a',
        position: {
          x: 1547.2981669827484,
          y: -129.74556547795407,
        },
        createDate: 1712904027367,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: '611856e2-88fa-4398-99cb-cbe567909646',
        label: t('tour.onboarding.template.label16'),
        outputID: '6c4964ae-e657-4ec5-a08c-864fa9eebd37',
        position: {
          x: -267.60482444730957,
          y: -273.0428786542932,
        },
        createDate: 1713335958076,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
      {
        id: '59e783e6-25cf-4ce3-9dd4-d8611d1b44b1',
        label: t('tour.onboarding.template.label18'),
        outputID: '1f891d9b-8294-499b-b60e-a149763aa6bd',
        position: {
          x: 1549.0170840083194,
          y: -254.86155559058147,
        },
        createDate: 1713340396730,
        elementType: ElementTypesEnum.NOTE,
        children: [],
      },
    ];
    state.restoreElement(TOUR_ONBOARDING_TEMPLATE);
  }, [state, t]);

  const addTourOnboardingRN = useCallback(
    (endpoint: string) => {
      const TOUR_ONBOARDING_TEMPLATE_RN = [
        {
          id: TOUR_ONBOARDING_TEMPLATE_PROPS.RN_BLOCK_ID,
          data: {},
          label: t('tour.onboarding.template.label19'),
          inputID: 'bca98d43-2e61-4290-ba33-efab5b3d3317',
          children: ['d60d03dd-6753-41b9-9342-ab89fa5121c6'],
          outputID: '2a51b1b1-383d-4f4b-85bd-bab8904b5968',
          position: {
            x: 658.1091304172996,
            y: -3.0035217395675318,
          },
          targetID: '',
          createDate: 1706607338805,
          elementType: ElementTypesEnum.BLOCK,
        },
        {
          id: 'd60d03dd-6753-41b9-9342-ab89fa5121c6',
          data: {},
          index: 1,
          title: t('tour.onboarding.template.label20'),
          topic: 'aef6abec-91ea-49c2-9e74-d33228a96184',
          cellID: '7cd43dca-4ae9-470b-ae26-a2fe1bbdb097',
          fileID: 'upload-image',
          nodeID: '67fd5c62-81dd-4909-bb4d-ad7b9479a48c',
          fileUrl: `${endpoint}${RN_TEMPLATE_IMAGE_URL}`,
          reoptin: false,
          children: [],
          outputID: '8dad376e-3421-4ed9-8b64-547ca51ef9de',
          parentID: '67fd5c62-81dd-4909-bb4d-ad7b9479a48c',
          sourceID: '',
          targetID: TOUR_ONBOARDING_TEMPLATE_PROPS.SECOND_BLOCK_ID,
          imageType: 1,
          createDate: 1707214098756,
          elementName: t('canvas.blockMenu.collectUserAnswerButton'),
          elementType: ElementTypesEnum.RECURRING_NOTIFICATION,
        },
      ];
      state.addElements(TOUR_ONBOARDING_TEMPLATE_RN);
      state.updateElementData(
        TOUR_ONBOARDING_TEMPLATE_PROPS.FIRST_BLOCK_ID,
        'targetID',
        TOUR_ONBOARDING_TEMPLATE_PROPS.RN_BLOCK_ID,
      );
    },
    [state, t],
  );

  return {
    addSendCouponTemplate,
    addShowCouponTemplate,
    addRedeemCouponTemplate,
    addTourOnboardingTemplate,
    addTourOnboardingRN,
  };
};
