import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchSequenceMessageType } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE_MESSAGE } from '../apis';

interface PropsType {
  projectID: string;
  sequenceID: string;
  data: PatchSequenceMessageType;
}

async function patchSequenceMessage(props: PropsType) {
  return axios
    .patch(
      `${SEQUENCE_MESSAGE}?projectId=${props.projectID}&sequenceId=${props.sequenceID}`,
      {
        ...props.data,
      },
    )
    .then((res) => res.data);
}
export function usePatchSequenceMessage() {
  return useMutation({
    mutationKey: ['patch-sequence-message'],
    mutationFn: (props: PropsType) => patchSequenceMessage(props),
    onSuccess: (data, variables: PropsType) => {
      queryClient.refetchQueries([
        'get-sequence-list',
        { projectID: variables.projectID, sequenceID: variables.sequenceID },
      ]);
    },
  });
}
