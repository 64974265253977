import { Box } from '@mui/material';
import { Dialog } from '@frontend/components/ui';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from '../form-input/form-input';
import { useForm, RegisterOptions } from 'react-hook-form';

/* eslint-disable-next-line */
export interface NameModalProps {
  open: boolean;
  name: string;
  title: string;
  label?: string;
  validation?: RegisterOptions;
  closeAfterConfirm?: boolean;
  setName: (newName: string) => void;
  handleClose: () => void;
  handleConfirm: (name: string) => void;
}

const formName = 'name';

type FormValues = {
  name: string;
};

export const NameModal: FC<NameModalProps> = ({
  open,
  name,
  title,
  label,
  validation,
  closeAfterConfirm = true,
  setName,
  handleClose,
  handleConfirm,
}) => {
  const [t] = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      name: name,
    },
  });

  useEffect(() => {
    reset({ name });
  }, [name]);

  useEffect(() => {
    trigger();
  }, [validation]);

  const onConfirm = useCallback(
    (data: FormValues) => {
      setName(data.name);
      handleConfirm(data.name);
      if (closeAfterConfirm) {
        handleClose();
      }
    },
    [handleClose, setName, handleConfirm],
  );

  const onSubmit = handleSubmit(onConfirm);

  return (
    <form onSubmit={onSubmit}>
      <Dialog
        size="xs"
        title={title}
        open={open}
        handleClose={handleClose}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.ok')}
        handleConfirm={onSubmit}
      >
        <Box>
          <FormInput
            formName={formName}
            formError={errors}
            label={label}
            register={register(
              formName,
              validation
                ? validation
                : {
                    required: t('common.required'),
                  },
            )}
          />
        </Box>
      </Dialog>
    </form>
  );
};

export default NameModal;
