import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface InputFieldStyledType {
  $isFocus: boolean;
  $isHover: boolean;
  $zoom: number;
}

export const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '2px 12px 12px 12px',
}));

export const ItemWrapperStyled = styled(Box)<InputFieldStyledType>(
  ({ theme, $isFocus, $isHover, $zoom }) => ({
    position: 'relative',
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    borderRadius: '8px',
    background: theme.palette['grey']['100'],
    border: `2px solid ${
      $isFocus || $isHover ? theme.palette['purple']['50'] : 'transparent'
    }}`,
    fontSize: `${28 / $zoom}px`,
  }),
);

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 0 8px 8px',
}));

export const GoogleSheetWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0 8px 8px',
}));

export const AttributeWrapperStyled = styled(Box)(({ theme }) => ({
  background: theme.palette['bluegrey'][50],
  color: theme.palette['primary']['main'],
  padding: '2px 8px',
  borderRadius: '4px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
