import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostIncentiveCodeResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_INCENTIVE_CODE_UPLOAD } from '../apis';

interface PropsType {
  file: Blob;
}

async function postIncentiveCode(
  projectID: string,
  couponID: string,
  incentiveID: string,
  props: PropsType,
): Promise<ResponseWithData<PostIncentiveCodeResponse>> {
  const formData = new FormData();
  formData.append('file', props.file);

  return axios
    .post(
      `${COUPON_INCENTIVE_CODE_UPLOAD}?projectId=${projectID}&couponId=${couponID}&incentiveId=${incentiveID}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then((res) => res.data)
    .catch((err) => {
      return {
        code: 400,
        msg: err,
      };
    });
}

export function usePostCouponIncentiveCode(
  projectID: string,
  couponID: string,
  incentiveID: string,
) {
  return useMutation({
    mutationKey: ['post-coupon-incentive-code'],
    mutationFn: (props: PropsType) => {
      return postIncentiveCode(projectID, couponID, incentiveID, props);
    },
    onSuccess: (
      data: ResponseWithData<PostIncentiveCodeResponse>,
      variables,
    ) => {
      queryClient.invalidateQueries(['get-coupon', { projectID, couponID }]);
    },
  });
}
