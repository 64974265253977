import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_STEP } from '../apis';

async function patchAccountStep(step: string): Promise<Response> {
  return axios.patch(ACCOUNT_STEP, { step }).then((res) => res.data);
}

export function useAccountStep(options?: UseApi<Response, any>) {
  return useMutation({
    mutationKey: ['account-step'],
    mutationFn: () => patchAccountStep('complete'),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['get-account-info'], {
        step: 'complete',
      });
      options?.onSuccess && options?.onSuccess(data, variables);
    },
  });
}

export default useAccountStep;
