import { MaterialStyledProps } from '@frontend/components/interface';
import { Box, Menu, SxProps } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as EmojiIcon } from '../../images/emoji-icon.svg';
import { ReactComponent as TextLabelIcon } from '../../images/text-label-icon.svg';
import { Emoji } from '../emoji/emoji';
import { MenuItem } from '../menu/item/item';
import { Typography } from '../typography/typography';

export enum ToolPositionTypesEnum {
  'IN' = 'IN',
  'OUT' = 'OUT',
}

export interface TextareaTooltipProps {
  length: number;
  hasLabel?: boolean;
  labelItem?: LabelMenuItem[];
  position?: ToolPositionTypesEnum;
  limit?: number;
  prefix?: string;
  parentRef?: React.RefObject<HTMLElement>;
  setEmoji?: (emoji: string) => void;
  insertLabel?: (label: string) => void;
  onEllipsisClick?: () => void;
  hasEmoji?: boolean;
  sx?: SxProps;
}

interface LabelMenuItem {
  name: string;
  value: string;
}

interface TextareaTooltipStyledProps extends MaterialStyledProps {
  $position: ToolPositionTypesEnum;
}

const TextareaTooltipStyled = styled(Box)<TextareaTooltipStyledProps>(
  ({ theme, $position }) => ({
    position: 'absolute',
    right: 0,
    bottom:
      $position === ToolPositionTypesEnum.IN
        ? theme.spacing(1)
        : theme.spacing(-4),
    background: theme.palette.bluegrey[700],
    borderRadius: '4px',
    padding: '5px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    zIndex: 99,
    color: theme.palette.success.main,
  }),
);

const IconStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '16px',
  marginRight: '5px',
}));

const TextCountWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '12px',
  width: '18px',
}));

export function TextareaTooltip(props: TextareaTooltipProps) {
  const {
    labelItem,
    limit,
    length,
    parentRef,
    prefix,
    hasLabel = false,
    position = ToolPositionTypesEnum.IN,
    setEmoji,
    onEllipsisClick,
    insertLabel = () => null,
    hasEmoji = false,
    sx,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ellipsisRef = useRef<HTMLElement>(null);

  const handleEllipsisClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setAnchorEl(ellipsisRef.current);
      if (onEllipsisClick) {
        onEllipsisClick();
      }
    },
    [onEllipsisClick],
  );

  const handleClose = () => {
    // 關閉選單時清除沒有選擇的標籤
    parentRef?.current?.childNodes.forEach((node) => {
      const n = node as HTMLElement;
      if (n.getAttribute && n.getAttribute('data-type') === 'label') {
        if (n.innerText === prefix) {
          node.remove();
        }
      }
    });

    setAnchorEl(null);
  };

  return (
    <>
      <TextareaTooltipStyled ref={ellipsisRef} $position={position} sx={sx}>
        {hasLabel && (
          <IconStyled onClick={handleEllipsisClick}>
            <TextLabelIcon />
          </IconStyled>
        )}
        {hasEmoji && setEmoji && (
          <IconStyled>
            <Emoji
              renderButton={<EmojiIcon />}
              insertEmoji={setEmoji}
              isDisabled={limit ? limit - length < 2 : false}
            />
          </IconStyled>
        )}

        {limit && (
          <TextCountWrapper>
            <Typography
              variant="notoSans"
              color={length >= limit ? 'error' : 'success'}
            >
              {limit - length}
            </Typography>
          </TextCountWrapper>
        )}
      </TextareaTooltipStyled>
      {hasLabel && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        >
          {labelItem &&
            labelItem.map((i) => {
              return (
                <MenuItem
                  key={i.name}
                  content={i.name}
                  onClick={() => insertLabel(i.value)}
                />
              );
            }, [])}
        </Menu>
      )}
    </>
  );
}

export default TextareaTooltip;
