import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface TypographyProps extends MuiTypographyProps {
  styleType?: 'title' | 'info';
}

const styles = {
  title: {
    fontWeight: 600,
    fontSize: '28px',
    color: 'grey.800',
  },
  info: {
    fontWeight: 400,
    fontSize: '14px',
    color: 'grey.700',
  },
};

export const Typography: FC<TypographyProps> = ({
  styleType,
  children,
  ...props
}) => {
  return (
    <MuiTypography {...(styleType && { sx: styles[styleType] })} {...props}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
