import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_CONDITION } from '../apis';

async function deleteCommentReplyCondition(id: string) {
  return axios
    .delete(`${COMMENT_REPLY_CONDITION}?id=${id}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteCommentReplyCondition() {
  return useMutation({
    mutationKey: ['delete-comment-reply-condition'],
    mutationFn: (id: string) => deleteCommentReplyCondition(id),
  });
}
