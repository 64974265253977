import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_LOGOUT } from '../apis';

async function deleteAccountLogout(): Promise<Response> {
  return axios
    .delete(`${ACCOUNT_LOGOUT}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}
export function useDeleteAccountLogout() {
  return useMutation({
    mutationKey: ['delete-account-logout'],
    mutationFn: () => deleteAccountLogout(),
  });
}

export default useDeleteAccountLogout;
