import { globalTheme } from '@frontend/components/external-providers';
import { Textarea, UploadImage } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import {
  useCanvasCollect,
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
  useCanvasView,
  useGetProjectID,
  useModal,
  usePostEditorFlowUpload,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { PublishErrorsEnum, ShareLinkType } from '@frontend/editor/interface';
import { IMAGE_RATIO, IMAGE_WIDTH } from '@frontend/editor/utils';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { GetEditorLabelItemLabelType } from '@frontend/sorghum/interface';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Typography } from '@mui/material';
import { get } from 'lodash';
import { memo, useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import NodeShareLinkUrlButton from './node-share-link-url-button';
import {
  BodyStyled,
  ElementContainerStyled,
  ImageUploadWrapperStyled,
  InfoStyled,
} from './styles';

export const NodeShareLink = ({ id }: NodeProps) => {
  const { zoom } = useViewport();
  const { getTargetElement } = useCanvasGet();
  const shareLinkData =
    (getTargetElement(id) as ShareLinkType) || ({} as ShareLinkType);
  const { label: header, inputID } = shareLinkData;

  const title = get(shareLinkData, 'data.title', '');
  const fileID = get(shareLinkData, 'data.fileID', '');
  const fileUrl = get(shareLinkData, 'data.fileUrl', '');
  const buttonText = get(shareLinkData, 'data.buttonText', '');

  const state = useContext(EditorCtx);
  const [t] = useTranslation();
  const [tempData, setTempData] = useState<{
    fileUrl: string;
    fileID: string;
    title: string;
    buttonText: string;
  }>({
    fileID,
    fileUrl,
    title,
    buttonText,
  });

  const { openDeleteBlockModal } = useModal();
  const { canvasUpdateLabel } = useCanvasUpdate();
  const { canvasRemoveBlockByID } = useCanvasRemove();
  const { onFocusID } = useCanvasView();
  const { getErrorStatus, getErrorStatusAfterPublished } = useCanvasCollect();
  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);
  const { data: projectID } = useGetProjectID();
  const { mutate: uploadImage } = usePostEditorFlowUpload(projectID as string);
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);

  const labelList = get(labelItems, 'data', []);

  const handleChange = useCallback(
    (text: string) => {
      state.updateElementData(id, 'data.title', text);
      setTempData({
        ...tempData,
        title: text,
      });
    },
    [tempData, state, id],
  );

  const onRemoveButtonClick = useCallback(() => {
    if (tempData.title || tempData.fileUrl || tempData.buttonText) {
      openDeleteBlockModal(id);
    } else {
      canvasRemoveBlockByID(id);
    }
  }, [
    tempData.title,
    tempData.fileUrl,
    tempData.buttonText,
    openDeleteBlockModal,
    id,
    canvasRemoveBlockByID,
  ]);

  const handleButtonTextChange = useCallback(
    (val: string) => {
      state.updateElementData(id, 'data.buttonText', val);
      setTempData({
        ...tempData,
        buttonText: val,
      });
    },
    [tempData, id, state],
  );

  const handleImageChange = useCallback(
    (val: Blob) => {
      uploadImage(
        { file: val, fileType: '4' },
        {
          onSuccess: (data) => {
            if (data.code === 20000) {
              state.updateElementData(id, 'data.fileID', data.data.fileId);
              state.updateElementData(id, 'data.fileUrl', data.data.fileUrl);
              setTempData({
                ...tempData,
                fileID: data.data.fileId,
                fileUrl: data.data.fileUrl,
              });
            } else {
              Alert.error(t('alert.imageSize'));
            }
          },
        },
      );
    },
    [id, state, t, tempData, uploadImage],
  );

  const handleMouseEnter = useCallback(() => {
    setIsHoverBlock(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHoverBlock(false);
  }, []);

  return (
    <EditorBlockContainer nodeID={id}>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
          setIsHoverBlock={setIsHoverBlock}
        />
      )}
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        color={get(globalTheme, 'palette.grass.600', '')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Block 上方的刪除和 ... 按鈕 */}
        {(isHoverBlock || onFocusID === id) && (
          <EditorFloatPanel
            direction="row"
            backgroundColor="rgba(96, 125, 139, 0.1)"
            handleDelete={onRemoveButtonClick}
          />
        )}

        <NodeHeader
          background={globalTheme.palette?.['grass'][50]}
          color={globalTheme.palette?.['grass'][600]}
          icon={
            <CallSplitIcon
              sx={{
                transform: 'rotateY(180deg)',
              }}
              fontSize="small"
            />
          }
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled>
          <InfoStyled>
            <Typography variant="caption">
              <Trans i18nKey="canvas.shareLink.info" />
            </Typography>
          </InfoStyled>
          <ElementContainerStyled>
            <ImageUploadWrapperStyled>
              <UploadImage
                defaultValue={fileUrl}
                title={t('canvas.shareLink.addImage')}
                info={t('canvas.shareLink.imageFormat')}
                width={IMAGE_WIDTH}
                ratio={IMAGE_RATIO}
                onChange={handleImageChange}
                error={
                  !!getErrorStatus(id, PublishErrorsEnum.SHARE_LINK_IMAGE_EMPTY)
                }
                publishError={
                  !!getErrorStatusAfterPublished(
                    id,
                    PublishErrorsEnum.SHARE_LINK_IMAGE_EMPTY,
                  )
                }
              />
            </ImageUploadWrapperStyled>
            <Textarea
              defaultValue={title}
              placeholder={t('canvas.shareLink.addTitle')}
              limit={80}
              error={
                !!getErrorStatus(id, PublishErrorsEnum.SHARE_LINK_TITLE_EMPTY)
              }
              publishError={
                !!getErrorStatusAfterPublished(
                  id,
                  PublishErrorsEnum.SHARE_LINK_TITLE_EMPTY,
                )
              }
              onChange={handleChange}
              labelItem={labelList.filter(
                (item: GetEditorLabelItemLabelType) =>
                  item.type === 1 || item.type === 2,
              )}
              allowEnter={false}
            />
            <NodeShareLinkUrlButton
              id={id}
              defaultValue={buttonText}
              onChange={handleButtonTextChange}
            />
          </ElementContainerStyled>
        </BodyStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeShareLink);
