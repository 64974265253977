import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NestedMenuItem } from './nested-menu-item';

interface NestedMenuSubItemType {
  id?: string;
  label: string;
  value: string;
  onClick: () => void;
  isSelected?: boolean;
}

interface NestedMenuItemType {
  label: string;
  subItemList: NestedMenuSubItemType[];
  onClick?: () => void;
}

export interface NestedMenuProps {
  anchorEl: Element | null;
  open: boolean;
  itemList: NestedMenuItemType[];
  setAnchorEl: (anchor: Element | null) => void;
}

const MenuStyled = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: 0,
  },
  '.MuiMenu-list': {
    padding: 0,
    backgroundColor: theme.palette['bluegrey'][900],
    color: theme.palette['bluegrey'][200],
    borderRadius: 0,
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '224px',
  padding: '16px 24px',
  '&:hover': {
    backgroundColor: '#0000001A',
  },
}));

export function NestedMenu({
  anchorEl,
  itemList,
  open,
  setAnchorEl,
}: NestedMenuProps) {
  const handleItemClick = () => {
    setAnchorEl(null);
  };

  return (
    <MenuStyled
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={open}
      onClose={() => setAnchorEl(null)}
    >
      {itemList.map((item, index) => {
        if (item.subItemList.length > 0) {
          return (
            <NestedMenuItem
              key={index}
              label={item.label}
              parentMenuOpen={open}
              onClick={handleItemClick}
              rightIcon={<KeyboardArrowRightIcon />}
            >
              {item.subItemList.map((subItem, subIndex: number) => (
                <MenuItemStyled
                  id={subItem.id}
                  key={`${index}_${subIndex}`}
                  onClick={() => {
                    handleItemClick();
                    subItem.onClick();
                  }}
                >
                  {subItem.label}
                  {subItem.isSelected && (
                    <CheckIcon sx={{ color: 'bluegrey.100' }} />
                  )}
                </MenuItemStyled>
              ))}
            </NestedMenuItem>
          );
        } else {
          return (
            <MenuItemStyled
              key={index}
              onClick={() => {
                handleItemClick();
                // 有傳入 onClick function 才執行
                item?.onClick?.();
              }}
            >
              {item.label}
            </MenuItemStyled>
          );
        }
      })}
    </MenuStyled>
  );
}

export default NestedMenu;
