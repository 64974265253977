import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_PROFILE } from '../apis';

interface Props {
  language?: string;
  tutorial?: boolean;
}

async function patchAccountInfo(props: Props) {
  return axios.patch(`${ACCOUNT_PROFILE}`, props).then((res) => res.data);
}

export function usePatchAccountInfo() {
  return useMutation({
    mutationKey: ['patch-account-info'],
    mutationFn: (props: Props) => patchAccountInfo(props),
    onSuccess: (res, props) => {
      queryClient.invalidateQueries(['get-account-info']);
      queryClient.setQueriesData(['get-account-info'], {
        ...queryClient.getQueryData(['get-account-info']),
        ...props,
      });
    },
  });
}
