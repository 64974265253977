import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchContactTagRequestType } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS_TAG } from '../apis';

async function patchContactTag(
  props: PatchContactTagRequestType,
): Promise<PatchContactTagRequestType> {
  return axios
    .patch(`${CONTACTS_TAG}`, props)
    .then((res) => res.data)
    .catch((err) => {
      return {
        code: 400,
        msg: err,
      };
    });
}

export function usePatchContactTag() {
  return useMutation({
    mutationKey: ['patch-contact-tag'],
    mutationFn: (props: PatchContactTagRequestType) => patchContactTag(props),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([
        'get-contacts',
        { contactsID: variables.contactsId },
      ]);
    },
  });
}

export default usePatchContactTag;
