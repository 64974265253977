import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostPublishFlowResponse,
  PublishFlows,
  ResponseWithData,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_PUBLISH } from '../apis';

async function postEditorFlowPublish(
  chart: PublishFlows,
  projectID: string,
): Promise<ResponseWithData<PostPublishFlowResponse>> {
  return axios
    .post(`${FLOW_PUBLISH}?projectId=${projectID}`, chart)
    .then((res) => res.data);
}

export function usePostEditorFlowPublish(
  projectID: string,
  options?: UseApi<ResponseWithData<PostPublishFlowResponse>, PublishFlows>,
) {
  return useMutation({
    mutationKey: ['publish-flow'],
    mutationFn: (flows: PublishFlows) =>
      postEditorFlowPublish(flows, projectID),
    onSuccess: (
      data: ResponseWithData<PostPublishFlowResponse>,
      variables: PublishFlows,
    ) => {
      if (data.code === 20000) {
        variables.flows.forEach((f) => {
          if (f) {
            queryClient.refetchQueries([
              'flow',
              { projectID, flowID: f.flowId, isPublished: true },
            ]);
            queryClient.refetchQueries([
              'flow',
              { projectID, flowID: f.flowId, isPublished: false },
            ]);
          }
        });
      }
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
    onError: () => {
      if (options?.onError) options?.onError();
    },
  });
}

export default usePostEditorFlowPublish;
