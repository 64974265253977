import { TourMaskProps, TourTargetType } from '@frontend/components/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import TourHighlight from '../tour-highlight/tour-highlight';
import TourTooltip from '../tour-tooltip/tour-tooltip';

const MASK_PADDING = 20;

const MaskBlockStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 9998,
  background: 'rgba(0, 0, 0, 0.54)',
}));

const CenterBlockStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: 9999,
  pointerEvents: 'none',
  background: 'transparent',
}));

const getTargetElement = (_target: TourTargetType, selectorTarget?: string) => {
  if (typeof _target === 'string') {
    const target = document.getElementById(_target);
    if (target) {
      return target;
    } else if (selectorTarget) {
      return document.querySelector(`[${selectorTarget}="${_target}"]`);
    } else {
      return null;
    }
  } else {
    return _target.current;
  }
};

export const TourMask: FC<TourMaskProps> = ({
  targets,
  highlight,
  highlightType = 'box',
  onHighlightClick,
  onHighlightHover,
  tooltipProps,
  selectorTarget,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [right, setRight] = useState(0);
  const [bottom, setBottom] = useState(0);

  const onResize = useCallback(() => {
    if (targets.length <= 0) {
      setLeft(0);
      setTop(0);
      setRight(0);
      setBottom(0);
    }

    let _top = Infinity;
    let _left = Infinity;
    let _bottom = -Infinity;
    let _right = -Infinity;

    // 取得包含所有元素的範圍
    targets.forEach((target) => {
      const _target = getTargetElement(target, selectorTarget);

      const rect = _target?.getBoundingClientRect();
      if (rect) {
        _top = Math.min(_top, rect.top);
        _left = Math.min(_left, rect.left);
        _bottom = Math.max(_bottom, rect.bottom);
        _right = Math.max(_right, rect.right);
      }
    });

    // 加上 Padding 範圍
    setLeft(_left - MASK_PADDING);
    setTop(_top - MASK_PADDING);
    setRight(_right + MASK_PADDING);
    setBottom(_bottom + MASK_PADDING);
  }, [selectorTarget, targets]);

  // 縮放 / 滾動時重新計算
  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, [onResize]);

  return (
    <Fragment>
      {/* 上方遮罩 */}
      <MaskBlockStyled
        sx={{
          width: '100vw',
          height: top,
          top: 0,
          left: 0,
        }}
      />
      {/* 左中遮罩 */}
      <MaskBlockStyled
        sx={{
          width: left,
          height: bottom - top,
          top,
          left: 0,
        }}
      />
      {/* 可操作區塊 */}
      <CenterBlockStyled
        ref={ref}
        sx={{
          width: right - left,
          height: bottom - top,
          top,
          left,
        }}
      />
      {/* 右側遮罩 */}
      <MaskBlockStyled
        sx={{
          width: window.innerWidth - right,
          height: bottom - top,
          top,
          left: right,
        }}
      />
      {/* 下方遮罩 */}
      <MaskBlockStyled
        sx={{
          width: '100vw',
          height: window.innerHeight - bottom,
          top: bottom,
          left: 0,
        }}
      />
      {tooltipProps && <TourTooltip target={ref} {...tooltipProps} />}
      {/* 強調特定範圍 */}
      {highlight && (
        <TourHighlight
          target={highlight}
          type={highlightType}
          onClick={onHighlightClick}
          onHover={onHighlightHover}
          selectorTarget={selectorTarget}
        />
      )}
      {targets.length <= 0 && (
        <MaskBlockStyled
          sx={{
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
          }}
        />
      )}
    </Fragment>
  );
};

export default TourMask;
