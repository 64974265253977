import { Box, MenuItem as MuiMenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';
import { FC } from 'react';
import EllipsisMenu from '../../ellipsis-menu/ellipsis-menu';
import Typography from '../../typography/typography';

export interface EllipsisItemProps {
  label: string | JSX.Element;
  onClick: () => void;
  disabled?: boolean;
  color?: string;
}

export interface MenuItemProps {
  content: string | JSX.Element;
  StartIcon?: JSX.Element;
  EndIcon?: JSX.Element;
  selected?: boolean;
  disabled?: boolean;
  ellipsis?: 'none' | 'hover' | 'always';
  items?: EllipsisItemProps[];
  onClick?: () => void;
  color?: string;
  id?: string;
}

const MenuWrapper = styled(MuiMenuItem)`
  #menu-ellipsis {
    opacity: 0;
  }
  &:hover {
    #menu-ellipsis {
      opacity: 1;
    }
  }
`;

export const MenuItem: FC<MenuItemProps> = ({
  content,
  StartIcon,
  EndIcon,
  selected,
  disabled = false,
  items,
  onClick,
  color,
  id,
}) => {
  return (
    <MenuWrapper
      id={id}
      disabled={disabled}
      onClick={onClick}
      selected={selected}
    >
      {StartIcon && <ListItemIcon>{StartIcon}</ListItemIcon>}
      <ListItemText>
        <Typography
          variant="subtitle2"
          fontWeight={400}
          sx={{ ...(color && { color }) }}
        >
          {content}
        </Typography>
      </ListItemText>
      {EndIcon && <ListItemIcon>{EndIcon}</ListItemIcon>}
      {items && items.length > 0 && (
        <Box id="menu-ellipsis" mt={0.5}>
          <EllipsisMenu>
            {items.map((i) => (
              <MuiMenuItem
                key={`menu-ellipsis-item-${i.label}`}
                onClick={i.onClick}
                disabled={i.disabled}
              >
                {i.label}
              </MuiMenuItem>
            ))}
          </EllipsisMenu>
        </Box>
      )}
    </MenuWrapper>
  );
};

export default MenuItem;
