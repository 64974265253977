import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Pagination as MuiPagination,
  PaginationItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export interface PaginationProps {
  count: number;
  onChange: (event: ChangeEvent<unknown>, page: number) => void;
}

const PaginationStyled = styled(MuiPagination)<{ $count: number }>(
  ({ theme, $count }) => ({
    position: 'relative',
    '.MuiPagination-ul': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      // justifyContent: 'space-around',

      'li:first-child': {
        width: '98px',
        marginRight: `calc((50% - (98px * 2  + 14px * ${$count}) * 0.5))`,
      },

      'li:last-child': {
        textAlign: 'right',
        width: '98px',
        marginLeft: `calc((50% - (98px * 2  + 14px * ${$count}) * 0.5))`,
      },
    },
  }),
);

const PaginationItemStyled = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected:not(.MuiPaginationItem-previousNext)': {
    backgroundColor: theme.palette['primary']['main'],
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    minWidth: '8px',
    padding: 0,
  },
  '&:not(.Mui-selected):not(.MuiPaginationItem-previousNext)': {
    backgroundColor: theme.palette['grey']['200'],
    width: '8px',
    height: '8px',
    borderRadius: '4px',
    minWidth: '8px',
    padding: 0,
  },
}));

const IconContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const PreviousIcon = () => {
  const [t] = useTranslation();
  return (
    <IconContainerStyled>
      <KeyboardArrowLeftIcon />
      <Typography variant="buttonSmall">
        {t('components.pagination.prev')}
      </Typography>
    </IconContainerStyled>
  );
};

const NextIcon = () => {
  const [t] = useTranslation();
  return (
    <IconContainerStyled>
      <Typography variant="buttonSmall">
        {t('components.pagination.next')}
      </Typography>
      <KeyboardArrowRightIcon />
    </IconContainerStyled>
  );
};

export function Pagination({ count, onChange }: PaginationProps) {
  return (
    <PaginationStyled
      onChange={onChange}
      count={count}
      $count={count}
      renderItem={(item) => {
        if (item.type === 'previous' || item.type === 'next') {
          return (
            <PaginationItemStyled
              {...item}
              components={{
                previous: PreviousIcon,
                next: NextIcon,
              }}
            />
          );
        }
        return (
          <PaginationItemStyled
            selected={item.selected}
            onClick={item.onClick}
          />
        );
      }}
    />
  );
}

export default Pagination;
