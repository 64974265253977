import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import Typography from '../typography/typography';

export interface VideoModalProps {
  open: boolean;
  title: string;
  content: string;
  videoLink?: string;
  lottieJsonUrl?: string;
  cancelButtonText: string;
  confirmButtonText: string;
  handleClose: () => void;
  onConfirm?: () => void;
}

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  padding: '20px 24px',
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: '0 24px 20px 24px',
}));

export const VideoModal = ({
  open,
  handleClose,
  title,
  content,
  videoLink,
  lottieJsonUrl,
  cancelButtonText,
  confirmButtonText,
  onConfirm,
}: VideoModalProps) => {
  const [videoModalJson, setVideoModalJson] = useState<JSON | null>(null);

  useEffect(() => {
    if (lottieJsonUrl) {
      fetch(lottieJsonUrl).then((res) => {
        res.json().then((json) => {
          setVideoModalJson(json);
        });
      });
    }
  }, [lottieJsonUrl, open]);

  if (!open) return null;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: videoModalJson,
  };

  return (
    <Dialog open maxWidth="s" fullWidth>
      {videoLink && (
        <iframe
          height="330"
          src={videoLink}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      )}
      {lottieJsonUrl && videoModalJson && <Lottie options={defaultOptions} />}
      <DialogTitleStyled>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </DialogTitleStyled>
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>
      <DialogActionsStyled>
        {handleClose && (
          <Button onClick={handleClose}>{cancelButtonText}</Button>
        )}
        <Button onClick={onConfirm} variant="contained">
          {confirmButtonText}
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
};

export default VideoModal;
