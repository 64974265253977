import { globalTheme } from '@frontend/components/external-providers';
import {
  TourHighlightProps,
  TourHighlightType,
  TourTargetType,
} from '@frontend/components/interface';
import { Box, css, keyframes } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';

const circleBreathAnimation = keyframes`
  0% { opacity: 0.45; transform: scale(0.6); }
  50% { opacity: 0.6; transform: scale(0.8); }
  100% { opacity: 0.45; transform: scale(0.6); }
`;

const boxBreathAnimation = keyframes`
  0% { box-shadow: 0px 0px 0px 0px; border-width: 1px; }
  50% { box-shadow: 0px 0px 10px 0px; border-width: 2px; }
  100% { box-shadow: 0px 0px 0px 0px; border-width: 1px; }
`;

const inputBreathAnimation = keyframes`
  0% { box-shadow: 0px 0px 0px 0px; border-width: 1px; }
  50% { box-shadow: 0px 0px 10px 0px; border-width: 2px; }
  100% { box-shadow: 0px 0px 0px 0px; border-width: 1px; }
`;

const buttonBreathAnimation = keyframes`
  0% { box-shadow: 0px 0px 0px 0px; border-width: 1px; }
  50% { box-shadow: 0px 0px 20px 0px; border-width: 2px; }
  100% { box-shadow: 0px 0px 0px 0px; border-width: 1px; }
`;

const dropdownBreathAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1;}
  100% { opacity: 0; }
`;

const animations = {
  button: buttonBreathAnimation,
  input: inputBreathAnimation,
  box: boxBreathAnimation,
  circle: circleBreathAnimation,
  dropdown: dropdownBreathAnimation,
};

const colors = {
  button: get(globalTheme, 'palette.primary.main'),
  input: get(globalTheme, 'palette.error.main'),
  box: get(globalTheme, 'palette.warning.main'),
  circle: get(globalTheme, 'palette.warning.main'),
  dropdown: 'transparent',
};

const backgrounds = {
  button: 'transparent',
  input: 'transparent',
  box: 'transparent',
  circle: get(globalTheme, 'palette.warning.main'),
  dropdown: get(globalTheme, 'palette.background.primary.5'),
};

const HighlightWrapperStyled = styled(Box)<{
  $type: TourHighlightType;
}>(
  ({ $type }) => css`
    position: fixed;
    z-index: 9999;
    cursor: pointer;
    color: ${colors[$type]};
    border-color: ${colors[$type]};
    animation: ${animations[$type]} 1s infinite;
    background: ${backgrounds[$type]};
  `,
);

const getTargetElement = (_target: TourTargetType, selectorTarget?: string) => {
  if (typeof _target === 'string') {
    const target = document.getElementById(_target);
    if (target) {
      return target;
    } else if (selectorTarget) {
      return document.querySelector(`[${selectorTarget}="${_target}"]`);
    } else {
      return null;
    }
  } else {
    return _target.current;
  }
};

export const TourHighlight: FC<TourHighlightProps> = ({
  target,
  type = 'box',
  onClick,
  onHover,
  selectorTarget,
}) => {
  const haveHighlightAction = (target && onClick) || (target && onHover);
  const [highlightSize, setHighlightSize] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });

  const onResize = useCallback(() => {
    // 設定 target 範圍
    if (target) {
      const _target = getTargetElement(target, selectorTarget);
      const rect = _target?.getBoundingClientRect();
      if (rect) {
        setHighlightSize({
          width: rect.width,
          height: rect.height,
          top: rect.top,
          left: rect.left,
        });
      }
    }
  }, [target, selectorTarget]);

  // 縮放 / 滾動時重新計算
  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, [onResize]);

  return (
    <HighlightWrapperStyled
      $type={type}
      sx={{
        width: highlightSize.width,
        height: highlightSize.height,
        top: highlightSize.top,
        left: highlightSize.left,
        borderRadius: type === 'circle' ? '50%' : '4px',
        pointerEvents: haveHighlightAction ? 'auto' : 'none',
      }}
      onClick={onClick}
      onMouseEnter={onHover}
    />
  );
};

export default TourHighlight;
