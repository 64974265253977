import { PAGE_PERMISSION_DENIED } from '@frontend/sorghum/utils';
import axios, { AxiosRequestHeaders } from 'axios';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token') as string;
    const projectID = sessionStorage.getItem('project-id') as string;
    const reqConfig = config;
    reqConfig.headers = {
      'gosky-api-token': token,
      ...(projectID && { 'project-id': projectID }),
    } as AxiosRequestHeaders;

    return reqConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 伺服器統一處理 403 跳轉至沒有專案權限
    if (error.response.status === 403) {
      window.location.href = PAGE_PERMISSION_DENIED;
    }
    return Promise.reject(error);
  },
);
