import { Button } from '@frontend/components/ui';
import { TOUR_ONBOARDING_TEMPLATE_PROPS } from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionAttributeType,
  DrawerTypesEnum,
} from '@frontend/editor/interface';
import { UICtx as SorghumUICtx } from '@frontend/sorghum/external-providers';
import { Box, Skeleton, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import EditorTourSelect from '../editor-tour-select/editor-tour-select';

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '24px',
  gap: '12px',
}));

const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '12px',
}));

const InlineWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  gap: '8px',
}));

export const DrawerTourAction: FC = () => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const UIState = useContext(UICtx);
  const { nextTour } = useContext(SorghumUICtx);
  const elementData = state.getElement(state.onFocusID) as ActionAttributeType;
  const [isCompleted, setIsCompleted] = useState(false);

  const handleApply = useCallback(() => {
    UIState.setDrawerType(DrawerTypesEnum.CLOSE);
    state.updateElementData(
      TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_FIRST_SET_ID,
      'attributeName',
      t('tour.drawer.action.options.attribute.source'),
    );
    state.updateElementData(
      TOUR_ONBOARDING_TEMPLATE_PROPS.ACTION_FIRST_SET_ID,
      'valueName',
      t('tour.drawer.action.options.value.website'),
    );
    nextTour();
  }, [UIState, nextTour, state, t]);

  const attributeOptions = useMemo(
    () => [
      {
        label: t('tour.drawer.action.options.attribute.source'),
        value: 'source',
      },
      {
        label: t('tour.drawer.action.options.attribute.gender'),
        value: 'gender',
      },
      {
        label: t('tour.drawer.action.options.attribute.age'),
        value: 'age',
      },
    ],
    [t],
  );

  const valueOptions = useMemo(
    () => [
      {
        label: t('tour.drawer.action.options.value.website'),
        value: 'website',
      },
      {
        label: t('tour.drawer.action.options.value.store'),
        value: 'store',
      },
    ],
    [t],
  );

  const setAttributeName = useCallback(() => {
    nextTour();
  }, [nextTour]);

  const setAttributeValue = useCallback(() => {
    nextTour();
    setIsCompleted(true);
  }, [nextTour]);

  if (!elementData) return null;

  return (
    <EditorDrawer drawerType={DrawerTypesEnum.ACTION_ATTRIBUTE}>
      <ContainerStyled>
        <Box>
          <Typography
            sx={{
              mb: '12px',
            }}
            variant="h6"
            color="grey.900"
          >
            {t('canvas.action.set.title')}
          </Typography>
        </Box>
        <ContentWrapperStyled>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
          <Typography variant="body2" width="50%">
            <Skeleton />
          </Typography>
          <EditorTourSelect
            id="drawer-tour-action-attribute"
            label={t('canvas.action.drawer.name')}
            options={attributeOptions}
            targetOption={'source'}
            onOpen={nextTour}
            onClose={setAttributeName}
          />
          <InlineWrapperStyled>
            <TextField
              size="small"
              value={t('canvas.action.drawer.is')}
              autoComplete="off"
              disabled
            />
            <EditorTourSelect
              id="drawer-tour-action-value"
              label={t('canvas.action.drawer.value')}
              options={valueOptions}
              targetOption={'website'}
              onOpen={nextTour}
              onClose={setAttributeValue}
            />
          </InlineWrapperStyled>
        </ContentWrapperStyled>

        <Button
          id="drawer-tour-action-apply"
          size="large"
          variant="contained"
          fullWidth
          disabled={!isCompleted}
          onClick={handleApply}
        >
          <Trans i18nKey="common.apply" />
        </Button>
      </ContainerStyled>
    </EditorDrawer>
  );
};

export default memo(DrawerTourAction);
