import {
  AccountResetProps,
  Response,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_RESET } from '../apis';

async function postAccountReset(props: AccountResetProps): Promise<Response> {
  return axios.post(ACCOUNT_RESET, props).then((res) => res.data);
}

export function useAccountResetPassword(options?: UseApi<Response, any>) {
  return useMutation({
    mutationKey: ['account-reset'],
    mutationFn: (props: AccountResetProps) => postAccountReset(props),
    ...options,
  });
}

export default useAccountResetPassword;
