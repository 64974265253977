import { GetAttributeKeyType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ATTRIBUTE } from '../apis';

const defaultData: GetAttributeKeyType[] = [{ id: '', key: '' }];

const getAttributeKey = async (
  projectID: string,
): Promise<GetAttributeKeyType[]> =>
  axios
    .get(`${ATTRIBUTE}?projectId=${projectID}`)
    .then((res) => res.data.data)
    .catch((_) => defaultData);

export function useGetAttributeKey(projectID: string) {
  return useQuery({
    queryKey: ['get-project-attribute-key', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getAttributeKey(projectID);
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetAttributeKey;
