import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import EditorLabel from '../editor-label/editor-label';

export interface NodeHeaderProps {
  color?: string;
  background?: string;
  title?: string;
  icon?: JSX.Element;
  readonly?: boolean;
  onBlur?: (val: string) => void;
}

const HeaderStyled = styled(Box)<{
  $color?: string;
  $background?: string;
  $readonly?: boolean;
}>(({ theme, $color, $background, $readonly }) => ({
  borderRadius: '12px 12px 0 0',
  color: $color,
  background: $background,
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',

  '& > svg': {
    background: $background,
    '& > g > path': {
      fill: $color,
    },
  },
}));

export const NodeHeader: FC<NodeHeaderProps> = ({
  color,
  background,
  title = '',
  icon,
  readonly,
  onBlur,
}: NodeHeaderProps) => {
  return (
    <HeaderStyled $color={color} $background={background} $readonly={readonly}>
      <>
        {icon}
        {readonly ? (
          <Typography sx={{ marginLeft: '8px' }} variant="subtitle1">
            {title}
          </Typography>
        ) : (
          <EditorLabel color={color} onBlur={onBlur} defaultValue={title} />
        )}
      </>
    </HeaderStyled>
  );
};

export default NodeHeader;
