import {
  GetFlowListResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW } from '../apis';

export type sortFlowType = 'title' | 'group' | 'lastModified' | 'status' | '';
export type orderFlowType = 'DESC' | 'ASC' | '1' | '2' | '3' | '';

export type filterFlowType =
  | 'ALL'
  | 'PUBLISH'
  | 'UNPUBLISH'
  | 'CHANGE'
  | 'FACEBOOK'
  | 'INSTAGRAM';

interface GetFlowListProps {
  projectId: string;
  status?: number;
  keyword?: string;
  socialType?: 1 | 2;
  sortBy?: sortFlowType;
  limit?: number;
  orderBy?: orderFlowType;
  offset?: number;
  flowGroupId?: string;
}

async function getFlowList({
  projectId,
  status,
  keyword,
  socialType,
  sortBy,
  limit = 20,
  orderBy,
  offset,
  flowGroupId,
}: GetFlowListProps): Promise<ResponseWithData<GetFlowListResponse>> {
  return axios
    .get(`${FLOW}`, {
      params: {
        projectId,
        ...(keyword && { keyword }),
        ...(socialType && { socialType }),
        ...(sortBy && { sortBy }),
        ...(orderBy && { orderBy }),
        ...(offset && { offset }),
        ...(limit && { limit }),
        ...(flowGroupId && { flowGroupId }),
        ...(status && { status }),
      },
    })
    .then((res) => res.data);
}

export function useFlows({
  projectId,
  searchType,
  keyword,
  flowGroupId,
  limit,
  offset,
  sortBy,
  orderBy,
}: {
  projectId: string;
  searchType?: filterFlowType;
  keyword?: string;
  flowGroupId?: string;
  limit?: number;
  offset?: number;
  sortBy?: sortFlowType;
  orderBy?: orderFlowType;
}) {
  return useQuery({
    queryKey: [
      'flow-list',
      {
        projectId,
        searchType,
        keyword,
        flowGroupId,
        limit,
        offset,
        sortBy,
        orderBy,
      },
    ],
    queryFn: () => {
      if (projectId) {
        switch (searchType) {
          case 'ALL': {
            return getFlowList({
              projectId,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
          case 'INSTAGRAM': {
            return getFlowList({
              projectId,
              socialType: 2,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
          case 'FACEBOOK': {
            return getFlowList({
              projectId,
              socialType: 1,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
          case 'PUBLISH': {
            return getFlowList({
              projectId,
              status: 2,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
          case 'UNPUBLISH': {
            return getFlowList({
              projectId,
              status: 1,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
          case 'CHANGE': {
            return getFlowList({
              projectId,
              status: 3,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
          default: {
            return getFlowList({
              projectId,
              keyword,
              limit,
              flowGroupId,
              offset,
              sortBy,
              orderBy,
            });
          }
        }
      } else {
        return {
          code: 400,
          msg: 'no project id',
          data: {
            limit: '20',
            offset: 0,
            flows: [],
          },
        };
      }
    },
  });
}

export default useFlows;
