import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_DEFAULT_ANSWER } from '../apis';
import {
  UseApi,
  Response,
  PatchEditorDefaultAnswerProps,
} from '@frontend/sorghum/interface';
import { queryClient } from '@frontend/sorghum/external-providers';

async function patchEditorDefaultAnswer(
  projectID: string,
  socialType: number,
  params: PatchEditorDefaultAnswerProps,
): Promise<Response> {
  return axios
    .patch(
      `${EDITOR_DEFAULT_ANSWER}?projectId=${projectID}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorDefaultAnswer(
  projectID: string,
  socialType: number,
  option?: UseApi<Response, PatchEditorDefaultAnswerProps>,
) {
  return useMutation({
    mutationKey: ['patch-editor-default-answer', { projectID, socialType }],
    mutationFn: (params: PatchEditorDefaultAnswerProps) =>
      patchEditorDefaultAnswer(projectID, socialType, params),
    onSuccess: (res: Response, variables) => {
      queryClient.refetchQueries([
        'get-editor-default-answer',
        { projectID, socialType },
      ]);
      queryClient.invalidateQueries(['get-editor-flow-entry']);
      queryClient.invalidateQueries(['get-editor-entry-status']);
      if (option?.onSuccess) {
        option.onSuccess(res, variables);
      }
    },
  });
}

export default usePatchEditorDefaultAnswer;
