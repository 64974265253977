import { queryClient } from '@frontend/sorghum/external-providers';
import { ResponseWithData, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON } from '../apis';

const defaultData = {
  couponId: '',
  name: '',
};

interface PropsType {
  name: string;
  incentiveName: string;
}

async function postCoupon(
  projectID: string,
  props: PropsType,
): Promise<ResponseWithData<{ couponId: string; name: string }>> {
  return axios
    .post(`${COUPON}?projectId=${projectID}`, {
      name: props.name,
      incentiveName: props.incentiveName,
    })
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostCoupon(
  projectID: string,
  options?: UseApi<
    ResponseWithData<{ couponId: string; name: string }>,
    PropsType
  >,
) {
  return useMutation({
    mutationKey: ['post-coupon'],
    mutationFn: (props: PropsType) =>
      postCoupon(projectID, {
        name: props.name,
        incentiveName: props.incentiveName,
      }),
    onSuccess: (
      data: ResponseWithData<{ couponId: string; name: string }>,
      variables,
    ) => {
      queryClient.invalidateQueries(['get-coupon-list']);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables);
      }
    },
  });
}
