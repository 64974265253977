import { Box } from '@mui/material';
import { FC } from 'react';

const Content: FC = () => {
  return (
    <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
      {/* <FlowSelect socialType={1} /> */}
    </Box>
  );
};

export default Content;
