import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Response } from '@frontend/sorghum/interface';
import { FLOW_GROUP } from '../apis';
import { toast } from 'react-toastify';
import { queryClient } from '@frontend/sorghum/external-providers';

interface Props {
  id: string;
  name: string;
}

async function patchEditorFlowGroup(props: Props): Promise<Response> {
  return axios
    .patch(`${FLOW_GROUP}?flowGroupId=${props.id}`, { name: props.name })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorFlowGroup() {
  return useMutation({
    mutationKey: ['patch-editor-flow-group'],
    mutationFn: (props: Props) => patchEditorFlowGroup(props),
    onSuccess: (data) => {
      if (data.code === 20000) {
        queryClient.refetchQueries(['editor-flow-group']);
        toast.success(data.msg);
      } else {
        toast.warn(data.msg);
      }
    },
  });
}

export default usePatchEditorFlowGroup;
