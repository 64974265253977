import {
  PostAccountTutorialRequestType,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_TUTORIAL } from '../apis';

const defaultData = {} as PostAccountTutorialRequestType;

async function postAccountTutorial(
  props: PostAccountTutorialRequestType,
): Promise<Response> {
  return axios
    .post(ACCOUNT_TUTORIAL, props)
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostAccountTutorial() {
  return useMutation({
    mutationKey: ['post-account-tutorial'],
    mutationFn: (props: PostAccountTutorialRequestType) =>
      postAccountTutorial(props),
  });
}

export default usePostAccountTutorial;
