import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Fade, Modal as MuiModal } from '@mui/material';
import { styled } from '@mui/system';
import { FC, PropsWithChildren } from 'react';
import Typography from '../typography/typography';

/* eslint-disable-next-line */
export interface ModalProps extends PropsWithChildren {
  open: boolean;
  title?: string;
  handleClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  p: '28px',
};

const TitleWrapper = styled(Box)`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

const CloseButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

export const Modal: FC<ModalProps> = ({
  children,
  title,
  open,
  handleClose,
}: ModalProps) => {
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <CloseButtonWrapper onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </CloseButtonWrapper>

          {title && (
            <TitleWrapper>
              <Typography variant="h5">{title}</Typography>
            </TitleWrapper>
          )}
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
