import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';

export const useDebouncedCallback = (
  callback: (val?: any) => void,
  delay: number,
) => {
  const debouncedCallback = useRef(
    debounce(callback, delay, {
      trailing: true,
    }),
  ).current;
  useEffect(() => {
    debouncedCallback.cancel();
  }, [debouncedCallback]);

  return debouncedCallback;
};
