import {
  PostEditorFlowUploadResponse,
  ResponseWithData,
} from '@frontend/editor/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_FLOW_UPLOAD } from '../apis';

interface PostEditorFlowUploadProps {
  file: Blob;
  fileType: string;
}

async function postEditorFlowUpload(
  projectID: string,
  param: PostEditorFlowUploadProps,
): Promise<ResponseWithData<PostEditorFlowUploadResponse>> {
  const formData = new FormData();
  formData.append('file', param.file);
  formData.append('fileType', param.fileType);

  return axios
    .post(`${EDITOR_FLOW_UPLOAD}?projectId=${projectID}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 設定請求標頭的 Content-Type 為 multipart/form-data
      },
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostEditorFlowUpload(projectID: string) {
  return useMutation({
    mutationKey: ['post-editor-flow-upload', { projectID }],
    mutationFn: (param: PostEditorFlowUploadProps) =>
      postEditorFlowUpload(projectID, param),
  });
}

export default usePostEditorFlowUpload;
