import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
  TypographyVariant,
} from '@mui/material';
import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

/* eslint-disable-next-line */
export interface CheckboxProps extends MuiCheckboxProps {
  label?: string;
  variant?: TypographyVariant;
  fontWeight?: number;
  register?: UseFormRegisterReturn;
}

const StyledCheckBox = styled(MuiCheckbox)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const Checkbox: FC<CheckboxProps> = ({
  register,
  label,
  variant = 'h6',
  fontWeight = 400,
  color = 'default',
  size = 'small',
  ...props
}) => {
  return (
    <Box display="inline-flex" alignItems="center" justifyContent="left">
      <StyledCheckBox {...props} {...register} color={color} size={size} />
      <Typography variant={variant} fontWeight={fontWeight} color="grey.700">
        {label}
      </Typography>
    </Box>
  );
};

export default Checkbox;
