import { User, UserContext } from '@frontend/sorghum/interface';
import {
  BREAK_POINT,
  PAGE_EMAIL_VERIFY,
  PAGE_MICRO,
  PAGE_PAGES,
  PAGE_PROJECT_INVITE,
  PAGE_VERIFY_LIMIT,
  PAGE_VERIFY_UNAVAILABLE,
  useRwd,
} from '@frontend/sorghum/utils';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import SmallLogo from 'assets/images/logo.svg';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import LoginPic from '../images/login.png';
import Logo from '../images/logo.png';

const HomePageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',

  [theme.breakpoints.down('md')]: {
    minHeight: '-webkit-fill-available',
    height: '-webkit-fill-available',
  },
}));

const ImageContainerStyled = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  padding: '12px 0',
}));

export function SorghumFeaturesHome() {
  const { pathname } = useLocation();
  const [t] = useTranslation();
  const { breakPoint } = useRwd();
  const [user, setUser] = useState<User>({ email: '', status: 'PENDING' });
  const [descriptionText, setDescriptionText] = useState('');
  const onSetUser = useCallback(
    (newUser: User) => {
      const data = {
        id: newUser.id ? newUser.id : user.id,
        email: newUser.email ? newUser.email : user.email,
        password: newUser.password ? newUser.password : user.password,
        status: newUser.status ? newUser.status : user.status,
        error: newUser.error ? newUser.error : user.error,
      };

      setUser(data);
    },
    [user],
  );

  // 只有特定頁面需要顯示左側插圖底下的文字
  const isShowDescriptionText = (url: string) => {
    switch (url) {
      case PAGE_PROJECT_INVITE:
      case PAGE_VERIFY_UNAVAILABLE:
      case PAGE_EMAIL_VERIFY:
      case PAGE_VERIFY_LIMIT:
        return false;
      default:
        return true;
    }
  };

  const onSetDescriptionText = useCallback(
    (url: string) => {
      switch (url) {
        case PAGE_PAGES:
          setDescriptionText(t('pages.leftDescriptionText'));
          break;
        case PAGE_MICRO:
          setDescriptionText(t('micro.leftDescriptionText'));
          break;
        default:
          setDescriptionText(t('login.leftDescriptionText'));
          break;
      }
    },
    [t],
  );

  useEffect(() => {
    if (!isEmpty(pathname)) {
      onSetDescriptionText(pathname);
    }
  }, [onSetDescriptionText, pathname]);

  return (
    <HomePageContainerStyled>
      <Grid container component="main" sx={{ height: '100%' }}>
        {breakPoint > BREAK_POINT.SM && (
          <Grid item md={4}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: (t) => t.palette.grey[100],
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  left: '40px',
                  top: '40px',
                }}
              >
                <img src={Logo} alt="logo" width="139" />
              </Box>
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    marginBottom: '24px',
                  }}
                >
                  <img src={LoginPic} width="100%" alt="" />
                </Box>
                {isShowDescriptionText(pathname) && (
                  <Box>
                    <Typography
                      textAlign="center"
                      variant="body2"
                      color="text.secondary"
                    >
                      {descriptionText}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid
          item
          sm={12}
          xs={12}
          md={8}
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: breakPoint <= BREAK_POINT.SM ? '100%' : '60%',
              padding: breakPoint <= BREAK_POINT.SM ? '20px' : '0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {breakPoint < BREAK_POINT.MD && (
              <ImageContainerStyled>
                <img src={SmallLogo} alt="small-logo" />
              </ImageContainerStyled>
            )}
            <Outlet context={{ user, setUser: onSetUser }} />
          </Box>
        </Grid>
      </Grid>
    </HomePageContainerStyled>
  );
}

export function useUser() {
  return useOutletContext<UserContext>();
}

export default SorghumFeaturesHome;
