import { EditorCtx } from '@frontend/editor/external-providers';
import { BaseElement } from '@frontend/editor/interface';
import { useContext } from 'react';

export function useCanvasGet() {
  const state = useContext(EditorCtx);

  const getTargetElement = (id: string) => {
    const targetElement = state.getElement(id);

    if (targetElement) {
      return targetElement;
    }

    return {} as BaseElement;
  };

  return { getTargetElement };
}

export default useCanvasGet;
