import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC, memo, useCallback, useState } from 'react';

interface EditorTourSelectItemProps {
  label: string;
  value: string;
}

interface EditorTourSelectProps {
  id?: string;
  label: string;
  options: EditorTourSelectItemProps[];
  // 預期可以選得選項
  targetOption: string;
  onOpen?: () => void;
  onClose?: () => void;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
}));

const OptionsStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '2px 0',
  boxShadow: get(theme, 'shadows.24'),
  borderRadius: '4px',
  width: '100%',
}));

export const EditorTourSelect: FC<EditorTourSelectProps> = ({
  id,
  label,
  targetOption,
  options,
  onOpen,
  onClose,
}: EditorTourSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = useCallback(() => {
    if (!isCompleted && !isOpen) {
      setIsOpen(true);
      onOpen && onOpen();
    }
  }, [isCompleted, isOpen, onOpen]);

  const handleOptionClick = useCallback(
    (value: string) => {
      if (value === targetOption) {
        setInputValue(options.find((i) => i.value === value)?.label || '');
        setIsCompleted(true);
        setIsOpen(false);
        onClose && onClose();
      }
    },
    [onClose, options, targetOption],
  );

  return (
    <ContainerStyled id={`${id}-editor-tour-select`}>
      <TextField
        onFocus={handleOpen}
        label={label}
        size="small"
        fullWidth
        error={!isCompleted}
        value={inputValue}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </InputAdornment>
          ),
        }}
      />
      {isOpen && (
        <OptionsStyled>
          {options.map((option: EditorTourSelectItemProps) => (
            <Typography
              key={option.value}
              id={`${id}-editor-tour-select-item-${option.value}`}
              sx={{
                padding: '6px 16px',
                cursor: 'pointer',
              }}
              variant="body1"
              color="grey.900"
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </Typography>
          ))}
        </OptionsStyled>
      )}
    </ContainerStyled>
  );
};

export default memo(EditorTourSelect);
