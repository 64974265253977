import { Dialog } from '@frontend/components/ui';
import { usePostProject } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezoneOffset = new Date().getTimezoneOffset();

export function CreateProjectModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  const { mutate: createProject } = usePostProject();

  const handleConfirm = useCallback(() => {
    createProject({
      name: t('flows.defaultProjectName'),
      timezone,
      timezoneOffset,
    });
    uiState.closeModal();
  }, [createProject, t, uiState]);

  return (
    <Dialog
      size="xs"
      title={t('flows.modal.createProject')}
      open={uiState.modalType === ModalTypesEnum.CREATE_PROJECT}
      confirmBtnText={t('common.add')}
      closeBtn={false}
      handleClose={() => null}
      handleConfirm={handleConfirm}
    >
      <Box>
        <Typography variant="body1" color="grey.800">
          {t('flows.modal.createProjectInfo')}
        </Typography>
      </Box>
    </Dialog>
  );
}

export default CreateProjectModal;
