import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/system';

/* eslint-disable-next-line */
export interface ButtonProps extends MuiButtonProps {
  dash?: boolean;
  error?: boolean;
}

interface ButtonStyledProps {
  $dash: boolean;
}

const ButtonStyled = styled(MuiButton)<ButtonStyledProps>(
  ({ theme, $dash }) => ({
    ...($dash && {
      borderStyle: 'dashed',
      ':hover': {
        borderStyle: 'dashed',
      },
      ':focus': {
        borderStyle: 'dashed',
      },
    }),
  }),
);

export const Button: FC<ButtonProps> = ({
  children,
  error = false,
  dash = false,
  ...props
}: ButtonProps) => {
  return (
    <ButtonStyled color="error" $dash={dash && !error} {...props}>
      {children}
    </ButtonStyled>
  );
};

export default Button;
