import {
  DashboardEngagementChartData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_ENGAGEMENT_CHART } from '../apis';

// TODO:假資料要拿掉
const defaultValue = [] as DashboardEngagementChartData[];

async function postDataDashboardEngagementChart(
  projectID: string,
  params: PostDataDashboardProps,
): Promise<DashboardEngagementChartData[]> {
  return axios
    .post(`${DASHBOARD_DATA_ENGAGEMENT_CHART}?projectId=${projectID}`, params)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardEngagementChart(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-engagement-chart'],
    mutationFn: (props: PostDataDashboardProps) => {
      return postDataDashboardEngagementChart(projectID, props);
    },
  });
}

export default usePostDataDashboardEngagementChart;
