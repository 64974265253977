import { NodeContainerStyledProps } from '@frontend/editor/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus, $zoom }) => ({
    position: 'relative',
    background: theme.palette['grey'][100],
    borderRadius: '8px',
    border: `2px solid ${
      $focus ? theme.palette['info']['light'] : 'transparent'
    }`,
    padding: theme.spacing(1.5),
    fontSize: `${$zoom ? 28 / $zoom : 28}px`,
  }),
);

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  wordBreak: 'break-word',
  background: theme.palette['grey']['100'],
  paddingLeft: '5px',
  marginBottom: '12px',
}));

export const OptionContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px 8px 8px',
  backgroundColor: theme.palette['grey']['200'],
  marginTop: '12px',
  borderRadius: '4px',
}));

export const HintTextContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: `${theme.palette['warning']['main']}0D`,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  borderRadius: '8px',
  marginTop: '12px',
}));

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const AddOptionButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100px',
  border: `1px dashed ${theme.palette['primary']['main']}`,
  backgroundColor: `${theme.palette['grey']['white']}BF`,
  padding: '8px 12px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: `${theme.palette['background']['black'][5]}`,
  },
}));

export const AllowUserInputButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  '&.inactive': {
    justifyContent: 'flex-end',
  },
}));

export const AllowUserInputButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100px 100px 0 100px',
  border: 0,
  backgroundColor: `${theme.palette['grey']['white']}BF`,
  padding: '8px 12px',
  marginTop: '4px',
  cursor: 'pointer',

  '&.inactive': {
    border: `1px dashed ${theme.palette['secondary']['dark']}`,
  },

  '&:hover': {
    backgroundColor: `${theme.palette['background']['black'][5]}`,
  },
}));

export const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const AttributeContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette['bluegrey']['50'],
  borderRadius: '4px',
  width: '100%',
  padding: '2px 0 2px 8px',
  marginTop: '8px',
}));

export const OptionButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',

  '.button': {
    marginBottom: '4px',
  },
}));

export const FloatPanelContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  left: '-12px',
  paddingRight: '12px',
}));
