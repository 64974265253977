import { GetViewFlowEntryResponse } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_FLOW_ENTRY } from '../apis';

const defaultValue = {
  uniqSent: 0,
  sent: 0,
  uniqEnter: 0,
  uniqEnterP: 0,
  enter: 0,
  enterP: 0,
  uniqInteracted: 0,
  uniqInteractedP: 0,
  interacted: 0,
  interactedP: 0,
  uniqNewRnContacts: 0,
  uniqComments: 0,
  comments: 0,
} as GetViewFlowEntryResponse;

async function getViewFlowEntry(
  projectID: string,
  // 1: all, 2: basic setting, 3: bot-link, 4: share link. 0: broadcast 6: redirect flow, 7: sequence, 8: comment reply
  type: number,
  flowID: string,
  entryID?: string,
): Promise<GetViewFlowEntryResponse> {
  return axios
    .get(DATA_FLOW_ENTRY, {
      params: {
        projectId: projectID,
        type,
        flowId: flowID,
        ...(entryID && { entryId: entryID }),
      },
    })
    .then((res) => res.data.data)
    .catch(() => {
      return defaultValue;
    });
}

export function useGetViewFlowEntry(
  projectID: string,
  type: number,
  flowID: string,
  entryID?: string,
) {
  return useQuery<GetViewFlowEntryResponse>({
    queryKey: ['get-view-flow-entry', { type, flowID, entryID, projectID }],
    queryFn: () => {
      if (type && flowID && projectID) {
        return getViewFlowEntry(projectID, type, flowID, entryID);
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetViewFlowEntry;
