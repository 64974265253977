import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum } from '@frontend/editor/interface';
import { RefObject, useContext, useEffect, useRef } from 'react';
import { Edge, Node } from 'reactflow';

export interface UseCanvasFlowView {
  ref: RefObject<HTMLDivElement>;
  nodes: Node[];
  edges: Edge[];
  drawerType: DrawerTypesEnum;
  setDrawerType: (drawerType: DrawerTypesEnum) => void;
}

export function useCanvasFlowView(): UseCanvasFlowView {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    state.setOnFocusCellID('');
  }, [state]);

  return {
    ref,
    nodes: state.nodes,
    edges: state.edges,
    drawerType: uiState.drawerType,
    setDrawerType: uiState.setDrawerType,
  };
}

export default useCanvasFlowView;
