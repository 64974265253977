import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ProjectLinked } from '@frontend/sorghum/interface';
import { PROJECT_LINKED } from '../apis';

async function getProjectLinked(id: string): Promise<ProjectLinked> {
  return axios.get(`${PROJECT_LINKED}/${id}`).then((res) => res.data.data);
}

export function useProjectLinked(id: string) {
  return useQuery<ProjectLinked | null>({
    queryKey: ['project-linked', { id }],
    queryFn: () => {
      if (id) {
        return getProjectLinked(id);
      } else {
        return null;
      }
    },
  });
}

export default useProjectLinked;
