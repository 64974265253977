import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TextButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  backgroundColor: theme.palette['grey']['white'],
  borderRadius: '12px',
  padding: '12px',
  boxSizing: 'border-box',
}));

export const TextButtonInnerContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette['grey'][50],
  borderRadius: '8px',
  padding: '12px',
}));

export const DisabledTextButtonMaskStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette['grey'][100],
  left: 0,
  top: 0,
  borderRadius: '12px',
  pointerEvents: 'none',
}));
