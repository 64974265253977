import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_PERIOD } from '../apis';

interface Props {
  id: string;
  isAutoSave: boolean;
  periodType: 1 | 2;
  startedAt?: string;
  endedAt?: string;
}

async function patchCommentReplyPeriod(props: Props) {
  return axios
    .patch(`${COMMENT_REPLY_PERIOD}`, props)
    .then((res) => res.data)
    .catch((err) => {
      return {
        code: 400,
        msg: err,
      };
    });
}

export function usePatchCommentReplyPeriod() {
  return useMutation({
    mutationKey: ['patch-comment-reply-period'],
    mutationFn: (props: Props) => patchCommentReplyPeriod(props),
    onSuccess: (_, props: Props) => {
      queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
    },
  });
}
