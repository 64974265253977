import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_INVITATION_CHECK } from '../apis';

interface Props {
  token: string;
}

async function postAccountInvitationCheck(props: Props) {
  return axios
    .post(ACCOUNT_INVITATION_CHECK, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostAccountInvitationCheck() {
  return useMutation({
    mutationKey: ['use-post-account-invitation-check'],
    mutationFn: (props: Props) => postAccountInvitationCheck(props),
  });
}

export default usePostAccountInvitationCheck;
