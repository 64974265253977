import {
  DashboardSourceFlowChartData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_SOURCE_ENTRY_CHART } from '../apis';

const defaultValue = [] as DashboardSourceFlowChartData[];

async function postDataDashboardSourceFlowChart(
  projectID: string,
  params: PostDataDashboardProps,
): Promise<DashboardSourceFlowChartData[]> {
  return axios
    .post(`${DASHBOARD_DATA_SOURCE_ENTRY_CHART}?projectId=${projectID}`, params)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardSourceFlowChart(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-source-flow-chart'],
    mutationFn: (props: PostDataDashboardProps) => {
      return postDataDashboardSourceFlowChart(projectID, props);
    },
  });
}

export default usePostDataDashboardSourceFlowChart;
