import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

const TextFieldStyled = styled(TextField)(({ theme }) => ({}));

export interface SelectProps extends StandardTextFieldProps {
  register?: UseFormRegisterReturn;
}

export const Select: FC<SelectProps> = ({
  value,
  label,
  children,
  register,
  ...props
}: SelectProps) => {
  return (
    <TextFieldStyled
      {...(register && register)}
      {...props}
      select
      label={label}
      variant="outlined"
      value={value}
      SelectProps={{
        IconComponent: KeyboardArrowDownOutlinedIcon,
      }}
    >
      {children}
    </TextFieldStyled>
  );
};

export default Select;
