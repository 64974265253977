import { ExpandPanel } from '@frontend/components/ui';
import { PatchEditorConversationItem } from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Content from '../../lib/content/content';
import Header from '../header/header';

interface QuestionProps {
  data: PatchEditorConversationItem;
  setData: (id: string, title?: string, flowID?: string) => void;
  deleteQuestion: (id: string) => void;
  moveUp?: () => void;
  moveDown?: () => void;
}

type FormValues = {
  title: string;
  flowID: string;
};

const Question: FC<QuestionProps> = ({
  data,
  setData,
  deleteQuestion,
  moveUp,
  moveDown,
}) => {
  const [open, setOpen] = useState(false);
  const methods = useForm<FormValues>({
    defaultValues: {
      title: data.title || '',
      flowID: data.flowID,
    },
  });

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { trigger, watch } = methods;

  // useEffect(() => {
  //   const subscription = watch((value) => {
  //     trigger();
  //     setData(data.id, value.title, value.flowID);
  //     return () => subscription.unsubscribe();
  //   });
  // }, [watch]);

  return (
    <FormProvider {...methods}>
      <form>
        <Box>
          <ExpandPanel
            open={true}
            toggleOpen={toggleOpen}
            header={
              <Header
                onDelete={() => deleteQuestion(data.id)}
                onMoveUp={moveUp}
                onMoveDown={moveDown}
              />
            }
            content={<Content />}
            autoClose={false}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default Question;
