import { Project } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { PROJECT } from '../apis';

const defaultValue = {
  projectId: '',
  projectName: '',
  isLinked: false,
  shortCode: '',
  timezone: 'Asia/Taipei',
  timezoneOffset: 8,
  paymentStatus: 1,
  step: 1,
  status: 1,
  permission: true,
};

async function getProject(id: string): Promise<Project> {
  return axios
    .get(PROJECT, {
      params: {
        ...(id.length > 10 && { projectId: id }),
        ...(id.length <= 10 && { shortCode: id }),
      },
    })
    .then((res) => {
      if (res.data.code === 20000) {
        return res.data.data;
      } else {
        return {
          ...defaultValue,
          permission: false,
        };
      }
    })
    .catch((_) => ({
      ...defaultValue,
      permission: false,
    }));
}

export function useGetProject(id?: string) {
  const { projectID: queryProjectID } = useParams();
  let projectID: string | undefined;

  // 有傳入 id 則用 id 查詢
  if (id) {
    projectID = id;
  } else {
    // 沒有則取用 queryProjectID
    projectID = queryProjectID;
  }

  return useQuery<Project>({
    queryKey: ['get-project', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getProject(projectID);
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetProject;
