import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_FLOW_STATUS } from '../apis';

interface Props {
  flowID: string;
}

async function getEditorFlowStatus(flowID: string): Promise<number> {
  return axios
    .get(`${EDITOR_FLOW_STATUS}?flowId=${flowID}`)
    .then((res) => (res.data.data.status ? res.data.data.status : 2))
    .catch((err) => 1);
}

export function useGetEditorFlowStatus({ flowID }: Props) {
  return useQuery<number>({
    queryKey: ['get-editor-flow-status', { flowID }],
    queryFn: () => {
      if (flowID) {
        return getEditorFlowStatus(flowID);
      } else {
        return -1;
      }
    },
  });
}

export default useGetEditorFlowStatus;
