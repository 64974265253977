import { FLOW_ENTRY_INDEX } from '@frontend/sorghum/data-access';
import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface Props {
  entries: string[];
}

async function patchEditorFlowEntryIndex(
  projectID: string,
  props: Props,
): Promise<Response> {
  return axios
    .patch(`${FLOW_ENTRY_INDEX}?projectId=${projectID}`, props.entries)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorFlowEntryIndex(
  flowID: string,
  socialType: number,
  projectID: string,
  option?: UseApi<Response, Props>,
) {
  return useMutation({
    mutationKey: ['patch-editor-flow-entry-index'],
    mutationFn: (props: Props) => patchEditorFlowEntryIndex(projectID, props),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([
        'get-editor-flow-entry',
        { socialType, flowID, projectID, isPublished: false },
      ]);
      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
  });
}

export default usePatchEditorFlowEntryIndex;
