import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SurveyModalProps {
  surveyID: string;
  handleComplete?: () => void;
  handleLater?: () => void;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '24px',
  bottom: '24px',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette['grey'][200]}`,
  width: '360px',
  zIndex: 99,
  background: theme.palette['grey']['white'],
  maxHeight: 'calc(100vh - 114px)',
  borderRadius: '16px',
  boxShadow: get(theme, 'shadows.20', ''),
  overflow: 'hidden',

  '& > iframe': {
    border: 0,
    width: '100%',
    height: '600px',
  },
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  padding: '24px',
  justifyContent: 'center',
  gap: '24px',
}));

export const SurveyModal: FC<SurveyModalProps> = ({
  surveyID,
  handleComplete,
  handleLater,
}) => {
  const [t] = useTranslation();

  return (
    <ModalContainerStyled>
      <iframe src={`https://www.surveycake.com/s/${surveyID}`} />
      <ButtonWrapperStyled>
        <Button
          onClick={handleComplete}
          size="medium"
          color="primary"
          variant="text"
        >
          {t('flows.modal.complete')}
        </Button>
        <Button
          onClick={handleLater}
          size="medium"
          color="primary"
          variant="text"
        >
          {t('flows.modal.later')}
        </Button>
      </ButtonWrapperStyled>
    </ModalContainerStyled>
  );
};

export default SurveyModal;
