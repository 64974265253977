import {
  Response,
  UseAccountSignupVerifyResponse,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SIGN_UP_VERIFY } from '../apis';

async function getAccountSignupVerify(
  token: string,
): Promise<UseAccountSignupVerifyResponse> {
  return axios
    .get(`${SIGN_UP_VERIFY}?token=${token}`)
    .then((res) => res.data)
    .catch((err) => {
      const res: Response = {
        code: 500,
        msg: err,
      };
      return res;
    });
}

export function useAccountSignupVerify(
  props?: UseApi<UseAccountSignupVerifyResponse, any>,
) {
  return useMutation({
    mutationKey: ['emailSignUp'],
    mutationFn: (token: string) => getAccountSignupVerify(token),
    ...props,
  });
}

export default useAccountSignupVerify;
