import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PEOPLE_TAGS_BY_FREQUENCY } from '../apis';
import { Tag } from '@frontend/sorghum/interface';

async function getTagsByFrequency({
  projectID,
}: {
  projectID: string;
}): Promise<Tag[]> {
  return axios
    .get(PEOPLE_TAGS_BY_FREQUENCY, { params: { projectId: projectID } })
    .then((res) => res.data.data.tags)
    .catch((err) => []);
}

export function useGetTagsByFrequency({ projectID }: { projectID: string }) {
  return useQuery<Tag[]>({
    queryKey: ['get-tags-by-frequency', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getTagsByFrequency({ projectID });
      } else {
        return [];
      }
    },
  });
}

export default useGetTagsByFrequency;
