import {
  Response,
  ResponseWithData,
  SendAccountResetMailResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_RESEND } from '../apis';

interface Props {
  id: string;
  token?: string;
}

async function postAccountResend(
  props: Props,
): Promise<ResponseWithData<SendAccountResetMailResponse>> {
  return axios
    .post(ACCOUNT_RESEND, props)
    .then((res) => res.data)
    .catch((err) => {
      const res: Response = {
        code: 400,
        msg: err.data,
      };
      return res;
    });
}

export function useAccountResend() {
  return useMutation({
    mutationKey: ['account-resend'],
    mutationFn: (props: Props) => postAccountResend(props),
  });
}

export default useAccountResend;
