import { FC } from 'react';
import { Box } from '@mui/material';
import {
  Typography,
  EllipsisMenu,
  MenuItem,
  FormInput,
} from '@frontend/components/ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

interface HeaderProps {
  onDelete: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

const formTitle = 'title';

const Header: FC<HeaderProps> = ({ onDelete, onMoveUp, onMoveDown }) => {
  const [t] = useTranslation();
  const { control } = useFormContext();

  return (
    <Box sx={{ display: 'inline-flex', width: '100%' }}>
      <Box sx={{ ml: 2 }}>
        <FormInput
          limit={20}
          name={formTitle}
          control={control}
          label={formTitle}
          borderType="label"
          rules={{
            required: t(
              'basicSetting.conversationStarter.validate.titleRequired',
            ),
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, ml: 2, color: 'grey.500' }}>
        <Typography>
          <Trans i18nKey="common.times" />
        </Typography>
      </Box>

      <Box sx={{ zIndex: 20 }}>
        <EllipsisMenu>
          <MenuItem
            onClick={onMoveUp}
            disabled={!onMoveUp}
            content={
              <Typography>
                <Trans i18nKey="common.moveUp" />
              </Typography>
            }
          />
          <MenuItem
            onClick={onMoveDown}
            disabled={!onMoveDown}
            content={
              <Typography>
                <Trans i18nKey="common.moveDown" />
              </Typography>
            }
          />
          <MenuItem
            onClick={onDelete}
            content={
              <Typography color="error">
                <Trans i18nKey="common.delete" />
              </Typography>
            }
          />
        </EllipsisMenu>
      </Box>
    </Box>
  );
};

export default Header;
