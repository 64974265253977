import { Box, Typography } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { FC, ReactElement } from 'react';

const StyledNoticeBar = styled(Box)(({ theme }) => ({
  height: '37px',
  width: '100%',
  position: 'fixed',
  zIndex: 15,
}));

const StyledFixedBox = styled(Box)<{ type: 'system' | 'error' | 'info' }>(({ theme, type }) => {
  const styles = getStylesForType(type, theme);
  return {
    background: styles.backgroundColor,
    height: '37px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
  };
});

const StyledMessage = styled(Typography)(({ theme }) => ({
  marginRight: '24px',
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
}));

interface NoticeBarProps {
  message: string | ReactElement;
  linkText?: string;
  onLinkClick?: () => void;
  type?: 'system' | 'error' | 'info';
}

const getStylesForType = (type: 'system' | 'error' | 'info', theme: Theme) => {
  switch (type) {
    case 'system':
      return {
        backgroundColor: theme.palette.warning.light,
        messageColor: theme.palette.grey[700],
        linkColor: theme.palette.info.main,
      };
    case 'error':
      return {
        backgroundColor: theme.palette.error.light,
        messageColor: theme.palette.error.dark,
        linkColor: theme.palette.info.main,
      };
    case 'info':
      return {
        backgroundColor: theme.palette.grey[800],
        messageColor: theme.palette.grey.white,
        linkColor: theme.palette.grey.white,
      };
    default:
      return {
        backgroundColor: theme.palette.warning.light,
        messageColor: theme.palette.grey[700],
        linkColor: theme.palette.info.main,
      };
  }
};

export const NoticeBar: FC<NoticeBarProps> = ({
  message,
  linkText,
  onLinkClick,
  // default
  type = "system"
}: NoticeBarProps) => {
  const theme = useTheme();
  const styles = getStylesForType(type, theme);

  return (
    <StyledNoticeBar>
      <StyledFixedBox type={type}>
        <StyledMessage variant="body2" color={styles.messageColor}>
          {message}
        </StyledMessage>
        {linkText && (
          <StyledLink
            variant="overlineBold"
            color={styles.linkColor}
            onClick={onLinkClick}
          >
            {linkText}
          </StyledLink>
        )}
      </StyledFixedBox>
    </StyledNoticeBar>
  );
};

export default NoticeBar;
