import { Textarea } from '@frontend/components/ui';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import { BaseElement, TextButtonCellType } from '@frontend/editor/interface';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { Box } from '@mui/material';
import { get } from 'lodash';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EditorViewTextButtonCell } from '../editor-view-text-button-cell/editor-view-text-button-cell';

interface EditorViewTextButtonProps {
  element: BaseElement;
}

const BlockTextButtonStyled = styled(Box)(({ theme }) => ({
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  marginTop: theme.spacing(1.5),
  padding: '0 12px 12px 12px',

  '& > div': {
    marginTop: theme.spacing(1.5),
  },
}));

export const EditorViewTextButton = ({
  element,
}: EditorViewTextButtonProps) => {
  const { getTargetElement } = useCanvasGet();
  const { id: flowID } = useParams();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });

  const { data: labelItems } = useGetEditorLabelItems(projectID as string);

  const labelList = get(labelItems, 'data', []);

  return (
    <BlockTextButtonStyled>
      <Textarea
        readonly
        defaultValue={element.label}
        disabled={true}
        labelItem={labelList}
      />
      {element.children.map((cellID: string) => {
        const textButtonCell = getTargetElement(cellID) as TextButtonCellType;
        const cellViewData = get(viewData, `cells.${cellID}.uniqCvr`);

        return (
          <EditorViewTextButtonCell
            key={textButtonCell.id}
            buttonNumber={cellViewData}
            iconType={textButtonCell.buttonType}
            text={textButtonCell.label as string}
            outputID={textButtonCell.outputID}
            targetID={textButtonCell.targetID as string}
            prefixIconColor="info.main"
          />
        );
      })}
    </BlockTextButtonStyled>
  );
};

export default EditorViewTextButton;
