import { Dialog } from '@frontend/components/ui';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface FacebookErrorModalProps {
  open: boolean;
  handleClose: () => void;
}

export const FacebookErrorModal: FC<FacebookErrorModalProps> = ({
  open,
  handleClose,
}) => {
  const [t] = useTranslation();

  return (
    <Dialog
      size="xs"
      title={t('facebook.error.title')}
      open={open}
      handleClose={handleClose}
      closeBtn={false}
      confirmBtnText={t('common.ok')}
      handleConfirm={handleClose}
    >
      <Box>
        <Typography color="grey.800" variant="body1">
          {t('facebook.error.info')}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default FacebookErrorModal;
