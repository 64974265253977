import { useQuery } from '@tanstack/react-query';
import { EDITOR_OPTION_PARAM } from '../apis';

import axios from 'axios';

const defaultData = {
  data: [{ type: '', name: '' }],
};

interface LabelType {
  type: number;
  name: string;
}

async function getEditorLabelItems(projectID: string) {
  return axios
    .get(`${EDITOR_OPTION_PARAM}?projectId=${projectID}&socialType=1`)
    .then((res) => {
      if (res.data?.data) {
        const mappedLabelList: LabelType[] = res.data.data.map(
          (item: LabelType) => ({
            type: item.type,
            name: `{{${item.name}}}`,
          }),
        );

        return { code: 20000, data: mappedLabelList };
      }

      return res.data;
    })
    .catch((err) => {
      return defaultData;
    });
}

export function useGetEditorLabelItems(projectID: string) {
  return useQuery({
    queryKey: ['editor-label-item'],
    queryFn: () => {
      if (projectID) {
        return getEditorLabelItems(projectID);
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetEditorLabelItems;
