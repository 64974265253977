import { FC } from 'react';
import { MenuList, MenuListProps } from '@mui/material';

/* eslint-disable-next-line */
export interface MenuProps extends MenuListProps {}

export const Menu: FC<MenuProps> = ({ children }) => {
  return <MenuList>{children}</MenuList>;
};

export default Menu;
