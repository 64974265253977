import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostCommentAutoReplyActiveResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_ACTIVE } from '../apis';

interface Props {
  id: string;
  enable: boolean;
}

async function postCommentAutoReplyActive(
  props: Props,
): Promise<ResponseWithData<PostCommentAutoReplyActiveResponse>> {
  return axios
    .post(`${COMMENT_REPLY_ACTIVE}`, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
      data: {
        selectPosts: { isComplete: false },
        schedulePeriod: { isComplete: false },
        replyCondition: { isComplete: false, data: [] },
      },
    }));
}

export function usePostCommentAutoReplyActive() {
  return useMutation({
    mutationKey: ['post-comment-auto-reply-active'],
    mutationFn: (props: Props) => postCommentAutoReplyActive(props),
    onSuccess: (_, props: Props) => {
      queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
    },
  });
}

export default usePostCommentAutoReplyActive;
