import { MaterialStyledProps } from '@frontend/components/interface';
import { DateType } from '@frontend/sorghum/interface';
import { dayjs } from '@frontend/sorghum/utils';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {
  Box,
  ClickAwayListener,
  Paper,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface DateRangePickerProps {
  date: DateType;
  setDate: (props: DateType) => void;
  className?: string;
  isFocusPicker?: boolean;
  marginBetween?: number;
  maxDate?: dayjs.Dayjs;
  startDateProps?: TextFieldProps;
  endDateProps?: TextFieldProps;
  setIsFocusPicker?: (isFocus: boolean) => void;
  disabled?: boolean;
}

interface TextFieldStyledProps extends MaterialStyledProps {
  $margin?: number;
}

const DateRangePickerContainerStyled = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 100,
}));

const DateRangePickerStyled = styled(Box)(({ theme }) => ({
  '.rdrDateDisplayWrapper': {
    display: 'none',
  },
  '.rdrInRange': {
    backgroundColor: 'rgba(0, 98, 255, 0.1)',
  },
  '.rdrDay:not(.rdrDayPassive)': {
    '.rdrStartEdge ~ .rdrDayNumber': {
      span: {
        color: 'white',
      },
    },
    '.rdrEndEdge ~ .rdrDayNumber': {
      span: {
        color: 'white',
      },
    },
    '.rdrDayNumber': {
      span: {
        color: theme.palette['grey'][800],
      },
    },
  },
  '.rdrMonth': {
    width: '23em',
  },
}));

const InputContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
}));

const TextFieldStyled = styled(TextField)<TextFieldStyledProps>(
  ({ theme, $margin }) => ({
    ...($margin && { width: `calc(50% - (${$margin}px / 2))` }),
    input: {
      cursor: 'pointer',
    },
  }),
);

const ClearButtonStyled = styled(Box)(({ theme }) => ({
  paddingLeft: '20px',
  paddingBottom: '10px',
  cursor: 'pointer',
  color: theme.palette['primary']['main'],
}));

export function DateRangePicker({
  date,
  setDate,
  className,
  isFocusPicker,
  marginBetween,
  // 限制最晚只能選到哪一天
  maxDate,
  startDateProps,
  endDateProps,
  setIsFocusPicker,
  disabled = false,
}: DateRangePickerProps) {
  const [t] = useTranslation();
  const [isOpenPicker, setIsOpenPicker] = useState(false);

  const DateRangePickerRef = useRef<HTMLDivElement>(null);

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    if (
      DateRangePickerRef &&
      !DateRangePickerRef.current?.contains(e.target as Node)
    ) {
      setIsOpenPicker(false);
    }

    setIsFocusPicker && setIsFocusPicker(false);
  };

  const resetDate = () => {
    setDate({
      startDate: null,
      endDate: null,
    });
  };

  const openStartDataPicker = useCallback(() => {
    if (!startDateProps?.disabled) {
      setIsOpenPicker(true);
    }
    setIsFocusPicker && setIsFocusPicker(true);
  }, [setIsFocusPicker, startDateProps?.disabled]);

  // 從 parent 層控制是否要打開 date picker
  useEffect(() => {
    if (isFocusPicker) {
      setIsOpenPicker(true);
    }
  }, [isFocusPicker]);

  const ranges =
    date.startDate || date.endDate
      ? [
          {
            startDate: date.startDate?.toDate(),
            endDate: date.endDate?.toDate(),
            key: 'selection',
          },
        ]
      : [
          {
            startDate: undefined,
            endDate: new Date(''),
            key: 'selection',
          },
        ];

  return (
    <DateRangePickerStyled ref={DateRangePickerRef} className={className}>
      <InputContainerStyled>
        <TextFieldStyled
          disabled={disabled}
          $margin={marginBetween}
          value={date.startDate?.format('YYYY / MM / DD') || ''}
          size="small"
          sx={{ mr: '8px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayOutlinedIcon
                  sx={{ color: disabled ? 'grey.400' : 'grey.500' }}
                  onClick={(e) => {
                    if (disabled) {
                      e.preventDefault();
                    } else {
                      openStartDataPicker();
                    }
                  }}
                />
              </InputAdornment>
            ),
            readOnly: true,
          }}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            } else {
              openStartDataPicker();
            }
          }}
          {...(startDateProps && startDateProps)}
          focused={isFocusPicker}
        />
        <TextFieldStyled
          disabled={disabled}
          $margin={marginBetween}
          value={date.endDate?.format('YYYY / MM / DD') || ''}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarTodayOutlinedIcon
                  sx={{ color: disabled ? 'grey.400' : 'grey.500' }}
                  onClick={(e) => {
                    if (disabled) {
                      e.preventDefault();
                    } else {
                      setIsOpenPicker(true);
                    }
                  }}
                />
              </InputAdornment>
            ),
            readOnly: true,
          }}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            } else {
              setIsOpenPicker(true);
            }
          }}
          {...(endDateProps && endDateProps)}
        />
      </InputContainerStyled>
      {isOpenPicker && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <DateRangePickerContainerStyled>
            <DateRange
              onChange={(range: RangeKeyDict) => {
                const newRange = range['selection'];
                const _startDate = newRange['startDate']
                  ? dayjs(newRange['startDate'] as Date)
                  : null;

                const _endDate = newRange['endDate']
                  ? dayjs(newRange['endDate'] as Date)
                  : null;

                setDate({
                  startDate: _startDate,
                  endDate: _endDate,
                });
              }}
              moveRangeOnFirstSelection={false}
              ranges={ranges}
              months={2}
              direction="horizontal"
              {...(maxDate && { maxDate: maxDate.toDate() })}
            />
            <ClearButtonStyled onClick={() => resetDate()}>
              <Typography variant="body2">
                {t('components.datePicker.clear')}
              </Typography>
            </ClearButtonStyled>
          </DateRangePickerContainerStyled>
        </ClickAwayListener>
      )}
    </DateRangePickerStyled>
  );
}

export default DateRangePicker;
