import {
  PostCommentReplyResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_DATA } from '../apis';

async function postCommentReplyListData(
  ids: string[],
  channelID?: string,
): Promise<ResponseWithData<PostCommentReplyResponse>> {
  return axios
    .post(COMMENT_REPLY_DATA, {
      projectChannelId: channelID,
      ids,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return {};
    });
}

export function usePostCommentReply({
  ids,
  channelID,
}: {
  ids: string[];
  channelID?: string;
}) {
  return useQuery({
    queryKey: ['post-comment-reply', { channelID, ids }],
    queryFn: () => {
      if (channelID && ids.length > 0) {
        return postCommentReplyListData(ids, channelID);
      } else {
        return {};
      }
    },
  });
}
