import {
  SendAccountResetMailProps,
  SendAccountResetMailResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_RESET_MAIL } from '../apis';

async function postAccountResetMail(
  props: SendAccountResetMailProps,
): Promise<SendAccountResetMailResponse> {
  return axios.post(ACCOUNT_RESET_MAIL, props).then((res) => res.data);
}

export function useSendAccountResetMail() {
  return useMutation({
    mutationKey: ['sendAccountResetMail'],
    mutationFn: (props: SendAccountResetMailProps) =>
      postAccountResetMail(props),
  });
}

export default useSendAccountResetMail;
