import { globalTheme } from '@frontend/components/external-providers';
import { EditorCtx } from '@frontend/editor/external-providers';
import { AutocompleteOptionType } from '@frontend/editor/interface';
import { uuid } from '@frontend/editor/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Autocomplete as MuiAutocomplete,
  Paper,
  PaperProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNull } from 'lodash';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface AutocompleteProps {
  value: string | number | undefined | null;
  error?: string;
  noOptionsText?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  placeholder?: string;
  options: AutocompleteOptionType[];
  customMessage?: JSX.Element;
  outputID?: string;
  // 禁止的字段 regex
  pattern?: RegExp;
  maxLength?: number;
  isShowTooltip?: boolean;
  onChange?: (newValue: AutocompleteOptionType | null) => void;
  onFocus?: () => void;
  addOption?: (label: AutocompleteOptionType) => void;
  restrictList?: string[];
  restrictText?: string;
}

// 客製化 autocomplete 的下拉選單樣式
const CustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

export const EditorAutocomplete: FC<AutocompleteProps> = ({
  value,
  error,
  label,
  required,
  disabled,
  noOptionsText,
  options,
  placeholder,
  customMessage,
  outputID,
  maxLength,
  pattern,
  isShowTooltip = false,
  addOption,
  onChange,
  onFocus,
  restrictList = [],
  restrictText = '',
}: AutocompleteProps) => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const defaultValue = options.find((i) => i.value === value);
  const [inputValue, setInputValue] = useState(defaultValue?.label || '');
  const [selectValue, setSelectValue] = useState<AutocompleteOptionType | null>(
    defaultValue || null,
  );
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleFocus = useCallback(() => {
    setIsFocus(true);
    if (onFocus) onFocus();
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: AutocompleteOptionType | null) => {
      if (newValue && newValue.value) {
        setSelectValue(newValue);
      } else {
        setSelectValue(null);
      }
      onChange && onChange(newValue);
    },
    [onChange],
  );

  const handleInputKeydown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      if (pattern) {
        if (pattern.test(e.key)) {
          e.preventDefault();
        }
      }
    },
    [pattern],
  );

  const handleInputPaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>): void => {
      const pastedText = e.clipboardData.getData('text');
      if (pattern) {
        if (pastedText.match(pattern)) {
          e.preventDefault();
          const patternGlobal = new RegExp(pattern, 'g');
          const cleanedText = pastedText.replace(patternGlobal, '');
          setInputValue(cleanedText);
        }
      }
    },
    [pattern],
  );

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue.label);
      setSelectValue(defaultValue);
    } else {
      setSelectValue(null);
      setInputValue('');
    }
  }, [defaultValue]);

  const onAddOptionClick = useCallback(() => {
    if (addOption) {
      const newID = uuid();
      const newOption = { label: inputValue, value: newID };
      // 新增選項後要選取並關閉選單
      setSelectValue(newOption);
      addOption(newOption);
      setIsOpen(false);
    }
  }, [addOption, inputValue]);

  const handleKeyDownCapure = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  const optionList = useMemo(() => {
    if (addOption && inputValue) {
      // 允許新增輸入的字串
      if (options.find((i) => i.label === inputValue)) {
        return options;
      }
      return [
        {
          label: `+ ${t('components.autocomplete.add')} "${inputValue}"`,
          value: 'add option',
          divider: true,
          onClick: onAddOptionClick,
        },
        ...options,
      ];
    } else {
      return options;
    }
  }, [t, addOption, inputValue, options, onAddOptionClick]);

  return (
    <Box>
      <MuiAutocomplete
        filterOptions={(option, state) => {
          if (restrictList.length > 0) {
            if (state.inputValue && restrictList.includes(state.inputValue)) {
              return [
                {
                  label: restrictText,
                  value: '',
                  disabled: true,
                },
              ];
            }
          }

          return option;
        }}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(
          option: AutocompleteOptionType,
          value: AutocompleteOptionType,
        ) => option.value === value.value}
        value={selectValue}
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'clear') {
            // reset value & remove connect
            setInputValue('');
            setSelectValue(null);
            if (outputID) {
              state.removeConnect(outputID);
            }
          } else {
            setInputValue(newInputValue);
          }
        }}
        // 控制所有下拉選單開啟 / 關閉的時機
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onKeyDown={() => setIsOpen(true)}
        onKeyDownCapture={handleKeyDownCapure}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        disabled={disabled}
        popupIcon={<KeyboardArrowDownIcon />}
        blurOnSelect
        size="small"
        options={optionList}
        getOptionLabel={(option: AutocompleteOptionType) => option.label}
        getOptionDisabled={(option: AutocompleteOptionType) =>
          !!option.disabled
        }
        groupBy={(option) => option.group || ''}
        PaperComponent={CustomPaper}
        renderGroup={(params) => (
          <div
            key={`autocomplete_${params.key}_${params.group}}`}
            style={{
              ...(params.key.toString() !== '0' && {
                borderTop: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
              }),
            }}
          >
            {params.group && (
              <div
                style={{
                  padding: '8px 0',
                }}
              >
                <Typography ml={2} variant="caption" color="bluegrey.400">
                  {params.group}
                </Typography>
              </div>
            )}
            {params.children}
          </div>
        )}
        renderOption={(props, option: AutocompleteOptionType) => (
          <li
            key={`autocomplete_${option.value}_${props.tabIndex}}`}
            {...props}
            style={{
              ...(option.divider && {
                borderBottom: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
              }),
            }}
            {...(option.onClick && {
              onClick: option.onClick,
            })}
          >
            <Typography
              variant="menuItem"
              color="grey.900"
              sx={{ wordBreak: 'break-all' }}
            >
              {option.label}
            </Typography>
          </li>
        )}
        onChange={handleChange}
        renderInput={(params) => {
          if (isShowTooltip && !isNull(params.inputProps.value)) {
            return (
              <Tooltip
                title={params.inputProps.value as string}
                placement="right"
                open={isHover && !isFocus}
              >
                <TextField
                  {...params}
                  inputProps={{
                    onKeyDown: handleInputKeydown,
                    onPaste: handleInputPaste,
                    ...params.inputProps,
                    ...(maxLength && { maxLength: maxLength }),
                  }}
                  required={required}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  variant="outlined"
                  size="small"
                  label={label}
                  disabled={disabled}
                  placeholder={placeholder}
                  fullWidth
                  margin="normal"
                  error={!!error}
                />
              </Tooltip>
            );
          }

          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                onPaste: handleInputPaste,
                ...(maxLength && { maxLength: maxLength }),
              }}
              required={required}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onFocus={handleFocus}
              onBlur={handleBlur}
              variant="outlined"
              size="small"
              label={label}
              disabled={disabled}
              placeholder={placeholder}
              fullWidth
              margin="normal"
              error={!!error}
            />
          );
        }}
      />
      {error && (
        <Box>
          <Typography variant="body2" color="error.main">
            {error}
          </Typography>
        </Box>
      )}
      {customMessage && customMessage}
    </Box>
  );
};

export default EditorAutocomplete;
