import { FacebookPage, ResponseWithData } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_PAGE_LIST } from '../apis';

async function getFacebookPages(
  accessToken?: string,
): Promise<ResponseWithData<FacebookPage[]>> {
  return axios
    .get(ACCOUNT_PAGE_LIST, { params: { accessToken } })
    .then((res) => res.data)
    .catch((err) => ({ code: 400, msg: err, data: [] }));
}

export function useGetFacebookPages(fbToken?: string) {
  return useQuery({
    queryKey: ['facebook-pages', { fbToken }],
    queryFn: async () => {
      const res = await getFacebookPages(fbToken);
      switch (res.code) {
        case 20000: {
          return {
            isAuth: true,
            data: res.data,
          };
        }
        case 40100: {
          return {
            error: 'TOKEN_EXPIRED',
            data: [],
          };
        }
        case 40001: {
          return {
            error: 'API_ERROR',
            data: [],
          };
        }
        default: {
          return {
            isAuth: false,
            data: [],
          };
        }
      }
    },
  });
}

export default useGetFacebookPages;
