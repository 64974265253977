export interface TextButtonCellDataType {
  title: string;
  flowId?: string;
  flowName?: string;
  url?: string;
  size?: number;
  tel?: string;
}

export enum TextButtonCellTypeEnum {
  REDIRECT_BUTTON = 'redirectButton',
  URL_BUTTON = 'urlButton',
  CALL_BUTTON = 'callButton',
}

// FIXME: 不同情境應該要分開？
export enum TextButtonCellErrorEnum {
  EMPTY_URL = 'empty_url',
  WRONG_URL_FORMAT = 'wrong_url_format',
  NO_SELECT_FLOW = 'no_select_flow',
  EMPTY_PHONE = 'empty_phone',
  WRONG_PHONE_FORMAT = 'wrong_phone_format',
  EMPTY_TEXTAREA_TITLE = 'empty_textarea_title',
  CONDITION_EMPTY_PUBLIC_REPLY = 'empty_public_reply',
  EMPTY_CELL_TITLE = 'empty_cell_title',
  EMPTY_KEYWORD_CONDITION = 'empty_keyword_condition',
  EMPTY_KEYWORD = 'empty_keyword',
}

export interface TextButtonCellType {
  cellId: string;
  type: TextButtonCellTypeEnum;
  data: TextButtonCellDataType;
}

export interface TextButtonElementType {
  elementId: string;
  type: string;
  data: {
    text: string;
  };
  cells?: TextButtonCellType[];
}

export interface TextButtonBlockType {
  blockId: string;
  starter: boolean;
  data: {
    title: string;
  };
  elements: TextButtonElementType[];
}
