import { useEffect, useState } from 'react';

export enum BREAK_POINT {
  XL = 1920,
  LG = 1280,
  MD = 1024,
  SM = 0,
}

export const useRwd = () => {
  const [breakPoint, setBreakPoint] = useState<BREAK_POINT>(() => {
    if (window.innerWidth >= BREAK_POINT.XL) {
      return BREAK_POINT.XL;
    } else if (window.innerWidth >= BREAK_POINT.LG) {
      return BREAK_POINT.LG;
    } else if (window.innerWidth >= BREAK_POINT.MD) {
      return BREAK_POINT.MD;
    } else {
      return BREAK_POINT.SM;
    }
  });
  const [screenSize, setScreenSize] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const handleRWD = () => {
    if (window.innerWidth >= BREAK_POINT.XL) {
      setBreakPoint(BREAK_POINT.XL);
    } else if (window.innerWidth >= BREAK_POINT.LG) {
      setBreakPoint(BREAK_POINT.LG);
    } else if (window.innerWidth >= BREAK_POINT.MD) {
      setBreakPoint(BREAK_POINT.MD);
    } else {
      setBreakPoint(BREAK_POINT.SM);
    }

    const newSize = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    setScreenSize(newSize);
  };

  useEffect(() => {
    window.addEventListener('resize', handleRWD);
    handleRWD();

    return () => {
      window.removeEventListener('resize', handleRWD);
    };
  }, []);

  return {
    breakPoint,
    screenSize,
  };
};

export default useRwd;
