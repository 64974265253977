import { OptionTypeV2, PublishedFlowSelect } from '@frontend/components/ui';
import { useCanvasUpdate } from '@frontend/editor/data-access';
import { BaseElement, TextButtonCellType } from '@frontend/editor/interface';

export const FlowSetting = ({
  excludeFlow,
  elementID,
  getTargetElement,
}: {
  excludeFlow: string;
  elementID: string;
  getTargetElement: (id: string) => BaseElement;
}) => {
  const { canvasUpdateData } = useCanvasUpdate();

  const elementData =
    (getTargetElement(elementID) as TextButtonCellType) ||
    ({} as TextButtonCellType);

  const handleChangeFlow = (newValue: OptionTypeV2 | null) => {
    if (newValue) {
      canvasUpdateData(elementID, 'data.flowTitle', newValue.label);
      canvasUpdateData(elementID, 'data.flowID', newValue.value);
    } else {
      canvasUpdateData(elementID, 'data.flowTitle', '');
      canvasUpdateData(elementID, 'data.flowID', '');
    }
  };

  return (
    <PublishedFlowSelect
      onChange={(newValue: OptionTypeV2 | null) => {
        handleChangeFlow(newValue);
      }}
      value={elementData.data.flowID || ''}
      excludeFlow={excludeFlow}
    />
  );
};
