import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, ClickAwayListener, MenuList, Paper, Popper } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import Button from '../button/button';
import MenuItem from '../menu/item/item';

export interface MenuButtonProps {
  menuList: { content: string; onClick: () => void }[];
  buttonText: string;
}

const MenuButtonContainerStyled = styled(Box)(({ theme }) => ({
  '.button-inner-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    svg: {
      width: '16px',
      height: '16px',
      marginLeft: '4px',
    },
  },
}));

export function MenuButton({ menuList, buttonText }: MenuButtonProps) {
  const [bulkButtonAnchorEl, setBulkButtonAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleClickBulkButton = (event: React.MouseEvent<HTMLElement>) => {
    setBulkButtonAnchorEl(event.currentTarget);
  };
  const handleCloseBulkButton = () => {
    setBulkButtonAnchorEl(null);
  };
  const isOpenBulkButtonMenu = Boolean(bulkButtonAnchorEl);

  return (
    <MenuButtonContainerStyled>
      <Button
        variant="outlined"
        color="bluegrey500"
        onClick={handleClickBulkButton}
      >
        <Box className="button-inner-container">
          {buttonText}
          <ArrowDropDownIcon />
        </Box>
      </Button>
      <Popper
        sx={{ zIndex: 10 }}
        placement="bottom-end"
        open={isOpenBulkButtonMenu}
        anchorEl={bulkButtonAnchorEl}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleCloseBulkButton}>
            <MenuList>
              {menuList.map((item, index) => (
                <MenuItem
                  key={index}
                  content={item.content}
                  onClick={item.onClick}
                />
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </MenuButtonContainerStyled>
  );
}

export default MenuButton;
