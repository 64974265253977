import {
  DashboardEngagementData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_ENGAGEMENT } from '../apis';

const defaultValue = {
  uniqNewActiveUsers: 0,
  uniqNewActiveUsersP: 0,
  lastUniqNewActiveUsers: 0,
  uniqNewActiveUsersTrend: 0,
  uniqRetentionUsers: 0,
  uniqRetentionUsersP: 0,
  lastUniqRetentionUsers: 0,
  uniqRetentionUsersTrend: 0,
  uniqSleepUsers: 0,
  uniqSleepUsersP: 0,
  lastUniqSleepUsers: 0,
  uniqSleepUsersTrend: 0,
} as DashboardEngagementData;

async function postDataDashboardEngagementData(
  projectID: string,
  params: PostDataDashboardProps,
): Promise<DashboardEngagementData> {
  return axios
    .post(`${DASHBOARD_DATA_ENGAGEMENT}?projectId=${projectID}`, params)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardEngagementData(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-engagement-chart'],
    mutationFn: (props: PostDataDashboardProps) => {
      return postDataDashboardEngagementData(projectID, props);
    },
  });
}

export default usePostDataDashboardEngagementData;
