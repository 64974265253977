import { Box, Divider } from '@mui/material';
import { styled } from '@mui/system';

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  width: '100%',
}));

export const TitleDescriptionContainer = styled(Box)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '40px',
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  height: '36px',

  '& > button': {
    height: '100%',
  },
}));

export const DragIconStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-25px',
  paddingRight: '15px',
  width: '30px',
  height: '50px',
  cursor: 'pointer',

  '& > svg': {
    position: 'absolute',
    left: '0',
    top: '10px',
  },
}));

export const SortableItemStyled = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  position: 'relative',

  form: {
    width: '100%',
  },
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  marginTop: '20px',
}));

export const FlowItemContainerStyled = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  '.flow-item': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',

    '.flow-title': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      svg: {
        marginRight: '15px',
        cursor: 'pointer',
      },
    },
  },
}));

export const FlowItemStyled = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette['background']['black']['5'],
  },
}));
