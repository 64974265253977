import {
  AutocompleteWithNestedOptionType,
  AutocompleteWithNestedOptions,
  Button,
  CompoundSelector,
  CompoundSelectorItemProps,
} from '@frontend/components/ui';
import {
  useGetCategoryValue,
  useGetProjectID,
  useIsDrawerDataUpdate,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  CategoryValueTypesEnum,
  ConditionCellType,
  DrawerTypesEnum,
  ElementTypesEnum,
  Rules,
} from '@frontend/editor/interface';
import { useGetAttributeKey } from '@frontend/sorghum/data-access';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { isEmpty } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EditorDrawer } from '../editor-drawer/editor-drawer';

const ContainerStyled = styled('div')(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  padding: '24px',
}));

const TopWrapperStyled = styled('div')(({ theme }) => ({}));

export const DrawerCondition = () => {
  const { data: projectID } = useGetProjectID();
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const element = state.getElement(state.onFocusCellID) as ConditionCellType;
  const [categoryType, setCategoryType] = useState<
    CategoryValueTypesEnum | null | undefined | string
  >(null);
  const [attributeValue, setAttributeValue] = useState<string>('');
  const [operator, setOperator] = useState<Rules>(1);
  const [optionValue, setOptionValue] = useState<string>('');

  const { data: attributeKeys } = useGetAttributeKey(projectID as string);

  const useGetCategoryValueType =
    categoryType === CategoryValueTypesEnum.RN_TOPIC ||
    categoryType === CategoryValueTypesEnum.TAG ||
    categoryType === CategoryValueTypesEnum.RN_TITLE
      ? (categoryType as CategoryValueTypesEnum)
      : CategoryValueTypesEnum.ATTRIBUTE;

  const { data: categoryValues } = useGetCategoryValue({
    projectID: projectID as string,
    type: useGetCategoryValueType ?? CategoryValueTypesEnum.ATTRIBUTE,
    ...(attributeValue && { attributeID: attributeValue }),
  });

  const { isDrawerDataUpdate } = useIsDrawerDataUpdate();

  const categoryOption: AutocompleteWithNestedOptionType[] = useMemo(() => {
    const filterOptionList = [
      {
        group: t('canvas.condition.drawer.rn'),
        label: t('canvas.condition.drawer.topic'),
        value: CategoryValueTypesEnum.RN_TOPIC,
      },
      {
        group: t('canvas.condition.drawer.rn'),
        label: t('canvas.condition.drawer.title'),
        value: CategoryValueTypesEnum.RN_TITLE,
      },
      {
        group: t('canvas.condition.drawer.all'),
        label: t('canvas.condition.drawer.userTag'),
        value: CategoryValueTypesEnum.TAG,
      },
    ] as AutocompleteWithNestedOptionType[];

    if (!isEmpty(attributeKeys) && attributeKeys && attributeKeys?.length > 0) {
      attributeKeys.map((item) =>
        filterOptionList.push({
          value: item.id,
          label: item.key,
          group: t('canvas.condition.drawer.attribute'),
        }),
      );
    }
    return filterOptionList;
  }, [attributeKeys, t]);

  const categoryValueOption = useMemo(() => {
    if (categoryType === CategoryValueTypesEnum.RN_TOPIC) {
      return [
        { label: t('canvas.condition.drawer.subscribed'), value: 'subscribed' },
      ];
    } else if (categoryValues) {
      return categoryValues.map((i) => ({
        label: i.value,
        value: i.id,
      }));
    } else {
      return [];
    }
  }, [categoryType, categoryValues, t]);

  const handleApply = useCallback(() => {
    if (!categoryType || !optionValue || !operator) {
      return;
    }
    if (element) {
      uiState.setDrawerType(DrawerTypesEnum.CLOSE);
    }

    switch (categoryType) {
      case CategoryValueTypesEnum.RN_TITLE:
      case CategoryValueTypesEnum.TAG:
      case CategoryValueTypesEnum.RN_TOPIC: {
        state.updateElementData(element.id, 'categoryType', categoryType);
        state.updateElementData(
          element.id,
          'categoryTypeName',
          categoryOption?.find((i) => i.value === categoryType)?.label,
        );
        break;
      }
      default: {
        state.updateElementData(
          element.id,
          'categoryType',
          CategoryValueTypesEnum.ATTRIBUTE,
        );
        state.updateElementData(element.id, 'attributeID', attributeValue);
        state.updateElementData(
          element.id,
          'categoryTypeName',
          categoryOption?.find((i) => i.value === attributeValue)?.label,
        );
      }
    }

    state.updateElementData(element.id, 'rule', operator);
    state.updateElementData(element.id, 'categoryValue', optionValue);
    state.updateElementData(
      element.id,
      'categoryValueName',
      categoryValueOption?.find((i) => i.value === optionValue)?.label,
    );
  }, [
    categoryType,
    optionValue,
    operator,
    element,
    state,
    categoryValueOption,
    uiState,
    categoryOption,
    attributeValue,
  ]);

  const operatorOptions = useMemo(() => {
    return [
      { label: t('canvas.condition.drawer.is'), value: 1 },
      { label: t('canvas.condition.drawer.isNot'), value: 2 },
    ];
  }, [t]);

  const onOptionChange = useCallback(
    (
      _operator: CompoundSelectorItemProps | undefined,
      _option: CompoundSelectorItemProps | undefined,
    ) => {
      setOperator(_operator?.value as number);
      setOptionValue(_option?.value as string);
    },
    [],
  );

  useEffect(() => {
    if (element && uiState.drawerType === DrawerTypesEnum.CONDITION_CELL) {
      if (
        element.categoryType === CategoryValueTypesEnum.ATTRIBUTE &&
        element.attributeID
      ) {
        setCategoryType(element.attributeID);
        if (element.attributeID) {
          setAttributeValue(element.attributeID);
        } else {
          setAttributeValue('');
        }
      } else {
        setCategoryType(element.categoryType);
      }
      setOperator(element.rule);
      setOptionValue(element.categoryValue ? element.categoryValue : '');
    } else {
      setCategoryType(null);
      setAttributeValue('');
      setOperator(1);
      setOptionValue('');
    }
  }, [element, uiState.drawerType]);

  useEffect(() => {
    if (element && uiState.drawerType === DrawerTypesEnum.CONDITION_CELL) {
      if (
        isDrawerDataUpdate(
          { operator, categoryType, optionValue },
          {
            operator: element.rule,
            categoryType: element.categoryType,
            optionValue: element.categoryValue ?? '',
          },
        )
      ) {
        state.setIsDrawerUpdate(true);
      } else {
        state.setIsDrawerUpdate(false);
      }
    }
  }, [
    attributeValue,
    categoryType,
    element,
    operator,
    optionValue,
    state,
    uiState.drawerType,
    isDrawerDataUpdate,
  ]);

  const onCategoryChange = useCallback(
    (_: object, newValue: AutocompleteWithNestedOptionType | null) => {
      if (!newValue) {
        setCategoryType(null);
        setAttributeValue('');
        setOptionValue('');
        return;
      }

      setCategoryType(newValue.value);

      if (
        newValue.value !== CategoryValueTypesEnum.RN_TITLE &&
        newValue.value !== CategoryValueTypesEnum.RN_TOPIC &&
        newValue.value !== CategoryValueTypesEnum.TAG
      ) {
        setAttributeValue(newValue.value ? (newValue.value as string) : '');
      } else {
        setAttributeValue('');
      }

      setOptionValue('');
    },
    [],
  );

  if (element && element.elementType === ElementTypesEnum.CONDITION_CELL) {
    return (
      <EditorDrawer drawerType={DrawerTypesEnum.CONDITION_CELL}>
        <ContainerStyled>
          <TopWrapperStyled>
            <Typography
              sx={{
                mb: '12px',
              }}
              variant="h6"
              color="grey.900"
            >
              <Trans i18nKey="canvas.condition.title" />
            </Typography>
            <Typography
              sx={{
                mb: '24px',
              }}
              variant="subtitle2"
              color="grey.900"
            >
              <Trans i18nKey="canvas.condition.drawer.subtitle" />
            </Typography>
            <AutocompleteWithNestedOptions
              errorMessage={
                !categoryType ? t('canvas.condition.drawer.categoryEmpty') : ''
              }
              label={t('canvas.condition.drawer.category')}
              value={categoryType}
              required
              placeholder={t('canvas.condition.drawer.category')}
              options={categoryOption}
              onChange={onCategoryChange}
              isShowTooltip
            />
            <Box
              sx={{
                mt: '12px',
                width: '100%',
              }}
            >
              <CompoundSelector
                required
                label={t('canvas.condition.drawer.value')}
                disabled={!categoryType}
                operatorValue={operator}
                value={optionValue}
                operatorOptions={operatorOptions}
                valueOptions={categoryValueOption}
                onChange={onOptionChange}
                error={!optionValue}
                helperText={
                  !optionValue ? t('canvas.condition.drawer.valueEmpty') : ''
                }
                isShowTooltip
              />
            </Box>
          </TopWrapperStyled>
          <Button
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={!state.isDrawerUpdate}
          >
            <Trans i18nKey="common.apply" />
          </Button>
        </ContainerStyled>
      </EditorDrawer>
    );
  } else {
    return null;
  }
};

export default memo(DrawerCondition);
