import { ControlProps } from '@frontend/editor/interface';
import { memo } from 'react';
import { Controls } from 'reactflow';

export const Control = ({
  showInteractive = true,
  addBlock,
  onRestore,
  onSave,
}: ControlProps) => {
  return (
    <Controls showInteractive={showInteractive}>
      {/* <ControlButton onClick={addBlock}>
          <PostAdd />
        </ControlButton>
        <ControlButton onClick={onSave}>
          <Save />
        </ControlButton>
        <ControlButton onClick={onRestore}>
          <Restore />
        </ControlButton> */}
    </Controls>
  );
};

export default memo(Control);
