export const authorizations = [
  'public_profile',
  'email',
  'pages_manage_ads',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_messaging',
  'business_management',
  'pages_manage_engagement',
  'catalog_management',
  'instagram_basic',
  'ads_read',
  'ads_management',
  'page_events',
  'pages_show_list',
];
