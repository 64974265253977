import { InputFieldPrefixIcon, Typography } from '@frontend/components/ui';
import {
  useCanvasCollect,
  useCanvasGet,
  useCanvasUpdate,
  useCanvasView,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import {
  PublishErrorsEnum,
  TextButtonCellType,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import {
  Box,
  ClickAwayListener,
  Link,
  StandardTextFieldProps,
  Tooltip,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { isNull } from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Position } from 'reactflow';
import EditorTooltip from '../editor-tooltip/editor-tooltip';
import HandlePoint from '../handle-point/handle-point';
import TextButtonCellModal from '../text-button-cell-modal/text-button-cell-modal';
import {
  FlowNameContainerStyled,
  InputFieldStyled,
  TooltipStyled,
} from './styles';

/* eslint-disable-next-line */
export interface TextButtonCellProps extends StandardTextFieldProps {
  elementID: string;
  isInputFieldHover: boolean;
  setIsHoverBlock: Dispatch<SetStateAction<boolean>>;
  setIsInputFieldHover: Dispatch<SetStateAction<boolean>>;
  innerRef: any;
}

/** text button cell 需考量的情況：
 * 一開始進頁面時 modal 不可以打開
 * 新增 text button cell 時會自動 focus 並打開 modal
 * 一次只會打開一個 modal，開啟下一個 modal 就要關閉上一個
 * modal 點擊外面會自動關閉，modal 內保持可正常切換選項
 */

export function TextButtonCell({
  elementID,
  isInputFieldHover,
  setIsHoverBlock,
  setIsInputFieldHover,
  innerRef,
}: TextButtonCellProps) {
  const [t] = useTranslation();
  const ref = useRef(null);
  const state = useContext(EditorCtx);
  const { navigateToProjectPage } = usePath();

  const { getTargetElement } = useCanvasGet();

  const elementData =
    (getTargetElement(elementID) as TextButtonCellType) ||
    ({} as TextButtonCellType);

  const [anchorEl, setAnchorEl] = useState<
    null | HTMLElement | HTMLInputElement
  >(null);

  const [text, setText] = useState<string>(elementData.label ?? '');
  // 避免 handleClose 重設 focus ID 和 onFocus 設定 ID 會打架，故用 isOpen 和 focus ID 共同判斷是否要打開 modal
  const [isOpen, setIsOpen] = useState<boolean>(
    state.onFocusCellID === elementData.id,
  );
  // 在 on blur 的時候檢查 input 是否要顯示紅框
  const [isInputError, setIsInputError] = useState(false);

  const { canvasUpdateLabel, canvasUpdateData } = useCanvasUpdate();
  const { setAllowCanvasMove } = useCanvasView();
  const { getErrorStatus, getErrorStatusAfterPublished } = useCanvasCollect();

  const handleFocus = useCallback(() => {
    state.setOnFocusCellID(elementData.id);
    setIsOpen(true);

    // 如果 modal 是打開狀態，禁止畫布可以拖曳、縮放
    setAllowCanvasMove(false);
  }, [elementData.id, setAllowCanvasMove, state]);

  const handleClose = () => {
    // 如果 modal 是關閉狀態，恢復畫布可以拖曳、縮放
    setIsOpen(false);
    setAllowCanvasMove(true);
    setIsInputFieldHover(false);
  };

  const onCheckErrorWhenPopupIsClosed = () => {
    // 確保新增的 button (吃不到 onBlur 的觸發) 也會執行驗證
    setIsInputError(
      !!validateTextButtonCell(elementData.id, true, elementData.buttonType),
    );
  };

  const handleClickAway = (e: Event | React.SyntheticEvent) => {
    // 如果點擊同一個 text button cell 則不會觸發關閉
    if (state.onFocusCellID === elementData.id && e.target === anchorEl) {
      return;
    }

    // 關閉的時間不能和點到其他 cell 打開 modal 的行為衝突
    if (isOpen) {
      setAllowCanvasMove(true);
      setIsOpen(false);
    }
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      state.setIsSaving(true);
      canvasUpdateData(elementData.id, 'label', e.target.value);
      setText(e.target.value);
    },
    [canvasUpdateData, elementData.id, state],
  );

  const setEmoji = useCallback(
    (emoji: string) => {
      setText(text + emoji);
      canvasUpdateLabel(elementData.id, text + emoji);
    },
    [canvasUpdateLabel, elementData.id, text],
  );

  const validateTextButtonCell = useCallback(
    (
      elementID: string,
      isBeforePublish: boolean,
      selectedButtonType: TextButtonCellTypesEnum,
    ) => {
      const getErrorStatusFunction = isBeforePublish
        ? getErrorStatus
        : getErrorStatusAfterPublished;

      // text button cell label 不得為空
      if (
        !isNull(
          getErrorStatusFunction(
            elementID,
            PublishErrorsEnum.TEXT_BUTTON_CELL_LABEL_EMPTY,
          ),
        )
      ) {
        return true;
      }

      switch (selectedButtonType) {
        case TextButtonCellTypesEnum.BLOCK:
          return !!getErrorStatusFunction(
            elementID,
            PublishErrorsEnum.TEXT_BUTTON_CELL_BLOCK_NO_TARGET_ID,
          );
        case TextButtonCellTypesEnum.URL:
          return !!getErrorStatusFunction(
            elementID,
            PublishErrorsEnum.TEXT_BUTTON_CELL_URL_EMPTY,
          );
        case TextButtonCellTypesEnum.PHONE:
          return !!getErrorStatusFunction(
            elementID,
            PublishErrorsEnum.TEXT_BUTTON_CELL_PHONE_NO_NUMBER,
          );
        default:
          return false;
      }
    },
    [getErrorStatus, getErrorStatusAfterPublished],
  );

  // text button cell 的字數上限
  const textLimit = 20;

  // 設定打開 modal 的 text button cell 的 DOM
  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
    }
  }, []);

  const publishedInputError = !!validateTextButtonCell(
    elementData.id,
    false,
    elementData.buttonType,
  );

  useEffect(() => {
    setIsInputError(publishedInputError);
  }, [publishedInputError]);

  // 轉換 focus 的 text button cell 時驗證是否顯示紅框
  useEffect(() => {
    setIsInputError(
      !!validateTextButtonCell(elementData.id, true, elementData.buttonType),
    );
  }, [
    elementData.buttonType,
    elementData.id,
    validateTextButtonCell,
    state.onFocusCellID,
  ]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box ref={innerRef}>
        <Box>
          <InputFieldStyled
            className={
              elementData.buttonType === TextButtonCellTypesEnum.FLOW
                ? 'jump-to-flow'
                : ''
            }
            id={elementData.id}
            ref={ref}
            onClick={handleFocus}
            onChange={handleChange}
            value={text}
            inputProps={{ maxLength: textLimit }}
            $error={isInputError}
            onBlur={() => {
              setIsInputError(
                !!validateTextButtonCell(
                  elementData.id,
                  true,
                  elementData.buttonType,
                ),
              );
            }}
            $focus={state.onFocusCellID === elementData.id && isOpen}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InputFieldPrefixIcon
                    btnType={elementData.buttonType}
                    color="info.main"
                  />
                </InputAdornment>
              ),
              ...(elementData.buttonType === TextButtonCellTypesEnum.FLOW && {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      position: 'absolute',
                      left: '54px',
                      bottom: 0,
                      width: 'calc(100% - 69px)',
                    }}
                  >
                    {elementData?.data?.flowTitle ? (
                      <Tooltip
                        title={elementData?.data?.flowTitle}
                        placement="bottom-start"
                      >
                        <FlowNameContainerStyled>
                          <Typography
                            variant="overline"
                            sx={{ cursor: 'pointer' }}
                          >
                            <Link
                              onClick={() =>
                                navigateToProjectPage(
                                  `${PAGE_FLOW}/${elementData?.data?.flowID}`,
                                )
                              }
                            >
                              {elementData?.data?.flowTitle}
                            </Link>
                          </Typography>
                        </FlowNameContainerStyled>
                      </Tooltip>
                    ) : (
                      <Typography
                        variant="overline"
                        sx={{ textTransform: 'capitalize' }}
                        color="grey.400"
                      >
                        {t('components.editableButton.chooseFlowPlaceholder')}
                      </Typography>
                    )}
                  </InputAdornment>
                ),
              }),
            }}
            placeholder={t('components.editableButton.inputPlaceholder')}
          />
          {elementData.buttonType !== TextButtonCellTypesEnum.FLOW && (
            <HandlePoint
              id={elementData.outputID}
              type="source"
              position={Position.Right}
              isConnected={!!elementData.targetID}
              styles={{
                top: '50%',
              }}
              isConnectable={!state.tourMode}
              setIsHoverBlock={setIsHoverBlock}
              isFocus={state.onFocusCellID === elementData.id}
              isGoToBlockCell={
                elementData.buttonType === TextButtonCellTypesEnum.BLOCK
              }
            />
          )}
          {(isInputFieldHover ||
            (state.onFocusCellID === elementData.id && isOpen)) && (
            <TooltipStyled>
              <EditorTooltip
                limit={textLimit}
                length={text.length}
                hasLabel={false}
                setEmoji={setEmoji}
              />
            </TooltipStyled>
          )}
        </Box>
        <TextButtonCellModal
          elementID={elementData.id}
          outputID={elementData.outputID}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleCheckErrorWhenPopupIsClosed={onCheckErrorWhenPopupIsClosed}
          open={
            (isOpen || state.onFocusCellID === elementData.id) && !!anchorEl
          }
        />
      </Box>
    </ClickAwayListener>
  );
}

export default TextButtonCell;
