import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY } from '../apis';

interface Props {
  id: string;
  name: string;
}

async function patchCommentAutoReply(props: Props) {
  return axios
    .patch(`${COMMENT_REPLY}?id=${props.id}`, props)
    .then((res) => res.data);
}

export function usePatchCommentAutoReply() {
  return useMutation({
    mutationKey: ['patch-comment-auto-reply'],
    mutationFn: (props: Props) => patchCommentAutoReply(props),
    onSuccess: (_, props: Props) => {
      queryClient.invalidateQueries(['get-comment-reply-list']);
      queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
    },
  });
}
