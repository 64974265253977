import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_FLOW_SUMMARY } from '../apis';

const defaultData = {
  latest: {
    dateRange: {
      start: '',
      end: '',
    },
    enter: 0,
    interacted: 0,
    uniqEnter: 0,
    uniqInteracted: 0,
    avgConversation: 0,
  },
  past: {
    dateRange: {
      start: '',
      end: '',
    },
    enter: 0,
    interacted: 0,
    uniqEnter: 0,
    uniqInteracted: 0,
    avgConversation: 0,
  },
  enterRate: 0,
  interactedRate: 0,
  uniqEnterRate: 0,
  uniqInteractedRate: 0,
  avgConversationRate: 0,
};

interface getDashboardFlowSummaryProps {
  projectID: string;
  range: number;
  search?: string;
}

async function getDashboardFlowSummary({
  projectID,
  range,
  search,
}: getDashboardFlowSummaryProps) {
  return axios
    .get(
      `${DASHBOARD_FLOW_SUMMARY}?projectId=${projectID}${
        range ? `&range=${range}` : ''
      }${search ? `&search=${search}` : ''}`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetDashboardFlowSummary({
  projectID,
  range,
  search,
}: getDashboardFlowSummaryProps) {
  return useQuery({
    queryKey: ['get-dashboard-flow-summary', { projectID, range, search }],
    queryFn: () => {
      if (projectID) {
        return getDashboardFlowSummary({
          projectID,
          range,
          ...(search && { search }),
        });
      } else {
        return defaultData;
      }
    },
  });
}
