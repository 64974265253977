import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Response } from '@frontend/sorghum/interface';
import { BROADCAST } from '../apis';
import { queryClient } from '@frontend/sorghum/external-providers';

interface Props {
  broadcastID: string;
}

async function deleteBroadcast(
  projectID: string,
  broadcastID: string,
): Promise<Response> {
  return axios
    .delete(`${BROADCAST}?projectId=${projectID}&broadcastId=${broadcastID}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteBroadcast(projectID: string, broadcastID: string) {
  return useMutation({
    mutationKey: ['delete-broadcast'],
    mutationFn: (props: Props) => deleteBroadcast(projectID, props.broadcastID),
    onSuccess: () => {
      queryClient.refetchQueries(['get-broadcast-list']);
    },
  });
}

export default deleteBroadcast;
