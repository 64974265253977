import {
  ResponseWithData,
  WebSocketContext,
  WebSocketEventNameEnum,
  WebSocketResponse,
} from '@frontend/sorghum/interface';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Socket, io } from 'socket.io-client';

interface Props {
  projectID?: string;
}

export const WebSocketCtx = createContext<WebSocketContext>(
  {} as WebSocketContext,
);

const path = '/api/ws';
// node server.js 的測試 port
const uri = window.location.hostname.includes('localhost')
  ? 'localhost:3001'
  : `${window.location.hostname}`;

export const WebSocketProvider: React.FC<PropsWithChildren<Props>> = ({
  projectID,
  children,
}) => {
  const token = localStorage.getItem('token');
  const [ws, setWs] = useState<Socket>();

  const addMessageEventListener = useCallback(
    (
      eventName: WebSocketEventNameEnum,
      callback: (data: ResponseWithData<WebSocketResponse>) => void,
    ) => {
      const eventCallback = (jsonData: ResponseWithData<WebSocketResponse>) => {
        try {
          if (jsonData) {
            if (jsonData.code === 20000) {
              callback(jsonData);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      ws?.on(eventName, eventCallback);
      return eventCallback;
    },
    [ws],
  );

  const removeMessageEventListener = useCallback(
    (
      eventName: WebSocketEventNameEnum,
      callback: (data: ResponseWithData<WebSocketResponse>) => void,
    ) => {
      ws?.removeListener(eventName, callback);
    },
    [ws],
  );

  useEffect(() => {
    if (!token || !projectID) {
      return;
    }
    const socket = io(uri + path, {
      path,
      query: { 'gosky-api-token': token, 'project-id': projectID },
    });
    setWs(socket);
    return () => {
      socket.close();
    };
  }, [projectID, token]);

  useEffect(() => {
    ws?.on('connect', () => {
      if (process.env['NX_ENV'] !== 'PROD') {
        ws?.onAny((eventName, ...args) => {
          console.log(`eventName: ${eventName}`);
          console.log(`props:`, args);
        });
      }
      console.log('WebSocket connected');
    });
    ws?.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    return () => {
      ws?.close();
    };
  }, [ws]);

  const disconnect = useCallback(() => {
    ws?.close();
  }, [ws]);

  return (
    <WebSocketCtx.Provider
      value={{
        ws,
        addMessageEventListener,
        removeMessageEventListener,
        disconnect,
      }}
    >
      {children}
    </WebSocketCtx.Provider>
  );
};
