import { GetAccountInfoResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import i18next from 'i18next';
import { ACCOUNT_INFO } from '../apis';

async function getAccountInfo(): Promise<GetAccountInfoResponseType> {
  return axios
    .get(ACCOUNT_INFO)
    .then((res) => {
      if (res.data.code === 40100) {
        localStorage.removeItem('token');
      }
      if (res.data.data.language) {
        // 在 production 以外的環境可以透過網址上的 lng 參數來變更語系
        const searchParams = new URLSearchParams(window.location.search);
        const paramValue = searchParams.get('lng');

        if (!paramValue || process.env['NX_ENV'] === 'PROD') {
          // 修改語系
          i18next.changeLanguage(res.data.data.language);
        }
      }
      return res.data.data;
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('token');
      }
      return { userName: '', step: '' };
    });
}

export function useGetAccountInfo() {
  return useQuery<
    GetAccountInfoResponseType,
    { code: number; message: string }
  >({
    queryKey: ['get-account-info'],
    queryFn: getAccountInfo,
  });
}

export default useGetAccountInfo;
