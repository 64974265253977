import {
  Dialog,
  Divider,
  Tab,
  TabPanel,
  Tabs,
  Tooltip,
  Typography,
} from '@frontend/components/ui';
import { useGetContacts } from '@frontend/sorghum/data-access';
import { GetContactsResponseType } from '@frontend/sorghum/interface';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PeopleDetailAttributeTab from '../people-detail-attribute-tab/people-detail-attribute-tab';
import PeopleDetailSequenceTab from '../people-detail-sequence-tab/people-detail-sequence-tab';
import PeopleDetailTagTab from '../people-detail-tag-tab/people-detail-tag-tab';

export interface PeopleDetailModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
  contactsID: string;
}

const PeopleDetailModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '500px',

  '.MuiDialogActions-root': {
    display: 'none',
  },
}));

const LeftContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '30%',
  paddingRight: '24px',

  '.avatar-container': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },

  '.profile-info-container': {
    width: '100%',
    marginTop: '28px',
  },
}));

const RightContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  paddingLeft: '24px',
}));

const ProfileInfoContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: '24px 0',

  '.title-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
}));

const ImageContainerStyled = styled(Box)(({ theme }) => ({
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  marginBottom: '12px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}));

const TagStyled = styled(Box)<{ $subscribed: boolean }>(
  ({ theme, $subscribed }) => ({
    borderRadius: '4px',
    width: 'fit-content',
    padding: '2px 8px',
    background:
      theme.palette['background'][$subscribed ? 'primary' : 'bluegrey500'][5],
    border: `1px solid ${
      theme.palette['background'][$subscribed ? 'primary' : 'bluegrey500'][5]
    }`,
  }),
);

const ProfileInfo = ({
  title,
  tooltipText = '',
  content,
  isTagStyle = false,
  $subscribed = false,
}: {
  title: string;
  content: string | number;
  isTagStyle?: boolean;
  tooltipText?: string;
  $subscribed?: boolean;
}) => {
  return (
    <ProfileInfoContainerStyled>
      <Box className="title-container">
        <Typography variant="body3">{title}</Typography>
        {tooltipText && (
          <Tooltip placement="right" title={tooltipText}>
            <InfoIcon
              sx={{
                width: '20px',
                height: '20px',
                color: 'grey.400',
                marginLeft: '4px',
              }}
            />
          </Tooltip>
        )}
      </Box>
      {isTagStyle ? (
        <TagStyled $subscribed={$subscribed}>
          <Typography variant="body3">{content}</Typography>
        </TagStyled>
      ) : (
        <Typography variant="body3">{content}</Typography>
      )}
    </ProfileInfoContainerStyled>
  );
};

export function PeopleDetailModal({
  isOpenModal,
  setIsOpenModal,
  contactsID,
}: PeopleDetailModalProps) {
  const [t] = useTranslation();

  const { data: contactDataFromAPI } = useGetContacts(contactsID);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const contactData = contactDataFromAPI ?? ({} as GetContactsResponseType);

  return (
    <Dialog
      open={isOpenModal}
      size="md"
      handleClose={() => setIsOpenModal(false)}
      closeBtn
    >
      <PeopleDetailModalContainerStyled>
        <LeftContainerStyled>
          <Box className="avatar-container">
            <ImageContainerStyled
              sx={{
                backgroundImage: `url(${contactData.headshot})`,
              }}
            />

            <Typography variant="subtitle1">{contactData.name}</Typography>
          </Box>
          <Box className="profile-info-container">
            <ProfileInfo
              title={t('people.detailModal.userInfo.messengerID')}
              content={contactData.socialId}
            />
            <ProfileInfo
              title={t('people.detailModal.userInfo.subscription')}
              content={
                contactData.notification
                  ? t('people.detailModal.userInfo.subscribeStatusSubscribed')
                  : t('people.detailModal.userInfo.subscribeStatusUnsubscribed')
              }
              isTagStyle
              $subscribed={contactData.notification}
              tooltipText={t('people.detailModal.userInfo.subscriptionText')}
            />
            <ProfileInfo
              title={t('people.detailModal.userInfo.gender')}
              content={
                contactData.gender === 1
                  ? t('people.detailModal.userInfo.male')
                  : t('people.detailModal.userInfo.female')
              }
            />
            <ProfileInfo
              title={t('people.detailModal.userInfo.lastActive')}
              content={
                contactData.lastActive
                  ? dayjs(contactData.lastActive).format('YYYY/MM/DD')
                  : '-'
              }
              tooltipText={t('people.detailModal.userInfo.lastActiveText')}
            />
            <ProfileInfo
              title={t('people.detailModal.userInfo.joined')}
              content={
                contactData.joined
                  ? dayjs(contactData.joined).format('YYYY/MM/DD')
                  : '-'
              }
              tooltipText={t('people.detailModal.userInfo.joinedText')}
            />
            <ProfileInfo
              title={t('people.detailModal.userInfo.userTimezone')}
              content={contactData.timezone ?? 0}
            />
            <ProfileInfo
              title={t('people.detailModal.userInfo.locale')}
              content={contactData.locale ?? '-'}
            />
          </Box>
        </LeftContainerStyled>
        <Divider orientation="vertical" />
        <RightContainerStyled>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label={t('people.detailModal.tabAttributes')} />
            <Tab label={t('people.detailModal.tabTags')} />
            <Tab label={t('people.detailModal.tabFollowUps')} />
          </Tabs>
          <TabPanel index={0} value={selectedTab}>
            <PeopleDetailAttributeTab
              attributeTagList={contactData.attributes}
              contactID={get(contactData, 'id', '')}
              username={contactData.name ?? ''}
            />
          </TabPanel>
          <TabPanel index={1} value={selectedTab}>
            <PeopleDetailTagTab
              tagDataList={contactData.tags}
              contactID={get(contactData, 'id', '')}
              username={contactData.name ?? ''}
            />
          </TabPanel>
          <TabPanel index={2} value={selectedTab}>
            <PeopleDetailSequenceTab sequenceList={contactData.sequences} />
          </TabPanel>
        </RightContainerStyled>
      </PeopleDetailModalContainerStyled>
    </Dialog>
  );
}

export default PeopleDetailModal;
