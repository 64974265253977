import axios from 'axios';
import { ResponseWithData } from '@frontend/sorghum/interface';
import { EDITOR_UNIQUE_NAME } from '../apis';

async function getEditorUniqueName(
  props: Props,
): Promise<ResponseWithData<{ name: string }>> {
  return axios
    .get(EDITOR_UNIQUE_NAME, {
      params: props,
    })
    .then((res) => res.data)
    .catch(() => props.name);
}

interface Props {
  projectId: string;
  type: 'flow' | 'flowGroup';
  name: string;
}

export function useGetEditorUniqueName() {
  return {
    getUniqueName: async (props: Props) => await getEditorUniqueName(props),
  };
}

export default useGetEditorUniqueName;
