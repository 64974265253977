import { Typography } from '@frontend/components/ui';
import { ErrorMessage } from '@hookform/error-message';
import { Box, TextField as MuiTextField } from '@mui/material';
import { FC } from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';

export interface FormInputProps {
  formName: string;
  formError?: FieldErrors;
  register?: UseFormRegisterReturn;
  defaultValue?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  autoFocus?: boolean;
  onBlur?: () => void;
  inputDescription?: string;
  required?: boolean;
}

const FormInputStyled = styled(Box)(({ theme }) => ({
  padding: '8px 0 20px 0',
}));

export const FormInput: FC<FormInputProps> = (props: FormInputProps) => {
  const {
    formName,
    formError,
    defaultValue,
    label,
    register,
    placeholder,
    type,
    autoFocus = false,
    onBlur,
    inputDescription = '',
    required,
  } = props;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <FormInputStyled>
      <Box>
        <MuiTextField
          label={label}
          type={type}
          placeholder={placeholder}
          fullWidth
          inputProps={{ onBlur: onBlur }}
          onKeyDown={handleKeyDown}
          autoComplete="email"
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          size="small"
          error={formError && !!formError[formName]}
          required={required}
          {...register}
        />
        {!formError && inputDescription && (
          <Typography
            variant="h6"
            fontSize={12}
            fontWeight={400}
            letterSpacing="initial"
          >
            {inputDescription}
          </Typography>
        )}
      </Box>
      <ErrorMessage
        errors={formError}
        name={formName}
        render={({ message }) => (
          <Typography variant="body2" color="error.main">
            {message}
          </Typography>
        )}
      />
    </FormInputStyled>
  );
};

export default FormInput;
