import { GoogleSheetOption } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { GOOGLE_SHEET_OPTION } from '../apis';

interface GetGoogleSheetOptionProps {
  projectID: string;
}

async function getGoogleSheetOption({
  projectID,
}: GetGoogleSheetOptionProps): Promise<GoogleSheetOption[]> {
  return axios
    .get(GOOGLE_SHEET_OPTION, {
      params: {
        projectId: projectID,
      },
    })
    .then((res) => res.data.data)
    .catch((err) => []);
}

export function useGetGoogleSheetOption({
  projectID,
}: GetGoogleSheetOptionProps) {
  return useQuery<GoogleSheetOption[]>({
    queryKey: ['get-google-sheet-option', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getGoogleSheetOption({ projectID });
      } else {
        return [];
      }
    },
  });
}

export default useGetGoogleSheetOption;
