import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasGet,
  useCanvasUpdate,
  useCanvasView,
  useModal,
} from '@frontend/editor/data-access';
import {
  ConditionCellType,
  DrawerTypesEnum,
  Rules,
} from '@frontend/editor/interface';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { get } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import {
  BodyStyled,
  CategoryWrapperStyled,
  ItemWrapperStyled,
  TitleWrapperStyled,
} from './styles';

export const NodeCondition = ({ id }: NodeProps) => {
  const [t] = useTranslation();
  const { zoom } = useViewport();
  const { getTargetElement } = useCanvasGet();
  const { canvasUpdateLabel } = useCanvasUpdate();
  const { openDeleteBlockModal } = useModal();
  const { addConditionElse } = useCanvasAdd();
  const { onFocusID, onFocusCellID, focusOnCell, handleDrawer } =
    useCanvasView();
  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);
  const [hoverCell, setHoverCell] = useState<string>('');

  const elementData = getTargetElement(id);
  const header = get(elementData, 'label', '');
  const inputID = get(elementData, 'inputID', '');
  const children = get(elementData, 'children', []) as string[];

  const onRemoveButtonClick = useCallback(() => {
    openDeleteBlockModal(id);
  }, [id, openDeleteBlockModal]);

  const handleMatchClick = useCallback(
    (cellID: string) => {
      focusOnCell(cellID);
      handleDrawer(DrawerTypesEnum.CONDITION_CELL);
    },
    [focusOnCell, handleDrawer],
  );

  const handleAddClick = useCallback(() => {
    addConditionElse(id);
  }, [id, addConditionElse]);

  return (
    <EditorBlockContainer nodeID={id}>
      <HandlePoint
        id={inputID}
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
        setIsHoverBlock={setIsHoverBlock}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        color={get(globalTheme, 'palette.orange.600', '')}
        onMouseEnter={() => setIsHoverBlock(true)}
        onMouseLeave={() => setIsHoverBlock(false)}
      >
        {/* Block 上方的刪除和 ... 按鈕 */}
        {(isHoverBlock || onFocusID === id) && (
          <EditorFloatPanel
            direction="row"
            backgroundColor="rgba(96, 125, 139, 0.1)"
            handleDelete={onRemoveButtonClick}
          />
        )}

        <NodeHeader
          background={get(globalTheme, 'palette.orange.50', '')}
          color={get(globalTheme, 'palette.orange.600', '')}
          icon={<FilterAltIcon fontSize="small" />}
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled>
          {children.map((cellID: string) => {
            const cellData = getTargetElement(cellID) as ConditionCellType;
            switch (cellData.rule) {
              case Rules.IS:
              case Rules.IS_NOT: {
                return (
                  <ItemWrapperStyled
                    key={cellID}
                    onClick={() => handleMatchClick(cellID)}
                    $isFocus={onFocusCellID === cellID}
                    $isHover={hoverCell === cellID}
                    onMouseEnter={() => setHoverCell(cellID)}
                    onMouseLeave={() => setHoverCell('')}
                  >
                    <TitleWrapperStyled>
                      <HandlePoint
                        id={cellData.outputID}
                        type="source"
                        position={Position.Right}
                        styles={{
                          top: '50%',
                        }}
                        isConnected={false}
                        setIsHoverBlock={setIsHoverBlock}
                      />
                      <Typography
                        sx={{ marginRight: '12px' }}
                        variant="subtitle2"
                        color="grey.900"
                      >
                        <Trans i18nKey="canvas.condition.subtitle" />
                      </Typography>
                      {(!cellData.categoryType || !cellData.categoryValue) && (
                        <Tooltip
                          title={t('canvas.condition.tooltipCondition')}
                          placement="top"
                        >
                          <IconButton size="small" color="error">
                            <WarningAmberOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TitleWrapperStyled>

                    {cellData.categoryValue && cellData.categoryTypeName ? (
                      <CategoryWrapperStyled>
                        <Typography variant="caption" color="primary">
                          {cellData.categoryTypeName}
                        </Typography>
                        &nbsp;
                        <Typography variant="caption" color="grey.600">
                          {cellData.rule === Rules.IS ? (
                            <Trans i18nKey="canvas.condition.drawer.is" />
                          ) : (
                            <Trans i18nKey="canvas.condition.drawer.isNot" />
                          )}
                        </Typography>
                        &nbsp;
                        <Typography
                          sx={{
                            fontWeight: 700,
                          }}
                          variant="caption"
                          color="grey.600"
                        >
                          {cellData.categoryValueName}
                        </Typography>
                      </CategoryWrapperStyled>
                    ) : (
                      <CategoryWrapperStyled>
                        <Typography variant="caption" color="bluegrey.400">
                          <Trans i18nKey="canvas.condition.alert" />
                        </Typography>
                      </CategoryWrapperStyled>
                    )}
                  </ItemWrapperStyled>
                );
              }
              case Rules.ELSE: {
                return (
                  <ItemWrapperStyled
                    sx={{
                      mt: '8px',
                    }}
                    key={cellID}
                    $isFocus={onFocusCellID === cellID}
                    $isHover={hoverCell === cellID}
                    onMouseEnter={() => setHoverCell(cellID)}
                    onMouseLeave={() => setHoverCell('')}
                  >
                    <HandlePoint
                      id={cellData.outputID}
                      type="source"
                      position={Position.Right}
                      styles={{
                        top: '50%',
                      }}
                      isConnected={false}
                      setIsHoverBlock={setIsHoverBlock}
                    />
                    <Typography
                      sx={{ marginRight: '12px' }}
                      variant="subtitle2"
                      color="grey.900"
                    >
                      <Trans i18nKey="canvas.condition.notMatch" />
                    </Typography>
                  </ItemWrapperStyled>
                );
              }
              default:
                return null;
            }
          })}
          {children.length <= 1 && (
            <Button
              sx={{ marginTop: '10px' }}
              onClick={() => handleAddClick()}
              startIcon={<AddIcon fontSize="small" />}
              variant="outlined"
              dash
              color="bluegrey300"
              fullWidth
            >
              <Trans i18nKey="canvas.condition.notMatch" />
            </Button>
          )}
        </BodyStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeCondition);
