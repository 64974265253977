import { globalTheme } from '@frontend/components/external-providers';
import { Textarea } from '@frontend/components/ui';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  DataFlowChartBlock,
  NodeContainerStyledProps,
  ShareLinkType,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import EditorViewDataBlock from '../editor-view-data-block/editor-view-data-block';
import EditorViewTextButtonCell from '../editor-view-text-button-cell/editor-view-text-button-cell';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';

const ImageWrapperStyled = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  background: 'white',
  height: '150px',
}));

const BlockDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: '4px',
}));

const ContentStyled = styled(Box)(({ theme }) => ({
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  marginTop: theme.spacing(1.5),
  padding: '12px',
}));

const ImageContentStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  paddingTop: '52.3%',
  borderRadius: '8px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}));

const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus, $zoom }) => ({
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '11px',
    cursor: 'move',
    fontSize: `${$zoom ? 28 / $zoom : 28}px`,
    boxShadow:
      '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
    ...($focus && { border: `2px solid ${theme.palette['grass']['600']}` }),
  }),
);

const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  gap: '10px',
}));

export const ViewNodeShareLink = ({ id }: NodeProps) => {
  const { getTargetElement } = useCanvasGet();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });

  const viewNodeShareLinkData =
    (getTargetElement(id) as ShareLinkType) || ({} as ShareLinkType);
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);

  const {
    label: header,
    inputID,
    cellID: buttonID,
  } = (viewNodeShareLinkData as ShareLinkType) || ({} as ShareLinkType);

  const title = get(viewNodeShareLinkData, 'data.title', '');
  const image = get(viewNodeShareLinkData, 'data.fileUrl', '');
  const buttonText = get(viewNodeShareLinkData, 'data.buttonText', '');
  const labelList = get(labelItems, 'data', []);

  const {
    sent,
    uniqSent,
    opened,
    openedP,
    uniqOpened,
    uniqOpenedP,
    delivered,
    deliveredP,
    uniqDelivered,
    uniqDeliveredP,
    cellButtonClick,
    cellButtonUniqClick,
  } = useMemo(() => {
    const blockData: DataFlowChartBlock = get(viewData, `blocks.${id}`, {});
    const cellButtonClick = get(viewData, `cells.${buttonID}.clicked`);
    const cellButtonUniqClick = get(viewData, `cells.${buttonID}.uniqClicked`);

    return {
      sent: blockData?.sent,
      uniqSent: blockData?.uniqSent,
      uniqClicked: blockData?.uniqClicked,
      uniqClickedP: blockData?.uniqClickedP,
      clicked: blockData?.clicked,
      clickedP: blockData?.clickedP,
      opened: blockData?.opened,
      openedP: blockData?.openedP,
      uniqOpened: blockData?.uniqOpened,
      uniqOpenedP: blockData?.uniqOpenedP,
      uniqDelivered: blockData?.uniqDelivered,
      uniqDeliveredP: blockData?.uniqDeliveredP,
      delivered: blockData?.delivered,
      deliveredP: blockData?.deliveredP,
      cellButtonClick,
      cellButtonUniqClick,
    };
  }, [viewData, id, buttonID]);

  return (
    <Box>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
        />
      )}
      <ContainerStyled>
        <NodeHeader
          color={globalTheme.palette?.['grass'][600] as string}
          background={globalTheme.palette?.['grass'][50]}
          icon={<CallSplitIcon fontSize="small" />}
          title={header}
          readonly
        />

        <BodyStyled>
          <BlockDataWrapperStyled>
            <EditorViewDataBlock
              title={t('view.block.sent')}
              tooltipTitle={t('view.block.sentTooltip')}
              count={sent}
              uniqCount={uniqSent}
            />
            <EditorViewDataBlock
              title={t('view.block.delivered')}
              tooltipTitle={t('view.block.deliveredTooltip')}
              count={delivered}
              percentage={deliveredP}
              tooltipInfo={t('view.tooltip.delivery')}
              uniqCount={uniqDelivered}
              uniqPercentage={uniqDeliveredP}
            />
            <EditorViewDataBlock
              title={t('view.block.opened')}
              tooltipTitle={t('view.block.openedTooltip')}
              count={opened}
              percentage={openedP}
              tooltipInfo={t('view.tooltip.open')}
              uniqCount={uniqOpened}
              uniqPercentage={uniqOpenedP}
            />
          </BlockDataWrapperStyled>
          <ContentStyled>
            {image ? (
              <ImageContentStyled
                sx={{
                  backgroundImage: `url(${image})`,
                }}
              />
            ) : (
              <ImageWrapperStyled />
            )}
            <Textarea
              styles={{
                marginTop: '20px',
                marginBottom: '20px',
              }}
              readonly
              defaultValue={title}
              labelItem={labelList}
            />
            <Box>
              <EditorViewTextButtonCell
                buttonNumber={cellButtonClick}
                buttonNumberType="k"
                iconType={TextButtonCellTypesEnum.URL}
                text={buttonText}
                prefixIconColor="secondary.dark"
                showTooltip
                tooltipTimes={cellButtonClick}
                tooltipUsers={cellButtonUniqClick}
              />
            </Box>
          </ContentStyled>
        </BodyStyled>
      </ContainerStyled>
    </Box>
  );
};

export default memo(ViewNodeShareLink);
