import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface SwitchProps extends MuiSwitchProps {}

export const Switch: FC<SwitchProps> = ({ ...props }: MuiSwitchProps) => {
  return <MuiSwitch {...props} />;
};

Switch.displayName = 'Switch';

export default Switch;
