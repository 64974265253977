import { PropsWithChildren } from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryProps,
  FallbackProps,
} from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

interface UIErrorBoundaryProps
  extends Pick<ErrorBoundaryProps, 'FallbackComponent' | 'onReset'>,
    PropsWithChildren {}

function DefaultFallback({ error, resetErrorBoundary }: FallbackProps) {
  const [t] = useTranslation();
  return (
    <div>
      {error.name}
      {error.message ? ` - ${error.message}` : ''}
      <button aria-label="reload" onClick={resetErrorBoundary}>
        {t('pageNotFound.reload')}
      </button>
    </div>
  );
}

export function UIErrorBoundary({
  children,
  FallbackComponent = DefaultFallback,
  onReset = () => console.log('Try to reset'),
}: UIErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onReset={onReset}>
      {children}
    </ErrorBoundary>
  );
}

export default UIErrorBoundary;
