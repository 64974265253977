export enum TrendingStatusEnum {
  UP = 'up',
  DOWN = 'down',
  TIE = 'tie',
  NULL = 'null',
}

export enum IconSizeType {
  XXS = 'xx-small',
  XS = 'x-small',
  S = 'small',
  M = 'medium',
  L = 'large',
  XL = 'x-large',
}
