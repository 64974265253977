import { GetEditorFlowEntryResponse } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_ENTRY } from '../apis';

const defaultValue = {
  entries: [],
  broadcasts: [],
  redirectFlows: [],
  sequences: [],
  commentReplies: [],
} as GetEditorFlowEntryResponse;

async function getEditorFlowEntry(
  socialType: number,
  flowID: string,
  projectID: string,
  isPublished: boolean,
): Promise<GetEditorFlowEntryResponse> {
  return axios
    .get(FLOW_ENTRY, {
      params: {
        socialType,
        flowId: flowID,
        projectId: projectID,
        isPublished,
      },
    })
    .then((res) => res.data.data)
    .catch(() => {
      return defaultValue;
    });
}

// 查詢 flow entry-point 狀態
export function useGetEditorFlowEntry(
  socialType: number,
  flowID: string,
  projectID: string,
  isPublished: boolean,
) {
  return useQuery<GetEditorFlowEntryResponse>({
    queryKey: [
      'get-editor-flow-entry',
      { socialType, flowID, projectID, isPublished },
    ],
    queryFn: () => {
      if (socialType && projectID && flowID) {
        return getEditorFlowEntry(socialType, flowID, projectID, isPublished);
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetEditorFlowEntry;
