import {
  EntryPointBotLink,
  GetEditorFlowEntryResponse,
  PatchFlowEntryPointProps,
} from '@frontend/editor/interface';
import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_ENTRY } from '../apis';

async function patchEditorFlowEntry(
  projectID: string,
  props: PatchFlowEntryPointProps,
): Promise<Response> {
  return axios
    .patch(`${FLOW_ENTRY}?projectId=${projectID}`, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorFlowEntry(
  flowID: string,
  socialType: number,
  projectID: string,
  option?: UseApi<Response, PatchFlowEntryPointProps>,
) {
  return useMutation({
    mutationKey: ['patch-editor-flow-entry'],
    mutationFn: (props: PatchFlowEntryPointProps) => {
      const flowEntry: GetEditorFlowEntryResponse | undefined =
        queryClient.getQueryData([
          'get-editor-flow-entry',
          { socialType, flowID, projectID, isPublished: false },
        ]);
      const botLink = flowEntry?.entries.find(
        (i) => i.id === props.id,
      ) as EntryPointBotLink;

      return patchEditorFlowEntry(projectID, {
        ...props,
        ref: props.ref ? props.ref : botLink.ref,
        medium: props.medium ? props.medium : botLink.medium,
      });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['get-editor-flow-entry']);
      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
  });
}

export default usePatchEditorFlowEntry;
