import { SequenceResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE_LIST } from '../apis';

const defaultData = {
  offset: 0,
  limit: 0,
  totalCount: 0,
  sequences: [],
};

async function getSequenceList(
  projectID: string,
  offset: number,
  limit: number,
  search: string,
): Promise<SequenceResponseType> {
  return axios
    .get(
      `${SEQUENCE_LIST}?projectId=${projectID}&offset=${offset}&limit=${limit}&search=${search}`,
    )

    .then((res) => res.data.data)
    .catch(() => {
      return defaultData;
    });
}

export function useGetSequenceList(
  projectID: string,
  offset: number,
  limit: number,
  search: string,
) {
  return useQuery<SequenceResponseType>({
    queryKey: ['get-sequence-list', { projectID, offset, limit, search }],
    queryFn: () => {
      if (projectID) {
        return getSequenceList(projectID, offset, limit, search);
      } else {
        return {} as SequenceResponseType;
      }
    },
  });
}

export default useGetSequenceList;
