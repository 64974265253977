import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface ButtonProps extends MuiButtonProps {}

export const Button: FC<ButtonProps> = ({
  children,
  ...props
}: MuiButtonProps) => {
  return <MuiButton {...props}>{children}</MuiButton>;
};

export default Button;
