import { Dialog } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetSequence,
  usePatchSequence,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function RenameSequenceModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  const { data: projectID } = useGetProjectID();
  const { data: sequenceData } = useGetSequence(
    projectID as string,
    uiState.modalType === ModalTypesEnum.RENAME_SEQUENCE
      ? uiState.sequenceID
      : '',
  );

  const [sequenceName, setSequenceName] = useState(sequenceData?.name ?? '');
  const [errorText, setErrorText] = useState('');
  const isOpenModal = uiState.modalType === ModalTypesEnum.RENAME_SEQUENCE;

  const { mutate: updateSequence } = usePatchSequence();

  const validateSequenceName = useCallback(
    (name: string) => {
      if (isEmpty(name)) {
        setErrorText(t('sequence.renameModal.errorEmptyText'));
        return false;
      } else if (name.length > 100) {
        setErrorText(t('sequence.renameModal.errorTextMaxLength'));
        return false;
      }

      return true;
    },
    [t],
  );

  const handleConfirm = () => {
    if (validateSequenceName(sequenceName)) {
      updateSequence(
        {
          projectID: projectID as string,
          sequenceID: uiState.sequenceID,
          data: {
            id: uiState.sequenceID,
            name: sequenceName,
          },
        },
        {
          onSuccess: (data, variables) => {
            if (data.code === 40000) {
              setErrorText(t('sequence.renameModal.errorNameRepeated'));
            } else if (data.code === 20000) {
              setErrorText('');
              uiState.closeModal();
            }
          },
        },
      );
    }
  };

  const handleCloseModal = () => {
    setSequenceName('');
    setErrorText('');
    uiState.closeModal();
  };

  useEffect(() => {
    if (isOpenModal) {
      setSequenceName(uiState.sequenceName);
    }
  }, [isOpenModal, uiState.sequenceName]);

  return (
    <Dialog
      size="xs"
      title={t('common.rename')}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.ok')}
      closeBtn={false}
      open={isOpenModal}
      handleClose={handleCloseModal}
      handleConfirm={handleConfirm}
    >
      <Box>
        <TextField
          fullWidth
          autoFocus
          onChange={(e) => setSequenceName(e.target.value)}
          size="small"
          value={sequenceName}
          label={t('sequence.renameModal.sequenceName')}
          helperText={errorText}
          error={!isEmpty(errorText)}
        />
      </Box>
    </Dialog>
  );
}

export default RenameSequenceModal;
