import {
  BasicSettingProps,
  GetEditorWelcomeResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_WELCOME } from '../apis';

const defaultValue = {
  status: 0,
  greeting: {
    message: '',
    enable: true,
  },
  startFlow: {
    id: '',
    name: '',
    enable: true,
    publishStatus: 1,
  },
  onlineFlow: {
    id: '',
    name: '',
  },
  updatedAt: '',
} as GetEditorWelcomeResponse;

async function getEditorWelcome({
  projectID,
  socialType,
}: BasicSettingProps): Promise<GetEditorWelcomeResponse> {
  return axios
    .get(`${EDITOR_WELCOME}?projectId=${projectID}&socialType=${socialType}`)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function useGetEditorWelcome({
  projectID,
  socialType,
}: BasicSettingProps) {
  return useQuery<GetEditorWelcomeResponse>({
    queryKey: ['get-editor-welcome', { projectID, socialType }],
    queryFn: () => {
      if (projectID) {
        return getEditorWelcome({ projectID, socialType });
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetEditorWelcome;
