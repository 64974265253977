import { Chip, Typography } from '@frontend/components/ui';
import {
  useGetCategoryValue,
  useGetProjectID,
  useGetSequenceOption,
} from '@frontend/editor/data-access';
import {
  BaseElement,
  CategoryValueTypesEnum,
} from '@frontend/editor/interface';
import {
  useGetAttributeKey,
  useGetBroadcastOption,
  useGetCommentReply,
  useGetFlow,
} from '@frontend/sorghum/data-access';
import {
  FilterConditionTypeEnum,
  PeopleTableFilterConditionPropsType,
} from '@frontend/sorghum/interface';
import { convertToUTCString, dayjs } from '@frontend/sorghum/utils';
import { styled } from '@mui/material';
import { get } from 'lodash';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

enum RadioTypeEnum {
  AND = 1,
  OR = 2,
}

interface PeopleFilterChipsProps {
  filterCriteria: RadioTypeEnum;
  conditions: PeopleTableFilterConditionPropsType[];
}

interface CustomChipProps extends PeopleTableFilterConditionPropsType {
  searchKey?: string;
}

const StyledPeopleFilterChips = styled('div')({
  gap: 12,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  overflowY: 'auto',
  maxHeight: '76px',
});

const convertEntryTypeToName = (type: number | null | undefined) => {
  if (!type) return '';

  switch (type) {
    case 1:
      return 'all';
    case 2:
      return 'flow';
    case 3:
      return 'botlink';
    case 4:
      return 'share link';
    case 5:
      return 'broadcast';
    case 6:
      return 'redirect flow';
    case 7:
      return 'sequence';
    case 8:
      return 'comment reply';
    default:
      return '';
  }
};

const CustomChip: FC<CustomChipProps> = ({
  id,
  searchKey,
  searchFilter,
  flowId,
  search,
  operator,
  start,
  end,
  entryType,
}) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: flowData } = useGetFlow(
    flowId as string,
    projectID as string,
    true,
  );
  const { data: attributeData } = useGetAttributeKey(projectID as string);

  const { data: tagValueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.TAG,
  });

  const { data: sequences } = useGetSequenceOption({
    projectID: projectID as string,
  });
  const { data: commentReply } = useGetCommentReply(
    searchFilter === FilterConditionTypeEnum.COMMENT_REPLY
      ? (searchKey as string)
      : '',
  );

  const { data: broadcasts } = useGetBroadcastOption(projectID as string);

  const chipContext = useMemo(() => {
    switch (searchFilter) {
      case FilterConditionTypeEnum.COMMENT_REPLY: {
        return `${t('people.condition.commentReply')} | ${
          commentReply?.name
        } ${t('people.condition.is')} ${search}`;
      }
      case FilterConditionTypeEnum.BROADCAST: {
        return `${t('people.condition.broadcast')} | ${
          broadcasts?.find((i) => i.id === searchKey)?.name
        } ${
          operator === 1
            ? t('people.condition.is')
            : t('people.condition.isNot')
        } ${search}`;
      }
      case FilterConditionTypeEnum.FLOW_BLOCK: {
        const flowDataList: BaseElement[] = get(flowData, 'chart.data', []);
        const blockName =
          flowDataList.filter((item) => item.id === searchKey).length > 0
            ? flowDataList.filter((item) => item.id === searchKey)[0].label
            : '';
        return `${flowData?.name} ${convertEntryTypeToName(
          entryType,
        )} ${blockName} ${t('people.table.is')} ${search}`;
      }
      case FilterConditionTypeEnum.MESSENGER_ID:
      case FilterConditionTypeEnum.NAME: {
        return `${t('people.condition.name')} | ${search}`;
      }
      case FilterConditionTypeEnum.ATTRIBUTE: {
        return `${t('people.condition.attribute')} | ${
          attributeData?.find((i) => i.id === searchKey)?.key
        } ${
          operator === 1
            ? t('people.condition.is')
            : t('people.condition.isNot')
        } ${search}`;
      }
      case FilterConditionTypeEnum.TAG: {
        return `${t('people.condition.tag')} | ${
          operator === 1
            ? t('people.condition.is')
            : t('people.condition.isNot')
        } ${tagValueData?.find((i) => i.id === searchKey)?.value}`;
      }
      case FilterConditionTypeEnum.GENDER: {
        return `${t('people.condition.gender')} | ${t(
          `common.gender.${search}`,
        )}`;
      }
      case FilterConditionTypeEnum.SEQUENCE: {
        return `${t('people.condition.sequence')} | ${
          operator === 1
            ? t('people.condition.is')
            : t('people.condition.isNot')
        } ${sequences?.find((i) => i.id === searchKey)?.name}`;
      }
      case FilterConditionTypeEnum.TIME_ZONE: {
        return `${t('people.condition.timezone')} | UTC ${
          search ? convertToUTCString(search) : ''
        }`;
      }
      case FilterConditionTypeEnum.JOINED: {
        return `${t('people.condition.joined')} | ${dayjs(start).format(
          'YYYY/MM/DD',
        )} - ${dayjs(end).format('YYYY/MM/DD')}`;
      }
      case FilterConditionTypeEnum.LAST_ACTIVE: {
        return `${t('people.condition.lastActive')} | ${dayjs(start).format(
          'YYYY/MM/DD',
        )} - ${dayjs(end).format('YYYY/MM/DD')}`;
      }
      case FilterConditionTypeEnum.LOCALE: {
        return `${t('people.condition.locale')} | ${search}`;
      }
      case FilterConditionTypeEnum.TOPIC: {
        return `${t('people.condition.topic')} | ${search}`;
      }

      default: {
        return '';
      }
    }
  }, [
    attributeData,
    broadcasts,
    commentReply?.name,
    end,
    entryType,
    flowData,
    operator,
    search,
    searchFilter,
    searchKey,
    sequences,
    start,
    t,
    tagValueData,
  ]);

  return (
    <Chip key={id} variant="outlined" color="primary" label={chipContext} />
  );
};

export const PeopleFilterChips: FC<PeopleFilterChipsProps> = ({
  conditions,
  filterCriteria,
}: PeopleFilterChipsProps) => {
  const [t] = useTranslation();
  return (
    <StyledPeopleFilterChips>
      {conditions.map((condition, index) => (
        <Fragment key={condition.id}>
          <CustomChip {...condition} searchKey={condition.key} />
          {index < conditions.length - 1 && (
            <Typography variant="body2" color="grey.700">
              {filterCriteria === RadioTypeEnum.AND
                ? t('common.and')
                : t('common.or')}
            </Typography>
          )}
        </Fragment>
      ))}
    </StyledPeopleFilterChips>
  );
};

export default PeopleFilterChips;
