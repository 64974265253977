import { globalTheme } from '@frontend/components/external-providers';
import { PublishedFlowSelect } from '@frontend/components/ui';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  DataFlowChartTrigger,
  JumpToFlowType,
  NodeContainerStyledProps,
} from '@frontend/editor/interface';
import { useGetFlow } from '@frontend/sorghum/data-access';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import EditorViewDataBlock from '../editor-view-data-block/editor-view-data-block';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';

const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus }) => ({
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '11px',
    cursor: 'move',
    boxShadow:
      '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
    ...($focus && { border: `2px solid ${theme.palette['purple']['600']}` }),
  }),
);

const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '2px 12px 12px 12px',
  gap: '8px',
}));

const BlockDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '12px 0 0 0',
}));

const FlowDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  borderRadius: '8px',
  backgroundColor: theme.palette['grey'][100],
  padding: '12px',
}));

export const ViewNodeCondition = ({ id }: NodeProps) => {
  const { getTargetElement } = useCanvasGet();
  const { navigateToProjectPage } = usePath();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const [targetFlowID, setTargetFlowID] = useState('');
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const { data: flowData } = useGetFlow(
    targetFlowID as string,
    projectID as string,
    true,
  );
  const ViewNodeData = getTargetElement(id) as JumpToFlowType;

  const { uniqTrigger, trigger } = useMemo(() => {
    const blockData: DataFlowChartTrigger = get(viewData, `blocks.${id}`, {});

    return {
      uniqTrigger: blockData?.uniqTrigger,
      trigger: blockData?.trigger,
    };
  }, [viewData, id]);

  useEffect(() => {
    if (ViewNodeData?.data) {
      setTargetFlowID(ViewNodeData.data.flowID);
    }
  }, [ViewNodeData.data]);

  return (
    <Box>
      {ViewNodeData.inputID && (
        <HandlePoint
          id={ViewNodeData.inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
        />
      )}
      <ContainerStyled>
        <NodeHeader
          color={globalTheme.palette?.['orange'][600] as string}
          background={globalTheme.palette?.['orange'][50]}
          icon={<BookmarksIcon fontSize="small" />}
          title={ViewNodeData.label}
          readonly
        />

        <BodyStyled>
          <BlockDataWrapperStyled>
            <EditorViewDataBlock
              title={t('view.action.trigger')}
              tooltipTitle={t('view.block.triggerTooltip')}
              count={trigger}
              uniqCount={uniqTrigger}
            />
          </BlockDataWrapperStyled>
          <FlowDataWrapperStyled>
            <Typography variant="subtitle2" color="grey.900">
              {t('canvas.jumpToFlow.title')}
            </Typography>
            <PublishedFlowSelect value={ViewNodeData.data?.flowID} disabled />
            <Typography variant="body2" color="grey.600">
              {t('view.jumpToFlow.jumpTo')}&nbsp;
              <Link
                onClick={() =>
                  navigateToProjectPage(
                    `${PAGE_FLOW}/${ViewNodeData.data?.flowID}`,
                  )
                }
                variant="notoSans"
                color="info.main"
              >
                {flowData?.name}
              </Link>
            </Typography>
          </FlowDataWrapperStyled>
        </BodyStyled>
      </ContainerStyled>
    </Box>
  );
};

export default memo(ViewNodeCondition);
