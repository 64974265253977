import CheckCircle from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const ToastWrapper = styled(Box)(() => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Alert = {
  error: (msg: string) => toast.error(msg, { icon: <ErrorIcon /> }),
  warning: (msg: string) => toast.warning(msg, { icon: <WarningAmberIcon /> }),
  success: (msg: string) => toast.success(msg, { icon: <CheckCircle /> }),
  info: (msg: string) => toast.info(msg, { icon: <InfoIcon /> }),
};

export const externalLinkToast = (
  message: string,
  goto: string,
  url: string,
) => {
  const redirect = () => {
    window.open(url);
  };
  toast.success(
    <ToastWrapper>
      <Box
        sx={{
          marginRight: '20px',
        }}
      >
        {message}
      </Box>
      <Box onClick={redirect}>
        <Typography>{goto}</Typography>
      </Box>
    </ToastWrapper>,
  );
};

export const internalLinkToast = (
  message: string,
  buttonText: string,
  toastType: string,
  redirect: () => void,
  buttonID?: string,
) => {
  switch (toastType) {
    case 'info':
      return toast.info(
        <ToastWrapper>
          <Box
            sx={{
              marginRight: '20px',
            }}
          >
            {message}
          </Box>
          <Box onClick={redirect} id={buttonID}>
            <Typography>{buttonText}</Typography>
          </Box>
        </ToastWrapper>,
      );
    case 'success':
      return toast.success(
        <ToastWrapper>
          <Box
            sx={{
              marginRight: '20px',
            }}
          >
            {message}
          </Box>
          <Box onClick={redirect} id={buttonID}>
            <Typography>{buttonText}</Typography>
          </Box>
        </ToastWrapper>,
      );
    default:
      return toast.success(
        <ToastWrapper>
          <Box
            sx={{
              marginRight: '20px',
            }}
          >
            {message}
          </Box>
          <Box onClick={redirect} id={buttonID}>
            <Typography>{buttonText}</Typography>
          </Box>
        </ToastWrapper>,
      );
  }
  return;
};
