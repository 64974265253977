import { globalTheme } from '@frontend/components/external-providers';
import { AddBlockIcon } from '@frontend/components/ui';
import {
  useCanvasAdd,
  useCanvasFlow,
  useCanvasRemove,
  useCanvasView,
  useModal,
  usePostCommentAutoReply,
} from '@frontend/editor/data-access';
import {
  EditorCtx,
  UICtx as EditorUICtx,
} from '@frontend/editor/external-providers';
import {
  ActionCellTypesEnum,
  CouponTypesEnum,
  DrawerTypesEnum,
  ElementTypesEnum,
  EntryPointsEnum,
  ModalTypesEnum,
  ResponseWithData,
} from '@frontend/editor/interface';
import { useGetAccountInfo } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  COMMENT_AUTO_REPLY,
  CONTENT,
  HOW_TO_USE_ATTRIBUTE_EN,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import TextIcon from '@mui/icons-material/FormatColorText';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LabelOffOutlinedIcon from '@mui/icons-material/LabelOffOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import ModeIcon from '@mui/icons-material/Mode';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Box, Button, ClickAwayListener, Link, Popper } from '@mui/material';
import { get } from 'lodash';
import { MouseEvent, useCallback, useContext, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactFlow, { Node } from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import ConnectionLineComponent from '../connection-line-component/connection-line-component';
import Control from '../control/control';
import CreateBlockMenu from '../create-block-menu/create-block-menu';
import Dialog from '../dialog/dialog';
import DrawerActionAttribute from '../drawer-action-attribute/drawer-action-attribute';
import DrawerActionSequence from '../drawer-action-sequence/drawer-action-sequence';
import DrawerSheet from '../drawer-action-sheet/drawer-action-sheet';
import DrawerBotLink from '../drawer-bot-link/drawer-bot-link';
import DrawerCollectUserAnswer from '../drawer-collect-user-answer/drawer-collect-user-answer';
import DrawerCondition from '../drawer-condition/drawer-condition';
import DrawerCoupon from '../drawer-coupon/drawer-coupon';
import CustomEdge, {
  CustomFocusMarker,
  CustomMarker,
} from '../editor-custom-edge/editor-custom-edge';
import EditorLoadingModal from '../editor-loading-modal/editor-loading-modal';
import EditorMenu from '../editor-menu/editor-menu';
import { RedeemCouponIcon, SendCouponIcon, ShowCouponIcon } from '../icons';
import NodeAction from '../node-action/node-action';
import NodeBlock from '../node-block/node-block';
import NodeCondition from '../node-condition/node-condition';
import NodeCoupon from '../node-coupon/node-coupon';
import NodeEntryPoint from '../node-entry-point/node-entry-point';
import { NodeJumpToFlow } from '../node-jump-to-flow/node-jump-to-flow';
import NodeNote from '../node-note/node-note';
import NodeShareLink from '../node-share-link/node-share-link';

const nodeTypes = {
  BLOCK: NodeBlock,
  ENTRY_POINT: NodeEntryPoint,
  CREATE_MENU: CreateBlockMenu,
  SHARE_LINK: NodeShareLink,
  CONDITION: NodeCondition,
  COUPON: NodeCoupon,
  ACTION: NodeAction,
  NOTE: NodeNote,
  JUMP_TO_FLOW: NodeJumpToFlow,
};

const edgeTypes = {
  CUSTOM: CustomEdge,
};

const FlowWrapperStyled = styled(Box)<{
  $zoom: number;
  $xPosition: number;
  $yPosition: number;
  $isConnecting: boolean;
}>(({ theme, $zoom, $xPosition, $yPosition, $isConnecting }) => ({
  width: '100%',
  position: 'relative',
  flex: 1,
  '& .react-flow__node': {
    outline: 'none',
  },
  '& .react-flow__pane': {
    // 避免新增 node 時超出畫面導致跑版
    overflow: 'hidden',
    ...($isConnecting && {
      cursor: 'grabbing',
    }),
  },
  '& .react-flow__edge': {
    '&.selected': {
      '& .react-flow__edge-path': {
        stroke: theme.palette['blue']['600'],
      },
    },
  },
  background: theme.palette['grey']['200'],

  '.react-flow__node-CREATE_MENU': {
    transform: `translate(${$xPosition}px, ${$yPosition}px) scale(${
      1 / $zoom
    }) !important`,
  },
  fontSize: `${28 / $zoom}px`,
}));

const AddButtonContainerStyled = styled(Box)(({ theme }) => ({
  top: 0,
  right: '20px',
  display: 'flex',
  width: '36px',
  padding: '0',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'absolute',
  zIndex: 5,
}));

interface FlowProps {
  flowID: string;
  flowName?: string;
  welcomeMessagePage: string;
  defaultAnswerPage: string;
  persistentMenuPage: string;
  updateFlowChart: () => void;
  getFlowDataInPersistentMenu: () => void;
}

export const Flow = ({
  flowID,
  flowName,
  welcomeMessagePage,
  defaultAnswerPage,
  persistentMenuPage,
  updateFlowChart,
  getFlowDataInPersistentMenu,
}: FlowProps) => {
  const {
    nodes,
    edges,
    zoom,
    targetElement,
    isConnecting,
    onConnectStart,
    onConnectEnd,
    onConnect,
    onEdgesChange,
    onNodesChange,
    handleEdgeDelete,
    handleNodesDelete,
    handleEdgeUpdate,
    init,
    onSave,
    onRestore,
  } = useCanvasFlow();
  const { navigateToProjectPage } = usePath();
  const { canvasRemoveBlock, canvasRemoveElement } = useCanvasRemove();
  const {
    addSendCoupon,
    addShowCoupon,
    addRedeemCoupon,
    addTextButton,
    addRecurringNotification,
    addImage,
    addGallery,
    addCondition,
    addAction,
    addActionSetAttribute,
    addActionRemoveAttribute,
    addActionSubscribeSequence,
    addActionUnsubscribeSequence,
    addActionExportGoogleSheet,
    addBlock,
    addNote,
    addCollectUserAnswer,
    addJumpToFlow,
  } = useCanvasAdd();
  const { focusOn } = useCanvasView();
  const { openModal, modalState, closeModal } = useModal();
  const { data: info } = useGetAccountInfo();
  const { mutate: addCommentAutoReply } = usePostCommentAutoReply();

  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const editorUIState = useContext(EditorUICtx);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const onAddButtonClick = useCallback(
    (addType: ElementTypesEnum | CouponTypesEnum) => {
      setIsMenuOpen(false);
      switch (addType) {
        case ElementTypesEnum.TEXT_BUTTON: {
          const blockID = addBlock(undefined, undefined, false);
          addTextButton(blockID);
          break;
        }
        case ElementTypesEnum.RECURRING_NOTIFICATION: {
          const blockID = addBlock(undefined, undefined, false);
          addRecurringNotification(blockID);
          break;
        }
        case ElementTypesEnum.IMAGE: {
          const blockID = addBlock(undefined, undefined, false);
          addImage(blockID);
          break;
        }
        case ElementTypesEnum.GALLERY: {
          const blockID = addBlock(undefined, undefined, false);
          addGallery(blockID);
          break;
        }
        case ElementTypesEnum.SHARE_LINK: {
          break;
        }
        case CouponTypesEnum.SEND: {
          addSendCoupon(undefined, undefined, false);
          break;
        }
        case CouponTypesEnum.SHOW: {
          addShowCoupon(undefined, undefined, false);
          break;
        }
        case CouponTypesEnum.REDEEM: {
          addRedeemCoupon(undefined, undefined, false);
          break;
        }
        case ElementTypesEnum.CONDITION: {
          addCondition(undefined, undefined, false);
          break;
        }
        case ElementTypesEnum.NOTE: {
          addNote(undefined, false);
          break;
        }
        case ElementTypesEnum.COLLECT_USER_ANSWER: {
          const blockID = addBlock(undefined, undefined, false);
          addCollectUserAnswer(blockID);
          break;
        }
        case ElementTypesEnum.JUMP_TO_FLOW: {
          addJumpToFlow(undefined, undefined, false);
        }
      }
    },
    [
      addBlock,
      addTextButton,
      addRecurringNotification,
      addImage,
      addGallery,
      addSendCoupon,
      addShowCoupon,
      addRedeemCoupon,
      addCondition,
      addNote,
      addCollectUserAnswer,
      addJumpToFlow,
    ],
  );

  const onAddActionButtonClick = useCallback(
    (actionType: ActionCellTypesEnum) => {
      setIsMenuOpen(false);
      const actionID = addAction(undefined, undefined, false);
      switch (actionType) {
        case ActionCellTypesEnum.SET: {
          addActionSetAttribute(actionID);
          break;
        }
        case ActionCellTypesEnum.REMOVE: {
          addActionRemoveAttribute(actionID);
          break;
        }
        case ActionCellTypesEnum.SUBSCRIBE_SEQUENCE: {
          addActionSubscribeSequence(actionID);
          break;
        }
        case ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE: {
          addActionUnsubscribeSequence(actionID);
          break;
        }
        case ActionCellTypesEnum.EXPORT_GOOGLE_SHEET: {
          addActionExportGoogleSheet(actionID);
          break;
        }
      }
    },
    [
      addAction,
      addActionSetAttribute,
      addActionRemoveAttribute,
      addActionSubscribeSequence,
      addActionUnsubscribeSequence,
      addActionExportGoogleSheet,
    ],
  );

  const handleDeleteBlockModal = useCallback(() => {
    canvasRemoveBlock();
    closeModal();
  }, [canvasRemoveBlock, closeModal]);

  const handleDeleteTextButtonModal = useCallback(() => {
    canvasRemoveElement();
    closeModal();
  }, [canvasRemoveElement, closeModal]);

  const handleDeleteElementModal = useCallback(() => {
    canvasRemoveElement();
    closeModal();
  }, [canvasRemoveElement, closeModal]);

  const gotoWelcomeMessage = useCallback(() => {
    closeModal();
    updateFlowChart();
    navigateToProjectPage(welcomeMessagePage);
  }, [closeModal, navigateToProjectPage, updateFlowChart, welcomeMessagePage]);

  const gotoBasicSetting = useCallback(() => {
    closeModal();
    updateFlowChart();
    switch (modalState.entryType) {
      case EntryPointsEnum.DEFAULT_ANSWER: {
        navigateToProjectPage(defaultAnswerPage);
        break;
      }
      case EntryPointsEnum.PERSISTENT_MENU: {
        navigateToProjectPage(persistentMenuPage);
        break;
      }
      case EntryPointsEnum.WELCOME_MESSAGE: {
        navigateToProjectPage(welcomeMessagePage);
        break;
      }
    }
  }, [
    closeModal,
    defaultAnswerPage,
    modalState.entryType,
    navigateToProjectPage,
    persistentMenuPage,
    updateFlowChart,
    welcomeMessagePage,
  ]);

  const gotoPersistentMenu = useCallback(() => {
    closeModal();
    updateFlowChart();
    navigateToProjectPage(persistentMenuPage);
    getFlowDataInPersistentMenu();
  }, [
    closeModal,
    getFlowDataInPersistentMenu,
    navigateToProjectPage,
    persistentMenuPage,
    updateFlowChart,
  ]);

  const handleAddButtonClick = useCallback(() => {
    setIsMenuOpen(true);
    // 清空 focus 目標避免 drawer 無法在新增後開啟
    state.setOnFocusID('');
    state.setOnFocusCellID('');
  }, [state]);

  const handleAddCommentAutoReply = useCallback(() => {
    addCommentAutoReply(
      {
        name: `${flowName} ${t(
          'canvas.modal.addCommentAutoReply.defaultName',
        )}`,
        conditionName: t('canvas.blockMenu.condition'),
        flowId: flowID,
        flowName: flowName,
      },
      {
        onSuccess: (res: ResponseWithData<{ id: string }>) => {
          closeModal();
          if (res.code === 20000 && res.data.id) {
            sendGAEvent(
              'Comment auto reply',
              'comment auto reply - created',
              'Comment auto reply - created',
              '',
            );
            navigateToProjectPage(
              `/${COMMENT_AUTO_REPLY}/${res.data.id}/${CONTENT}`,
            );
            uiState.openRenameCommentAutoReplyModal(res.data.id);
          }
        },
      },
    );
  }, [
    addCommentAutoReply,
    closeModal,
    flowID,
    flowName,
    navigateToProjectPage,
    t,
    uiState,
  ]);

  // 讓 sortable item 在拖曳的時候不會被 zoom 影響大小
  // Gallery 水平拖曳時要 resize 子層元素的寬度
  // 因為 react-sortable-hoc 會操作 transform 所以不能直接操作同一層元素

  const horizontalDraggedStyle = `
  .node_horizontal_item_dragged_style {
    z-index: 20;
  }
  .node_horizontal_item_dragged_style > div {
    transform: translate(${zoom * 50 - 50}%, ${
    zoom * 50 - 50
  }%) scale(${zoom}) !important;
}`;

  const draggedCellStyle = `
  .cell_dragged_style {
    z-index: 20;
    fontSize: ${28 / zoom}px;
    }

    .cell_dragged_style > div  {
     transform: translate(${zoom * 50 - 50}%, ${
    zoom * 50 - 50
  }%) scale(${zoom}) !important;
  }

  .cell_dragged_style #float-panel {
    left: calc(-${zoom}em - 0.1em) !important;
    }
  `;

  const draggedStyle = `
  .node_item_dragged_style {
    z-index: 20;
    }
    .node_item_dragged_style > span {
      transform: scale(${zoom}) !important;
      left: calc(-15 * ${zoom}px - 20px) !important;
      }

    .node_item_dragged_style > div  {
      transform: translate(${zoom * 50 - 50}%, ${
    zoom * 50 - 50
  }%) scale(${zoom}) !important;
}`;

  // 點擊 flow 時都會清空 focusID，利用事件捕獲機制確認現在 focus 的目標是誰
  const handleFocus = useCallback(
    (e: MouseEvent) => {
      // 捕捉 onClickAway drawer 的時機，判斷是否要跳出警告未儲存的 modal
      if (editorUIState.drawerType !== DrawerTypesEnum.CLOSE) {
        // 判斷內容有沒有更新
        if (state.isDrawerUpdate) {
          e.stopPropagation();
          editorUIState.openModal(ModalTypesEnum.CLOSE_UNSAVED_DRAWER);
          state.setIsDrawerUpdate(false);
        } else {
          state.setOnFocusCellID('');
          state.setOnFocusID('');
          editorUIState.setDrawerType(DrawerTypesEnum.CLOSE);
        }
      } else {
        state.setOnFocusCellID('');
        state.setOnFocusID('');
        editorUIState.setDrawerType(DrawerTypesEnum.CLOSE);
      }
    },
    [editorUIState, state],
  );

  const onCloseDrawer = () => {
    state.setOnFocusCellID('');
    state.setOnFocusID('');
    editorUIState.setDrawerType(DrawerTypesEnum.CLOSE);
    state.setIsDrawerUpdate(false);
    closeModal();
  };

  const isTemplateMode = info?.userName === 'template@goskyai.com';

  const editorMenuItems = [
    {
      title: t('canvas.contentTitle'),
      dropdownItems: [
        {
          id: 'option_text_button',
          onClick: () => onAddButtonClick(ElementTypesEnum.TEXT_BUTTON),
          icon: <TextIcon color="primary" />,
          title: t('canvas.blockMenu.contentButton'),
        },
        {
          id: 'option_image',
          icon: <ImageOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.image'),
          onClick: () => onAddButtonClick(ElementTypesEnum.IMAGE),
        },
        {
          id: 'option_gallery',
          icon: <CollectionsOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.gallery'),
          onClick: () => onAddButtonClick(ElementTypesEnum.GALLERY),
          tooltip: <Box>{t('canvas.blockMenu.showGalleryTooltip')}</Box>,
        },
        {
          id: 'option_theme_subscription',
          icon: <NotificationsNoneIcon color="primary" />,
          title: t('canvas.blockMenu.recurringNotificationsButton'),
          onClick: () =>
            onAddButtonClick(ElementTypesEnum.RECURRING_NOTIFICATION),
          tooltip: <Box>{t('canvas.blockMenu.rnTooltip')}</Box>,
        },
        {
          id: 'option_collect_user_answer',
          icon: <CommentBankOutlinedIcon color="primary" />,
          title: t('canvas.blockMenu.collectUserAnswerButton'),
          onClick: () => onAddButtonClick(ElementTypesEnum.COLLECT_USER_ANSWER),
          tooltip: (
            <Box>{t('canvas.blockMenu.showCollectUserAnswerTooltip')}</Box>
          ),
        },
      ],
    },
    {
      title: t('canvas.marketingTool'),
      dropdownItems: [
        // {
        //   onClick: () => onAddButtonClick(ElementTypesEnum.SHARE_LINK),
        //   icon: (
        //     <ShareIcon
        //       sx={{
        //         color: globalTheme.palette?.grass?.[600],
        //       }}
        //     />
        //   ),
        //   title: t('canvas.blockMenu.shareLink'),
        // },
        {
          id: 'option_send_coupon',
          onClick: () => onAddButtonClick(CouponTypesEnum.SEND),
          icon: (
            <SendCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.sendCoupon'),
          tooltip: <Box>{t('canvas.blockMenu.sendCouponTooltip')}</Box>,
        },
        {
          id: 'option_show_coupon',
          onClick: () => onAddButtonClick(CouponTypesEnum.SHOW),
          icon: (
            <ShowCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.showCoupon'),
          tooltip: <Box>{t('canvas.blockMenu.showCouponTooltip')}</Box>,
        },
        {
          id: 'option_redeem_coupon',
          onClick: () => onAddButtonClick(CouponTypesEnum.REDEEM),
          icon: (
            <RedeemCouponIcon
              sx={{
                color: globalTheme.palette?.grass?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.redeemCoupon'),
          tooltip: <Box>{t('canvas.blockMenu.redeemCouponTooltip')}</Box>,
        },
      ],
    },
    {
      title: t('canvas.blockMenu.action'),
      dropdownItems: [
        {
          id: 'option_set_attribute',
          onClick: () => onAddActionButtonClick(ActionCellTypesEnum.SET),
          icon: (
            <BookmarkBorderOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.setAttribute'),
          tooltip: (
            <Box>
              {t('canvas.blockMenu.setAttributeTooltip')}{' '}
              <Link
                sx={{
                  color: get(globalTheme, 'palette.infoOnTooltips.main', ''),
                  cursor: 'pointer',
                }}
                onClick={() => window.open(HOW_TO_USE_ATTRIBUTE_EN, '_blank')}
              >
                {t('canvas.blockMenu.setAttributeTooltipLink')}
              </Link>
            </Box>
          ),
        },
        {
          id: 'option_remove_attribute',
          onClick: () => onAddActionButtonClick(ActionCellTypesEnum.REMOVE),
          icon: (
            <BookmarkRemoveOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.removeAttribute'),
        },
        {
          id: 'option_subscribe_sequence',
          onClick: () =>
            onAddActionButtonClick(ActionCellTypesEnum.SUBSCRIBE_SEQUENCE),
          icon: (
            <LabelOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.subscribeSequence'),
        },
        {
          id: 'option_unsubscribe_sequence',
          onClick: () =>
            onAddActionButtonClick(ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE),
          icon: (
            <LabelOffOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.unsubscribeSequence'),
        },
        {
          id: 'option_google_sheet',
          onClick: () =>
            onAddActionButtonClick(ActionCellTypesEnum.EXPORT_GOOGLE_SHEET),
          icon: (
            <TableChartOutlinedIcon
              sx={{
                color: globalTheme.palette?.purple?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.exportGoogleSheet'),
          tooltip: <Box>{t('canvas.blockMenu.exportGoogleSheetTooltip')}</Box>,
        },
      ],
    },
    {
      title: t('canvas.condition.title'),
      dropdownItems: [
        {
          id: 'option_condition',
          onClick: () => onAddButtonClick(ElementTypesEnum.CONDITION),
          icon: (
            <FilterAltOutlinedIcon
              sx={{
                color: globalTheme.palette?.orange?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.condition'),
        },
        {
          id: 'option_jump_to_flow',
          onClick: () => onAddButtonClick(ElementTypesEnum.JUMP_TO_FLOW),
          icon: (
            <AccountTreeOutlinedIcon
              sx={{
                color: globalTheme.palette?.orange?.[600],
              }}
            />
          ),
          title: t('canvas.blockMenu.jumpToFlow'),
          tooltip: <Box>{t('canvas.blockMenu.jumpToFlowTooltip')}</Box>,
        },
      ],
    },
    ...(isTemplateMode
      ? [
          {
            title: 'template',
            dropdownItems: [
              {
                onClick: () => onAddButtonClick(ElementTypesEnum.NOTE),
                icon: (
                  <ModeIcon
                    sx={{
                      color: globalTheme.palette?.pink?.[600],
                    }}
                  />
                ),
                title: 'Note',
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <FlowWrapperStyled
      ref={state.ref}
      $zoom={zoom}
      $xPosition={state.menuCoordination.x}
      $yPosition={state.menuCoordination.y}
      $isConnecting={isConnecting}
    >
      <style>{draggedStyle}</style>
      <style>{horizontalDraggedStyle}</style>
      <style>{draggedCellStyle}</style>
      <EditorLoadingModal />
      <DrawerBotLink />
      <DrawerCondition />
      <DrawerCoupon />
      <DrawerSheet />
      <DrawerActionSequence />
      <DrawerActionAttribute />
      <DrawerCollectUserAnswer />
      <ReactFlow
        // 在捕獲階段才設置，node focus 的事件也會在 clickCapture 觸發，才會是先清除在設定目標的正確順序
        onClickCapture={handleFocus}
        onNodeDragStart={(_: MouseEvent, node: Node, nodes: Node[]) =>
          focusOn(node.id)
        }
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        onConnectEnd={onConnectEnd}
        onConnectStart={onConnectStart}
        onClickConnectStart={onConnectStart}
        onEdgesChange={onEdgesChange}
        onNodesChange={onNodesChange}
        onNodesDelete={handleNodesDelete}
        onEdgesDelete={handleEdgeDelete}
        onEdgeUpdate={handleEdgeUpdate}
        onInit={init}
        nodeTypes={nodeTypes}
        // 預設的 edge 樣式
        defaultEdgeOptions={{
          type: 'CUSTOM',
        }}
        fitView
        fitViewOptions={{
          maxZoom: 0.8,
          minZoom: 0.8,
        }}
        edgeTypes={edgeTypes}
        // 設定拉線時的樣式
        connectionLineComponent={ConnectionLineComponent}
        panOnDrag={state.isFlowDraggable}
        nodesDraggable={state.isFlowDraggable}
        preventScrolling={state.isFlowPreventScrolling}
        zoomOnScroll={state.isFlowZoomOnScroll}
        zoomOnDoubleClick={state.isFlowZoomOnDoubleClick}
      >
        <Control onSave={onSave} onRestore={onRestore} addBlock={addBlock} />
      </ReactFlow>

      <AddButtonContainerStyled>
        <Button
          id="canvas_element"
          ref={addButtonRef}
          sx={{
            minWidth: '36px',
          }}
          color="primary"
          variant="contained"
          onClick={handleAddButtonClick}
        >
          <AddBlockIcon />
        </Button>
      </AddButtonContainerStyled>
      {isMenuOpen && (
        <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
          <Popper
            sx={{
              zIndex: 100,
            }}
            open={isMenuOpen}
            anchorEl={addButtonRef.current}
            placement="left-start"
            modifiers={[
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: 'document',
                  padding: '8px',
                },
              },
            ]}
          >
            <EditorMenu itemList={editorMenuItems} />
          </Popper>
        </ClickAwayListener>
      )}
      {/* modal 刪除 block 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteBlock.title', {
          title: targetElement?.label,
        })}
        content={t('canvas.modal.deleteBlock.info1')}
        open={openModal === ModalTypesEnum.DELETE_BLOCK}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteBlockModal}
      >
        <Trans i18nKey="canvas.modal.deleteBlock.info2" />
      </Dialog>
      {/* modal 刪除 element 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteElement.title', {
          title: targetElement?.elementName,
        })}
        content={t('canvas.modal.deleteElement.info1')}
        open={openModal === ModalTypesEnum.DELETE_ELEMENT}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteElementModal}
      >
        <Trans i18nKey="canvas.modal.deleteElement.info2" />
      </Dialog>
      {/* modal 刪除 text-button 確認 */}
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.deleteTextButton.title', {
          title: targetElement?.label,
        })}
        content={t('canvas.modal.deleteTextButton.info1')}
        open={openModal === ModalTypesEnum.DELETE_TEXT_BUTTON}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={handleDeleteTextButtonModal}
      >
        <Trans i18nKey="canvas.modal.deleteTextButton.info2" />
      </Dialog>
      {/* modal 提示 welcome message 尚未起用 */}
      <Dialog
        size="xs"
        title={t('canvas.modal.welcomeMessageDisable.title')}
        content={t('canvas.modal.welcomeMessageDisable.info')}
        open={openModal === ModalTypesEnum.WELCOME_MESSAGE_DISABLE}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.ok')}
        handleConfirm={gotoWelcomeMessage}
      />
      {/* modal 提示 persistent menu 尚未起用 */}
      <Dialog
        size="xs"
        title={t('canvas.modal.persistentMenuDisable.title')}
        content={t('canvas.modal.persistentMenuDisable.info')}
        open={openModal === ModalTypesEnum.PERSISTENT_MENU_DISABLE}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.ok')}
        handleConfirm={gotoPersistentMenu}
      />
      {/* modal add comment auto reply */}
      <Dialog
        size="xs"
        title={t('canvas.modal.addCommentAutoReply.title')}
        content={t('canvas.modal.addCommentAutoReply.content')}
        open={openModal === ModalTypesEnum.ADD_COMMENT_AUTO_REPLY}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('canvas.modal.addCommentAutoReply.confirm')}
        handleConfirm={handleAddCommentAutoReply}
      />
      {/* modal add persistent menu */}
      <Dialog
        size="xs"
        title={t('canvas.modal.addPersistentMenu.title')}
        content={t('canvas.modal.addPersistentMenu.content')}
        open={openModal === ModalTypesEnum.ADD_PERSISTENT_MENU}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.add')}
        handleConfirm={gotoPersistentMenu}
        confirmBtnID="trigger_menu_add"
      />
      {/* modal 提示 basic setting toggle 設定 */}
      <Dialog
        size="xs"
        title={t('canvas.modal.basicSettingToggle.title')}
        content={t('canvas.modal.basicSettingToggle.info')}
        open={openModal === ModalTypesEnum.BASIC_SETTING_TOGGLE}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('canvas.modal.basicSettingToggle.confirm')}
        handleConfirm={gotoBasicSetting}
      />
      {/* delete 共用 modal*/}
      <Dialog
        size="xs"
        color="error"
        title={modalState.title}
        content={modalState.content}
        open={openModal === ModalTypesEnum.DELETE}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={modalState.handleConfirm}
      >
        {modalState.info}
      </Dialog>
      <Dialog
        size="xs"
        color="error"
        title={t('canvas.modal.closeUnsavedDrawer.title')}
        content={t('canvas.modal.closeUnsavedDrawer.content')}
        open={openModal === ModalTypesEnum.CLOSE_UNSAVED_DRAWER}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.discard')}
        handleConfirm={onCloseDrawer}
      />
      <CustomMarker />
      <CustomFocusMarker />
    </FlowWrapperStyled>
  );
};
