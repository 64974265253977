import {
  GetEditorBasicFlowStatusResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_BASIC_FLOW_STATUS } from '../apis';

async function getGetEditorBasicFlowStatus(
  projectId: string,
): Promise<ResponseWithData<GetEditorBasicFlowStatusResponse>> {
  return axios
    .get(`${EDITOR_BASIC_FLOW_STATUS}?projectId=${projectId}`)
    .then((res) => res.data);
}

export function useGetEditorBasicFlowStatus(projectId: string) {
  return useQuery<ResponseWithData<GetEditorBasicFlowStatusResponse>>({
    queryKey: ['get-editor-basic-flow-status', { projectId }],
    queryFn: () => {
      if (projectId) {
        return getGetEditorBasicFlowStatus(projectId);
      } else {
        return {
          code: 400,
          msg: 'missing id',
          data: {
            welcomeMessage: {
              requiredCount: 0,
              finishedCount: 0,
            },
            persistentMenu: {
              requiredCount: 0,
              finishedCount: 0,
            },
            conversationStarter: {
              requiredCount: 0,
              finishedCount: 0,
            },
            defaultAnswer: {
              requiredCount: 0,
              finishedCount: 0,
            },
          },
        };
      }
    },
  });
}

export default useGetEditorBasicFlowStatus;
