import {
  useAccountResetVerify,
  useSendAccountResetMail,
} from '@frontend/sorghum/data-access';
import { useUser } from '@frontend/sorghum/features/home';
import {
  GetAccountResetVerifyResponse,
  ResponseWithData,
  SendAccountResetMailProps,
  SendAccountResetMailResponse,
} from '@frontend/sorghum/interface';
import {
  PAGE_LOGIN,
  PAGE_RESET_PASSWORD,
  PAGE_SIGN_UP,
  PAGE_VERIFY_LIMIT,
  getQuery,
  useDocumentTitle,
} from '@frontend/sorghum/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const usePasswordVerify = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { user, setUser } = useUser();
  const [email, setEmail] = useState<string>(user.email ? user.email : '');
  const [resendToken, setResendToken] = useState<string>();
  const { token } = getQuery();
  const isExpired = !!token;

  const onSuccess = useCallback(
    (data: ResponseWithData<GetAccountResetVerifyResponse>) => {
      switch (data.code) {
        case 20000: {
          setUser({ id: token as string, email: data.data.email });
          setEmail(data.data.email);
          navigate(PAGE_RESET_PASSWORD);
          return;
        }
        case 40000: {
          setUser({
            id: data.data.id,
            email: data.data.email,
            status: 'EMAIL_TOKEN_EXPIRED',
          });
          return;
        }
      }
    },
    [navigate, setUser, token],
  );

  const { mutate: resetVerify, isLoading } = useAccountResetVerify({
    token: token as string,
  });

  const resendMailSuccess = useCallback(
    (data: SendAccountResetMailResponse) => {
      if (data.code === 40001 || data.code === 40002) {
        navigate(PAGE_VERIFY_LIMIT);
      } else {
        toast.success(
          t('verify.password.expired.successResendMessage').replace(
            '{{email}}',
            user.email as string,
          ),
        );
        setResendToken(data.data.sendToken);
      }
    },
    [navigate, t, user?.email],
  );

  const { mutate } = useSendAccountResetMail();

  const resendEmail = useCallback(() => {
    if (user.email) {
      const props: SendAccountResetMailProps = {
        email: user.email,
        token: resendToken,
      };
      mutate(props, {
        onSuccess: resendMailSuccess,
      });
    }
  }, [user.email, resendToken, mutate, resendMailSuccess]);

  useEffect(() => {
    if (token) {
      setUser({
        id: token as string,
        status: 'PENDING',
      });
      resetVerify(undefined, {
        onSuccess,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, resetVerify]);

  const onArrowClick = () => navigate(PAGE_LOGIN);
  const title = t(`verify.password.${isExpired ? 'expired.' : ''}title`);
  const infoPrefix = t(
    isExpired
      ? 'verify.password.expired.infoPrefix'
      : 'verify.password.infoPrefix',
  );
  const infoSuffix = t(
    isExpired
      ? 'verify.password.expired.infoSuffix'
      : 'verify.password.infoSuffix',
  );
  const resendInfo = isExpired
    ? t('verify.password.expired.noConfirmEmail')
    : t('verify.password.noConfirmEmail');

  const toCreateNewSuffix = isExpired
    ? t('verify.password.expired.toCreateNew')
    : '';

  const resendText = isExpired
    ? t('verify.password.expired.resend')
    : t('verify.password.resend');

  useDocumentTitle(t('title.passwordVerify'));

  return {
    isLoading,
    email,
    token,
    title,
    infoPrefix,
    infoSuffix,
    resendInfo,
    arrowText: t('verify.password.back'),
    toCreateNewSuffix,
    onArrowClick,
    resendEmail: resendEmail,
    resendText,
    ...(isExpired && { gotoSignUp: () => navigate(PAGE_SIGN_UP) }),
  };
};
