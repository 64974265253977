import { globalTheme } from '@frontend/components/external-providers';
import { Divider, ListSubheader, MenuItem, MenuItemProps } from '@mui/material';
import { get } from 'lodash';
import { FC, PropsWithChildren } from 'react';
import Typography from '../../typography/typography';
export { ListSubheader } from '@mui/material';

export const GroupItem: FC<PropsWithChildren> = ({ children }) => (
  <ListSubheader sx={{ lineHeight: '30px' }}>
    <Typography variant="caption">{children}</Typography>
  </ListSubheader>
);

export const GroupDivider: FC = () => (
  <Divider
    sx={{
      borderWidth: '1px',
      borderColor: get(globalTheme, 'palette.grey.200'),
    }}
  />
);

/* eslint-disable-next-line */
export interface SelectItemProps extends MenuItemProps {}

export const SelectItem: FC<SelectItemProps> = ({
  children,
  ...props
}: SelectItemProps) => {
  return <MenuItem {...props}>{children}</MenuItem>;
};

export default SelectItem;
