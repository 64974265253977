import { GetCouponRewardCountResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_REWARD_COUNT } from '../apis';

const defaultData = {
  couponId: '',
  incentives: [
    {
      name: '',
      id: '',
      totalCount: 0,
      claimCount: 0,
    },
  ],
};

async function getCouponRewardCount(
  projectID: string,
  couponID: string,
): Promise<GetCouponRewardCountResponse> {
  return axios
    .get(`${COUPON_REWARD_COUNT}?projectId=${projectID}&couponId=${couponID}`)
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCouponRewardCount(projectID: string, couponID: string) {
  return useQuery({
    queryKey: ['get-coupon-reward-count', { projectID, couponID }],
    queryFn: () => {
      if (projectID && couponID) {
        return getCouponRewardCount(projectID, couponID);
      } else {
        return defaultData;
      }
    },
  });
}
