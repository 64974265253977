import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  ConditionCellType,
  ConditionType,
  DataFlowChartTrigger,
  Rules,
} from '@frontend/editor/interface';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import EditorViewDataBlock from '../editor-view-data-block/editor-view-data-block';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import {
  BodyStyled,
  CategoryWrapperStyled,
  ContainerStyled,
  ItemWrapperStyled,
  SpanStyled,
  TitleWrapperStyled,
} from './styles';

const BlockDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '12px 0 0 0',
}));

export const ViewNodeCondition = ({ id }: NodeProps) => {
  const { getTargetElement } = useCanvasGet();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const ViewNodeConditionData = getTargetElement(id) as ConditionType;

  const { label: header, inputID } = ViewNodeConditionData as ConditionType;

  const { uniqTrigger, trigger } = useMemo(() => {
    const blockData: DataFlowChartTrigger = get(viewData, `blocks.${id}`, {});

    return {
      uniqTrigger: blockData?.uniqTrigger,
      trigger: blockData?.trigger,
    };
  }, [viewData, id]);

  return (
    <Box>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
        />
      )}
      <ContainerStyled>
        <NodeHeader
          color={globalTheme.palette?.['orange'][600] as string}
          background={globalTheme.palette?.['orange'][50]}
          icon={<BookmarksIcon fontSize="small" />}
          title={header}
          readonly
        />

        <BodyStyled>
          <BlockDataWrapperStyled>
            <EditorViewDataBlock
              title={t('view.action.trigger')}
              tooltipTitle={t('view.block.triggerTooltip')}
              count={trigger}
              uniqCount={uniqTrigger}
            />
          </BlockDataWrapperStyled>
          {ViewNodeConditionData.children &&
            ViewNodeConditionData.children.map((cellID: string) => {
              const cellData = getTargetElement(cellID) as ConditionCellType;
              const uniqTriggerP: number | undefined = get(
                viewData,
                `cells.${cellID}.uniqTriggerP`,
                0,
              );

              switch (cellData.rule) {
                case Rules.IS:
                case Rules.IS_NOT: {
                  return (
                    <ItemWrapperStyled key={cellID}>
                      <TitleWrapperStyled>
                        <HandlePoint
                          id={cellData.outputID}
                          type="source"
                          position={Position.Right}
                          styles={{
                            top: '50%',
                          }}
                          isConnected={false}
                        />
                        <Typography
                          sx={{
                            marginRight: '12px',
                            marginBottom: '4px',
                            whiteSpace: 'break-spaces',
                          }}
                          variant="subtitle2"
                          color="grey.900"
                        >
                          <Trans i18nKey="canvas.condition.subtitle" />
                        </Typography>
                        <SpanStyled>
                          <Typography variant="caption" color="grey.900">
                            {uniqTriggerP}%
                          </Typography>
                        </SpanStyled>
                      </TitleWrapperStyled>

                      <CategoryWrapperStyled>
                        <Typography variant="caption" color="primary">
                          {cellData.categoryTypeName}
                        </Typography>
                        &nbsp;
                        <Typography variant="caption" color="grey.600">
                          {cellData.rule === Rules.IS ? (
                            <Trans i18nKey="canvas.condition.drawer.is" />
                          ) : (
                            <Trans i18nKey="canvas.condition.drawer.isNot" />
                          )}
                        </Typography>
                        &nbsp;
                        <Typography variant="caption" color="grey.600">
                          {cellData.categoryValueName}
                        </Typography>
                      </CategoryWrapperStyled>
                    </ItemWrapperStyled>
                  );
                }
                case Rules.ELSE: {
                  return (
                    <ItemWrapperStyled
                      sx={{
                        mt: '8px',
                      }}
                      key={cellID}
                    >
                      <HandlePoint
                        id={cellData.outputID}
                        type="source"
                        position={Position.Right}
                        styles={{
                          top: '50%',
                        }}
                        isConnected={false}
                      />
                      <TitleWrapperStyled>
                        <Typography
                          sx={{ marginRight: '12px' }}
                          variant="subtitle2"
                          color="grey.900"
                        >
                          <Trans i18nKey="canvas.condition.notMatch" />
                        </Typography>
                        <SpanStyled>
                          <Typography variant="caption" color="grey.900">
                            {uniqTriggerP}%
                          </Typography>
                        </SpanStyled>
                      </TitleWrapperStyled>
                    </ItemWrapperStyled>
                  );
                }
                default: {
                  return null;
                }
              }
            })}
        </BodyStyled>
      </ContainerStyled>
    </Box>
  );
};

export default memo(ViewNodeCondition);
