import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST_DATA } from '../apis';

const defaultData = {
  uniqSent: 0,
  uniqDelivered: 0,
  uniqDeliveredP: 0,
  uniqOpened: 0,
  uniqOpenedP: 0,
  uniqClicked: 0,
  uniqClickedP: 0,
};

// 拿取已發送的 broadcast 的數據
async function getSentBroadcastData(projectID: string, broadcastID: string) {
  return axios
    .get(`${BROADCAST_DATA}?projectId=${projectID}&broadcastId=${broadcastID}`)
    .then((res) => res.data.data)
    .catch((err) => defaultData);
}

export function useGetSentBroadcastData(
  projectID: string,
  broadcastID: string,
) {
  return useQuery({
    queryKey: ['get-sent-broadcast-data', { projectID, broadcastID }],
    queryFn: () => {
      if (projectID && broadcastID) {
        return getSentBroadcastData(projectID, broadcastID);
      }
      return defaultData;
    },
  });
}
