import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_CONVERSATION } from '../apis';
import {
  BasicSettingProps,
  GetEditorConversation,
  ResponseWithData,
} from '@frontend/sorghum/interface';

async function getEditorConversation({
  projectID,
  socialType,
}: BasicSettingProps): Promise<ResponseWithData<GetEditorConversation>> {
  return axios
    .get(
      `${EDITOR_CONVERSATION}?projectId=${projectID}&socialType=${socialType}`,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
      data: {},
    }));
}

export function useGetEditorConversation({
  projectID,
  socialType,
}: BasicSettingProps) {
  return useQuery<ResponseWithData<GetEditorConversation>>({
    queryKey: ['get-editor-conversation', { projectID, socialType }],
    queryFn: () => {
      if (projectID) {
        return getEditorConversation({ projectID, socialType });
      } else {
        return {
          code: 400,
          msg: 'missing id',
        } as ResponseWithData<GetEditorConversation>;
      }
    },
  });
}

export default useGetEditorConversation;
