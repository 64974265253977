import { BroadcastViewTextButton, Textarea } from '@frontend/components/ui';
import { EditorViewDataBlockProps } from '@frontend/editor/interface';
import { ConditionCellType } from '@frontend/sorghum/interface';

import { EditorViewDataBlock } from '@frontend/editor/ui';
import {
  DataBlockContainerStyled,
  DataBlockStyled,
  TextButtonContainerStyled,
  TextButtonInnerContainerStyled,
  TextContainerStyled,
} from './styles';

interface ViewTextButtonCellType extends ConditionCellType {
  ctr: number | undefined;
}

interface ViewTextButtonProps {
  buttonTitle: string;
  blockData?: EditorViewDataBlockProps[];
  labelItem: {
    name: string;
    type: number;
  }[];
  cellDataList: ViewTextButtonCellType[];
}

export const ViewTextButton = ({
  buttonTitle,
  labelItem,
  blockData = [],
  cellDataList,
}: ViewTextButtonProps) => {
  return (
    <TextButtonContainerStyled>
      <DataBlockContainerStyled>
        {blockData.map((i) => (
          <DataBlockStyled key={i.title}>
            <EditorViewDataBlock
              title={i.title}
              count={i.count}
              percentage={i.percentage}
              uniqCount={i.uniqCount}
              uniqPercentage={i.uniqPercentage}
              fillWidth
            />
          </DataBlockStyled>
        ))}
      </DataBlockContainerStyled>
      <TextButtonInnerContainerStyled>
        <TextContainerStyled>
          <Textarea
            disabled
            readonly
            labelItem={labelItem}
            defaultValue={buttonTitle}
          />
        </TextContainerStyled>
        {cellDataList &&
          cellDataList.map((cellItem) => (
            <BroadcastViewTextButton
              key={cellItem.cellId}
              iconType={cellItem.type}
              text={cellItem.data.title}
              ctrData={cellItem.ctr}
              cellData={cellItem}
            />
          ))}
      </TextButtonInnerContainerStyled>
    </TextButtonContainerStyled>
  );
};

export default ViewTextButton;
