import {
  useAccountResend,
  useAccountSignupVerify,
  useGetAccountInfo,
} from '@frontend/sorghum/data-access';
import { useUser } from '@frontend/sorghum/features/home';
import {
  ResponseWithData,
  SendAccountResetMailResponse,
  UseAccountSignupVerifyResponse,
} from '@frontend/sorghum/interface';
import {
  PAGE_LOGIN,
  PAGE_MICRO,
  PAGE_SIGN_UP,
  PAGE_VERIFY_LIMIT,
  PAGE_VERIFY_UNAVAILABLE,
  getQuery,
  useDocumentTitle,
  usePath,
} from '@frontend/sorghum/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// 若 localStorage 已有 token，顯示不同的文案
const infoPrefixWithTokenStatus = (token: string | null) => {
  if (token) return 'verify.email.verified.infoPrefixWithToken';

  return 'verify.email.verified.infoPrefix';
};

export const useMailVerify = () => {
  const [searchParams] = useSearchParams();
  const { navigate } = usePath();
  const inviteToken = searchParams.get('t') ?? '';
  const [t] = useTranslation();
  const { user, setUser } = useUser();
  const { token } = getQuery();
  const localStorageToken = localStorage.getItem('token');
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { data: account } = useGetAccountInfo();
  const isExpired = user.status === 'EMAIL_TOKEN_EXPIRED';
  const isVerified = user.status === 'TOKEN_ALREADY_VERIFY';

  const verifySuccess = useCallback(
    (data: UseAccountSignupVerifyResponse) => {
      if (data.code === 20000) {
        localStorage.setItem('token', data.data.accessToken);
        setUser({ id: data.data.id, email: data.data.email });
        navigate(PAGE_MICRO + `?p=${data.data.projectId}`);
        return;
      } else if (data.code === 40000) {
        // token 未驗證，已過期
        setUser({ ...user, status: 'EMAIL_TOKEN_EXPIRED' });
      } else if (data.code === 40001) {
        // token 已驗證
        setUser({ ...user, status: 'TOKEN_ALREADY_VERIFY' });
        setTimeout(() => {
          navigate(PAGE_LOGIN);
        }, 5000);
      } else if (data.code === 40002) {
        // 發送信件達上限
        navigate(PAGE_VERIFY_LIMIT);
      } else if (data.code === 500) {
        // 其他錯誤情境
        navigate(PAGE_VERIFY_UNAVAILABLE);
        return;
      }
    },
    [navigate, setUser, user],
  );

  const { mutate: emailSignupVerify, isLoading } = useAccountSignupVerify({
    onSuccess: verifySuccess,
  });

  const { mutate: resendEmail } = useAccountResend();

  const resendMailSuccess = useCallback(
    (data: ResponseWithData<SendAccountResetMailResponse>) => {
      if (data.code === 40001 || data.code === 40002) {
        navigate(PAGE_VERIFY_LIMIT);
      } else {
        toast.success(
          t('verify.email.expired.successResendMessage').replace(
            '{{email}}',
            user.email as string,
          ),
        );
      }
    },
    [navigate, t, user?.email],
  );

  const handleResendEmail = useCallback(() => {
    resendEmail(
      {
        id: user.id as string,
        ...(inviteToken && { token: inviteToken }),
      },
      {
        onSuccess: (data: ResponseWithData<SendAccountResetMailResponse>) =>
          resendMailSuccess(data),
      },
    );
  }, [resendEmail, user.id, inviteToken, resendMailSuccess]);

  useEffect(() => {
    if (token && !isExpired && !isVerified && !isLoaded) {
      setIsLoaded(true);
      setUser({
        status: 'PENDING',
      });
      emailSignupVerify(token as string);
    }
  }, [emailSignupVerify, isExpired, isLoaded, isVerified, setUser, token]);

  useEffect(() => {
    if (localStorageToken) {
      // 如果在驗證 email 後跳回原頁面，要轉址到問卷頁
      if (account?.step === 'question') {
        navigate(PAGE_MICRO);
      }
    }
  }, [account?.step, localStorageToken, navigate]);

  const onArrowClick = () => navigate(PAGE_SIGN_UP);
  const title = t(
    `verify.email.${
      isExpired ? 'expired.' : isVerified ? 'verified.' : ''
    }title`,
  );
  const infoPrefix = t(
    isExpired
      ? 'verify.email.expired.infoPrefix'
      : isVerified
      ? infoPrefixWithTokenStatus(localStorageToken)
      : 'verify.email.infoPrefix',
  );

  const infoSuffix = t(
    isExpired
      ? 'verify.email.expired.infoSuffix'
      : isVerified
      ? 'verify.email.verified.infoSuffix'
      : 'verify.email.infoSuffix',
  );

  const resendInfo = isExpired
    ? t('verify.email.noConfirmEmail')
    : t('verify.email.expired.noConfirmEmail');

  const toCreateNewSuffix = isExpired
    ? t('verify.email.expired.toCreateNew')
    : '';

  const resendText = t('verify.email.resend');

  useDocumentTitle(t('title.mailVerify'));

  return {
    isLoading,
    email: user.email as string,
    token,
    title,
    infoPrefix,
    infoSuffix,
    resendInfo,
    arrowText: t('common.backToSignUp'),
    toCreateNewSuffix,
    onArrowClick,
    resendText,
    resendEmail: handleResendEmail,
    ...(isExpired && { gotoSignUp: () => navigate(PAGE_SIGN_UP) }),
  };
};
