import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  EditorOptionFlow,
  BasicSettingProps,
} from '@frontend/sorghum/interface';
import { EDITOR_OPTION_FLOW } from '../apis';

async function getGetEditorOptionFlow(
  projectId: string,
  socialType: number,
): Promise<EditorOptionFlow[]> {
  return axios
    .get(
      `${EDITOR_OPTION_FLOW}?projectId=${projectId}&socialType=${socialType}`,
    )
    .then((res) => res.data.data)
    .catch((err) => []);
}

export function useGetEditorOptionFlow({
  projectID,
  socialType,
}: BasicSettingProps) {
  return useQuery<EditorOptionFlow[]>({
    queryKey: ['get-editor-option-flow', { projectID, socialType }],
    queryFn: () => {
      if (projectID && socialType) {
        return getGetEditorOptionFlow(projectID, socialType);
      } else {
        return [];
      }
    },
  });
}

export default useGetEditorOptionFlow;
