import { ReactNode, SuspenseProps } from 'react';
import QueryErrorBoundary from '../QueryErrorBoundary/QueryErrorBoundary';
import SuspenseWrapper from '../SuspenseWrapper/SuspenseWrapper';

interface WithPageBoundaryProps extends Pick<SuspenseProps, 'fallback'> {
  errorKey?: number | string;
}

export function withPageBoundary(
  page: ReactNode,
  props?: WithPageBoundaryProps,
) {
  return (
    <SuspenseWrapper {...props}>
      <QueryErrorBoundary
        {...(props?.errorKey ? { errorKey: props.errorKey } : {})}
        children={page}
      />
    </SuspenseWrapper>
  );
}

export default withPageBoundary;
