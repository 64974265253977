import { MenuItem, Typography } from '@frontend/components/ui';
import { MaterialStyledProps } from '@frontend/editor/interface';
import { Box, Menu } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as TextLabelIcon } from '../../../images/text-label-icon.svg';

export enum ToolPositionTypesEnum {
  'IN' = 'IN',
  'OUT' = 'OUT',
}

export interface EditorTooltipProps {
  length: number;
  hasLabel: boolean;
  labelItem?: LabelMenuItem[];
  position?: ToolPositionTypesEnum;
  limit?: number;
  prefix?: string;
  parentRef?: React.RefObject<HTMLElement>;
  setEmoji: (emoji: string) => void;
  insertLabel?: (label: string) => void;
  onEllipsisClick?: () => void;
}

interface LabelMenuItem {
  name: string;
  value: string;
}

interface EditorTooltipStyledProps extends MaterialStyledProps {
  $position: ToolPositionTypesEnum;
}

const EditorTooltipStyled = styled(Box)<EditorTooltipStyledProps>(
  ({ theme, $position }) => ({
    position: 'absolute',
    right: 0,
    bottom:
      $position === ToolPositionTypesEnum.IN
        ? theme.spacing(1)
        : theme.spacing(-4),
    background: theme.palette.bluegrey[700],
    borderRadius: '4px',
    padding: '6px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    zIndex: 99,
  }),
);

const IconStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '16px',
  marginRight: '5px',
}));

const TextCountWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '12px',
  width: '18px',
}));

export function EditorTooltip(props: EditorTooltipProps) {
  const {
    labelItem,
    limit,
    length,
    parentRef,
    prefix,
    hasLabel,
    position = ToolPositionTypesEnum.IN,
    setEmoji,
    onEllipsisClick,
    insertLabel = () => null,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ellipsisRef = useRef<HTMLElement>(null);

  const handleEllipsisClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setAnchorEl(ellipsisRef.current);
      if (onEllipsisClick) {
        onEllipsisClick();
      }
    },
    [onEllipsisClick],
  );

  const handleClose = () => {
    // 關閉選單時清除沒有選擇的標籤
    parentRef?.current?.childNodes.forEach((node) => {
      const n = node as HTMLElement;
      if (n.getAttribute && n.getAttribute('data-type') === 'label') {
        if (n.innerText === prefix) {
          node.remove();
        }
      }
    });

    setAnchorEl(null);
  };

  return (
    <>
      <EditorTooltipStyled ref={ellipsisRef} $position={position}>
        {hasLabel && (
          <IconStyled onClick={handleEllipsisClick}>
            <TextLabelIcon />
          </IconStyled>
        )}
        {/* <IconStyled>
          <Emoji
            renderButton={<EmojiIcon />}
            insertEmoji={setEmoji}
            isDisabled={limit ? limit - length < 2 : false}
          />
        </IconStyled> */}
        {limit && (
          <TextCountWrapper>
            <Typography
              variant="notoSans"
              color={length >= limit ? 'error' : '#33cc4b'}
            >
              {limit - length}
            </Typography>
          </TextCountWrapper>
        )}
      </EditorTooltipStyled>
      {hasLabel && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        >
          {labelItem &&
            labelItem.map((i) => {
              return (
                <MenuItem
                  key={i.name}
                  content={i.name}
                  onClick={() => insertLabel(i.value)}
                />
              );
            }, [])}
        </Menu>
      )}
    </>
  );
}

export default EditorTooltip;
