import { globalTheme } from '@frontend/components/external-providers';
import { get } from 'lodash';
import { Dispatch, SetStateAction, memo, useCallback } from 'react';
import { Handle, HandleProps } from 'reactflow';
import styled from 'styled-components';
import ConnectorRedIcon from '../../../images/connector-icon-red.svg';
import ConnectorWhiteIcon from '../../../images/connector-icon-white.svg';
import ConnectorIcon from '../../../images/connector-icon.svg';

interface HandlePointProps extends HandleProps {
  styles?: object;
  isConnected?: boolean;
  setIsHoverBlock?: Dispatch<SetStateAction<boolean>>;
  isFocus?: boolean;
  isGoToBlockCell?: boolean;
}

export const HandlePoint = ({
  styles,
  isConnected = false, // 連結點是否被連接
  isFocus = false, // 所屬 block 是否被 focus
  isGoToBlockCell, // 是不是 go to block 的 cell
  setIsHoverBlock,
  ...props
}: HandlePointProps) => {
  const handleElementMouseEnter = useCallback(() => {
    if (setIsHoverBlock) {
      setIsHoverBlock(true);
    }
  }, [setIsHoverBlock]);

  const handleElementMouseLeave = useCallback(() => {
    if (setIsHoverBlock) {
      setIsHoverBlock(false);
    }
  }, [setIsHoverBlock]);

  const colorBlueGrey500 = get(globalTheme, 'palette.bluegrey.500');
  const colorInfoMain = get(globalTheme, 'palette.info.main');
  const colorErrorMain = get(globalTheme, 'palette.error.main');

  const unconnectedGoToBlockCellStyle = `
    background-image: url('${ConnectorRedIcon}');
    border: 1px solid ${colorErrorMain};
    `;

  const connectedPointStyle = `
    background-color: ${isFocus ? colorInfoMain : colorBlueGrey500};
    border: 1px solid ${isFocus ? colorInfoMain : colorBlueGrey500};

    &:hover {
    background-color: ${colorInfoMain};
    border: 1px solid ${colorInfoMain};
    }
    `;

  const unconnectedPointStyle = `
  border: 1px solid ${isFocus ? colorInfoMain : colorBlueGrey500};
  background-image: url('${isFocus ? ConnectorIcon : ''}');

  &:hover {
    background-color: ${colorInfoMain};
    border: 1px solid ${colorInfoMain};
    background-image: url('${ConnectorWhiteIcon}');
  }
  `;

  const HandleStyled = styled(Handle)`
    width: 16px;
    height: 16px;

    &.react-flow__handle {
      width: 16px;
      height: 16px;
      border: 1px solid ${colorBlueGrey500};
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;

      ${isGoToBlockCell && !isConnected && unconnectedGoToBlockCellStyle}

      ${isConnected && connectedPointStyle}

      ${!isGoToBlockCell && !isConnected && unconnectedPointStyle}
    }
  `;

  return (
    <HandleStyled
      {...props}
      onMouseEnter={handleElementMouseEnter}
      onMouseLeave={handleElementMouseLeave}
      style={{
        ...(props.type === 'source' && { right: '-8px' }),
        ...(props.type === 'target' && { opacity: 0, left: '-1px' }),
        ...(styles && styles),
      }}
    />
  );
};

export default memo(HandlePoint);
