import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PatchCouponRequestType,
  Response,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON } from '../apis';

async function patchCoupon(
  projectID: string,
  coupon: PatchCouponRequestType,
): Promise<Response> {
  if (!projectID) {
    return {
      code: 400,
      msg: 'projectID is required',
    };
  }
  return axios
    .patch(`${COUPON}?projectId=${projectID}`, coupon)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
      data: {},
    }));
}

export function usePatchCoupon(
  projectID: string,
  option?: UseApi<Response, PatchCouponRequestType>,
) {
  return useMutation({
    mutationKey: ['patch-coupon'],
    mutationFn: (props: PatchCouponRequestType) =>
      patchCoupon(projectID, props),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([
        'get-coupon',
        { projectID, couponID: variables.id },
      ]);
      queryClient.refetchQueries(['get-coupon-list']);

      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
  });
}
