import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_PERMISSION } from '../apis';

async function deleteProjectPermission(permissionID: string) {
  return axios
    .delete(`${PROJECT_PERMISSION}?permissionId=${permissionID}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteProjectPermission() {
  return useMutation({
    mutationKey: ['delete-project-permission'],
    mutationFn: (permissionID: string) => deleteProjectPermission(permissionID),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['get-project-permission']);
    },
  });
}

export default useDeleteProjectPermission;
