import { ChartDataType } from '@frontend/components/interface';
import { ChartLine } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardOverviewChart } from '@frontend/sorghum/data-access';
import {
  DashboardOverviewChartData,
  PostDataDashboardCondition,
} from '@frontend/sorghum/interface';
import { dayjs, useRwd } from '@frontend/sorghum/utils';

import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OverviewChartType {
  startDate: string;
  endDate: string;
  period: number;
  condition: PostDataDashboardCondition[];
}

export const DataDashboardOverviewChart: FC<OverviewChartType> = ({
  startDate,
  endDate,
  condition,
  period,
}: OverviewChartType) => {
  const { screenSize } = useRwd();
  const [t] = useTranslation();

  const { data: projectID } = useGetProjectID();
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const { mutate: queryData, isLoading } = usePostDataDashboardOverviewChart(
    projectID as string,
  );

  const currentLocale = dayjs().locale();

  const onSuccess = useCallback(
    (data: DashboardOverviewChartData[]) => {
      setChartData(
        data.map((i) => {
          let newName = '';
          switch (period) {
            case 1: {
              newName = dayjs(i.startDate).format('MM / DD');
              break;
            }
            case 2: {
              newName =
                dayjs(i.startDate).format('MM / DD') +
                ' - ' +
                dayjs(i.endDate).format('MM / DD');
              break;
            }
            case 3: {
              newName = dayjs(i.startDate).format('MMM');
              break;
            }
          }

          return {
            name: newName,
            [t('dataDashboard.overview.chart.users')]: i.uniqNewUsers,
            [t('dataDashboard.overview.chart.rnSubscribers')]:
              i.uniqRnSubscribers,
            [t('dataDashboard.overview.chart.activeUsers')]: i.uniqActiveUsers,
          };
        }),
      );
    },
    [period, t],
  );

  const handleQuery = useCallback(() => {
    if (startDate && endDate && projectID && period) {
      queryData(
        { startDate, endDate, period, condition },
        {
          onSuccess: onSuccess,
        },
      );
    }
  }, [condition, endDate, onSuccess, period, projectID, queryData, startDate]);

  useEffect(handleQuery, [handleQuery, currentLocale]);

  return (
    <ChartLine
      data={chartData}
      title={t('dataDashboard.numberOfUniqueUsers')}
      isLoading={isLoading}
      showMask={screenSize.x < 1024}
    />
  );
};

export default DataDashboardOverviewChart;
