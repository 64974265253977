import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasGet,
  useCanvasUpdate,
  useCanvasView,
  useGetCouponOption,
  useGetProjectID,
  useModal,
} from '@frontend/editor/data-access';
import {
  CouponCellType,
  CouponCellTypesEnum,
  CouponType,
  CouponTypesEnum,
  DrawerTypesEnum,
} from '@frontend/editor/interface';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { get } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import { EditorButton } from '../editor-button/editor-button';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';
import {
  BodyStyled,
  ItemWrapperStyled,
  TitleWrapperStyled,
  ValueWrapperStyled,
} from './styles';

export const NodeCoupon = ({ id }: NodeProps) => {
  const [t] = useTranslation();
  const { zoom } = useViewport();
  const { data: projectID } = useGetProjectID();
  const { getTargetElement } = useCanvasGet();
  const { canvasUpdateLabel } = useCanvasUpdate();
  const { openDeleteBlockModal } = useModal();
  const { onFocusID, focusOn, handleDrawer } = useCanvasView();
  const [isHoverBlock, setIsHoverBlock] = useState<boolean>(false);
  const { data: couponOptions } = useGetCouponOption({
    projectID: projectID as string,
  });

  const elementData = getTargetElement(id) as CouponType;
  const header = get(elementData, 'label', '');
  const inputID = get(elementData, 'inputID', '');
  const couponValue = get(elementData, 'couponValue', '');
  const children = get(elementData, 'children', []) as string[];

  const couponName = useMemo(() => {
    return couponOptions?.find((i) => i.id === couponValue)?.name;
  }, [couponOptions, couponValue]);

  const { title } = useMemo(() => {
    switch (elementData.type) {
      case CouponTypesEnum.SEND:
        return {
          title: t('canvas.coupon.send.title'),
        };
      case CouponTypesEnum.SHOW:
        return {
          title: t('canvas.coupon.show.title'),
        };
      case CouponTypesEnum.REDEEM:
      default:
        return {
          title: t('canvas.coupon.redeem.title'),
        };
    }
  }, [elementData.type, t]);

  const onRemoveButtonClick = useCallback(() => {
    openDeleteBlockModal(id);
  }, [id, openDeleteBlockModal]);

  const handleTitleClick = useCallback(() => {
    focusOn(id);
    handleDrawer(DrawerTypesEnum.COUPON);
  }, [focusOn, handleDrawer, id]);

  return (
    <EditorBlockContainer nodeID={id}>
      <HandlePoint
        id={inputID}
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
        setIsHoverBlock={setIsHoverBlock}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        color={get(globalTheme, 'palette.grass.600', '')}
        onMouseEnter={() => setIsHoverBlock(true)}
        onMouseLeave={() => setIsHoverBlock(false)}
      >
        {/* Block 上方的刪除和 ... 按鈕 */}
        {(isHoverBlock || onFocusID === id) && (
          <EditorFloatPanel
            direction="row"
            backgroundColor="rgba(96, 125, 139, 0.1)"
            handleDelete={onRemoveButtonClick}
          />
        )}

        <NodeHeader
          background={get(globalTheme, 'palette.grass.50', '')}
          color={get(globalTheme, 'palette.grass.600', '')}
          icon={<FilterAltIcon fontSize="small" />}
          title={header}
          onBlur={(val) => canvasUpdateLabel(id, val)}
        />

        <BodyStyled>
          <ItemWrapperStyled
            $isFocus={onFocusID === id}
            $isHover={onFocusID === id}
          >
            <TitleWrapperStyled onClick={handleTitleClick}>
              <Typography
                sx={{ marginRight: '12px' }}
                variant="subtitle2"
                color="grey.900"
              >
                {title}
              </Typography>
              {!couponValue && (
                <Tooltip
                  title={t('canvas.coupon.redeem.tooltipRedeem')}
                  placement="top"
                >
                  <IconButton size="small" color="error">
                    <WarningAmberOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </TitleWrapperStyled>
            <ValueWrapperStyled>
              {couponName ? (
                <Typography variant="caption" color="primary">
                  {couponName}
                </Typography>
              ) : (
                <Typography variant="caption" color="bluegrey.400">
                  <Trans i18nKey="canvas.coupon.alert" />
                </Typography>
              )}
            </ValueWrapperStyled>
            {children.map((cellID: string) => {
              const cellData = getTargetElement(cellID) as CouponCellType;
              const cellDataType = cellData.cellType;
              let cellLabel = '';

              switch (elementData.type) {
                case CouponTypesEnum.SEND: {
                  switch (cellDataType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      cellLabel = t('canvas.coupon.send.success');
                      break;
                    }
                    case CouponCellTypesEnum.EXHAUSTED: {
                      cellLabel = t('canvas.coupon.send.exhausted');
                      break;
                    }
                    case CouponCellTypesEnum.LIMIT: {
                      cellLabel = t('canvas.coupon.send.limit');
                      break;
                    }
                  }
                  break;
                }
                case CouponTypesEnum.SHOW: {
                  switch (cellDataType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      cellLabel = t('canvas.coupon.show.success');
                      break;
                    }
                    case CouponCellTypesEnum.FAIL: {
                      cellLabel = t('canvas.coupon.show.fail');
                      break;
                    }
                  }
                  break;
                }
                case CouponTypesEnum.REDEEM: {
                  switch (cellDataType) {
                    case CouponCellTypesEnum.SUCCESS: {
                      cellLabel = t('canvas.coupon.redeem.success');
                      break;
                    }
                    case CouponCellTypesEnum.FAIL: {
                      cellLabel = t('canvas.coupon.redeem.fail');
                      break;
                    }
                  }
                  break;
                }
              }

              return (
                <EditorButton
                  key={cellID}
                  outputID={cellData.outputID}
                  setIsHoverBlock={setIsHoverBlock}
                >
                  {cellLabel}
                </EditorButton>
              );
            })}
          </ItemWrapperStyled>
        </BodyStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeCoupon);
