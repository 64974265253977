import { CouponOption } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_OPTION } from '../apis';

interface GetCouponOptionProps {
  projectID: string;
}

async function getCouponOption({
  projectID,
}: GetCouponOptionProps): Promise<CouponOption[]> {
  return axios
    .get(COUPON_OPTION, {
      params: {
        projectId: projectID,
      },
    })
    .then((res) => res.data.data)
    .catch((err) => []);
}

export function useGetCouponOption({ projectID }: GetCouponOptionProps) {
  return useQuery<CouponOption[]>({
    queryKey: ['get-coupon-option', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getCouponOption({ projectID });
      } else {
        return [];
      }
    },
  });
}

export default useGetCouponOption;
