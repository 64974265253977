import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EntryPointStatus } from '@frontend/sorghum/interface';
import { FLOW_ENTRY_STATUS } from '../apis';

const defaultValue: EntryPointStatus = {
  welcomeMessage: {
    isUsed: false,
    flow: {
      id: 'uuid',
      name: 'flow 1',
    },
  },
  defaultAnswer: {
    isUsed: false,
    flow: {
      id: 'uuid',
      name: 'flow 1',
    },
  },
};

async function getEditorEntryStatus(
  socialType: number,
  projectId: string,
): Promise<EntryPointStatus> {
  return axios
    .get(FLOW_ENTRY_STATUS, {
      params: {
        socialType,
        projectId,
      },
    })
    .then((res) => res.data.data)
    .catch(() => defaultValue);
}

// 查詢 entry-point 可設定的選單
export function useGetEditorEntryStatus(socialType: number, projectId: string) {
  return useQuery<EntryPointStatus>({
    queryKey: ['get-editor-entry-status', { socialType, projectId }],
    queryFn: () => {
      if (socialType && projectId) {
        return getEditorEntryStatus(socialType, projectId);
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetEditorEntryStatus;
