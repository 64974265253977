import { useCanvasView } from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { Box, BoxProps, styled } from '@mui/material';
import { useCallback, useContext } from 'react';

interface EditorBlockContainerProps extends BoxProps {
  nodeID: string;
}

const ContainerStyled = styled(Box)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => ({
    ...($disabled && {
      '& > div': {
        pointerEvents: 'none',
      },
    }),
  }),
);

export const EditorBlockContainer = ({
  nodeID,
  children,
  ...props
}: EditorBlockContainerProps) => {
  const state = useContext(EditorCtx);
  const { focusOn } = useCanvasView();

  // 設定 hover 目標，讓拖曳線段時可以得知連線目標，整個 block 都可以連線
  const handleNodeMouseEnter = useCallback(() => {
    state.setOnHoverNode(nodeID);
  }, [nodeID, state]);

  const handleNodeMouseLeave = useCallback(() => {
    state.setOnHoverNode('');
  }, [state]);

  return (
    <ContainerStyled
      {...props}
      id={nodeID}
      $disabled={state.tourMode}
      onClickCapture={() => focusOn(nodeID)}
      onMouseEnter={handleNodeMouseEnter}
      onMouseLeave={handleNodeMouseLeave}
    >
      {children}
    </ContainerStyled>
  );
};

export default EditorBlockContainer;
