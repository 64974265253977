import { NoticeBar } from '@frontend/components/ui';
import {
  useGetProjectID,
  useGetProjectNotibarInfo,
} from '@frontend/editor/data-access';
import {
  useGetProject,
  usePostNewBroadcast,
  useProjectLinked
} from '@frontend/sorghum/data-access';
import {
  NotificationCtx,
  UICtx,
  WebSocketCtx,
} from '@frontend/sorghum/external-providers';
import {
  NotificationTypeEnum,
  ResponseWithData,
  WebSocketEventNameEnum,
  WebSocketResponse,
  WebSocketTypeEnum,
} from '@frontend/sorghum/interface';
import {
  DRAFT,
  PAGE_BROADCAST,
  PAGE_FLOW_ENTRY,
  PAGE_SETTING,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DashboardNoticeBarProps {
  redirectTo: (page?: string) => void;
}

const sendGAEventWhenNoticeBarIsClicked = () => {
  sendGAEvent(
    'Automated Messaging',
    'notibar Hyperlink',
    'Automated Messaging - notibar - send first broadcast - click',
    '',
  );
};

export const DashboardNoticeBar: FC<DashboardNoticeBarProps> = ({
  redirectTo,
}) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const notificationState = useContext(NotificationCtx);
  const webSocketState = useContext(WebSocketCtx);
  const { data: notibar, isFetched } = useGetProjectNotibarInfo(
    projectID as string,
  );
  const { data: linked } = useProjectLinked(projectID as string);
  const [isChange, setIsChange] = useState<boolean>(true);
  const { mutate: addNewBroadcast } = usePostNewBroadcast(projectID as string);
  const { data: project } = useGetProject(projectID as string);

  const handleAddButtonClick = useCallback(() => {
    sendGAEventWhenNoticeBarIsClicked();
    addNewBroadcast(t('broadcast.defaultBroadcastName'), {
      onSuccess: (data) => {
        if (data.code === 20000) {
          navigateToProjectPage(
            `${PAGE_BROADCAST}/${data.data.broadcastId}/${DRAFT}`,
          );
          uiState.openRenameBroadcastModal(data.data.broadcastId);
        }
      },
    });
  }, [addNewBroadcast, navigateToProjectPage, t, uiState]);

  useEffect(() => {
    const handleMessage = (data: ResponseWithData<WebSocketResponse>) => {
      switch (data.data.notificationType) {
        case WebSocketTypeEnum.NEW_RN_RECEIVER:
          notificationState.addNoticeBarType(
            NotificationTypeEnum.NEW_RN_RECEIVER,
          );
          sendGAEvent(
            'Automated Messaging',
            'notibar view',
            'Automated Messaging - notibar - send first broadcast - view',
            '',
          );
          break;
        case WebSocketTypeEnum.SEND_FIRST_RN:
          notificationState.removeNoticeBarType(
            NotificationTypeEnum.NEW_RN_RECEIVER,
          );
          break;
        case WebSocketTypeEnum.SEND_RN_UNPUBLISHED_FLOW:
          notificationState.addNoticeBarType(
            NotificationTypeEnum.FLOW_UNPUBLISHED,
          );
          sendGAEvent(
            'Automated Messaging',
            'notibar view',
            'Automated Messaging - notibar - send first broadcast - view',
            '',
          );
          break;
        case WebSocketTypeEnum.PUBLISHED_FIRST_FLOW:
          notificationState.removeNoticeBarType(
            NotificationTypeEnum.FLOW_UNPUBLISHED,
          );
          break;
        default:
          break;
      }
    };
    const callback = webSocketState.addMessageEventListener(
      WebSocketEventNameEnum.NOTIBAR,
      handleMessage,
    );

    return () => {
      webSocketState.removeMessageEventListener(
        WebSocketEventNameEnum.NOTIBAR,
        callback,
      );
    };
  }, [notificationState, webSocketState]);

  useEffect(() => {
    // 沒有綁定粉專
    if (!linked?.fb?.id) {
      notificationState.addNoticeBarType(
        NotificationTypeEnum.FACEBOOK_PAGE_DISCONNECT,
      );
    } else {
      notificationState.removeNoticeBarType(
        NotificationTypeEnum.FACEBOOK_PAGE_DISCONNECT,
      );
    }
  }, [linked?.fb?.id, notificationState]);

  useEffect(() => {
    if (isFetched) {
      setIsChange(true);
    }
    // 切換專案時要重置 notice bar 的狀態
  }, [isFetched, projectID]);

  useEffect(() => {
    if (isChange) {
      // 累積了至少 1 個 rn 用戶，但未曾發過推播
      if (notibar?.notificationType === 1) {
        notificationState.addNoticeBarType(
          NotificationTypeEnum.NEW_RN_RECEIVER,
        );
        sendGAEvent(
          'Automated Messaging',
          'notibar view',
          'Automated Messaging - notibar - send first broadcast - view',
          '',
        );
      } else {
        notificationState.removeNoticeBarType(
          NotificationTypeEnum.NEW_RN_RECEIVER,
        );
      }
      if (notibar?.notificationType === 3) {
        //3: 專案已成功發過至少 1 則推播以及未曾發布過 Flow
        notificationState.addNoticeBarType(
          NotificationTypeEnum.FLOW_UNPUBLISHED,
        );
      } else {
        notificationState.removeNoticeBarType(
          NotificationTypeEnum.FLOW_UNPUBLISHED,
        );
      }

      setIsChange(false);
    }
  }, [isChange, isFetched, notibar?.notificationType, notificationState]);

  useEffect(() => {
    // 為 beta 用戶
    if (project && project.status === 1) {
      notificationState.addNoticeBarType(
        NotificationTypeEnum.TRIAL_EXPIRATION,
      );
    } else {
      notificationState.removeNoticeBarType(
        NotificationTypeEnum.TRIAL_EXPIRATION,
      );
    }
  }, [project, notificationState]);

  switch (notificationState.noticeBarType) {
    case NotificationTypeEnum.TRIAL_EXPIRATION: {
      return (
        <NoticeBar
          message={t('alert.trialExpiration')}
          type='info'
        />
      );
    }
    case NotificationTypeEnum.FACEBOOK_PAGE_DISCONNECT: {
      return (
        <NoticeBar
          message={t('alert.fanPageDisconnected')}
          linkText={t('alert.connect')}
          onLinkClick={() => {
            sendGAEventWhenNoticeBarIsClicked();
            redirectTo(PAGE_SETTING);
          }}
        />
      );
    }
    case NotificationTypeEnum.NEW_RN_RECEIVER: {
      return (
        <NoticeBar
          message={t('alert.firstSubscribeRN')}
          linkText={t('common.GO')}
          onLinkClick={handleAddButtonClick}
        />
      );
    }
    case NotificationTypeEnum.FLOW_UNPUBLISHED: {
      return (
        <NoticeBar
          message={t('alert.publishFirstFlow')}
          linkText={t('common.GO')}
          onLinkClick={() => {
            sendGAEventWhenNoticeBarIsClicked();
            redirectTo(PAGE_FLOW_ENTRY);
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default DashboardNoticeBar;
