import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_PIN } from '../apis';

interface Props {
  projectId: string;
  flowID: string;
}

async function deleteEditorFlowPin(props: Props): Promise<Response> {
  return axios
    .delete(`${FLOW_PIN}?projectId=${props.projectId}&flowId=${props.flowID}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteEditorFlowPin() {
  return useMutation({
    mutationKey: ['post-editor-flow-pin'],
    mutationFn: (props: Props) => deleteEditorFlowPin(props),
    onSuccess: (data: Response) => {
      queryClient.refetchQueries(['flow-list']);
      // toast.success(data.msg);
    },
  });
}

export default useDeleteEditorFlowPin;
