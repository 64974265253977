import { queryClient } from '@frontend/sorghum/external-providers';
import {
  ProjectDisconnectProps,
  Response,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_DISCONNECT } from '../apis';

async function postProjectDisconnect(
  props: ProjectDisconnectProps,
): Promise<Response> {
  return axios.post(PROJECT_DISCONNECT, props).then((res) => res.data);
}

export function useProjectDisconnect(
  props?: UseApi<Response, ProjectDisconnectProps>,
) {
  return useMutation({
    mutationKey: ['project-disconnect'],
    mutationFn: (props: ProjectDisconnectProps) => postProjectDisconnect(props),
    onSuccess: (data, res) => {
      queryClient.invalidateQueries(['facebook-pages']);
      queryClient.invalidateQueries(['project-linked']);
      queryClient.invalidateQueries(['get-project']);
      queryClient.invalidateQueries(['get-project-list']);

      if (props?.onSuccess) {
        props.onSuccess(data, res);
      }
    },
  });
}

export default useProjectDisconnect;
