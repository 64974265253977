import { globalTheme } from '@frontend/components/external-providers';
import { TextareaTooltip } from '@frontend/components/ui';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { get, isNull } from 'lodash';
import {
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface EditorLabelProps {
  defaultValue?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  color?: string;
  error?: boolean | string;
  size?: 'subtitle1' | 'subtitle2' | 'h6';
  maxLength?: number;
  onBlur?: (val: string) => void;
  isShowTooltips?: boolean;
  onFocusLabel?: (isFocus: boolean) => void;
  placeholder?: string;
  helperText?: string;
  inputRef?: MutableRefObject<null>;
}

const EditorLabelContainerStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const TextFieldStyled = styled(TextField)(({ theme, disabled }) => ({
  width: '100%',
  '& fieldset': {
    borderColor: `transparent ${disabled ? '!important' : ''}`,
  },
}));

export const EditorLabel = ({
  color,
  defaultValue,
  error,
  size = 'subtitle1',
  maxLength = 20,
  disabled = false,
  fullWidth = false,
  onBlur,
  isShowTooltips = false,
  onFocusLabel,
  placeholder,
  helperText = '',
  inputRef,
}: EditorLabelProps) => {
  const [value, setValue] = useState<string>(defaultValue ? defaultValue : '');
  const [isFocusLabel, setIsFocusLabel] = useState<boolean>(false);

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur(value);
    }
    setIsFocusLabel(false);
  }, [onBlur, value]);

  const handleFocusLabel = useCallback(() => {
    if (onFocusLabel) {
      onFocusLabel(true);
    }
    setIsFocusLabel(true);
  }, [onFocusLabel]);

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <EditorLabelContainerStyled>
      <TextFieldStyled
        placeholder={placeholder}
        fullWidth={fullWidth}
        className="nodrag"
        onBlur={handleBlur}
        inputRef={!isNull(inputRef) ? inputRef : undefined}
        autoComplete="off"
        disabled={disabled}
        inputProps={{
          style: {
            color,
            padding: '4px 8px',
            ...get(globalTheme, `typography.${size}`, {}),
          },
          maxLength: maxLength,
        }}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        error={!!error}
        helperText={helperText}
        onClick={(e) => {
          e.stopPropagation();
          handleFocusLabel();
        }}
      />
      {isShowTooltips && isFocusLabel && (
        <TextareaTooltip
          limit={maxLength}
          length={value.length}
          sx={{ top: '35px', height: '100%' }}
        />
      )}
    </EditorLabelContainerStyled>
  );
};

export default memo(EditorLabel);
