import { EditorCtx } from '@frontend/editor/external-providers';
import { useCallback, useContext } from 'react';

export interface UseCanvasRemove {
  canvasRemoveBlockByID: (id: string) => void;
  canvasRemoveBlock: () => void;
  canvasRemoveElement: (id?: string) => void;
  canvasRemoveCell: (id: string) => void;
}

export function useCanvasRemove(): UseCanvasRemove {
  const state = useContext(EditorCtx);

  const canvasRemoveBlock = useCallback(() => {
    if (state.targetElementID) {
      state.removeElement(state.targetElementID);
    }
  }, [state]);

  const canvasRemoveBlockByID = useCallback(
    (id: string) => {
      state.removeElement(id);
    },
    [state],
  );

  const canvasRemoveElement = useCallback(
    (id?: string) => {
      // 如果沒有指定 id 則從 state 儲存的 target 為目標
      if (id) {
        state.removeElement(id);
      } else if (state.targetElementID) {
        state.removeElement(state.targetElementID);
      }
    },
    [state],
  );

  const canvasRemoveCell = useCallback(
    (id: string) => {
      state.removeElement(id);
    },
    [state],
  );
  return {
    canvasRemoveBlockByID,
    canvasRemoveBlock,
    canvasRemoveElement,
    canvasRemoveCell,
  };
}

export default useCanvasRemove;
