import { Dialog } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetBroadcastData,
  usePatchBroadcast,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function RenameBroadcastModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  const { data: projectID } = useGetProjectID();
  const { data: broadcastData } = useGetBroadcastData(
    projectID as string,
    uiState.modalType === ModalTypesEnum.RENAME_BROADCAST ? uiState.flowID : '',
  );

  const [name, setName] = useState(broadcastData?.name ?? '');

  const { mutate: updateBroadcast } = usePatchBroadcast();

  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleConfirm = useCallback(() => {
    if (!broadcastData) return;

    if (isEmpty(name)) {
      setErrorText(t('modal.renameBroadcast.errorEmptyName'));
      setIsError(true);
      return;
    } else if (name.length > 100) {
      setErrorText(t('modal.renameBroadcast.errorExceedMaximumLength'));
      setIsError(true);
      return;
    } else {
      setErrorText('');
      setIsError(false);
      updateBroadcast({
        projectID: projectID as string,
        data: {
          id: broadcastData.id,
          name: name,
        },
      });

      uiState.closeModal();
    }
  }, [broadcastData, name, projectID, uiState, updateBroadcast, t]);

  const handleCloseModal = useCallback(() => {
    uiState.closeModal();
    setName('');
    setErrorText('');
    setIsError(false);
  }, [uiState]);

  useEffect(() => {
    if (
      uiState.modalType === ModalTypesEnum.RENAME_BROADCAST &&
      broadcastData
    ) {
      setName(broadcastData.name);
    }
  }, [broadcastData, uiState.modalType]);

  if (uiState.modalType !== ModalTypesEnum.RENAME_BROADCAST) {
    return null;
  }

  return (
    <Dialog
      size="xs"
      title={t('common.rename')}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.ok')}
      closeBtn={false}
      open={uiState.modalType === ModalTypesEnum.RENAME_BROADCAST}
      handleClose={handleCloseModal}
      handleConfirm={handleConfirm}
    >
      <Box>
        <TextField
          fullWidth
          autoFocus
          onChange={(e) => setName(e.target.value)}
          size="small"
          value={name}
          label={t('modal.renameBroadcast.title')}
          helperText={errorText}
          error={isError}
        />
      </Box>
    </Dialog>
  );
}

export default RenameBroadcastModal;
