import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_ENTRY } from '../apis';
import {
  ResponseWithData,
  UseApi,
  UsePostEditorFlowEntryResponse,
  UsePostEditorFlowEntryProps,
} from '@frontend/sorghum/interface';
import { queryClient } from '@frontend/sorghum/external-providers';

async function postEditorFlowEntry(
  projectID: string,
  flowID: string,
  socialType: 1 | 2,
  props: UsePostEditorFlowEntryProps,
): Promise<ResponseWithData<UsePostEditorFlowEntryResponse>> {
  return axios
    .post(
      `${FLOW_ENTRY}?projectId=${projectID}&flowId=${flowID}&socialType=${socialType}`,
      { ...props, idx: props.index },
    )
    .then((res) => res.data);
}

export function usePostEditorFlowEntry(
  projectID: string,
  flowID: string,
  socialType: 1 | 2,
  options?: UseApi<
    ResponseWithData<UsePostEditorFlowEntryResponse>,
    UsePostEditorFlowEntryProps
  >,
) {
  return useMutation({
    mutationKey: ['post-editor-flow-entry', projectID, flowID, socialType],
    mutationFn: (props: UsePostEditorFlowEntryProps) =>
      postEditorFlowEntry(projectID, flowID, socialType, props),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['get-editor-flow-entry']);
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}

export default usePostEditorFlowEntry;
