import { Switch } from '@frontend/components/ui';
import {
  convertNumberToPercentage,
  formatNumberWithCommas,
} from '@frontend/editor/utils';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { isNumber } from 'lodash';
import { memo } from 'react';
import { Trans } from 'react-i18next';
import 'reactflow/dist/style.css';

interface DrawerViewTriggerCompareEntryItemProps {
  title: string;
  sent?: number;
  cvr?: number;
  enable?: boolean;
  icon: JSX.Element;
}

const ContainerWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '12px',
  width: '100%',
  borderRadius: '4px',
  background: theme.palette['grey']['50'],
  border: `1px solid ${theme.palette['grey']['200']}`,
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
}));

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '8px',
}));

const TitleItemWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  gap: '8px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  width: '100%',
}));

const InfoItemWrapperStyled = styled(Box)(({ theme }) => ({
  flex: 1,
  gap: '20px',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignContent: 'center',
}));

export const DrawerViewTriggerCompareEntryItem = ({
  title,
  sent,
  cvr,
  enable,
  icon,
}: DrawerViewTriggerCompareEntryItemProps) => {
  const sentNumber = isNumber(sent) ? formatNumberWithCommas(sent) : '-';

  const cvrPercentage = isNumber(cvr) ? convertNumberToPercentage(cvr) : '-';

  // TODO: css 樣式，數字轉千分位逗號，icon 顯示
  return (
    <ContainerWrapperStyled>
      <TitleWrapperStyled>
        <TitleItemWrapperStyled>
          {icon}
          <Typography
            sx={{
              mb: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
            }}
            variant="body1"
            color="bluegrey.500"
          >
            {title}
          </Typography>
        </TitleItemWrapperStyled>

        {(enable === true || enable === false) && (
          <Box>
            <Switch size="small" checked={enable} disabled />
          </Box>
        )}
      </TitleWrapperStyled>
      <InfoWrapperStyled>
        <InfoItemWrapperStyled>
          <Typography variant="body3" color="grey.500">
            <Trans i18nKey="view.drawer.sent.title" />
          </Typography>
          <Typography
            sx={{
              mr: '8px',
            }}
            variant="body3"
            color="primary"
          >
            {sentNumber}
          </Typography>
        </InfoItemWrapperStyled>
        <InfoItemWrapperStyled>
          <Typography sx={{}} variant="body3" color="grey.500">
            <Trans i18nKey="view.drawer.compareEntry.cvr" />
          </Typography>
          <Typography sx={{}} variant="body3" color="primary">
            {cvrPercentage ? cvrPercentage : 0}
          </Typography>
        </InfoItemWrapperStyled>
      </InfoWrapperStyled>
    </ContainerWrapperStyled>
  );
};

export default memo(DrawerViewTriggerCompareEntryItem);
