import { Box } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import Typography from '../typography/typography';

export interface ProgressBarProps {
  title?: string;
  percent: number;
}

const ProgressBarContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const ProgressBarStyled = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 2,
  // 進度條空值的顏色
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette['grey']['200'],
  },
  // 進度條本身的顏色
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette['primary']['main'],
  },
}));

export function ProgressBar({ title, percent }: ProgressBarProps) {
  return (
    <ProgressBarContainerStyled>
      <Typography variant="caption" color="grey.500" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <ProgressBarStyled
        color="primary"
        variant="determinate"
        value={percent}
      />
    </ProgressBarContainerStyled>
  );
}

export default ProgressBar;
