import { ReactElement, RefObject } from 'react';

export enum PlacementEnum {
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
}

export interface TourTooltipProps {
  // react ref for element id
  target: RefObject<HTMLElement> | string;
  title?: string;
  description?: string;
  pagination?: string;
  image?: string;
  imageType?: 'video' | 'image';
  imageSize?: 'large' | 'small';
  button?: ReactElement;
  nextButtonText?: string;
  prevButtonText?: string;
  placement?: PlacementEnum;
  onClose?: () => void;
  nextButtonClick?: () => void;
  prevButtonClick?: () => void;
}
