import { Autocomplete, OptionType } from '@frontend/components/ui';
import { useProjectList } from '@frontend/sorghum/data-access';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const formId = 'projectId';

export const ProjectSelect = () => {
  const [t] = useTranslation();
  const [list, setList] = useState<OptionType[]>([]);

  const { data: projects } = useProjectList();

  useEffect(() => {
    if (projects?.code === 20000) {
      const newOptionFlows: OptionType[] = [];
      projects.data.forEach((i) => {
        newOptionFlows.push({
          label: i.projectName,
          value: i.projectId,
        });
      }, []);
      setList(newOptionFlows);
    }
  }, [projects]);

  return (
    <Box>
      <Autocomplete label={t('modal.bot')} options={list} formName={formId} />
    </Box>
  );
};

export default ProjectSelect;
