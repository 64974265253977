import { GetEditorMenuType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_MENU } from '../apis';

async function getGetEditorMenu(
  projectId: string,
  socialType: number,
): Promise<GetEditorMenuType> {
  return axios
    .get(`${EDITOR_MENU}?projectId=${projectId}&socialType=${socialType}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return {
        code: 400,
        msg: 'catch',
        data: {},
      };
    });
}

export function useGetEditorMenu(projectID: string, socialType: 1 | 2) {
  return useQuery<GetEditorMenuType>({
    queryKey: ['get-editor-menu', { projectID, socialType }],
    queryFn: () => {
      if (projectID && socialType) {
        return getGetEditorMenu(projectID, socialType);
      } else {
        return {
          status: 1,
          items: [],
          enable: false,
        } as GetEditorMenuType;
      }
    },
  });
}

export default useGetEditorMenu;
