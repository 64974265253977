import { EditorCtx } from '@frontend/editor/external-providers';
import { EntryPointsEnum, FlowEntryPoint } from '@frontend/editor/interface';
import { Box } from '@mui/material';
import { ComponentClass, FC, useContext, useState } from 'react';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
} from 'react-sortable-hoc';
import { useViewport } from 'reactflow';
import FloatPanel from './node-entry-point-float-panel';
import EntryPointItem from './node-entry-point-item';
import { SortableItemStyled } from './styles';

interface NodeEntryPointListProps {
  items: FlowEntryPoint[];
  deleteEntryPoint: (entryID: string) => void;
  sortEntryPoint: ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => void;
  handleAdd: (ref: HTMLElement, index: number) => void;
}

interface SortableListProps {
  items: FlowEntryPoint[];
  deleteEntryPoint: (entryID: string) => void;
  handleAdd: (ref: HTMLElement, index: number) => void;
}

interface SortableItemProps {
  item: FlowEntryPoint;
  draggable: boolean;
  deleteEntryPoint: (entryID: string) => void;
  handleAdd: (ref: HTMLElement) => void;
}

const SortableItem: ComponentClass<SortableElementProps & SortableItemProps> =
  SortableElement(
    ({ item, draggable, deleteEntryPoint, handleAdd }: SortableItemProps) => {
      const state = useContext(EditorCtx);
      const { zoom } = useViewport();
      const [isHover, setIsHover] = useState(false);

      return (
        <SortableItemStyled
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          $zoom={zoom}
        >
          {isHover && !state.readonly && (
            <FloatPanel
              draggable={draggable}
              direction="column"
              {...(item.type === EntryPointsEnum.BOT_LINK && {
                handleDelete: () => deleteEntryPoint(item.id),
              })}
              handleAdd={handleAdd}
            />
          )}
          <EntryPointItem item={item} />
        </SortableItemStyled>
      );
    },
  );

const SortableList: ComponentClass<SortableContainerProps & SortableListProps> =
  SortableContainer(
    ({ items, deleteEntryPoint, handleAdd }: SortableListProps) => {
      return (
        <Box>
          {items &&
            items.map((item, index: number) => (
              <SortableItem
                key={item.id}
                index={index}
                item={item}
                draggable={items.length > 1}
                handleAdd={(ref: HTMLElement) => handleAdd(ref, index)}
                deleteEntryPoint={deleteEntryPoint}
              />
            ))}
        </Box>
      );
    },
  );

const NodeEntryPointList: FC<NodeEntryPointListProps> = ({
  items,
  sortEntryPoint,
  deleteEntryPoint,
  handleAdd,
}: NodeEntryPointListProps) => {
  return (
    <SortableList
      items={items}
      useDragHandle
      deleteEntryPoint={deleteEntryPoint}
      onSortEnd={sortEntryPoint}
      handleAdd={handleAdd}
      lockAxis="y"
      // 免縮放時拖曳會改變大小
      helperClass="node_item_dragged_style"
    />
  );
};

export default NodeEntryPointList;
