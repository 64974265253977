import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const useModal = () => {
  const [t] = useTranslation();
  const state = useContext(UICtx);
  const editorState = useContext(EditorCtx);

  const openDeleteBlockModal = useCallback(
    (blockId: string) => {
      editorState.setTargetElementID(blockId);
      state.openModal(ModalTypesEnum.DELETE_BLOCK);
    },
    [state, editorState],
  );

  const openDeleteElementModal = useCallback(
    (targetID: string) => {
      editorState.setTargetElementID(targetID);
      state.openModal(ModalTypesEnum.DELETE_ELEMENT);
    },
    [state, editorState],
  );

  const openDeleteTextButtonModal = useCallback(
    (targetID: string) => {
      editorState.setTargetElementID(targetID);
      state.openModal(ModalTypesEnum.DELETE_TEXT_BUTTON);
    },
    [state, editorState],
  );

  const openDeleteTextButtonCellModal = useCallback(
    (handleDelete: () => void) => {
      state.openModal(ModalTypesEnum.DELETE);
      state.setModalState({
        title: t('canvas.modal.deleteTextButtonCell.title'),
        content: t('canvas.modal.deleteTextButtonCell.content'),
        info: t('canvas.modal.deleteTextButtonCell.info'),
        handleConfirm: () => {
          handleDelete();
          state.closeModal();
        },
      });
    },
    [state, t],
  );

  const openDeleteGalleryModal = useCallback(
    (handleDelete: () => void) => {
      state.openModal(ModalTypesEnum.DELETE);
      state.setModalState({
        title: t('canvas.modal.deleteGallery.title'),
        content: t('canvas.modal.deleteGallery.content'),
        info: t('canvas.modal.deleteGallery.info'),
        handleConfirm: () => {
          handleDelete();
          state.closeModal();
        },
      });
    },
    [state, t],
  );
  const openDeleteGalleryImageModal = useCallback(
    (handleDelete: () => void) => {
      state.openModal(ModalTypesEnum.DELETE);
      state.setModalState({
        title: t('canvas.modal.deleteGalleryImage.title'),
        content: t('canvas.modal.deleteGallery.content'),
        info: t('canvas.modal.deleteGallery.info'),
        handleConfirm: () => {
          handleDelete();
          state.closeModal();
        },
      });
    },
    [state, t],
  );

  const openDeleteConfirmModal = useCallback(
    (handleDelete: () => void) => {
      state.openModal(ModalTypesEnum.DELETE);
      state.setModalState({
        title: t('common.delete'),
        content: t('canvas.modal.deleteTextButtonCell.content'),
        info: t('canvas.modal.deleteTextButtonCell.info'),
        handleConfirm: () => {
          handleDelete();
          state.closeModal();
        },
      });
    },
    [state, t],
  );

  const openAddPersistentMenuModal = useCallback(() => {
    state.openModal(ModalTypesEnum.ADD_PERSISTENT_MENU);
  }, [state]);

  const openAddCommentAutoReplyModal = useCallback(() => {
    state.openModal(ModalTypesEnum.ADD_COMMENT_AUTO_REPLY);
  }, [state]);

  return {
    openModal: state.modalType,
    modalState: state.modalState,
    closeModal: state.closeModal,
    openAddCommentAutoReplyModal,
    openDeleteTextButtonCellModal,
    openAddPersistentMenuModal,
    openDeleteBlockModal,
    openDeleteElementModal,
    openDeleteTextButtonModal,
    openDeleteConfirmModal,
    openDeleteGalleryModal,
    openDeleteGalleryImageModal,
  };
};

export default useModal;
