import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';

const NoticeBoxContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
}));

const NoticeBoxWrapperStyled = styled(Box)(({ theme }) => ({
  background: theme.palette['info']['light'],
  position: 'absolute',
  top: '8px',
  borderRadius: 4,
  border: `1px solid ${theme.palette['grey'][300]}`,
  display: 'flex',
  width: '360px',
  padding: '8px 24px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  zIndex: 98,
}));

const MessageStyled = styled(Typography)(({ theme }) => ({
  marginRight: '24px',
  whiteSpace: 'nowrap',
}));

const LinkStyled = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  height: '13px',
}));

interface NoticeBoxProps {
  message: string;
  linkText?: string;
  onLinkClick?: () => void;
  id?: string;
}

export const NoticeBox: FC<NoticeBoxProps> = ({
  message,
  linkText,
  onLinkClick,
  id,
}: NoticeBoxProps) => {
  return (
    <NoticeBoxContainerStyled id={id}>
      <NoticeBoxWrapperStyled>
        <MessageStyled variant="body2" color="info.dark">
          {message}
        </MessageStyled>
        {linkText && (
          <LinkStyled
            variant="notoSans"
            color="info.main"
            onClick={onLinkClick}
          >
            {linkText}
          </LinkStyled>
        )}
      </NoticeBoxWrapperStyled>
    </NoticeBoxContainerStyled>
  );
};

export default NoticeBox;
