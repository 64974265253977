import { TrendingStatusEnum } from '@frontend/components/interface';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

/* eslint-disable-next-line */
export interface TrendingPercentageProps {
  status: TrendingStatusEnum;
  percentage?: number;
}

const TagContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '4px',
  backgroundColor: `${theme.palette['bluegrey']['500']}0D`,
  padding: '4px 8px',
  width: '70px',

  svg: {
    marginRight: '4px',
  },

  '&.down': {
    color: theme.palette['error']['main'],
    backgroundColor: `${theme.palette['error']['main']}0D`,
  },

  '&.up': {
    color: theme.palette['success']['main'],
    backgroundColor: `${theme.palette['success']['main']}0D`,
  },
}));

const EmptyIconStyled = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '16px',
  height: '16px',
}));

// TODO: 看後端資料怎麼給再來修改判斷要顯示 trending up 或 down 的依據
export function TrendingPercentage({
  status,
  percentage,
}: TrendingPercentageProps) {
  const percentageText = percentage === 0 ? '0.0' : percentage;
  switch (status) {
    case TrendingStatusEnum.UP:
      return (
        <TagContainerStyled className="up">
          <TrendingUpIcon
            sx={{ color: 'success.main', width: '16px', height: '16px' }}
          />
          <Typography variant="caption">{`${percentageText}%`}</Typography>
        </TagContainerStyled>
      );
    case TrendingStatusEnum.DOWN:
      return (
        <TagContainerStyled className="down">
          <TrendingDownIcon
            sx={{ color: 'error.main', width: '16px', height: '16px' }}
          />
          <Typography variant="caption">{`${percentageText}%`}</Typography>
        </TagContainerStyled>
      );
    case TrendingStatusEnum.TIE:
      return (
        <TagContainerStyled>
          <EmptyIconStyled />
          <Typography variant="caption">{`${percentageText}%`}</Typography>
        </TagContainerStyled>
      );
    case TrendingStatusEnum.NULL:
    default:
      return (
        <TagContainerStyled>
          <EmptyIconStyled />
          <Typography variant="caption">{'-'}</Typography>
        </TagContainerStyled>
      );
  }
}
