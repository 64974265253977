import { queryClient } from '@frontend/sorghum/external-providers';
import { ResponseWithData, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_INCENTIVE } from '../apis';

interface PropsType {
  name: string;
}

const defaultData = {
  id: '',
  name: '',
};

async function postCouponIncentive(
  projectID: string,
  couponID: string,
  props: PropsType,
): Promise<ResponseWithData<{ id: string; name: string }>> {
  return axios
    .post(`${COUPON_INCENTIVE}?projectId=${projectID}&couponId=${couponID}`, {
      name: props.name,
    })
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostCouponIncentive(
  projectID: string,
  couponID: string,
  options?: UseApi<ResponseWithData<{ id: string; name: string }>, PropsType>,
) {
  return useMutation({
    mutationKey: ['post-coupon-incentive'],
    mutationFn: (props: PropsType) => {
      return postCouponIncentive(projectID, couponID, { name: props.name });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['get-coupon', { projectID, couponID }]);
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}
