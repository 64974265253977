import { Textarea, UploadImage } from '@frontend/components/ui';
import {
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import { RecurringNotificationType } from '@frontend/editor/interface';
import { IMAGE_WIDTH } from '@frontend/editor/utils';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EditorViewTextButton from '../editor-view-text-button-cell/editor-view-text-button-cell';

interface EditorViewRecurringNotificationProps {
  element: RecurringNotificationType;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  marginTop: '12px',
  padding: '12px',
  position: 'relative',
}));

const ImageWrapperStyled = styled(Box)(({ theme }) => ({
  marginTop: '20px',
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '6px 12px',
  marginBottom: '20px',
}));

export const EditorViewRecurringNotification = ({
  element,
}: EditorViewRecurringNotificationProps) => {
  const [t] = useTranslation();
  const { id: flowID } = useParams();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);
  const percentage = get(viewData, `cells.${element.cellID}.uniqCvr`);

  const labelList = get(labelItems, 'data', []);

  return (
    <ContainerStyled>
      <Typography variant="subtitle2" color="grey.900">
        <Trans i18nKey="canvas.recurringNotification.drawer.title" />
      </Typography>
      <ImageWrapperStyled>
        <UploadImage
          imageType={element.imageType}
          defaultValue={element.fileUrl}
          ratio={1.91}
          width={IMAGE_WIDTH}
          readonly
        />
      </ImageWrapperStyled>
      <InfoWrapperStyled>
        <Textarea defaultValue={element.title} readonly labelItem={labelList} />

        <Typography variant="caption" color="grey.500">
          <Trans i18nKey="canvas.recurringNotification.info" />
        </Typography>
      </InfoWrapperStyled>
      <EditorViewTextButton
        buttonNumber={percentage}
        text={t('canvas.recurringNotification.button')}
        targetID={element.targetID}
        outputID={element.outputID}
        prefixIconColor="secondary.dark"
        textButtonDisabled
      />
    </ContainerStyled>
  );
};

export default EditorViewRecurringNotification;
