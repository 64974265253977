import { Link as MuiLink, TypographyProps } from '@mui/material';
import { FC, MouseEvent, PropsWithChildren, startTransition } from 'react';
import { useNavigate } from 'react-router-dom';

/* eslint-disable-next-line */
export interface LinkProps extends PropsWithChildren {
  variant: TypographyProps['variant'];
  color?: TypographyProps['color'];
  onClick?: () => void;
  href?: string;
  to?: string;
  fontWeight?: string;
  noWrap?: boolean;
  textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
}

export const Link: FC<LinkProps> = ({
  children,
  variant,
  to,
  color = 'primary',
  fontWeight = '600',
  href,
  onClick,
  noWrap = false,
  textTransform = 'capitalize',
}: LinkProps) => {
  const navigate = useNavigate();
  const handleClick = (e: MouseEvent) => {
    if (onClick) {
      onClick();
    }
    if (to) {
      startTransition(() => navigate(to));
    }
  };
  return (
    <MuiLink
      noWrap={noWrap}
      href={href}
      color={color}
      variant={variant}
      fontWeight={fontWeight}
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        textTransform: textTransform ? textTransform : 'capitalize',
      }}
    >
      {children}
    </MuiLink>
  );
};

export default Link;
