import { GoogleSheet, ResponseWithData } from '@frontend/editor/interface';
import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { GOOGLE_SHEET } from '../apis';

async function postGoogleSheet(
  projectID: string,
  googleID: string,
): Promise<ResponseWithData<GoogleSheet>> {
  return axios
    .post(`${GOOGLE_SHEET}?projectId=${projectID}&googleId=${googleID}`)
    .then((res) => res.data);
}

export function usePostGoogleSheet(projectID: string) {
  return useMutation({
    mutationKey: ['post-google-sheet', { projectID }],
    mutationFn: (googleID: string) => postGoogleSheet(projectID, googleID),
    onSuccess: (_: ResponseWithData<GoogleSheet>, variable: string) => {
      queryClient.invalidateQueries([
        'get-google-sheet',
        { projectID, accountID: variable },
      ]);
    },
  });
}

export default usePostGoogleSheet;
