import { isUndefined } from 'lodash';

// 在外層傳入 titles 時記得把要檢查是否重複的 value 從 titles 中 filter 掉
export const checkDuplicate = (value: string | undefined, titles: string[]) => {
  if (!isUndefined(value) && titles.findIndex((t) => t === value) !== -1) {
    return true;
  } else {
    return false;
  }
};
