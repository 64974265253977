import { Drawer } from '@frontend/components/ui';
import { useCanvasView } from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum } from '@frontend/editor/interface';
import { styled } from '@mui/system';
import { FC, memo, PropsWithChildren, useContext } from 'react';

interface DrawerStyledProps {
  drawerType: DrawerTypesEnum;
  autoClose?: boolean;
}

const DrawerStyled = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'absolute',
}));

export const EditorDrawer: FC<PropsWithChildren<DrawerStyledProps>> = ({
  drawerType,
  children,
  autoClose,
}: PropsWithChildren<DrawerStyledProps>) => {
  const uiState = useContext(UICtx);
  const { handleDrawer } = useCanvasView();
  const isOpen = drawerType === uiState.drawerType;

  return (
    <DrawerStyled>
      <Drawer
        open={isOpen}
        autoClose={autoClose}
        handleOpen={() => handleDrawer(drawerType)}
        handleClose={() => uiState.setDrawerType(DrawerTypesEnum.CLOSE)}
      >
        {children}
      </Drawer>
    </DrawerStyled>
  );
};

export default memo(EditorDrawer);
