import { UploadImage } from '@frontend/components/ui';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import { TextButtonCellType } from '@frontend/editor/interface';
import { IMAGE_RATIO, IMAGE_WIDTH } from '@frontend/editor/utils';
import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EditorViewTextButtonCell } from '../editor-view-text-button-cell/editor-view-text-button-cell';

interface EditorViewImageProps {
  id: string;
}

const BlockTextButtonStyled = styled(Box)(({ theme }) => ({
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  marginTop: theme.spacing(1.5),
  padding: '12px',

  '& > div': {
    marginTop: theme.spacing(1.5),
  },
}));

export const EditorViewImage = ({ id }: EditorViewImageProps) => {
  const { id: flowID } = useParams();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const { getTargetElement } = useCanvasGet();
  const targetElement = getTargetElement(id);
  const label = get(targetElement, 'label', '');
  const children = get(targetElement, 'children', []);
  const imageType = get(targetElement, 'imageType', 1);
  const fileUrl = get(targetElement, 'fileUrl', '');

  return (
    <BlockTextButtonStyled>
      <Typography
        variant="body2"
        color="grey.900"
        sx={{ wordBreak: 'break-word' }}
      >
        {label}
      </Typography>
      <UploadImage
        readonly={true}
        imageType={imageType}
        defaultValue={fileUrl}
        width={IMAGE_WIDTH}
        ratio={IMAGE_RATIO}
      />
      {children.map((cellID: string) => {
        const textButtonCell = getTargetElement(cellID) as TextButtonCellType;
        const cellViewData = get(viewData, `cells.${cellID}.uniqCvr`);

        return (
          <EditorViewTextButtonCell
            buttonNumber={cellViewData}
            key={textButtonCell.id}
            iconType={textButtonCell.buttonType}
            text={textButtonCell.label as string}
            outputID={textButtonCell.outputID}
            targetID={textButtonCell.targetID as string}
          />
        );
      })}
    </BlockTextButtonStyled>
  );
};

export default EditorViewImage;
