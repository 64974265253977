import { Dialog, NoticeBox } from '@frontend/components/ui';
import {
  PublishStatus,
  UsePageDefaultAnswerReturn,
} from '@frontend/sorghum/interface';
import {
  BasicSetting,
  DefaultAnswerFacebook,
  DefaultAnswerPreview,
  TopBar,
} from '@frontend/sorghum/ui';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface SorghumFeaturesDefaultAnswerProps {
  usePage: () => UsePageDefaultAnswerReturn;
}

const DefaultAnswerContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const NoticeBoxContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  width: '100%',
  alignItems: 'center',
  top: '87px',
}));

export function SorghumFeaturesDefaultAnswer({
  usePage,
}: SorghumFeaturesDefaultAnswerProps) {
  const [t] = useTranslation();
  const {
    publishStatus,
    defaultAnswer,
    breadcrumbItems,
    breadcrumbNow,
    tabValue,
    isSaving,
    onlineFlow,
    flowName,
    isLoaded,
    isAlertModalOpen,
    setTabValue,
    setDefaultAnswerStatus,
    onPublish,
    setDefaultAnswer,
    onUpdateFlowID,
    closeModal,
    goToFlow,
  } = usePage();

  const InstagramContent = () => {
    return <Box></Box>;
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <DefaultAnswerContainerStyled>
        <TopBar
          history={breadcrumbItems}
          now={breadcrumbNow}
          customButton={
            <Button
              variant="contained"
              size="small"
              disabled={publishStatus === PublishStatus.PUBLISHED && !isSaving}
              color={
                publishStatus === PublishStatus.UNPUBLISH
                  ? 'primary'
                  : 'secondary'
              }
              onClick={onPublish}
            >
              {t(
                `common.topBar.${
                  publishStatus === PublishStatus.UNPUBLISH
                    ? 'publish'
                    : 'update'
                }`,
              )}
            </Button>
          }
        />
        {publishStatus === 3 && (
          <NoticeBoxContainerStyled>
            <NoticeBox
              message={t('basicSetting.alert.unpublishChange')}
              linkText={t('common.update')}
              onLinkClick={onPublish}
            />
          </NoticeBoxContainerStyled>
        )}
        <BasicSetting
          title={t('basicSetting.defaultAnswer.title')}
          tabValue={tabValue}
          menuStatus={defaultAnswer.enable}
          setMenuStatus={setDefaultAnswerStatus}
          setTabValue={setTabValue}
          facebookContent={
            <DefaultAnswerFacebook
              onlineFlow={onlineFlow}
              data={defaultAnswer}
              setData={setDefaultAnswer}
              onUpdateFlowID={onUpdateFlowID}
            />
          }
          previewPosition="top"
          instagramContent={<InstagramContent />}
          mobilePreviewContent={<DefaultAnswerPreview data={defaultAnswer} />}
        />
      </DefaultAnswerContainerStyled>

      {/* default answer 設定沒有發布的 flow 時的提示 modal */}
      <Dialog
        size="xs"
        title={t('basicSetting.defaultAnswer.modal.unpublish.title', {
          status:
            publishStatus === PublishStatus.UNPUBLISH ? 'publish' : 'update',
        })}
        content={t('basicSetting.defaultAnswer.modal.unpublish.content', {
          name: flowName,
        })}
        open={isAlertModalOpen}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.ok')}
        handleConfirm={goToFlow}
      />
    </>
  );
}

export default SorghumFeaturesDefaultAnswer;
