import { theme } from '@frontend/components/external-providers';
import { Dialog, Switch, Typography } from '@frontend/components/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BasicSettingPreview } from '../basic-setting-preview/basic-setting-preview';

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  paddingTop: '55px',
}));

const MainWrapperStyled = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(7.5),
  borderRight: `1px solid ${theme.palette['grey'][300]}`,
  height: 'fit-content',
  minHeight: '100%',
  flexGrow: 1,
}));

const MobileWrapperStyled = styled(Box)(({ theme }) => ({}));

export interface BasicSettingProps {
  title: string;
  tabValue: number;
  menuStatus?: boolean;
  facebookContent: JSX.Element;
  instagramContent: JSX.Element;
  mobilePreviewContent: JSX.Element;
  previewPosition: 'top' | 'bottom';
  isShowPreviewInfoModal?: boolean;
  setTabValue: (val: number) => void;
  setMenuStatus?: (newState: boolean) => void;
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: '32px',
}));

export const BasicSetting: FC<BasicSettingProps> = ({
  title,
  tabValue,
  menuStatus,
  facebookContent,
  instagramContent,
  mobilePreviewContent,
  previewPosition,
  isShowPreviewInfoModal = true,
  setTabValue,
  setMenuStatus,
}: BasicSettingProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [previewInfoModal, setPreviewInfoModal] = useState<boolean>(false);
  const [t] = useTranslation();

  const a11yProps = (index: number) => {
    return {
      id: `persistent-tab-${index}`,
      'aria-controls': `persistent-tabpanel-${index}`,
    };
  };

  const handlePreviewOnClick = useCallback(() => {
    if (isShowPreviewInfoModal) {
      setPreviewInfoModal(true);
    }
  }, [isShowPreviewInfoModal]);

  return (
    <>
      <ContainerStyled>
        <MainWrapperStyled>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography sx={{ pl: theme.spacing(4) }} variant="h5">
                {title}
              </Typography>
            </Box>
            {setMenuStatus && (
              <Box sx={{ mr: 1 }}>
                <Switch
                  size="small"
                  onChange={(event, checked) => setMenuStatus(checked)}
                  checked={menuStatus}
                />
              </Box>
            )}
          </Box>
          <ContentWrapper>{facebookContent}</ContentWrapper>
          {/* <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChange}>
                <Tab
                  sx={{ marginLeft: theme.spacing(3), marginTop: 1 }}
                  icon={<FacebookIcon />}
                  iconPosition="start"
                  label="FACEBOOK"
                  {...a11yProps(0)}
                />
                <Tab
                icon={<InstagramIcon />}
                iconPosition="start"
                label="INSTAGRAM"
                {...a11yProps(1)}
              />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              {facebookContent}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {instagramContent}
            </TabPanel>
          </Box> */}
        </MainWrapperStyled>
        <MobileWrapperStyled>
          <BasicSettingPreview
            position={previewPosition}
            onClick={handlePreviewOnClick}
            bottomColor={theme.palette.grey[100]}
          >
            {mobilePreviewContent}
          </BasicSettingPreview>
        </MobileWrapperStyled>
      </ContainerStyled>
      <Dialog
        size="xs"
        title={t('basicSetting.persistentMenu.previewModal.title', {
          pageName: title,
        })}
        open={previewInfoModal}
        handleClose={() => setPreviewInfoModal(false)}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.ok')}
        handleConfirm={() => setPreviewInfoModal(false)}
      >
        <Box>
          <Typography color="grey.800" variant="body1">
            <Trans i18nKey="basicSetting.persistentMenu.previewModal.info" />
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default BasicSetting;
