import { GetProjectPermission } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_PERMISSION } from '../apis';

const defaultData = [
  {
    id: '',
    name: '',
    headshot: '',
    permission: 2,
  },
];

async function getProjectPermission(): Promise<GetProjectPermission[]> {
  return axios
    .get(`${PROJECT_PERMISSION}`)
    .then((res) => res.data)
    .catch((e) => {
      return defaultData;
    });
}

export function useGetProjectPermission() {
  return useQuery({
    queryKey: ['get-project-permission'],
    queryFn: () => {
      return getProjectPermission();
    },
  });
}
