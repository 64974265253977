import { DataFlowChart } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_FLOW_CHART } from '../apis';

interface GetDataFlowChartProps {
  projectID: string;
  flowID: string;
  entryType?: number;
  entryID?: string;
}

async function getDataFlowChart({
  projectID,
  flowID,
  entryType,
  entryID,
}: GetDataFlowChartProps): Promise<DataFlowChart> {
  return axios
    .get(DATA_FLOW_CHART, {
      params: {
        projectId: projectID,
        flowId: flowID,
        ...(entryType && { entryType }),
        ...(entryID && { entryId: entryID }),
      },
    })
    .then((res) => res.data.data)
    .catch((err) => ({
      summary: {
        sent: 0,
        uniqEnter: 0,
        uniqInteracted: 0,
      },
      shareLink: {},
      botlink: {},
      welcomeMessage: {},
      persistentMenu: {},
      defaultAnswer: {},
      conversationStarter: {},
      blocks: {},
      cells: {},
    }));
}

export function useGetDataFlowChart({
  projectID,
  flowID,
  entryType,
  entryID,
}: GetDataFlowChartProps) {
  return useQuery<DataFlowChart>({
    queryKey: ['data-flow-chart', { projectID, flowID, entryType, entryID }],
    queryFn: () => getDataFlowChart({ projectID, flowID, entryType, entryID }),
  });
}

export default useGetDataFlowChart;
