import { PatchEditorConversation, Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { EDITOR_CONVERSATION } from '../apis';

async function patchEditorConversation(
  projectId: string,
  socialType: number,
  params: PatchEditorConversation,
): Promise<Response> {
  return axios
    .patch(
      `${EDITOR_CONVERSATION}?projectId=${projectId}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorConversation(
  projectId: string,
  socialType: number,
) {
  return useMutation({
    mutationKey: ['patch-editor-conversation', { projectId, socialType }],
    mutationFn: (params: PatchEditorConversation) =>
      patchEditorConversation(projectId, socialType, params),
    onSuccess: (data: Response) => {
      toast.success(data.msg);
    },
  });
}

export default usePatchEditorConversation;
