import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Response } from '@frontend/sorghum/interface';
import { FLOW_GROUP_FLOW } from '../apis';
import { toast } from 'react-toastify';
import { queryClient } from '@frontend/sorghum/external-providers';

interface Props {
  projectId: string;
  flows: string[];
  groupId: string;
}

async function patchEditorFlowGroupFlow(props: Props): Promise<Response> {
  return axios
    .patch(`${FLOW_GROUP_FLOW}?projectId=${props.projectId}`, {
      flowGroupId: props.groupId,
      flows: props.flows,
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorFlowGroupFlow() {
  return useMutation({
    mutationKey: ['patch-editor-flow-group-flow'],
    mutationFn: (props: Props) => patchEditorFlowGroupFlow(props),
    onSuccess: (data) => {
      if (data.code === 20000) {
        queryClient.refetchQueries(['flow-list']);
        toast.success(data.msg);
      } else {
        toast.warn(data.msg);
      }
    },
  });
}

export default usePatchEditorFlowGroupFlow;
