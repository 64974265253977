import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON } from '../apis';

interface PropsType {
  couponID: string;
}

async function deleteCoupon(
  projectID: string,
  couponID: string,
): Promise<Response> {
  return axios
    .delete(`${COUPON}?couponId=${couponID}&projectId=${projectID}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}
export function useDeleteCoupon(
  projectID: string,
  option?: UseApi<Response, PropsType>,
) {
  return useMutation({
    mutationKey: ['delete-coupon'],
    mutationFn: (props: PropsType) => deleteCoupon(projectID, props.couponID),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['get-coupon-list'],
      });

      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
  });
}

export default useDeleteCoupon;
