import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  CollectUserAnswerType,
  DataFlowChartBlock,
  ElementTypesEnum,
  NodeContainerStyledProps,
  RecurringNotificationType,
  TextButtonCellType,
} from '@frontend/editor/interface';
import { sendGAEvent, usePath } from '@frontend/sorghum/utils';
import CommentIcon from '@mui/icons-material/Comment';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import { EditorViewCollectUserAnswer } from '../editor-view-collect-user-answer/editor-view-collect-user-answer';
import { EditorViewDataBlock } from '../editor-view-data-block/editor-view-data-block';
import { EditorViewGallery } from '../editor-view-gallery/editor-view-gallery';
import { EditorViewImage } from '../editor-view-image/editor-view-image';
import { EditorViewRecurringNotification } from '../editor-view-recurring-notification/editor-view-recurring-notification';
import { EditorViewTextButton } from '../editor-view-text-button/editor-view-text-button';
import { HandlePoint } from '../handle-point/handle-point';
import { NodeHeader } from '../node-header/node-header';

const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus }) => ({
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '11px',
    cursor: 'move',
    boxShadow:
      '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
    ...($focus && { border: `2px solid ${theme.palette['info']['main']}` }),
  }),
);

const BodyStyled = styled(Box)(({ theme }) => ({
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '12px',
  width: '316px',
}));

const BlockDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
}));

export const ViewNodeBlock = ({ id }: NodeProps) => {
  const [t] = useTranslation();
  const state = useContext(EditorCtx);
  const { navigateToPeopleWithCondition } = usePath();
  const { getTargetElement } = useCanvasGet();
  const { id: flowID } = useParams();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const entryPoint = state.elements.find(
    (i) => i.elementType === ElementTypesEnum.ENTRY_POINT,
  );

  const viewBlockData = getTargetElement(id);
  const outputID = get(viewBlockData, 'outputID', '');
  const children = get(viewBlockData, 'children', []);
  const inputID = get(viewBlockData, 'inputID', '');
  const targetID = get(viewBlockData, 'targetID', '');
  const header = get(viewBlockData, 'label', '');
  const blockID = get(viewBlockData, 'id', '');

  const entryID = get(entryPoint, 'id', '');

  const {
    sent,
    uniqSent,
    clicked,
    clickedP,
    uniqClicked,
    uniqClickedP,
    opened,
    openedP,
    uniqOpened,
    uniqOpenedP,
    delivered,
    deliveredP,
    uniqDelivered,
    uniqDeliveredP,
  } = useMemo(() => {
    const blockData: DataFlowChartBlock = get(viewData, `blocks.${id}`, {});

    return {
      sent: blockData?.sent,
      uniqSent: blockData?.uniqSent,
      uniqClicked: blockData?.uniqClicked,
      uniqClickedP: blockData?.uniqClickedP,
      clicked: blockData?.clicked,
      clickedP: blockData?.clickedP,
      opened: blockData?.opened,
      openedP: blockData?.openedP,
      uniqOpened: blockData?.uniqOpened,
      uniqOpenedP: blockData?.uniqOpenedP,
      uniqDelivered: blockData?.uniqDelivered,
      uniqDeliveredP: blockData?.uniqDeliveredP,
      delivered: blockData?.delivered,
      deliveredP: blockData?.deliveredP,
    };
  }, [viewData, id]);

  const handleTooltipClick = useCallback((handleNavigate: () => void) => {
    sendGAEvent(
      'Chat Flow View',
      'Block Data',
      'Chat Flow View - Block - Data - user - click',
      '',
    );
    handleNavigate();
  }, []);

  return (
    <Box>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
          isConnected={false}
        />
      )}
      {outputID && targetID && (
        <HandlePoint
          id={outputID}
          type="source"
          position={Position.Right}
          styles={{
            top: 'calc(100% - 30px)',
          }}
          isConnected={false}
        />
      )}

      <ContainerStyled>
        <NodeHeader
          color={globalTheme.palette?.['primary']?.['main']}
          background={globalTheme.palette?.['blue'][50]}
          icon={<CommentIcon fontSize="small" />}
          title={header}
          readonly
        />

        <BodyStyled>
          <BlockDataWrapperStyled>
            <EditorViewDataBlock
              title={t('view.block.sent')}
              tooltipTitle={t('view.block.sentTooltip')}
              count={sent}
              uniqCount={uniqSent}
              onClickTooltip={() =>
                handleTooltipClick(() =>
                  navigateToPeopleWithCondition({
                    searchFilter: 'flowBlock',
                    flowID,
                    blockID,
                    status: 'sent',
                    key: blockID,
                    entryID: uiState.viewFlowEntryID || '',
                    entryType: uiState.viewFlowEntryType,
                  }),
                )
              }
            />
            <EditorViewDataBlock
              title={t('view.block.delivered')}
              tooltipTitle={t('view.block.deliveredTooltip')}
              count={delivered}
              percentage={deliveredP}
              tooltipInfo={t('view.tooltip.delivery')}
              uniqCount={uniqDelivered}
              uniqPercentage={uniqDeliveredP}
              onClickTooltip={() =>
                handleTooltipClick(() =>
                  navigateToPeopleWithCondition({
                    searchFilter: 'flowBlock',
                    flowID,
                    blockID,
                    status: 'delivered',
                    key: blockID,
                    entryID: uiState.viewFlowEntryID || '',
                    entryType: uiState.viewFlowEntryType,
                  }),
                )
              }
            />
            <EditorViewDataBlock
              title={t('view.block.opened')}
              tooltipTitle={t('view.block.openedTooltip')}
              count={opened}
              percentage={openedP}
              tooltipInfo={t('view.tooltip.open')}
              uniqCount={uniqOpened}
              uniqPercentage={uniqOpenedP}
              onClickTooltip={() =>
                handleTooltipClick(() =>
                  navigateToPeopleWithCondition({
                    searchFilter: 'flowBlock',
                    flowID,
                    blockID,
                    status: 'opened',
                    key: blockID,
                    entryID: uiState.viewFlowEntryID || '',
                    entryType: uiState.viewFlowEntryType,
                  }),
                )
              }
            />
            <EditorViewDataBlock
              title={t('view.block.clicked')}
              tooltipTitle={t('view.block.clickedTooltip')}
              count={clicked}
              percentage={clickedP}
              tooltipInfo={t('view.tooltip.click')}
              uniqCount={uniqClicked}
              uniqPercentage={uniqClickedP}
              onClickTooltip={() =>
                handleTooltipClick(() =>
                  navigateToPeopleWithCondition({
                    searchFilter: 'flowBlock',
                    flowID,
                    blockID,
                    status: 'clicked',
                    key: blockID,
                    entryID: uiState.viewFlowEntryID || '',
                    entryType: uiState.viewFlowEntryType,
                  }),
                )
              }
            />
          </BlockDataWrapperStyled>
          {children.map((itemID: string) => {
            const element = getTargetElement(itemID);

            switch (element.elementType) {
              case ElementTypesEnum.TEXT_BUTTON: {
                return (
                  <EditorViewTextButton
                    key={element.id}
                    element={element as TextButtonCellType}
                  />
                );
              }
              case ElementTypesEnum.IMAGE: {
                return <EditorViewImage key={element.id} id={element.id} />;
              }
              case ElementTypesEnum.RECURRING_NOTIFICATION: {
                return (
                  <EditorViewRecurringNotification
                    key={element.id}
                    element={element as RecurringNotificationType}
                  />
                );
              }
              case ElementTypesEnum.COLLECT_USER_ANSWER: {
                return (
                  <EditorViewCollectUserAnswer
                    key={element.id}
                    element={element as CollectUserAnswerType}
                  />
                );
              }
              case ElementTypesEnum.GALLERY: {
                return <EditorViewGallery key={element.id} id={element.id} />;
              }
            }
            return null;
          })}
        </BodyStyled>
      </ContainerStyled>
    </Box>
  );
};

export default memo(ViewNodeBlock);
