import { GetBroadcastListResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST_LIST } from '../apis';

const defaultData: GetBroadcastListResponse = {
  totalCount: 0,
  limit: 0,
  offset: 0,
  draft: [],
  sent: [],
  isDefault: true,
};

async function getBroadcastList(
  projectID: string,
  status: number,
  limit: number,
  offset: number,
): Promise<GetBroadcastListResponse> {
  return axios
    .get(
      `${BROADCAST_LIST}?projectId=${projectID}&status=${status}&limit=${limit}&offset=${offset}`,
    )
    .then((res) => {
      return { ...res.data.data, isDefault: false };
    })
    .catch((err) => {
      return defaultData;
    });
}

export function useGetBroadcastList(
  projectID: string,
  status: number,
  limit: number,
  offset: number,
) {
  return useQuery<GetBroadcastListResponse>({
    queryKey: ['get-broadcast-list', { projectID, offset, status, limit }],
    queryFn: () => {
      if (projectID) {
        return getBroadcastList(projectID, status, limit, offset);
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetBroadcastList;
