import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import { findWithRegex } from '@frontend/components/utils';
import {
  useCanvasGet,
  useCanvasRemove,
  useCanvasUpdate,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import { Note } from '@frontend/editor/interface';
import { REGEX_CHECK_HYPER_LINK } from '@frontend/editor/utils';
import { useGetAccountInfo } from '@frontend/sorghum/data-access';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { CompositeDecorator, ContentState, EditorState } from 'draft-js';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NodeProps } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';

const ContainerStyled = styled(Box)<{ $hover: boolean }>(
  ({ theme, $hover }) => ({
    background: theme.palette['orange'][50],
    padding: '16px',
    borderRadius: '4px',
    cursor: 'move',
    maxHeight: '305px',
    minWidth: '100px',
    width: '316px',
    overflow: 'auto',
    border: `2px solid ${$hover ? theme.palette['orange'][200] : 'transparent'
      }`,
  }),
);

const TextareaWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette['grey'][700],
  fontSize: '16px',
}));

const compositeDecorator = new CompositeDecorator([
  {
    strategy: function (contentBlock, callback) {
      findWithRegex(
        new RegExp(REGEX_CHECK_HYPER_LINK, 'g'),
        contentBlock,
        callback,
      );
    },
    component: (props: { decoratedText: string }) => {
      const url = props.decoratedText
        .match(new RegExp(/\((.*?)\)/))
        ?.pop()
        ?.replace('(', '')
        ?.replace(')', '');

      const text = props.decoratedText
        .match(new RegExp(/\[(.*?)\]/))
        ?.pop()
        ?.replace('[', '')
        ?.replace(']', '');

      const handleClick = () => {
        window.open(url, '_blank');
      };

      return (
        <Typography
          onClick={handleClick}
          variant="overline"
          color="info.main"
          sx={{
            cursor: 'pointer',
          }}
        >
          {text}
        </Typography>
      );
    },
  },
]);

// edit / view 共用
export const NodeNote = ({ id }: NodeProps) => {
  const ref = useRef<Editor>(null);
  const state = useContext(EditorCtx);
  const { data: info } = useGetAccountInfo();
  const [isLoaded, setIsLoaded] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    return createEditorStateWithText('');
  });
  const { getTargetElement } = useCanvasGet();
  const { canvasUpdateLabel } = useCanvasUpdate();
  const element = (getTargetElement(id) as Note) || ({} as Note);
  const { canvasRemoveElement } = useCanvasRemove();

  const [isHover, setIsHover] = useState<boolean>(false);

  const isTemplateMode = info?.userName === 'template@goskyai.com';

  const handleMouseEnter = useCallback(() => {
    setIsHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const onRemoveButtonClick = useCallback(() => {
    canvasRemoveElement(id);
  }, [canvasRemoveElement, id]);

  const handleChange = useCallback(
    (newState: EditorState) => {
      // 取得內文內容的 JSON 格式
      const content = newState.getCurrentContent();
      // 將 JSON 格式的內容轉換成純文字內容
      const contentText = content.getPlainText();
      if (isLoaded) {
        setEditorState(newState);
        canvasUpdateLabel(id, contentText);
      }
    },
    [canvasUpdateLabel, id, isLoaded],
  );

  useEffect(() => {
    if (!isLoaded) {
      const initialContent = ContentState.createFromText(
        element.label ?? 'test',
      );
      const initialEditorState = EditorState.createWithContent(initialContent);
      const newEditorState = EditorState.set(initialEditorState, {
        decorator: compositeDecorator,
      });
      setEditorState(newEditorState);
      setIsLoaded(true);
    }
  }, [element?.label, isLoaded]);

  return (
    <ContainerStyled
      $hover={isHover}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHover && !state.readonly && (
        <EditorFloatPanel
          backgroundColor="rgba(96, 125, 139, 0.1)"
          handleDelete={onRemoveButtonClick}
        />
      )}
      <TextareaWrapper className={isTemplateMode ? 'nodrag' : ''}>
        <Editor ref={ref} editorState={editorState} onChange={handleChange} />
      </TextareaWrapper>
    </ContainerStyled>
  );
};

export default memo(NodeNote);
