import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_SURVEY } from '../apis';

interface Props {
  // 1: 累積rn大於 >= 5人
  // 2: 成功發送1次broadcast
  type: number;
  // 1: Completed/2: Fill Out Later
  action: number;
}

async function postAccountSurvey(props: Props): Promise<Response> {
  return axios.post(ACCOUNT_SURVEY, props).then((res) => res.data);
}

export function usePostAccountSurvey() {
  return useMutation({
    mutationKey: ['post-account-survey'],
    mutationFn: (props: Props) => postAccountSurvey(props),
    onSuccess: () => {
      queryClient.refetchQueries(['get-account-survey']);
    },
  });
}

export default usePostAccountSurvey;
