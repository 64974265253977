import { queryClient } from '@frontend/sorghum/external-providers';
import { ResponseWithData } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_COPY } from '../apis';

interface Params {
  flowId: string;
  name: string;
  projectId: string;
}

interface PostCopyFlowResponseType {
  flowId: string;
}

async function postFlowCopy(
  params: Params,
): Promise<ResponseWithData<PostCopyFlowResponseType>> {
  return axios
    .post(FLOW_COPY, params)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostFlowCopy() {
  return useMutation({
    mutationKey: ['post-flow-copy'],
    mutationFn: (params: Params) => postFlowCopy(params),
    onSuccess: () => {
      queryClient.refetchQueries(['flow-list']);
    },
  });
}

export default usePostFlowCopy;
