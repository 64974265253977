import { PublishedFlowSelect, ToggleIconButton } from '@frontend/components/ui';
import { EditorInput } from '@frontend/editor/ui';
import { REGEX_CHECK_URL } from '@frontend/editor/utils';
import {
  TextButtonCellDataType,
  TextButtonCellErrorEnum,
  TextButtonCellType,
  TextButtonCellTypeEnum,
} from '@frontend/sorghum/interface';
import {
  HOW_TO_TRACK_WITH_UTM,
  HOW_TO_TRACK_WITH_UTM_EN,
} from '@frontend/sorghum/utils';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { Box, Button, Link, Popper, TextField } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BlockSelectorWrapperStyled,
  CloseButtonWrapperStyled,
  DoneButtonWrapperStyled,
  PopperWrapperStyled,
  ToggleWrapperStyled,
} from './styles';

export interface CellButtonModalProps {
  open: boolean;
  cellID: string;
  cellData: TextButtonCellType;
  anchorEl: HTMLElement | HTMLInputElement | null;
  setAnchorEl: (anchor: HTMLElement | HTMLInputElement | null) => void;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
}

// Set URL
const UrlSetting = ({
  cellID,
  cellData,
  updateCellData,
  errorList,
  elementID,
  setErrorList,
}: {
  cellID: string;
  cellData: TextButtonCellType;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
}) => {
  const [t, i18next] = useTranslation();
  const urlCellData = get(cellData, 'data', {} as TextButtonCellDataType);
  const urlRegex = new RegExp(REGEX_CHECK_URL);

  const [error, setError] = useState<boolean>(
    urlCellData?.url ? !urlCellData.url.match(urlRegex) : false,
  );

  return (
    <BlockSelectorWrapperStyled>
      <Box>
        <TextField
          fullWidth
          size="small"
          placeholder={t('canvas.modal.editTextButton.urlPlaceholder')}
          onChange={(e) =>
            updateCellData(elementID, cellID, {
              ...cellData,
              data: { ...cellData.data, url: e.target.value },
            })
          }
          value={urlCellData?.url ?? ''}
          error={
            error ||
            isEmpty(urlCellData?.url) ||
            errorList.filter(
              (item) =>
                item.id === cellID &&
                (item.type === TextButtonCellErrorEnum.EMPTY_URL ??
                  item.type === TextButtonCellErrorEnum.WRONG_URL_FORMAT),
            ).length > 0
          }
          onBlur={() => {
            if (!isEmpty(urlCellData?.url)) {
              setError(!(urlCellData?.url as string).match(urlRegex));
            }
          }}
          helperText={
            <span>
              {t('canvas.modal.editTextButton.urlHelperText')}
              <Link
                color="info.main"
                href={
                  i18next.language.includes('en')
                    ? HOW_TO_TRACK_WITH_UTM_EN
                    : HOW_TO_TRACK_WITH_UTM
                }
                target="_blank"
              >
                {t('canvas.modal.editTextButton.addingUTM')}
              </Link>
            </span>
          }
        />
      </Box>
    </BlockSelectorWrapperStyled>
  );
};

// Jump to flow
const FlowSetting = ({
  cellID,
  cellData,
  updateCellData,
  errorList,
  elementID,
}: {
  cellID: string;
  cellData: TextButtonCellType;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  elementID: string;
}) => {
  const [t] = useTranslation();

  const flowCellData = get(cellData, 'data', {} as TextButtonCellDataType);

  return (
    <BlockSelectorWrapperStyled>
      <PublishedFlowSelect
        onChange={(item) => {
          if (item?.value) {
            updateCellData(elementID, cellID, {
              ...cellData,
              data: {
                ...cellData.data,
                flowId: item?.value,
                flowName: item?.label,
              },
            });
          }
        }}
        value={flowCellData?.flowId ?? ''}
        placeholder={t('broadcast.draft.flowSelectPlaceholder')}
        // error={
        //   errorList.filter(
        //     (item) =>
        //       item.id === cellID &&
        //       item.type === ConditionErrorEnum.NO_SELECT_FLOW,
        //   ).length > 0
        // }
      />
    </BlockSelectorWrapperStyled>
  );
};
// Set phone
const PhoneSetting = ({
  cellID,
  cellData,
  updateCellData,
  errorList,
  elementID,
}: {
  cellID: string;
  cellData: TextButtonCellType;
  updateCellData: (
    elementID: string,
    id: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  elementID: string;
}) => {
  const [t] = useTranslation();

  const telCellData = get(cellData, 'data', {} as TextButtonCellDataType);

  return (
    <BlockSelectorWrapperStyled>
      <EditorInput
        placeholder={t('canvas.modal.editTextButton.phoneCode')}
        value={telCellData?.tel}
        onChange={(val: string) =>
          updateCellData(elementID, cellID, {
            ...cellData,
            data: { ...cellData.data, tel: val },
          })
        }
        // error={
        //   errorList.filter(
        //     (item) =>
        //       item.id === cellID &&
        //       (item.type === ConditionErrorEnum.EMPTY_PHONE ??
        //         item.type === ConditionErrorEnum.WRONG_PHONE_FORMAT),
        //   ).length > 0
        // }
        helperText={t('canvas.modal.editTextButton.phoneHelperText')}
      />
    </BlockSelectorWrapperStyled>
  );
};

// 目前 modal 內沒有 error 提示
export function CellButtonModal({
  open,
  cellData,
  cellID,
  anchorEl,
  setAnchorEl,
  updateCellData,
  errorList,
  elementID,
  setErrorList,
}: CellButtonModalProps) {
  const [t] = useTranslation();

  const toggle = (type: TextButtonCellTypeEnum) => {
    updateCellData(elementID, cellID, { ...cellData, type });
  };

  const toggleIconList = [
    {
      type: TextButtonCellTypeEnum.REDIRECT_BUTTON,
      isSelected: cellData.type === TextButtonCellTypeEnum.REDIRECT_BUTTON,
      label: t('broadcast.draft.cellModal.jumpToFlow'),
      Icon: <AccountTreeOutlinedIcon fontSize="small" />,
    },
    {
      type: TextButtonCellTypeEnum.URL_BUTTON,
      isSelected: cellData.type === TextButtonCellTypeEnum.URL_BUTTON,
      label: t('canvas.modal.editTextButton.url'),
      Icon: <AddLinkOutlinedIcon fontSize="small" />,
    },
    {
      type: TextButtonCellTypeEnum.CALL_BUTTON,
      isSelected: cellData.type === TextButtonCellTypeEnum.CALL_BUTTON,
      label: t('canvas.modal.editTextButton.call'),
      Icon: <LocalPhoneOutlinedIcon fontSize="small" />,
    },
  ];

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="top-end"
      sx={{
        zIndex: 1,
      }}
    >
      <PopperWrapperStyled>
        <CloseButtonWrapperStyled>
          <CloseIcon onClick={() => setAnchorEl(null)} fontSize="small" />
        </CloseButtonWrapperStyled>
        <ToggleWrapperStyled>
          {toggleIconList.map((item, index) => (
            <ToggleIconButton
              key={index}
              toggle={() => toggle(item.type)}
              isSelected={item.isSelected}
              label={item.label}
            >
              {item.Icon}
            </ToggleIconButton>
          ))}
        </ToggleWrapperStyled>
        {cellData.type === TextButtonCellTypeEnum.REDIRECT_BUTTON && (
          <FlowSetting
            cellID={cellID}
            cellData={cellData}
            updateCellData={updateCellData}
            errorList={errorList}
            elementID={elementID}
          />
        )}
        {cellData.type === TextButtonCellTypeEnum.URL_BUTTON && (
          <UrlSetting
            cellID={cellID}
            cellData={cellData}
            updateCellData={updateCellData}
            errorList={errorList}
            elementID={elementID}
            setErrorList={setErrorList}
          />
        )}
        {cellData.type === TextButtonCellTypeEnum.CALL_BUTTON && (
          <PhoneSetting
            cellID={cellID}
            cellData={cellData}
            updateCellData={updateCellData}
            errorList={errorList}
            elementID={elementID}
          />
        )}
        <DoneButtonWrapperStyled>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setAnchorEl(null)}
          >
            {t('broadcast.draft.done')}
          </Button>
        </DoneButtonWrapperStyled>
      </PopperWrapperStyled>
    </Popper>
  );
}

export default CellButtonModal;
