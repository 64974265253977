import {
  EntryProps,
  PostDataFlowEntryAllProps,
  PostDataFlowEntryAllRequest,
  PostDataFlowEntryAllResponse,
} from '@frontend/editor/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_FLOW_ENTRY_ALL } from '../apis';

const defaultData = {
  shareLink: {
    uniqSent: 0,
    uniqCvr: 0,
  },
  botlink: {
    uniqSent: 0,
    uniqCvr: 0,
  },
  broadcast: {
    uniqSent: 0,
    uniqCvr: 0,
  },
  basicSetting: {
    uniqSent: 0,
    uniqCvr: 0,
  },
  redirectFlow: { uniqSent: 0, uniqCvr: 0 },
  sequence: { uniqSent: 0, uniqCvr: 0 },
  commentReply: {
    '0': {
      uniqSent: 0,
      uniqCvr: 0,
    },
  },
};

async function postDataFlowEntryAll({
  projectID,
  flowID,
  params,
}: PostDataFlowEntryAllRequest): Promise<PostDataFlowEntryAllResponse> {
  return axios
    .post(
      `${DATA_FLOW_ENTRY_ALL}?projectId=${projectID}&flowId=${flowID}`,
      params.map((i) => ({
        entryId: i.entryID,
        type: i.type,
      })),
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostDataFlowEntryAll({
  projectID,
  flowID,
}: PostDataFlowEntryAllProps) {
  return useMutation({
    mutationKey: ['post-data-flow-entry-all', { projectID, flowID }],
    mutationFn: (params: EntryProps[]) =>
      postDataFlowEntryAll({ projectID, flowID, params }),
  });
}

export default usePostDataFlowEntryAll;
