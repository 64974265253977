export const convertToUTCString = (offset: number | string) => {
  let currentOffset = 0;
  if (typeof offset !== 'number') {
    currentOffset = parseInt(offset, 10);
  } else {
    currentOffset = offset;
  }
  const hours = Math.floor(Math.abs(currentOffset));

  // 根據正負號決定 UTC 的符號
  const sign = currentOffset >= 0 ? '+' : '-';

  // 格式化小時為兩位數
  const formattedHours = String(hours).padStart(2, '0');

  // 返回格式化的時區字符串
  return `UTC ${sign}${formattedHours}:00`;
};
