import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  OutlinedTextFieldProps,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import { first } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface CompoundSelectorItemProps {
  value: string | number | undefined;
  label: string;
}

export interface CompoundSelectorProps {
  operatorValue?: string | number | undefined;
  operatorOptions: CompoundSelectorItemProps[];
  value?: string | number | undefined;
  valueOptions: CompoundSelectorItemProps[];
  disabled?: boolean;
  placeholder?: string;
  operatorPrefix?: string;
  onChange?: (
    newOperator: CompoundSelectorItemProps | undefined,
    newValue: CompoundSelectorItemProps | undefined,
  ) => void;
  onBlur?: () => void;
  isShowTooltip?: boolean;
  required?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  open?: boolean;
  handleClose?: () => void;
}

const DropdownContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

const LeftContainerStyled = styled(Box)(({ theme }) => ({
  width: '40%',
}));

const RightContainerStyled = styled(Box)(({ theme }) => ({
  width: '60%',
  maxWidth: '312px',
}));

const InputContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '8px 12px 0 12px',
}));

const InputStyled = styled(TextField)(({ theme }) => ({
  width: '100%',
}));

const CompoundSelectorStyled = styled(Box)(({ theme }) => ({
  width: '100%',
}));

const CompoundMenuContainerStyled = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 100,
}));

const SelectFieldStyled = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    paddingRight: '8px',
  },
}));

//FIXME: 改用 popper 後需解決 RWD 時右側距離邊緣 margin 問題
export function CompoundSelector({
  disabled,
  placeholder,
  operatorValue,
  operatorOptions,
  value,
  valueOptions,
  onChange,
  onBlur,
  operatorPrefix,
  isShowTooltip = false,
  required = false,
  label,
  error = false,
  helperText = '',
  open = false,
  handleClose = () => null,
}: CompoundSelectorProps) {
  // 下拉選單內的搜尋框
  const [searchInput, setSearchInput] = useState('');
  const [isOpenMenu, setIsOpenMenu] = useState(open);
  const [operatorOptionValue, setOperatorOptionValue] =
    useState<CompoundSelectorItemProps>();
  const [optionValue, setOptionValue] = useState<CompoundSelectorItemProps>();

  const handleOpenDropdown = useCallback(() => {
    if (!disabled) {
      setIsOpenMenu(true);
    }
  }, [disabled]);

  const handleClickAway = () => {
    setIsOpenMenu(false);
    handleClose();
    // 清空下拉的搜尋框
    setSearchInput('');
  };

  const handleOperatorSelect = useCallback(
    (_option: CompoundSelectorItemProps) => {
      setOperatorOptionValue(_option);
      if (onChange) onChange(_option, optionValue);
    },
    [onChange, optionValue],
  );

  const handleValueSelect = useCallback(
    (_option: CompoundSelectorItemProps) => {
      setOptionValue(_option);
      if (onChange) onChange(operatorOptionValue, _option);
      setIsOpenMenu(false);
      handleClose();
    },
    [handleClose, onChange, operatorOptionValue],
  );

  const textfieldValue = useMemo(() => {
    let text = '';
    if (operatorPrefix) {
      text += operatorPrefix;
    }

    if (operatorOptionValue) {
      text += operatorOptionValue.label;
    }
    if (optionValue) {
      text += ` ${optionValue.label}`;
    }
    // 兩個都有值或是 menu 開啟時才會顯示值
    if ((operatorOptionValue && optionValue) || isOpenMenu) {
      return text;
    } else {
      return '';
    }
  }, [isOpenMenu, operatorOptionValue, operatorPrefix, optionValue]);

  const optionValueFilter = useMemo(() => {
    if (searchInput) {
      return valueOptions.filter((i) => {
        return i.value && i.label.match(searchInput);
      });
    } else {
      return valueOptions;
    }
  }, [searchInput, valueOptions]);

  // 如果打開選單時沒有值，則自動帶入第一個
  useEffect(() => {
    if (isOpenMenu && !operatorOptionValue) {
      setOperatorOptionValue(first(operatorOptions));
    }
  }, [isOpenMenu, operatorOptions, operatorOptionValue]);

  useEffect(() => {
    setOperatorOptionValue(
      operatorOptions.find((i) => i.value === operatorValue),
    );
  }, [operatorOptions, operatorValue]);

  useEffect(() => {
    setOptionValue(valueOptions.find((i) => i.value === value));
  }, [value, valueOptions]);

  useEffect(() => {
    if (open) {
      setIsOpenMenu(open);
    }
  }, [open]);

  const textfieldProps: OutlinedTextFieldProps = {
    error,
    helperText,
    required,
    disabled,
    label,
    placeholder,
    onClick: handleOpenDropdown,
    value: textfieldValue,
    onBlur,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          {isOpenMenu ? <ExpandLess /> : <ExpandMore />}
        </InputAdornment>
      ),
      readOnly: true,
    },
    sx: {
      '& .MuiInputBase-input': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    size: 'small',
    fullWidth: true,
    // material UI 的 textfield props 需要 type 是 'outlined' | 'filled' ..., etc. 的 type
    // 所以不需要 eslint 自動轉成 as const
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    variant: 'outlined' as 'outlined',
  };

  return (
    <CompoundSelectorStyled>
      {isShowTooltip ? (
        <Tooltip placement="right" title={textfieldValue}>
          <SelectFieldStyled {...textfieldProps} />
        </Tooltip>
      ) : (
        <SelectFieldStyled {...textfieldProps} />
      )}
      {isOpenMenu && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <CompoundMenuContainerStyled>
            <DropdownContainerStyled>
              <LeftContainerStyled>
                <List>
                  {operatorOptions.map((_option: CompoundSelectorItemProps) => (
                    <ListItemButton
                      key={_option.value}
                      selected={operatorOptionValue?.value === _option.value}
                      // 需要先執行 onClick 再觸發 onBlur，所以這裡用 onMouseDown 來讓點擊事件在 onBlur 之前觸發
                      onMouseDown={(e) => {
                        e.preventDefault();
                        handleOperatorSelect(_option);
                      }}
                    >
                      <ListItemText primary={_option.label} />
                    </ListItemButton>
                  ))}
                </List>
              </LeftContainerStyled>
              <RightContainerStyled>
                <InputContainerStyled>
                  <InputStyled
                    size="small"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </InputContainerStyled>
                <List>
                  {optionValueFilter.map((_option) => (
                    <ListItemButton
                      key={_option.value}
                      selected={optionValue?.value === _option.value}
                      // 需要先執行 onClick 再觸發 onBlur，所以這裡用 onMouseDown 來讓點擊事件在 onBlur 之前觸發
                      onMouseDown={(e) => {
                        e.preventDefault();
                        handleValueSelect(_option);
                      }}
                    >
                      <ListItemText
                        primary={_option.label}
                        sx={{ wordBreak: 'break-all' }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </RightContainerStyled>
            </DropdownContainerStyled>
          </CompoundMenuContainerStyled>
        </ClickAwayListener>
      )}
    </CompoundSelectorStyled>
  );
}

export default CompoundSelector;
