import { isEmpty } from 'lodash';
import { FC, Fragment, ElementType } from 'react';

export interface TextHighlightProps {
  text: string;
  highlight?: string;
  Container: ElementType;
  HighlightContainer: ElementType;
  TextContainer: ElementType;
}

export const TextHighlight: FC<TextHighlightProps> = ({
  text,
  highlight = '',
  Container,
  HighlightContainer,
  TextContainer,
}) => {
  const parts =
    !isEmpty(highlight) && !isEmpty(text) && highlight.length > 0
      ? text.split(highlight)
      : [text];

  return (
    <Container>
      {parts.map((part, index) => (
        <Fragment key={index}>
          {/* 插入 highlight 的字段 */}
          {index > 0 && highlight.length > 0 && (
            <HighlightContainer>{highlight}</HighlightContainer>
          )}
          {/* 插入一般字段 */}
          {part && <TextContainer>{part}</TextContainer>}
        </Fragment>
      ))}
    </Container>
  );
};

export default TextHighlight;
