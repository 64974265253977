import { PostCommentReplyDetailResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_DATA_DETAIL } from '../apis';

const defaultData = {};

async function PostCommentReplyDetailData(
  id: string,
  channelID?: string,
): Promise<PostCommentReplyDetailResponse> {
  return axios
    .post(COMMENT_REPLY_DATA_DETAIL, {
      projectChannelId: channelID,
      id,
    })
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostCommentReplyDetail(id: string, channelID?: string) {
  return useQuery<PostCommentReplyDetailResponse>({
    queryKey: ['post-comment-reply-detail', { id, channelID }],
    queryFn: () => {
      if (id && channelID) {
        return PostCommentReplyDetailData(id, channelID);
      } else {
        return defaultData;
      }
    },
  });
}
