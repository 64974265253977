import { EditorCtx } from '@frontend/editor/external-providers';
import { BaseElement, NodeTypesEnum } from '@frontend/editor/interface';
import { useDebouncedCallback, uuid } from '@frontend/editor/utils';
import { sendGAEvent } from '@frontend/sorghum/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Connection,
  Edge,
  EdgeChange,
  MarkerType,
  Node,
  NodeChange,
  OnConnectStartParams,
  OnInit,
  ReactFlowInstance,
  XYPosition,
  addEdge,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useViewport,
} from 'reactflow';

export interface UseCanvasFlow {
  nodes: Node[];
  edges: Edge[];
  zoom: number;
  targetElement?: BaseElement;
  isConnecting: boolean;
  init: OnInit;
  onSave: () => void;
  onRestore: () => void;
  onConnect: (params: Edge | Connection) => void;
  onConnectEnd: (event: MouseEvent | TouchEvent, allowAdd?: boolean) => void;
  onNodesChange: (nodeChanges: NodeChange[]) => void;
  onEdgesChange: (edgeChanges: EdgeChange[]) => void;
  handleNodesDelete: (deleteNodes: Node[]) => void;
  handleEdgeDelete: (deleteEdges: Edge[]) => void;
  handleEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  onConnectStart: (
    event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>,
    { nodeId }: OnConnectStartParams,
  ) => void;
}

export function useCanvasFlow(): UseCanvasFlow {
  const state = useContext(EditorCtx);
  const [rfInstance, setRfInstance] = useState<ReactFlowInstance>();
  const [activeNode, setActiveNode] = useState<OnConnectStartParams>();
  const { project, setViewport } = useReactFlow();
  const [isConnecting, setIsConnecting] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState(state.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(state.edges);

  const menuWidth = 314;
  const menuHeight = 400;
  const canvasPadding = 24;

  const { zoom } = useViewport();

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem('flowKey', JSON.stringify(flow));
    }
  }, [rfInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem('flowKey') as string);

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  }, [setNodes, setEdges, setViewport]);

  const onConnectStart = useCallback(
    (
      e: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>,
      props: OnConnectStartParams,
    ) => {
      setIsConnecting(true);
      state.updateNode();
      setActiveNode(props);
    },
    [state],
  );

  // 暫時停用
  const onConnect = useCallback(
    (params: Edge | Connection) => {
      // 相同 node 的 element 不能互相連接
      if (params.source === params.target) {
        return;
      }

      setEdges((els) => addEdge(params, els));

      if (params.sourceHandle && params.target) {
        // 只有外層 node 可以被連接
        state.connectElement(params.sourceHandle, params.target);
      }
    },
    [setEdges, state],
  );

  const onConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent, allowAdd = true) => {
      const mouseEvent = event as MouseEvent;
      const target = event.target as HTMLElement;
      setIsConnecting(false);
      if (target.classList.contains('react-flow__pane') && allowAdd) {
        if (state.ref && state.ref.current) {
          const { width, height, top, left } =
            state.ref.current.getBoundingClientRect();
          const id = uuid();

          // 314 和 400 分別為 menu 選單的寬度和高度，24 為畫布預留的 padding
          let positionX = mouseEvent.clientX;
          let positionY = mouseEvent.clientY;

          if (mouseEvent.clientX < canvasPadding) {
            positionX += canvasPadding;
          } else if (mouseEvent.clientX > width - menuWidth) {
            positionX = width - menuWidth - canvasPadding;
          }

          if (mouseEvent.clientY < canvasPadding) {
            positionY += canvasPadding;
          } else if (mouseEvent.clientY > height - menuHeight) {
            positionY = height - menuHeight - canvasPadding;
          }

          state.setMenuCoordination(
            project({
              x: positionX - left,
              y: positionY - top,
            }),
          );

          // menu of create block
          const newNode = {
            id,
            position: project({
              x: positionX - left,
              y: positionY - top,
            }),
            data: { sourceOutputID: activeNode?.handleId as string },
            type: NodeTypesEnum.CREATE_MENU_NODE,
            // 比 fucus node 的層級高一點
            zIndex: 21,
          } as Node;

          setNodes((nds) => nds.concat(newNode));
          setEdges((eds) =>
            eds.concat({
              id,
              source: activeNode?.nodeId as string,
              sourceHandle: activeNode?.handleId,
              target: id,
              updatable: 'target',
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            }),
          );

          if (activeNode?.handleId) {
            // 判斷是從 block 還是 cell 拉的線
            sendGAEvent(
              'Chat Flow Edit',
              'Block connector',
              `Chat Flow Edit - connector- ${
                state.getElementByOutputID(activeNode?.handleId)
                  ?.elementType === 'BLOCK'
                  ? 'block'
                  : 'cell'
              } - click`,
              '',
            );
          }
        }
      } else {
        // 尋找正在被 hover 的 block
        const onHoverNodeID = state.elements.find(
          (el) => el.id === state.onHoverNode,
        )?.id;
        if (activeNode?.nodeId && onHoverNodeID) {
          if (activeNode?.nodeId === onHoverNodeID) {
            // 不能自己連線自己
            return;
          }
          if (activeNode?.handleId) {
            state.connectElement(activeNode?.handleId, onHoverNodeID);
          } else {
            const handleID = state.getElement(activeNode?.nodeId)?.id;
            if (handleID) {
              state.connectElement(handleID, onHoverNodeID);
            }
          }
        }
      }
    },
    [project, activeNode, setNodes, setEdges, state],
  );

  const updatePosition = useDebouncedCallback(
    ({ id, position }: { id: string; position: XYPosition }) =>
      state.updateElementData(id, 'position', position),
    100,
  );

  const handleNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => {
      onNodesChange(nodeChanges);
      nodeChanges.forEach((i) => {
        if (i.type === 'position' && i.position) {
          updatePosition({ id: i.id, position: i.position });
        }
      });
    },
    [onNodesChange, updatePosition],
  );

  /** 選取 edge 並按下 backspace */
  const handleEdgeDelete = useCallback(
    (edges: Edge[]) => {
      const targets = edges.map((e) => {
        if (e.sourceHandle) {
          return e.sourceHandle;
        } else {
          return e.source;
        }
      });
      state.removeConnects(targets);
    },
    [state],
  );

  const handleNodesDelete = useCallback(
    (nodes: Node[]) => {
      nodes.forEach((n) => {
        state.removeElement(n.id);
      });
    },
    [state],
  );

  const handleEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      // 相同 node 的 element 不能互相連接
      if (newConnection.source === newConnection.target) {
        return;
      }
      // 改變連接線段的事件
      setEdges((els) => updateEdge(oldEdge, newConnection, els));

      // 移除線段
      if (oldEdge.sourceHandle) {
        state.removeConnects([oldEdge.sourceHandle]);
      } else {
        state.removeConnects([oldEdge.source]);
      }

      // 從子層或是外層連線傳送的參數不同，子層的 source 會繼承父層 node 的 id，所以必須傳 souceHandle 才會是內層的 handle id = element input id
      if (newConnection.sourceHandle && newConnection.target) {
        state.connectElement(newConnection.sourceHandle, newConnection.target);
        // 從 node 外層的連線只會有 source = element id
      } else if (newConnection.source && newConnection.target) {
        state.connectElement(newConnection.source, newConnection.target);
      }
    },
    [setEdges, state],
  );

  const handleEdgesChange = useCallback(
    (edgeChange: EdgeChange[]) => {
      onEdgesChange(edgeChange);
    },
    [onEdgesChange],
  );

  // 前端資料改變則更新 react-flow node
  useEffect(() => {
    setNodes(state.nodes);
  }, [state.nodes, setNodes]);

  useEffect(() => {
    setEdges(state.edges);
  }, [state.edges, setEdges]);

  return {
    nodes,
    edges,
    zoom,
    targetElement: state.getElement(state.targetElementID ?? ''),
    isConnecting,
    init: setRfInstance,
    onConnectStart,
    onConnectEnd,
    onNodesChange: handleNodesChange,
    onEdgesChange: handleEdgesChange,
    handleNodesDelete,
    handleEdgeDelete,
    handleEdgeUpdate,
    onConnect,
    onSave,
    onRestore,
  };
}

export default useCanvasFlow;
