import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostProjectResponse,
  ResponseWithData,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT } from '../apis';

interface PropsType {
  name: string;
  timezone: string;
  timezoneOffset: number;
}

async function postCreateProject(
  props: PropsType,
): Promise<ResponseWithData<PostProjectResponse>> {
  return axios
    .post(PROJECT, {
      name: props.name,
      timezone: props.timezone,
      timezoneOffset: props.timezoneOffset,
    })
    .then((res) => res.data);
}

export function usePostProject(
  option?: UseApi<ResponseWithData<PostProjectResponse>, PropsType>,
) {
  return useMutation({
    mutationKey: ['post-project'],
    mutationFn: (props: PropsType) => postCreateProject(props),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['get-project-list']);
      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
  });
}

export default usePostProject;
