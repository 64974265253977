import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TextButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette['grey']['white'],
  borderRadius: '12px',
  padding: '12px',
  boxSizing: 'border-box',
}));

export const DataBlockContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: '4px',
  marginBottom: '12px',
}));

export const DataBlockStyled = styled(Box)(({ theme }) => ({
  width: '25%',
}));

export const TextButtonInnerContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette['grey'][100],
  borderRadius: '8px',
  padding: '12px',
  wordBreak: 'break-all',
}));

export const TextContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}));

export const BlockStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: `1px solid ${theme.palette['grey'][100]}`,
  borderRadius: 8,
  padding: 8,
  background: 'white',
  gap: 4,
  width: 'auto',
  height: '76px',
}));
