/* eslint-disable react-hooks/exhaustive-deps */
import { useGetAccountInfo } from '@frontend/sorghum/data-access';
import {
  PAGE_LOGIN,
  PAGE_MICRO,
  PAGE_PAGES,
  usePath,
} from '@frontend/sorghum/utils';
import { useEffect } from 'react';

export const useAuth = () => {
  const token = localStorage.getItem('token');
  const { data: account, refetch } = useGetAccountInfo();
  const { navigate } = usePath();

  useEffect(() => {
    if (!token) {
      navigate(PAGE_LOGIN);
    } else if (account?.step === 'question') {
      navigate(PAGE_MICRO);
    } else if (account?.step === 'fb_connect') {
      navigate(PAGE_PAGES);
    } else {
      refetch();
    }
  }, [token, account]);

  return {
    userName: account?.userName || '?',
  };
};
