import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE } from '../apis';

interface PropsType {
  projectID: string;
  sequenceID: string;
  data: {
    id: string;
    name: string;
  };
}

async function patchSequence(props: PropsType): Promise<Response> {
  return axios
    .patch(`${SEQUENCE}?projectId=${props.projectID}`, { ...props.data })
    .then((res) => res.data);
}

export function usePatchSequence() {
  return useMutation({
    mutationKey: ['patch-sequence'],
    mutationFn: (props: PropsType) => patchSequence(props),
    onSuccess: (data, variables: PropsType) => {
      queryClient.refetchQueries([
        'get-sequence-list',
        { projectID: variables.projectID },
      ]);
      queryClient.refetchQueries([
        'get-sequence',
        { projectID: variables.projectID, sequenceID: variables.sequenceID },
      ]);
    },
  });
}
