import { ResponseWithData } from '@frontend/editor/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_AUTO_REPLY } from '../apis';

interface PostCommentAutoReplyProps {
  name: string;
  flowName?: string;
  flowId?: string;
  conditionName: string;
}

async function postCommentAutoReply(
  props: PostCommentAutoReplyProps,
): Promise<ResponseWithData<{ id: string }>> {
  return axios
    .post(`${COMMENT_AUTO_REPLY}`, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostCommentAutoReply() {
  return useMutation({
    mutationKey: ['post-comment-auto-reply'],
    mutationFn: (props: PostCommentAutoReplyProps) =>
      postCommentAutoReply(props),
  });
}

export default usePostCommentAutoReply;
