import { globalTheme } from '@frontend/components/external-providers';
import { Textarea, UploadImage } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import {
  useCanvasCollect,
  useCanvasRemove,
  useGetProjectID,
  useModal,
  usePostEditorFlowUpload,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  PublishErrorsEnum,
  RecurringNotificationType,
} from '@frontend/editor/interface';
import { IMAGE_RATIO, IMAGE_WIDTH } from '@frontend/editor/utils';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import { GetEditorLabelItemLabelType } from '@frontend/sorghum/interface';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Position, useViewport } from 'reactflow';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import HandlePoint from '../handle-point/handle-point';

interface RecurringNotificationProps {
  id: string;
  draggable: boolean;
  parentID: string;
  index: number;
  setIsHoverMenu: (isHover: boolean) => void;
}

const ContainerStyled = styled(Box)<{ $isHover: boolean; $zoom: number }>(
  ({ theme, $isHover, $zoom }) => ({
    position: 'relative',
    background: theme.palette['grey'][100],
    borderRadius: '8px',
    border: `2px solid ${
      $isHover ? theme.palette['info']['light'] : 'transparent'
    }`,
    padding: theme.spacing(1.5),
    fontSize: `${28 / $zoom}px`,
  }),
);

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '0 8px',
  marginBottom: '20px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '4px',
  cursor: 'pointer',
  height: '32px',
}));

const TextAreaWrapperStyled = styled(Box)(({ theme }) => ({
  marginTop: '20px',
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '0 8px 20px 8px',
}));

export const RecurringNotification = ({
  id, // RecurringNotification 的 ID
  index,
  parentID, // Block 的 ID
  draggable,
  setIsHoverMenu,
}: RecurringNotificationProps) => {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { zoom } = useViewport();
  const element =
    (state.getElement(id) as RecurringNotificationType) ||
    ({} as RecurringNotificationType);
  const [t] = useTranslation();
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isHoverImage, setIsHoverImage] = useState(false);
  const [isFocusTextarea, setIsFocusTextarea] = useState<boolean>(false);
  const { openDeleteElementModal } = useModal();
  const { canvasRemoveElement } = useCanvasRemove();
  const { getErrorStatus } = useCanvasCollect();
  const { data: projectID } = useGetProjectID();
  const { mutate: uploadImage } = usePostEditorFlowUpload(projectID as string);
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);

  const onRemoveButtonClick = useCallback(() => {
    // 如果有設定任何資料則跳出提示
    const needConfirm = !!element.fileID || !!element.title;
    if (needConfirm) {
      openDeleteElementModal(id);
    } else {
      canvasRemoveElement(id);
    }
  }, [
    canvasRemoveElement,
    element.fileID,
    element.title,
    id,
    openDeleteElementModal,
  ]);

  const handleChange = useCallback(
    (text: string) => {
      state.updateElementData(id, 'title', text);
    },
    [id, state],
  );

  const handleMouseEnter = useCallback(() => {
    uiState.onHoverElement(id, 2);
    setIsHover(true);
  }, [id, uiState]);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handleMouseEnterMenu = useCallback(() => {
    setIsHoverMenu(true);
  }, [setIsHoverMenu]);

  const handleMouseLeaveMenu = useCallback(() => {
    setIsHoverMenu(false);
  }, [setIsHoverMenu]);

  const handleImageChange = useCallback(
    (val: Blob) => {
      uploadImage(
        { file: val, fileType: '6' },
        {
          onSuccess: (data) => {
            if (data.code === 20000) {
              state.updateElementData(id, 'fileID', data.data.fileId);
              state.updateElementData(id, 'fileUrl', data.data.fileUrl);
            } else {
              Alert.error(t('alert.imageSize'));
            }
          },
        },
      );
    },
    [id, state, t, uploadImage],
  );

  const labelList = get(labelItems, 'data', []);

  return (
    <ContainerStyled
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => state.setOnFocusCellID(id)}
      $isHover={isHover}
      $zoom={zoom}
    >
      {/* Element 左側的刪除按鈕*/}
      {isHover &&
        !isHoverImage &&
        uiState.hoveredElement.layer < 3 &&
        !isFocusTextarea && (
          <EditorFloatPanel
            onMouseEnter={handleMouseEnterMenu}
            onMouseLeave={handleMouseLeaveMenu}
            allowAdd
            parentID={parentID}
            index={index}
            draggable={draggable}
            direction="column"
            handleDelete={onRemoveButtonClick}
            backgroundColor={
              globalTheme.palette?.grey ? globalTheme.palette.grey[100] : ''
            }
          />
        )}
      <TitleWrapperStyled>
        <Typography variant="subtitle2">
          {t('canvas.recurringNotification.drawer.title')}
        </Typography>
      </TitleWrapperStyled>
      <UploadImage
        imageType={element.imageType}
        defaultValue={element.fileUrl}
        title={t('canvas.recurringNotification.addImage')}
        info={
          element.imageType === 1
            ? t('canvas.recurringNotification.imageFormat1_91')
            : t('canvas.recurringNotification.imageFormat1')
        }
        width={IMAGE_WIDTH}
        ratio={IMAGE_RATIO}
        onMouseEnter={() => setIsHoverImage(true)}
        onMouseLeave={() => setIsHoverImage(false)}
        onChange={handleImageChange}
        error={
          !!getErrorStatus(
            id,
            PublishErrorsEnum.RECURRING_NOTIFICATION_IMAGE_EMPTY,
          )
        }
        publishError={
          !!getErrorStatus(
            id,
            PublishErrorsEnum.RECURRING_NOTIFICATION_IMAGE_EMPTY,
          )
        }
        toggleType={(val) => state.updateElementData(id, 'imageType', val)}
      />
      <TextAreaWrapperStyled>
        <Textarea
          defaultValue={element.title}
          placeholder={t('canvas.recurringNotification.addTitle')}
          limit={65}
          error={
            !!getErrorStatus(
              id,
              PublishErrorsEnum.RECURRING_NOTIFICATION_TITLE_EMPTY,
            )
          }
          publishError={
            !!getErrorStatus(
              id,
              PublishErrorsEnum.RECURRING_NOTIFICATION_TITLE_EMPTY,
            )
          }
          onFocus={() => setIsFocusTextarea(true)}
          onBlur={() => setIsFocusTextarea(false)}
          onChange={handleChange}
          allowEnter={false}
          labelItem={labelList.filter(
            (item: GetEditorLabelItemLabelType) =>
              item.type === 1 || item.type === 2,
          )}
        />
      </TextAreaWrapperStyled>
      <InfoWrapperStyled>
        <Typography variant="caption" color="grey.500">
          {t('canvas.recurringNotification.info')}
        </Typography>
      </InfoWrapperStyled>
      <Box sx={{ position: 'relative' }}>
        <Button
          fullWidth
          disabled
          variant="outlined"
          sx={{
            textTransform: 'none',
          }}
        >
          <Typography variant="body2" color="grey.900">
            {t('canvas.recurringNotification.button')}
          </Typography>
        </Button>
        <HandlePoint
          type="source"
          styles={{ top: '50%' }}
          id={element.outputID}
          position={Position.Right}
          isConnected={!!element.targetID}
        />
      </Box>
    </ContainerStyled>
  );
};

export default memo(RecurringNotification);
