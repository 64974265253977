import { TrendingStatusEnum } from '@frontend/components/interface';
import { TrendingNumber, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardSourceChannelData } from '@frontend/sorghum/data-access';
import {
  DashboardSourceChannelData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { isNumber } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type DataDashboardSourceChannelTableProps = PostDataDashboardProps;

const InfoIconStyled = styled(InfoIcon)({
  margin: '0 0 -4px 0',
});

export const DataDashboardSourceChannelTable: FC<
  DataDashboardSourceChannelTableProps
> = ({
  startDate,
  endDate,
  condition,
}: DataDashboardSourceChannelTableProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const [data, setData] = useState<DashboardSourceChannelData[]>([]);

  const { mutate: queryData } = usePostDataDashboardSourceChannelData(
    projectID as string,
  );

  useEffect(() => {
    if (startDate && endDate && projectID) {
      queryData(
        {
          startDate,
          endDate,
          condition,
        },
        {
          onSuccess: (_data: DashboardSourceChannelData[]) => {
            setData(_data);
          },
        },
      );
    }
  }, [condition, endDate, projectID, queryData, startDate, t]);

  return (
    <>
      <Box className="title">
        <Typography variant="h6" color="grey.800">
          <Trans i18nKey="dataDashboard.source.table.channelEntry" />
        </Typography>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">
              <Typography variant="body3" color="grey.500">
                <Trans i18nKey="dataDashboard.source.table.column.channel.title" />
                <Tooltip
                  placement="top"
                  title={t('dataDashboard.source.table.column.channel.tooltip')}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3" color="grey.500">
                <Trans i18nKey="dataDashboard.source.table.column.newUsers.title" />
                <Tooltip
                  placement="top"
                  title={t(
                    'dataDashboard.source.table.column.newUsers.tooltip',
                  )}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body3" color="grey.500">
                <Trans i18nKey="dataDashboard.source.table.column.lastPeriod.title" />
                <Tooltip
                  placement="top"
                  title={t(
                    'dataDashboard.source.table.column.lastPeriod.tooltip',
                  )}
                >
                  <InfoIconStyled fontSize="small" />
                </Tooltip>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((i, index) => {
            let numberStatus = TrendingStatusEnum.NULL;
            if (isNumber(i.uniqEnterUsersTrend)) {
              if (i.lastUniqEnterUsers > 0) {
                numberStatus = TrendingStatusEnum.UP;
              } else if (i.lastUniqEnterUsers < 0) {
                numberStatus = TrendingStatusEnum.DOWN;
              } else {
                numberStatus = TrendingStatusEnum.TIE;
              }
            }
            return (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body3" color="grey.500">
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip title={i.channelName} placement="top">
                    <Box
                      sx={{
                        maxWidth: '160px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <Typography variant="body3" color="grey.700">
                        {i.channelName}
                      </Typography>
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography variant="body3" color="grey.700">
                    {i.uniqEnterUsers}
                  </Typography>
                </TableCell>
                <TableCell>
                  <TrendingNumber
                    status={numberStatus}
                    percentage={i.uniqEnterUsersTrend}
                    total={i.lastUniqEnterUsers}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default DataDashboardSourceChannelTable;
