import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FlowGroup } from '@frontend/sorghum/interface';
import { FLOW_GROUP } from '../apis';

async function getEditorFlowGroup(projectId: string): Promise<FlowGroup[]> {
  return axios
    .get(`${FLOW_GROUP}?projectId=${projectId}`)
    .then((res) => {
      return res.data.data.flowGroups;
    })
    .catch(() => {
      return [];
    });
}

export function useGetEditorFlowGroup(projectId?: string) {
  return useQuery<FlowGroup[]>({
    queryKey: ['editor-flow-group', { projectId }],
    queryFn: () => {
      if (projectId) {
        return getEditorFlowGroup(projectId);
      } else {
        return [];
      }
    },
  });
}

export default useGetEditorFlowGroup;
