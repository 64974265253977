import { Dialog } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useFlows } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface RenameFlowModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (name: string) => void;
}

const FLOW_NAME_LIMIT = 100;

export const RenameFlowModal: FC<RenameFlowModalProps> = ({
  open,
  handleConfirm,
  handleClose,
}) => {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const [error, setError] = useState<string>('');
  const [name, setName] = useState<string>('');
  const ref = useRef<HTMLDivElement>(null);

  const { data: projectID } = useGetProjectID();

  const { data: flows } = useFlows({
    projectId: projectID as string,
    searchType: 'ALL',
  });

  const checkNameIsDuplicate = useCallback(
    (name: string) => {
      return (
        name !== uiState.name &&
        flows?.data.flows.findIndex((i) => i.name === name) !== -1
      );
    },
    [flows?.data.flows, uiState.name],
  );

  const validate = useCallback(() => {
    if (isEmpty(name)) {
      return t('flows.modal.renameFlowRequired');
    }
    const isDuplicate = checkNameIsDuplicate(name);
    if (isDuplicate) {
      return t('flows.modal.renameFlowRepeat');
    } else if (name.length > FLOW_NAME_LIMIT) {
      return t('flows.modal.renameFlowLimit');
    } else {
      return '';
    }
  }, [checkNameIsDuplicate, name, t]);

  const onSubmit = useCallback(() => {
    const error = validate();
    if (error) {
      setError(error);
      return;
    }
    handleConfirm(name);
  }, [handleConfirm, name, validate]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setName(e.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    setError('');
  }, []);

  useEffect(() => {
    // reset data when modal open
    if (uiState.modalType === ModalTypesEnum.RENAME_FLOW) {
      setName(uiState.name);
      setError('');
    }
  }, [uiState.modalType, uiState.name]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (open && ref.current) {
        ref.current.focus();
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [open]);

  return (
    <Dialog
      size="xs"
      title={t('common.rename')}
      open={open}
      closeBtn={false}
      handleClose={handleClose}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.ok')}
      handleConfirm={onSubmit}
    >
      <Box>
        <TextField
          fullWidth
          autoFocus
          inputRef={ref}
          onFocus={handleFocus}
          onChange={handleChange}
          size="small"
          value={name}
          label={t('flows.modal.flowNameLabel')}
          helperText={error}
          error={!!error}
          inputProps={{
            maxLength: FLOW_NAME_LIMIT,
          }}
        />
      </Box>
    </Dialog>
  );
};

export default RenameFlowModal;
