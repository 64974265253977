export const FLOW_ENTRY = '/api/v1/editor/flow/entry';
export const FLOW_ENTRY_STATUS = '/api/v1/editor/entry/status';
export const EDITOR_FLOW_UPLOAD = '/api/v1/editor/flow/upload';
export const PROJECT_SYMBOL = '/api/v1/project/symbol';
export const PROJECT_ATTRIBUTE_KEY = '/api/v1/project/attribute/key';
export const PROJECT_ATTRIBUTE_VALUE = '/api/v1/project/attribute/value';
export const CATEGORY_VALUE = '/api/v1/project/category/value';
export const COUPON_OPTION = '/api/v1/coupon/option';
export const GOOGLE_ACCOUNT = '/api/v1/editor/flow/googleAccount';
export const GOOGLE_SHEET_OPTION = '/api/v1/editor/flow/googleSheet/option';
export const GOOGLE_SHEET = '/api/v1/editor/flow/googleSheet';
export const GOOGLE_SHEET_PERMISSION =
  '/api/v1/editor/flow/googleSheet/permission';
export const SEQUENCE_OPTION = '/api/v1/sequence/option';
export const DATA_FLOW_CHART = '/api/v1/data/flow/chart';
export const DATA_FLOW_ENTRY = '/api/v1/data/flow/entry';
export const DATA_FLOW_ENTRY_ALL = '/api/v1/data/flow/entry/all';
export const FLOW_TEST = '/api/v1/editor/flow/test';
export const COMMENT_AUTO_REPLY = 'api/v1/commentReply';

//admin
export const ADMIN_FLOW_TEMPLATE = '/api/v1/admin/flow/template';
