import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/system';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { globalTheme } from '@frontend/components/external-providers';

/* eslint-disable-next-line */
export interface SortProps extends BoxProps {
  index: number;
  ellipsisPosition?: 'start' | 'center' | 'end';
  sortSpace: number;
  handleDrop: (indexA: number, indexB: number, before: boolean) => void;
}

const leftSide = '40px';
const spacing = 2;

const DragWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  position: 'relative',
  '& > #drag': {
    opacity: 0,
    zIndex: 0,
  },
  '&:hover': {
    '& > #drag': {
      opacity: 1,
      zIndex: 20,
    },
  },
}));

const DropWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  display: 'inline-flex',
  paddingLeft: leftSide,
  '& > div': {
    width: `calc(100%)`,
  },
}));

export interface SortType {
  index: number;
  before: boolean;
}

export const Sort: FC<SortProps> = ({
  children,
  index,
  ellipsisPosition = 'center',
  sortSpace,
  handleDrop,
  ...props
}: SortProps) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'Box',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    drop: () => ({ index }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<SortType>();
      if (dropResult) {
        handleDrop(item.index, dropResult.index, dropResult.before);
      }
    },
  }));

  const [{ isOver: overTop }, dropTop] = useDrop(() => ({
    accept: 'Box',
    drop: () => ({ index, before: true }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    }),
  }));

  const [{ isOver: overBottom }, dropBottom] = useDrop(() => ({
    accept: 'Box',
    drop: () => ({ index, before: false }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <DragWrapper {...props} sx={{ opacity }} alignItems={ellipsisPosition}>
      <Box
        id="drag"
        ref={drag}
        mr={2}
        height="fit-content"
        sx={{
          zIndex: 10,
        }}
      >
        <DragIndicatorIcon sx={{ color: 'grey.400' }} />
      </Box>
      <DropWrapper
        ref={dropTop}
        sx={{
          height: `calc(50% + ${sortSpace}px)`,
          top: `-${(sortSpace - spacing) / 2}px`,
        }}
      >
        <Box
          sx={{
            borderTop: `${spacing}px solid ${
              overTop && !isDragging
                ? globalTheme.palette?.grey?.[400]
                : 'transparent'
            }`,
          }}
        />
      </DropWrapper>
      <DropWrapper
        ref={dropBottom}
        sx={{
          top: `calc(50% - ${sortSpace * 1.5}px + ${spacing}px)`,
          height: `calc(50% + ${sortSpace}px)`,
        }}
      >
        <Box
          sx={{
            borderBottom: `${spacing}px solid ${
              overBottom && !isDragging
                ? globalTheme.palette?.grey?.[400]
                : 'transparent'
            }`,
          }}
        />
      </DropWrapper>
      <Box sx={{ width: '100%', mb: `${sortSpace}px` }}>{children}</Box>
    </DragWrapper>
  );
};

export default Sort;
