import {
  DashboardSourceFlowData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_DATA_SOURCE_ENTRY } from '../apis';

const defaultValue = [] as DashboardSourceFlowData[];

async function postDataDashboardSourceFlowData(
  projectID: string,
  params: PostDataDashboardProps,
): Promise<DashboardSourceFlowData[]> {
  return axios
    .post(`${DASHBOARD_DATA_SOURCE_ENTRY}?projectId=${projectID}`, params)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardSourceFlowData(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-source-flow-data'],
    mutationFn: (props: PostDataDashboardProps) => {
      return postDataDashboardSourceFlowData(projectID, props);
    },
  });
}

export default usePostDataDashboardSourceFlowData;
