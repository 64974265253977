import { globalTheme } from '@frontend/components/external-providers';
import { IconSizeType } from '@frontend/components/interface';
import { IconButton, Switch } from '@frontend/components/ui';
import { useCanvasGet, useCanvasView } from '@frontend/editor/data-access';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeProps, Position } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import { HandlePoint } from '../handle-point/handle-point';
import AssistantNavigationIcon from '../icons/assistant-navigation-icon';
import { NodeHeader } from '../node-header/node-header';

export const StartWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '-35px',
  borderRadius: '100px 50px 0px 100px',
  border: `1px solid ${theme.palette?.['grey'][300]}`,
  width: '85px',
  color: theme.palette?.['grey'][800],
  background: theme.palette?.['grey'][200],
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '4px 12px 4px 10px',
}));

const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  padding: '16px',

  '& > div': {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'column',
    borderRadius: '12px',
    padding: '16px',
    background: theme.palette['grey'][100],
    gap: '8px',
  },
}));

const EntryPointItemInlineWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const EntryPointItemTitleWrapperStyled = styled(Typography)(({ theme }) => ({
  width: '180px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const BotLinkWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  background: theme.palette['bluegrey'][50],
  padding: '4px 8px',
  borderRadius: '4px',
  justifyContent: 'space-between',
}));

export const NodeEntryPoint = ({ id }: NodeProps) => {
  const [t] = useTranslation();
  const { onFocusID } = useCanvasView();
  const { getTargetElement } = useCanvasGet();
  const targetElement = getTargetElement(id);
  const targetID = get(targetElement, 'targetID', '');
  const outputID = get(targetElement, 'outputID', '');

  return (
    <Box>
      <HandlePoint
        id={outputID}
        type="source"
        position={Position.Right}
        styles={{
          top: '95%',
        }}
        isConnected={!!targetID}
        isFocus={onFocusID === id}
      />
      {/* NodeEntryPoint 上方的 start 標籤 */}
      <StartWrapper>
        <PlayCircleOutlineIcon color="success" fontSize="small" />
        <Typography variant="body2">{t('canvas.start')}</Typography>
      </StartWrapper>
      <EditorBlockBorder
        nodeID={id}
        color={get(globalTheme, 'palette.green.600', '')}
      >
        <NodeHeader
          background={globalTheme.palette?.['green'][50]}
          color={globalTheme.palette?.['green'][600]}
          icon={<AssistantNavigationIcon fontSize="small" />}
          title={t('canvas.entryPoint.title')}
          readonly
        />

        <BodyStyled>
          <Box>
            <EntryPointItemInlineWrapper>
              <LinkIcon color="success" />
              <EntryPointItemTitleWrapperStyled variant="subtitle2">
                {'bot link #1'}
              </EntryPointItemTitleWrapperStyled>
              <Box>
                <Switch size="small" checked={true} />
              </Box>
            </EntryPointItemInlineWrapper>

            <BotLinkWrapperStyled>
              <Link variant="notoSans" color="info.main">
                {'https://m.me.....ref=event1'}
              </Link>
              <IconButton
                id="node-tour-entry-point-icon-button"
                $size={IconSizeType.XXS}
              >
                <ContentCopyIcon />
              </IconButton>
            </BotLinkWrapperStyled>
          </Box>
        </BodyStyled>
      </EditorBlockBorder>
    </Box>
  );
};

export default memo(NodeEntryPoint);
