import { Loading } from '@frontend/components/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useIsFetching } from '@tanstack/react-query';
import { memo } from 'react';

const ContainerStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.54)',
  // 在 top-bar 之下
  zIndex: 99,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ViewLoadingModal = () => {
  const isFetching = useIsFetching();

  if (!isFetching) {
    return null;
  }

  return (
    <ContainerStyled>
      <Loading />
    </ContainerStyled>
  );
};

export default memo(ViewLoadingModal);
