import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetEditorMenu,
  usePatchEditorMenu,
  usePostEditorMenu,
} from '@frontend/sorghum/data-access';
import {
  MAIN,
  PAGE_PERSISTENT_MENU,
  PERSISTENT_MENU,
  useDocumentTitle,
  usePath,
  useTimer,
  uuid,
} from '@frontend/sorghum/utils';
import jsonDiff from 'json-diff';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Alert, resortList } from '@frontend/components/utils';
import {
  EditorMenuItemType,
  EditorMenuType,
  GetEditorMenuType,
  PatchEditorMenuType,
  PersistentMenuPublishErrorsEnum,
  PersistentMenuPublishErrorsItemType,
  PostEditorMenuResponseType,
  PublishStatus,
  ResponseWithData,
  UsePagePersistentMenuReturn,
} from '@frontend/sorghum/interface';
import { isUndefined } from 'lodash';

export const usePage = () => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();
  const [formData, setFormData] = useState<EditorMenuType>({
    status: 1,
    items: [],
    enable: false,
  });

  const [publishStatus, setPublishStatus] = useState<number>(0);
  // 點擊發布前才需要檢查的錯誤
  const [publishErrors, setPublishErrors] = useState<
    PersistentMenuPublishErrorsItemType[]
  >([]);
  // 所有 item 的 title，拿來檢查有無重複的 title
  const [titles, setTitles] = useState<string[]>([]);
  // call publish API 後回傳尚未 publish 的 flow
  const [unpublishedFlows, setUnpublishedFlows] = useState<
    { id: string; name: string }[]
  >([]);
  // 控制 publish 後有尚未 publish flows 的 Dialog 開關
  const [isOpenUnpublishedFlowModal, setIsOpenUnpublishedFlowModal] =
    useState(false);
  // 控制刪除 item 的提示 modal
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  // 記錄正在編輯的 item index
  const [handleEdit, setHandleEdit] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  // 將 publish 狀態轉換成文字
  const getPublishStatus = useCallback(
    (status: number) => {
      if (!status) return '';

      switch (status) {
        case PublishStatus.UNPUBLISH:
          return t('common.publish');
        case PublishStatus.UNPUBLISHED_CHANGES:
          return t('common.update');
        default:
          return t('common.publish');
      }
    },
    [t],
  );

  const { data: persistentMenu } = useGetEditorMenu(projectID as string, 1);
  const { mutate: updateMenu, isLoading: isSaving } = usePatchEditorMenu(
    projectID as string,
    1,
  );

  const { mutate: publishMenu } = usePostEditorMenu(projectID as string, 1);

  const breadcrumbItems = [
    {
      id: MAIN,
      label: t('common.breadcrumbs.project'),
      onClick: () => navigateToProjectPage(),
    },
  ];

  const breadcrumbNow = {
    id: PERSISTENT_MENU,
    label: t('common.breadcrumbs.persistentMenu'),
    onClick: () => navigateToProjectPage(PAGE_PERSISTENT_MENU),
  };

  // 拿所有 persistent item 的 title 之後方便檢查有沒有重複的 title
  const getTitles = useCallback(() => {
    if (!formData.items) return;

    const titleArray: string[] = [];
    formData.items.forEach((item) => {
      if (!isUndefined(item.title)) {
        titleArray.push(item.title);
      }
    });
    setTitles(titleArray);
  }, [formData]);

  // map data to format of API input
  const convertOutputData = useCallback((value: EditorMenuType) => {
    const outputData: PatchEditorMenuType = {
      enable: value.enable,
      items: value.items.map((item: EditorMenuItemType) => {
        const param = {
          title: item.title as string,
          type: parseInt(item.type as string, 10),
          ...(item.id && !item.isNew && { id: item.id }),
          ...(parseInt(item.type as string, 10) === 1 &&
            item.flow && { flowId: item.flow.id }),
          ...(parseInt(item.type as string, 10) === 2 && {
            ...(item.url && { url: item.url }),
            ...{ size: item.size || 1 },
          }),
        };

        return param;
      }),
    };

    return outputData;
  }, []);

  // validation before publish
  const checkBeforePublish = useCallback(() => {
    if (!formData) return [];
    const errors: PersistentMenuPublishErrorsItemType[] = [];
    const urlRegex = /https?:\/\//g;

    convertOutputData(formData).items.map((item) => {
      if (!item.title) {
        // 無填寫標題
        errors.push({
          id: item.id as string,
          type: PersistentMenuPublishErrorsEnum.NO_TITLE,
          message: t('basicSetting.persistentMenu.validate.titleRequired', {
            publishStatus: getPublishStatus(persistentMenu?.status as number),
          }),
        });
      } else if (titles.filter((i) => i === item.title).length > 1) {
        // 重複的標題名稱
        errors.push({
          id: item.id as string,
          type: PersistentMenuPublishErrorsEnum.DUPLICATED_TITLE,
          message: t('basicSetting.persistentMenu.validate.titleRepeat', {
            publishStatus: getPublishStatus(persistentMenu?.status as number),
          }),
        });
      }
      if (item.type === 1) {
        if (!item.flowId) {
          // 沒有選擇 flow
          errors.push({
            id: item.id as string,
            type: PersistentMenuPublishErrorsEnum.NO_SELECT_FLOW,
            message: t('basicSetting.persistentMenu.validate.flowEmpty'),
          });
        }
      } else if (item.type === 2) {
        if (item.url === '' || !item.url) {
          // 沒有輸入 URL
          errors.push({
            id: item.id as string,
            type: PersistentMenuPublishErrorsEnum.NO_URL,
            message: t('basicSetting.persistentMenu.errorEmptyURL', {
              status: getPublishStatus(persistentMenu?.status as number),
            }),
          });
        } else if (item.url.match(urlRegex) === null) {
          // URL 格式錯誤
          errors.push({
            id: item.id as string,
            type: PersistentMenuPublishErrorsEnum.INCORRECT_URL_FORMAT,
            message: t('basicSetting.persistentMenu.errorFailed', {
              status: getPublishStatus(persistentMenu?.status as number),
            }),
          });
        }
      }
      return errors;
    });

    return errors;
  }, [
    convertOutputData,
    formData,
    getPublishStatus,
    persistentMenu?.status,
    t,
    titles,
  ]);

  // set menu enable status
  const setMenuStatus = useCallback(
    (_menuStatus: boolean) => {
      if (!formData) {
        return;
      }
      const newMenu = {
        ...formData,
        enable: _menuStatus,
      };

      setFormData(newMenu);
    },
    [formData],
  );

  // 更改 item 的排序
  const changeSort = useCallback(
    (indexA: number, indexB: number) => {
      const newArray = resortList(formData.items, indexA, indexB);
      const newData: GetEditorMenuType = {
        status: formData.status,
        enable: formData.enable,
        items: newArray,
      };
      setFormData(newData);
    },
    [formData],
  );

  // 打開刪除 modal 時紀錄刪除的 item index
  const openDeleteModal = (index: number) => {
    setHandleEdit(index);
    setIsOpenDeleteModal(true);
  };

  // 新增 persistent menu item
  const addNewMenuItem = useCallback(() => {
    const newItem: EditorMenuItemType = {
      id: uuid(),
      title: '',
      type: 1,
      size: 1,
      isNew: true,
    };

    setFormData({
      ...formData,
      items: [...formData.items, newItem],
    });
  }, [formData]);

  // 刪除 persistent menu item
  const deleteMenuItem = useCallback(() => {
    const newMenu: EditorMenuType = {
      ...formData,
      items: formData.items.filter((i, index) => index !== handleEdit),
    };

    setFormData(newMenu);
    setIsOpenDeleteModal(false);
  }, [handleEdit, formData]);

  // set value in form
  const setFieldValue = useCallback(
    (id: string, fieldName: string, value: string) => {
      if (!formData) return;

      const modifiedItem = formData.items.map((item) => {
        if (item.id !== id) {
          return item;
        }

        return {
          ...item,
          [fieldName]: value,
        };
      });

      setFormData({ ...formData, items: modifiedItem });
    },
    [formData],
  );

  const setItemData = useCallback(
    (itemID: string, itemData: EditorMenuItemType) => {
      if (!formData) return;

      const modifiedItem = formData.items.map((item) => {
        if (item.id !== itemID) {
          return item;
        }

        return itemData;
      });

      setFormData({ ...formData, items: modifiedItem });
    },
    [formData],
  );

  const onUpdateFlowID = useCallback(
    (flowID: string, itemID: string) => {
      if (!formData) return;

      const modifiedItem: EditorMenuItemType[] = formData.items.map((item) => {
        if (item.id !== itemID) {
          return item;
        }

        return {
          ...item,
          flow: {
            ...(item.flow && item.flow),
            id: flowID,
          },
        } as EditorMenuItemType;
      });

      updateMenu(convertOutputData({ ...formData, items: modifiedItem }));
    },
    [convertOutputData, formData, updateMenu],
  );

  // save data function
  const onSave = useCallback(() => {
    if (formData && projectID && isLoaded) {
      updateMenu(convertOutputData(formData));
    }
  }, [convertOutputData, formData, isLoaded, projectID, updateMenu]);

  const checkUpdated = useCallback(() => {
    if (!persistentMenu || !isLoaded) return false;

    const { updatedAt, ...props } = persistentMenu;
    const diff = jsonDiff.diffString(
      convertOutputData({ ...props }),
      convertOutputData(formData),
    );
    return !!diff;
  }, [persistentMenu, isLoaded, convertOutputData, formData]);

  // publish data function
  const onPublish = useCallback(() => {
    const errors = checkBeforePublish();
    setPublishErrors(errors);

    if (errors.length > 0 && errors[0].message !== '') {
      return toast.warning(errors[0].message);
    } else if (unpublishedFlows.length > 0 && formData.enable) {
      if (persistentMenu?.status === 1) {
        setIsOpenUnpublishedFlowModal(true);
      } else {
        Alert.error(t('basicSetting.welcomeMessage.alert.flowUnpublished'));
      }
    } else {
      return publishMenu(convertOutputData(formData), {
        onSuccess: (data: ResponseWithData<PostEditorMenuResponseType>) => {
          if (data.code !== 20000) {
            if (data.msg === 'flow unpublished') {
              setUnpublishedFlows(data.data?.flows ?? []);
              return;
            }

            //if have unpublished flows, show modal
            return Alert.error(
              `${getPublishStatus(persistentMenu?.status as number)} failed`,
            );
          }
          return Alert.success(
            t('basicSetting.persistentMenu.publishToast.success', {
              publishStatus: getPublishStatus(persistentMenu?.status as number),
            }),
          );
        },
      });
    }
  }, [
    checkBeforePublish,
    convertOutputData,
    formData,
    getPublishStatus,
    persistentMenu?.status,
    publishMenu,
    t,
    unpublishedFlows.length,
  ]);

  useEffect(() => {
    setIsUpdated(checkUpdated);
  }, [checkUpdated]);

  useEffect(() => {
    if (persistentMenu) {
      const { updatedAt, ...props } = persistentMenu;
      setFormData({ ...props });
      setPublishStatus(persistentMenu.status || 1);
      setIsLoaded(true);
      setIsUpdated(false);
    }
  }, [persistentMenu]);

  useEffect(() => {
    getTitles();
  }, [getTitles]);

  // autosave
  useTimer(2, isUpdated, onSave);

  /********** 以下為傳入 BasicSetting 的 functions ************/
  const [tabValue, setTabValue] = useState(0);
  const [socialType, setSocialType] = useState<1 | 2>(1);

  useEffect(() => {
    if (tabValue === 0) {
      setSocialType(1);
    } else {
      setSocialType(2);
    }
  }, [tabValue]);

  useDocumentTitle(t('title.persistentMenu'));

  return {
    formData,
    publishStatus,
    breadcrumbItems,
    breadcrumbNow,
    isSaving,
    onPublish,
    menuStatus: formData ? formData.enable : false,
    setMenuStatus,
    changeSort,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    deleteMenuItem,
    openDeleteModal,
    setIsOpenUnpublishedFlowModal,
    isOpenUnpublishedFlowModal,
    unpublishedFlows,
    addNewMenuItem,
    setFormData,
    publishErrors,
    setFieldValue,
    onSave,
    setItemData,
    onUpdateFlowID,
    getPublishStatus,
    /* 以下為傳入 BasicSetting 的 functions */
    tabValue,
    setTabValue,
  } as UsePagePersistentMenuReturn;
};
