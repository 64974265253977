import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { IconButton } from '../icon-button/icon-button';

interface ImageUploadContainerStyledProps {
  $ratio?: number;
  $width: number;
  $error: boolean;
  $hasImage: boolean;
}

export const ImageUploadContainerStyled = styled(
  Box,
)<ImageUploadContainerStyledProps>(
  ({ theme, $width, $ratio, $error, $hasImage }) => ({
    position: 'relative',
    width: `${$width}px`,
    ...($ratio && { height: `${$width / $ratio}px` }),
    background: theme.palette['grey'][50],
    color: theme.palette['grey'][300],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    border: `1px solid ${
      $error ? theme.palette['error']['main'] : 'transparent'
    }`,
    '&:hover': {
      ...(!$hasImage && {
        border: `1px solid ${theme.palette['info']['main']}`,
      }),
    },
  }),
);

export const IconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({
  // marginBottom: '2px',
}));

export const UploadImageEmptyWrapperStyled = styled(Box)<{ $error: boolean }>(
  ({ theme }) => ({
    padding: theme.spacing(1),
    borderRadius: '8px',
  }),
);

export const PreviewImageWrapperStyled = styled('img')(({ theme }) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  border: `1px solid transparent`,
  '&:hover': {
    border: `1px solid ${theme.palette['info']['main']}`,
  },
}));

export const LoadingWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
}));

export const HiddenInputWrapper = styled(Box)(({ theme }) => ({
  // 為了讓 input 能夠被 focus，但又不顯示在畫布上
  opacity: 0,
  height: 0,
  width: 0,
}));

export const ToggleWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '4px',
  width: 'fit-content',
  borderRadius: '100px',
  position: 'absolute',
  top: '-20px',
  background: theme.palette['grey']['white'],
}));

export const IconButtonStyled = styled(IconButton)<{
  $isActive: boolean;
  color: string;
}>(({ theme, $isActive }) => ({
  ...(!$isActive && { color: theme.palette['grey'][400] }),
}));

export const FloatPanelWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  padding: 4,
  left: '-40px',
  paddingRight: '20px',
  '& > div': {
    background: theme.palette?.['grey']['white'],
    borderRadius: '100px',
  },
}));

export const ImageModalWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));
