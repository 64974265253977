import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const CellButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  marginTop: '12px',
  height: '40px',
  width: '100%',
  background: 'rgba(255, 255, 255, 0.75)',
  '.MuiInputBase-root': {
    height: '100%',
  },

  '.Mui-disabled': {
    backgroundColor: theme.palette['grey'][100],
    color: theme.palette['grey'][500],

    '&.fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette['grey'][300],
    },
  },

  '& input': {
    textAlign: 'center',
  },
}));

export const TooltipStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  bottom: '-38px',
}));
