import { queryClient } from '@frontend/sorghum/external-providers';
import {
  PostEditorMenuResponseType,
  PostEditorMenuType,
  ResponseWithData,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_MENU } from '../apis';

async function postEditorMenu(
  projectId: string,
  socialType: number,
  params: PostEditorMenuType,
): Promise<ResponseWithData<PostEditorMenuResponseType>> {
  return axios
    .post(
      `${EDITOR_MENU}?projectId=${projectId}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostEditorMenu(
  projectID: string,
  socialType: number,
  options?: UseApi<
    ResponseWithData<PostEditorMenuResponseType>,
    PostEditorMenuType
  >,
) {
  return useMutation({
    mutationKey: ['post-editor-menu', { projectID, socialType }],
    mutationFn: (params: PostEditorMenuType) =>
      postEditorMenu(projectID, socialType, params),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries([
        'get-editor-menu',
        { projectID, socialType },
      ]);
      queryClient.invalidateQueries(['get-editor-flow-entry']);
      queryClient.invalidateQueries(['get-editor-entry-status']);
      if (options?.onSuccess) options?.onSuccess(data, variables);
    },
  });
}

export default usePostEditorMenu;
