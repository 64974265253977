import {
  AutocompleteV2,
  OptionTypeV2,
} from '../autocomplete-v2/autocomplete-v2';

import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetFlowListWithPublishStatus } from '@frontend/sorghum/data-access';

interface PublishedFlowSelectProps {
  onChange?: (newValue: OptionTypeV2 | null) => void;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  excludeFlow?: string;
  errorMessage?: string;
}

const FlowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '& > div': {
    width: '100%',
  },
  '& > svg': {
    position: 'relative',
    marginTop: '24px',
    marginLeft: '11px',
    right: '0',
    cursor: 'pointer',
  },
}));

export function PublishedFlowSelect({
  onChange,
  value,
  placeholder,
  disabled = false,
  excludeFlow,
  errorMessage,
}: PublishedFlowSelectProps) {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: flowData } = useGetFlowListWithPublishStatus({
    projectId: projectID as string,
  });

  const newOptionFlows: OptionTypeV2[] = [];

  if (flowData?.flows && flowData?.flows.length > 0) {
    flowData?.flows
      .filter((item) => item.status > 1)
      .map((i) =>
        newOptionFlows.push({
          label: i.name as string,
          value: i.id,
        }),
      );
  }

  const handleChange = useCallback(
    (_: object, newValue: OptionTypeV2 | null) => {
      onChange && onChange(newValue as OptionTypeV2);
    },
    [onChange],
  );

  return (
    <FlowWrapper>
      <AutocompleteV2
        value={value}
        errorMessage={errorMessage}
        disabled={disabled}
        onChange={handleChange}
        noOptionsText={t('select.flow.noData')}
        placeholder={
          placeholder ?? t('select.flow.publishedFlowSelectPlaceholder')
        }
        options={
          excludeFlow
            ? newOptionFlows.filter((item) => item.value !== excludeFlow)
            : newOptionFlows
        }
      />
    </FlowWrapper>
  );
}

export default PublishedFlowSelect;
