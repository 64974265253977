import { Box, CircularProgress, Modal } from '@mui/material';
import { FC } from 'react';

export interface FullScreenLoadingProps {
  isLoading?: boolean;
  handleClose?: () => void;
}

export const FullScreenLoading: FC<FullScreenLoadingProps> = ({
  isLoading = true,
  handleClose,
}: FullScreenLoadingProps) => {
  return (
    <Modal
      open={isLoading}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading && <CircularProgress />}
      </Box>
    </Modal>
  );
};

export default FullScreenLoading;
