import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY } from '../apis';

async function deleteCommentAutoReply(id: string): Promise<Response> {
  return axios
    .delete(`${COMMENT_REPLY}?id=${id}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}
export function useDeleteCommentAutoReply() {
  return useMutation({
    mutationKey: ['delete-comment-auto-reply'],
    mutationFn: (id: string) => deleteCommentAutoReply(id),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['get-comment-reply-list'],
      });
    },
  });
}

export default useDeleteCommentAutoReply;
