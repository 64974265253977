import { ResponseWithData } from '@frontend/editor/interface';
import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_ATTRIBUTE_VALUE } from '../apis';

interface Param {
  value: string;
  attributeID: string;
}

interface PostProjectAttributeValueProps {
  projectID: string;
  param: Param;
}

async function postProjectAttributeValue({
  projectID,
  param,
}: PostProjectAttributeValueProps): Promise<ResponseWithData<{ id: string }>> {
  return axios
    .post(`${PROJECT_ATTRIBUTE_VALUE}?projectId=${projectID}`, {
      value: param.value,
      attributeId: param.attributeID,
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostProjectAttributeValue(projectID: string) {
  return useMutation({
    mutationKey: ['post-project-attribute-value', { projectID }],
    mutationFn: (param: Param) =>
      postProjectAttributeValue({ projectID, param }),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-project-category-value']);
      queryClient.invalidateQueries(['get-google-sheet-option']);
    },
  });
}

export default usePostProjectAttributeValue;
