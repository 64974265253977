import {
  BaseElement,
  BlockTypesEnum,
  Editor,
  EditorContext,
  ElementCollectType,
  EntryPointBroadcast,
  EntryPointStatus,
  EntryPointsEnum,
  FlowEntryPoint,
  NodeTypesEnum,
  PublishError,
} from '@frontend/editor/interface';
import { isValueInEnum, uuid } from '@frontend/editor/utils';
import jsonDiff from 'json-diff';
import { set } from 'lodash';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Edge, MarkerType, Node, ReactFlowProvider } from 'reactflow';
import { useImmer } from 'use-immer';
import { UIProvider } from './UIProvider';

const initialState: Editor = {
  mapElement: new Map<string, BaseElement>(),
  elements: [],
  nodes: [],
  edges: [],
  labelItems: [],
  entryPointStatus: {
    welcomeMessage: {
      id: '',
      i18nKey: 'welcomeMessage',
      type: EntryPointsEnum.WELCOME_MESSAGE,
      isUsed: false,
      flow: {
        id: '',
        name: '',
      },
    },
    defaultAnswer: {
      id: '',
      i18nKey: 'defaultAnswer',
      type: EntryPointsEnum.DEFAULT_ANSWER,
      isUsed: false,
      flow: {
        id: '',
        name: '',
      },
    },
  },
  flowEntryPoints: [],
  broadcasts: [],
};

export const EditorCtx = createContext<EditorContext>({} as EditorContext);

export const EditorProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // 攤平的 base element json
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const [elements, setElements] = useImmer<ElementCollectType[]>([]);
  const [blockSerialNumber, setBlockSerialNumber] = useState<number>(1);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [tourMode, setTourMode] = useState<boolean>(false);
  const [nodes, setNodes] = useState<Node[]>(initialState.nodes);
  const [edges, setEdges] = useState<Edge[]>(initialState.edges);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [currentElement, setCurrentElement] = useState('');
  const [onHoverNode, setOnHoverNode] = useState('');
  const [defaultFocusID, setDefaultFocusID] = useState<string>('');

  const [targetElementID, setTargetElementID] = useState<string>('');
  // focused node ID
  const [onFocusID, setOnFocusID] = useState<string>('');
  const [onFocusCellID, setOnFocusCellID] = useState<string>('');
  const [entryPoints, setEntryPoints] = useState<EntryPointStatus>(
    initialState.entryPointStatus,
  );
  const [publishStatus, setPublishStatus] = useState<number>(2);
  const [flowEntryPoints, setFlowEntryPoints] = useState<FlowEntryPoint[]>(
    initialState.flowEntryPoints,
  );
  const [broadcasts, setBroadcasts] = useState<EntryPointBroadcast[]>(
    initialState.broadcasts,
  );

  // 畫布是否可拖曳
  const [isFlowDraggable, setIsFlowDraggable] = useState(true);
  // 畫布是否可以上下捲動
  const [isFlowPreventScrolling, setIsFlowPreventScrolling] = useState(true);
  // 畫布是否可以用滾輪放大
  const [isFlowZoomOnScroll, setIsFlowZoomOnScroll] = useState(true);
  // 畫布是否可以雙擊放大
  const [isFlowZoomOnDoubleClick, setIsFlowZoomOnDoubleClick] = useState(true);
  const [publishErrors, setPublishErrors] = useState<PublishError[]>([]);

  const [countdownUpdateTimer, setCountdownUpdateTimer] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isDrawerUpdate, setIsDrawerUpdate] = useState(false);
  const [menuCoordination, setMenuCoordination] = useState({ x: 0, y: 0 });

  //更新 elements 裡面的資料
  const updateElementData = useCallback(
    (id: string, path: string, data: any) => {
      setElements((element) => {
        const target = element.find((item) => item.id === id);

        if (target) {
          set(target, path, data);
        }
      });
    },
    [setElements],
  );

  const handleSetEntryPoint = useCallback((status: EntryPointStatus) => {
    setEntryPoints(status);
  }, []);
  const handleSetFlowEntryPoint = useCallback(
    (entryPoint: FlowEntryPoint[]) => {
      setFlowEntryPoints(entryPoint);
    },
    [],
  );
  const handleSetBroadcasts = useCallback(
    (newBroadcasts: EntryPointBroadcast[]) => {
      setBroadcasts(newBroadcasts);
    },
    [],
  );

  const handleSetFocusID = useCallback(
    (id: string) => {
      const targetElement = elements.find((item) => item.id === id);
      if (
        targetElement &&
        isValueInEnum(targetElement.elementType, BlockTypesEnum)
      ) {
        setOnFocusID(id);
      }
    },
    [elements],
  );

  const updateNode = useCallback(() => {
    const newNode: Node[] = [];
    const newEdges: Edge[] = [];

    // update node
    elements.map((item: BaseElement) => {
      if (isValueInEnum(item.elementType, NodeTypesEnum)) {
        //把 elements 轉成 reactflow 要的格式，僅提供 node 必要的資料，剩下的在 component 內處理
        // 被 focus 的 node 會疊在最上面
        newNode.push({
          id: item.id,
          type: item.elementType,
          data: {},
          position: item.position || { x: 0, y: 0 },
          ...(item.id === onFocusID && { zIndex: 20 }),
        });
      }

      return null;
    });

    // update edge
    elements.forEach((item) => {
      if (item.targetID && item.outputID) {
        const target = elements.find((x) => x.id === item.targetID);
        if (target) {
          let newEdge: Edge;
          // 只有在內層的元素才會有 node id
          if (item.nodeID) {
            newEdge = {
              id: uuid(),
              source: item.nodeID,
              sourceHandle: item.outputID,
              target: target.id,
              targetHandle: target.inputID,
              updatable: 'target',
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            };
          } else {
            newEdge = {
              id: uuid(),
              source: item.id,
              target: target.id,
              updatable: 'target',
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            };
          }

          newEdges.push(newEdge);
        }
      }
    });

    setNodes(newNode);
    setEdges(newEdges);
  }, [elements, onFocusID]);

  //新增攤平的 element 到 elements
  const addElement = useCallback(
    (newElement: any, parentID?: string, index?: number) => {
      setElements((element) => {
        // 如果找到父層元件要將子層 id 加入 children
        element.forEach((i) => {
          if (i.id === parentID) {
            // 如果 index 是數字代表指定插入的位置
            if (index) {
              i.children.splice(index, 0, newElement.id);
            } else {
              set(newElement, 'index', i.children.length);
              i.children.push(newElement.id);
            }
            // nodeID 為最上層 id，如果沒有 nodeID 代表該父層為最上層
            set(newElement, 'nodeID', i.nodeID ?? i.id);
          }
        });

        // 如果 id 不存在則新增新的 element
        if (element.findIndex((i) => i.id === newElement.id) === -1) {
          element.push(newElement);
        }
      });
    },
    [setElements],
  );

  const removeElement = useCallback(
    (id: string) => {
      setElements((draft) => {
        const target = draft.find((i) => i.id === id);
        if (target) {
          // 移除連接到此 element 的 targetID
          draft
            .filter((el) => el.targetID === target.id)
            .map((el) => set(el, 'targetID', ''));

          // 若為子元素則從父層移除
          if (target.parentID) {
            draft
              .filter((el) => el.id === target.parentID)
              .forEach((el) => {
                const child = el.children.findIndex((i) => i === target.id);
                el.children.splice(child, 1);
              });
          }

          // 若有 children 則移除以下所有 element
          if (target.children) {
            draft
              .filter(
                (el) => el.nodeID === target.id || el.parentID === target.id,
              )
              .forEach((i) => {
                const removeIndex = draft.findIndex((el) => el.id === i.id);
                if (removeIndex !== -1) draft.splice(removeIndex, 1);
              });
          }

          // 移除 element
          const removeTargetIndex = draft.findIndex((el) => el.id === id);
          if (removeTargetIndex !== -1) draft.splice(removeTargetIndex, 1);
        }
      });
    },
    [setElements],
  );

  const removeConnect = useCallback(
    (outputID: string) => {
      const target = elements.find((el) => el.outputID === outputID);
      if (target) {
        updateElementData(target.id, 'targetID', '');
      }
    },
    [elements, updateElementData],
  );

  const removeConnects = useCallback(
    (outputs: string[]) => {
      outputs.forEach((i) => {
        // node 裡面的元素要從 mapOutputElement 找
        const target = elements.find((el) => el.outputID === i);
        if (target) {
          updateElementData(target.id, 'targetID', '');
        } else {
          // node 外層從 mapElement 找，因為對於 reactflow 來說，node 跟 handle point 的 edge 結構不一樣
          updateElementData(i, 'targetID', '');
        }
      });
    },
    [elements, updateElementData],
  );

  const connectElement = useCallback(
    (sourceID: string, inputBlockID: string) => {
      // 只有 block 可以作為 target
      // 如果是子元素 sourceID 會是 outputID
      const el = elements.find((el) => el.outputID === sourceID);

      if (el) {
        updateElementData(el.id, 'targetID', inputBlockID);
      } else {
        // 如果找不到代表是最外層的 node，sourceID 會是 elementID
        updateElementData(sourceID, 'targetID', inputBlockID);
      }
    },
    [elements, updateElementData],
  );

  //檢查 elements 是否有更新
  const checkDataUpdated = useCallback(() => {
    return isUpdated;
  }, [isUpdated]);

  const getElement = useCallback(
    (id: string) => {
      const targetItem = elements.find((item) => item.id === id);
      return targetItem;
    },
    [elements],
  );

  const getElementByOutputID = useCallback(
    (outputID: string) => {
      const targetItem = elements.find((item) => item.outputID === outputID);
      return targetItem;
    },
    [elements],
  );

  // 更改子元件的排序
  const sortElement = useCallback(
    (id: string, oldIndex: number, newIndex: number) => {
      // 元件上一層的 id
      const target = getElement(id);
      if (target) {
        const tempArr = [...target.children];
        const tempA = target.children[oldIndex];
        tempArr.splice(oldIndex, 1);
        tempArr.splice(newIndex, 0, tempA);
        updateElementData(id, 'children', tempArr);
      }
    },
    [getElement, updateElementData],
  );

  // 把 API 回傳的資料放進 elements
  const restoreElement = useCallback(
    (data: BaseElement[]) => {
      setElements(data);
      // 存 autosave diff 用的資料
      setCurrentElement(JSON.stringify(data));
    },
    [setElements],
  );

  // elements 改動就重新 render reactflow
  useEffect(() => {
    updateNode();
  }, [updateNode]);

  //elements 更動且有值時設定 isUpdated
  useEffect(() => {
    if (elements.length > 0 && currentElement) {
      const elementJson = JSON.stringify(elements);
      const diff = jsonDiff.diffString(currentElement, elementJson);

      if (diff) {
        setIsUpdated(true);
      }
    }
  }, [currentElement, elements]);

  // 如果頁面網址更動就重置 elements，並且解除 isUpdated
  useEffect(() => {
    setElements([]);
    setCurrentElement('');
  }, [pathname, setElements]);

  const addElements = useCallback(
    (newElements: BaseElement[]) => {
      setElements([...elements, ...newElements]);
    },
    [elements, setElements],
  );

  return (
    <UIProvider>
      <EditorCtx.Provider
        value={{
          ref,
          readonly,
          entryPoints,
          flowEntryPoints,
          nodes,
          edges,
          elements,
          targetElementID,
          onFocusID,
          publishStatus,
          broadcasts,
          defaultFocusID,
          tourMode,
          setTourMode,
          setDefaultFocusID,
          setReadonly,
          setPublishStatus,
          checkDataUpdated,
          handleSetEntryPoint,
          handleSetFlowEntryPoint,
          handleSetBroadcasts,
          setNodes,
          setEdges,
          setIsUpdated,
          setOnFocusID: handleSetFocusID,
          getElement,
          getElementByOutputID,
          connectElement,
          removeElement,
          removeConnect,
          removeConnects,
          sortElement,
          updateNode,
          setTargetElementID,
          isFlowDraggable,
          setIsFlowDraggable,
          isFlowPreventScrolling,
          setIsFlowPreventScrolling,
          isFlowZoomOnScroll,
          setIsFlowZoomOnScroll,
          isFlowZoomOnDoubleClick,
          setIsFlowZoomOnDoubleClick,
          setOnFocusCellID,
          onFocusCellID,
          blockSerialNumber,
          setBlockSerialNumber,
          publishErrors,
          setPublishErrors,
          addElement,
          addElements,
          updateElementData,
          countdownUpdateTimer,
          setCountdownUpdateTimer,
          restoreElement,
          isSaving,
          setIsSaving,
          isDrawerUpdate,
          setIsDrawerUpdate,
          onHoverNode,
          setOnHoverNode,
          setMenuCoordination,
          menuCoordination,
        }}
      >
        <ReactFlowProvider>{children}</ReactFlowProvider>
      </EditorCtx.Provider>
    </UIProvider>
  );
};
