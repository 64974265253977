import { Dialog } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePatchCoupon } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function RenameCouponModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  const [couponName, setCouponName] = useState('');
  const [errorText, setErrorText] = useState('');

  const { data: projectID } = useGetProjectID();
  const { mutate: updateCoupon } = usePatchCoupon(projectID as string, {
    onSuccess: (data, variables) => {
      if (data.code === 40000) {
        setErrorText(t('couponList.modal.rename.errorNameRepeated'));
      } else if (data.code === 20000) {
        uiState.closeModal();
      }
    },
  });

  const validateCouponName = useCallback(
    (name: string) => {
      if (isEmpty(name)) {
        setErrorText(t('couponList.modal.rename.errorEmptyText'));
        return false;
      } else if (name.length > 100) {
        setErrorText(t('couponList.modal.rename.errorTextMaxLength'));
        return false;
      }

      return true;
    },
    [t],
  );

  const handleConfirm = useCallback(() => {
    if (validateCouponName(couponName)) {
      updateCoupon({
        id: uiState.couponID,
        name: couponName,
      });
    }
  }, [couponName, uiState, updateCoupon, validateCouponName]);

  useEffect(() => {
    if (uiState.modalType === ModalTypesEnum.RENAME_COUPON) {
      setCouponName(uiState.couponName);
    }
  }, [uiState.couponName, uiState.modalType]);

  return (
    <Dialog
      size="xs"
      title={t('common.rename')}
      open={uiState.modalType === ModalTypesEnum.RENAME_COUPON}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.ok')}
      closeBtn={false}
      handleClose={uiState.closeModal}
      handleConfirm={handleConfirm}
    >
      <Box>
        <TextField
          fullWidth
          autoFocus
          onChange={(e) => setCouponName(e.target.value)}
          size="small"
          value={couponName}
          label={t('couponList.modal.rename.label')}
          helperText={errorText}
          error={!isEmpty(errorText)}
        />
      </Box>
    </Dialog>
  );
}

export default RenameCouponModal;
