import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS_LOCALES } from '../apis';

async function getGetContactLocale(): Promise<string[]> {
  return axios
    .get(CONTACTS_LOCALES)
    .then((res) => res.data.data)
    .catch(() => []);
}

export function useGetContactLocale() {
  return useQuery<string[]>({
    queryKey: ['use-get-contact-locale'],
    queryFn: () => getGetContactLocale(),
  });
}

export default useGetContactLocale;
