import {
  EmailSignInResponse,
  EmailSignUpProps,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EMAIL_SIGN_IN } from '../apis';

async function emailSignIn(
  props: EmailSignUpProps,
): Promise<ResponseWithData<EmailSignInResponse>> {
  return axios.post(EMAIL_SIGN_IN, props).then((res) => res.data);
}

export function useEmailSignIn() {
  return useMutation({
    mutationKey: ['emailSignUp'],
    mutationFn: (props: EmailSignUpProps) => emailSignIn(props),
    onSuccess: (data) => {
      if (data.data.accessToken) {
        localStorage.setItem('token', data.data.accessToken);
      }
      if (data.data.projectId) {
        sessionStorage.setItem('project-id', data.data.projectId);
      }
    },
  });
}

export default useEmailSignIn;
