import { BaseElement, BlockTypesEnum } from '@frontend/editor/interface';
import { isValueInEnum } from '../isValueInEnum';

export const getUniquePosition = (
  centerX: number,
  centerY: number,
  elements: BaseElement[],
  distance: number,
) => {
  const list = new Map();
  elements.forEach((element, index) => {
    if (!isValueInEnum(element.elementType, BlockTypesEnum)) return;
    if (!element.position) return;

    const distanceX = element.position.x - centerX;
    const distanceY = element.position.y - centerY;
    if (distanceX !== distanceY) return;

    if (distanceX % distance === 0 && distanceY % distance === 0) {
      list.set(distanceX / distance, index);
    }
  });

  let count = 0;

  while (list.has(count)) {
    count++;
  }

  return {
    x: count * distance + centerX,
    y: count * distance + centerY,
  };
};
