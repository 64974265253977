import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ClickAwayListener } from '@mui/material';
import { SxProps, styled } from '@mui/system';
import { FC, useCallback } from 'react';

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  width: '100%',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

/* eslint-disable-next-line */
export interface ExpandPanelProps {
  open: boolean;
  header?: JSX.Element;
  content?: JSX.Element;
  sx?: SxProps;
  autoClose?: boolean;
  onClickAway?: () => void;
  toggleOpen: () => void;
  className?: string;
}

export const ExpandPanel: FC<ExpandPanelProps> = ({
  open,
  header,
  content,
  sx,
  autoClose,
  toggleOpen,
  onClickAway,
  className,
}: ExpandPanelProps) => {
  const handleClickAway = useCallback(() => {
    autoClose && open && toggleOpen();
    open && onClickAway && onClickAway();
  }, [onClickAway, toggleOpen, autoClose, open]);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className={className}
        sx={{ boxShadow: 2, borderRadius: '4px', width: '100%', ...sx }}
      >
        <HeaderWrapper onClick={toggleOpen}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {open && (
              <ExpandLessIcon sx={{ color: 'grey.500' }} onClick={toggleOpen} />
            )}
            {!open && (
              <ExpandMoreIcon sx={{ color: 'grey.500' }} onClick={toggleOpen} />
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>{header}</Box>
        </HeaderWrapper>
        {open && <ContentWrapper>{content}</ContentWrapper>}
      </Box>
    </ClickAwayListener>
  );
};

export default ExpandPanel;
