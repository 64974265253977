import { TextButtonCellTypesEnum } from '@frontend/editor/interface';
import { usePath } from '@frontend/sorghum/utils';
import { Box, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  BroadcastButtonTypeEnum,
  BroadcastCellType,
  BroadcastRedirectButtonType,
  TextButtonCellType,
} from '@frontend/sorghum/interface';
import { PAGE_FLOW_VIEW } from '@frontend/sorghum/utils';
import { Link } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import InputFieldPrefixIcon from '../input-field-prefix-icon/input-field-prefix-icon';

interface EditorViewTextButtonProps {
  iconType: TextButtonCellTypesEnum | BroadcastButtonTypeEnum | string;
  text: string;
  prefixIconColor?: string;
  ctrData: number | undefined;
  cellData: BroadcastCellType | TextButtonCellType;
}

const TextButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  height: '40px',
  borderRadius: '4px',
  padding: '0px 16px 0px 12px',
  width: '100%',
  background: theme.palette['grey']['white'],
  '&:not(:last-child)': {
    marginBottom: '20px',
  },
}));

const SpanStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '100px',
  padding: '2px 10px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette['background']['black'][5],
  whiteSpace: 'nowrap',
}));

const IconWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CellContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'calc(100% - 62px)',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 8px',
}));

const AlignLeftContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'calc(100% - 62px)',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 8px',
}));

//TODO: 改成可復用的 component
export const BroadcastViewTextButton = ({
  iconType,
  text,
  prefixIconColor = 'info.main',
  ctrData,
  cellData,
}: EditorViewTextButtonProps) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { id } = useParams();

  const cellFlowData = get(cellData, 'data', {
    flowName: '',
    flowId: '',
    title: '',
  });

  return (
    <TextButtonContainerStyled>
      <IconWrapperStyled>
        <InputFieldPrefixIcon btnType={iconType} color={prefixIconColor} />
      </IconWrapperStyled>
      {iconType === BroadcastButtonTypeEnum.REDIRECT ? (
        <CellContainerStyled>
          <Typography variant="body2" color="grey.900">
            {text}
          </Typography>

          {isEmpty((cellFlowData as BroadcastRedirectButtonType).flowName) ? (
            <Typography variant="caption" color="grey.400">
              {t('broadcast.deletedFlow')}
            </Typography>
          ) : (
            <Tooltip
              placement="top"
              title={(cellFlowData as BroadcastRedirectButtonType).flowName}
            >
              <Link
                variant="overline"
                color="infoOnTooltips.main"
                onClick={() =>
                  navigateToProjectPage(
                    `${PAGE_FLOW_VIEW}/${
                      (cellFlowData as BroadcastRedirectButtonType).flowId
                    }?overAll=${id}`,
                  )
                }
                sx={{ textTransform: 'none', cursor: 'pointer' }}
              >
                {(cellFlowData as BroadcastRedirectButtonType).flowName}
              </Link>
            </Tooltip>
          )}
        </CellContainerStyled>
      ) : (
        <AlignLeftContainerStyled>
          <Typography variant="body2" color="grey.900">
            {text}
          </Typography>
        </AlignLeftContainerStyled>
      )}

      {ctrData ? (
        <SpanStyled>
          <Typography variant="caption" color="grey.900">
            {ctrData}%
          </Typography>
        </SpanStyled>
      ) : (
        <SpanStyled>
          <Typography variant="caption" color="grey.900">
            0%
          </Typography>
        </SpanStyled>
      )}
    </TextButtonContainerStyled>
  );
};

export default BroadcastViewTextButton;
