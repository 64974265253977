import { FacebookPermission } from '@frontend/sorghum/interface';
import { useEffect, useState } from 'react';

interface UseFacebookProps {
  appId: string;
  mode?: string;
}

interface FacebookPermissionReturn {
  data: FacebookPermission[];
  error: number;
}

const LoginStatus = {
  CONNECTED: 'connected',
  NOT_AUTHORIZED: 'not_authorized',
  UKNOWN: 'unknown',
};

export const useFacebook = ({ appId, mode = 'prod' }: UseFacebookProps) => {
  const [permission, setPermission] = useState<FacebookPermission[]>([]);
  const [token, setToken] = useState<string>('');
  window.fbAsyncInit = () => {
    if (window?.FB?.init) {
      window.FB.init({
        xfbml: true,
        status: true,
        version: 'v14.0',
        appId: appId,
      });
    }
  };

  useEffect(() => {
    if (mode !== 'dev') {
      window.fbAsyncInit();
    }
  }, [mode]);

  const getLoginStatus = () => {
    window.FB.getLoginStatus(function (response) {
      console.log('login status', response);
    });
  };

  const login = (
    scope = '',
    callback?: (response: fb.StatusResponse) => void,
  ) => {
    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        console.log('login connected', response);
        setToken(response.authResponse.accessToken);
        if (callback) callback(response);
      } else {
        window.FB.login(
          function (response) {
            if (callback) callback(response);
          },
          { scope, auth_type: 'rerequest' },
        );
      }
    });
  };

  const checkPermission = () => {
    window.FB.api(
      '/me/permissions',
      (response: { data: FacebookPermission[] }) => {
        setPermission(response.data);
      },
    );
  };

  const requestPermission = (
    callback?: (response: fb.StatusResponse) => void,
  ) => {
    window.FB.api('/me/permissions', (response: FacebookPermissionReturn) => {
      console.log('permission response', response);
      if (response?.data) {
        const permission = response.data
          .filter((item) => item.status === 'declined')
          .map((item) => item.permission)
          .join(',');
        if (permission) {
          window.FB.login(
            function (response) {
              if (callback) callback(response);
            },
            { scope: permission, auth_type: 'rerequest' },
          );
        }
      } else {
        console.log(response);
      }
    });
  };

  const logout = () => {
    window.FB.logout((response) => {
      setToken('');
      console.log('fb logout');
    });
  };

  return {
    token,
    LoginStatus,
    permission,
    requestPermission,
    checkPermission,
    getLoginStatus,
    login,
    logout,
  };
};

export default useFacebook;
