import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS_EXPORT } from '../apis';

interface ConditionType {
  searchFilter?: string;
  key?: string;
  search?: string;
  operator?: number;
  flowId?: string;
  start?: string;
  end?: string;
  entryId?: string;
  entryType?: number;
}
interface PostContactsExportProps {
  filterCriteria: 1 | 2;
  limit: number;
  sortBy?: string;
  orderBy?: string;
  offset?: number;
  condition?: ConditionType[];
  contactsId?: string[];
}

interface ResponseType {
  code: number;
  msg: string;
  data: {
    fileUrl: string;
  };
}

async function postContactsExport({
  sortBy,
  orderBy,
  offset,
  limit,
  filterCriteria,
  condition,
  contactsId,
}: PostContactsExportProps): Promise<ResponseType> {
  return axios
    .post(CONTACTS_EXPORT, {
      limit,
      offset,
      ...(sortBy && { sortBy: sortBy }),
      ...(orderBy && { orderBy: orderBy }),
      ...(condition && condition.length > 0
        ? { condition, filterCriteria }
        : // 沒有條件則帶入 all
          { condition: [], filterCriteria: 1 }),
      ...(contactsId && contactsId.length && { contactsId: contactsId }),
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
}

export function usePostContactsExport() {
  return useMutation({
    mutationKey: ['post-export-people-list'],
    mutationFn: (params: PostContactsExportProps) => postContactsExport(params),
    onSuccess: (data: ResponseType, variables: PostContactsExportProps) => {
      const token = localStorage.getItem('token');
      const projectID = sessionStorage.getItem('project-id') as string;
      if (data) {
        window.open(
          `${data.data.fileUrl}&projectId=${projectID}&token=${token}`,
        );
      }
    },
  });
}

export default usePostContactsExport;
