import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST } from '../apis';

async function postNewBroadcast(projectID: string, name: string) {
  return axios
    .post(`${BROADCAST}?projectId=${projectID}`, { name })
    .then((res) => res.data);
}

export function usePostNewBroadcast(projectID: string) {
  return useMutation({
    mutationKey: ['post-new-broadcast', { projectID }],
    mutationFn: (name: string) => postNewBroadcast(projectID, name),
  });
}
