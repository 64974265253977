import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_PIN } from '../apis';

interface Props {
  projectId: string;
  flowID: string;
  isReplace?: boolean;
}

async function postEditorFlowPin(props: Props): Promise<Response> {
  return axios
    .post(
      `${FLOW_PIN}?projectId=${props.projectId}&flowId=${props.flowID}${
        props.isReplace ? '&isReplace=true' : ''
      }`,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostEditorFlowPin(options?: UseApi<Response, Props>) {
  return useMutation({
    mutationKey: ['post-editor-flow-pin'],
    mutationFn: (props: Props) => postEditorFlowPin(props),
    onSuccess: (data: Response, variables) => {
      queryClient.refetchQueries(['flow-list']);
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}

export default usePostEditorFlowPin;
