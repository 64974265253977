import { Box, LabelDisplayedRowsArgs } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LabelDisplayedRows: React.FC<LabelDisplayedRowsArgs> = ({
  from,
  to,
  count,
  page,
}) => {
  const [t] = useTranslation();
  return (
    <Box component="span">{`${from}–${to} ${t('common.of')} ${
      count !== -1 ? count : `more than ${to}`
    }`}</Box>
  );
};

export default LabelDisplayedRows;
