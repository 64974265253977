import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_CONDITION } from '../apis';

const defaultData = {
  id: '',
  name: '',
  replyTo: 1,
  replyContentType: 1,
  publicReplyContent: '',
  privateReplyContent: {},
  condition: [],
};

async function getCommentReplyCondition(id: string) {
  return axios
    .get(`${COMMENT_REPLY_CONDITION}?id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCommentReplyCondition(id: string) {
  return useQuery({
    queryKey: ['get-comment-reply-condition', { id }],
    queryFn: () => {
      if (id) {
        return getCommentReplyCondition(id);
      } else {
        return defaultData;
      }
    },
  });
}
