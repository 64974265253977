import styled from 'styled-components';
import { Button, ButtonProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

/* eslint-disable-next-line */
export interface GradientButtonProps extends ButtonProps {
  isShining: boolean;
}

const StyledGradientButton = styled(Button)(({ theme }) => ({
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  '&.gradient': {
    background: `linear-gradient(-45deg, ${theme.palette['primary']['main']} 0%,${theme.palette['primary']['light']} 25%,${theme.palette['primary']['main']} 75%,${theme.palette['primary']['light']} 100%)`,
    animation: `gradient 5s ease infinite`,
    backgroundSize: '400% 400%',
    '&:hover': {
      animation: 'none',
      background: `${theme.palette['primary']['main']}`,
    },
  },
}));

export const GradientButton: FC<PropsWithChildren<GradientButtonProps>> = ({
  children,
  isShining,
  ...props
}: PropsWithChildren<GradientButtonProps>) => {
  return (
    <StyledGradientButton {...props} className={isShining ? 'gradient' : ''}>
      {children}
    </StyledGradientButton>
  );
};

export default GradientButton;
