import {
  CategoryValue,
  CategoryValueTypesEnum,
} from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CATEGORY_VALUE } from '../apis';

interface GetCategoryValueProps {
  projectID: string;
  type: CategoryValueTypesEnum | null;
  attributeID?: string;
}

async function getCategoryValue({
  projectID,
  type,
  attributeID,
}: GetCategoryValueProps): Promise<CategoryValue[]> {
  return axios
    .get(CATEGORY_VALUE, {
      params: {
        projectId: projectID,
        type,
        ...(attributeID && { attributeId: attributeID }),
      },
    })
    .then((res) => res.data.data)
    .catch((err) => []);
}

export function useGetCategoryValue({
  projectID,
  type,
  attributeID,
}: GetCategoryValueProps) {
  return useQuery<CategoryValue[]>({
    queryKey: ['get-category-value', { projectID, type, attributeID }],
    queryFn: () => {
      if (projectID && type) {
        return getCategoryValue({ projectID, type, attributeID });
      } else {
        return [];
      }
    },
  });
}

export default useGetCategoryValue;
