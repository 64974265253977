import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_CONDITION } from '../apis';

const defaultData = {
  id: '',
  name: '',
};

interface PropsType {
  id: string;
  name: string;
}

async function postCommentReplyCondition(id: string, name: string) {
  return axios
    .post(COMMENT_REPLY_CONDITION, {
      commentReplyId: id,
      name,
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
      data: defaultData,
    }));
}

export function usePostCommentReplyCondition() {
  return useMutation({
    mutationKey: ['post-comment-reply-condition'],
    mutationFn: (props: PropsType) =>
      postCommentReplyCondition(props.id, props.name),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['get-comment-reply', { uuid: variables.id }],
      });
    },
  });
}
