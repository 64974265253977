import { FlowChart, PublishStatus } from '@frontend/sorghum/interface';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_CHART } from '../apis';

const defaultValue = {
  name: '',
  chart: {
    blockSerialNumber: 0,
    data: [],
  },
  updatedAt: '',
  publishStatus: PublishStatus.UNPUBLISH,
} as FlowChart;

interface getFlowProps {
  flowID: string;
  projectID: string;
  // true 為查詢線上版本，沒有傳預設為 draft 版本
  isPublished?: boolean;
}

async function getFlow({
  flowID,
  projectID,
  isPublished,
}: getFlowProps): Promise<FlowChart> {
  return axios
    .get(FLOW_CHART, {
      params: {
        flowId: flowID,
        projectId: projectID,
        isPublished,
      },
    })
    .then((res) => res?.data?.data || defaultValue)
    .catch(() => {
      return defaultValue;
    });
}

export function useGetFlow(
  flowID: string,
  projectID: string,
  isPublished: boolean,
) {
  return useQuery<FlowChart>({
    queryKey: ['flow', { flowID, projectID, isPublished }],
    queryFn: () => {
      if (flowID && projectID) {
        return getFlow({ flowID, projectID, isPublished });
      } else {
        return defaultValue;
      }
    },
  });
}

// canvas 內不需要頻繁 get data，使用 useMutationGetFlow 來避免畫面閃爍
export function useMutationGetFlow(
  flowID: string,
  projectID: string,
  isPublished: boolean,
) {
  return useMutation<FlowChart>({
    mutationKey: ['mutation-flow', { flowID, projectID, isPublished }],
    mutationFn: () => getFlow({ flowID, projectID, isPublished }),
  });
}

export default useGetFlow;
