import { Box, Typography } from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';
import styled from 'styled-components';
import Switch from '../switch/switch';

export interface SwitchButtonProps {
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning';
  checked: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  onClick?: (e: React.MouseEvent) => void;
}

const SwitchButtonStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 12px',
  borderRadius: '100px',
  background: theme.palette['background']['primary'][5],
  cursor: 'pointer',
}));

export const SwitchButton: FC<PropsWithChildren<SwitchButtonProps>> = ({
  color = 'primary',
  disabled = false,
  checked,
  children,
  onChange,
  onClick,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!disabled) {
        onClick && onClick(e);
      }
    },
    [disabled, onClick],
  );

  return (
    <SwitchButtonStyled onClick={handleClick}>
      <Switch
        onChange={handleChange}
        size="tiny"
        checked={checked}
        color={color}
        disabled={disabled}
      />
      <Typography variant="subtitle2" color="grey.700">
        {children}
      </Typography>
    </SwitchButtonStyled>
  );
};

export default SwitchButton;
