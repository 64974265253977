import { UseMailVerifyReturn } from '@frontend/sorghum/interface';
import { BackTo, FullScreenLoading, Link } from '@frontend/sorghum/ui';
import { BREAK_POINT, useRwd } from '@frontend/sorghum/utils';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import ResetEmail from '../images/reset-email.png';

export interface SorghumFeaturesMailVerifyProps {
  usePage: () => UseMailVerifyReturn;
}

export function SorghumFeaturesMailVerify({
  usePage,
}: SorghumFeaturesMailVerifyProps) {
  const { breakPoint } = useRwd();

  const {
    email,
    title,
    infoPrefix,
    infoSuffix,
    resendInfo,
    isLoading,
    arrowText,
    toCreateNewSuffix,
    resendText,
    onArrowClick,
    resendEmail,
    gotoSignUp,
  } = usePage();
  if (isLoading) {
    return <FullScreenLoading />;
  } else {
    return (
      <>
        <BackTo handleClick={onArrowClick} arrowText={arrowText} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:
              breakPoint >= BREAK_POINT.MD ? 'center' : 'flex-start',
            marginTop: breakPoint >= BREAK_POINT.MD ? 0 : '40px',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <Box
            sx={{
              marginBottom: '12px',
            }}
          >
            <img src={ResetEmail} alt="logo" />
          </Box>
          <Typography
            gutterBottom
            variant="h5"
            color="grey.900"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="grey.900">
            {infoPrefix}
            <Link textTransform="none" variant="body2">
              {email}
            </Link>
            {infoSuffix}
          </Typography>
          <br />
          {email && (
            <Box
              sx={{
                display: 'inline-flex',
              }}
            >
              <Typography variant="body2">
                {resendInfo}
                &nbsp;
              </Typography>
              <Link color="primary" variant="body2" onClick={resendEmail}>
                {resendText}
              </Link>
              &nbsp; &nbsp;
            </Box>
          )}
          {gotoSignUp && (
            <Box
              sx={{
                display: 'inline-flex',
              }}
            >
              <Typography variant="body2">
                <Trans i18nKey="verify.email.expired.youCanAlso" />
                &nbsp;
              </Typography>
              <Link variant="body2" onClick={gotoSignUp}>
                <Trans i18nKey="verify.email.expired.signUp" />
              </Link>
              <Typography variant="body2">
                &nbsp;
                {toCreateNewSuffix}
              </Typography>
            </Box>
          )}
        </Box>
      </>
    );
  }
}

export default SorghumFeaturesMailVerify;
