import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  AutocompleteRenderGroupParams,
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC, useEffect, useState } from 'react';

interface EditorAutocompleteNestedGroupProps
  extends AutocompleteRenderGroupParams {
  inputValue?: string;
}

// FIXME: 第一個 group 不要有 border top
// all 不應該有 tab
const FilterGroupStyled = styled(ListItemButton)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette['grey'][200]}`,
}));

export const EditorAutocompleteNestedGroup: FC<
  EditorAutocompleteNestedGroupProps
> = ({ inputValue, children, group }) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(true);
  }, [inputValue]);

  return (
    <Box>
      {/* 不需要巢狀時需把 group 設成 All */}
      {group === 'All' ? (
        <ListItemText
          primary={children}
          sx={{ paddingLeft: 0, backgroundColor: 'pink' }}
        />
      ) : (
        <>
          <FilterGroupStyled onClick={() => setIsOpen(!isOpen)}>
            {group && <ListItemText primary={group}></ListItemText>}
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </FilterGroupStyled>
          <Collapse in={isOpen} timeout="auto">
            {children}
          </Collapse>
        </>
      )}
    </Box>
  );
};

export default EditorAutocompleteNestedGroup;
