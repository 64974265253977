import { NodeContainerStyledProps } from '@frontend/editor/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus }) => ({
    position: 'relative',
    background: theme.palette['grey'][100],
    borderRadius: '8px',
    border: `2px solid ${
      $focus ? theme.palette['info']['light'] : 'transparent'
    }`,
    padding: theme.spacing(1.5),
  }),
);

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  wordBreak: 'break-word',
  background: theme.palette['grey']['100'],
  paddingLeft: '5px',
}));

export const OptionContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 12px 8px 8px',
  backgroundColor: theme.palette['grey']['200'],
  marginTop: '12px',
  borderRadius: '4px',
}));

export const HintTextContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: `${theme.palette['warning']['main']}0D`,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  borderRadius: '8px',
  marginTop: '12px',
}));

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const AttributeContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette['bluegrey']['50'],
  borderRadius: '4px',
  width: '100%',
  padding: '2px 0 2px 8px',
  marginTop: '8px',
}));

export const OptionButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',

  '.button': {
    marginBottom: '4px',
  },
}));

export const FreeUserInputContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}));

export const FreeUserInputButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 8px)',
  backgroundColor: theme.palette['grey']['white'],
  borderRadius: '100px 100px 0 100px',
  padding: '8px 12px',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const DataContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '100px',
  padding: '2px 10px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette['background']['black'][5],
}));
