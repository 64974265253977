import { TextButtonCellTypesEnum } from '@frontend/editor/interface';
import { BroadcastButtonTypeEnum } from '@frontend/sorghum/interface';

import { globalTheme } from '@frontend/components/external-providers';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { SvgIcon } from '@mui/material';
import { get } from 'lodash';
import { ReactComponent as ConnectorBlockIcon } from '../../images/block-icon-blue.svg';

export const InputFieldPrefixIcon = ({
  btnType,
  color,
}: {
  btnType: TextButtonCellTypesEnum | BroadcastButtonTypeEnum | string;
  color?: string;
}) => {
  switch (btnType) {
    case TextButtonCellTypesEnum.URL:
    case BroadcastButtonTypeEnum.URL:
      return <AddLinkIcon sx={{ color }} />;
    case TextButtonCellTypesEnum.BLOCK:
      return (
        <SvgIcon
          component={ConnectorBlockIcon}
          sx={{
            '& > g > path': {
              stroke: get(globalTheme, `palette.${color}`),
              fill: get(globalTheme, `palette.${color}`),
            },
          }}
        />
      );
    case BroadcastButtonTypeEnum.REDIRECT:
      return <AccountTreeOutlinedIcon sx={{ color }} />;
    case TextButtonCellTypesEnum.PHONE:
    case BroadcastButtonTypeEnum.CALL:
      return <LocalPhoneOutlinedIcon sx={{ color }} />;
    case TextButtonCellTypesEnum.FLOW:
      return <AccountTreeOutlinedIcon sx={{ color }} />;
    default:
      return <SvgIcon component={ConnectorBlockIcon} sx={{ color }} />;
  }
};

export default InputFieldPrefixIcon;
