import { GetCouponResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON } from '../apis';

const defaultData = {
  name: '',
  status: 1,
  setting: [],
};

async function getCoupon(
  projectID: string,
  couponID: string,
): Promise<GetCouponResponseType> {
  return axios
    .get(`${COUPON}?projectId=${projectID}&couponId=${couponID}`)
    .then((res) => {
      // 如果 coupon 已經被刪除，API 會回傳 40000，前端會多回 isDeleted:true
      if (res.data.code === 40000) {
        return { ...res.data.data, isDeleted: true };
      }

      return res.data.data;
    })
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCoupon(projectID: string, couponID: string) {
  return useQuery<GetCouponResponseType>({
    queryKey: ['get-coupon', { projectID, couponID }],
    queryFn: () => {
      if (projectID && couponID) {
        return getCoupon(projectID, couponID);
      } else {
        return defaultData;
      }
    },
  });
}
