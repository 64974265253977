import {
  TextField as MuiTextField,
  StandardTextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface TextFieldProps extends MuiTextFieldProps {
  type?: string;
}

export const TextField: FC<TextFieldProps> = ({
  type = 'default',
  ...props
}: TextFieldProps) => {
  return <MuiTextField {...props} />;
};

export default TextField;
