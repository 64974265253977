import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { GetFlowListResponse } from '@frontend/sorghum/interface';
import { FLOW } from '../apis';

interface RequestProps {
  projectId: string;
}

async function getFlowListWithPublishStatus({
  projectId,
}: RequestProps): Promise<GetFlowListResponse> {
  return axios
    .get(`${FLOW}`, {
      params: {
        projectId,
        limit: '100',
        offset: 0,
      },
    })
    .then((res) => {
      return res.data.data;
    });
}

export function useGetFlowListWithPublishStatus({
  projectId,
}: {
  projectId: string;
}) {
  return useQuery({
    queryKey: ['get-flow-list-with-publish-status', { projectId }],
    queryFn: () => {
      if (projectId) {
        return getFlowListWithPublishStatus({
          projectId,
        });
      } else {
        return {
          limit: '20',
          offset: 0,
          flows: [],
        };
      }
    },
  });
}

export default useGetFlowListWithPublishStatus;
