import {
  Box,
  InputAdornment,
  TextField,
  TypographyVariantsOptions,
} from '@mui/material';
import { styled } from '@mui/system';

interface InputFieldStyledType {
  $focus: boolean;
  $error: boolean;
}

export const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  gap: '10px',
}));

export const InfoStyled = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  padding: '2px 8px',
  background: theme.palette?.['bluegrey'][50],
  color: theme.palette?.['bluegrey'][400],
  marginBottom: theme.spacing(1),
}));

export const EditorTooltipWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2.7),
  bottom: theme.spacing(-1.5),
}));

export const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
  color: theme.palette['secondary']['dark'],
}));

export const ImageUploadWrapperStyled = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  img: {
    borderRadius: '8px',
  },
}));

export const InputFieldStyled = styled(TextField)<InputFieldStyledType>(
  ({ theme, $focus, $error }) => ({
    backgroundColor: '#fff',
    borderRadius: '4px',
    width: '100%',
    '& input': {
      padding: '8px 12px',
      textAlign: 'center',
      ...(theme.typography as TypographyVariantsOptions).body2,
    },
    cursor: 'initial',
    border: 0,
    ...($error && { border: `1px solid ${theme.palette['error']['main']}` }),
    ...($focus && { border: `1px solid ${theme.palette['info']['main']}` }),
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    marginTop: theme.spacing(1),
  }),
);

export const ElementContainerStyled = styled(Box)(({ theme }) => ({
  background: theme.palette['grey'][100],
  borderRadius: '12px',
  padding: theme.spacing(1.5),
}));
