import { queryClient } from '@frontend/sorghum/external-providers';
import { Response, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_ENTRY } from '../apis';

interface Props {
  entryID: string;
}

async function deleteEditorFlowEntry(
  projectID: string,
  flowID: string,
  entryID: string,
): Promise<Response> {
  return axios
    .delete(
      `${FLOW_ENTRY}?projectId=${projectID}&flowId=${flowID}&entryId=${entryID}`,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteEditorFlowEntry(
  flowID: string,
  socialType: number,
  projectID: string,
  option?: UseApi<Response, Props>,
) {
  return useMutation({
    mutationKey: ['Delete-editor-flow-entry'],
    mutationFn: (props: Props) =>
      deleteEditorFlowEntry(projectID, flowID, props.entryID),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['get-editor-flow-entry']);

      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
  });
}

export default useDeleteEditorFlowEntry;
