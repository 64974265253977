import { Radio as MuiRadio, RadioProps as MuiRadioProps } from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface RadioProps extends MuiRadioProps {}

export const Radio: FC<RadioProps> = ({ ...props }: MuiRadioProps) => {
  return <MuiRadio {...props} />;
};

export default Radio;
