import {
  QueryClient,
  QueryClientProviderProps,
  QueryClientProvider as QueryProvider,
} from '@tanstack/react-query';
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      useErrorBoundary: true,
      retry: false,
      staleTime: 60000,
    },
  },
});

export const QueryClientProvider = (props: QueryClientProviderProps) => {
  return <QueryProvider {...props}>{props.children}</QueryProvider>;
};

QueryClientProvider.defaultProps = {
  client: queryClient,
};
