import { globalTheme } from '@frontend/components/external-providers';
import { SubData } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardOverviewData } from '@frontend/sorghum/data-access';
import {
  DashboardOverviewData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { get } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DataDashboardOverviewSubDataProps extends PostDataDashboardProps {
  gotoTab: (tab: number) => void;
}

export const DataDashboardOverviewSubData: FC<
  DataDashboardOverviewSubDataProps
> = ({
  startDate,
  endDate,
  condition,
  // 原先點擊後會切換 tab 的功能先拿掉，之後開放另外兩個 tab 時才會加回來
  gotoTab,
}: DataDashboardOverviewSubDataProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const [data, setData] = useState<DashboardOverviewData>({
    uniqNewUsers: 99999,
    lastUniqNewUsers: 99999,
    uniqNewUsersTrend: 0.8,
    uniqRnSubscribers: 9999,
    lastUniqRnSubscribers: 99999,
    uniqRnSubscribersTrend: -0.8,
    uniqActiveUsers: 999,
    lastUniqActiveUsers: 99999,
    uniqActiveUsersTrend: 0,
  });

  const { mutate: queryData } = usePostDataDashboardOverviewData(
    projectID as string,
  );

  useEffect(() => {
    if (startDate && endDate && projectID) {
      queryData(
        {
          startDate,
          endDate,
          condition,
        },
        {
          onSuccess: (_data: DashboardOverviewData) => {
            setData(_data);
          },
        },
      );
    }
  }, [condition, endDate, projectID, queryData, startDate, t]);

  return (
    <>
      <SubData
        title={t('dataDashboard.overview.subData.newUsers.title')}
        titleTooltip={t('dataDashboard.overview.subData.newUsers.info')}
        mainData={data.uniqNewUsers}
        color={get(globalTheme, 'palette.blue.200')}
        trendingPercentage={data.uniqNewUsersTrend}
        lastPeriod={data.lastUniqNewUsers}
        // onClick={() => gotoTab(1)}
      />
      <SubData
        title={t('dataDashboard.overview.subData.rnSubscribers.title')}
        titleTooltip={t('dataDashboard.overview.subData.rnSubscribers.info')}
        mainData={data.uniqRnSubscribers}
        color={get(globalTheme, 'palette.green.200')}
        trendingPercentage={data.uniqRnSubscribersTrend}
        lastPeriod={data.lastUniqRnSubscribers}
      />
      <SubData
        title={t('dataDashboard.overview.subData.avg.title')}
        titleTooltip={t('dataDashboard.overview.subData.avg.info')}
        mainData={data.uniqActiveUsers}
        color={get(globalTheme, 'palette.orange.200')}
        trendingPercentage={data.uniqActiveUsersTrend}
        lastPeriod={data.lastUniqActiveUsers}
        // onClick={() => gotoTab(2)}
      />
    </>
  );
};

export default DataDashboardOverviewSubData;
