import { globalTheme } from '@frontend/components/external-providers';
import { AutocompleteOptionType } from '@frontend/editor/interface';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Autocomplete as MuiAutocomplete,
  Paper,
  PaperProps,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';

export interface EditorSelectProps {
  value: string | number;
  error?: string;
  noOptionsText?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  options: AutocompleteOptionType[];
  customMessage?: JSX.Element;
  onChange?: (newValue: AutocompleteOptionType | null) => void;
  onFocus?: () => void;
}

// 排除某些選項出現在搜尋結果
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: AutocompleteOptionType) =>
    option.exclude ? '' : option.label,
});

// 客製化 autocomplete 的下拉選單樣式
const CustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  "button[aria-label='Clear']": {
    display: 'none',
    pointerEvents: 'none',
  },
  input: {
    cursor: 'pointer',
    caretColor: 'transparent',
    userSelect: 'none',
    '&::selection': {
      background: 'transparent',
    },
  },
}));

export const EditorSelect: FC<EditorSelectProps> = ({
  value,
  error,
  label,
  disabled,
  noOptionsText,
  options,
  placeholder,
  customMessage,
  onChange,
}: EditorSelectProps) => {
  const defaultValue = options.find((i) => i.value === value);
  const [selectValue, setSelectValue] = useState<AutocompleteOptionType | null>(
    defaultValue || null,
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: AutocompleteOptionType | null) => {
      if (newValue && newValue.value) {
        setSelectValue(newValue);
        onChange && onChange(newValue);
      } else {
        setSelectValue(null);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (defaultValue) {
      setSelectValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box>
      <MuiAutocomplete
        filterOptions={filterOptions}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(
          option: AutocompleteOptionType,
          value: AutocompleteOptionType,
        ) => option.value === value.value}
        value={selectValue}
        disabled={disabled}
        popupIcon={<KeyboardArrowDownIcon />}
        blurOnSelect
        size="small"
        options={options}
        getOptionLabel={(option: AutocompleteOptionType) => option.label}
        groupBy={(option) => option.group || ''}
        PaperComponent={CustomPaper}
        renderGroup={(params) => (
          <div
            key={`autocomplete_${params.key}_${params.group}}`}
            style={{
              ...(params.key.toString() !== '0' && {
                borderTop: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
              }),
            }}
          >
            {params.group && (
              <div
                style={{
                  padding: '8px 0',
                }}
              >
                <Typography ml={2} variant="caption" color="bluegrey.400">
                  {params.group}
                </Typography>
              </div>
            )}
            {params.children}
          </div>
        )}
        renderOption={(props, option: AutocompleteOptionType) => (
          <li
            key={`autocomplete_${option.value}_${props.tabIndex}}`}
            {...props}
            style={{
              ...(option.divider && {
                borderBottom: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
              }),
            }}
            {...(option.onClick && {
              onClick: option.onClick,
            })}
          >
            <Typography variant="menuItem" color="grey.500">
              {option.label}
            </Typography>
          </li>
        )}
        onChange={handleChange}
        renderInput={(params) => (
          <TextFieldStyled
            {...params}
            ref={params.InputProps.ref}
            variant="outlined"
            size="small"
            label={label}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps,
              disabled,
              onKeyDown: (e) => e.preventDefault(),
              style: {
                userSelect: 'none',
              },
            }}
            fullWidth
            margin="normal"
          />
        )}
      />
      {error && (
        <Box>
          <Typography variant="body2" color="error.main">
            {error}
          </Typography>
        </Box>
      )}
      {customMessage && customMessage}
    </Box>
  );
};

export default EditorSelect;
