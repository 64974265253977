import { GetSequenceDataType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE_DATA } from '../apis';

async function getSequenceData(
  projectID: string,
  sequenceID: string,
): Promise<GetSequenceDataType[]> {
  return axios
    .get(`${SEQUENCE_DATA}?projectId=${projectID}&sequenceId=${sequenceID}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return {};
    });
}

export function useGetSequenceData(projectID: string, sequenceID: string) {
  return useQuery<GetSequenceDataType[]>({
    queryKey: ['get-sequence-data', { projectID, sequenceID }],
    queryFn: () => {
      if (projectID && sequenceID) {
        return getSequenceData(projectID, sequenceID);
      } else {
        return [];
      }
    },
  });
}
