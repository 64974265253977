import { ProjectListItem, ResponseWithData } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_LIST } from '../apis';

async function getProjectList(): Promise<ResponseWithData<ProjectListItem[]>> {
  return axios
    .get(PROJECT_LIST)
    .then((res) => res.data)
    .catch(() => [
      {
        projectId: '',
        projectName: '',
        isLinked: false,
        shortCode: '',
      },
    ]);
}

export function useProjectList() {
  return useQuery<ResponseWithData<ProjectListItem[]>>({
    queryKey: ['get-project-list'],
    queryFn: () => getProjectList(),
  });
}

export default useProjectList;
