import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import { Position } from 'reactflow';
import HandlePoint from '../handle-point/handle-point';

interface ButtonProps {
  outputID?: string;
  borderRadius?: boolean;
  children?: React.ReactNode;
  setIsHoverBlock?: Dispatch<SetStateAction<boolean>>;
}

const ButtonStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '2.352px',
  border: `0.588px solid ${theme.palette?.['grey']['300'] ?? '#E0E0E0'}`,
  background: theme.palette?.['grey']['100'] ?? '#F5F5F5',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  height: '40px',
  width: '100%',
  padding: '0 12px',
}));

export function EditorButton({
  outputID,
  children,
  setIsHoverBlock,
}: ButtonProps) {
  return (
    <ButtonStyled>
      {outputID && (
        <HandlePoint
          id={outputID}
          type="source"
          position={Position.Right}
          isConnected={false}
          styles={{
            top: '50%',
          }}
          setIsHoverBlock={setIsHoverBlock}
        />
      )}
      <Typography variant="body2" color="grey.500">
        {children}
      </Typography>
    </ButtonStyled>
  );
}

export default EditorButton;
