import {
  Autocomplete as MuiAutocomplete,
  TextField,
  Box,
  createFilterOptions,
  Paper,
  PaperProps,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Typography } from '../typography/typography';
import { useFormContext } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { globalTheme } from '@frontend/components/external-providers';

export interface OptionType {
  label: string;
  value: string | undefined;
  group?: string | null;
  divider?: boolean;
  onClick?: () => void;
  // if true 不會出現在搜尋結果
  exclude?: boolean;
}

export interface AutocompleteProps {
  disabled?: boolean;
  noOptionsText?: string;
  label?: string;
  placeholder?: string;
  options: OptionType[];
  formName: string;
  customMessage?: JSX.Element;
  onChange?: (event: object, newValue: OptionType | null) => void;
  onFocus?: () => void;
}

// 排除某些選項出現在搜尋結果
const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: OptionType) => (option.exclude ? '' : option.label),
});

// 客製化 autocomplete 的下拉選單樣式
const CustomPaper = (props: PaperProps) => {
  return <Paper elevation={8} {...props} />;
};

export const Autocomplete: FC<AutocompleteProps> = ({
  label,
  disabled,
  noOptionsText,
  options,
  placeholder,
  formName,
  customMessage,
  onChange,
  onFocus,
}: AutocompleteProps) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const formValue = watch(formName);
  const defaultValue = options.find((i) => i.value === formValue);
  const [inputValue, setInputValue] = useState(defaultValue?.label || '');
  const [selectValue, setSelectValue] = useState<OptionType | null>(
    defaultValue || null,
  );

  const errorMessage = errors ? errors[formName]?.message?.toString() : '';

  const onOptionChange = useCallback(
    (event: object, newValue: OptionType | null) => {
      if (newValue && newValue.value) {
        setSelectValue(newValue);
        onChange && onChange(event, newValue);
        setValue(formName, newValue?.value);
      } else {
        setSelectValue(null);
        setValue(formName, null);
      }
    },
    [onChange, setValue, formName],
  );

  useEffect(() => {
    if (defaultValue) {
      setSelectValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box>
      <MuiAutocomplete
        filterOptions={filterOptions}
        noOptionsText={noOptionsText}
        isOptionEqualToValue={(option: OptionType, value: OptionType) =>
          option.value === value.value
        }
        value={selectValue}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disabled={disabled}
        popupIcon={<KeyboardArrowDownIcon />}
        blurOnSelect
        size="small"
        options={options}
        getOptionLabel={(option: OptionType) => option.label}
        groupBy={(option) => option.group || ''}
        PaperComponent={CustomPaper}
        renderGroup={(params) => (
          <div
            key={`autocomplete_${params.key}_${params.group}}`}
            style={{
              ...(params.key.toString() !== '0' && {
                borderTop: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
              }),
            }}
          >
            {params.group && (
              <div
                style={{
                  padding: '8px 0',
                }}
              >
                <Typography ml={2} variant="caption" color="bluegrey.400">
                  {params.group}
                </Typography>
              </div>
            )}
            {params.children}
          </div>
        )}
        renderOption={(props, option: OptionType) => (
          <li
            key={`autocomplete_${option.value}_${props.tabIndex}}`}
            {...props}
            style={{
              ...(option.divider && {
                borderBottom: `4px solid ${globalTheme.palette?.bluegrey?.[50]}`,
              }),
            }}
            {...(option.onClick && {
              onClick: option.onClick,
            })}
          >
            <Typography variant="menuItem" color="grey.500">
              {option.label}
            </Typography>
          </li>
        )}
        onChange={onOptionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={onFocus}
            variant="outlined"
            size="small"
            label={label}
            disabled={disabled}
            placeholder={placeholder}
            fullWidth
            margin="normal"
          />
        )}
      />
      {errorMessage && (
        <Box>
          <Typography variant="body2" color="error.main">
            {errorMessage}
          </Typography>
        </Box>
      )}
      {customMessage && customMessage}
    </Box>
  );
};

export default Autocomplete;
