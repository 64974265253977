import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BROADCAST_SEND } from '../apis';

import { queryClient } from '@frontend/sorghum/external-providers';
import { PostBroadcastDataType } from '@frontend/sorghum/interface';

interface ParamsType {
  projectID: string;
  data: PostBroadcastDataType;
}

interface ResponseType {
  code: number;
  msg: string;
}

interface callbackFuncType {
  onSuccess: (data: ResponseType, variables: ParamsType) => void;
}

async function postBroadcastSend(params: ParamsType) {
  return axios
    .post(`${BROADCAST_SEND}?projectId=${params.projectID}`, params.data)
    .then((res) => res.data);
}

export function usePostBroadcastSend(callbackFunc: callbackFuncType) {
  return useMutation({
    mutationKey: ['post-broadcast-send'],
    mutationFn: (params: ParamsType) => postBroadcastSend(params),
    onSuccess: (data: ResponseType, variables: ParamsType) => {
      callbackFunc.onSuccess(data, variables);
      queryClient.refetchQueries([
        'get-broadcast-list',
        { projectID: variables.projectID },
      ]);
    },
  });
}
