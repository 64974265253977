import { useEffect, useState } from 'react';

export const useTimer = (
  second: number,
  trigger: boolean,
  callback: () => void,
) => {
  const [count, setCount] = useState<number>(-1);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (count >= 1) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [count]);

  useEffect(() => {
    if (count === 0) {
      callback();
      setCount(-1);
    }
  }, [count, callback]);

  useEffect(() => {
    // 啟動儲存倒數
    if (trigger) {
      setCount(second);
    }
  }, [trigger, second]);
  return {
    count,
  };
};

export default useTimer;
