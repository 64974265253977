import { Dialog } from '@frontend/components/ui';
import {
  useDeleteCommentAutoReply,
  useGetCommentReply,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { PAGE_COMMENT_AUTO_REPLY_LIST, usePath } from '@frontend/sorghum/utils';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export function DeleteCommentAutoReplyModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const id = uiState.dataID;
  const { navigateToProjectPage } = usePath();
  const { data } = useGetCommentReply(id as string);
  const { mutate: deleteCommentAutoReply } = useDeleteCommentAutoReply();

  const handleConfirm = useCallback(() => {
    if (id) {
      deleteCommentAutoReply(id, {
        onSuccess: (res) => {
          if (res.code === 20000) {
            uiState.closeModal();
            if (uiState.needRedirect) {
              navigateToProjectPage(PAGE_COMMENT_AUTO_REPLY_LIST);
            }
          }
        },
      });
    }
  }, [deleteCommentAutoReply, id, navigateToProjectPage, uiState]);

  return (
    <Dialog
      size="xs"
      title={t('commentAutoReply.modal.delete.title', {
        title: data?.name,
      })}
      open={uiState.modalType === ModalTypesEnum.DELETE_COMMENT_AUTO_REPLY}
      content={t('commentAutoReply.modal.delete.description')}
      cancelBtnText={t('broadcast.deleteBroadcastDialog.cancel')}
      confirmBtnText={t('broadcast.deleteBroadcastDialog.confirm')}
      isNegative
      handleClose={uiState.closeModal}
      handleConfirm={handleConfirm}
      color="error"
    />
  );
}

export default DeleteCommentAutoReplyModal;
