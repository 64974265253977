import {
  NotificationContext,
  NotificationTypeEnum,
} from '@frontend/sorghum/interface';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const NotificationCtx = createContext<NotificationContext>(
  {} as NotificationContext,
);

// 提供提示 NoticeBar 內容的 context
export const NotificationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  // 累積了至少 1 個 rn 用戶，但未曾發過推播
  const [NEW_RN_RECEIVER, setNEW_RN_RECEIVER] = useState<boolean>(false);
  // 專案已成功發過至少 1 則推播以及未曾發布過 Flow
  const [FLOW_UNPUBLISHED, setFLOW_UNPUBLISHED] = useState<boolean>(false);
  // 沒有綁定粉專
  const [FACEBOOK_PAGE_DISCONNECT, setFACEBOOK_PAGE_DISCONNECT] =
    useState<boolean>(false);
  // Trial Plan 即將到期
  const [TRIAL_EXPIRATION, setTRIAL_EXPIRATION] =
    useState<boolean>(false);

  const updateNoticeBarType = useCallback(
    (val: NotificationTypeEnum, status: boolean) => {
      switch (val) {
        case NotificationTypeEnum.TRIAL_EXPIRATION: {
          setTRIAL_EXPIRATION(!!status);
          break;
        }
        case NotificationTypeEnum.FACEBOOK_PAGE_DISCONNECT: {
          setFACEBOOK_PAGE_DISCONNECT(!!status);
          break;
        }
        case NotificationTypeEnum.NEW_RN_RECEIVER: {
          setNEW_RN_RECEIVER(!!status);

          if (status) {
            setFLOW_UNPUBLISHED(false);
          }
          break;
        }
        case NotificationTypeEnum.FLOW_UNPUBLISHED: {
          setFLOW_UNPUBLISHED(!!status);

          if (status) {
            setNEW_RN_RECEIVER(false);
          }
          break;
        }
      }
    },
    [],
  );

  const { noticeBarType, noticeBarTypes } = useMemo(() => {
    const newList = [];
    if (TRIAL_EXPIRATION) {
      newList.push(NotificationTypeEnum.TRIAL_EXPIRATION);
    }
    if (FACEBOOK_PAGE_DISCONNECT) {
      newList.push(NotificationTypeEnum.FACEBOOK_PAGE_DISCONNECT);
    }
    if (NEW_RN_RECEIVER) {
      newList.push(NotificationTypeEnum.NEW_RN_RECEIVER);
    }
    if (FLOW_UNPUBLISHED) {
      newList.push(NotificationTypeEnum.FLOW_UNPUBLISHED);
    }

    return {
      noticeBarTypes: newList,
      noticeBarType: newList.length > 0 ? newList[0] : null,
    };
  }, [TRIAL_EXPIRATION, FACEBOOK_PAGE_DISCONNECT, FLOW_UNPUBLISHED, NEW_RN_RECEIVER]);

  return (
    <NotificationCtx.Provider
      value={{
        noticeBarType,
        noticeBarTypes,
        addNoticeBarType: (val: NotificationTypeEnum) =>
          updateNoticeBarType(val, true),
        removeNoticeBarType: (val: NotificationTypeEnum) =>
          updateNoticeBarType(val, false),
      }}
    >
      {children}
    </NotificationCtx.Provider>
  );
};
