export enum GOOGLE_API_SCOPE_ENUM {
  DRIVE = 'https://www.googleapis.com/auth/drive',
  DRIVE_READONLY = 'https://www.googleapis.com/auth/drive.readonly',
  DRIVE_METADATA = 'https://www.googleapis.com/auth/drive.metadata',
  USERINFO_EMAIL = 'https://www.googleapis.com/auth/userinfo.email',
  SPREADSHEETS_READONLY = 'https://www.googleapis.com/auth/spreadsheets.readonly',
  DRIVE_METADATA_READONLY = 'https://www.googleapis.com/auth/drive.metadata.readonly',
  DRIVE_FILE = 'https://www.googleapis.com/auth/drive.file',
  DRIVE_PHOTOS_READONLY = 'https://www.googleapis.com/auth/drive.photos.readonly',
  DRIVE_SCRIPTS = 'https://www.googleapis.com/auth/drive.scripts',
  SPREADSHEETS = 'https://www.googleapis.com/auth/spreadsheets',
  DRIVE_APPDATA = 'https://www.googleapis.com/auth/drive.appdata',
  USERINFO_PROFILE = 'https://www.googleapis.com/auth/userinfo.profile',
  OPENID = 'openid',
}

export interface GoogleAuthContext {
  refreshed: boolean;
  openAuthWindow: (scopes: GOOGLE_API_SCOPE_ENUM[]) => void;
  googleSheetAuth: () => void;
}
