import {
  convertNumberToK,
  convertNumberToPercentage,
} from '@frontend/editor/utils';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { Tooltip } from '../tooltip/tooltip';
import { Typography } from '../typography/typography';

interface DataItem {
  title: string;
  titleTooltip?: string;
  count?: number;
  percentage?: number;
  link?: string;
  handleRedirect?: () => void;
}

interface BroadcastViewDataBlockProps {
  main: DataItem;
  tooltipItems?: DataItem[];
}

const TooltipWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
}));

const BlockStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: `1px solid ${theme.palette['grey'][100]}`,
  borderRadius: 8,
  padding: 8,
  background: 'white',
  gap: 4,
  width: 'auto',
  height: '76px',

  '&: hover': {
    background: theme.palette['background']['primary']['5'],
  },
}));

export function BroadcastViewDataBlock({
  main,
  tooltipItems = [],
}: BroadcastViewDataBlockProps) {
  const title = main.title;
  const countData = convertNumberToK(main.count);
  const percentageData = convertNumberToPercentage(main.percentage);

  return (
    <Tooltip
      placement="bottom-end"
      title={
        <TooltipWrapper>
          {main.titleTooltip && (
            <Typography variant="note">{main.titleTooltip}</Typography>
          )}
          {tooltipItems.map((i) => {
            const tooltipPercentage = i.percentage
              ? `(${convertNumberToPercentage(i.percentage)})`
              : '';
            const tooltipContent = `${i.title} ${
              i.count?.toLocaleString() ?? 0
            } ${tooltipPercentage}`;

            if (i.handleRedirect) {
              return (
                <Typography
                  key={`${i.title}`}
                  sx={{
                    textDecoration: 'underline',
                  }}
                  onClick={i.handleRedirect}
                  variant="notoSans"
                  color="blue.200"
                >
                  {tooltipContent}
                </Typography>
              );
            } else {
              return (
                <Typography
                  key={`${i.title}`}
                  variant="notoSans"
                  color="grey.white"
                >
                  {tooltipContent}
                </Typography>
              );
            }
          })}
        </TooltipWrapper>
      }
    >
      <BlockStyled>
        <Typography variant="note" color="bluegrey.500">
          {title}
        </Typography>
        <Typography variant="body1" color="bluegrey.900">
          {countData}
        </Typography>
        <Typography variant="caption" color="bluegrey.500">
          {percentageData}
        </Typography>
      </BlockStyled>
    </Tooltip>
  );
}

export default BroadcastViewDataBlock;
