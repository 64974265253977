import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  ActionCellBaseType,
  ActionType,
  DataFlowChartTrigger,
} from '@frontend/editor/interface';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import EditorViewDataBlock from '../editor-view-data-block/editor-view-data-block';
import { HandlePoint } from '../handle-point/handle-point';
import { ActionCellItem } from '../node-action/node-action';
import { BodyStyled } from '../node-action/styles';
import { NodeHeader } from '../node-header/node-header';
import { ContainerStyled } from './styles';

const BlockDataWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '12px 0 0 0',
}));

const ContentItemStyled = styled(Box)(({ theme }) => ({
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  marginTop: theme.spacing(1.5),
  padding: '12px',
}));

export const ViewNodeAction = ({ id }: NodeProps) => {
  const { getTargetElement } = useCanvasGet();
  const { id: flowID } = useParams();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const ViewNodeActionData = getTargetElement(id) as ActionType;

  const {
    label: header,
    inputID,
    outputID,
    targetID,
  } = ViewNodeActionData as ActionType;

  const { uniqTrigger, trigger } = useMemo(() => {
    const blockData: DataFlowChartTrigger = get(viewData, `blocks.${id}`, {});

    return {
      uniqTrigger: blockData?.uniqTrigger,
      trigger: blockData?.trigger,
    };
  }, [viewData, id]);

  return (
    <Box>
      {inputID && (
        <HandlePoint
          id={inputID}
          type="target"
          position={Position.Left}
          styles={{
            top: '5%',
          }}
        />
      )}
      {outputID && targetID && (
        <HandlePoint
          id={outputID}
          type="source"
          position={Position.Right}
          styles={{
            top: 'calc(100% - 30px)',
          }}
          isConnected={false}
        />
      )}
      <ContainerStyled>
        <NodeHeader
          color={globalTheme.palette?.['purple'][600] as string}
          background={globalTheme.palette?.['purple'][50]}
          icon={<BookmarksIcon fontSize="small" />}
          title={header}
          readonly
        />

        <BodyStyled>
          <BlockDataWrapperStyled>
            <EditorViewDataBlock
              title={t('view.action.trigger')}
              tooltipTitle={t('view.block.triggerTooltip')}
              count={trigger}
              uniqCount={uniqTrigger}
            />
          </BlockDataWrapperStyled>

          {ViewNodeActionData.children &&
            ViewNodeActionData.children.map((i: string, index: number) => {
              const cellData = getTargetElement(i) as ActionCellBaseType;
              if (cellData) {
                return (
                  <ContentItemStyled key={index}>
                    <ActionCellItem
                      key={`ViewNodeActionData_${i}`}
                      actionData={cellData}
                    />
                  </ContentItemStyled>
                );
              } else {
                return null;
              }
            })}
        </BodyStyled>
      </ContainerStyled>
    </Box>
  );
};

export default memo(ViewNodeAction);
