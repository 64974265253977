import { TourTooltipProps } from '@frontend/components/interface';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import Button from '../button/button';
import Typography from '../typography/typography';

// 間隔目標的距離
const DISTANCE = 30;
const TRIANGLE_HEIGHT = 24;
const TRIANGLE_BASE = 27;
const TRIANGLE_PADDING = 40;

enum TriangleArrow {
  UP = 0,
  RIGHT = 90,
  DOWN = 180,
  LEFT = 270,
}

const TooltipContainerStyled = styled(Box)<{
  $imageSize: 'large' | 'small';
  $top: number;
  $left: number;
}>(({ $imageSize, $top, $left }) => ({
  opacity: $left === 0 && $top === 0 ? 0 : 1,
  zIndex: 9999,
  position: 'fixed',
  display: 'inline-flex',
  gap: '20px',
  padding: $imageSize === 'large' ? '24px' : '16px',
  background: '#0056B3',
  width: $imageSize === 'large' ? '900px' : '280px',
  borderRadius: '4px',
  top: `${$top}px`,
  left: `${$left}px`,
  boxShadow:
    '-1px 0px 1px 0px rgba(255, 255, 255, 0.05), 0px -1px 1px 0px rgba(255, 255, 255, 0.05), 0px 1px 1px 0px rgba(255, 255, 255, 0.10), 1px 0px 1px 0px rgba(255, 255, 255, 0.10)',
}));

const TriangleWrapperStyled = styled(Box)<{
  $top: number;
  $left: number;
  $position: TriangleArrow;
}>(({ $top, $left, $position }) => ({
  position: 'absolute',
  left: `${$left}px`,
  top: `${$top}px`,
  borderLeft: `${TRIANGLE_BASE / 2}px solid transparent`,
  borderRight: `${TRIANGLE_BASE / 2}px solid transparent`,
  borderBottom: `${TRIANGLE_HEIGHT}px solid #0056B3`,
  transform: `rotate(${$position}deg)`,
}));

const RightWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '280px',
  borderRadius: '4px',
  alignItems: 'space-between',
}));

const TextWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  flexGrow: 1,
}));

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'start',
  justifyContent: 'space-between',
  width: '100%',
  color: theme.palette['grey']['white'],

  '& > svg': {
    fontSize: '21px',
  },
}));
const SmallImageContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '192px',
  borderRadius: '4px',
}));

const LargeImageContainerStyled = styled(Box)(({ theme }) => ({
  width: '600px',
  borderRadius: '4px',
}));

const BottomWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'end',
  alignItems: 'center',
}));

const PaginationWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '8px',
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'end',
  alignItems: 'center',
  flexGrow: 1,
}));

export const TourTooltip: FC<TourTooltipProps> = ({
  target,
  title,
  description,
  pagination,
  image,
  imageType = 'image',
  imageSize: size = 'small',
  nextButtonText,
  prevButtonText,
  placement = 'top',
  onClose,
  nextButtonClick,
  prevButtonClick,
}) => {
  const tooltipRef = useRef<HTMLElement>(null);
  const tooltipCurrent = tooltipRef.current;
  const targetElement =
    typeof target === 'string'
      ? document.getElementById(target)
      : target.current;

  const imageSize = size === 'large' && image ? 'large' : 'small';
  const [animation, setAnimation] = useState<JSON | null>();
  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);
  const [tooltipRect, setTooltipRect] = useState<DOMRectReadOnly | null>(null);
  const [left, setLeft] = useState<number>(0);
  const [top, setTop] = useState<number>(0);
  const [triangleLeft, setTriangleLeft] = useState(0);
  const [triangleTop, setTriangleTop] = useState(0);
  //箭頭方向
  const [trianglePosition, setTrianglePosition] = useState<TriangleArrow>(
    TriangleArrow.DOWN,
  );

  // 依據目標元素寬高/定位與箭頭方向計算 tooltip 絕對位置
  const getTooltipPosition = useCallback(() => {
    if (targetRect && tooltipRect) {
      // 箭頭靠前
      if (placement.endsWith('start')) {
        if (['top', 'bottom'].some((dir) => placement.startsWith(dir))) {
          const offset =
            targetRect.left +
            targetRect.width / 2 -
            TRIANGLE_PADDING -
            TRIANGLE_BASE / 2;
          setLeft(offset);
        } else {
          const offset =
            targetRect.top +
            targetRect.height / 2 -
            TRIANGLE_PADDING -
            TRIANGLE_BASE / 2;
          setTop(offset);
        }
        // 箭頭靠後
      } else if (placement.endsWith('end')) {
        if (placement.startsWith('top') || placement.startsWith('bottom')) {
          const offset =
            targetRect.left +
            targetRect.width / 2 -
            tooltipRect.width +
            TRIANGLE_PADDING +
            TRIANGLE_BASE / 2;
          setLeft(offset);
        } else {
          const offset =
            targetRect.top +
            targetRect.height / 2 -
            tooltipRect.height +
            TRIANGLE_PADDING +
            TRIANGLE_BASE / 2;
          setTop(offset);
        }
        // 箭頭置中
      } else {
        if (['top', 'bottom'].some((dir) => placement.startsWith(dir))) {
          const offset =
            targetRect.left - tooltipRect.width / 2 + targetRect.width / 2;
          setLeft(offset);
        } else {
          const offset =
            targetRect.top - tooltipRect.height / 2 + targetRect.height / 2;
          setTop(offset);
        }
      }

      // tooltip 在頂部
      if (placement.startsWith('top')) {
        setTop(targetRect.top - tooltipRect.height - DISTANCE);
        // tooltip 在底部
      } else if (placement.startsWith('bottom')) {
        setTop(targetRect.bottom + DISTANCE);
        // tooltip 在左側
      } else if (placement.startsWith('left')) {
        setLeft(targetRect.left - tooltipRect.width - DISTANCE);
        // tooltip 在右側
      } else if (placement.startsWith('right')) {
        setLeft(targetRect.right + DISTANCE);
        // 扣除 border
      }
    } else {
      setTop(0);
      setLeft(0);
    }
  }, [placement, targetRect, tooltipRect]);

  // 依據 tooltip 元素寬高，計算箭頭相對 tooltip 位置
  const getTrianglePosition = useCallback(() => {
    if (tooltipRect) {
      // 箭頭靠前
      if (placement.endsWith('start')) {
        if (['top', 'bottom'].some((dir) => placement.startsWith(dir))) {
          setTriangleLeft(TRIANGLE_PADDING);
        } else {
          setTriangleTop(TRIANGLE_PADDING);
        }
        // 箭頭靠後
      } else if (placement.endsWith('end')) {
        if (placement.startsWith('top') || placement.startsWith('bottom')) {
          setTriangleLeft(tooltipRect.width - TRIANGLE_PADDING - TRIANGLE_BASE);
        } else {
          setTriangleTop(tooltipRect.height - TRIANGLE_PADDING - TRIANGLE_BASE);
        }
        // 箭頭置中
      } else {
        if (['top', 'bottom'].some((dir) => placement.startsWith(dir))) {
          setTriangleLeft(tooltipRect.width / 2 - TRIANGLE_BASE / 2);
        } else {
          setTriangleTop(tooltipRect.height / 2 - TRIANGLE_BASE / 2);
        }
      }

      // tooltip 在頂部
      if (placement.startsWith('top')) {
        setTriangleTop(tooltipRect.height - 1);
        setTrianglePosition(TriangleArrow.DOWN);
        // tooltip 在底部
      } else if (placement.startsWith('bottom')) {
        // 扣除 border
        setTriangleTop(-TRIANGLE_HEIGHT + 1);
        setTrianglePosition(TriangleArrow.UP);
        // tooltip 在左側
      } else if (placement.startsWith('left')) {
        // 扣除 border
        setTriangleLeft(tooltipRect.width - 1);
        setTrianglePosition(TriangleArrow.RIGHT);
        // tooltip 在右側
      } else if (placement.startsWith('right')) {
        // 扣除 border
        setTriangleLeft(-TRIANGLE_HEIGHT - 1);
        setTrianglePosition(TriangleArrow.LEFT);
      }
    }
  }, [placement, tooltipRect]);

  useEffect(() => {
    getTooltipPosition();
    getTrianglePosition();
  }, [getTooltipPosition, getTrianglePosition]);

  useEffect(() => {
    // 監控目標元素位置/寬高改變
    if (targetElement) {
      setTargetRect(targetElement?.getBoundingClientRect());
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation: MutationRecord) => {
          const rect = (mutation.target as HTMLElement).getBoundingClientRect();
          if (rect) {
            setTargetRect(rect);
          }
        });
      });

      if (targetElement) {
        observer.observe(targetElement, {
          attributes: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }
    return;
  }, [targetElement]);

  useEffect(() => {
    // 監控 tooltip resize
    if (tooltipCurrent) {
      setTooltipRect(tooltipCurrent.getBoundingClientRect());
      const tooltipResizeObserver = new ResizeObserver((entries) => {
        entries.forEach((i) => {
          setTooltipRect(i.target.getBoundingClientRect());
        });
      });

      if (tooltipCurrent) {
        tooltipResizeObserver.observe(tooltipCurrent);
      }

      return () => {
        if (tooltipCurrent) {
          tooltipResizeObserver.unobserve(tooltipCurrent);
        }
        tooltipResizeObserver.disconnect();
      };
    }
    return;
  }, [tooltipCurrent]);

  useEffect(() => {
    // 視窗改變或滾動時設定目標元素與 tooltip 元素位置與寬高
    const reset = () => {
      if (tooltipCurrent) {
        setTooltipRect(tooltipCurrent.getBoundingClientRect());
      }
      if (targetElement) {
        setTargetRect(targetElement?.getBoundingClientRect());
      }
    };
    window.addEventListener('resize', reset);
    window.addEventListener('scroll', reset);

    return () => {
      window.removeEventListener('resize', reset);
      window.removeEventListener('scroll', reset);
    };
  }, [targetElement, tooltipCurrent]);

  useEffect(() => {
    if (image && imageType === 'video') {
      fetch(image).then((res) => {
        res
          .json()
          .then((json) => {
            setAnimation(json);
          })
          .catch((err) => {
            setAnimation(null);
          });
      });
    }
  }, [image, imageType]);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
  };

  return (
    <TooltipContainerStyled
      ref={tooltipRef}
      $imageSize={imageSize}
      $top={top}
      $left={left}
    >
      <TriangleWrapperStyled
        $top={triangleTop}
        $left={triangleLeft}
        $position={trianglePosition}
      />
      {image && imageSize === 'large' && (
        <LargeImageContainerStyled>
          {imageType === 'image' ? (
            <img src={image} alt="" width="100%" height="100%" />
          ) : (
            <Lottie width="100%" height="100%" options={lottieOptions} />
          )}
        </LargeImageContainerStyled>
      )}
      <RightWrapperStyled>
        {image && imageSize === 'small' && (
          <SmallImageContainerStyled>
            {imageType === 'image' ? (
              <img src={image} alt="" width="100%" height="100%" />
            ) : (
              <Lottie options={lottieOptions} />
            )}
          </SmallImageContainerStyled>
        )}
        <TextWrapperStyled>
          {title && (
            <Typography variant="subtitle2" color="grey.white">
              {title}
            </Typography>
          )}
          {description && (
            <TitleWrapperStyled>
              <Typography variant="body2" color="grey.white">
                {description}
              </Typography>
              {onClose && <CloseIcon onClick={onClose} />}
            </TitleWrapperStyled>
          )}
        </TextWrapperStyled>
        {(pagination || prevButtonText || nextButtonText) && (
          <BottomWrapperStyled>
            {pagination && (
              <PaginationWrapperStyled>
                <Typography variant="body3" color="grey.white">
                  {pagination}
                </Typography>
              </PaginationWrapperStyled>
            )}
            <ButtonWrapperStyled>
              {prevButtonText && (
                <Button variant="text" color="white" onClick={prevButtonClick}>
                  {prevButtonText}
                </Button>
              )}
              {nextButtonText && (
                <Button
                  variant="contained"
                  color="white"
                  onClick={nextButtonClick}
                >
                  {nextButtonText}
                </Button>
              )}
            </ButtonWrapperStyled>
          </BottomWrapperStyled>
        )}
      </RightWrapperStyled>
    </TooltipContainerStyled>
  );
};

export default TourTooltip;
