import { MaterialStyledProps } from '@frontend/editor/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const PopperWrapperStyled = styled(Box)<MaterialStyledProps>(
  ({ theme }) => ({
    width: '400px',
    backgroundColor: theme.palette['grey']['white'],
    marginLeft: '24px',
    borderRadius: '12px',
    boxShadow: theme.shadows[24],
    padding: '24px 20px',
  }),
);

export const CloseButtonWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
  padding: theme.spacing(1),
  color: theme.palette['grey'][500],
  paddingRight: 0,
  '& > button': {
    minWidth: 0,
  },
}));

export const ToggleWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  marginBottom: theme.spacing(2),
  '& > div': {
    marginRight: theme.spacing(2),
  },
  '& > div:nth-last-child(1)': {
    marginRight: 0,
  },
}));

export const BlockSelectorWrapperStyled = styled(Box)(({ theme }) => ({
  '& > div': {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  '.MuiFormHelperText-root': {
    color: theme.palette['grey'][500],
  },
}));

export const DoneButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row-reverse',
}));
