import { Box, Link, Typography } from '@mui/material';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import Pic404 from 'assets/images/404.png';
import Logo from 'assets/images/logo.png';
import { PropsWithChildren, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { PAGE_MAIN } from '../path';

interface QueryErrorBoundaryProps extends PropsWithChildren {
  errorKey?: string | number;
}

const PageNotFoundStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  flexDirection: 'row',
}));

const LeftContainerStyled = styled(Box)(({ theme }) => ({
  width: '60%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

const RightContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  background: 'rgba(96, 125, 139, 0.05)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40%',
}));

export function QueryErrorBoundary({
  children,
  errorKey,
}: QueryErrorBoundaryProps) {
  const [retried, setRetried] = useState(0);
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      {...(errorKey ? { key: errorKey } : {})}
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <PageNotFoundStyled>
          <img
            src={Logo}
            alt="logo"
            width="139"
            height="21"
            style={{
              position: 'fixed',
              left: '60px',
              top: '60px',
            }}
          />
          <LeftContainerStyled>
            <Typography
              variant="h4"
              sx={{
                mb: 4,
              }}
            >
              <Trans i18nKey="pageNotFound.title" />
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="pageNotFound.info" />
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="pageNotFound.home" />
              <Link variant="overline" color="info.main" href={PAGE_MAIN}>
                <Trans i18nKey="pageNotFound.here" />
              </Link>
              <Trans i18nKey="pageNotFound.or" />
              <Link
                aria-label="reload"
                data-retried-count={retried}
                variant="overline"
                color="info.main"
                onClick={() => {
                  setRetried(retried + 1);
                  resetErrorBoundary();
                }}
              >
                <Trans i18nKey="pageNotFound.reload" />
              </Link>
              <Trans i18nKey="pageNotFound.to" />
            </Typography>
          </LeftContainerStyled>
          <RightContainerStyled>
            <img src={Pic404} alt="logo" />
          </RightContainerStyled>
        </PageNotFoundStyled>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

export default QueryErrorBoundary;
