import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE_MESSAGE } from '../apis';

interface Props {
  sequenceID: string;
  sequenceMessageID: string;
}

async function deleteSequenceMessage(
  projectID: string,
  sequenceID: string,
  sequenceMessageID: string,
): Promise<Response> {
  return axios
    .delete(
      `${SEQUENCE_MESSAGE}?projectId=${projectID}&sequenceId=${sequenceID}&sequenceMessageId=${sequenceMessageID}`,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteSequenceMessage(
  projectID: string,
  sequenceID: string,
  sequenceMessageID: string,
) {
  return useMutation({
    mutationKey: ['delete-sequence-message'],
    mutationFn: (props: Props) =>
      deleteSequenceMessage(
        projectID,
        props.sequenceID,
        props.sequenceMessageID,
      ),
    onSuccess: (data, variables) => {
      if (variables) {
        queryClient.invalidateQueries([
          'get-sequence',
          { projectID, sequenceID: variables.sequenceID },
        ]);
      }
    },
  });
}

export default useDeleteSequenceMessage;
