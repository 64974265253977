import { GetContactsResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS } from '../apis';

// const defaultData: GetContactsResponseType = {
//   id: 'people uuid',
//   headshot: 'https://i.imgur.com/lpSR4s5.jpeg',
//   socialId: '0123456789',
//   name: 'aaa',
//   notification: true,
//   gender: 1,
//   timezone: 8,
//   locale: 'zh_TW',
//   lastActive: '2023-04-27T09:04:23.723Z',
//   joined: '2023-04-27T09:04:23.723Z',
//   tags: [
//     {
//       id: 'uuid1',
//       name: 'abc',
//       lastUpdated: '2023-04-27T09:04:23.723Z',
//     },
//     {
//       id: 'uuid2',
//       name: 'def',
//       lastUpdated: '2023-04-27T09:04:23.723Z',
//     },
//   ],
//   attribute: [
//     {
//       id: 'uuid1',
//       name: 'attr1',
//       value: 'value1',
//       lastUpdated: '2023-04-27T09:04:23.723Z',
//     },
//     {
//       id: 'uuid2',
//       name: 'attr2',
//       value: 'value2',
//       lastUpdated: '2023-04-27T09:04:23.723Z',
//     },
//   ],
//   sequence: [
//     {
//       name: 'sequence 1',
//       lastUpdated: '2023-04-27T09:04:23.723Z',
//     },
//   ],
// };

const defaultData: GetContactsResponseType = {
  id: '',
  headshot: '',
  socialId: '',
  name: '',
  notification: false,
  gender: 0,
  timezone: 0,
  locale: '',
  lastActive: '',
  joined: '',
  tags: [],
  attributes: [],
  sequences: [],
};

async function getContacts(
  contactsID: string,
): Promise<GetContactsResponseType> {
  return axios
    .get(`${CONTACTS}?contactsId=${contactsID}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetContacts(contactsID: string) {
  return useQuery<GetContactsResponseType>({
    queryKey: ['get-contacts', { contactsID }],
    queryFn: () => {
      if (contactsID) {
        return getContacts(contactsID);
      } else {
        return defaultData;
      }
    },
  });
}
