import { GoogleSheet } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { GOOGLE_SHEET } from '../apis';

interface GetGoogleSheetProps {
  projectID: string;
  accountID: string;
}

async function getGoogleSheet({
  projectID,
  accountID,
}: GetGoogleSheetProps): Promise<GoogleSheet[]> {
  return axios
    .get(GOOGLE_SHEET, {
      params: {
        projectId: projectID,
        googleId: accountID,
      },
    })
    .then((res) => {
      if (res.data.code === 20000) {
        return res.data.data;
      } else {
        return [];
      }
    })
    .catch((err) => []);
}

export function useGetGoogleSheet({
  projectID,
  accountID,
}: GetGoogleSheetProps) {
  return useQuery<GoogleSheet[]>({
    queryKey: ['get-google-sheet', { projectID, accountID }],
    queryFn: () => {
      if (projectID && accountID) {
        return getGoogleSheet({ projectID, accountID });
      } else {
        return [];
      }
    },
  });
}

export default useGetGoogleSheet;
