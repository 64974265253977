import { queryClient } from '@frontend/sorghum/external-providers';
import {
  ResponseWithData,
  UseApi,
  WelcomeMessageForm,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_WELCOME } from '../apis';

async function PostEditorWelcome(
  projectId: string,
  socialType: number,
  params: {
    greeting: {
      message: string;
      enable: boolean;
    };
    startFlow: {
      id?: string;
      enable: boolean;
    };
  },
): Promise<ResponseWithData<{ link: string }>> {
  return axios
    .post(
      `${EDITOR_WELCOME}?projectId=${projectId}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostEditorWelcome(
  projectId: string,
  socialType: number,
  option?: UseApi<ResponseWithData<{ link: string }>, WelcomeMessageForm>,
) {
  return useMutation({
    mutationKey: ['post-editor-welcome', { projectId, socialType }],
    mutationFn: (params: WelcomeMessageForm) =>
      PostEditorWelcome(projectId, socialType, {
        greeting: {
          message: params.message,
          enable: params.status,
        },
        startFlow: {
          id: params.flowID || '',
          enable: params.flowEnable,
        },
      }),
    onSuccess: (
      data: ResponseWithData<{ link: string }>,
      variables: WelcomeMessageForm,
    ) => {
      if (data.code === 20000) {
        queryClient.refetchQueries([
          'get-editor-welcome',
          { projectID: projectId, socialType },
        ]);
        queryClient.invalidateQueries(['get-editor-flow-entry']);
        queryClient.invalidateQueries(['get-editor-entry-status']);
      }
      if (option?.onSuccess) {
        option.onSuccess(data, variables);
      }
    },
    onError: option?.onError,
  });
}

export default usePostEditorWelcome;
