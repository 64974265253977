import { Typography } from '@frontend/components/ui';
import {
  EditorViewDataBlockProps,
  MaterialStyledProps,
} from '@frontend/editor/interface';
import {
  convertNumberToK,
  convertNumberToPercentage,
} from '@frontend/editor/utils';
import { Box, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

interface BlockStyledProps extends MaterialStyledProps {
  $full: boolean;
}

const BlockStyled = styled(Box)<BlockStyledProps>(({ theme, $full }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  border: `1px solid ${theme.palette['grey'][100]}`,
  borderRadius: 8,
  padding: 6,
  width: '70px',
  background: 'white',
  gap: 4,
  ...($full !== false && { width: 'auto', height: '74px' }),
}));

export function EditorViewDataBlock({
  title,
  count,
  tooltipTitle,
  uniqCount,
  percentage,
  uniqPercentage,
  fillWidth = false, // 在 broadcast 使用時寬度要填滿四分之一
  onClickTooltip,
}: EditorViewDataBlockProps) {
  return (
    <Tooltip
      placement="bottom-end"
      title={
        <div>
          {tooltipTitle && (
            <>
              <Typography variant="note">{tooltipTitle}</Typography>
              <br />
            </>
          )}
          <Typography variant="note">
            <Trans i18nKey="view.tooltip.times" />
            {count?.toLocaleString()}{' '}
            {convertNumberToPercentage(percentage) &&
              `(${convertNumberToPercentage(percentage)})`}
          </Typography>
          <br />
          <Typography variant="note">
            <Trans i18nKey="view.tooltip.users" />
          </Typography>
          <Typography
            variant="note"
            {...(onClickTooltip && {
              onClick: onClickTooltip,
              sx: { textDecoration: 'underline', cursor: 'pointer' },
            })}
          >
            {' '}
            {uniqCount?.toLocaleString()}{' '}
            {convertNumberToPercentage(uniqPercentage) &&
              `(${convertNumberToPercentage(uniqPercentage)})`}
          </Typography>
        </div>
      }
    >
      <BlockStyled $full={fillWidth}>
        <Typography variant="note" color="bluegrey.500">
          {title}
        </Typography>
        <Typography variant="body1" color="bluegrey.900">
          {convertNumberToK(uniqCount)}
        </Typography>
        <Typography variant="caption" color="bluegrey.500">
          {convertNumberToPercentage(uniqPercentage)}
        </Typography>
      </BlockStyled>
    </Tooltip>
  );
}

export default EditorViewDataBlock;
