import { TextButtonCellTypeEnum } from '@frontend/sorghum/interface';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

export interface CellButtonIconProps {
  iconButtonType: TextButtonCellTypeEnum;
}

export function CellButtonIcon({ iconButtonType }: CellButtonIconProps) {
  switch (iconButtonType) {
    case TextButtonCellTypeEnum.REDIRECT_BUTTON:
      return <AccountTreeOutlinedIcon sx={{ color: 'info.main' }} />;
    case TextButtonCellTypeEnum.URL_BUTTON:
      return <AddLinkOutlinedIcon sx={{ color: 'info.main' }} />;
    case TextButtonCellTypeEnum.CALL_BUTTON:
      return <LocalPhoneOutlinedIcon sx={{ color: 'info.main' }} />;
    default:
      return <AccountTreeOutlinedIcon sx={{ color: 'info.main' }} />;
  }
}

export default CellButtonIcon;
