import { GetCouponIncentiveListResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_INCENTIVE_LIST } from '../apis';

const defaultData = {
  totalCount: 0,
  limit: 0,
  offset: 0,
  claimCount: 0,
  incentiveList: [
    {
      name: '',
      code: '',
    },
  ],
};

async function getCouponIncentiveList(
  projectID: string,
  couponID: string,
  limit: number,
  offset: number,
  search?: string,
): Promise<GetCouponIncentiveListResponse> {
  return axios
    .get(
      `${COUPON_INCENTIVE_LIST}?projectId=${projectID}&couponId=${couponID}&limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ''
      }`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCouponIncentiveList(
  projectID: string,
  couponID: string,
  limit: number,
  offset: number,
  search?: string,
) {
  return useQuery<GetCouponIncentiveListResponse>({
    queryKey: [
      'get-coupon-incentive-list',
      { projectID, couponID, limit, offset, search },
    ],
    queryFn: () => {
      if (projectID && couponID) {
        return getCouponIncentiveList(
          projectID,
          couponID,
          limit,
          offset,
          search,
        );
      } else {
        return defaultData as GetCouponIncentiveListResponse;
      }
    },
  });
}
