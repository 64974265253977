import { GoogleAccount } from '@frontend/editor/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { GOOGLE_ACCOUNT } from '../apis';

interface GetGoogleAccountProps {
  projectID: string;
}

async function getGoogleAccount({
  projectID,
}: GetGoogleAccountProps): Promise<GoogleAccount[]> {
  return axios
    .get(GOOGLE_ACCOUNT, {
      params: {
        projectId: projectID,
      },
    })
    .then((res) => res.data.data)
    .catch((err) => []);
}

export function useGetGoogleAccount({ projectID }: GetGoogleAccountProps) {
  return useQuery<GoogleAccount[]>({
    queryKey: ['get-google-account', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getGoogleAccount({ projectID });
      } else {
        return [];
      }
    },
  });
}

export default useGetGoogleAccount;
