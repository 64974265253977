import { TextField, Box } from '@mui/material';
import { FC } from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { styled } from '@mui/system';
import { useRef } from 'react';

export interface FormInputProps {
  name: string;
  limit?: number;
  label: string;
  control: Control<any, any> | undefined;
  borderType?: 'input' | 'label';
  fullWidth?: boolean;
  rules: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  onBlur?: () => void;
}

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid transparent',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette['grey'][200],
  },
  '&:focus .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette['primary']['main'],
  },
  '.Mui-error .MuiOutlinedInput-notchedOutline ': {
    borderColor: theme.palette['error']['main'],
  },
  '.MuiInputLabel-shrink': {
    display: 'none',
  },
  input: {
    padding: '4px 8px',
  },
}));

export const FormInput: FC<FormInputProps> = ({
  borderType = 'input',
  limit,
  name,
  control,
  label,
  rules,
  fullWidth = false,
  onBlur,
}: FormInputProps) => {
  const Component = borderType === 'input' ? TextField : TextFieldStyled;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Component
            // sx={{
            //   width:
            //     value && value.length > 6 ? `${value.length * 10}px` : `52px`,
            // }}
            fullWidth={fullWidth}
            variant="outlined"
            placeholder={label}
            value={value}
            onChange={onChange}
            inputProps={{ onBlur, maxLength: limit }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={rules}
      />
    </Box>
  );
};

export default FormInput;
