import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface AvatarProps extends MuiAvatarProps {}

export const Avatar: FC<AvatarProps> = ({
  children,
  ...props
}: MuiAvatarProps) => {
  return <MuiAvatar {...props}>{children}</MuiAvatar>;
};

export default Avatar;
