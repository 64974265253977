import {
  EmailSignUpProps,
  EmailSignUpResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { EMAIL_SIGN_UP } from '../apis';

async function emailSignUp(
  props: EmailSignUpProps,
): Promise<ResponseWithData<EmailSignUpResponse>> {
  return axios.post(EMAIL_SIGN_UP, props).then((res) => res.data);
}

export function usePostEmailSignUp() {
  return useMutation({
    mutationKey: ['post-email-sign-up'],
    mutationFn: (props: EmailSignUpProps) => emailSignUp(props),
  });
}

export default usePostEmailSignUp;
