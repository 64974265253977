import { styled } from '@mui/system';

import CheckCircle from '@mui/icons-material/CheckCircle';
import { Box, Checkbox, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export interface ToggleIconButtonProps {
  label: string;
  isSelected: boolean;
  toggle: () => void;
}

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette['grey'][200]}`,
  flex: 1,
  justifyContent: 'center',
  borderRadius: '4px',
  cursor: 'pointer',
}));

const SelectedIconWrapper = styled(IconWrapper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  position: 'relative',
  flex: 2,
  '& > svg > g > path': {
    fill: theme.palette['primary']['main'],
    stroke: theme.palette['primary']['main'],
  },
  borderColor: theme.palette['primary']['main'],
  color: theme.palette['primary']['main'],
  cursor: 'pointer',
}));

const CheckWrapper = styled(Checkbox)(({ theme }) => ({
  '&:hover': {
    background: 'transparent',
  },
}));

export const ToggleIconButton = ({
  label,
  isSelected,
  children,
  toggle,
}: PropsWithChildren<ToggleIconButtonProps>) => {
  return isSelected ? (
    <SelectedIconWrapper>
      {children}
      <Box ml={1}>
        <Typography variant="body2">{label}</Typography>
      </Box>
      <CheckWrapper
        checked={true}
        checkedIcon={
          <CheckCircle sx={{ background: 'white', borderRadius: '50%' }} />
        }
        sx={{
          position: 'absolute',
          top: '-20px',
          right: '-20px',
          backgroundColor: 'transparent',
          borderRadius: '50%',
        }}
      />
    </SelectedIconWrapper>
  ) : (
    <IconWrapper onClick={toggle}>{children}</IconWrapper>
  );
};

export default ToggleIconButton;
