import { Dialog, TextField } from '@frontend/components/ui';
import {
  useGetCommentReply,
  usePatchCommentAutoReply,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function RenameCommentAutoReplyModal() {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const id = uiState.dataID;
  const [name, setName] = useState('');
  const { mutate: updateName } = usePatchCommentAutoReply();
  const { data: commentData } = useGetCommentReply(id as string);
  const [error, setError] = useState(false);

  const handleConfirm = useCallback(() => {
    if (id && name) {
      if (name === commentData?.name) {
        uiState.closeModal();
        return;
      }
      updateName(
        { id, name },
        {
          onSuccess: (res) => {
            if (res.code === 20000) uiState.closeModal();
            else if (res.code === 40000) {
              setError(true);
            }
          },
        },
      );
    } else {
      setError(true);
    }
  }, [commentData?.name, id, name, uiState, updateName]);

  useEffect(() => {
    if (
      uiState.modalType === ModalTypesEnum.RENAME_COMMENT_AUTO_REPLY &&
      commentData
    ) {
      setError(false);
      setName(commentData.name ?? '');
    }
  }, [commentData, commentData?.name, uiState.modalType]);

  return (
    <Dialog
      size="xs"
      title={t('common.rename')}
      open={uiState.modalType === ModalTypesEnum.RENAME_COMMENT_AUTO_REPLY}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.ok')}
      closeBtn={false}
      handleClose={uiState.closeModal}
      handleConfirm={handleConfirm}
      uppercase
    >
      <TextField
        size="small"
        autoFocus
        inputProps={{ maxLength: 100 }}
        fullWidth
        onFocus={() => setError(false)}
        label={t('commentAutoReply.modal.rename.placeholder')}
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
        helperText={
          error ? t('commentAutoReply.modal.rename.emptyOrDuplicate') : ''
        }
        error={error}
      />
    </Dialog>
  );
}

export default RenameCommentAutoReplyModal;
