import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC } from 'react';

interface ViewDrawerBoxProps {
  title: string;
  times?: number;
  tooltip: string;
  percent?: number;
}

const ViewDrawerBoxStyled = styled(Box)(({ theme }) => ({
  padding: '20px',
  display: 'inline-flex',
  width: '296px',

  '&: hover': {
    background: theme.palette['background']['primary'][5],
  },
}));

const ViewInnerContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
}));

const TimesWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '4px',

  '&> h6': {
    marginRight: theme.spacing(1),
  },
}));

const TooltipIconWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette['bluegrey'][300],
}));

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const PercentTagStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0 8px',
  backgroundColor: `${theme.palette['primary']['main']}0D`,
  borderRadius: '4px',
  width: 'fit-content',
  marginTop: '4px',
}));

export const ViewDrawerBox: FC<ViewDrawerBoxProps> = ({
  title,
  times,
  tooltip,
  percent,
}: ViewDrawerBoxProps) => {
  return (
    <ViewDrawerBoxStyled>
      <ViewInnerContainerStyled>
        <TitleWrapperStyled>
          <Typography color="bluegrey.500" variant="body2">
            {title}
          </Typography>
          <TooltipIconWrapperStyled>
            <Tooltip placement="top" title={tooltip}>
              <ErrorOutlineOutlinedIcon fontSize="tiny" />
            </Tooltip>
          </TooltipIconWrapperStyled>
        </TitleWrapperStyled>
        <TimesWrapperStyled>
          <Typography variant="h6" color="primary">
            {times === undefined ? '-' : times}
          </Typography>
          {percent && (
            <PercentTagStyled>
              <Typography variant="body3" color="bluegrey.700">
                {percent} %
              </Typography>
            </PercentTagStyled>
          )}
        </TimesWrapperStyled>
      </ViewInnerContainerStyled>
    </ViewDrawerBoxStyled>
  );
};

export default React.memo(ViewDrawerBox);
