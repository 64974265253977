import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { EDITOR_DEFAULT_ANSWER } from '../apis';
import {
  ResponseWithData,
  PatchEditorDefaultAnswerProps,
  UseApi,
} from '@frontend/sorghum/interface';
import { queryClient } from '@frontend/sorghum/external-providers';

async function postEditorDefaultAnswer(
  projectID: string,
  socialType: number,
  params: PatchEditorDefaultAnswerProps,
): Promise<ResponseWithData<{ link: string }>> {
  return axios
    .post(
      `${EDITOR_DEFAULT_ANSWER}?projectId=${projectID}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostEditorDefaultAnswer(
  projectID: string,
  socialType: number,
  options?: UseApi<
    ResponseWithData<{ link: string }>,
    PatchEditorDefaultAnswerProps
  >,
) {
  return useMutation({
    mutationKey: ['post-editor-default-answer', { projectID, socialType }],
    mutationFn: (params: PatchEditorDefaultAnswerProps) =>
      postEditorDefaultAnswer(projectID, socialType, params),
    onSuccess: (
      res: ResponseWithData<{ link: string }>,
      data: PatchEditorDefaultAnswerProps,
    ) => {
      queryClient.refetchQueries([
        'get-editor-default-answer',
        { projectID, socialType },
      ]);
      queryClient.invalidateQueries(['get-editor-flow-entry']);
      queryClient.invalidateQueries(['get-editor-entry-status']);
      if (options?.onSuccess) {
        options.onSuccess(res, data);
      }
    },
  });
}

export default usePostEditorDefaultAnswer;
