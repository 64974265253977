import { Textarea, Tooltip, UploadImage } from '@frontend/components/ui';
import {
  useCanvasGet,
  useGetDataFlowChart,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  GalleryImageType,
  GalleryType,
  TextButtonCellType,
} from '@frontend/editor/interface';
import { IMAGE_RATIO, IMAGE_WIDTH } from '@frontend/editor/utils';
import { useGetEditorLabelItems } from '@frontend/sorghum/data-access';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { EditorViewTextButtonCell } from '../editor-view-text-button-cell/editor-view-text-button-cell';

interface EditorViewGalleryProps {
  id: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  background: theme.palette?.['grey'][100],
  borderRadius: '8px',
  marginTop: theme.spacing(1.5),
  padding: '12px',

  '& > div': {
    marginTop: theme.spacing(1.5),
  },
}));

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette['grey'][100],
  display: 'inline-flex',
  justifyContent: 'space-between',

  '& > svg': {
    color: theme.palette['grey'][600],
    fontSize: '16px',
  },
}));

export const ImageListWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  overflow: 'visible',
  width: '100%',
  gap: '12px',
}));

export const ImageItemStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '8px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  background: theme.palette['grey'][200],
  border: `2px solid ${theme.palette['info']['light']}`,
  borderRadius: '8px',
  gap: '12px',
}));

export const EditorViewGallery = ({ id }: EditorViewGalleryProps) => {
  const [t] = useTranslation();
  const { id: flowID } = useParams();
  const { data: projectID } = useGetProjectID();
  const { data: labelItems } = useGetEditorLabelItems(projectID as string);
  const uiState = useContext(UICtx);
  const { viewFlowEntryID, viewFlowEntryType } = uiState;
  const { data: viewData } = useGetDataFlowChart({
    projectID: projectID as string,
    flowID: flowID as string,
    entryID: viewFlowEntryID,
    entryType: viewFlowEntryType,
  });
  const { getTargetElement } = useCanvasGet();
  const labelList = get(labelItems, 'data', []);
  const targetElement = getTargetElement(id) as GalleryType;
  const children = get(targetElement, 'children', []);
  const imageType = get(targetElement, 'ratio') === 1 ? 1 : 2;

  return (
    <ContainerStyled>
      <TitleWrapperStyled>
        <Typography variant="subtitle2" color="grey.900">
          {t('canvas.editorGallery.title')}
        </Typography>
        <Tooltip
          placement="right"
          title={t('canvas.editorGallery.titleTooltip')}
        >
          <ErrorOutlineIcon />
        </Tooltip>
      </TitleWrapperStyled>
      <ImageListWrapperStyled>
        {children.map((i) => {
          const galleryImage = getTargetElement(i) as GalleryImageType;
          const cellButtons = get(galleryImage, 'children', []);
          return (
            <ImageItemStyled key={i}>
              <UploadImage
                readonly={true}
                key={galleryImage.id}
                imageType={imageType}
                defaultValue={galleryImage.url}
                // 扣除 padding
                width={IMAGE_WIDTH - 24}
                ratio={IMAGE_RATIO}
              />
              <Textarea
                defaultValue={galleryImage.title}
                placeholder={t('canvas.editorGallery.addTitle')}
                readonly
                labelItem={labelList}
              />
              <Textarea
                defaultValue={galleryImage.subtitle}
                placeholder={t('canvas.editorGallery.addSubtitle')}
                readonly
                labelItem={labelList}
              />
              {cellButtons.map((cellID: string) => {
                const textButtonCell = getTargetElement(
                  cellID,
                ) as TextButtonCellType;
                const cellViewData = get(viewData, `cells.${cellID}.uniqCvr`);

                return (
                  <EditorViewTextButtonCell
                    buttonNumber={cellViewData}
                    key={textButtonCell.id}
                    iconType={textButtonCell.buttonType}
                    text={textButtonCell.label as string}
                    outputID={textButtonCell.outputID}
                    targetID={textButtonCell.targetID as string}
                  />
                );
              })}
            </ImageItemStyled>
          );
        })}
      </ImageListWrapperStyled>
    </ContainerStyled>
  );
};

export default EditorViewGallery;
