import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { EDITOR_DEFAULT_ANSWER } from '../apis';
import {
  BasicSettingProps,
  GetEditorDefaultAnswerResponse,
} from '@frontend/sorghum/interface';

const defaultData = {
  status: 2,
  enable: false,
  startFlow: {
    id: '',
    name: '',
  },
  frequency: 1,
};

async function getGetEditorDefaultAnswer({
  projectID,
  socialType,
}: BasicSettingProps): Promise<GetEditorDefaultAnswerResponse> {
  return axios
    .get(
      `${EDITOR_DEFAULT_ANSWER}?projectId=${projectID}&socialType=${socialType}`,
    )
    .then((res) => res.data.data)
    .catch((err) => defaultData);
}

export function useGetEditorDefaultAnswer({
  projectID,
  socialType,
}: BasicSettingProps) {
  return useQuery<GetEditorDefaultAnswerResponse>({
    queryKey: ['get-editor-default-answer', { projectID, socialType }],
    queryFn: () => {
      if (projectID) {
        return getGetEditorDefaultAnswer({ projectID, socialType });
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetEditorDefaultAnswer;
