import { queryClient } from '@frontend/sorghum/external-providers';
import { ResponseWithData } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE } from '../apis';

async function postSequence(
  projectID: string,
  socialType: number,
  name: string,
): Promise<ResponseWithData<{ id: string; socialType: number; name: string }>> {
  return axios
    .post(`${SEQUENCE}?projectId=${projectID}&socialType=${socialType}`, {
      name,
    })
    .then((res) => res.data);
}

export function usePostSequence(
  projectId: string,
  socialType: number,
  name: string,
  option?: any,
) {
  return useMutation({
    mutationKey: ['post-sequence'],
    mutationFn: () => {
      return postSequence(projectId, socialType, name);
    },
    onSuccess: (data, variables) => {
      if (data.code === 20000) {
        queryClient.refetchQueries(['get-sequence-list']);
        if (option?.onSuccess) {
          option.onSuccess(data, variables);
        }
      }
    },
  });
}
