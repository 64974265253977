import { PROJECT_NOTIBAR_INFO } from '@frontend/sorghum/data-access';
import { ProjectNotibarInfoResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

async function getProjectNotibarInfo(): Promise<ProjectNotibarInfoResponse> {
  return axios
    .get(PROJECT_NOTIBAR_INFO)
    .then((res) => res.data.data)
    .catch((_) => ({}));
}

export function useGetProjectNotibarInfo(projectID: string) {
  return useQuery<ProjectNotibarInfoResponse>({
    queryKey: ['get-project-notibar-info', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getProjectNotibarInfo();
      } else {
        return {};
      }
    },
  });
}

export default useGetProjectNotibarInfo;
