import { Button, Sort, Typography } from '@frontend/components/ui';
import { PatchEditorConversation } from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import Question from '../question/question';

const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

interface ConversationStarterFacebookProps {
  data?: PatchEditorConversation;
  setData: (id: string, title?: string, flowID?: string) => void;
  addQuestion: () => void;
  deleteQuestion: (id: string) => void;
  changeSort: (indexA: number, indexB: number, before: boolean) => void;
}

export const ConversationStarterFacebook: FC<
  ConversationStarterFacebookProps
> = ({
  data,
  setData,
  addQuestion,
  deleteQuestion,
  changeSort,
}: ConversationStarterFacebookProps) => {
  return (
    <ContentWrapper>
      <Box mb={1}>
        <Typography variant="body2" color="grey.600">
          <Trans i18nKey="basicSetting.conversationStarter.content" />
        </Typography>
        <Typography variant="body2" color="grey.600">
          <Trans i18nKey="basicSetting.conversationStarter.content2" />
        </Typography>
      </Box>

      {data &&
        data.items &&
        data.items.map((i, index) => {
          const total = data.items.length;
          return (
            <Sort
              key={`conversation_question_${index}_${new Date().getTime()}`}
              ml={1}
              index={index}
              handleDrop={changeSort}
              ellipsisPosition="start"
              sortSpace={10}
            >
              <Question
                data={i}
                setData={setData}
                deleteQuestion={deleteQuestion}
                {...(index > 0 && {
                  moveUp: () => changeSort(index, index - 1, true),
                })}
                {...(index < total - 1 && {
                  moveDown: () => changeSort(index, index + 1, false),
                })}
              />
            </Sort>
          );
        })}

      {data && data.items && data?.items?.length < 5 && (
        <Box mb={2} ml={6}>
          <Button
            onClick={addQuestion}
            size="large"
            fullWidth
            color="bluegrey300"
            variant="outlined"
            dash
          >
            <Trans i18nKey="basicSetting.conversationStarter.addQuestionButton" />
          </Button>
        </Box>
      )}
      <Box>
        <Typography variant="body2" color="grey.600">
          <Trans i18nKey="basicSetting.conversationStarter.memo" />
        </Typography>
      </Box>
    </ContentWrapper>
  );
};

export default ConversationStarterFacebook;
