import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Response, PatchEditorConversation } from '@frontend/sorghum/interface';
import { EDITOR_CONVERSATION } from '../apis';
import { toast } from 'react-toastify';
import { queryClient } from '@frontend/sorghum/external-providers';

async function postEditorConversation(
  projectId: string,
  socialType: number,
  params: PatchEditorConversation,
): Promise<Response> {
  return axios
    .post(
      `${EDITOR_CONVERSATION}?projectId=${projectId}&socialType=${socialType}`,
      params,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostEditorConversation(
  projectId: string,
  socialType: number,
) {
  return useMutation({
    mutationKey: ['post-editor-conversation', { projectId, socialType }],
    mutationFn: (params: PatchEditorConversation) =>
      postEditorConversation(projectId, socialType, params),
    onSuccess: (data: Response) => {
      // todo: 要加上 refetch  entry-point 的資料
      if (data.code === 20000) {
        toast.success(data.msg);
      } else {
        toast.warn(data.msg);
      }
    },
  });
}

export default usePostEditorConversation;
