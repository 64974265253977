import { uuid } from '@frontend/components/utils';
import {
  CommentAutoReplyContext,
  ConditionDataType,
  ConditionElementType,
  TextButtonCellErrorEnum,
  TextButtonCellType,
} from '@frontend/sorghum/interface';
import { isEmpty } from 'lodash';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

export const CommentAutoReplyCtx = createContext<CommentAutoReplyContext>(
  {} as CommentAutoReplyContext,
);

export const CommentAutoReplyProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isUpdated, setIsUpdated] = useState(false);
  // 目前操作的 condition ID
  const [conditionID, setConditionID] = useState('');
  const [conditionData, setConditionData] = useState<ConditionDataType>(
    {} as ConditionDataType,
  );
  const [errorList, setErrorList] = useState<
    {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[]
  >([]);
  const [focusID, setFocusID] = useState('');

  const elementList = useMemo(() => {
    return conditionData?.privateReplyContent?.block?.elements ?? [];
  }, [conditionData?.privateReplyContent?.block?.elements]);

  const handleUpdateData = useCallback((data: ConditionDataType) => {
    setConditionData(data);
    setIsUpdated(true);
  }, []);

  const getTargetElement = useCallback(
    (elementID: string) => {
      const filterElement = elementList.filter(
        (item) => item.elementId === elementID,
      );
      if (filterElement.length === 1) {
        return filterElement[0];
      } else {
        return {} as ConditionElementType;
      }
    },
    [elementList],
  );

  const updateTextButtonData = useCallback(
    (elementID: string, data: ConditionElementType) => {
      const itemIndex = elementList.findIndex(
        (item) => item.elementId === elementID,
      );

      if (itemIndex !== -1) {
        const newArray = [
          ...elementList.slice(0, itemIndex),
          data,
          ...elementList.slice(itemIndex + 1, elementList.length),
        ];

        if (conditionData?.privateReplyContent) {
          setConditionData({
            ...conditionData,
            privateReplyContent: {
              ...conditionData.privateReplyContent,
              block: {
                ...conditionData.privateReplyContent.block,
                elements: newArray,
              },
            },
          });
          setIsUpdated(true);
        }
      }
    },
    [conditionData, elementList],
  );

  const updateCellListData = useCallback(
    (elementID: string, list: TextButtonCellType[]) => {
      const element = getTargetElement(elementID);

      if (!isEmpty(element) && conditionData?.privateReplyContent) {
        updateTextButtonData(elementID, {
          ...element,
          cells: list,
        });
      }
    },
    [
      conditionData?.privateReplyContent,
      getTargetElement,
      updateTextButtonData,
    ],
  );

  const updateCellData = useCallback(
    (elementID: string, cellID: string, cellData: TextButtonCellType) => {
      if (conditionData?.privateReplyContent) {
        const element = getTargetElement(elementID);

        if (!isEmpty(element) && element?.cells) {
          const cellList = element?.cells;
          const itemIndex = cellList.findIndex(
            (item) => item.cellId === cellID,
          );

          if (itemIndex !== -1) {
            const newArray = [
              ...cellList.slice(0, itemIndex),
              cellData,
              ...cellList.slice(itemIndex + 1, cellList.length),
            ];
            updateCellListData(elementID, newArray);
          }
        }
      }
    },
    [conditionData?.privateReplyContent, getTargetElement, updateCellListData],
  );

  const addCell = (elementID: string) => {
    const newID = uuid();
    const newItem = {
      cellId: newID,
      type: 'redirectButton',
      data: {
        title: '',
        flowId: '',
      },
    } as TextButtonCellType;

    setFocusID(newID);

    const element = getTargetElement(elementID);

    if (conditionData?.privateReplyContent && !isEmpty(element)) {
      if (element?.cells) {
        updateCellListData(elementID, [...element.cells, newItem]);
      } else {
        updateCellListData(elementID, [newItem]);
      }
    }
  };

  const deleteCell = (elementID: string, cellID: string) => {
    const element = getTargetElement(elementID);

    if (
      !isEmpty(element) &&
      element?.cells &&
      conditionData?.privateReplyContent
    ) {
      const filteredData = element.cells.filter(
        (item) => item.cellId !== cellID,
      );
      updateCellListData(elementID, filteredData);
    }
  };

  const isErrorTextButton = useCallback(() => {
    const error = errorList.filter(
      (item) => item.type === TextButtonCellErrorEnum.EMPTY_TEXTAREA_TITLE,
    );

    if (error.length > 0) {
      return true;
    }

    return false;
  }, [errorList]);

  return (
    <CommentAutoReplyCtx.Provider
      value={{
        isUpdated,
        conditionID,
        conditionData,
        errorList,
        setConditionID,
        setIsUpdated,
        setConditionDefaultData: (val: ConditionDataType) =>
          setConditionData(val),
        setConditionData: handleUpdateData,
        updateTextButtonData,
        updateCellData,
        addCell,
        deleteCell,
        updateCellListData,
        setErrorList,
        isErrorTextButton,
        focusID,
      }}
    >
      {children}
    </CommentAutoReplyCtx.Provider>
  );
};
