import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Response } from '@frontend/sorghum/interface';
import { FLOW } from '../apis';
import { queryClient } from '@frontend/sorghum/external-providers';

interface Props {
  flowID: string;
  projectId: string;
}

async function deleteEditorFlow({
  flowID,
  projectId,
}: Props): Promise<Response> {
  return axios
    .delete(`${FLOW}?flowId=${flowID}&projectId=${projectId}`)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteEditorFlow() {
  return useMutation({
    mutationKey: ['delete-editor-flow'],
    mutationFn: (props: Props) => deleteEditorFlow(props),
    onSuccess: (data) => {
      queryClient.refetchQueries(['flow-list']);
    },
  });
}

export default useDeleteEditorFlow;
