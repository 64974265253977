import { InputFieldPrefixIcon, Tooltip } from '@frontend/components/ui';
import { TextButtonCellTypesEnum } from '@frontend/editor/interface';
import { convertNumberToK } from '@frontend/editor/utils';
import { BroadcastButtonTypeEnum } from '@frontend/sorghum/interface';
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Position } from 'reactflow';
import styled from 'styled-components';
import { HandlePoint } from '../handle-point/handle-point';

interface EditorViewTextButtonCellProps {
  iconType?: TextButtonCellTypesEnum | BroadcastButtonTypeEnum;
  buttonNumber?: number;
  buttonNumberType?: '%' | 'k';
  text: string;
  targetID?: string | null | undefined;
  outputID?: string;
  prefixIconColor?: string;
  textButtonDisabled?: boolean;
  variant?: 'block' | 'coupon';
  showTooltip?: boolean;
  tooltipTimes?: number;
  tooltipUsers?: number;
}

const TextButtonContainerStyled = styled(Box)<{ $variant: 'block' | 'coupon' }>(
  ({ theme, $variant }) => ({
    display: 'inline-flex',
    position: 'relative',
    height: '40px',
    alignItems: 'center',
    borderRadius: '4px',
    width: '100%',
    background:
      $variant === 'block' ? theme.palette['grey']['white'] : 'transparent',
    ...($variant === 'coupon' && {
      border: `0.5px solid ${theme.palette['grey']['500']}`,
    }),
    padding: '0px 16px 0px 12px',
    justifyContent: 'space-between',
  }),
);

const SpanStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '100px',
  padding: '2px 10px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette['background']['black'][5],
}));

const IconWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  top: '9px',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const EditorViewTextButtonCell = ({
  iconType,
  text,
  buttonNumber,
  buttonNumberType = '%',
  targetID,
  outputID,
  textButtonDisabled,
  variant = 'block',
  prefixIconColor = 'primary.main',
  showTooltip = false,
  tooltipTimes,
  tooltipUsers,
}: EditorViewTextButtonCellProps) => {
  return (
    <TextButtonContainerStyled $variant={variant}>
      {iconType && (
        <IconWrapperStyled>
          <InputFieldPrefixIcon btnType={iconType} color={prefixIconColor} />
        </IconWrapperStyled>
      )}
      <Typography
        variant="body2"
        color={textButtonDisabled ? 'grey.500' : 'grey.900'}
      >
        {text}
      </Typography>
      <Tooltip
        disabled={!showTooltip}
        title={
          <Box>
            <Typography variant="note">
              <Trans i18nKey="view.tooltip.times" />
              {tooltipTimes?.toLocaleString() ?? 0}
            </Typography>
            <br />
            <Typography variant="note">
              <Trans i18nKey="view.tooltip.users" />
            </Typography>
            <Typography variant="note">
              {tooltipUsers?.toLocaleString() ?? 0}
            </Typography>
          </Box>
        }
      >
        <SpanStyled>
          {buttonNumberType === '%' && (
            <Typography variant="caption" color="grey.900">
              {buttonNumber ?? 0}%
            </Typography>
          )}
          {buttonNumberType === 'k' && (
            <Typography variant="caption" color="grey.900">
              {convertNumberToK(buttonNumber) ?? 0}
            </Typography>
          )}
        </SpanStyled>
      </Tooltip>
      {targetID && (
        <HandlePoint
          id={outputID}
          type="source"
          position={Position.Right}
          styles={{
            top: '50%',
          }}
          isConnected={!!targetID}
        />
      )}
    </TextButtonContainerStyled>
  );
};

export default EditorViewTextButtonCell;
