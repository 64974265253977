import { globalTheme } from '@frontend/components/external-providers';
import {
  ChipEditorChipType,
  ChipEditorSelectOptionType,
} from '@frontend/components/interface';
import { ChipEditor, Typography } from '@frontend/components/ui';
import {
  useGetCategoryValue,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { CategoryValueTypesEnum } from '@frontend/editor/interface';
import {
  useGetAttributeKey,
  usePatchContactAttribute,
} from '@frontend/sorghum/data-access';
import {
  AttributeItemType,
  PatchContactAttributeRequestType,
} from '@frontend/sorghum/interface';
import { PEOPLE_DETAIL_MODAL_ATTRIBUTE_SEE_DETAIL } from '@frontend/sorghum/utils';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface PeopleDetailAttributeTabProps {
  attributeTagList: AttributeItemType[];
  contactID: string;
  username: string;
}

const AttributeTabContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 0',
}));

export function PeopleDetailAttributeTab({
  attributeTagList,
  contactID,
  username,
}: PeopleDetailAttributeTabProps) {
  const [t] = useTranslation();
  const [tagList, setTagList] = useState<ChipEditorChipType[]>([]);
  const [attributeID, setAttributeID] = useState('');
  const [valueList, setValueList] = useState<ChipEditorSelectOptionType[]>([]);
  const [tagData, setTagData] = useState<{
    id: string; // chip id
    label: string; // attr name
    value: string; // attr id
    attrValue?: string; // attr value
    newAttribute?: boolean;
  }>({ id: '', label: '', value: '' });

  const { data: projectID } = useGetProjectID();
  const { data: attributeData } = useGetAttributeKey(projectID as string);
  const { data: valueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.ATTRIBUTE,
    attributeID,
  });
  const { mutate: updateAttribute } = usePatchContactAttribute();

  // 選擇完 attribute name 執行
  const onUpdateAttributeName = (
    chipID: string,
    option: ChipEditorSelectOptionType,
  ) => {
    setAttributeID(option.value);
    setTagData({
      id: chipID,
      label: option.label,
      value: option.value,
    });
  };

  // 選擇完 attribute value 執行
  const onUpdateAttributeValue = (
    chipID: string,
    option: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => {
    if (type === 'add') {
      setTagData({
        ...tagData,
        attrValue: option.label,
        ...(option?.newTag && { newAttribute: true }),
      });

      const updatedList = tagList.map((item) =>
        item.id === chipID
          ? {
              ...item,
              label: tagData.label,
              value: tagData.value,
              attrValue: option.label,
              ...(option?.newTag && { newAttribute: true }),
            }
          : item,
      );

      setTagList(updatedList);

      const formattedProps: PatchContactAttributeRequestType = {
        contactsId: contactID,
        create: [
          {
            id: tagData.value,
            name: tagData.label,
            value: option.label,
            ...(option?.newTag && { newAttribute: true }),
          },
        ],
        delete: [],
      };
      updateAttribute(formattedProps);
    } else if (type === 'delete') {
      const formattedProps: PatchContactAttributeRequestType = {
        contactsId: contactID,
        create: [],
        delete: [option?.id ?? ''],
      };
      updateAttribute(formattedProps);
    }
  };

  const formattedAttributeNameList = useMemo(() => {
    if (attributeData) {
      return attributeData.map((item) => {
        return {
          label: item.key,
          value: item.id,
        };
      });
    } else {
      return [] as ChipEditorSelectOptionType[];
    }
  }, [attributeData]);

  // 從 API 拿到 attribute list，有值的話就放進 tagList
  useEffect(() => {
    if (attributeTagList) {
      const formattedTagList = attributeTagList.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.value,
        lastUpdated: item.lastUpdated,
      }));

      setTagList(formattedTagList);
    }
  }, [attributeTagList]);

  useEffect(() => {
    if (valueData) {
      const formattedValueList: ChipEditorSelectOptionType[] = valueData.map(
        (item) => ({
          label: item.value,
          value: item.id,
        }),
      );

      setValueList(formattedValueList);
    }
  }, [valueData]);

  return (
    <AttributeTabContainerStyled>
      <Typography variant="body3">
        {t('people.detailModal.attributeTab.description')}

        <Link href={PEOPLE_DETAIL_MODAL_ATTRIBUTE_SEE_DETAIL}>
          {t('people.detailModal.attributeTab.seeDetails')}
        </Link>
      </Typography>
      <Box sx={{ marginTop: '16px' }}>
        <ChipEditor
          firstOptionList={formattedAttributeNameList}
          secondOptionList={valueList}
          tagList={tagList}
          setTagList={setTagList}
          addButtonText={t('people.detailModal.attributeTab.buttonText')}
          isAllowSecondSelectAddOption
          hasSecondValue
          onUpdateFirstChipValue={onUpdateAttributeName}
          onUpdateSecondChipValue={onUpdateAttributeValue}
          chipBackgroundColor={`${get(
            globalTheme,
            'palette.secondary.main',
            '',
          )}1A`}
          chipFirstTextColor={get(globalTheme, 'palette.secondary.dark', '')}
          chipSecondTextColor={get(globalTheme, 'palette.grey.800', '')}
          cancelButtonColor="secondary.main"
          isNeedConfirmDelete
          username={username}
          disableOptionTooltip={t(
            'people.detailModal.attributeTab.disableOptionText',
          )}
          chipTooltipLabelName={t(
            'people.detailModal.attributeTab.tooltipLabel',
          )}
          chipTooltipValueName={t(
            'people.detailModal.attributeTab.tooltipValue',
          )}
        />
      </Box>
    </AttributeTabContainerStyled>
  );
}

export default PeopleDetailAttributeTab;
