import { globalTheme } from '@frontend/components/external-providers';
import {
  useCanvasCollect,
  useCanvasView,
  useGetProjectID,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  EntryPointBroadcast,
  EntryPointCommentAutoReply,
  SystemEntryPoint,
} from '@frontend/editor/interface';
import { useProjectLinked } from '@frontend/sorghum/data-access';
import {
  COMMENT_AUTO_REPLY,
  CONTENT,
  PAGE_BROADCAST,
  PAGE_FLOW,
  PAGE_SEQUENCE,
  SENT,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import React, { FC, useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ItemContainer,
  StatusEntryPointItemStyled,
  SystemEntryPointInfo,
  SystemEntryPointItem,
  SystemEntryPointItemLabelWrapperStyled,
  ToolTipStyled,
} from './styles';

interface NodeSystemEntryPointListProps {
  items: SystemEntryPoint[];
  broadcasts: EntryPointBroadcast[];
  commentAutoReplies: EntryPointCommentAutoReply[];
}

interface NodeEntryPointItemProps {
  id: string;
  title: string;
  status?: boolean;
  content?: React.ReactNode;
  onClick?: () => void;
}

const NodeEntryPointItem: FC<NodeEntryPointItemProps> = ({
  id,
  title,
  status = true,
  content,
  onClick,
}: NodeEntryPointItemProps) => {
  const uiState = useContext(UICtx);
  const { entryDataType } = uiState;
  return (
    <StatusEntryPointItemStyled
      $status={status}
      onClick={onClick}
      $onFocus={entryDataType === id}
    >
      <CallMergeIcon sx={{ color: globalTheme?.palette?.grass?.[600] }} />
      <SystemEntryPointItemLabelWrapperStyled>
        <Typography variant="caption" color="grey.500">
          {title}
        </Typography>
        {content}
      </SystemEntryPointItemLabelWrapperStyled>
    </StatusEntryPointItemStyled>
  );
};

//TODO: flow focus on block
const NodeSystemEntryPointList: FC<NodeSystemEntryPointListProps> = ({
  items,
  broadcasts,
  commentAutoReplies,
}: NodeSystemEntryPointListProps) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { setEntryDataType, entryDataType } = uiState;
  const { focusNode } = useCanvasView();
  const { redirectFlowEntryPoint, sequenceEntryPoint } = useCanvasCollect();
  const { data: projectID } = useGetProjectID();
  const { data: linked } = useProjectLinked(projectID as string);
  const isConnectPages = !!linked?.fb?.id;

  const gotoBroadcastDetailPage = useCallback(
    (id: string) => {
      if (state.readonly) {
        sendGAEvent(
          'Chat Flow View',
          'Redirect from broadcast',
          'Chat Flow View - Entry point - System trigger - redirect from broadcast - click',
          '',
        );
      } else {
        sendGAEvent(
          'Chat Flow Edit',
          'Redirect from broadcast',
          'Chat Flow Edit - Entry point - System trigger - redirect from broadcast - click',
          '',
        );
      }
      navigateToProjectPage(`${PAGE_BROADCAST}/${id}/${SENT}`);
    },
    [navigateToProjectPage, state.readonly],
  );

  const gotoCommentAutoReply = useCallback(
    (id: string) => {
      navigateToProjectPage(`/${COMMENT_AUTO_REPLY}/${id}/${CONTENT}`);
    },
    [navigateToProjectPage],
  );

  const gotoFlow = useCallback(
    (id: string) => {
      if (state.readonly) {
        sendGAEvent(
          'Chat Flow View',
          'Redirect from flow',
          'Chat Flow View - Entry point - System trigger - redirect from flow - click',
          '',
        );
      } else {
        sendGAEvent(
          'Chat Flow Edit',
          'Redirect from flow',
          'Chat Flow Edit - Entry point - System trigger - redirect from flow - click',
          '',
        );
      }
      navigateToProjectPage(`${PAGE_FLOW}/${id}`);
    },
    [navigateToProjectPage, state.readonly],
  );
  const gotoSequence = useCallback(
    (id: string) => {
      if (state.readonly) {
        sendGAEvent(
          'Chat Flow View',
          'Redirect from follow-up',
          'Chat Flow View - Entry point - System trigger - redirect from follow up - click',
          '',
        );
      } else {
        sendGAEvent(
          'Chat Flow Edit',
          'Redirect from follow up',
          'Chat Flow Edit - Entry point - System trigger - redirect from follow up - click',
          '',
        );
      }
      navigateToProjectPage(`${PAGE_SEQUENCE}/${id}/${CONTENT}`);
    },
    [navigateToProjectPage, state.readonly],
  );

  const getOrdinalSuffix = (sequenceIndex: number) => {
    switch (sequenceIndex % 10) {
      case 1:
        return `${sequenceIndex}st`;
      case 2:
        return `${sequenceIndex}nd`;
      case 3:
        return `${sequenceIndex}rd`;
      default:
        return `${sequenceIndex}th`;
    }
  };

  return (
    <ItemContainer>
      <SystemEntryPointInfo onClick={() => setEntryDataType('ALL')}>
        <Typography variant="caption" color="bluegrey.400">
          <Trans i18nKey="canvas.entryPoint.system.info" />
        </Typography>
      </SystemEntryPointInfo>
      {/* share link */}
      {items
        .sort((a: SystemEntryPoint, b: SystemEntryPoint) => {
          if (a.createDate > b.createDate) {
            return 1;
          } else if (a.createDate === b.createDate) {
            return 0;
          } else {
            return -1;
          }
        })
        .map((i: SystemEntryPoint) =>
          state.readonly ? (
            <SystemEntryPointItem
              key={`SystemEntryPointItem_${i.id}`}
              $onFocus={entryDataType === i.id}
            >
              <CallSplitIcon
                sx={{ color: globalTheme?.palette?.grass?.[600] }}
              />
              <Typography variant="subtitle2" color="grey.500">
                {i.label}
              </Typography>
            </SystemEntryPointItem>
          ) : (
            <Tooltip
              key={`Tooltip_${i.id}`}
              placement="right"
              title={
                <ToolTipStyled>
                  <Trans i18nKey="canvas.entryPoint.system.goto" />
                  <Link
                    variant="inherit"
                    onClick={() => focusNode(i.id)}
                    color="infoOnTooltips.main"
                  >
                    {i.label}
                  </Link>
                  <Trans i18nKey="canvas.entryPoint.system.edit" />
                </ToolTipStyled>
              }
            >
              <SystemEntryPointItem
                $onFocus={entryDataType === i.id}
                onClick={() => {
                  setEntryDataType(i.id);
                }}
              >
                <CallSplitIcon
                  sx={{ color: globalTheme?.palette?.grass?.[600] }}
                />
                <Typography variant="subtitle2" color="grey.500">
                  {i.label}
                </Typography>
              </SystemEntryPointItem>
            </Tooltip>
          ),
        )}
      {/* broadcast */}
      {broadcasts.map((i) => (
        <NodeEntryPointItem
          key={`BroadcastItem_${i.broadcastId}`}
          id={i.id}
          onClick={() => {
            setEntryDataType(i.id);
          }}
          title={t('canvas.entryPoint.system.broadcast')}
          status={i.status}
          content={
            <Box>
              <Typography
                color="grey.500"
                variant="caption"
                sx={{ fontSize: '14px' }}
              >
                {t('canvas.entryPoint.from')} {t('canvas.entryPoint.the')}{' '}
                <Tooltip placement="top" title={i.buttonName}>
                  <span
                    className="link"
                    onClick={() => gotoBroadcastDetailPage(i.broadcastId)}
                  >
                    {i.buttonName}
                  </span>
                </Tooltip>{' '}
                {t('canvas.entryPoint.in')} {i.name}
              </Typography>
            </Box>
          }
        />
      ))}
      {/* flow */}
      {redirectFlowEntryPoint &&
        redirectFlowEntryPoint.map((i) => (
          <NodeEntryPointItem
            key={`RedirectFlowItem_${i.id}`}
            id={i.id}
            onClick={() => {
              setEntryDataType(i.id);
            }}
            title={t('canvas.entryPoint.system.redirectFlow')}
            content={
              <Box>
                <Typography
                  color="grey.500"
                  variant="caption"
                  sx={{ fontSize: '14px' }}
                >
                  {t('canvas.entryPoint.from')}{' '}
                  <Tooltip placement="top" title={i.blockName}>
                    <span
                      className="link"
                      onClick={() => {
                        gotoFlow(i.flowId);
                        state.setDefaultFocusID(i.id);
                      }}
                    >
                      {i.blockName}
                    </span>
                  </Tooltip>{' '}
                  {t('canvas.entryPoint.in')} {i.name}
                </Typography>
              </Box>
            }
          />
        ))}
      {/* sequence */}
      {sequenceEntryPoint &&
        sequenceEntryPoint.map((i) => (
          <NodeEntryPointItem
            key={`SequenceItem_${i.sequenceId}-${i.idx}`}
            id={i.id}
            onClick={() => {
              setEntryDataType(i.id);
            }}
            title={t('canvas.entryPoint.system.sequence')}
            content={
              <Box>
                <Typography
                  color="grey.500"
                  variant="caption"
                  sx={{ fontSize: '14px' }}
                >
                  {t('canvas.entryPoint.from')} {t('canvas.entryPoint.the')}{' '}
                  <span
                    className="link"
                    onClick={() => gotoSequence(i.sequenceId)}
                  >
                    {getOrdinalSuffix(i.idx)}
                  </span>{' '}
                  {t('canvas.entryPoint.message')} {t('canvas.entryPoint.in')}{' '}
                  {i.name}
                </Typography>
              </Box>
            }
          />
        ))}
      {/* comment auto reply */}
      {commentAutoReplies.map((i) => (
        <NodeEntryPointItem
          key={`CommentAutoReplyItem_${i.commentReplyId}`}
          id={i.id}
          title={t('canvas.entryPoint.system.commentAutoReply')}
          status={i.status === 2 && isConnectPages}
          content={
            <Box
              onClick={() => {
                setEntryDataType(i.id);
              }}
            >
              <Typography
                color="grey.500"
                variant="caption"
                sx={{ fontSize: '14px' }}
              >
                {t('canvas.entryPoint.from')} {t('canvas.entryPoint.the')}{' '}
                <Tooltip placement="top" title={i.conditionName}>
                  <span
                    className="link"
                    onClick={() => gotoCommentAutoReply(i.commentReplyId)}
                  >
                    {i.conditionName}
                  </span>
                </Tooltip>{' '}
                {t('canvas.entryPoint.in')} {i.name}
                <br />
                {i.status === 2 && isConnectPages && (
                  <Typography color="success">
                    ({t('canvas.entryPoint.addMenu.commentAutoReply.active')})
                  </Typography>
                )}
                {(i.status !== 2 || !isConnectPages) && (
                  <Typography variant="captionItalic" color="grey.400">
                    ({t('canvas.entryPoint.addMenu.commentAutoReply.inactive')})
                  </Typography>
                )}
              </Typography>
            </Box>
          }
        />
      ))}
    </ItemContainer>
  );
};

export default NodeSystemEntryPointList;
