import { Avatar, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';

import { PhonePreviewHeader, Typography } from '@frontend/components/ui';

import {
  TextButtonCellType,
  TextButtonElementType,
} from '@frontend/sorghum/interface';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Gosky from '../../images/gosky.svg';

export interface BroadcastContentPreviewProps {
  buttonList: TextButtonElementType[];
}

const PreviewContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '440px',
  overflow: 'auto',
  borderRadius: ' 0 0 20px 20px',
  backgroundColor: theme.palette['grey'][50],
  paddingTop: '4px',
}));

const MessageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'flex-end',
  marginTop: '18px',
}));

const MessageStyled = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 12px',
  margin: '4px',
  width: '175px',
}));

const MessageButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette['grey']['white'],
  marginTop: '8px',
  borderRadius: '10px',
  padding: '8px 12px',
  whiteSpace: 'pre-wrap',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LeftSideMessageStyled = styled(MessageStyled)(({ theme }) => ({
  alignItems: 'center',

  backgroundColor: theme.palette['grey'][200],
  color: theme.palette['grey']['black'],
}));

const ButtonsContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export function BroadcastContentPreview({
  buttonList,
}: BroadcastContentPreviewProps) {
  const [t] = useTranslation();
  const [contentCellList, setContentCellList] = useState<
    TextButtonElementType[]
  >([]);

  useEffect(() => {
    if (buttonList) {
      setContentCellList(buttonList);
    }
  }, [buttonList]);

  return (
    <PreviewContainerStyled>
      <PhonePreviewHeader />
      {contentCellList && (
        <MessageContainerStyled>
          <Box justifyContent="end" display="flex" ml={2} mb={1}>
            <Avatar
              src={Gosky}
              sx={{
                width: '17px',
                height: '17px',
              }}
            />
          </Box>
          <ButtonsContainerStyled>
            {contentCellList.map((item) => (
              <LeftSideMessageStyled key={item.elementId}>
                <Box display="inline-flex">
                  {!isEmpty(item?.data?.text) ? (
                    <Typography
                      lineHeight="14px"
                      variant="body2"
                      ml={1}
                      sx={{
                        letterSpacing: '0.25px',
                        fontSize: '12px',
                        lineHeight: '150%',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                      }}
                    >
                      {item?.data?.text}
                    </Typography>
                  ) : (
                    <Typography
                      lineHeight="14px"
                      variant="body2"
                      ml={1}
                      sx={{
                        letterSpacing: '0.25px',
                        fontSize: '12px',
                        lineHeight: '150%',
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-all',
                      }}
                    >
                      {t('broadcast.contentPreview.textareaPlaceholder')}
                    </Typography>
                  )}
                </Box>
                {item?.cells &&
                  item.cells.map((cellItem: TextButtonCellType) => {
                    return (
                      <MessageButtonStyled key={cellItem.cellId}>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            letterSpacing: '0.25px',
                          }}
                          color={
                            isEmpty(cellItem.data.title)
                              ? 'grey.400'
                              : 'grey.black'
                          }
                          fontSize="small"
                          variant="body2"
                        >
                          {isEmpty(cellItem.data.title)
                            ? t(
                                'broadcast.contentPreview.textButtonPlaceholder',
                              )
                            : cellItem.data.title}
                        </Typography>
                      </MessageButtonStyled>
                    );
                  })}
              </LeftSideMessageStyled>
            ))}
          </ButtonsContainerStyled>
        </MessageContainerStyled>
      )}
    </PreviewContainerStyled>
  );
}

export default BroadcastContentPreview;
