import { ResponseWithData } from '@frontend/editor/interface';
import { queryClient } from '@frontend/sorghum/external-providers';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_ATTRIBUTE_KEY } from '../apis';

interface Param {
  key: string;
}

interface PostProjectAttributeKeyProps {
  projectID: string;
  param: Param;
}

async function postProjectAttributeKey({
  projectID,
  param,
}: PostProjectAttributeKeyProps): Promise<ResponseWithData<{ id: string }>> {
  return axios
    .post(`${PROJECT_ATTRIBUTE_KEY}?projectId=${projectID}`, param)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostProjectAttributeKey(projectID: string) {
  return useMutation({
    mutationKey: ['post-project-attribute-key', { projectID }],
    mutationFn: (param: Param) => postProjectAttributeKey({ projectID, param }),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-project-attribute-key']);
      queryClient.refetchQueries(['editor-label-item']);
      queryClient.invalidateQueries(['get-google-sheet-option']);
    },
  });
}

export default usePostProjectAttributeKey;
