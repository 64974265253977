import { globalTheme } from '@frontend/components/external-providers';
import { SubData } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetDataDashboardTotal } from '@frontend/sorghum/data-access';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { get, isNaN } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const DashboardTotalDataContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  '& > .MuiBox-root:not(:first-child)': {
    marginLeft: '24px',
  },
}));

export const DataDashboardTotalBlock: FC = () => {
  const { data: projectID } = useGetProjectID();
  const [t] = useTranslation();
  const { data } = useGetDataDashboardTotal(projectID as string);
  const percentageSuffix = t('dataDashboard.percentageOfAllUser');

  const uniqUsers = get(data, 'uniqUsers', 0);
  const uniqRnSubscribers = get(data, 'uniqRnSubscribers', 0);
  const uniqActiveUsers = get(data, 'uniqActiveUsers', 0);
  const percentageRnSubscribers = isNaN((uniqRnSubscribers / uniqUsers) * 100)
    ? 0
    : (uniqRnSubscribers / uniqUsers) * 100;
  const percentageActiveUsers = isNaN((uniqActiveUsers / uniqUsers) * 100)
    ? 0
    : (uniqActiveUsers / uniqUsers) * 100;

  return (
    <DashboardTotalDataContainerStyled>
      <SubData
        size="large"
        title={t('dataDashboard.subDataType.users')}
        titleTooltip={t('dataDashboard.subDataTooltips.users')}
        mainData={uniqUsers}
        color={get(globalTheme, 'palette.blue.200')}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t('dataDashboard.subDataType.rnSubscribers')}
        titleTooltip={t('dataDashboard.subDataTooltips.rnSubscribers')}
        mainData={uniqRnSubscribers}
        color={get(globalTheme, 'palette.green.200')}
        percentage={percentageRnSubscribers}
        percentageSuffix={percentageSuffix}
        hideLastPeriod
        hideTrendingPercentage
      />
      <SubData
        size="large"
        title={t('dataDashboard.subDataType.activeUsers')}
        titleTooltip={t('dataDashboard.subDataTooltips.activeUsers')}
        mainData={uniqActiveUsers}
        color={get(globalTheme, 'palette.orange.200')}
        percentage={percentageActiveUsers}
        percentageSuffix={percentageSuffix}
        hideLastPeriod
        hideTrendingPercentage
      />
    </DashboardTotalDataContainerStyled>
  );
};

export default DataDashboardTotalBlock;
