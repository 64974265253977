import { Radio } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useGetProject,
  usePatchCommentReplyPeriod,
} from '@frontend/sorghum/data-access';
import { SchedulePeriodType } from '@frontend/sorghum/interface';
import { dayjs, useTimer } from '@frontend/sorghum/utils';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CommentAutoReplySecondStepProps {
  commentAutoReplyID: string;
  disabled?: boolean;
  schedulePeriodData?: SchedulePeriodType;
  handleSaving: (status: boolean) => void;
  updateData: (newData: {
    periodType: 1 | 2;
    startedAt?: string;
    endedAt?: string;
  }) => void;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '24px',
}));

const RadioWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: '24px',
  marginTop: '12px',
}));

const DatePickerWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  paddingTop: '16px',
  gap: '24px',

  '& > div': {
    maxWidth: '360px',
  },
}));

export const CommentAutoReplySecondStep: FC<
  CommentAutoReplySecondStepProps
> = ({
  commentAutoReplyID,
  disabled,
  schedulePeriodData,
  handleSaving,
  updateData,
}) => {
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const [t] = useTranslation();
  const [radioValue, setRadioValue] = useState<1 | 2>(1);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  const timezone = project?.timezoneOffset ? project?.timezoneOffset : 0;

  const { mutate: updateCommentAutoReplyPeriod, isLoading } =
    usePatchCommentReplyPeriod();

  const handleRadioChange = useCallback(
    (newValue: 1 | 2) => {
      if (disabled) return;
      setRadioValue(newValue);
      setIsUpdated(true);
    },
    [disabled],
  );

  const handleDataChange = useCallback(() => {
    updateData({
      periodType: radioValue,
      startedAt: startDate?.format('YYYY-MM-DD HH:mm'),
      endedAt: endDate?.format('YYYY-MM-DD HH:mm'),
    });
  }, [endDate, radioValue, startDate, updateData]);

  const startDateError = useMemo(() => {
    if (!startDate)
      return t('commentAutoReply.content.secondStep.startDateEmpty');
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        return t('commentAutoReply.content.secondStep.startDateBeforeEndDate');
      }
    }
    return '';
  }, [endDate, startDate, t]);

  const endDateError = useMemo(() => {
    if (!endDate) return t('commentAutoReply.content.secondStep.endDateEmpty');
    if (startDate && endDate) {
      if (endDate.isBefore(startDate)) {
        return t('commentAutoReply.content.secondStep.endDateAfterStartDate');
      }
    }
    return '';
  }, [endDate, startDate, t]);

  const onSave = useCallback(() => {
    if (disabled) return;
    updateCommentAutoReplyPeriod({
      id: commentAutoReplyID,
      isAutoSave: true,
      periodType: radioValue === 1 ? 1 : 2,
      startedAt: startDate?.format('YYYY-MM-DD HH:mm'),
      endedAt: endDate?.format('YYYY-MM-DD HH:mm'),
    });

    setIsUpdated(false);
  }, [
    commentAutoReplyID,
    disabled,
    endDate,
    radioValue,
    startDate,
    updateCommentAutoReplyPeriod,
  ]);

  useEffect(() => {
    if (!isLoaded && schedulePeriodData) {
      setIsLoaded(true);
      setIsUpdated(false);
      setRadioValue(schedulePeriodData?.periodType || 1);
      setStartDate(
        schedulePeriodData?.startedAt
          ? dayjs(schedulePeriodData?.startedAt)
          : null,
      );
      setEndDate(
        schedulePeriodData?.endedAt ? dayjs(schedulePeriodData?.endedAt) : null,
      );
    }
  }, [schedulePeriodData, isLoaded]);

  useTimer(2, isUpdated, onSave);

  useEffect(() => {
    handleDataChange();
  }, [handleDataChange]);

  useEffect(() => {
    handleSaving(isLoading);
  }, [handleSaving, isLoading]);

  return (
    <ContainerStyled>
      <RadioWrapperStyled>
        <Radio
          size="small"
          checked={radioValue === 1}
          onClick={() => handleRadioChange(1)}
          disabled={disabled}
        />
        {t('commentAutoReply.content.secondStep.radio1')}
        <Radio
          size="small"
          checked={radioValue === 2}
          onClick={() => handleRadioChange(2)}
          disabled={disabled}
        />
        {t('commentAutoReply.content.secondStep.radio2')}
      </RadioWrapperStyled>
      {radioValue === 1 && (
        <>
          <Typography variant="body2" color="grey.600">
            {t('commentAutoReply.content.secondStep.subtitle1', {
              timezone: `${timezone >= 0 ? '+' : '-'} ${timezone}`,
            })}
          </Typography>
          <DatePickerWrapperStyled>
            <DatePicker
              label={t('commentAutoReply.content.secondStep.startDateLabel')}
              disablePast
              disabled={disabled}
              value={startDate}
              onChange={(newValue: dayjs.Dayjs | null) => {
                if (newValue) {
                  setIsUpdated(true);
                  setStartDate(newValue);
                } else {
                  setStartDate(null);
                }
              }}
              minDate={dayjs()}
              maxDate={endDate ? endDate : dayjs().add(1, 'year')}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  error: !!startDateError,
                  helperText: startDateError,
                },
              }}
            />
            <TimePicker
              label={t('commentAutoReply.content.secondStep.startTimeLabel')}
              value={startDate || dayjs('12:00', 'HH:mm')}
              disabled={disabled}
              maxTime={
                startDate && endDate && startDate.diff(endDate) === 0
                  ? endDate
                  : dayjs('23:59', 'HH:mm')
              }
              onChange={(newValue: dayjs.Dayjs | null) => {
                if (newValue) {
                  setIsUpdated(true);
                  setStartDate(newValue);
                }
              }}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                },
              }}
            />
          </DatePickerWrapperStyled>
          <DatePickerWrapperStyled>
            <DatePicker
              label={t('commentAutoReply.content.secondStep.endDateLabel')}
              disablePast
              disabled={disabled}
              value={endDate}
              onChange={(newValue: dayjs.Dayjs | null) => {
                if (newValue) {
                  setIsUpdated(true);
                  setEndDate(newValue);
                } else {
                  setStartDate(null);
                }
              }}
              minDate={startDate ? startDate : dayjs()}
              maxDate={dayjs().add(1, 'year')}
              slotProps={{
                textField: {
                  required: true,
                  fullWidth: true,
                  error: !!endDateError,
                  helperText: endDateError,
                },
              }}
            />
            <TimePicker
              label={t('commentAutoReply.content.secondStep.endTimeLabel')}
              disabled={disabled}
              minTime={
                startDate && endDate && startDate.diff(endDate) === 0
                  ? startDate
                  : dayjs('00:00', 'HH:mm')
              }
              value={endDate || dayjs('12:00', 'HH:mm')}
              onChange={(newValue: dayjs.Dayjs | null) => {
                if (newValue) {
                  setIsUpdated(true);
                  setEndDate(newValue);
                }
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                },
              }}
            />
          </DatePickerWrapperStyled>
        </>
      )}
    </ContainerStyled>
  );
};

export default CommentAutoReplySecondStep;
