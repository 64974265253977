import { MenuItem, SxProps } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { MenuItemProps } from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import {
  ElementType,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  RefAttributes,
  RefObject,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

type NestedMenuItemProps = Omit<MenuItemProps, 'button'> & {
  parentMenuOpen: boolean;
  component?: ElementType;
  label?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
  tabIndex?: number;
  disabled?: boolean;
  ContainerProps?: HTMLAttributes<HTMLElement> &
    RefAttributes<HTMLElement | null>;
  MenuProps?: Partial<Omit<MenuProps, 'children'>>;
  button?: true | undefined;
};

type IconMenuItemProps = {
  MenuItemProps?: MenuItemProps;
  className?: string;
  disabled?: boolean;
  label?: string;
  leftIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  ref?: RefObject<HTMLLIElement>;
  rightIcon?: React.ReactNode;
  sx?: SxProps;
};

const MenuStyled = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: 0,
  },
  '.MuiMenu-list': {
    padding: 0,
    backgroundColor: theme.palette['bluegrey'][900],
    color: theme.palette['bluegrey'][200],
    borderRadius: 0,
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '224px',
  padding: '16px 24px',
  '&:hover': {
    backgroundColor: '#0000001A',
  },
}));

const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  function IconMenuItem(
    { MenuItemProps, className, label, leftIcon, rightIcon, ...props },
    ref,
  ) {
    return (
      <MenuItemStyled
        {...MenuItemProps}
        ref={ref}
        className={className}
        {...props}
      >
        <span>{label}</span>
        {rightIcon}
      </MenuItemStyled>
    );
  },
);

export const NestedMenuItem = forwardRef<
  HTMLLIElement | null,
  NestedMenuItemProps
>(function NestedMenuItem(props, ref) {
  const {
    parentMenuOpen,
    label,
    rightIcon = null,
    leftIcon = null,
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    MenuProps,
    ...MenuItemProps
  } = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLLIElement | null>(null);
  useImperativeHandle(ref, () => menuItemRef.current!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(true);

    if (ContainerProps.onMouseEnter) {
      ContainerProps.onMouseEnter(e);
    }
  };
  const handleMouseLeave = (e: MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(false);

    if (ContainerProps.onMouseLeave) {
      ContainerProps.onMouseLeave(e);
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <div
      {...ContainerProps}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <IconMenuItem
        MenuItemProps={MenuItemProps}
        className={className}
        ref={menuItemRef}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        label={label}
      />

      <MenuStyled
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
        {...MenuProps}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </MenuStyled>
    </div>
  );
});
