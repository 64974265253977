import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DASHBOARD_FLOW_LIST } from '../apis';

const defaultData = {
  totalCount: 200,
  filterCount: 20,
  flows: [
    {
      name: 'flow #1',
      enter: 4000,
      interacted: 400,
      uniqEnter: 2000,
      uniqInteracted: 200,
      avgConversation: 2.5,
    },
  ],
  limit: 10,
  offset: 0,
};

interface getDashboardFlowListProps {
  projectID: string;
  range: number;
  search?: string;
  offset?: number;
  limit: number;
}

async function getDashboardFlowList({
  projectID,
  range,
  search,
  offset,
  limit,
}: getDashboardFlowListProps) {
  return axios
    .get(
      `${DASHBOARD_FLOW_LIST}?projectId=${projectID}${
        range ? `&range=${range}` : ''
      }${search ? `&search=${search}` : ''}${
        offset ? `&offset=${offset}` : ''
      }${limit ? `&limit=${limit}` : ''}`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetDashboardFlowList({
  projectID,
  range,
  search,
  offset,
  limit,
}: getDashboardFlowListProps) {
  return useQuery({
    queryKey: [
      'get-dashboard-flow-List',
      { projectID, range, search, offset, limit },
    ],
    queryFn: () => {
      if (projectID) {
        return getDashboardFlowList({
          projectID,
          limit,
          range,
          ...(search && { search }),
          ...(offset && { offset }),
        });
      } else {
        return defaultData;
      }
    },
  });
}
