import { Box as MuiBox, BoxProps as MuiBoxProps } from '@mui/material';
import { FC } from 'react';

/* eslint-disable-next-line */
export interface BoxProps extends MuiBoxProps {}

export const Box: FC<BoxProps> = ({ children, ...props }: MuiBoxProps) => {
  return <MuiBox {...props}>{children}</MuiBox>;
};

export default Box;
