import { TextareaTooltip } from '@frontend/components/ui';
import { REGEX_CHECK_URL } from '@frontend/editor/utils';
import {
  TextButtonCellErrorEnum,
  TextButtonCellType,
  TextButtonCellTypeEnum,
} from '@frontend/sorghum/interface';
import { Box, ClickAwayListener, InputAdornment } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellButtonIcon from '../cell-button-icon/cell-button-icon';
import CellButtonModal from '../cell-button-modal/cell-button-modal';
import {
  CellButtonContainerStyled,
  TextFieldStyled,
  TooltipStyled,
} from './styles';
export interface CellButtonProps {
  cellData: TextButtonCellType;
  isHoverCell: string;
  id: string;
  updateCellData: (
    elementID: string,
    cellID: string,
    data: TextButtonCellType,
  ) => void;
  errorList: {
    id: string;
    type: TextButtonCellErrorEnum;
    errorText?: string;
  }[];
  mode?: string;
  elementID: string;
  setErrorList: (
    list: {
      id: string;
      type: TextButtonCellErrorEnum;
      errorText?: string;
    }[],
  ) => void;
}

export function CellButton({
  id,
  cellData,
  isHoverCell,
  updateCellData,
  errorList,
  mode = 'editable',
  elementID,
  setErrorList,
}: CellButtonProps) {
  const ref = useRef(null);

  const [t] = useTranslation();

  const [anchorEl, setAnchorEl] = useState<
    null | HTMLElement | HTMLInputElement
  >(null);
  const [isOpen, setIsOpen] = useState(false);
  const onHandleClickAway = useCallback(() => {
    if (cellData.type === TextButtonCellTypeEnum.URL_BUTTON) {
      const urlRegex = new RegExp(REGEX_CHECK_URL);
      if (isEmpty(cellData.data.url)) {
        const hasErrorAlready = errorList.find(
          (item) =>
            item.id === id && item.type === TextButtonCellErrorEnum.EMPTY_URL,
        );

        if (!hasErrorAlready) {
          setErrorList([
            ...errorList,
            { id, type: TextButtonCellErrorEnum.EMPTY_URL },
          ]);
        }
      } else if (!(cellData?.data?.url as string).match(urlRegex)) {
        const hasErrorAlready = errorList.find(
          (item) =>
            item.id === id &&
            item.type === TextButtonCellErrorEnum.WRONG_URL_FORMAT,
        );

        if (!hasErrorAlready) {
          setErrorList([
            ...errorList,
            { id, type: TextButtonCellErrorEnum.WRONG_URL_FORMAT },
          ]);
        }
      } else {
        // remove error
        setErrorList(
          errorList.filter(
            (item) =>
              item.id !== id &&
              item.type !== TextButtonCellErrorEnum.EMPTY_CELL_TITLE,
          ),
        );
      }
    }
    setAnchorEl(null);
  }, [cellData.data.url, cellData.type, errorList, id, setErrorList]);

  // cell 被新增的時候可以抓到該 cell 的 DOM 並開啟 cell modal
  useEffect(() => {
    if (ref.current) {
      setAnchorEl(ref.current);
    }
  }, []);

  return (
    <ClickAwayListener onClickAway={onHandleClickAway}>
      <Box>
        <CellButtonContainerStyled>
          <TextFieldStyled
            key={id}
            value={cellData.data.title}
            onClick={() => {
              if (ref.current) setAnchorEl(ref.current);
              setIsOpen(true);
            }}
            inputRef={ref}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CellButtonIcon iconButtonType={cellData.type} />
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 20 }}
            onChange={(e) =>
              updateCellData(elementID, id, {
                ...cellData,
                data: { ...cellData.data, title: e.target.value },
              })
            }
            error={
              errorList.filter((item) => item.id === id).length > 0 ||
              isEmpty(cellData.data.title)
            }
            placeholder={t('components.editableButton.inputPlaceholder')}
          />
          {isHoverCell === id && (
            <TooltipStyled>
              <TextareaTooltip
                limit={20}
                length={cellData.data.title.length}
                hasLabel={false}
              />
            </TooltipStyled>
          )}
        </CellButtonContainerStyled>
        <CellButtonModal
          open={isOpen && !!anchorEl}
          cellData={cellData}
          cellID={id}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          updateCellData={updateCellData}
          errorList={errorList}
          elementID={elementID}
          setErrorList={setErrorList}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default CellButton;
