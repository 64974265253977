import { globalTheme } from '@frontend/components/external-providers';
import { SubData } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardEngagementData } from '@frontend/sorghum/data-access';
import {
  DashboardEngagementData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { get } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type DataDashboardEngagementSubDataProps = PostDataDashboardProps;

export const DataDashboardEngagementSubData: FC<
  DataDashboardEngagementSubDataProps
> = ({
  startDate,
  endDate,
  condition,
}: DataDashboardEngagementSubDataProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const [data, setData] = useState<DashboardEngagementData>({
    uniqNewActiveUsers: 0,
    uniqNewActiveUsersP: 0,
    lastUniqNewActiveUsers: 0,
    uniqNewActiveUsersTrend: 0,
    uniqRetentionUsers: 0,
    uniqRetentionUsersP: 0,
    lastUniqRetentionUsers: 0,
    uniqRetentionUsersTrend: 0,
    uniqSleepUsers: 0,
    uniqSleepUsersP: 0,
    lastUniqSleepUsers: 0,
    uniqSleepUsersTrend: 0,
  });

  const { mutate: queryData, isLoading } = usePostDataDashboardEngagementData(
    projectID as string,
  );

  const percentageSuffix = t('dataDashboard.engagement.percentageOfUser');

  useEffect(() => {
    if (startDate && endDate && projectID) {
      queryData(
        {
          startDate,
          endDate,
          condition,
        },
        {
          onSuccess: (_data: DashboardEngagementData) => {
            setData(_data);
          },
        },
      );
    }
  }, [condition, endDate, projectID, queryData, startDate, t]);

  return (
    <>
      <SubData
        isLoading={isLoading}
        title={t('dataDashboard.engagement.subData.newActiveUsers.title')}
        titleTooltip={t('dataDashboard.engagement.subData.newActiveUsers.info')}
        mainData={data.uniqNewActiveUsers}
        color={get(globalTheme, 'palette.orange.200')}
        trendingPercentage={data.uniqNewActiveUsersTrend}
        percentage={data.uniqNewActiveUsersP}
        lastPeriod={data.lastUniqNewActiveUsers}
        percentageSuffix={percentageSuffix}
      />
      <SubData
        isLoading={isLoading}
        title={t('dataDashboard.engagement.subData.retentionUsers.title')}
        titleTooltip={t('dataDashboard.engagement.subData.retentionUsers.info')}
        mainData={data.uniqRetentionUsers}
        color={get(globalTheme, 'palette.orange.200')}
        trendingPercentage={data.uniqRetentionUsersTrend}
        percentage={data.uniqRetentionUsersP}
        lastPeriod={data.lastUniqRetentionUsers}
        percentageSuffix={percentageSuffix}
      />
      <SubData
        isLoading={isLoading}
        title={t('dataDashboard.engagement.subData.sleepUsers.title')}
        titleTooltip={t('dataDashboard.engagement.subData.sleepUsers.info')}
        mainData={data.uniqSleepUsers}
        color={get(globalTheme, 'palette.grey.200')}
        trendingPercentage={data.uniqSleepUsersTrend}
        percentage={data.uniqSleepUsersP}
        lastPeriod={data.lastUniqSleepUsers}
        percentageSuffix={percentageSuffix}
      />
    </>
  );
};

export default DataDashboardEngagementSubData;
