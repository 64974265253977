import { ClickAwayListener } from '@mui/material';
import { FC, ReactNode, useCallback, useState } from 'react';
import { InfoWrapper, ItemContainer, ItemWrapper } from './styles';

interface ExpandPanelProps {
  openMode?: 'always' | '';
  title: ReactNode;
  content?: ReactNode;
  onClick?: () => void;
}

const ExpandPanel: FC<ExpandPanelProps> = ({
  title,
  content,
  openMode,
  onClick,
}: ExpandPanelProps) => {
  const [isFocus, setIsFocus] = useState(false);
  const handleClickAway = useCallback(() => {
    setIsFocus(false);
  }, []);

  const handleClick = useCallback(() => {
    setIsFocus(true);
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <ItemContainer onClick={handleClick} $focus={isFocus}>
        <ItemWrapper>{title}</ItemWrapper>
        {(openMode === 'always' || isFocus) && (
          <InfoWrapper>{content}</InfoWrapper>
        )}
      </ItemContainer>
    </ClickAwayListener>
  );
};

export default ExpandPanel;
