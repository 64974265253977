import {
  PostPeopleListConditionType,
  PostPeopleListResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PEOPLE_LIST } from '../apis';

export interface PostPeopleListProps {
  limit: number;
  start?: string | null;
  end?: string | null;
  sortBy?: string;
  orderBy?: string;
  offset?: number;
  condition?: PostPeopleListConditionType[];
  // 1-and/2-or
  filterCriteria?: 1 | 2;
}

const defaultValue = {
  totalCount: 0,
  filterCount: 0,
  contacts: [],
  limit: 0,
  offset: 0,
};

const postPeopleList = ({
  sortBy,
  orderBy,
  offset,
  limit,
  filterCriteria,
  condition,
}: PostPeopleListProps): Promise<PostPeopleListResponse> =>
  axios
    .post(PEOPLE_LIST, {
      ...(sortBy && { sortBy }),
      ...(orderBy && { orderBy }),
      ...(condition && condition.length > 0
        ? { condition, filterCriteria }
        : // 沒有條件則帶入 all
          { condition: [], filterCriteria: 1 }),
      offset,
      limit,
    })
    .then((res) => res.data.data)
    .catch((_) => defaultValue);

// postContacts
export const usePostPeopleList = ({
  sortBy,
  orderBy,
  offset,
  limit,
  filterCriteria,
  condition,
}: PostPeopleListProps) => {
  return useQuery<PostPeopleListResponse>({
    queryKey: [
      'post-people-list',
      {
        filterCriteria,
        sortBy,
        orderBy,
        offset,
        limit,
        condition,
      },
    ],
    queryFn: () => {
      return postPeopleList({
        sortBy,
        orderBy,
        offset,
        limit,
        filterCriteria,
        condition,
      });
    },
  });
};

export default usePostPeopleList;
