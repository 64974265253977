import { styled } from '@mui/system';
import { FC } from 'react';
import { usePreview } from 'react-dnd-preview';
import { Box } from '@mui/material';
import Typography from '../typography/typography';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

interface Props {
  info: string;
  count: number;
}

const DragPreviewWrapper = styled(Box)(({ theme }) => ({
  zIndex: 20,
  paddingLeft: '360px',
}));

const PreviewContentWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  width: '444px',
  background: theme.palette?.['info']?.light,
  padding: '15px 18px',
  color: theme.palette?.['info']?.main,
  '& > p': {
    color: theme.palette?.['info']?.dark,
  },
}));

const RowDragPreview: FC<Props> = ({ info, count }: Props) => {
  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { style } = preview;
  return (
    <DragPreviewWrapper style={style}>
      <PreviewContentWrapper>
        <AccountTreeOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
        <Typography variant="body2">
          {info.replace('{{count}}', count.toString())}
        </Typography>
      </PreviewContentWrapper>
    </DragPreviewWrapper>
  );
};

export default RowDragPreview;
