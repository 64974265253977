import TagManager from 'react-gtm-module';

export const sendGAEvent = (
  category: string,
  action: string,
  label: string,
  value: string,
) => {
  const args = {
    dataLayer: {
      event: 'customEvent',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    },
  };

  TagManager.dataLayer(args);
};
