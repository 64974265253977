import { BREAK_POINT, useRwd } from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Grid,
  Modal as MuiModal,
  Typography,
} from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

/* eslint-disable-next-line */
export interface ModalProps {
  open: boolean;
  title: string;
  info: string | ReactNode;
  buttonText?: string;
  cancelText?: string;
  size?: 'lg' | 'sm';
  onButtonClick?: () => void;
  onCancelClick?: () => void;
  handleClose?: () => void;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  title,
  info,
  buttonText,
  cancelText,
  onButtonClick,
  onCancelClick,
  handleClose,
  children,
}: PropsWithChildren<ModalProps>) => {
  const { breakPoint } = useRwd();
  return (
    <MuiModal disableAutoFocus open={open} onClose={handleClose}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#ffffff',
          borderRadius: '24px',
          padding: '30px',
          ...(breakPoint < BREAK_POINT.MD && { width: 'calc(100% - 40px)' }),
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right',
            }}
          >
            <CloseIcon onClick={handleClose} fontSize="small" />
          </Box>
          <Box
            flexDirection="column"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <Box mb="1rem" textAlign="center">
              <Typography gutterBottom variant="h5" color="grey.900">
                {title}
              </Typography>
            </Box>
            <Box textAlign="center" mb="2rem">
              <Typography gutterBottom variant="body2" color="grey.800">
                {info}
              </Typography>
            </Box>
            <Box>{children}</Box>
            <Box display="flex" justifyContent="space-between" width="100%">
              {cancelText && (
                <Box flex="1" mr="10px">
                  <Button
                    fullWidth
                    variant="outlined"
                    color="inherit"
                    onClick={onCancelClick}
                  >
                    {cancelText}
                  </Button>
                </Box>
              )}
              {buttonText && (
                <Box flex="1">
                  <Button fullWidth variant="contained" onClick={onButtonClick}>
                    {buttonText}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </MuiModal>
  );
};

export default Modal;
