import {
  useCanvasAdd,
  useCanvasCollect,
  useCanvasUpdate,
} from '@frontend/editor/data-access';
import { AutocompleteOptionType } from '@frontend/editor/interface';
import { Box, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Autocomplete from '../autocomplete/autocomplete';
import { ToggleIconType } from './text-button-cell-modal';

interface BlockSettingType extends ToggleIconType {
  handleClose: () => void;
  outputID: string;
  onValidateWhenCreateNewBlock: () => void;
}

const BlockSelectorWrapper = styled(Box)(({ theme }) => ({
  '& > div': {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

//FIXME: 新增 Block 後 data 內的 targetID 要更新
export const BlockSetting = ({
  outputID,
  handleClose,
  elementID,
  getTargetElement,
  onValidateWhenCreateNewBlock,
}: BlockSettingType) => {
  const { canvasConnectBlock, canvasUpdateData } = useCanvasUpdate();
  const { addNodeEditorMenu } = useCanvasAdd();
  const { blocks } = useCanvasCollect();

  const [t] = useTranslation();

  const elementData = getTargetElement(elementID);

  const onCreateClick = useCallback(() => {
    addNodeEditorMenu(outputID, elementData?.nodeID ?? '');
    // 新增 block 時驗證 text button cell 需不需要顯示紅框
    onValidateWhenCreateNewBlock();

    handleClose();
  }, [
    addNodeEditorMenu,
    elementData?.nodeID,
    handleClose,
    onValidateWhenCreateNewBlock,
    outputID,
  ]);

  const handleChange = useCallback(
    (newValue: AutocompleteOptionType | null) => {
      if (newValue) {
        canvasConnectBlock(outputID, newValue.value as string);

        canvasUpdateData(elementID, 'data.targetID', newValue.value);
      } else {
        canvasUpdateData(elementID, 'data.targetID', '');
      }
    },
    [canvasConnectBlock, canvasUpdateData, elementID, outputID],
  );

  const optionBlocks = useMemo(() => {
    const newOptionList: AutocompleteOptionType[] = [];
    newOptionList.push({
      label: t('canvas.modal.editTextButton.createBlock'),
      value: 'add',
      divider: true,
      exclude: true,
      onClick: onCreateClick,
    });

    if (blocks.length > 0) {
      blocks
        .filter((i) => i.id !== elementData?.nodeID)
        .forEach((i) => {
          newOptionList.push({
            label: i.label as string,
            value: i.id as string,
          });
        });
    }

    return newOptionList;
  }, [blocks, elementData?.nodeID, onCreateClick, t]);

  return (
    <BlockSelectorWrapper>
      <Autocomplete
        outputID={outputID}
        placeholder={t('canvas.modal.editTextButton.chooseBlock')}
        onChange={handleChange}
        value={elementData.targetID}
        options={optionBlocks}
      />
    </BlockSelectorWrapper>
  );
};
