import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Response } from '@frontend/sorghum/interface';
import { FLOW_GROUP } from '../apis';
import { toast } from 'react-toastify';
import { queryClient } from '@frontend/sorghum/external-providers';

interface Props {
  flowGroupId: string;
  projectId: string;
  // true: delete / false: ungroup
  deleteFlows: boolean;
}

async function deleteEditorFlowGroup({
  flowGroupId,
  projectId,
  deleteFlows,
}: Props): Promise<Response> {
  return axios
    .delete(
      `${FLOW_GROUP}?flowGroupId=${flowGroupId}&projectId=${projectId}&deleteFlows=${deleteFlows}`,
    )
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function useDeleteEditorFlowGroup() {
  return useMutation({
    mutationKey: ['delete-editor-flow-group'],
    mutationFn: (props: Props) => deleteEditorFlowGroup(props),
    onSuccess: (data) => {
      if (data.code === 20000) {
        queryClient.refetchQueries(['editor-flow-group']);
        toast.success(data.msg);
      } else {
        toast.warn(data.msg);
      }
    },
  });
}

export default useDeleteEditorFlowGroup;
