import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, PropsWithChildren } from 'react';
import MobilePreview from '../../images/preview.png';

/* eslint-disable-next-line */
export interface BasicSettingPreviewProps {
  bottomColor: string;
  position?: 'top' | 'bottom';
  onClick?: () => void;
  className?: string;
}

const PreviewContainer = styled(Box)`
  width: 500px;
  display: flex;
  justify-content: center;
`;

const MobileWrapper = styled(Box)`
  position: relative;
  width: 264px;
  height: 600px;
  background-repeat: no-repeat;
  background-size: 400px 595px;
  background-image: url('${MobilePreview}');
  background-position: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const MobileBottom = styled(Box)`
  position: absolute;
  width: 235px;
  background-color: #f5f5f5;
  bottom: 13px;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileTop = styled(Box)`
  position: absolute;
  width: 235px;
  top: 80px;
  background-color: #f5f5f5;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BasicSettingPreview: FC<
  PropsWithChildren<BasicSettingPreviewProps>
> = ({
  bottomColor,
  children,
  position = 'bottom',
  onClick,
  className,
}: PropsWithChildren<BasicSettingPreviewProps>) => {
  return (
    <PreviewContainer className={className}>
      <MobileWrapper onClick={onClick}>
        {position === 'bottom' && (
          <MobileBottom sx={{ background: bottomColor }}>
            {children}
          </MobileBottom>
        )}
        {position === 'top' && (
          <MobileTop sx={{ background: bottomColor }}>{children}</MobileTop>
        )}
      </MobileWrapper>
    </PreviewContainer>
  );
};

export default BasicSettingPreview;
