import { GetCouponListResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COUPON_LIST } from '../apis';

const defaultData = {
  totalCount: 0,
  filterCount: 0,
  limit: 10,
  offset: 0,
  coupons: [
    {
      id: '',
      name: '',
      activatedAt: '',
      totalIssuance: 0,
      claimCount: 0,
    },
  ],
};

async function getCouponList(
  projectID: string,
  limit: number,
  offset: number,
  search?: string,
): Promise<GetCouponListResponse> {
  return axios
    .get(
      `${COUPON_LIST}?projectId=${projectID}&limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ''
      }`,
    )
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCouponList(
  projectID: string,
  limit: number,
  offset: number,
  search?: string,
) {
  return useQuery<GetCouponListResponse>({
    queryKey: ['get-coupon-list', { projectID, offset, limit, search }],
    queryFn: () => {
      if (projectID) {
        return getCouponList(projectID, limit, offset, search);
      } else {
        return defaultData;
      }
    },
  });
}
