import { Typography } from '@frontend/components/ui';
import { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../box/box';

interface SurveyBarProps {
  firstText: string;
  lastText: string;
  value: string | number;
  options: number[];
  setValue: (val: number) => void;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  height: '72px',
  gap: '32px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ItemWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '12px',
  justifyContent: 'end',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'visible',
  width: '40px',
}));

const OptionStyled = styled(Box)<{ $checked: boolean }>(
  ({ theme, $checked }) => ({
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    cursor: 'pointer',
    color: theme.palette['primary']['main'],
    background: $checked
      ? theme.palette['background']['primary'][10]
      : 'transparent',

    '&:hover': {
      background: theme.palette['background']['primary'][5],
    },
  }),
);

export const SurveyBar: FC<SurveyBarProps> = ({
  firstText,
  lastText,
  value,
  options,
  setValue,
}: SurveyBarProps) => {
  return (
    <ContainerStyled>
      {options.map((option, index) => (
        <ItemWrapperStyled key={option}>
          {index === 0 && (
            <Typography variant="body3" color="grey.700">
              {firstText}
            </Typography>
          )}
          {index === options.length - 1 && (
            <Typography variant="body3" color="grey.700">
              {lastText}
            </Typography>
          )}
          <OptionStyled
            $checked={option === value}
            onClick={() => setValue(option)}
          >
            {option}
          </OptionStyled>
        </ItemWrapperStyled>
      ))}
    </ContainerStyled>
  );
};

export default SurveyBar;
