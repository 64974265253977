import { ExpandPanel } from '@frontend/components/ui';
import {
  EditorMenuItemType,
  GetEditorMenuItemType,
} from '@frontend/sorghum/interface';
import { Box, styled } from '@mui/material';
import { FC, MutableRefObject } from 'react';
import PersistentMenuItemContent from '../persistent-menu-item-content/persistent-menu-item-content';
import PersistentMenuItemHeader from '../persistent-menu-item-header/persistent-menu-item-header';

export interface PersistentMenuItemProps {
  index: number;
  itemLength: number;
  menuItemData: GetEditorMenuItemType;
  titles: string[];
  isPanelOpen: boolean;
  togglePanelOpen: () => void;
  changeSort: (indexA: number, indexB: number, before: boolean) => void;
  isEditable: boolean;
  textFieldRef: MutableRefObject<null>;
  openDeleteModal: (index: number) => void;
  publishErrors: any[];
  setFieldValue: (
    id: string | undefined,
    fieldName: string,
    value: string | null | number,
  ) => void;
  setItemData: (itemID: string, itemData: EditorMenuItemType) => void;
  onUpdateFlowID: (flowID: string, itemID: string | undefined) => void;
}

const PersistentMenuItemStyled = styled(Box)(({ theme }) => ({
  width: '100%',
}));

export const PersistentMenuItem: FC<PersistentMenuItemProps> = ({
  index,
  itemLength,
  menuItemData,
  titles,
  isPanelOpen,
  changeSort,
  togglePanelOpen,
  textFieldRef,
  isEditable,
  publishErrors,
  openDeleteModal,
  setFieldValue,
  setItemData,
  onUpdateFlowID,
}: PersistentMenuItemProps) => {
  return (
    <PersistentMenuItemStyled>
      <ExpandPanel
        key={menuItemData.id}
        sx={{ marginBottom: '8px', width: '100%' }}
        open={isPanelOpen}
        toggleOpen={togglePanelOpen}
        header={
          <PersistentMenuItemHeader
            id={menuItemData.id || ''}
            menuItemData={menuItemData}
            index={index}
            titles={titles}
            textFieldRef={textFieldRef}
            isEditable={isEditable}
            publishErrors={publishErrors}
            openDeleteModal={openDeleteModal}
            setFieldValue={setFieldValue}
            {...(index > 0 && {
              handleMoveUp: () => changeSort(index, index - 1, true),
            })}
            {...(index < itemLength - 1 && {
              handleMoveDown: () => changeSort(index, index + 1, false),
            })}
          />
        }
        content={
          <PersistentMenuItemContent
            isEditable={isEditable}
            menuItemData={menuItemData}
            publishErrors={publishErrors}
            setFieldValue={setFieldValue}
            setItemData={setItemData}
            onUpdateFlowID={onUpdateFlowID}
          />
        }
        autoClose={false}
      />
    </PersistentMenuItemStyled>
  );
};

export default PersistentMenuItem;
