import {
  AutocompleteWithNestedOptions,
  Button,
  Divider,
  Radio,
  Tooltip,
} from '@frontend/components/ui';
import { uuid } from '@frontend/components/utils';
import {
  FilterConditionTypeEnum,
  PeopleTableFilterConditionPropsType,
} from '@frontend/sorghum/interface';
import { dayjs } from '@frontend/sorghum/utils';
import { InfoOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  IconButton,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PeopleTableFilterButtonCondition from '../people-table-filter-button-condition/people-table-filter-button-condition';

enum RadioTypeEnum {
  AND = 1,
  OR = 2,
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '24px 20px',
  background: theme.palette['grey']['white'],
  borderRadius: '4px',
  boxShadow: theme.shadows[4],
  width: '800px',
}));

const RadioWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: '12px',
}));

const RadioInfoWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
}));

const ConditionWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '12px',
}));

const BottomWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SubmitWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
}));

interface PeopleTableFilterButtonProps {
  conditions: PeopleTableFilterConditionPropsType[];
  disabled: boolean;
  onSubmit: (
    radioValue: 1 | 2,
    value: PeopleTableFilterConditionPropsType[],
  ) => void;
}

export const PeopleTableFilterButton: FC<PeopleTableFilterButtonProps> = ({
  conditions: initialConditions,
  disabled,
  onSubmit,
}: PeopleTableFilterButtonProps) => {
  const [t] = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [radioValue, setRadioValue] = useState<RadioTypeEnum>(
    RadioTypeEnum.AND,
  );
  const [conditions, setConditions] = useState<
    PeopleTableFilterConditionPropsType[]
  >([]);

  const handleSubmit = useCallback(
    (close?: boolean) => {
      onSubmit(radioValue, conditions);
      if (close) {
        setAnchorEl(null);
      }
    },
    [conditions, onSubmit, radioValue],
  );

  const handleCancel = useCallback(() => {
    setConditions([]);
    setRadioValue(RadioTypeEnum.AND);
  }, []);

  const handleConditionValueChange = useCallback(
    (id: string, value: PeopleTableFilterConditionPropsType) => {
      setConditions((prev) =>
        prev.map((condition) => {
          if (condition.id === id) {
            return { ...condition, ...value };
          }
          return condition;
        }),
      );
    },
    [],
  );

  const handleConditionTypeChange = useCallback(
    (id: string, type: FilterConditionTypeEnum) => {
      if (type) {
        setConditions((prev) =>
          prev.map((condition) => {
            if (condition.id === id) {
              const defaultValue = {
                ...condition,
                key: '',
                search: '',
                start: '',
                end: '',
                operator: 1,
                searchFilter: type,
              };
              switch (type) {
                case FilterConditionTypeEnum.LAST_ACTIVE:
                case FilterConditionTypeEnum.JOINED: {
                  return {
                    ...defaultValue,
                    start: dayjs().add(-1, 'month').format('YYYY/MM/DD'),
                    end: dayjs().format('YYYY/MM/DD'),
                  };
                }
                default: {
                  return { ...defaultValue };
                }
              }
            }
            return condition;
          }),
        );
      }
    },
    [],
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleAddButtonClick = useCallback(() => {
    setConditions((prev) => [...prev, { id: uuid(), searchFilter: undefined }]);
  }, []);

  const handleRemoveButtonClick = useCallback((id: string) => {
    setConditions((prev) => prev.filter((condition) => condition.id !== id));
  }, []);

  const handleClickAway = useCallback((e: MouseEvent | TouchEvent) => {
    // 避免點擊下拉選單時觸發
    if ((e.target as Node).nodeName === 'BODY') {
      e.stopPropagation();
      return;
    }
    setAnchorEl(null);
  }, []);

  const conditionTypeOption = useMemo(() => {
    const filterOptionList = [
      {
        label: t('people.condition.attribute'),
        group: t('people.condition.attributeAndTag'),
        value: FilterConditionTypeEnum.ATTRIBUTE,
      },
      {
        label: t('people.condition.tag'),
        group: t('people.condition.attributeAndTag'),
        value: FilterConditionTypeEnum.TAG,
      },
      {
        label: t('people.condition.topic'),
        group: t('people.condition.general'),
        value: FilterConditionTypeEnum.TOPIC,
      },
      {
        label: t('people.condition.sequence'),
        group: t('people.condition.general'),
        value: FilterConditionTypeEnum.SEQUENCE,
      },
      {
        label: t('people.condition.broadcast'),
        group: t('people.condition.general'),
        value: FilterConditionTypeEnum.BROADCAST,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.name'),
        value: FilterConditionTypeEnum.NAME,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.gender'),
        value: FilterConditionTypeEnum.GENDER,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.lastActive'),
        value: FilterConditionTypeEnum.LAST_ACTIVE,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.joined'),
        value: FilterConditionTypeEnum.JOINED,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.messengerID'),
        value: FilterConditionTypeEnum.MESSENGER_ID,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.locale'),
        value: FilterConditionTypeEnum.LOCALE,
      },
      {
        group: t('people.condition.system'),
        label: t('people.condition.timezone'),
        value: FilterConditionTypeEnum.TIME_ZONE,
      },
    ];

    return filterOptionList;
  }, [t]);

  const isValid = useMemo(() => {
    let _isValid = true;
    conditions.forEach((condition) => {
      switch (condition.searchFilter) {
        case FilterConditionTypeEnum.ATTRIBUTE:
        case FilterConditionTypeEnum.BROADCAST: {
          if (!condition.key || !condition.search) _isValid = false;
          break;
        }
        case FilterConditionTypeEnum.JOINED:
        case FilterConditionTypeEnum.LAST_ACTIVE: {
          if (!condition.start || !condition.end) _isValid = false;
          break;
        }
        case FilterConditionTypeEnum.SEQUENCE:
        case FilterConditionTypeEnum.TAG: {
          if (!condition.key) _isValid = false;
          break;
        }
        default: {
          if (!condition.search) _isValid = false;
          break;
        }
      }
    });
    return _isValid;
  }, [conditions]);

  useEffect(() => {
    // 開啟時初始化
    if (open) {
      setConditions(initialConditions);
    }
  }, [initialConditions, open]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Button
          variant="outlined"
          color="bluegrey500"
          onClickCapture={handleClick}
          disabled={disabled}
        >
          {t('people.filters.button')}
        </Button>
        {open && (
          <Popper
            sx={{
              zIndex: 99,
            }}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 4], // 设置水平和垂直偏移量，垂直偏移为4px
                },
              },
            ]}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <ContainerStyled>
                  <RadioWrapperStyled>
                    <RadioInfoWrapperStyled>
                      <Tooltip title={t('people.filters.radioTooltip')}>
                        <InfoOutlined />
                      </Tooltip>
                      <Typography variant="body3" color="grey.800">
                        {t('people.filters.radioTitle')}:
                      </Typography>
                    </RadioInfoWrapperStyled>
                    <FormControlLabel
                      checked={radioValue === RadioTypeEnum.AND}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="body3" color="grey.800">
                          {t('common.and')}
                        </Typography>
                      }
                      onClick={() => setRadioValue(RadioTypeEnum.AND)}
                    />
                    <FormControlLabel
                      checked={radioValue === RadioTypeEnum.OR}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="body3" color="grey.800">
                          {t('common.or')}
                        </Typography>
                      }
                      onClick={() => setRadioValue(RadioTypeEnum.OR)}
                    />
                  </RadioWrapperStyled>
                  <Divider />
                  {conditions.map((item, index) => {
                    return (
                      <ConditionWrapperStyled key={item.id}>
                        <Typography
                          variant="body1"
                          color="grey.500"
                          sx={{
                            ...(index === 0 && { opacity: 0 }),
                          }}
                        >
                          {radioValue === RadioTypeEnum.AND
                            ? t('common.and')
                            : t('common.or')}
                        </Typography>
                        {/* Condition Type */}
                        <Box width={180}>
                          <AutocompleteWithNestedOptions
                            value={item.searchFilter}
                            onChange={(_, value) =>
                              handleConditionTypeChange(
                                item.id,
                                value?.value as FilterConditionTypeEnum,
                              )
                            }
                            disableClearable
                            options={conditionTypeOption}
                            placeholder={t('people.condition.placeholder')}
                            maxHeight="400px"
                          />
                        </Box>
                        <PeopleTableFilterButtonCondition
                          value={item}
                          onChange={(val) =>
                            handleConditionValueChange(item.id, val)
                          }
                        />

                        {/* delete */}
                        <IconButton
                          sx={{
                            color: (theme) => theme.palette.grey[500],
                          }}
                          onClick={() => handleRemoveButtonClick(item.id)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </ConditionWrapperStyled>
                    );
                  })}
                  <Button
                    dash
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="bluegrey300"
                    onClick={handleAddButtonClick}
                  >
                    {t('people.filters.addCondition')}
                  </Button>
                  <Divider />
                  <BottomWrapperStyled>
                    <Button variant="text" onClick={handleCancel}>
                      {t('people.filters.clear')}
                    </Button>
                    <SubmitWrapperStyled>
                      <Button
                        variant="outlined"
                        onClick={() => handleSubmit(false)}
                        disabled={!isValid}
                      >
                        {t('people.filters.apply')}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleSubmit(true)}
                        disabled={!isValid}
                      >
                        {t('people.filters.close')}
                      </Button>
                    </SubmitWrapperStyled>
                  </BottomWrapperStyled>
                </ContainerStyled>
              </Fade>
            )}
          </Popper>
        )}{' '}
      </Box>
    </ClickAwayListener>
  );
};

export default PeopleTableFilterButton;
