import { DndProvider as Provider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PropsWithChildren, FC } from 'react';

export const DndProvider: FC<PropsWithChildren> = ({ children }) => {
  return <Provider backend={HTML5Backend}>{children}</Provider>;
};

export const DragType = {
  ROW: 'row',
};
