export const resortArray = (
  arr: any[],
  /** 預移動的元素 */
  indexA: number,
  /** 目的地元素 */
  indexB: number,
  /** 在目標之前 or 之後 */
  before = true,
) => {
  if (indexA === indexB) {
    return arr;
  }
  let targetIndex = indexB;

  const tempArr = [...arr];
  const tempA = arr[indexA];
  tempArr.splice(indexA, 1);

  if (indexA < indexB && before) {
    targetIndex -= 1;
  } else if (indexA > indexB && !before) {
    targetIndex += 1;
  }

  tempArr.splice(targetIndex, 0, tempA);

  return tempArr;
};

export const resortList = (
  arr: any[],
  /** 預移動的元素 */
  indexA: number,
  /** 目的地元素 */
  indexB: number,
) => {
  if (indexA === indexB) {
    return arr;
  }
  const targetIndex = indexB;

  const tempArr = [...arr];
  const tempA = arr[indexA];
  tempArr.splice(indexA, 1);

  tempArr.splice(targetIndex, 0, tempA);

  return tempArr;
};
