import { ResponseWithData, UseApi } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_INVITE } from '../apis';

const defaultData = {
  url: '',
};

async function postProjectInvite(): Promise<ResponseWithData<{ url: string }>> {
  return axios
    .post(PROJECT_INVITE)
    .then((res) => res.data)
    .catch((err) => {
      return defaultData;
    });
}

export function usePostProjectInvite(
  options?: UseApi<ResponseWithData<{ url: string }>, any>,
) {
  return useMutation({
    mutationKey: ['post-project-invite'],
    mutationFn: () => postProjectInvite(),
    onSuccess: (data, variables) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}
