import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Drawer as MuiDrawer,
} from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { FC, PropsWithChildren, useCallback } from 'react';

interface DrawerProps {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  autoClose?: boolean;
}

const DrawerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  position: 'relative',
  zIndex: 99,
  height: '100%',
}));

const DrawerTransitionStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ theme: ThemeOptions }>(({ theme, open }) => ({
  width: '360px',
  overflow: 'hidden',
  height: '100%',
  transition:
    theme?.transitions?.create &&
    theme.transitions.create('width', {
      easing: get(theme, 'transitions.easing.sharp'),
      duration: get(theme, 'transitions.duration.enteringScreen'),
    }),
  boxSizing: 'border-box',
  ...(!open && {
    overflowX: 'hidden',
    transition:
      theme?.transitions?.create &&
      theme.transitions.create('width', {
        easing: get(theme, 'transitions.easing.sharp'),
        duration: get(theme, 'transitions.duration.leavingScreen'),
      }),
    width: 0,
  }),
  '& .MuiDrawer-paper': {
    border: 0,
    background: get(theme, 'palette.grey.50'),
    position: 'relative',
    whiteSpace: 'nowrap',
    boxShadow: get(theme, 'palette.shadows.6'),
  },
}));

const IconWrapperStyled = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
}));

export const Drawer: FC<PropsWithChildren<DrawerProps>> = ({
  children,
  open,
  autoClose = false,
  handleClose,
  handleOpen,
}: PropsWithChildren<DrawerProps>) => {
  const handleClickAway = useCallback(() => {
    if (autoClose) {
      handleClose();
    }
  }, [autoClose, handleClose]);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <DrawerStyled>
        <DrawerTransitionStyled anchor={'left'} variant="permanent" open={open}>
          {children}
        </DrawerTransitionStyled>
        <IconWrapperStyled>
          {open ? (
            <IconButton size="small" onClick={handleClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton size="small" onClick={handleOpen}>
              <ChevronRightIcon />
            </IconButton>
          )}
        </IconWrapperStyled>
      </DrawerStyled>
    </ClickAwayListener>
  );
};

export default Drawer;
