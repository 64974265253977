export const copyToClipboard = (
  text: string,
  containerElement = document.body, // modal 內沒辦法直接在 body 上 append child，此參數可傳入要 append child 的目標 element
): void => {
  const textarea = document.createElement('textarea');
  textarea.value = text;

  containerElement.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  containerElement.removeChild(textarea);
};
