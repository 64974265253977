import { GetSequenceResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { SEQUENCE } from '../apis';

async function getSequence(
  projectID: string,
  sequenceID: string,
): Promise<GetSequenceResponseType> {
  return axios
    .get(`${SEQUENCE}?projectId=${projectID}&sequenceId=${sequenceID}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return {};
    });
}

export function useGetSequence(projectID: string, sequenceID: string) {
  return useQuery<GetSequenceResponseType>({
    queryKey: ['get-sequence', { projectID, sequenceID }],
    queryFn: () => {
      if (projectID && sequenceID) {
        return getSequence(projectID, sequenceID);
      } else {
        return {} as GetSequenceResponseType;
      }
    },
  });
}
