import { GetCommentReplyConditionDataResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_DATA_REPLY_CONDITION } from '../apis';

const defaultData = {
  sent: 0,
  uniqSent: 0,
  delivered: 0,
  deliveredP: 0,
  uniqDelivered: 0,
  uniqDeliveredP: 0,
  opened: 0,
  openedP: 0,
  uniqOpened: 0,
  uniqOpenedP: 0,
  clicked: 0,
  clickedP: 0,
  uniqClicked: 0,
  uniqClickedP: 0,
};

async function getCommentReplyConditionData(
  projectID: string,
  commentReplyID: string,
  conditionID: string,
  channelID?: string,
): Promise<GetCommentReplyConditionDataResponse> {
  return axios
    .get(
      `${COMMENT_DATA_REPLY_CONDITION}?projectId=${projectID}&projectChannelId=${channelID}&commentReplyId=${commentReplyID}${
        conditionID ? `&conditionId=${conditionID}` : ''
      }`,
    )

    .then((res) => res.data.data)
    .catch(() => {
      return defaultData;
    });
}

export function useGetCommentReplyConditionData(
  projectID: string,
  commentReplyID: string,
  conditionID: string,
  channelID?: string,
) {
  return useQuery<GetCommentReplyConditionDataResponse>({
    queryKey: [
      'get-comment-reply-condition-data',
      { projectID, commentReplyID, conditionID, channelID },
    ],
    queryFn: () => {
      if (projectID && commentReplyID && conditionID && channelID) {
        return getCommentReplyConditionData(
          projectID,
          commentReplyID,
          conditionID,
          channelID,
        );
      } else {
        return defaultData;
      }
    },
  });
}

export default useGetCommentReplyConditionData;
