interface UseIsDrawerUpdateType {
  isDrawerDataUpdate: <T extends object>(objectA: T, objectB: T) => boolean;
}

export function useIsDrawerDataUpdate(): UseIsDrawerUpdateType {
  const isDrawerDataUpdate = <T extends object>(
    objectA: T,
    objectB: T,
  ): boolean => {
    const keysA = Object.keys(objectA);
    const keysB = Object.keys(objectB);

    if (keysA.length !== keysB.length) {
      return true;
    }

    for (const key of keysA) {
      if (objectA[key as keyof T] !== objectB[key as keyof T]) {
        return true;
      }
    }

    return false;
  };
  return {
    isDrawerDataUpdate,
  };
}

export default useIsDrawerDataUpdate;
