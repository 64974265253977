import { Dialog, TextField } from '@frontend/components/ui';
import { useGetProject, usePatchProject } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { isEmpty, trim } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function RenameProjectModal() {
  // Renamed to start with an uppercase letter
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const projectID = uiState.dataID;
  const [projectName, setProjectName] = useState('');
  const { data: project } = useGetProject(projectID);
  const { mutate: updateProject } = usePatchProject();
  const [error, setError] = useState(false);

  const handleConfirm = useCallback(() => {
    if (projectID && projectName && !isEmpty(trim(projectName))) {
      updateProject({ id: projectID as string, name: projectName });
      uiState.closeModal();
    } else {
      setError(true);
    }
  }, [projectID, projectName, uiState, updateProject]);

  useEffect(() => {
    if (project && uiState.modalType === ModalTypesEnum.RENAME_PROJECT) {
      setProjectName(project.projectName);
      setError(false);
    }
  }, [project, uiState.modalType]);

  return (
    <Dialog
      size="xs"
      title={t('dashboard.renameModal.title')}
      open={uiState.modalType === ModalTypesEnum.RENAME_PROJECT}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.ok')}
      closeBtn={false}
      handleClose={uiState.closeModal}
      handleConfirm={handleConfirm}
      uppercase
    >
      <TextField
        size="small"
        autoFocus
        fullWidth
        onFocus={() => setError(false)}
        label={t('dashboard.renameModal.labelBotName')}
        value={projectName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setProjectName(e.target.value)
        }
        helperText={error ? t('dashboard.renameModal.error') : ''}
        error={error}
      />
    </Dialog>
  );
}

export default RenameProjectModal;
