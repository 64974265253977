import { Typography } from '@frontend/components/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { memo } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface EditorInputProps extends PhoneInputProps {
  helperText?: string;
}

const PhoneInputWrapper = styled(Box)(({ theme }) => ({
  '& .flag-dropdown': {
    backgroundColor: `${theme.palette['grey']['white']} !important`,
    borderRight: '0 !important',
  },
  '& .react-tel-input .form-control': {
    width: '100% !important',
    height: '48px !important',
  },
}));

export const EditorInput = ({ helperText, ...props }: EditorInputProps) => {
  return (
    <PhoneInputWrapper>
      <PhoneInput country={'tw'} masks={{ tw: '..........' }} {...props} />
      <Typography
        variant="inherit"
        color="grey.500"
        sx={{ paddingLeft: '16px', fontSize: '12px' }}
      >
        {helperText}
      </Typography>
    </PhoneInputWrapper>
  );
};

export default memo(EditorInput);
