import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, BoxProps, styled } from '@mui/system';
import { useRef } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

export interface EditorFloatPanelProps {
  draggable?: boolean;
  direction?: 'row' | 'column';
  backgroundColor?: string;
  handleHover?: (target: string) => void;
  handleAdd?: (ref: HTMLElement) => void;
  handleDelete?: () => void;
  addButtonID?: string;
  deleteButtonID?: string;
}

interface ContainerStyledProps extends BoxProps {
  direction: 'row' | 'column';
  backgroundColor?: string;
}

const FloatPanelWrapperStyled = styled(Box)<{ direction: 'row' | 'column' }>(
  ({ theme, direction }) => ({
    position: 'absolute',
    left: `${direction === 'row' ? '0' : '-1.25em'}`,
    top: `${direction === 'row' ? '-1.25em' : '0'}`,
    paddingRight: '0.42em',
    paddingBottom: `${direction === 'row' ? '1.07em' : '0'}`,
  }),
);

const ContainerStyled = styled(Box)<ContainerStyledProps>(
  ({ theme, direction, backgroundColor }) => ({
    display: 'flex',
    width: 'fit-content',
    color: theme.palette['grey'][600],
    background: theme.palette?.['grey'][100],
    borderRadius: '2.57em',
    padding: '0.14em',

    '& > div': {
      display: 'flex',
      padding: '0.14em',
      borderRadius: '50%',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette['background']['black'][5],
      },
      '& > svg': {
        fontSize: '0.5em',
      },
    },

    flexDirection: direction,
    ...(backgroundColor && { backgroundColor }),
  }),
);

const DragHandle: React.ComponentClass<{
  handleHover?: (target: string) => void;
}> = SortableHandle(
  ({ handleHover }: { handleHover?: (target: string) => void }) => (
    <Box
      onMouseEnter={() => {
        handleHover && handleHover('drag');
      }}
      onMouseLeave={() => {
        handleHover && handleHover('');
      }}
    >
      <DragIndicatorIcon />
    </Box>
  ),
);

export const EditorFloatPanel = ({
  draggable,
  direction = 'row',
  backgroundColor,
  handleHover,
  handleDelete,
  handleAdd,
  addButtonID,
  deleteButtonID,
}: EditorFloatPanelProps) => {
  const addRef = useRef<HTMLElement>(null);

  const handleAddClick = () => {
    handleAdd && handleAdd(addRef.current as HTMLElement);
  };

  return (
    <FloatPanelWrapperStyled direction={direction}>
      <ContainerStyled direction={direction} backgroundColor={backgroundColor}>
        {draggable && <DragHandle handleHover={handleHover} />}
        {handleDelete && (
          <Box
            id={deleteButtonID}
            onClick={handleDelete}
            onMouseEnter={() => {
              handleHover && handleHover('delete');
            }}
            onMouseLeave={() => {
              handleHover && handleHover('');
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </Box>
        )}
        {handleAdd && (
          <Box
            id={addButtonID}
            ref={addRef}
            onClick={handleAddClick}
            onMouseEnter={() => {
              handleHover && handleHover('add');
            }}
            onMouseLeave={() => {
              handleHover && handleHover('');
            }}
          >
            <AddIcon />
          </Box>
        )}
      </ContainerStyled>
    </FloatPanelWrapperStyled>
  );
};

export default EditorFloatPanel;
