import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface InputFieldStyledType {
  $isFocus: boolean;
  $isHover: boolean;
}

export const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '12px',
}));

export const ItemWrapperStyled = styled(Box)<InputFieldStyledType>(
  ({ theme, $isFocus, $isHover }) => ({
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px',
    borderRadius: '8px',
    background: theme.palette['grey']['100'],
    border: `2px solid ${
      $isFocus || $isHover ? theme.palette['orange']['50'] : 'transparent'
    }}`,
  }),
);

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  wordBreak: 'break-word',
  background: theme.palette['grey']['100'],
}));

export const CategoryWrapperStyled = styled(Box)(({ theme }) => ({
  marginTop: '12px',
  padding: '2px 8px',
  borderRadius: '8px',
  width: '100%',
  background: theme.palette['bluegrey']['50'],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
}));
