import SvgIcon from '@mui/material/SvgIcon';
import { FC } from 'react';
import { LoadingIcon } from '../icons';
import './loading.css';

/* eslint-disable-next-line */
export interface LoadingProps {
  color?: string;
  sx?: object;
}

export const Loading: FC<LoadingProps> = (props: LoadingProps) => {
  return (
    <SvgIcon
      component={LoadingIcon}
      className="spin"
      sx={{ ...props.sx, '& > path': { fill: props?.color ?? 'initial' } }}
    />
  );
};

export default Loading;
