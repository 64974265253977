import { Box, styled } from '@mui/material';

export const SortableItemStyled = styled(Box)<{ $zoom: number }>(
  ({ theme, $zoom }) => ({
    position: 'relative',
    paddingBottom: '8px',
    width: '100%',
    fontSize: `${28 / $zoom}px`,
  }),
);
