import { uuid } from '@frontend/components/utils';
import {
  BroadcastContext,
  TextButtonCellType,
  TextButtonCellTypeEnum,
  TextButtonElementType,
} from '@frontend/sorghum/interface';
import { isEmpty } from 'lodash';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from 'react';

export const BroadcastCtx = createContext<BroadcastContext>(
  {} as BroadcastContext,
);

export const BroadcastProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [focusID, setFocusID] = useState('');
  const [elementList, setElementList] = useState<TextButtonElementType[]>([]);
  const [errorList, setErrorList] = useState([]);

  const getTargetElement = useCallback(
    (elementID: string) => {
      const filterElement = elementList.filter(
        (item) => item.elementId === elementID,
      );

      if (filterElement.length === 1) {
        return filterElement[0];
      } else {
        return {} as TextButtonElementType;
      }
    },
    [elementList],
  );

  const updateTextButtonData = useCallback(
    (elementID: string, value: TextButtonElementType) => {
      const itemIndex = elementList.findIndex(
        (item) => item.elementId === elementID,
      );

      if (itemIndex !== -1) {
        const newArray = [
          ...elementList.slice(0, itemIndex),
          value,
          ...elementList.slice(itemIndex + 1, elementList.length),
        ];
        setElementList(newArray);
      }
    },
    [elementList],
  );

  const updateCellListData = useCallback(
    (elementID: string, value: TextButtonCellType[]) => {
      const element = getTargetElement(elementID);

      if (!isEmpty(element)) {
        updateTextButtonData(elementID, {
          ...element,
          cells: value,
        });
      }
    },
    [getTargetElement, updateTextButtonData],
  );

  const updateCellData = useCallback(
    (elementID: string, cellID: string, value: TextButtonCellType) => {
      const element = getTargetElement(elementID);

      if (!isEmpty(element) && element?.cells) {
        const cellList = element?.cells;
        const itemIndex = element.cells.findIndex(
          (item) => item.cellId === cellID,
        );
        if (itemIndex !== -1) {
          const newArray = [
            ...cellList.slice(0, itemIndex),
            value,
            ...cellList.slice(itemIndex + 1, cellList.length),
          ];
          updateCellListData(elementID, newArray);
        }
      }
    },
    [getTargetElement, updateCellListData],
  );

  const addCell = (elementID: string) => {
    const newID = uuid();
    const newItem = {
      cellId: newID,
      type: TextButtonCellTypeEnum.REDIRECT_BUTTON,
      data: {
        title: '',
        flowId: '',
      },
    } as TextButtonCellType;
    setFocusID(newID);

    const element = getTargetElement(elementID);

    if (!isEmpty(element) && element?.cells) {
      updateCellListData(elementID, [...element.cells, newItem]);
    } else {
      updateCellListData(elementID, [newItem]);
    }
  };

  const deleteCell = (elementID: string, cellID: string) => {
    const element = getTargetElement(elementID);

    if (!isEmpty(element) && element?.cells) {
      const filteredData = element.cells.filter(
        (item) => item.cellId !== cellID,
      );
      updateCellListData(elementID, filteredData);
    }
  };

  return (
    <BroadcastCtx.Provider
      value={{
        focusID,
        setFocusID,
        elementList,
        setElementList,
        deleteCell,
        addCell,
        updateCellData,
        updateCellListData,
        updateTextButtonData,
        errorList,
        setErrorList,
      }}
    >
      {children}
    </BroadcastCtx.Provider>
  );
};
