import { Button, Tooltip } from '@frontend/components/ui';
import { FacebookPage } from '@frontend/sorghum/interface';
import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Box, Typography } from '../../index';

export interface FacebookPagesProps {
  pages: FacebookPage[] | undefined | null;
  connect: (targetID: string) => void;
  isConnectLoading: boolean;
}

export const FacebookPages: FC<FacebookPagesProps> = ({
  pages,
  connect,
  isConnectLoading,
}) => {
  const [t] = useTranslation();
  const [activeID, setActiveID] = useState<string>('');

  const handleButtonClick = useCallback((pageID: string) => {
    setActiveID(pageID)
    connect(pageID);
  }, [connect]);
  
  return (
    <Box mt="8px" mb="26px" height="192px" overflow="auto">
      <Box p="16px">
        {pages &&
          pages.map((page: FacebookPage) => {
            const isConnected = page.isLinked;
            return (
              <Box
                key={`pages_page_${page.pageId}`}
                p="20px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box flex="1">
                  <Avatar alt={page.pageId} src={page.picture} />
                </Box>
                <Box flex="2">
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {page.pageName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {page.projectName}
                  </Typography>
                </Box>
                <Tooltip
                  disabled={!isConnected}
                  placement="bottom-start"
                  title={<Box>{t('pages.isConnected')}</Box>}
                >
                  <div
                    style={{
                      ...(isConnected && {
                        cursor: 'not-allowed',
                      }),
                    }}
                  >
                    {/* 使用 box 會導致 tooltip 失效，原因不明 */}
                    <Button
                      loading={activeID === page.pageId && isConnectLoading}
                      loadingColor="white"
                      variant="contained"
                      color="primary"
                      disabled={isConnected || isConnectLoading}
                      onClick={() => {
                        handleButtonClick(page.pageId);
                      }}
                    >
                      <Trans i18nKey="pages.connect" />
                    </Button>
                  </div>
                </Tooltip>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default FacebookPages;
