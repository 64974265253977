/** 所有的元素類別 */
export enum ElementTypesEnum {
  BLOCK = 'BLOCK',
  ENTRY_POINT = 'ENTRY_POINT',
  TEXT_BUTTON = 'TEXT_BUTTON',
  TEXT_BUTTON_CELL = 'TEXT_BUTTON_CELL',
  SHARE_LINK = 'SHARE_LINK',
  CONDITION = 'CONDITION',
  CONDITION_CELL = 'CONDITION_CELL',
  ACTION = 'ACTION',
  ACTION_ELEMENT = 'ACTION_ELEMENT',
  RECURRING_NOTIFICATION = 'RECURRING_NOTIFICATION',
  IMAGE = 'IMAGE',
  GALLERY = 'GALLERY',
  GALLERY_IMAGE = 'GALLERY_IMAGE',
  COUPON = 'COUPON',
  COUPON_CELL = 'COUPON_CELL',
  NOTE = 'NOTE',
  COLLECT_USER_ANSWER = 'COLLECT_USER_ANSWER',
  OPTION_CELL = 'OPTION_CELL',
  JUMP_TO_FLOW = 'JUMP_TO_FLOW',
}

/** 最外層的元素類別 */
export enum BlockTypesEnum {
  BLOCK = 'BLOCK',
  SHARE_LINK = 'SHARE_LINK',
  CONDITION = 'CONDITION',
  COUPON = 'COUPON',
  ACTION = 'ACTION',
  JUMP_TO_FLOW = 'JUMP_TO_FLOW',
}

export enum TextButtonCellTypesEnum {
  URL = 'URL',
  BLOCK = 'BLOCK',
  PHONE = 'PHONE',
  FLOW = 'FLOW',
}

export enum NodeTypesEnum {
  BLOCK_NODE = 'BLOCK',
  ENTRY_POINT_NODE = 'ENTRY_POINT',
  CREATE_MENU_NODE = 'CREATE_MENU',
  SHARE_LINK_NODE = 'SHARE_LINK',
  COUPON_NODE = 'COUPON',
  CONDITION_NODE = 'CONDITION',
  ACTION_NODE = 'ACTION',
  NOTE_NODE = 'NOTE',
  JUMP_TO_FLOW_NODE = 'JUMP_TO_FLOW',
}

export enum ModalTypesEnum {
  DELETE = 'DELETE',
  DELETE_BLOCK = 'DELETE_BLOCK',
  DELETE_TEXT_BUTTON = 'DELETE_TEXT_BUTTON',
  DELETE_ELEMENT = 'DELETE_ELEMENT',
  WELCOME_MESSAGE_DISABLE = 'WELCOME_MESSAGE_DISABLE',
  PERSISTENT_MENU_DISABLE = 'PERSISTENT_MENU_DISABLE',
  BASIC_SETTING_TOGGLE = 'BASIC_SETTING_TOGGLE',
  ADD_PERSISTENT_MENU = 'ADD_PERSISTENT_MENU',
  SAVE_TEMPLATE = 'SAVE_TEMPLATE',
  ADD_COMMENT_AUTO_REPLY = 'ADD_COMMENT_AUTO_REPLY',
  COMMON = 'COMMON',
  CLOSE = 'CLOSE',
  CLOSE_UNSAVED_DRAWER = 'CLOSE_UNSAVED_DRAWER',
}

export enum DrawerTypesEnum {
  CONDITION_CELL = 'CONDITION_CELL',
  COUPON = 'COUPON',
  ACTION_ATTRIBUTE = 'ACTION_ATTRIBUTE',
  ACTION_SEQUENCE = 'ACTION_SEQUENCE',
  ACTION_EXPORT_GOOGLE_SHEET = 'ACTION_EXPORT_GOOGLE_SHEET',
  BOT_LINK = 'BOT_LINK',
  VIEW_TRIGGER = 'VIEW_TRIGGER',
  COLLECT_USER_ANSWER = 'COLLECT_USER_ANSWER',
  CLOSE = 'CLOSE',
}

export enum PublishErrorsEnum {
  BLOCK_EMPTY = 'BLOCK_EMPTY',
  TEXTAREA_LABEL_EMPTY = 'TEXTAREA_LABEL_EMPTY',
  ENTRY_POINT_EMPTY = 'ENTRY_POINT_EMPTY',
  // text-button cell 沒有填入文字
  TEXT_BUTTON_CELL_LABEL_EMPTY = 'TEXT_BUTTON_CELL_NO_TARGET_ID',
  // text-button cell 沒有指定連接目標
  TEXT_BUTTON_CELL_BLOCK_NO_TARGET_ID = 'TEXT_BUTTON_CELL_NO_TARGET_ID',
  // text-button cell 電話沒有填寫
  TEXT_BUTTON_CELL_PHONE_NO_NUMBER = 'TEXT_BUTTON_CELL_PHONE_NO_NUMBER',
  // text-button cell 電話格式不對
  TEXT_BUTTON_CELL_PHONE_WRONG_FORMAT = 'TEXT_BUTTON_CELL_PHONE_WRONG_FORMAT',
  // text-button cell url 沒有填寫
  TEXT_BUTTON_CELL_URL_EMPTY = 'TEXT_BUTTON_CELL_URL_EMPTY',
  // text-button cell url 格式不對
  TEXT_BUTTON_CELL_URL_WRONG_FORMAT = 'TEXT_BUTTON_CELL_URL_WRONG_FORMAT',
  // entry-point 一定要連接一個目標
  ENTRY_POINT_NO_TARGET = 'ENTRY_POINT_NO_TARGET',
  SHARE_LINK_TITLE_EMPTY = 'SHARE_LINK_TITLE_EMPTY',
  SHARE_LINK_TEXT_BUTTON_EMPTY = 'SHARE_LINK_TEXT_BUTTON_EMPTY',
  SHARE_LINK_IMAGE_EMPTY = 'SHARE_LINK_IMAGE_EMPTY',
  RECURRING_NOTIFICATION_IMAGE_EMPTY = 'RECURRING_NOTIFICATION_IMAGE_EMPTY',
  RECURRING_NOTIFICATION_TITLE_EMPTY = 'RECURRING_NOTIFICATION_TITLE_EMPTY',
  RECURRING_NOTIFICATION_TOPIC_EMPTY = 'RECURRING_NOTIFICATION_TOPIC_EMPTY',
  IMAGE_FILE_EMPTY = 'IMAGE_FILE_EMPTY',
  ACTION_SET_ATTRIBUTE_KEY_EMPTY = 'ACTION_SET_ATTRIBUTE_KEY_EMPTY',
  ACTION_SET_ATTRIBUTE_VALUE_EMPTY = 'ACTION_SET_ATTRIBUTE_VALUE_EMPTY',
  ACTION_REMOVE_ATTRIBUTE_VALUE_EMPTY = 'ACTION_REMOVE_ATTRIBUTE_VALUE_EMPTY',
  ACTION_SEQUENCE_EMPTY = 'ACTION_SEQUENCE_EMPTY',
  ACTION_SHEET_NOT_SET_YET = 'ACTION_SHEET_NOT_SET_YET',
  CONDITION_CATEGORY_TYPE_EMPTY = 'CONDITION_CATEGORY_TYPE_EMPTY',
  CONDITION_MATCH_NEXT_STEP_EMPTY = 'CONDITION_MATCH_NEXT_STEP_EMPTY',
  TEXT_BUTTON_CELL_FLOW_EMPTY = 'TEXT_BUTTON_CELL_FLOW_EMPTY',
  COUPON_VALUE_EMPTY = 'COUPON_VALUE_EMPTY',
  COUPON_SEND_SUCCESS_TARGET_EMPTY = 'COUPON_SEND_SUCCESS_TARGET_EMPTY',
  COUPON_SEND_EXHAUSTED_TARGET_EMPTY = 'COUPON_SEND_EXHAUSTED_TARGET_EMPTY',
  COUPON_SEND_LIMIT_TARGET_EMPTY = 'COUPON_SEND_LIMIT_TARGET_EMPTY',
  COUPON_SHOW_SUCCESS_TARGET_EMPTY = 'COUPON_SHOW_SUCCESS_TARGET_EMPTY',
  COUPON_SHOW_FAIL_TARGET_EMPTY = 'COUPON_SHOW_FAIL_TARGET_EMPTY',
  COUPON_REDEEM_SUCCESS_TARGET_EMPTY = 'COUPON_REDEEM_SUCCESS_TARGET_EMPTY',
  COUPON_REDEEM_FAIL_TARGET_EMPTY = 'COUPON_REDEEM_FAIL_TARGET_EMPTY',
  GALLERY_DATA_ERROR = 'GALLERY_DATA_ERROR',
  GALLERY_TITLE_EMPTY = 'GALLERY_TITLE_EMPTY',
  COLLECT_USER_ANSWER_TITLE_EMPTY = 'COLLECT_USER_ANSWER_TITLE_EMPTY',
  COLLECT_USER_ANSWER_ATTRIBUTE_EMPTY = 'COLLECT_USER_ANSWER_ATTRIBUTE_EMPTY',
  COLLECT_USER_ANSWER_OPTION_TITLE_EMPTY = 'COLLECT_USER_ANSWER_OPTION_TITLE_EMPTY',
  JUMP_TO_FLOW_TARGET_EMPTY = 'JUMP_TO_FLOW_TARGET_EMPTY',
  COLLECT_USER_ANSWER_NO_OPTION = 'COLLECT_USER_ANSWER_NO_OPTION',
}

export enum EntryPointsEnum {
  WELCOME_MESSAGE = 1,
  PERSISTENT_MENU = 2,
  DEFAULT_ANSWER = 3,
  CONVERSATION_STARTER = 4,
  BOT_LINK = 5,
}

export enum TextAreaLabelTypesEnum {
  LABEL = 'label',
  TEXT = 'text',
}

export enum PublishElementTypesEnum {
  IMAGE = 'image',
  SHARE_LINK = 'shareLink',
  BLOCK = 'block',
  BUTTON = 'button',
  TEXT_BUTTON = 'textButton',
  URL_BUTTON = 'urlButton',
  CALL_BUTTON = 'callButton',
  REDIRECT_BUTTON = 'redirectButton',
  RECURRING_NOTIFICATION = 'rn',
  ACTION_SET_ATTRIBUTE = 'setAttribute',
  ACTION_REMOVE_ATTRIBUTE = 'removeAttribute',
  ACTION_SUBSCRIBE_SEQUENCE = 'setSequence',
  ACTION_UNSUBSCRIBE_SEQUENCE = 'removeSequence',
  EXPORT_GOOGLE_SHEET = 'googleSheet',
  CONDITION = 'condition',
  COUPON_SEND = 'sendCoupon',
  COUPON_SHOW = 'showCoupon',
  COUPON_REDEEM = 'redeemCoupon',
  COUPON_CELL = 'couponRoute',
  GALLERY = 'gallery',
  COLLECT_USER_ANSWER = 'collectUserAnswer',
  OPTION_CELL = 'quickReply',
  CUSTOM_INPUT_CELL = 'customInput',
  JUMP_TO_FLOW = 'redirect',
}

export enum SymbolTypesEnum {
  TAG = 1,
  TOPIC = 2,
}

export enum ActionCellTypesEnum {
  SET = 'SET',
  REMOVE = 'REMOVE',
  SUBSCRIBE_SEQUENCE = 'SUBSCRIBE_SEQUENCE',
  UNSUBSCRIBE_SEQUENCE = 'UNSUBSCRIBE_SEQUENCE',
  EXPORT_GOOGLE_SHEET = 'EXPORT_GOOGLE_SHEET',
}

export enum CategoryValueTypesEnum {
  TAG = 1,
  RN_TOPIC = 2,
  RN_TITLE = 3,
  ATTRIBUTE = 4,
}

export enum Rules {
  IS = 1,
  IS_NOT = 2,
  ELSE = 3,
}

export enum CouponTypesEnum {
  SEND = 'SEND',
  SHOW = 'SHOW',
  REDEEM = 'REDEEM',
}

export enum CouponCellTypesEnum {
  SUCCESS = 'SUCCESS',
  EXHAUSTED = 'EXHAUSTED',
  LIMIT = 'LIMIT',
  FAIL = 'FAIL',
}
