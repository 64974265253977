import { globalTheme } from '@frontend/components/external-providers';
import { useCanvasCollect } from '@frontend/editor/data-access';
import { PublishErrorsEnum } from '@frontend/editor/interface';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { Box, ClickAwayListener } from '@mui/material';
import { get } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditorTooltip from '../editor-tooltip/editor-tooltip';
import {
  EditorTooltipWrapper,
  InputAdornmentStyled,
  InputFieldStyled,
} from './styles';

const LINK_TITLE_LIMIT = 20;

interface NodeShareLinkUrlButtonProps {
  id: string;
  defaultValue: string;
  onChange: (newVal: string) => void;
}

export const NodeShareLinkUrlButton: FC<NodeShareLinkUrlButtonProps> = ({
  id,
  defaultValue,
  onChange,
}: NodeShareLinkUrlButtonProps) => {
  const [t] = useTranslation();
  const [value, setValue] = useState<string>(defaultValue);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { getErrorStatus, getErrorStatusAfterPublished } = useCanvasCollect();

  const publishError = !!getErrorStatusAfterPublished(
    id,
    PublishErrorsEnum.SHARE_LINK_TEXT_BUTTON_EMPTY,
  );

  const handleClickAway = useCallback(() => {
    setIsFocus(false);
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.currentTarget.value);
      if (onChange) {
        onChange(e.currentTarget.value);
      }
    },
    [onChange],
  );

  const setEmoji = useCallback(
    (emoji: string) => {
      setValue(value + emoji);
      if (onChange) {
        onChange(value + emoji);
      }
    },
    [onChange, value],
  );

  useEffect(() => {
    setIsError(publishError);
  }, [publishError]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <InputFieldStyled
          onChange={handleChange}
          value={value}
          inputProps={{ maxLength: LINK_TITLE_LIMIT }}
          $focus={isFocus}
          onClick={() => setIsFocus(true)}
          onBlur={() =>
            setIsError(
              !!getErrorStatus(
                id,
                PublishErrorsEnum.SHARE_LINK_TEXT_BUTTON_EMPTY,
              ),
            )
          }
          $error={isError}
          InputProps={{
            startAdornment: (
              <InputAdornmentStyled position="start">
                <AddLinkIcon
                  sx={{ color: get(globalTheme, 'palette.secondary.dark', '') }}
                />
              </InputAdornmentStyled>
            ),
          }}
          placeholder={isFocus ? '' : t('canvas.shareLink.urlButton')}
        />
        {isFocus && (
          <EditorTooltipWrapper>
            <EditorTooltip
              limit={LINK_TITLE_LIMIT}
              length={value.length}
              hasLabel={false}
              setEmoji={setEmoji}
            />
          </EditorTooltipWrapper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default NodeShareLinkUrlButton;
