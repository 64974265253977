import {
  ChipEditorChipType,
  ChipEditorSelectOptionType,
} from '@frontend/components/interface';
import { uuid } from '@frontend/components/utils';
import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Box } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../dialog/dialog';
import Tooltip from '../tooltip/tooltip';
import Typography from '../typography/typography';
import {
  AddChipButtonStyled,
  ChipEditorContainerStyled,
  ChipsContainerStyled,
  ChipsStyled,
  TextFieldStyled,
} from './styles';

/*** ChipEditor Spec（整理中）
 *  1. 可選擇一個 autocomplete 或兩個
 *  2. 第二個 autocomplete update 會連同第一個 autocomplete 的值帶回去
 ***/

//FIXME: type，移到 chip editor interface
export interface ChipEditorProps {
  firstOptionList?: { label: string; value: string }[];
  secondOptionList?: { label: string; value: string }[];
  tagList: ChipEditorChipType[];
  setTagList?: (tagList: ChipEditorChipType[]) => void;
  readonly?: boolean;
  hasSecondValue?: boolean;
  addButtonText?: string;
  isAllowFirstSelectAddOption?: boolean;
  isAllowSecondSelectAddOption?: boolean;
  isNeedConfirmDelete?: boolean;
  onUpdateFirstChipValue?: (
    chipID: string,
    props: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => void;
  onUpdateSecondChipValue?: (
    chipID: string,
    props: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => void;
  chipBackgroundColor?: string;
  chipBorderColor?: string;
  chipFirstTextColor?: string;
  chipSecondTextColor?: string;
  cancelButtonColor?: string;
  username?: string;
  disableOptionTooltip?: string;
  chipTooltipLabelName?: string;
  chipTooltipValueName?: string;
}

interface ChipContentType {
  setTagList?: (tagList: ChipEditorChipType[]) => void;
  tagID: string;
  tagList: ChipEditorChipType[];
  hasSecondValue?: boolean;
  readonly?: boolean;
  isNewAdded?: boolean;
  tagData: ChipEditorChipType;
  firstOptionList?: { label: string; value: string }[];
  secondOptionList?: { label: string; value: string }[];
  isAllowFirstSelectAddOption?: boolean;
  isAllowSecondSelectAddOption?: boolean;
  isNeedConfirmDelete?: boolean;
  setIsOpenConfirmDeleteModal: (isOpen: boolean) => void;
  setTagID: (tagID: string) => void;
  onUpdateFirstChipValue?: (
    chipID: string,
    props: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => void;
  handleDeleteTag: (id: string) => void;
  onUpdateSecondChipValue?: (
    chipID: string,
    props: ChipEditorSelectOptionType,
    type?: 'add' | 'delete',
  ) => void;
  chipBackgroundColor?: string;
  chipFirstTextColor?: string;
  chipSecondTextColor?: string;
  cancelButtonColor?: string;
  disableOptionTooltip?: string;
  chipTooltipLabelName?: string;
  chipTooltipValueName?: string;
}

const ChipTooltipText = ({
  labelText,
  valueText = '',
  label,
  value = '',
  updateTime,
}: {
  labelText: string;
  valueText?: string;
  label: string;
  value?: string;
  updateTime: string;
}) => {
  return (
    <Box>
      <span>
        {labelText}: {label}
      </span>
      <br />
      {valueText && value && (
        <>
          <span>
            {valueText}: {value}
          </span>
          <br />
        </>
      )}
      <span>
        {'Last updated'}: {updateTime}
      </span>
    </Box>
  );
};

const ChipContent = ({
  setTagList,
  tagID,
  tagList,
  hasSecondValue = false,
  readonly = false,
  isNewAdded = false, // 新增的 tag，要和 API 的 newTag（新的 tagOption） 做區別
  tagData,
  firstOptionList,
  secondOptionList,
  isAllowFirstSelectAddOption,
  isAllowSecondSelectAddOption,
  isNeedConfirmDelete = false,
  setIsOpenConfirmDeleteModal,
  setTagID,
  onUpdateFirstChipValue,
  handleDeleteTag,
  onUpdateSecondChipValue,
  chipBackgroundColor = '',
  chipFirstTextColor = '',
  chipSecondTextColor = '',
  cancelButtonColor = '',
  disableOptionTooltip = '',
  chipTooltipLabelName = '',
  chipTooltipValueName = '',
}: ChipContentType) => {
  const [t] = useTranslation();

  const secondInputRef = useRef<HTMLInputElement>(null);
  const firstInputRef = useRef<HTMLInputElement>(null);

  const [isDisableInput, setIsDisableInput] = useState(false);
  const [optionSelectStatus, setOptionSelectStatus] = useState({
    first: false,
    second: false,
  });

  const [firstInputWidth, setFirstInputWidth] = useState(0);
  const [isOpenFirstSelect, setIsOpenFirstSelect] = useState(false);
  const [firstInputValue, setFirstInputValue] = useState('');
  const [firstSelectValue, setFirstSelectValue] =
    useState<ChipEditorSelectOptionType>({ label: '', value: '' });

  const [secondInputWidth, setSecondInputWidth] = useState(0);
  const [isOpenSecondSelect, setIsOpenSecondSelect] = useState(false);
  const [secondInputValue, setSecondInputValue] = useState('');
  const [secondSelectValue, setSecondSelectValue] =
    useState<ChipEditorSelectOptionType>({ label: '', value: '' });

  const PX_PER_CHAR = 11;

  const handleChangeOption = useCallback(
    (
      _: React.SyntheticEvent,
      option: ChipEditorSelectOptionType | null,
      index: number, // 第 1 或第 2 個 autocomplete
    ) => {
      if (!isEmpty(option) && option.label) {
        if (index === 1) {
          setFirstSelectValue(option);
          setFirstInputWidth(option.label.length * PX_PER_CHAR);

          if (onUpdateFirstChipValue) {
            onUpdateFirstChipValue(tagID, option, 'add');
          }

          setOptionSelectStatus({ ...optionSelectStatus, first: true });
          // 確保 onBlur 觸發時 optionSelectStatus 的 state 已更新
          setTimeout(() => {
            if (firstInputRef?.current) {
              firstInputRef?.current.blur();
            }
          }, 0);
        } else if (index === 2) {
          setSecondSelectValue(option);
          setSecondInputWidth(option.label.length * PX_PER_CHAR);

          if (onUpdateSecondChipValue) {
            onUpdateSecondChipValue(tagID, option, 'add');
          }

          setOptionSelectStatus({ ...optionSelectStatus, second: true });
          // 確保 onBlur 觸發時 optionSelectStatus 的 state 已更新
          setTimeout(() => {
            if (secondInputRef?.current) {
              secondInputRef?.current.blur();
            }
          }, 0);
        }
      }
    },
    [
      onUpdateFirstChipValue,
      onUpdateSecondChipValue,
      optionSelectStatus,
      tagID,
    ],
  );

  const onFirstSelectAddOptionClick = useCallback(() => {
    if (isAllowFirstSelectAddOption) {
      // 標示為新增的選項
      const newOptionValue = uuid();
      const newOption = {
        ...tagData,
        label: firstInputValue,
        value: newOptionValue,
        newTag: true,
      };

      setFirstInputValue(firstInputValue);
      setFirstSelectValue(newOption);
      setIsOpenFirstSelect(false);
      if (firstInputRef?.current) {
        firstInputRef?.current.blur();
      }
      if (onUpdateFirstChipValue) {
        onUpdateFirstChipValue(tagID, newOption, 'add');
      }
    }
  }, [
    firstInputValue,
    tagID,
    isAllowFirstSelectAddOption,
    onUpdateFirstChipValue,
    tagData,
  ]);

  const onSecondSelectAddOptionClick = useCallback(() => {
    if (isAllowSecondSelectAddOption) {
      // 標示為新增的選項
      const newOption = { ...tagData, label: secondInputValue, newTag: true };

      setSecondInputValue(secondInputValue);
      setSecondSelectValue(newOption);
      setIsOpenSecondSelect(false);
      if (secondInputRef?.current) {
        secondInputRef?.current.blur();
      }
      if (onUpdateSecondChipValue) {
        onUpdateSecondChipValue(tagID, newOption, 'add');
      }
    }
  }, [
    tagID,
    isAllowSecondSelectAddOption,
    onUpdateSecondChipValue,
    secondInputValue,
    tagData,
  ]);

  // 檢查如果沒選擇選項就跳出要刪除 tag
  const checkTagCompleteStatus = useCallback(
    (index: number) => {
      if (setTagList) {
        if (index === 1 || index === 2) {
          if (!optionSelectStatus.first) {
            const filteredList = tagList.filter((item) => item.id !== tagID);
            setTagList(filteredList);
          }
        }
        if (index === 2) {
          if (!optionSelectStatus.second) {
            const filteredList = tagList.filter((item) => item.id !== tagID);
            setTagList(filteredList);
          }
        }
      }
    },
    [optionSelectStatus, setTagList, tagID, tagList],
  );

  const formattedFirstOptionList: ChipEditorSelectOptionType[] = useMemo(() => {
    if (firstOptionList) {
      // format duplicated option
      const formattedList = firstOptionList.map((item) => {
        if (tagList.find((tag) => tag.label === item.label)) {
          return {
            ...item,
            isDisabled: true,
          };
        } else {
          return item;
        }
      });

      if (isAllowFirstSelectAddOption && firstInputValue) {
        if (formattedList.find((i) => i.label === firstInputValue)) {
          return formattedList;
        }
        return [
          {
            label: `+ ${t('components.autocomplete.add')} "${firstInputValue}"`,
            value: 'add option',
            onClick: onFirstSelectAddOptionClick,
          },
        ];
      } else {
        return formattedList || ([] as ChipEditorSelectOptionType[]);
      }
    } else {
      return [] as ChipEditorSelectOptionType[];
    }
  }, [
    firstInputValue,
    firstOptionList,
    isAllowFirstSelectAddOption,
    onFirstSelectAddOptionClick,
    t,
    tagList,
  ]);

  const formattedSecondOptionList: ChipEditorSelectOptionType[] =
    useMemo(() => {
      if (
        isAllowSecondSelectAddOption &&
        secondInputValue &&
        secondOptionList
      ) {
        if (secondOptionList.find((i) => i.label === secondInputValue)) {
          return secondOptionList;
        }
        return [
          {
            label: `+ ${t(
              'components.autocomplete.add',
            )} "${secondInputValue}"`,
            value: 'add option',
            onClick: onSecondSelectAddOptionClick,
          },
        ];
      } else {
        return secondOptionList || ([] as ChipEditorSelectOptionType[]);
      }
    }, [
      isAllowSecondSelectAddOption,
      onSecondSelectAddOptionClick,
      secondInputValue,
      secondOptionList,
      t,
    ]);

  if (isNewAdded) {
    return (
      <Tooltip
        placement="top"
        title={
          <ChipTooltipText
            labelText={chipTooltipLabelName}
            valueText={chipTooltipValueName}
            label={tagData.label}
            value={tagData?.attrValue ?? ''}
            updateTime={
              tagData.lastUpdated
                ? dayjs(tagData.lastUpdated).format('YYYY/MM/DD')
                : ''
            }
          />
        }
        disabled={!isDisableInput}
      >
        <ChipsStyled
          sx={{
            ...(chipBackgroundColor && {
              backgroundColor: `${chipBackgroundColor}`,
            }),
          }}
        >
          <Autocomplete
            size="small"
            disabled={isDisableInput || readonly}
            // 控制選單下拉的時機
            open={isOpenFirstSelect}
            onOpen={() => setIsOpenFirstSelect(true)}
            onClose={() => setIsOpenFirstSelect(false)}
            onFocus={() => setIsOpenFirstSelect(true)}
            onBlur={() => {
              setIsOpenFirstSelect(false);
              checkTagCompleteStatus(1);
            }}
            inputValue={firstInputValue}
            onInputChange={(_, newInputValue) => {
              setFirstInputValue(newInputValue);
            }}
            value={firstSelectValue}
            options={formattedFirstOptionList}
            onChange={(event, option: ChipEditorSelectOptionType | null) =>
              handleChangeOption(event, option, 1)
            }
            getOptionLabel={(option: ChipEditorSelectOptionType) =>
              option.label as string
            }
            componentsProps={{
              popper: {
                style: { width: 'fit-content' },
                placement: 'bottom-start',
              },
            }}
            renderOption={(props, option: ChipEditorSelectOptionType) => {
              return (
                <Tooltip
                  disabled={!option?.isDisabled ?? false}
                  placement="right"
                  title={disableOptionTooltip}
                >
                  <Box>
                    <li
                      key={`autocomplete_${option.value}_${props.tabIndex}}`}
                      {...props}
                      {...(option.onClick && {
                        onClick: option.onClick,
                      })}
                      style={{
                        ...(option?.isDisabled && {
                          cursor: 'not-allowed',
                          pointerEvents: 'none',
                        }),
                      }}
                    >
                      <Typography
                        variant="menuItem"
                        color={option?.isDisabled ? 'grey.400' : 'grey.900'}
                        sx={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {option.label}
                      </Typography>
                    </li>
                  </Box>
                </Tooltip>
              );
            }}
            renderInput={(params) => (
              <TextFieldStyled
                {...params}
                size="small"
                autoFocus
                inputRef={firstInputRef}
                onBlur={() => {
                  if (!hasSecondValue) {
                    setIsDisableInput(true);
                  }

                  if (secondInputRef?.current) {
                    secondInputRef?.current?.focus();
                  }
                }}
                placeholder="name"
                sx={{
                  fontSize: '14px',
                  width: `${firstInputWidth}px`,
                  input: {
                    color: chipFirstTextColor,
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: chipFirstTextColor,
                  },
                }}
              />
            )}
          />
          {hasSecondValue && (
            <Autocomplete
              size="small"
              disabled={isDisableInput || readonly}
              open={isOpenSecondSelect}
              onOpen={() => setIsOpenSecondSelect(true)}
              onClose={() => setIsOpenSecondSelect(false)}
              onFocus={() => setIsOpenSecondSelect(true)}
              onBlur={() => {
                setIsOpenSecondSelect(false);
                checkTagCompleteStatus(2);
              }}
              inputValue={secondInputValue}
              onInputChange={(_, newInputValue) => {
                setSecondInputValue(newInputValue);
              }}
              value={secondSelectValue}
              options={formattedSecondOptionList}
              onChange={(event, option: ChipEditorSelectOptionType | null) =>
                handleChangeOption(event, option, 2)
              }
              getOptionLabel={(option: ChipEditorSelectOptionType) =>
                option.label as string
              }
              componentsProps={{
                popper: {
                  style: { width: 'fit-content' },
                  placement: 'bottom-start',
                },
              }}
              renderOption={(props, option: ChipEditorSelectOptionType) => (
                <li
                  key={`autocomplete_${option.value}_${props.tabIndex}}`}
                  {...props}
                  {...(option.onClick && {
                    onClick: option.onClick,
                  })}
                >
                  <Typography
                    variant="menuItem"
                    color="grey.900"
                    sx={{ wordBreak: 'break-all' }}
                  >
                    {option.label}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextFieldStyled
                  {...params}
                  size="small"
                  inputRef={secondInputRef}
                  placeholder="value"
                  sx={{
                    width: `${secondInputWidth}px`,
                    input: {
                      color: chipSecondTextColor,
                    },
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: chipSecondTextColor,
                    },
                  }}
                  onBlur={() => setIsDisableInput(true)}
                />
              )}
            />
          )}
          {!readonly && (
            <CancelIcon
              sx={{ color: cancelButtonColor, marginLeft: '8px' }}
              onClick={() => {
                setTagID(tagID);
                if (handleDeleteTag) {
                  if (isNeedConfirmDelete) {
                    setIsOpenConfirmDeleteModal(true);
                  } else {
                    handleDeleteTag(tagID);
                  }
                }
              }}
            />
          )}
        </ChipsStyled>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        placement="top"
        title={
          <ChipTooltipText
            labelText={chipTooltipLabelName}
            valueText={chipTooltipValueName}
            label={tagData.label}
            value={tagData.value}
            updateTime={
              tagData.lastUpdated
                ? dayjs(tagData.lastUpdated).format('YYYY/MM/DD')
                : ''
            }
          />
        }
      >
        <ChipsStyled
          className="with-delete-icon"
          $backgroundColor={chipBackgroundColor}
        >
          <Typography variant="body2" color={chipFirstTextColor}>
            {tagData.label}
          </Typography>
          <Typography
            variant="body2"
            color={chipSecondTextColor}
            sx={{ ml: 1 }}
          >
            {tagData.value}
          </Typography>

          <CancelIcon
            sx={{ color: cancelButtonColor, marginLeft: '8px' }}
            onClick={() => {
              setTagID(tagID);
              if (handleDeleteTag) {
                if (isNeedConfirmDelete) {
                  setIsOpenConfirmDeleteModal(true);
                } else {
                  handleDeleteTag(tagID);
                }
              }
            }}
          />
        </ChipsStyled>
      </Tooltip>
    );
  }
};

export function ChipEditor({
  firstOptionList,
  secondOptionList,
  readonly = false,
  tagList,
  setTagList,
  hasSecondValue = false,
  addButtonText = '',
  isAllowFirstSelectAddOption = false,
  isAllowSecondSelectAddOption = false,
  isNeedConfirmDelete = false,
  onUpdateFirstChipValue,
  onUpdateSecondChipValue,
  chipBackgroundColor = '',
  chipFirstTextColor = '',
  chipSecondTextColor = '',
  chipBorderColor = '',
  cancelButtonColor = '',
  username = '',
  disableOptionTooltip = '',
  chipTooltipLabelName = '',
  chipTooltipValueName = '',
}: ChipEditorProps) {
  const [t] = useTranslation();

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] =
    useState(false);
  const [tagID, setTagID] = useState('');

  const onAddChip = () => {
    const chipId = uuid();
    if (setTagList) {
      setTagList([
        { id: chipId, label: '', value: '', newAdded: true },
        ...tagList,
      ]);
    }
  };

  const handleDeleteTag = useCallback(
    (tagID: string) => {
      const deletedTag = tagList.find((item) => item.id === tagID);

      if (deletedTag) {
        const filteredList = tagList.filter((item) => item.id !== tagID);

        if (setTagList) {
          setTagList(filteredList);
        }

        if (!hasSecondValue) {
          if (onUpdateFirstChipValue) {
            onUpdateFirstChipValue(tagID, deletedTag, 'delete');
          }
        } else {
          if (onUpdateSecondChipValue) {
            onUpdateSecondChipValue(tagID, deletedTag, 'delete');
          }
        }
      }
    },
    [
      hasSecondValue,
      onUpdateFirstChipValue,
      onUpdateSecondChipValue,
      setTagList,
      tagList,
    ],
  );

  const selectedTagName = (tagID: string) => {
    const selectedTag = tagList.find((item) => item.id === tagID);

    if (selectedTag) {
      return `${selectedTag.label} ${
        selectedTag?.attrValue ? `:${selectedTag?.attrValue}` : ''
      }`;
    }
    return '';
  };

  return (
    <>
      <ChipEditorContainerStyled>
        {readonly ? (
          <ChipsContainerStyled>
            {tagList &&
              tagList.map((item) => (
                <Tooltip
                  key={item.id}
                  placement="top"
                  title={
                    <ChipTooltipText
                      labelText={chipTooltipLabelName}
                      label={item.label}
                      updateTime={dayjs(item.lastUpdated).format('YYYY/MM/DD')}
                    />
                  }
                >
                  <ChipsStyled
                    sx={{
                      ...(chipBackgroundColor && {
                        backgroundColor: `${chipBackgroundColor}`,
                      }),
                      ...(chipBorderColor && {
                        borderColor: `${chipBorderColor}`,
                      }),
                    }}
                  >
                    <Typography variant="body2" color={chipFirstTextColor}>
                      {item.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={chipSecondTextColor}
                      sx={{ ml: 1 }}
                    >
                      {item.value && item.value}
                    </Typography>
                  </ChipsStyled>
                </Tooltip>
              ))}
          </ChipsContainerStyled>
        ) : (
          <>
            <AddChipButtonStyled variant="outlined" dash onClick={onAddChip}>
              {addButtonText}
            </AddChipButtonStyled>
            <ChipsContainerStyled>
              {tagList.map((item) => (
                <ChipContent
                  key={item.id}
                  setTagList={setTagList}
                  tagList={tagList}
                  tagID={item.id}
                  hasSecondValue={hasSecondValue}
                  readonly={readonly}
                  isNewAdded={item?.newAdded ?? false}
                  tagData={item}
                  firstOptionList={firstOptionList}
                  isAllowFirstSelectAddOption={isAllowFirstSelectAddOption}
                  isAllowSecondSelectAddOption={isAllowSecondSelectAddOption}
                  isNeedConfirmDelete={isNeedConfirmDelete}
                  setIsOpenConfirmDeleteModal={setIsOpenConfirmDeleteModal}
                  setTagID={setTagID}
                  onUpdateFirstChipValue={onUpdateFirstChipValue}
                  onUpdateSecondChipValue={onUpdateSecondChipValue}
                  handleDeleteTag={handleDeleteTag}
                  secondOptionList={secondOptionList}
                  chipBackgroundColor={chipBackgroundColor}
                  chipFirstTextColor={chipFirstTextColor}
                  chipSecondTextColor={chipSecondTextColor}
                  cancelButtonColor={cancelButtonColor}
                  disableOptionTooltip={disableOptionTooltip}
                  chipTooltipLabelName={chipTooltipLabelName}
                  chipTooltipValueName={chipTooltipValueName}
                />
              ))}
            </ChipsContainerStyled>
          </>
        )}
      </ChipEditorContainerStyled>
      {/* FIXME: 之後要拉出去 */}
      <Dialog
        size="xs"
        color="error"
        title={t('people.detailModal.confirmDeleteModal.title')}
        content={t('people.detailModal.confirmDeleteModal.content', {
          username: username,
          tagName: selectedTagName(tagID),
        })}
        open={isOpenConfirmDeleteModal}
        handleClose={() => setIsOpenConfirmDeleteModal(false)}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.delete')}
        handleConfirm={() => {
          handleDeleteTag(tagID);
          setIsOpenConfirmDeleteModal(false);
        }}
        closeBtn={false}
      />
    </>
  );
}

export default ChipEditor;
