import { NodeContainerStyledProps } from '@frontend/editor/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface InputFieldStyledType {
  $isFocus?: boolean;
  $isHover?: boolean;
}

export const ContainerStyled = styled(Box)<NodeContainerStyledProps>(
  ({ theme, $focus }) => ({
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '11px',
    cursor: 'move',
    boxShadow:
      '0px 3px 6px rgba(69, 90, 100, 0.15), 0px 2px 3px rgba(69, 90, 100, 0.12)',
    ...($focus && { border: `2px solid ${theme.palette['purple']['600']}` }),
  }),
);

export const FloatPanelWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  paddingRight: '12px',
}));

export const FloatLeftPanelWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-35px',
  top: '0',
  paddingRight: '12px',
  zIndex: 99,
}));

export const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '2px 12px 12px 12px',
}));

export const ItemWrapperStyled = styled(Box)<InputFieldStyledType>(
  ({ theme, $isFocus, $isHover }) => ({
    position: 'relative',
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    borderRadius: '8px',
    background: theme.palette['grey']['100'],
    border: `2px solid ${
      $isFocus || $isHover ? theme.palette['purple']['50'] : 'transparent'
    }}`,
  }),
);

export const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 0 2px 8px',
}));

export const AttributeWrapperStyled = styled(Box)(({ theme }) => ({
  background: theme.palette['bluegrey'][50],
  color: theme.palette['primary']['main'],
  padding: '2px 0 2px 8px',
  borderRadius: '4px',
}));

export const CategoryWrapperStyled = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: '2px 0 2px 8px',
  borderRadius: '8px',
  width: '100%',
  background: theme.palette['bluegrey']['50'],
}));

export const SpanStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '100px',
  padding: '2px 10px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette['background']['black'][5],
}));
