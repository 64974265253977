import { FC } from 'react';
import { Checkbox } from '../checkbox/checkbox';
import { Typography } from '../typography/typography';

import { ColumnType } from './table';

export interface HeaderProps {
  id?: string;
  type: ColumnType;
  title: any;
}

export const Header: FC<HeaderProps> = ({ id, title, type }) => {
  switch (type) {
    case 'CHECKBOX': {
      return <Checkbox />;
    }
    default:
      return (
        <Typography id={id} variant="subtitle2">
          {title}
        </Typography>
      );
  }
};
