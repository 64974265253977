import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchCommentReplyConditionRequest } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_CONDITION } from '../apis';

async function patchCommentReplyCondition(
  props: PatchCommentReplyConditionRequest,
) {
  return axios
    .patch(COMMENT_REPLY_CONDITION, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchCommentReplyCondition() {
  return useMutation({
    mutationKey: ['patch-comment-reply-condition'],
    mutationFn: (props: PatchCommentReplyConditionRequest) =>
      patchCommentReplyCondition(props),
    onSuccess: (_, props: PatchCommentReplyConditionRequest) => {
      queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
      queryClient.invalidateQueries(['get-comment-reply-condition']);
    },
  });
}
