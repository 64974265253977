import { Dialog, Select, SelectItem, TextField } from '@frontend/components/ui';
import { usePostAdminFlowTemplate } from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { styled } from '@mui/system';
import { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../box/box';

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

type LanguageType = 'en_us' | 'zh_tw';

export const SaveTemplateModal: FC = () => {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);
  const editorState = useContext(EditorCtx);
  const { mutate: saveTemplate } = usePostAdminFlowTemplate();
  const [language, setLanguage] = useState<string>('en_us');
  const [templateType, setTemplateType] = useState<string>('0');
  const [description, setDescription] = useState<string>('');

  const handleConfirm = useCallback(() => {
    const chart = {
      data: editorState.elements,
      blockSerialNumber: editorState.blockSerialNumber,
    };
    saveTemplate({
      chartTemplateID: parseInt(templateType, 10),
      language: language as LanguageType,
      chart,
      description,
    });
    uiState.closeModal();
  }, [
    description,
    editorState.blockSerialNumber,
    editorState.elements,
    language,
    saveTemplate,
    templateType,
    uiState,
  ]);

  return (
    <Dialog
      size="xs"
      title="Save Template"
      open={uiState.modalType === ModalTypesEnum.SAVE_TEMPLATE}
      confirmBtnText="save"
      handleClose={uiState.closeModal}
      handleConfirm={handleConfirm}
    >
      <ModalContainerStyled>
        <Select
          size="small"
          label="Template"
          value={templateType}
          onChange={(e) => setTemplateType(e.target.value)}
        >
          <SelectItem value="0">{'Default'}</SelectItem>
          <SelectItem value="1">{t('canvas.modal.template.RN')}</SelectItem>
          <SelectItem value="2">
            {t('canvas.modal.template.autoReply')}
          </SelectItem>
          <SelectItem value="3">{t('canvas.modal.template.draw')}</SelectItem>
        </Select>
        <Select
          size="small"
          label="Language"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <SelectItem value="zh_tw">{t('dashboard.chinese')}</SelectItem>
          <SelectItem value="en_us">{t('dashboard.english')}</SelectItem>
        </Select>
        <TextField
          size="small"
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="description"
        />
      </ModalContainerStyled>
    </Dialog>
  );
};

export default SaveTemplateModal;
