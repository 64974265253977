import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  BlockTypesEnum,
  DrawerTypesEnum,
  ElementTypesEnum,
} from '@frontend/editor/interface';
import { isValueInEnum } from '@frontend/editor/utils';
import { useCallback, useContext } from 'react';
import { useReactFlow, useStoreApi, useViewport } from 'reactflow';

export interface UseCanvasView {
  onFocusID: string;
  onFocusCellID: string;
  onHoverCellID: string;
  onHoverLayer: number;
  focusNode: (nodeId: string, zoom?: number, duration?: number) => void;
  // 尋找 reactflow 內的 node id 並且 focus，如果找不到則回傳 false，代表沒有資料尚未被渲染
  focusNodeByReactFlowID: (nodeId: string) => boolean;
  focusEntryPoint: (zoom?: number) => void;
  focusOn: (id: string) => void;
  focusOnCell: (id: string) => void;
  onHoverElement: (id: string, layer: number) => void;
  setAllowCanvasMove: (isAllow: boolean) => void;
  handleDrawer: (drawerType: DrawerTypesEnum) => void;
}

const drawerWidth = 360;

// 操作畫布
export function useCanvasView(): UseCanvasView {
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const store = useStoreApi();
  const { setCenter } = useReactFlow();
  const { zoom, x: viewportX } = useViewport();

  const focusNode = useCallback(
    (nodeID: string, _zoom?: number, duration = 1000) => {
      let targetNodeID = nodeID;
      const element = state.getElement(nodeID);
      if (element && !isValueInEnum(element.elementType, BlockTypesEnum)) {
        targetNodeID = element.nodeID as string;
      }
      const { nodeInternals } = store.getState();
      const nodes = Array.from(nodeInternals).map(([, node]) => node);
      const node = nodes.find((i) => i.id === targetNodeID);
      if (node) {
        const width = node.width ? node.width / 2 : 0;
        const height = node.height ? node.height / 2 : 0;
        const x = node.position.x + width;
        const y = node.position.y + height;

        setCenter(x, y, { zoom: _zoom || zoom, duration });
        state.setOnFocusID(node.id);
      }
    },
    [store, setCenter, zoom, state],
  );

  const focusNodeByReactFlowID = useCallback(
    (targetNodeID: string) => {
      const { nodeInternals } = store.getState();
      const nodes = Array.from(nodeInternals).map(([, node]) => node);
      const node = nodes.find((i) => i.id === targetNodeID);

      if (node) {
        const width = node.width ? node.width / 2 : 0;
        const height = node.height ? node.height / 2 : 0;
        const x = node.position.x + width;
        const y = node.position.y + height;

        setCenter(x, y, { zoom, duration: 1000 });
        state.setOnFocusID(node.id);
        return true;
      } else {
        return false;
      }
    },
    [store, setCenter, zoom, state],
  );

  const focusEntryPoint = useCallback(
    (_zoom?: number) => {
      const target = state.elements.find(
        (el) => el.elementType === ElementTypesEnum.ENTRY_POINT,
      );
      if (target) {
        const { nodeInternals } = store.getState();
        const nodes = Array.from(nodeInternals).map(([, node]) => node);
        const node = nodes.find((i) => i.id === target.id);
        if (node) {
          const width = node.width ? node.width / 2 : 0;
          const height = node.height ? node.height / 2 : 0;
          const x = node.position.x + width;
          const y = node.position.y + height;

          setCenter(x, y, { zoom: _zoom || zoom, duration: 1000 });
          state.setOnFocusID(target.id);
        }
      }
    },
    [setCenter, state, store, zoom],
  );

  const handleFlowDraggable = useCallback(
    (isDrag: boolean) => {
      state.setIsFlowDraggable(isDrag);
    },
    [state],
  );
  const handleFlowPreventScrolling = useCallback(
    (isPrevent: boolean) => {
      state.setIsFlowPreventScrolling(isPrevent);
    },
    [state],
  );

  const handleFlowZoomOnScroll = useCallback(
    (isZoom: boolean) => {
      state.setIsFlowZoomOnScroll(isZoom);
    },
    [state],
  );

  const handleFlowZoomOnDoubleClick = useCallback(
    (isZoom: boolean) => {
      state.setIsFlowZoomOnDoubleClick(isZoom);
    },
    [state],
  );

  const setAllowCanvasMove = useCallback(
    (isAllow: boolean) => {
      handleFlowDraggable(isAllow);
      handleFlowPreventScrolling(isAllow);
      handleFlowZoomOnScroll(isAllow);
      handleFlowZoomOnDoubleClick(isAllow);
    },
    [
      handleFlowDraggable,
      handleFlowPreventScrolling,
      handleFlowZoomOnDoubleClick,
      handleFlowZoomOnScroll,
    ],
  );

  const focusOn = useCallback(
    (id: string) => {
      state.setOnFocusID(id);
    },
    [state],
  );

  const focusOnCell = useCallback(
    (id: string) => {
      state.setOnFocusCellID(id);
    },
    [state],
  );

  const handleDrawer = useCallback(
    (drawerType: DrawerTypesEnum) => {
      const { nodeInternals } = store.getState();
      const nodes = Array.from(nodeInternals).map(([, node]) => node);
      const node = nodes.find((i) => i.id === state.onFocusID);

      if (node) {
        // 如果開啟的 drawer 會蓋住目標 element 則移動畫面中心至可以顯示的位置，避免被開啟的 drawer 蓋住
        if (node.position.x + viewportX / zoom < drawerWidth / zoom) {
          const width = node.width ? node.width / 2 : 0;
          const height = node.height ? node.height / 2 : 0;
          // 移動到畫面中心稍微偏向右邊
          const x = node.position.x + width - 200 / zoom;
          const y = node.position.y + height;

          setCenter(x, y, { zoom, duration: 1000 });
        }
      }

      uiState.setDrawerType(drawerType);
    },
    [setCenter, state.onFocusID, store, uiState, viewportX, zoom],
  );

  const onHoverElement = useCallback(
    (id: string, layer: number) => {
      uiState.onHoverElement(id, layer);
      // hover 到 Element 時不可拖曳元素
      if (id && layer === 2) {
        state.setIsFlowDraggable(false);
      } else {
        state.setIsFlowDraggable(true);
      }
    },
    [state, uiState],
  );

  return {
    onFocusID: state.onFocusID,
    onFocusCellID: state.onFocusCellID,
    onHoverCellID: uiState.hoveredElement.id,
    onHoverLayer: uiState.hoveredElement.layer,
    focusOn,
    focusOnCell,
    focusEntryPoint,
    focusNode,
    setAllowCanvasMove,
    focusNodeByReactFlowID,
    onHoverElement,
    handleDrawer,
  };
}

export default useCanvasView;
