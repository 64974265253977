import { DragType, globalTheme } from '@frontend/components/external-providers';
import { FC, PropsWithChildren } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import Empty from './empty.svg';

interface Props extends PropsWithChildren {
  id?: string;
  selected: boolean;
  dropData?: any[];
}

const selectedRowStyle = {
  style: {
    background: globalTheme.palette?.background?.primary?.[5],
    cursor: 'grab',
  },
};

const Row: FC<Props> = ({ id, selected, children, dropData }: Props) => {
  const [_, drag, preview] = useDrag(
    () => ({
      type: DragType.ROW,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      item: { dropData },
    }),
    [dropData],
  );

  return (
    <>
      <DragPreviewImage connect={preview} src={Empty} />
      <tr
        id={id}
        {...(selected && { ref: drag })}
        {...(selected && selectedRowStyle)}
      >
        {children}
      </tr>
    </>
  );
};

export default Row;
