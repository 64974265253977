import { GetAccountTutorialResponse } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_TUTORIAL } from '../apis';

async function getGetAccountTutorial(): Promise<GetAccountTutorialResponse> {
  return axios
    .get(ACCOUNT_TUTORIAL)
    .then((res) => res.data.data)
    .catch((err) => ({
      code: 40000,
      msg: err,
    }));
}

export function useGetAccountTutorial(projectID: string) {
  return useQuery<GetAccountTutorialResponse>({
    queryKey: ['get-account-tutorial', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getGetAccountTutorial();
      } else {
        return {} as GetAccountTutorialResponse;
      }
    },
  });
}

export default useGetAccountTutorial;
