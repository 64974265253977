import {
  PostFlowTestResponse,
  PublishBlock,
  ResponseWithData,
} from '@frontend/editor/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW_TEST } from '../apis';

interface Props {
  flowId: string;
  blocks: PublishBlock[];
}

async function postPublishFlow(
  props: Props,
): Promise<ResponseWithData<PostFlowTestResponse>> {
  return axios
    .post(`${FLOW_TEST}`, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostFlowTest() {
  return useMutation({
    mutationKey: ['post-flow-test'],
    mutationFn: (flows: Props) => postPublishFlow(flows),
  });
}

export default usePostFlowTest;
