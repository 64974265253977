import { Button, Dialog, FlowIcon, Typography } from '@frontend/components/ui';
import {
  EditorMenuItemType,
  GetEditorMenuItemType,
  GetEditorMenuType,
  PersistentMenuPublishErrorsItemType,
} from '@frontend/sorghum/interface';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/ArrowForward';
import DragIcon from '@mui/icons-material/DragIndicator';
import { Box } from '@mui/material';
import { get, isUndefined } from 'lodash';
import { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortableHandle,
} from 'react-sortable-hoc';
import { PersistentMenuItem } from '../persistent-menu-item/persistent-menu-item';
import {
  ButtonWrapper,
  ContentWrapper,
  DividerStyled,
  DragIconStyled,
  FlowItemContainerStyled,
  FlowItemStyled,
  SortableItemStyled,
  TitleDescriptionContainer,
} from './styles';

interface PersistentMenuFacebookProps {
  formData: GetEditorMenuType;
  changeSort: (indexA: number, indexB: number) => void;
  onSave: () => void;
  isEditable: boolean;
  openDeleteModal: (index: number) => void;
  setIsOpenUnpublishedFlowModal: (val: boolean) => void;
  isOpenUnpublishedFlowModal: boolean;
  unpublishedFlows: any[];
  addNewMenuItem: () => void;
  publishErrors: PersistentMenuPublishErrorsItemType[];
  setFieldValue: (
    id: string | undefined,
    fieldName: string,
    value: string | null | number,
  ) => void;
  setItemData: (itemID: string, itemData: EditorMenuItemType) => void;
  onUpdateFlowID: (flowID: string, itemID: string | undefined) => void;
  getPublishStatus: (status: number) => string;
  publishStatus: number;
}

interface SortableItemProps {
  index: number;
  itemIndex: number;
  itemLength: number;
  menuItemData: GetEditorMenuItemType;
  titles: string[];
  changeSort: (indexA: number, indexB: number, before: boolean) => void;
  onSave: () => void;
  textFieldRef: MutableRefObject<null>;
  draggable?: boolean;
  isEditable: boolean;
  publishErrors: PersistentMenuPublishErrorsItemType[];
  openDeleteModal: (index: number) => void;
  setFieldValue: (
    id: string | undefined,
    fieldName: string,
    value: string | null | number,
  ) => void;
  setItemData: (itemID: string, itemData: EditorMenuItemType) => void;
  onUpdateFlowID: (flowID: string, itemID: string | undefined) => void;
}

interface SortableListProps {
  menuData: GetEditorMenuType;
  openDeleteModal: (index: number) => void;
  changeSort: (indexA: number, indexB: number) => void;
  onSave: () => void;
  textFieldRef: MutableRefObject<null>;
  isEditable: boolean;
  publishErrors: PersistentMenuPublishErrorsItemType[];
  setFieldValue: (
    id: string | undefined,
    fieldName: string,
    value: string | null | number,
  ) => void;
  setItemData: (itemID: string, itemData: EditorMenuItemType) => void;
  onUpdateFlowID: (flowID: string, itemID: string | undefined) => void;
}

const DragHandle = SortableHandle(() => <DragIcon />);

const SortableItem: React.ComponentClass<
  SortableElementProps & SortableItemProps
> = SortableElement(
  ({
    index,
    itemIndex,
    itemLength,
    menuItemData,
    titles,
    changeSort,
    onSave,
    textFieldRef,
    draggable,
    isEditable,
    publishErrors,
    openDeleteModal,
    setFieldValue,
    setItemData,
    onUpdateFlowID,
  }: SortableItemProps) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const togglePanelOpen = useCallback(() => {
      setIsPanelOpen(!isPanelOpen);
    }, [isPanelOpen]);

    return (
      <SortableItemStyled
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {(isHover || isPanelOpen) && draggable && (
          <DragIconStyled>
            <DragHandle />
          </DragIconStyled>
        )}

        <PersistentMenuItem
          isPanelOpen={isPanelOpen}
          togglePanelOpen={togglePanelOpen}
          index={itemIndex}
          itemLength={itemLength}
          menuItemData={menuItemData}
          titles={titles}
          changeSort={changeSort}
          textFieldRef={textFieldRef}
          isEditable={isEditable}
          publishErrors={publishErrors}
          openDeleteModal={openDeleteModal}
          setFieldValue={setFieldValue}
          setItemData={setItemData}
          onUpdateFlowID={onUpdateFlowID}
        />
      </SortableItemStyled>
    );
  },
);

const SortableList: React.ComponentClass<
  SortableContainerProps & SortableListProps
> = SortableContainer(
  ({
    menuData,
    openDeleteModal,
    changeSort,
    onSave,
    textFieldRef,
    isEditable,
    publishErrors,
    setFieldValue,
    setItemData,
    onUpdateFlowID,
  }: SortableListProps) => {
    const menuDataList = get(menuData, 'items', []);

    return (
      <Box>
        {menuDataList.map((item, index) => {
          const titles: string[] = [];
          menuDataList.forEach((i, menuIndex) => {
            if (index !== menuIndex && !isUndefined(i.title)) {
              titles.push(i.title);
            }
          });

          return (
            <SortableItem
              index={index}
              key={item.id}
              itemIndex={index}
              itemLength={menuData?.items?.length}
              draggable={menuData?.items?.length > 1}
              menuItemData={item}
              titles={titles}
              openDeleteModal={openDeleteModal}
              changeSort={changeSort}
              onSave={onSave}
              textFieldRef={textFieldRef}
              isEditable={isEditable}
              publishErrors={publishErrors}
              setFieldValue={setFieldValue}
              setItemData={setItemData}
              onUpdateFlowID={onUpdateFlowID}
            />
          );
        })}
      </Box>
    );
  },
);

export const PersistentMenuFacebook: FC<PersistentMenuFacebookProps> = ({
  formData,
  changeSort,
  onSave,
  isEditable,
  openDeleteModal,
  setIsOpenUnpublishedFlowModal,
  isOpenUnpublishedFlowModal,
  unpublishedFlows,
  addNewMenuItem,
  publishErrors,
  setFieldValue,
  setItemData,
  onUpdateFlowID,
  getPublishStatus,
  publishStatus,
}: PersistentMenuFacebookProps) => {
  const [t] = useTranslation();
  const textFieldRef = useRef(null);
  const { navigateToProjectPage } = usePath();

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    changeSort(oldIndex, newIndex);
  };

  return (
    <>
      <ContentWrapper>
        <TitleDescriptionContainer>
          <Typography variant="body2" color="grey.600">
            <Trans i18nKey="basicSetting.persistentMenu.content" />
          </Typography>
        </TitleDescriptionContainer>
        <Box sx={{ mt: '8px', mb: '8px' }}>
          {formData.items && (
            <SortableList
              useDragHandle
              onSortEnd={onSortEnd}
              openDeleteModal={openDeleteModal}
              changeSort={changeSort}
              onSave={onSave}
              menuData={formData}
              textFieldRef={textFieldRef}
              lockAxis="y"
              isEditable={isEditable}
              publishErrors={publishErrors}
              setFieldValue={setFieldValue}
              setItemData={setItemData}
              onUpdateFlowID={onUpdateFlowID}
            />
          )}

          {/* ADD Menu item 的按鈕 */}
          {formData.items && formData.items.length < 5 && (
            <ButtonWrapper>
              <Button
                onClick={() => {
                  addNewMenuItem();
                  if (textFieldRef !== null && textFieldRef.current !== null) {
                    //TODO: find a better way than setTimeout
                    setTimeout(() => {
                      if (textFieldRef.current) {
                        (textFieldRef.current as HTMLInputElement).focus();
                      }
                    }, 0);
                  }
                }}
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
                color="bluegrey300"
                dash
                size="medium"
                fullWidth
                disabled={!isEditable}
              >
                <Trans i18nKey="basicSetting.persistentMenu.addItem" />
              </Button>
            </ButtonWrapper>
          )}
        </Box>
        <Box sx={{ marginTop: '24px' }}>
          <Typography variant="body2" color="grey.600">
            <Trans i18nKey="basicSetting.persistentMenu.memo" />
          </Typography>
        </Box>
      </ContentWrapper>
      <Dialog
        size="xs"
        title={t('basicSetting.persistentMenu.flowUnpublishedModal.title', {
          status: getPublishStatus(publishStatus),
        })}
        handleClose={() => setIsOpenUnpublishedFlowModal(false)}
        content={t('basicSetting.persistentMenu.flowUnpublishedModal.content')}
        open={isOpenUnpublishedFlowModal}
      >
        <DividerStyled />
        <FlowItemContainerStyled className="flow-item-container">
          {unpublishedFlows.map((item) => (
            <FlowItemStyled
              className="flow-item"
              key={item.id}
              onClick={() => navigateToProjectPage(`${PAGE_FLOW}/${item.id}`)}
            >
              <Box className="flow-title">
                <FlowIcon />
                <Box>{item.name}</Box>
              </Box>
              <LinkIcon />
            </FlowItemStyled>
          ))}
        </FlowItemContainerStyled>
      </Dialog>
    </>
  );
};

export default PersistentMenuFacebook;
