import { ItemContainerStyledProps } from '@frontend/editor/interface';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StartWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '-35px',
  borderRadius: '100px 50px 0px 100px',
  border: `1px solid ${theme.palette?.['grey'][300]}`,
  width: '85px',
  color: theme.palette?.['grey'][800],
  background: theme.palette?.['grey'][200],
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '4px 12px 4px 10px',
}));

export const BodyStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'end',
  flexDirection: 'column',
  padding: '16px',
}));

export const EntryPointItemInlineWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexGrow: 1,
  '& > div': {
    marginRight: theme.spacing(1),
    '& > svg': {
      marginTop: theme.spacing(0.5),
    },
  },
}));

export const EntryPointItemTitleWrapperStyled = styled(Typography)(
  ({ theme }) => ({
    width: '180px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
);

export const SortableItemStyled = styled(Box)<{ $zoom: number }>(
  ({ theme, $zoom }) => ({
    position: 'relative',
    width: '100%',
    fontSize: `${28 / $zoom}px`,
  }),
);

export const ItemContainer = styled(Box)<ItemContainerStyledProps>(
  ({ theme, $focus }) => ({
    width: '100%',
    borderRadius: '8px',
    background: theme.palette['grey'][100],
    padding: '12px',
    marginTop: theme.spacing(1),
    border: '2px solid transparent',
    ...($focus && { border: `2px solid ${theme.palette['green']['200']}` }),
    wordBreak: 'break-word',

    '&: hover': {
      border: `2px solid ${theme.palette['green']['200']}`,
    },
  }),
);

export const ItemWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
}));

export const InfoWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  background: theme.palette['bluegrey'][50],
  padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
  marginTop: theme.spacing(1.5),
}));

export const SystemEntryPointInfo = styled(InfoWrapper)(({ theme }) => ({
  marginTop: 0,
}));

export const FloatPanelWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '-35px',
  paddingRight: '30px',
}));

export const SystemEntryPointItem = styled(Box)<{ $onFocus: boolean }>(
  ({ theme, $onFocus }) => ({
    marginTop: '12px',
    display: 'inline-flex',
    background: theme.palette['grey']['white'],
    width: '100%',
    borderRadius: '4px',
    border: `2px solid ${theme.palette['grey']['white']}`,
    padding: theme.spacing(1),
    alignItems: 'center',

    '&:hover': {
      border: `2px solid ${theme.palette['info']['main']}`,
    },

    ...($onFocus && {
      border: `2px solid ${theme.palette['green']['200']}`,
      '&:hover': {
        border: `2px solid ${theme.palette['green']['200']}`,
      },
    }),

    '& > svg': {
      marginRight: '12px',
    },
  }),
);

export const StatusEntryPointItemStyled = styled(SystemEntryPointItem)<{
  $status: boolean;
}>(({ theme, $status }) => ({
  ...(!$status && {
    border: `2px solid ${theme.palette['grey'][200]}`,
    background: theme.palette['grey'][200],
  }),
}));

export const SystemEntryPointItemLabelWrapperStyled = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '.link': {
      color: theme.palette['info']['main'],
      textDecoration: 'underline',
    },
  }),
);

export const ToolTipStyled = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

export const BotLinkWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
}));
