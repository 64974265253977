import { FC } from 'react';
import { ToastContainer } from 'react-toastify'; //ToastContainer
import 'react-toastify/dist/ReactToastify.css'; //CSS Style
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { globalTheme } from './ThemeProvider';

const CloseButton = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: auto;
  }
  .Toastify__toast {
    border: 0;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast--success {
    color: #00875a;
    background-color: #e9f7f2;
  }
  .Toastify__toast--success {
    color: ${globalTheme.palette?.success?.dark};
    background-color: ${globalTheme.palette?.success?.light};
    .Toastify__toast-icon svg {
      fill: ${globalTheme.palette?.success?.main};
    }
  }
  .Toastify__toast--warning {
    color: ${globalTheme.palette?.warning?.dark};
    background-color: ${globalTheme.palette?.warning?.light};
    .Toastify__toast-icon svg {
      fill: ${globalTheme.palette?.warning?.main};
    }
  }
  .Toastify__toast--error {
    color: ${globalTheme.palette?.error?.dark};
    background-color: ${globalTheme.palette?.error?.light};
    .Toastify__toast-icon svg {
      fill: ${globalTheme.palette?.error?.main};
    }
  }
  .Toastify__toast--info {
    color: ${globalTheme.palette?.info?.dark};
    background-color: ${globalTheme.palette?.info?.light};
    .Toastify__toast-icon svg {
      fill: ${globalTheme.palette?.info?.main};
    }
  }
`;

export const ToastifyProvider: FC = () => {
  return (
    <StyledToastContainer
      position="bottom-center"
      autoClose={5000}
      limit={3}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={
        <CloseButton>
          <CloseIcon fontSize="small" />
        </CloseButton>
      }
    />
  );
};
