import {
  AutocompleteV2,
  DateRangePicker,
  OptionTypeV2,
  Select,
  SelectItem,
} from '@frontend/components/ui';
import {
  useGetCategoryValue,
  useGetProjectID,
  useGetSequenceOption,
} from '@frontend/editor/data-access';
import {
  CategoryValueTypesEnum,
  SequenceOption,
} from '@frontend/editor/interface';
import {
  useGetAttributeKey,
  useGetBroadcastOption,
  useGetContactLocale,
} from '@frontend/sorghum/data-access';
import {
  DateType,
  FilterConditionTypeEnum,
  PeopleTableFilterConditionPropsType,
} from '@frontend/sorghum/interface';
import { convertToUTCString, dayjs } from '@frontend/sorghum/utils';
import { Box, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface PeopleTableFilterButtonConditionProps {
  value: PeopleTableFilterConditionPropsType;
  onChange: (value: PeopleTableFilterConditionPropsType) => void;
}
interface SelectProps {
  value: number | undefined;
  onChange: (val: number) => void;
}

const LogicSelect: React.FC<SelectProps> = ({ value, onChange }) => {
  const [t] = useTranslation();
  const logicValue = value ? value.toString() : '1';

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '1') {
        onChange(1);
      } else if (e.target.value === '2') {
        onChange(2);
      }
    },
    [onChange],
  );

  return (
    <Box width={70}>
      <Select fullWidth size="small" value={logicValue} onChange={handleChange}>
        <SelectItem value="1">{t('people.condition.is')}</SelectItem>
        <SelectItem value="2">{t('people.condition.isNot')}</SelectItem>
      </Select>
    </Box>
  );
};

export const PeopleTableFilterButtonCondition: FC<
  PeopleTableFilterButtonConditionProps
> = ({ value, onChange }: PeopleTableFilterButtonConditionProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: attributeData } = useGetAttributeKey(projectID as string);
  const { data: attributeValueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.ATTRIBUTE,
    attributeID: value.key,
  });
  const { data: tagValueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.TAG,
    attributeID: value.key,
  });

  const { data: sequences } = useGetSequenceOption({
    projectID: projectID as string,
  });

  const { data: broadcasts } = useGetBroadcastOption(projectID as string);

  const { data: locales } = useGetContactLocale();

  const updateDate = useCallback(
    (date: DateType) => {
      onChange({
        ...value,
        start: date.startDate?.utc().toISOString(),
        end: date.endDate?.utc().toISOString(),
      });
    },
    [onChange, value],
  );

  const updateOperator = useCallback(
    (val: number) => {
      onChange({
        ...value,
        operator: val,
      });
    },
    [onChange, value],
  );

  const updateKey = useCallback(
    (val: string) => {
      onChange({
        ...value,
        key: val,
        search: undefined,
      });
    },
    [onChange, value],
  );

  const updateSearch = useCallback(
    (val: string) => {
      onChange({
        ...value,
        search: val,
      });
    },
    [onChange, value],
  );

  const attributeOptions = useMemo(() => {
    const newOptions: OptionTypeV2[] = [];
    if (!isEmpty(attributeData) && attributeData && attributeData?.length > 0) {
      attributeData.forEach((item) => {
        newOptions.push({
          value: item.id,
          label: item.key,
        });
      });
    }
    return newOptions;
  }, [attributeData]);

  const attributeValueOptions = useMemo(() => {
    const newOptions: OptionTypeV2[] = [];
    if (attributeValueData) {
      attributeValueData.forEach((item) => {
        newOptions.push({
          value: item.value,
          label: item.value,
        });
      });
    }
    return newOptions;
  }, [attributeValueData]);

  const sequenceOptions = useMemo(() => {
    const newOptions: OptionTypeV2[] = [];
    if (sequences) {
      sequences.forEach((item: SequenceOption) => {
        newOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return newOptions;
  }, [sequences]);

  const tagValueOptions = useMemo(() => {
    const newOptions: OptionTypeV2[] = [];
    if (tagValueData) {
      tagValueData.forEach((item) => {
        newOptions.push({
          value: item.id,
          label: item.value,
        });
      });
    }
    return newOptions;
  }, [tagValueData]);

  const broadcastOptions = useMemo(() => {
    const newOptions: OptionTypeV2[] = [];
    if (broadcasts) {
      broadcasts.forEach((item) => {
        newOptions.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    return newOptions;
  }, [broadcasts]);

  const UTCOptions = useMemo(() => {
    const newOptions: OptionTypeV2[] = [];

    for (let i = -12; i <= 12; i++) {
      newOptions.push({
        value: i.toString(),
        label: convertToUTCString(i),
      });
    }

    return newOptions;
  }, []);

  switch (value.searchFilter) {
    case FilterConditionTypeEnum.ATTRIBUTE: {
      return (
        <>
          <Box width={200}>
            <AutocompleteV2
              onChange={(_, newValue) => updateKey(newValue?.value || '')}
              value={value.key}
              options={attributeOptions}
              placeholder={t('people.filters.placeholder.name')}
            />
          </Box>
          <LogicSelect value={value.operator} onChange={updateOperator} />
          <Box width={200}>
            <AutocompleteV2
              value={value.search}
              options={attributeValueOptions}
              placeholder={t('people.filters.placeholder.value')}
              onChange={(_, newValue) => updateSearch(newValue?.value || '')}
            />
          </Box>
        </>
      );
    }
    case FilterConditionTypeEnum.TAG: {
      return (
        <>
          <LogicSelect value={value.operator} onChange={updateOperator} />
          <Box width={400}>
            <AutocompleteV2
              value={value.key}
              options={tagValueOptions}
              placeholder={t('people.filters.placeholder.tag')}
              onChange={(_, newValue) => updateKey(newValue?.value || '')}
            />
          </Box>
        </>
      );
    }
    case FilterConditionTypeEnum.TOPIC:
      return (
        <Select
          fullWidth
          size="small"
          value={value.search || ''}
          onChange={(e) => updateSearch(e.target.value)}
        >
          <SelectItem value="subscribed">{t('common.subscribed')}</SelectItem>
          <SelectItem value="unsubscribed">
            {t('common.unsubscribed')}
          </SelectItem>
        </Select>
      );
    case FilterConditionTypeEnum.SEQUENCE:
      return (
        <>
          <LogicSelect value={value.operator} onChange={updateOperator} />
          <Box width={400}>
            <AutocompleteV2
              options={sequenceOptions}
              value={value.key}
              onChange={(_, newValue) => updateKey(newValue?.value || '')}
            />
          </Box>
        </>
      );
    case FilterConditionTypeEnum.BROADCAST:
      return (
        <>
          <Box width={200}>
            <AutocompleteV2
              options={broadcastOptions}
              value={value.key}
              onChange={(_, newValue) => updateKey(newValue?.value || '')}
            />
          </Box>
          <LogicSelect value={value.operator} onChange={updateOperator} />
          <Box width={200}>
            <Select
              fullWidth
              size="small"
              onChange={(e) => updateSearch(e.target.value)}
              value={value.search || ''}
            >
              <SelectItem value="delivered">
                {t('people.condition.delivered')}
              </SelectItem>
              <SelectItem value="opened">
                {t('people.condition.opened')}
              </SelectItem>
              <SelectItem value="clicked">
                {t('people.condition.clicked')}
              </SelectItem>
            </Select>
          </Box>
        </>
      );
    case FilterConditionTypeEnum.NAME:
    case FilterConditionTypeEnum.MESSENGER_ID:
      return (
        <TextField
          fullWidth
          size="small"
          value={value.search}
          onChange={(e) => updateSearch(e.target.value)}
        />
      );
    case FilterConditionTypeEnum.GENDER:
      return (
        <Select
          size="small"
          onChange={(e) => updateSearch(e.target.value)}
          value={value.search || ''}
          fullWidth
        >
          <SelectItem value="male">{t('common.gender.male')}</SelectItem>
          <SelectItem value="female">{t('common.gender.female')}</SelectItem>
        </Select>
      );
    case FilterConditionTypeEnum.LAST_ACTIVE:
    case FilterConditionTypeEnum.JOINED:
      return (
        <DateRangePicker
          date={{
            startDate: value.start
              ? dayjs(value.start)
              : dayjs().add(-30, 'day'),
            endDate: value.end ? dayjs(value.end) : dayjs(),
          }}
          setDate={updateDate}
          marginBetween={16}
          maxDate={dayjs().add(-1, 'day')}
        />
      );
    case FilterConditionTypeEnum.LOCALE:
      return (
        <Select
          size="small"
          onChange={(e) => updateSearch(e.target.value)}
          value={value.search || ''}
          fullWidth
        >
          {locales?.map((item) => (
            <SelectItem key={item} value={item}>
              {item}
            </SelectItem>
          ))}
        </Select>
      );
    case FilterConditionTypeEnum.TIME_ZONE:
      return (
        <Select
          size="small"
          onChange={(e) => updateSearch(e.target.value)}
          value={value.search || ''}
          fullWidth
        >
          {UTCOptions?.map((item) => (
            <SelectItem key={item.value} value={item.value as string}>
              {item.label}
            </SelectItem>
          ))}
        </Select>
      );
    default:
      return null;
  }
};

export default PeopleTableFilterButtonCondition;
