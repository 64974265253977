import { BaseElement } from '@frontend/editor/interface';
import {
  FlowChart,
  PublishStatus,
  Response,
} from '@frontend/sorghum/interface';
import { QueryClient, useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW } from '../apis';

interface ChartType {
  data: BaseElement[];
  blockSerialNumber: number;
}

async function patchUpdateFlow(
  flowID: string,
  chart: ChartType,
  projectID: string,
): Promise<Response> {
  return axios
    .patch(
      FLOW,
      {
        chart: {
          data: chart.data,
          blockSerialNumber: chart.blockSerialNumber,
        },
      },
      { params: { flowId: flowID, projectId: projectID } },
    )
    .then((res) => res.data);
}

export function usePatchFlow(
  flowID: string,
  queryClient: QueryClient,
  projectID: string,
  option?: any,
) {
  return useMutation({
    mutationKey: ['update-flow', { projectID, flowID }],
    mutationFn: (chart: ChartType) => patchUpdateFlow(flowID, chart, projectID),
    onSuccess: (data, variables) => {
      if (data.code === 20000) {
        if (option?.onSuccess) {
          option.onSuccess();
        }
        const query: FlowChart = queryClient.getQueryData([
          'flow',
          { flowID, projectID, isPublished: false },
        ]) as FlowChart;
        queryClient.setQueryData(
          ['flow', { projectID, flowID, isPublished: false }],
          {
            ...query,
            chart: {
              data: variables.data,
              blockSerialNumber: variables.blockSerialNumber,
            },
            publishStatus:
              query.publishStatus === PublishStatus.UNPUBLISH
                ? PublishStatus.UNPUBLISH
                : PublishStatus.UNPUBLISHED_CHANGES,
          },
        );
      }
      // queryClient.refetchQueries(['flow']);
    },
  });
}

export default usePatchFlow;
