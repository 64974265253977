import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import { resortList } from '@frontend/components/utils';
import {
  useCanvasCollect,
  useCanvasGet,
  useCanvasUpdate,
  useCanvasView,
  useDeleteEditorFlowEntry,
  useGetProjectID,
  useModal,
  usePatchEditorFlowEntryIndex,
} from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  DrawerTypesEnum,
  EntryPointBotLink,
  EntryPointsEnum,
  FlowEntryPoint,
  ResponseWithData,
} from '@frontend/editor/interface';
import { uuid } from '@frontend/editor/utils';
import { usePostEditorFlowEntry } from '@frontend/sorghum/data-access';
import {
  UsePostEditorFlowEntryProps,
  UsePostEditorFlowEntryResponse,
} from '@frontend/sorghum/interface';
import { PAGE_FLOW, usePath } from '@frontend/sorghum/utils';
import AddIcon from '@mui/icons-material/Add';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LinkIcon from '@mui/icons-material/Link';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Box,
  ClickAwayListener,
  Link,
  Popover,
  Typography,
} from '@mui/material';
import { get } from 'lodash';
import {
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NodeProps, Position } from 'reactflow';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorMenu, { EditorMenuItemList } from '../editor-menu/editor-menu';
import { HandlePoint } from '../handle-point/handle-point';
import AssistantNavigationIcon from '../icons/assistant-navigation-icon';
import { MessengerIcon } from '../icons/messenger-icon';
import { NodeHeader } from '../node-header/node-header';
import NodeEntryPointList from './node-entry-point-list';
import NodeSystemEntryPointList from './node-system-entry-point-list';
import { BodyStyled, StartWrapper } from './styles';

export const NodeEntryPoint = ({ id }: NodeProps) => {
  const { navigateToProjectPage } = usePath();
  const state = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const [t] = useTranslation();
  const { onFocusID, focusOn, handleDrawer } = useCanvasView();
  const { getTargetElement } = useCanvasGet();
  const { openAddPersistentMenuModal, openAddCommentAutoReplyModal } =
    useModal();
  const { data: projectID } = useGetProjectID();
  const { id: flowID } = useParams();
  const targetElement = getTargetElement(id);
  const targetID = get(targetElement, 'targetID', '');
  const outputID = get(targetElement, 'outputID', '');

  // add entry popper
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // 紀錄當下觸發的 entry point index，新增的時候才知道要插入哪裡
  const [entryIndex, setEntryIndex] = useState(0);
  const buttonRef = useRef<HTMLDivElement>(null);
  const open = Boolean(anchorEl);

  const { mutate: addEntryPoint } = usePostEditorFlowEntry(
    projectID as string,
    flowID as string,
    1,
    {
      onSuccess: (
        data: ResponseWithData<UsePostEditorFlowEntryResponse>,
        variables: UsePostEditorFlowEntryProps,
      ) => {
        if (data.code === 20000) {
          const newEntry = {
            id: data.data.id,
            type: variables.type,
          } as FlowEntryPoint;
          switch (variables.type) {
            case EntryPointsEnum.BOT_LINK: {
              (newEntry as EntryPointBotLink).ref = variables.ref as string;
              (newEntry as EntryPointBotLink).url = data.data.url as string;
              (newEntry as EntryPointBotLink).title = data.data.title as string;
              (newEntry as EntryPointBotLink).medium = data.data.medium;
              (newEntry as EntryPointBotLink).source = data.data.source;
              state.setOnFocusCellID(data.data.id);
              handleDrawer(DrawerTypesEnum.BOT_LINK);
            }
          }
          state.handleSetFlowEntryPoint([...state.flowEntryPoints, newEntry]);
        }
      },
    },
  );

  const { mutate: sortEntryPoint } = usePatchEditorFlowEntryIndex(
    id as string,
    1,
    projectID as string,
  );
  const { mutate: deleteEntryPoint } = useDeleteEditorFlowEntry(
    id as string,
    1,
    projectID as string,
  );

  const {
    entryPointMenu,
    flowEntryPoint: entryPoint,
    systemEntryPoint,
    broadcastEntryPoint,
    commentAutoReplyEntryPoint,
    redirectFlowEntryPoint,
    sequenceEntryPoint,
  } = useCanvasCollect();
  const { canvasUpdateFlowEntryPoint } = useCanvasUpdate();
  const { openDeleteConfirmModal } = useModal();

  const handleSort = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const newArray = resortList(entryPoint, oldIndex, newIndex);
      canvasUpdateFlowEntryPoint(newArray);
      sortEntryPoint({ entries: newArray.map((x) => x.id) });
    },
    [canvasUpdateFlowEntryPoint, entryPoint, sortEntryPoint],
  );

  const handleDelete = useCallback(
    (entryID: string) => {
      const confirmDelete = () => {
        canvasUpdateFlowEntryPoint(entryPoint.filter((i) => i.id !== entryID));
        deleteEntryPoint({ entryID });
      };
      openDeleteConfirmModal(confirmDelete);
    },
    [
      openDeleteConfirmModal,
      canvasUpdateFlowEntryPoint,
      entryPoint,
      deleteEntryPoint,
    ],
  );

  const goToFlow = (linkID: string | undefined) => {
    navigateToProjectPage(`${PAGE_FLOW}/${linkID}`);
  };

  const menuList: EditorMenuItemList[] = [
    {
      title: t('canvas.entryPoint.addMenu.title.growth'),
      dropdownItems: [
        ...entryPointMenu.growth.map((i) => {
          const ref = uuid();
          return {
            id: i.id,
            icon: <LinkIcon color="primary" />,
            title: t(`canvas.entryPoint.addMenu.${i.i18nKey}.title`),
            onClick: () =>
              addEntryPoint({
                type: i.type,
                ref,
                name: t('canvas.entryPoint.addMenu.botLink.defaultBotlinkName'),
                index: entryIndex + 1,
                // bot-link medium 預設值是 1
                ...(i.type === EntryPointsEnum.BOT_LINK && { medium: 1 }),
              }),
          };
        }),
        {
          id: 'option_comment_auto_reply',
          icon: <ChatOutlinedIcon color="primary" />,
          title: t('canvas.entryPoint.addMenu.commentAutoReply.title'),
          onClick: openAddCommentAutoReplyModal,
        },
      ],
    },
    {
      title: t('canvas.entryPoint.addMenu.title.basic'),
      dropdownItems: entryPointMenu.basic.map((i) => {
        return {
          id: i.id,
          icon: <MessengerIcon color="primary" />,
          title: t(`canvas.entryPoint.addMenu.${i.i18nKey}.title`),
          disabled: i.isUsed,
          ...(i.isUsed
            ? {
                tooltip: (
                  <Box>
                    {t(`canvas.entryPoint.addMenu.${i.i18nKey}.infoText`)}{' '}
                    <Link
                      variant="inherit"
                      color="infoOnTooltips.main"
                      onClick={() => goToFlow(i.flow.id)}
                    >
                      {i.flow.name}
                    </Link>
                  </Box>
                ),
              }
            : {
                onClick:
                  // PERSISTENT_MENU 是特例要打開提示 modal
                  i.type === EntryPointsEnum.PERSISTENT_MENU
                    ? openAddPersistentMenuModal
                    : () =>
                        addEntryPoint({ type: i.type, index: entryIndex + 1 }),
              }),
        };
      }),
    },
  ];

  return (
    <Box
      onClickCapture={() => {
        focusOn(id);
      }}
    >
      <HandlePoint
        id={outputID}
        type="source"
        position={Position.Right}
        styles={{
          top: '95%',
        }}
        isConnected={!!targetID}
        isFocus={onFocusID === id}
      />
      {/* NodeEntryPoint 上方的 start 標籤 */}
      <StartWrapper>
        <PlayCircleOutlineIcon color="success" fontSize="small" />
        <Typography variant="body2">{t('canvas.start')}</Typography>
      </StartWrapper>
      <EditorBlockBorder
        nodeID={id}
        color={get(globalTheme, 'palette.green.600', '')}
      >
        <NodeHeader
          background={globalTheme.palette?.['green'][50]}
          color={globalTheme.palette?.['green'][600]}
          icon={<AssistantNavigationIcon fontSize="small" />}
          title={t('canvas.entryPoint.title')}
          readonly
        />

        <BodyStyled>
          {uiState.entryDataType === 'ALL' && (
            <Typography
              variant="body2"
              color="grey.600"
              sx={{
                marginBottom: '10px',
              }}
            >
              {t('canvas.entryPoint.info')}
            </Typography>
          )}
          <ClickAwayListener
            onClickAway={() => uiState.setEntryDataType('ALL')}
          >
            <Box>
              {(systemEntryPoint.length > 0 ||
                broadcastEntryPoint.length > 0 ||
                redirectFlowEntryPoint.length > 0 ||
                sequenceEntryPoint.length > 0 ||
                commentAutoReplyEntryPoint.length > 0) && (
                <NodeSystemEntryPointList
                  items={systemEntryPoint}
                  broadcasts={broadcastEntryPoint}
                  commentAutoReplies={commentAutoReplyEntryPoint}
                />
              )}
              <NodeEntryPointList
                items={entryPoint}
                sortEntryPoint={handleSort}
                deleteEntryPoint={handleDelete}
                handleAdd={(ref: HTMLElement, index: number) => {
                  setAnchorEl(ref);
                  setEntryIndex(index);
                }}
              />
            </Box>
          </ClickAwayListener>
          {/* Add Entry Button */}
          {!state.readonly && (
            <Box
              sx={{
                marginTop: '8px',
              }}
              ref={buttonRef}
            >
              <Button
                id="add_trigger"
                onClick={() => {
                  setAnchorEl(
                    buttonRef.current as SetStateAction<HTMLElement | null>,
                  );
                  setEntryIndex(entryPoint.length - 1);
                }}
                startIcon={<AddIcon fontSize="small" />}
                variant="outlined"
                color="bluegrey300"
                dash
                size="medium"
                fullWidth
              >
                {t('canvas.entryPoint.addTrigger')}
              </Button>
            </Box>
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <EditorMenu itemList={menuList} />
          </Popover>
        </BodyStyled>
      </EditorBlockBorder>
    </Box>
  );
};

export default memo(NodeEntryPoint);
