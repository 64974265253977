import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Position } from 'reactflow';
import HandlePoint from '../handle-point/handle-point';

const OptionButtonContainerStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const InputStyled = styled(TextField)<{ $error: boolean }>(
  ({ theme, $error }) => ({
    width: '100%',
    backgroundColor: theme.palette['grey']['white'],
    borderRadius: '50px',
    ...($error && {
      border: `1px solid ${theme.palette['error']['main']}`,
    }),

    input: {
      textAlign: 'center',
    },

    fieldset: {
      border: 0,
    },
  }),
);

const TextBoxContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette['grey']['white'],
  borderRadius: '50px',
  padding: '8px 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const DataContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '100px',
  padding: '2px 10px',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette['background']['black'][5],
}));

export const OptionButton = ({
  className,
  onChange,
  id,
  value,
  error = false,
  readonly = false,
  cvrData,
  limit = 0,
}: {
  className?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  id: string;
  value: string;
  error?: boolean;
  readonly?: boolean;
  cvrData?: number;
  limit?: number;
}) => {
  const [t] = useTranslation();

  return (
    <OptionButtonContainerStyled className={className}>
      {readonly ? (
        <TextBoxContainerStyled>
          <Typography variant="body2">{value}</Typography>
          <DataContainerStyled>
            <Typography variant="caption" color="bluegrey.700">
              {cvrData ?? 0}%
            </Typography>
          </DataContainerStyled>
        </TextBoxContainerStyled>
      ) : (
        <InputStyled
          disabled={readonly}
          size="small"
          placeholder={t('components.optionButton.placeholder')}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          value={value}
          $error={error}
          inputProps={{ maxLength: limit }}
        />
      )}
      <HandlePoint
        id={id}
        type="source"
        position={Position.Right}
        styles={{ top: '50%' }}
      />
    </OptionButtonContainerStyled>
  );
};
