import { FacebookLoginPageProps } from '@frontend/sorghum/interface';
import { useLocation } from 'react-router-dom';
import { environment } from '../../environments/environment';
import { authorizations } from '../facebook-authorization';

interface useRoutesReturn extends FacebookLoginPageProps {
  key: string;
  googleStorageEndpoint: string;
}

export const useRoutes: () => useRoutesReturn = () => {
  const { mode, facebookAppID, googleStorageEndpoint } = environment;
  const authorizationString = authorizations.join(',');
  const { key } = useLocation();

  return {
    mode,
    facebookAppID,
    googleStorageEndpoint,
    permission: authorizationString,
    key,
  };
};
