import { TrendingStatusEnum } from '@frontend/components/interface';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export interface TrendingNumberProps {
  status: TrendingStatusEnum;
  percentage: number;
}

export const TrendingNumberStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette['bluegrey'][500],

  svg: {
    marginRight: '4px',
  },

  '&.down': {
    color: theme.palette['error']['main'],
  },

  '&.up': {
    color: theme.palette['success']['main'],
  },
}));

export const TrendingNumber = ({
  status,
  percentage,
  total,
}: {
  status: TrendingStatusEnum;
  percentage: number;
  total?: number;
}) => {
  switch (status) {
    case TrendingStatusEnum.UP:
      return (
        <TrendingNumberStyled className="up">
          <TrendingUpIcon sx={{ width: '16px', height: '16px' }} /> {percentage}
          % {total && `(${total})`}
        </TrendingNumberStyled>
      );
    case TrendingStatusEnum.DOWN:
      return (
        <TrendingNumberStyled className="down">
          <TrendingDownIcon sx={{ width: '16px', height: '16px' }} />
          {percentage}% {total && `(${total})`}
        </TrendingNumberStyled>
      );
    case TrendingStatusEnum.TIE:
      return (
        <TrendingNumberStyled>
          {percentage}% {total && `(${total})`}
        </TrendingNumberStyled>
      );
    default:
      return <TrendingNumberStyled>-</TrendingNumberStyled>;
  }
};

export default TrendingNumber;
