import { queryClient } from '@frontend/sorghum/external-providers';
import {
  FlowTemplateEnum,
  PostEditorFlowResponse,
  ResponseWithData,
  UseApi,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import i18next from 'i18next';
import { FLOW } from '../apis';

interface Props {
  name: string;
  chart?: object;
  chartTemplateID?: number;
  language?: string;
}

async function postEditorFlow(
  projectId: string,
  socialType: 1 | 2,
  name: string,
  chart?: object | null,
  chartTemplateID?: FlowTemplateEnum,
  language?: string,
): Promise<ResponseWithData<PostEditorFlowResponse>> {
  return axios
    .post(FLOW, {
      projectId,
      socialType,
      name,
      ...(chart && { chart }),
      ...(chartTemplateID && { chartTemplateId: chartTemplateID }),
      language: language || i18next.language,
    })
    .then((res) => res.data);
}

export function usePostEditorFlow(
  projectId: string,
  socialType: 1 | 2,
  options?: UseApi<ResponseWithData<PostEditorFlowResponse>, { name: string }>,
) {
  return useMutation({
    mutationKey: ['post-create-flow'],
    mutationFn: ({ name, chart, chartTemplateID, language }: Props) => {
      return postEditorFlow(
        projectId,
        socialType,
        name,
        chart
          ? {
              data: chart,
              blockSerialNumber: 1,
            }
          : null,
        chartTemplateID,
        language,
      );
    },
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['flow-list']);
      if (options?.onSuccess) {
        options.onSuccess(data, variables);
      }
    },
  });
}

export default usePostEditorFlow;
