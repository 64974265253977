import { GetCommentAutoReplyPost } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_POSTS } from '../apis';

async function getCommentAutoReplyPost(): Promise<GetCommentAutoReplyPost[]> {
  return axios
    .get(`${COMMENT_REPLY_POSTS}`)
    .then((res) => {
      if (res.data.code === 20000) {
        return res.data.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      return [];
    });
}

export function useGetCommentAutoReplyPost() {
  return useQuery<GetCommentAutoReplyPost[]>({
    queryKey: ['get-comment-auto-reply-post'],
    queryFn: getCommentAutoReplyPost,
  });
}
