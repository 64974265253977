import { IconSizeType } from '@frontend/components/interface';
import {
  AutocompleteWithNestedOptionType,
  AutocompleteWithNestedOptions,
  CompoundSelector,
  CompoundSelectorItemProps,
  IconButton,
  Select,
  SelectItem,
} from '@frontend/components/ui';
import { get, isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCategoryValue } from '@frontend/editor/data-access';
import { CategoryValueTypesEnum } from '@frontend/editor/interface';
import {
  useGetAttributeKey,
  usePostBroadcastContacts,
} from '@frontend/sorghum/data-access';
import {
  BroadcastConditionType,
  DraftDataType,
  GetAttributeKeyType,
} from '@frontend/sorghum/interface';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface ConditionRowType {
  index: number;
  conditionData: BroadcastConditionType;
  projectID: string;
  handleLogicOperatorChange: (index: number) => (e: any) => void;

  onDeleteCondition: (index: number) => void;
  fullDraftData: DraftDataType;
  setFullDraftData: (draftData: DraftDataType) => void;
  setTargetCount: (targetCount: number) => void;
}

export const TagValueSelectorStyled = styled(Box)(({ theme }) => ({
  width: 'calc((100% - 176px)* 0.5)',
  marginRight: '8px',
}));

export const ConditionContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: '16px',
}));

export const ClearConditionButtonStyled = styled(Box)(({ theme }) => ({
  marginLeft: '4px',
  marginTop: '5px',
}));

//FIXME: any
// 一整行 condition 的 component
export const ConditionRow = ({
  index,
  conditionData,
  projectID,
  fullDraftData,
  setFullDraftData,
  handleLogicOperatorChange,
  onDeleteCondition,
  setTargetCount,
}: ConditionRowType) => {
  const [t] = useTranslation();
  const [attrID, setAttrID] = useState(conditionData.key);
  const [isConditionValueEmpty, setIsConditionValueEmpty] = useState(false);

  /** dynamic data */
  // 拿 condition list Custom Attribute 底下的 attribute
  const { data: attributeData } = useGetAttributeKey(projectID as string);

  const { data: tagData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.TAG,
  });

  // attribute 的 value
  const { data: attributeValueData } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.ATTRIBUTE,
    attributeID: attrID,
  });

  const { mutate: postContacts } = usePostBroadcastContacts({
    onSuccess: (data) => {
      setTargetCount(data);
    },
  });

  const categoryList = (attrData: GetAttributeKeyType[] | undefined) => {
    const optionList: AutocompleteWithNestedOptionType[] = [
      {
        group: 'All',
        label: t('broadcast.draft.userTag'),
        value: 'userTag',
        exclude: true,
      },
    ];
    const formattedArray: AutocompleteWithNestedOptionType[] = [];
    if (!isEmpty(attrData)) {
      attrData?.map((item) =>
        formattedArray.push({
          value: item.id,
          label: item.key,
          group: t('broadcast.draft.customAttribute'),
        }),
      );

      return optionList.concat(formattedArray);
    }
    return optionList;
  };

  const draftDataConditionList: BroadcastConditionType[] = get(
    fullDraftData,
    'target.condition',
    [],
  );

  const CompoundSelectorOptions = useCallback(
    (filterType: number) => {
      if (filterType === 1) {
        if (!isEmpty(tagData)) {
          return (
            tagData?.map((i) => ({
              label: i.value,
              value: i.value,
            })) || ([] as CompoundSelectorItemProps[])
          );
        }
      } else {
        if (!isEmpty(attributeValueData)) {
          return (
            attributeValueData?.map((i) => ({
              label: i.value,
              value: i.value,
            })) || ([] as CompoundSelectorItemProps[])
          );
        }
      }
      return [] as CompoundSelectorItemProps[];
    },
    [tagData, attributeValueData],
  );

  const handleOnBlur = useCallback(() => {
    if (isEmpty(conditionData.value)) {
      setIsConditionValueEmpty(true);
    } else {
      setIsConditionValueEmpty(false);
    }
  }, [conditionData.value]);

  const handleFilterTypeChange = useCallback(
    (index: number) =>
      (_: object, newValue: AutocompleteWithNestedOptionType | null) => {
        if (!isEmpty(newValue)) {
          if (newValue.value !== 'userTag') {
            setAttrID(newValue.value as string);
            setFullDraftData({
              ...fullDraftData,
              target: {
                ...fullDraftData.target,
                condition: draftDataConditionList.map((cItem, cIndex) =>
                  cIndex === index
                    ? ({
                        ...cItem,
                        filterType: 2,
                        key: newValue.value,
                        value: '',
                      } as BroadcastConditionType)
                    : cItem,
                ),
              },
            });
          } else {
            setFullDraftData({
              ...fullDraftData,
              target: {
                ...fullDraftData.target,
                condition: draftDataConditionList.map((cItem, cIndex) =>
                  cIndex === index
                    ? {
                        ...cItem,
                        filterType: 1,
                      }
                    : cItem,
                ),
              },
            });
          }
        } else {
          // 點選叉叉時清除 filter 條件
          setAttrID('');
          setFullDraftData({
            ...fullDraftData,
            target: {
              ...fullDraftData.target,
              condition: draftDataConditionList.map((cItem, cIndex) =>
                cIndex === index
                  ? ({
                      ...cItem,
                      filterType: undefined,
                      value: '',
                    } as BroadcastConditionType)
                  : cItem,
              ),
            },
          });
        }
      },
    [draftDataConditionList, fullDraftData, setFullDraftData],
  );

  const handleConditionChange = useCallback(
    (index: number) =>
      (
        operator: CompoundSelectorItemProps | undefined,
        newValue: CompoundSelectorItemProps | undefined,
      ) => {
        setFullDraftData({
          ...fullDraftData,
          target: {
            ...fullDraftData.target,
            condition: draftDataConditionList.map((cItem, cIndex: number) =>
              cIndex === index
                ? ({
                    ...cItem,
                    value: newValue?.label,
                    compareType: operator?.value,
                  } as BroadcastConditionType)
                : cItem,
            ),
          },
        });

        if (operator && newValue) {
          postContacts({
            projectId: projectID as string,
            condition: fullDraftData.target?.condition ?? [],
          });
        }
      },
    [
      draftDataConditionList,
      fullDraftData,
      postContacts,
      projectID,
      setFullDraftData,
    ],
  );

  /** static data */
  const operatorOptions = [
    { label: t('broadcast.draft.is'), value: 1 },
    { label: t('broadcast.draft.isNot'), value: 2 },
  ];

  const convertCategory = (
    _filterType: number | undefined,
    data: BroadcastConditionType,
  ) => {
    if (_filterType === 1) {
      return 'userTag';
    } else if (_filterType === 2) {
      return data.key;
    } else {
      return '';
    }
  };

  return (
    <ConditionContainerStyled>
      <Select
        size="small"
        value={conditionData.logicalOperator ?? 1}
        sx={{
          width: '120px',
          marginRight: '8px',
        }}
        onChange={handleLogicOperatorChange(index)}
      >
        <SelectItem value={1}>
          {t('broadcast.draft.optionLogicalOperatorAnd')}
        </SelectItem>
        {/* <SelectItem value={2}>
          {t('broadcast.draft.optionLogicalOperatorOr')}
        </SelectItem> */}
      </Select>
      <Box
        sx={{
          marginRight: '8px',
          width: 'calc((100% - 176px)* 0.5)',
        }}
      >
        <AutocompleteWithNestedOptions
          required
          label={t('broadcast.draft.category')}
          value={convertCategory(conditionData.filterType, conditionData)}
          options={categoryList(attributeData)}
          onChange={handleFilterTypeChange(index)}
          isShowTooltip
        />
      </Box>
      {/* compare type */}
      <TagValueSelectorStyled>
        <CompoundSelector
          required
          label={t('broadcast.draft.value')}
          operatorValue={conditionData.compareType}
          value={conditionData.value}
          operatorOptions={operatorOptions}
          valueOptions={CompoundSelectorOptions(
            conditionData.filterType as number,
          )}
          onChange={handleConditionChange(index)}
          disabled={!conditionData.filterType}
          isShowTooltip
          error={isConditionValueEmpty}
          onBlur={handleOnBlur}
          helperText={
            isConditionValueEmpty ? t('broadcast.draft.errorValueEmpty') : ''
          }
        />
      </TagValueSelectorStyled>
      <ClearConditionButtonStyled>
        <IconButton
          $size={IconSizeType.XS}
          onClick={() => onDeleteCondition(index)}
        >
          <CloseIcon />
        </IconButton>
      </ClearConditionButtonStyled>
    </ConditionContainerStyled>
  );
};

export default ConditionRow;
