import { ChipEditorChipType } from '@frontend/components/interface';
import { ChipEditor, Typography } from '@frontend/components/ui';
import { SequenceItemType } from '@frontend/sorghum/interface';
import { PEOPLE_DETAIL_MODAL_SEQUENCE_SEE_DETAIL } from '@frontend/sorghum/utils';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface PeopleDetailSequenceTabProps {
  sequenceList: SequenceItemType[];
}

const SequenceTabContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 0',
}));

export function PeopleDetailSequenceTab({
  sequenceList,
}: PeopleDetailSequenceTabProps) {
  const [t] = useTranslation();

  const formattedSequenceList: ChipEditorChipType[] = sequenceList
    ? sequenceList.map((item) => ({
        id: '',
        label: item.name,
        value: '',
        lastUpdated: item.lastUpdated,
      }))
    : [];

  return (
    <SequenceTabContainerStyled>
      <Typography variant="body3">
        {t('people.detailModal.sequenceTab.description')}
        <Link href={PEOPLE_DETAIL_MODAL_SEQUENCE_SEE_DETAIL}>
          {t('people.detailModal.sequenceTab.seeDetails')}
        </Link>
      </Typography>
      <Box sx={{ marginTop: '16px' }}>
        <ChipEditor
          readonly
          tagList={formattedSequenceList}
          chipTooltipLabelName={t(
            'people.detailModal.sequenceTab.tooltipLabel',
          )}
        />
      </Box>
    </SequenceTabContainerStyled>
  );
}

export default PeopleDetailSequenceTab;
