import { globalTheme } from '@frontend/components/external-providers';
import {
  Divider,
  GroupDivider,
  Select,
  SelectItem,
  Switch,
  Tab,
  Tabs,
} from '@frontend/components/ui';
import {
  useCanvasCollect,
  useCanvasView,
  useGetEditorFlowEntry,
  useGetProjectID,
  useGetViewFlowEntry,
  usePostDataFlowEntryAll,
} from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import {
  DataFlowEntryAllData,
  DrawerTypesEnum,
  EntryPointBotLink,
  EntryPointBroadcast,
  EntryPointCommentAutoReply,
  EntryPointFlow,
  EntryPointSequence,
  EntryPointShareLink,
  EntryPointsEnum,
  FlowEntryPoint,
  GetViewFlowEntryResponse,
  PostDataFlowEntryAllResponse,
} from '@frontend/editor/interface';
import { convertNumberToK } from '@frontend/editor/utils';
import {
  VIEW_TRIGGER_DOCUMENT,
  VIEW_TRIGGER_DOCUMENT_EN,
  sendGAEvent,
} from '@frontend/sorghum/utils';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import i18next from 'i18next';
import { get, isNumber } from 'lodash';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import 'reactflow/dist/style.css';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import { MessengerIcon } from '../icons/messenger-icon';
import ViewDrawerBox from '../view-drawer-box/view-drawer-box';
import CompareEntryItem from './drawer-view-trigger-compare-entry-item';

enum TabTypesEnum {
  'OVERALL' = 0,
  'COMPARE_ENTRIES' = 1,
}

const emptyCompareEntryData: DataFlowEntryAllData = {};

interface ModeDataItemProps {
  title: string;
  tooltipText: string;
  firstData: number | string;
  firstDataP?: number;
  secondData: number | string;
  secondDataP?: number;
  isShowTooltip?: boolean;
  originFirstData?: number | undefined;
  originSecondData?: number | undefined;
}

interface ModeDataProps {
  data: GetViewFlowEntryResponse | undefined;
  type: number;
  t: TFunction<'translation', undefined>;
}

const ModeDataTitleWrapperStyled = styled(Box)(({ theme }) => ({
  width: '80px',
  whiteSpace: 'pre-wrap',
}));

const TooltipIconWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette['bluegrey'][300],
}));

const PercentTagStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0 8px',
  backgroundColor: `${theme.palette['primary']['main']}0D`,
  borderRadius: '4px',
  width: 'fit-content',
  marginTop: '4px',
}));

const DetailedModeDataItemStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  cursor: 'default',
  '&:hover': {
    backgroundColor: `${theme.palette['primary']['main']}0D`,
  },
}));

const DrawerDataWrapperStyled = styled(Box)(({ theme }) => ({
  width: '360px',
  padding: '32px',
  display: 'flex',
  flexDirection: 'column',
}));

const TopContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
}));

const TabInfoWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: '10px',
  gap: '4px',
  svg: {
    color: theme.palette['grey'][600],
    fontSize: '14px',
  },
}));

const SwitchModeContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ModeDataContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',

  '.header-first-col': {},
  '.header-second-col': {
    padding: '16px 0 8px 0',
    textAlign: 'end',
  },
  '.header-third-col': {
    padding: '16px 12px 8px 0',
    textAlign: 'end',
  },
  '.body-first-col': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 0 20px 12px',
  },
  '.body-second-col': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '16px 12px 8px 0',
    textAlign: 'end',
  },
  '.body-third-col': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '16px 12px 8px 0',
    textAlign: 'end',
  },
}));

const CompareEntryItemWrapperStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px',
}));

const DetailedModeData = ({ t, data, type }: ModeDataProps) => {
  return (
    <ModeDataContainerStyled>
      <Grid container columns={4}>
        {/* header */}
        <>
          <Grid item xs={2}></Grid>
          <Grid item xs={1}>
            <Box className="header-second-col">
              <Typography variant="note" color="bluegrey.500">
                {t('view.detailedModeData.time')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className="header-third-col">
              <Typography variant="note" color="bluegrey.500">
                {t('view.detailedModeData.user')}
              </Typography>
            </Box>
          </Grid>
        </>
        <DetailedModeDataItem
          title={t('view.drawer.sent.title')}
          tooltipText={t('view.drawer.sent.tooltip')}
          firstData={
            data?.sent || data?.sent === 0 ? convertNumberToK(data?.sent) : '-'
          }
          originFirstData={data?.sent}
          secondData={
            data?.uniqSent || data?.uniqSent === 0
              ? convertNumberToK(data?.uniqSent)
              : '-'
          }
          originSecondData={data?.uniqSent}
          isShowTooltip={data && isNumber(data?.sent) && data?.sent > 9999}
        />
        <DetailedModeDataItem
          title={
            type === 8
              ? t('view.drawer.commentTitles.enterContacts')
              : t('view.drawer.enter.title')
          }
          tooltipText={t('view.drawer.enter.tooltip')}
          firstData={
            data?.enter || data?.enter === 0
              ? convertNumberToK(data?.enter)
              : '-'
          }
          firstDataP={data?.enterP}
          secondData={
            data?.uniqEnter || data?.uniqEnter === 0
              ? convertNumberToK(data?.uniqEnter)
              : '-'
          }
          secondDataP={data?.uniqEnterP}
          isShowTooltip={data && isNumber(data?.enter) && data?.enter > 9999}
          originFirstData={data?.enter}
          originSecondData={data?.uniqEnter}
        />
        <DetailedModeDataItem
          title={
            type === 8
              ? t('view.drawer.commentTitles.interactedContacts')
              : t('view.drawer.interacted.title')
          }
          tooltipText={t('view.drawer.interacted.tooltip')}
          firstData={
            data?.interacted || data?.interacted === 0
              ? convertNumberToK(data?.interacted)
              : '-'
          }
          firstDataP={data?.interactedP}
          secondData={
            data?.uniqInteracted || data?.uniqInteracted === 0
              ? convertNumberToK(data?.uniqInteracted)
              : '-'
          }
          secondDataP={data?.uniqInteractedP}
          isShowTooltip={
            data && isNumber(data?.interacted) && data?.interacted > 9999
          }
          originFirstData={data?.interacted}
          originSecondData={data?.uniqInteracted}
        />

        {type === 1 && (
          <>
            <Divider sx={{ width: '100%' }} />
            <DetailedModeDataItem
              title={t('view.drawer.newRnContacts.title')}
              tooltipText={t('view.drawer.newRnContacts.tooltip')}
              firstData="-"
              secondData={
                data?.uniqNewRnContacts || data?.uniqNewRnContacts === 0
                  ? convertNumberToK(data?.uniqNewRnContacts)
                  : '-'
              }
            />
          </>
        )}
        {type === 4 && (
          <>
            <Divider sx={{ width: '100%' }} />
            <DetailedModeDataItem
              title={t('view.drawer.generatedLink.title')}
              tooltipText={t('view.drawer.generatedLink.tooltip')}
              firstData="-"
              secondData={
                data?.uniqGeneratedLinks || data?.uniqGeneratedLinks === 0
                  ? convertNumberToK(data?.uniqGeneratedLinks)
                  : '-'
              }
            />
            <DetailedModeDataItem
              title={t('view.drawer.newInvited.title')}
              tooltipText={t('view.drawer.newInvited.tooltip')}
              firstData="-"
              secondData={
                data?.uniqNewInvitee || data?.uniqNewInvitee === 0
                  ? convertNumberToK(data?.uniqNewInvitee)
                  : '-'
              }
            />
          </>
        )}
        {type === 8 && (
          <>
            <Divider sx={{ width: '100%' }} />
            <DetailedModeDataItem
              title="Comments"
              tooltipText=""
              firstData="-"
              secondData={
                data?.uniqComments || data?.uniqComments === 0
                  ? convertNumberToK(data?.uniqComments)
                  : '-'
              }
            />
          </>
        )}
      </Grid>
    </ModeDataContainerStyled>
  );
};

const DetailedModeDataItem = ({
  title,
  tooltipText,
  firstData,
  firstDataP,
  secondData,
  secondDataP,
  isShowTooltip = false,
  originFirstData,
  originSecondData,
}: ModeDataItemProps) => {
  return (
    <DetailedModeDataItemStyled>
      <Grid item xs={2}>
        <Box className="body-first-col">
          <ModeDataTitleWrapperStyled>
            <Typography variant="body3" color="bluegrey.500">
              {title}
            </Typography>
          </ModeDataTitleWrapperStyled>

          <TooltipIconWrapperStyled>
            <Tooltip placement="top" title={tooltipText}>
              <ErrorOutlineOutlinedIcon fontSize="tiny" />
            </Tooltip>
          </TooltipIconWrapperStyled>
        </Box>
      </Grid>
      {isShowTooltip ? (
        <>
          <Tooltip
            placement="bottom-end"
            title={
              <div>
                {title}: {originFirstData}
                <br />
                {title !== 'Sent' &&
                  `${title.split(' ')[0]} rate: ${firstDataP}%`}
              </div>
            }
          >
            <Grid item xs={1}>
              <Box className="body-second-col">
                <Typography variant="subtitle2" color="grey.600">
                  {firstData}
                </Typography>
                {firstDataP && (
                  <PercentTagStyled>
                    <Typography variant="body3" color="bluegrey.700">
                      {firstDataP} %
                    </Typography>
                  </PercentTagStyled>
                )}
              </Box>
            </Grid>
          </Tooltip>
          <Tooltip
            placement="bottom-end"
            title={
              <div>
                {title}: {originSecondData}
                <br />
                {title !== 'Sent' &&
                  `${title.split(' ')[0]} rate: ${secondDataP}%`}
              </div>
            }
          >
            <Grid item xs={1}>
              <Box className="body-third-col">
                <Typography variant="subtitle2" color="primary">
                  {secondData}
                </Typography>
                {secondDataP && (
                  <PercentTagStyled>
                    <Typography variant="body3" color="bluegrey.700">
                      {secondDataP} %
                    </Typography>
                  </PercentTagStyled>
                )}
              </Box>
            </Grid>
          </Tooltip>
        </>
      ) : (
        <>
          <Grid item xs={1}>
            <Box className="body-second-col">
              <Typography variant="subtitle2" color="grey.600">
                {firstData}
              </Typography>
              {firstDataP && (
                <PercentTagStyled>
                  <Typography variant="body3" color="bluegrey.700">
                    {firstDataP} %
                  </Typography>
                </PercentTagStyled>
              )}
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box className="body-third-col">
              <Typography variant="subtitle2" color="primary">
                {secondData}
              </Typography>
              {secondDataP && (
                <PercentTagStyled>
                  <Typography variant="body3" color="bluegrey.700">
                    {secondDataP} %
                  </Typography>
                </PercentTagStyled>
              )}
            </Box>
          </Grid>
        </>
      )}
    </DetailedModeDataItemStyled>
  );
};

export const DrawerViewTrigger = () => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const uiState = useContext(UICtx);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    entryDataType,
    viewFlowEntryType,
    viewFlowEntryID,
    setViewFlowEntryID,
    setViewFlowEntryType,
    setEntryDataType,
  } = uiState;
  // 從網址抓 ?overAll 後面的值當作選單的預設值
  const overAll = searchParams.get('overAll');

  const [compareEntryData, setCompareEntryData] =
    useState<PostDataFlowEntryAllResponse>();

  const {
    systemEntryPoint,
    broadcastEntryPoint,
    flowEntryPoint,
    redirectFlowEntryPoint,
    sequenceEntryPoint,
    commentAutoReplyEntryPoint,
  } = useCanvasCollect();
  const { focusEntryPoint } = useCanvasView();

  const { data: entries } = useGetEditorFlowEntry(
    1,
    id as string,
    projectID as string,
    true,
  );

  const { data: entryData } = useGetViewFlowEntry(
    projectID as string,
    viewFlowEntryType,
    id ? id : '',
    viewFlowEntryID,
  );
  const [tabValue, setTabValue] = useState<TabTypesEnum>(TabTypesEnum.OVERALL);
  const [isDetailedMode, setIsDetailedMode] = useState(
    localStorage.getItem('isDetailedMode') === 'true' ?? false,
  );

  const { mutate: getCompareEntryData } = usePostDataFlowEntryAll({
    flowID: id as string,
    projectID: projectID as string,
  });

  const formatEntries = useMemo(() => {
    const _entries: {
      entryID: string;
      type: number;
    }[] = [];
    if (entries) {
      if (entries.entries) {
        entries.entries.forEach((item) => {
          let _entryType = 2; // basic setting
          if (item.type === EntryPointsEnum.BOT_LINK) {
            _entryType = 3; // bot-link
          }
          _entries.push({
            entryID: item.id,
            type: _entryType,
          });
        });
      }
      if (systemEntryPoint) {
        systemEntryPoint.forEach((item) => {
          _entries.push({
            entryID: item.id,
            type: 4,
          });
        });
      }
      if (broadcastEntryPoint) {
        entries.broadcasts.forEach((item) => {
          _entries.push({
            entryID: item.id,
            type: 5,
          });
        });
      }
      if (redirectFlowEntryPoint) {
        entries.redirectFlows.forEach((item) => {
          _entries.push({
            entryID: item.id,
            type: 6,
          });
        });
      }
      if (sequenceEntryPoint) {
        entries.sequences.forEach((item) => {
          _entries.push({
            entryID: item.id,
            type: 7,
          });
        });
      }
      if (commentAutoReplyEntryPoint) {
        entries.commentReplies.forEach((item) => {
          _entries.push({
            entryID: item.id,
            type: 8,
          });
        });
      }
    }

    return _entries;
  }, [
    broadcastEntryPoint,
    entries,
    redirectFlowEntryPoint,
    sequenceEntryPoint,
    systemEntryPoint,
    commentAutoReplyEntryPoint,
  ]);

  const setViewFlowEntryProps = useCallback(
    ({ entryID, type }: { entryID?: string; type: number }) => {
      setViewFlowEntryID(entryID || '');
      setViewFlowEntryType(type);
    },
    [setViewFlowEntryID, setViewFlowEntryType],
  );

  const handleCompareEntryClick = useCallback(() => {
    focusEntryPoint();
    getCompareEntryData(formatEntries, {
      onSuccess: (_data: PostDataFlowEntryAllResponse) => {
        setCompareEntryData(_data);
      },
    });
  }, [focusEntryPoint, formatEntries, getCompareEntryData]);

  const handleSwitch = useCallback((checked: boolean) => {
    setIsDetailedMode(checked);
    localStorage.setItem('isDetailedMode', checked.toString());
    if (checked) {
      sendGAEvent(
        'Chat Flow View',
        'switch off to on',
        'Chat Flow View - Drawer - Data - switch off to on- click',
        '',
      );
    } else {
      sendGAEvent(
        'Chat Flow View',
        'switch on to off',
        'Chat Flow View - Drawer - Data - switch on to off - click',
        '',
      );
    }
  }, []);

  const handleEntryDataTypeChange = useCallback(
    (val: React.ChangeEvent<HTMLInputElement>) => {
      // 確保比 click away 晚觸發，在 node-entry-point 有 click away listener 會在觸發後將 entryDataType 設為 ALL
      setTimeout(() => {
        setEntryDataType(val.target.value);
      }, 0);
      sendGAEvent(
        'Chat Flow View',
        'entry point dropdown',
        'Chat Flow View - Drawer - Data - entry point dropdown - click',
        '',
      );
    },
    [setEntryDataType],
  );

  const handleTabChange = useCallback(
    (_value: number) => {
      setTabValue(_value);
      setEntryDataType('ALL');
    },
    [setEntryDataType],
  );

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    switch (entryDataType) {
      case 'ALL': {
        setViewFlowEntryProps({ type: 1 });
        break;
      }
      default: {
        const targetEntry = entries?.entries.find(
          (i: FlowEntryPoint) => i.id === entryDataType,
        );

        if (targetEntry) {
          switch (targetEntry.type) {
            case EntryPointsEnum.BOT_LINK: {
              setViewFlowEntryProps({ type: 3, entryID: entryDataType });
              break;
            }
            // basic setting
            default: {
              setViewFlowEntryProps({ type: 2, entryID: entryDataType });
              break;
            }
          }
        } else if (
          systemEntryPoint.find((i) => i.shareLinkID === entryDataType)
        ) {
          setViewFlowEntryProps({ type: 4, entryID: entryDataType });
        } else if (entries?.broadcasts.find((i) => i.id === entryDataType)) {
          setViewFlowEntryProps({ type: 5, entryID: entryDataType });
        } else if (redirectFlowEntryPoint.find((i) => i.id === entryDataType)) {
          setViewFlowEntryProps({ type: 6, entryID: entryDataType });
        } else if (sequenceEntryPoint.find((i) => i.id === entryDataType)) {
          setViewFlowEntryProps({ type: 7, entryID: entryDataType });
        } else if (
          commentAutoReplyEntryPoint.find((i) => i.id === entryDataType)
        ) {
          setViewFlowEntryProps({ type: 8, entryID: entryDataType });
        }

        break;
      }
    }
  }, [
    entries,
    entryDataType,
    entries?.entries,
    id,
    systemEntryPoint,
    entries?.broadcasts,
    redirectFlowEntryPoint,
    sequenceEntryPoint,
    setViewFlowEntryProps,
    commentAutoReplyEntryPoint,
  ]);

  const haveEntry =
    systemEntryPoint.length +
      broadcastEntryPoint.length +
      redirectFlowEntryPoint.length +
      sequenceEntryPoint.length +
      commentAutoReplyEntryPoint.length >
    0;

  useEffect(() => {
    if (!isLoaded) {
      if (overAll) {
        setEntryDataType(overAll);
      } else {
        setEntryDataType('ALL');
      }
      setIsLoaded(true);
    }
  }, [isLoaded, overAll, setEntryDataType]);

  return (
    <EditorDrawer autoClose={false} drawerType={DrawerTypesEnum.VIEW_TRIGGER}>
      <DrawerDataWrapperStyled>
        <TopContainerStyled>
          <Typography variant="h6" color="grey.900">
            {t('view.drawer.title')}
          </Typography>
          <Tooltip
            placement="top"
            title={
              <Typography variant="note">
                {t('view.drawer.titleTooltip')}
                <Typography
                  variant="note"
                  component="u"
                  color="infoOnTooltips.main"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    window.open(
                      i18next.language === 'en_us'
                        ? VIEW_TRIGGER_DOCUMENT_EN
                        : VIEW_TRIGGER_DOCUMENT,
                    )
                  }
                >
                  {t('view.readMore')}
                </Typography>
              </Typography>
            }
          >
            <InfoIcon fontSize="tiny" />
          </Tooltip>
        </TopContainerStyled>

        <Tabs
          sx={{
            mb: '12px',
          }}
          value={tabValue}
          onChange={(_, _value) => handleTabChange(_value)}
        >
          <Tab
            label={t('view.drawer.tab.overall')}
            {...a11yProps(TabTypesEnum.OVERALL)}
          />
          <Tab
            onClick={handleCompareEntryClick}
            label={t('view.drawer.tab.compare')}
            {...a11yProps(TabTypesEnum.COMPARE_ENTRIES)}
            id="compare_entries"
          />
        </Tabs>

        {tabValue === TabTypesEnum.OVERALL && (
          <>
            {entryDataType === 'ALL' && (
              <TabInfoWrapperStyled>
                <Typography variant="body2" color="grey.600">
                  {t('view.drawer.tabInfo')}
                </Typography>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant="note">
                      {t('view.drawer.tabInfoTooltip')}
                    </Typography>
                  }
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </TabInfoWrapperStyled>
            )}
            <Select
              size="small"
              fullWidth
              value={entryDataType}
              sx={{
                marginBottom: '10px',
              }}
              onChange={handleEntryDataTypeChange}
            >
              <SelectItem value="ALL">
                {t('view.drawer.selects.all')}
              </SelectItem>
              {entries?.broadcasts?.map((i) => (
                <SelectItem key={`trigger_data_broadcast_${i.id}`} value={i.id}>
                  {i.name}
                </SelectItem>
              ))}
              {redirectFlowEntryPoint.map((i) => (
                <SelectItem key={`trigger_data_flow_${i.id}`} value={i.id}>
                  <Trans i18nKey="view.drawer.redirectFrom" />
                  {i.name}
                </SelectItem>
              ))}
              {sequenceEntryPoint.map((i) => (
                <SelectItem key={`trigger_data_sequence_${i.id}`} value={i.id}>
                  <Trans i18nKey="view.drawer.redirectFrom" />
                  {i.name}
                </SelectItem>
              ))}
              {commentAutoReplyEntryPoint.map((i) => (
                <SelectItem
                  key={`trigger_data_comment_auto_reply_${i.id}`}
                  value={i.id}
                >
                  <Trans i18nKey="view.drawer.redirectFrom" />
                  {i.name}
                </SelectItem>
              ))}

              {haveEntry &&
                (systemEntryPoint.length > 0 ||
                  (entries?.entries && entries?.entries.length > 0)) && (
                  <GroupDivider />
                )}

              {entries?.entries &&
                entries.entries.map((i) => {
                  switch (i.type) {
                    case EntryPointsEnum.WELCOME_MESSAGE: {
                      return (
                        <SelectItem key={i.id} value={i.id}>
                          <Trans i18nKey="basicSetting.welcomeMessage.title" />
                        </SelectItem>
                      );
                    }
                    case EntryPointsEnum.DEFAULT_ANSWER: {
                      return (
                        <SelectItem key={i.id} value={i.id}>
                          <Trans i18nKey="basicSetting.defaultAnswer.title" />
                        </SelectItem>
                      );
                    }
                    case EntryPointsEnum.PERSISTENT_MENU: {
                      return (
                        <SelectItem key={i.id} value={i.id}>
                          <Trans i18nKey="basicSetting.persistentMenu.title" />
                        </SelectItem>
                      );
                    }
                    // TODO: bot link label
                    case EntryPointsEnum.BOT_LINK: {
                      return (
                        <SelectItem key={i.id} value={i.id}>
                          {i.title}
                        </SelectItem>
                      );
                    }
                    default:
                      return null;
                  }
                })}
              {systemEntryPoint.map((i) => {
                return (
                  <SelectItem
                    key={`trigger_data_share_link_${i.shareLinkID}`}
                    value={i.shareLinkID}
                  >
                    {i.label}
                  </SelectItem>
                );
              })}
            </Select>
            <SwitchModeContainerStyled>
              <Typography color="grey.600" variant="note">
                {isDetailedMode
                  ? t('view.drawer.switchOffText')
                  : t('view.drawer.switchOnText')}
              </Typography>
              <Switch
                size="small"
                onChange={(_, checked) => {
                  handleSwitch(checked);
                }}
                checked={isDetailedMode}
              />
            </SwitchModeContainerStyled>
            {isDetailedMode ? (
              <DetailedModeData
                t={t}
                data={entryData}
                type={viewFlowEntryType}
              />
            ) : (
              <>
                <ViewDrawerBox
                  title={t('view.drawer.sent.title')}
                  times={entryData?.uniqSent}
                  tooltip={t('view.drawer.sent.tooltip')}
                />
                <ViewDrawerBox
                  title={
                    viewFlowEntryType === 8
                      ? t('view.drawer.commentTitles.enterContacts')
                      : t('view.drawer.interacted.title')
                  }
                  times={entryData?.uniqEnter}
                  tooltip={t('view.drawer.enter.tooltip')}
                  percent={entryData?.uniqEnterP}
                />
                <ViewDrawerBox
                  title={
                    viewFlowEntryType === 8
                      ? t('view.drawer.commentTitles.interactedContacts')
                      : t('view.drawer.interacted.title')
                  }
                  times={entryData?.uniqInteracted}
                  tooltip={t('view.drawer.interacted.tooltip')}
                  percent={entryData?.uniqInteractedP}
                />
                {viewFlowEntryType === 1 && (
                  <ViewDrawerBox
                    title={t('view.drawer.newRnContacts.title')}
                    times={entryData?.uniqNewRnContacts}
                    tooltip={t('view.drawer.newRnContacts.tooltip')}
                  />
                )}
                {viewFlowEntryType === 4 && (
                  <>
                    <Divider />
                    <ViewDrawerBox
                      title={t('view.drawer.generatedLink.title')}
                      times={entryData?.uniqGeneratedLinks}
                      tooltip={t('view.drawer.generatedLink.tooltip')}
                    />
                    <ViewDrawerBox
                      title={t('view.drawer.newInvited.title')}
                      times={entryData?.uniqNewInvitee}
                      tooltip={t('view.drawer.newInvited.tooltip')}
                    />
                  </>
                )}
                {viewFlowEntryType === 8 && (
                  <>
                    <Divider />
                    <ViewDrawerBox
                      title={t('view.drawer.commentTitles.comments')}
                      times={entryData?.comments}
                      tooltip={t('view.drawer.commentTitles.commentTooltip')}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        {tabValue === TabTypesEnum.COMPARE_ENTRIES && (
          <>
            <Typography
              sx={{
                mt: '16px',
              }}
              variant="notoSans"
              color="grey.600"
            >
              <Trans i18nKey="view.drawer.compareEntry.info" />
            </Typography>
            <Typography
              sx={{
                mb: '16px',
                whiteSpace: 'break-spaces',
              }}
              variant="notoSans"
              color="grey.600"
            >
              <Trans i18nKey="view.drawer.compareEntry.info2" />
            </Typography>

            {haveEntry && (
              <CompareEntryItemWrapperStyled>
                {systemEntryPoint.map((i: EntryPointShareLink) => {
                  const _data = get(
                    compareEntryData,
                    `shareLink.${i.id}`,
                    emptyCompareEntryData,
                  ) as DataFlowEntryAllData;
                  return (
                    <CompareEntryItem
                      key={`shareLink_${i.id}`}
                      title={i.label}
                      sent={_data.uniqSent}
                      cvr={_data.uniqCvr}
                      icon={
                        <CallSplitIcon
                          sx={{
                            color: get(globalTheme, 'palette.grass.600'),
                          }}
                        />
                      }
                    />
                  );
                })}

                {broadcastEntryPoint.map((i: EntryPointBroadcast) => {
                  const _data = get(
                    compareEntryData,
                    `broadcast.${i.id}`,
                    emptyCompareEntryData,
                  ) as DataFlowEntryAllData;

                  return (
                    <CompareEntryItem
                      key={`broadcast_${i.id}`}
                      title={i.name}
                      sent={_data.uniqSent}
                      cvr={_data.uniqCvr}
                      icon={
                        <CallMergeIcon
                          sx={{
                            color: get(globalTheme, 'palette.grass.600'),
                          }}
                        />
                      }
                    />
                  );
                })}

                {redirectFlowEntryPoint.map((i: EntryPointFlow) => {
                  const _data = get(
                    compareEntryData,
                    `redirectFlow.${i.id}`,
                    emptyCompareEntryData,
                  ) as DataFlowEntryAllData;
                  return (
                    <CompareEntryItem
                      key={`redirectFlow_${i.id}`}
                      title={t('canvas.entryPoint.system.redirectFlow')}
                      sent={_data.uniqSent}
                      cvr={_data.uniqCvr}
                      icon={
                        <CallMergeIcon
                          sx={{
                            color: get(globalTheme, 'palette.grass.600'),
                          }}
                        />
                      }
                    />
                  );
                })}
                {sequenceEntryPoint.map((i: EntryPointSequence) => {
                  const _data = get(
                    compareEntryData,
                    `sequence.${i.id}`,
                    emptyCompareEntryData,
                  ) as DataFlowEntryAllData;
                  return (
                    <CompareEntryItem
                      key={`sequence_${i.id}`}
                      title={t('canvas.entryPoint.system.sequence')}
                      sent={_data.uniqSent}
                      cvr={_data.uniqCvr}
                      icon={
                        <CallMergeIcon
                          sx={{
                            color: get(globalTheme, 'palette.grass.600'),
                          }}
                        />
                      }
                    />
                  );
                })}
                {commentAutoReplyEntryPoint.map(
                  (i: EntryPointCommentAutoReply) => {
                    const _data = get(
                      compareEntryData,
                      `commentReply.${i.id}`,
                      emptyCompareEntryData,
                    ) as DataFlowEntryAllData;
                    return (
                      <CompareEntryItem
                        key={`commentReply_${i.id}`}
                        title={t('canvas.entryPoint.system.commentReply')}
                        sent={_data.uniqSent}
                        cvr={_data.uniqCvr}
                        icon={
                          <CallMergeIcon
                            sx={{
                              color: get(globalTheme, 'palette.grass.600'),
                            }}
                          />
                        }
                      />
                    );
                  },
                )}
              </CompareEntryItemWrapperStyled>
            )}
            {flowEntryPoint.length > 0 && (
              <CompareEntryItemWrapperStyled>
                {flowEntryPoint.map((i: FlowEntryPoint) => {
                  switch (i.type) {
                    case EntryPointsEnum.BOT_LINK: {
                      const _data = get(
                        compareEntryData,
                        `botlink.${i.id}`,
                        emptyCompareEntryData,
                      ) as DataFlowEntryAllData;

                      return (
                        <CompareEntryItem
                          key={`botlink_${i.id}`}
                          title={(i as EntryPointBotLink).title}
                          sent={_data.uniqSent}
                          cvr={_data.uniqCvr}
                          icon={<LinkIcon color="success" />}
                          enable={i.enable}
                        />
                      );
                    }
                    case EntryPointsEnum.WELCOME_MESSAGE: {
                      const _data = get(
                        compareEntryData,
                        `basicSetting.${i.id}`,
                        emptyCompareEntryData,
                      ) as DataFlowEntryAllData;

                      return (
                        <CompareEntryItem
                          key={`welcomeMessage_${i.id}`}
                          title={t(
                            'canvas.entryPoint.addMenu.welcomeMessage.title',
                          )}
                          sent={_data.uniqSent}
                          cvr={_data.uniqCvr}
                          icon={<MessengerIcon />}
                          enable={i.enable}
                        />
                      );
                    }
                    case EntryPointsEnum.DEFAULT_ANSWER: {
                      const _data = get(
                        compareEntryData,
                        `basicSetting.${i.id}`,
                        emptyCompareEntryData,
                      ) as DataFlowEntryAllData;

                      return (
                        <CompareEntryItem
                          key={`defaultAnswer_${i.id}`}
                          title={t(
                            'canvas.entryPoint.addMenu.defaultAnswer.title',
                          )}
                          sent={_data.uniqSent}
                          cvr={_data.uniqCvr}
                          icon={<MessengerIcon />}
                          enable={i.enable}
                        />
                      );
                    }
                    case EntryPointsEnum.PERSISTENT_MENU: {
                      const _data = get(
                        compareEntryData,
                        `basicSetting.${i.id}`,
                        emptyCompareEntryData,
                      ) as DataFlowEntryAllData;

                      return (
                        <CompareEntryItem
                          key={`persistentMenu_${i.id}`}
                          title={t(
                            'canvas.entryPoint.addMenu.persistentMenu.title',
                          )}
                          sent={_data.uniqSent}
                          cvr={_data.uniqCvr}
                          icon={<MessengerIcon />}
                          enable={i.enable}
                        />
                      );
                    }
                    default:
                      return null;
                  }
                })}
              </CompareEntryItemWrapperStyled>
            )}
          </>
        )}
      </DrawerDataWrapperStyled>
    </EditorDrawer>
  );
};

export default memo(DrawerViewTrigger);
