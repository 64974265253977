import { Button, Divider, Tooltip } from '@frontend/components/ui';
import { FacebookPage } from '@frontend/sorghum/interface';
import {
  FACEBOOK_FAN_PAGE_READ_MORE_EN,
  FACEBOOK_FAN_PAGE_READ_MORE_ZH,
  FACEBOOK_PAGE_CREATE,
} from '@frontend/sorghum/utils';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { IconButton, Link, Modal, styled } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Box, Typography } from '../../index';

export interface FacebookPagesModalProps {
  open: boolean;
  pages: FacebookPage[] | undefined | null;
  isConnectLoading: boolean;
  refresh: () => void;
  handleClose: () => void;
  connect: (targetID: string) => void;
}

const ModalStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: theme.palette['grey']['white'],
  padding: '20px 24px',
  width: '550px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  border: '4px',
}));

const TitleWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
}));

const ButtonWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const FacebookPagesModal: FC<FacebookPagesModalProps> = ({
  open,
  pages,
  isConnectLoading,
  handleClose,
  connect,
  refresh,
}) => {
  const [t, i18next] = useTranslation();
  const [activeID, setActiveID] = useState<string>('');

  const handleButtonClick = useCallback(
    (pageID: string) => {
      setActiveID(pageID);
      connect(pageID);
    },
    [connect],
  );

  const readMoreUrl =
    i18next.language === 'en_us'
      ? FACEBOOK_FAN_PAGE_READ_MORE_EN
      : FACEBOOK_FAN_PAGE_READ_MORE_ZH;

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalStyled>
        <TitleWrapperStyled>
          <Typography variant="h6" color="grey.black">
            {t('pages.title')}
          </Typography>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </TitleWrapperStyled>
        <Typography variant="body1" color="grey.800">
          {t('pages.info')}
          &nbsp;
          <Link onClick={() => window.open(readMoreUrl, '_blank')}>
            {t('pages.readMore')}
          </Link>
        </Typography>
        <Divider />
        <Box height="192px" overflow="auto">
          <Box p="16px">
            {pages &&
              pages.map((page: FacebookPage) => {
                const isConnected = page.isLinked;
                return (
                  <Box
                    key={`pages_page_${page.pageId}`}
                    p="20px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box flex="1">
                      <Avatar alt={page.pageId} src={page.picture} />
                    </Box>
                    <Box flex="2">
                      <Typography
                        variant="body2"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {page.pageName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ textDecoration: 'underline' }}
                      >
                        {page.projectName}
                      </Typography>
                    </Box>
                    <Tooltip
                      disabled={!isConnected}
                      placement="bottom-start"
                      title={<Box>{t('pages.isConnected')}</Box>}
                    >
                      <div
                        style={{
                          ...(isConnected && {
                            cursor: 'not-allowed',
                          }),
                        }}
                      >
                        {/* 使用 box 會導致 tooltip 失效，原因不明 */}
                        <Button
                          loading={activeID === page.pageId && isConnectLoading}
                          loadingColor="white"
                          variant="contained"
                          color="primary"
                          disabled={isConnected || isConnectLoading}
                          onClick={() => {
                            handleButtonClick(page.pageId);
                          }}
                        >
                          <Trans i18nKey="pages.connect" />
                        </Button>
                      </div>
                    </Tooltip>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <ButtonWrapperStyled>
          <Button
            onClick={refresh}
            startIcon={<ReplayIcon />}
            variant="outlined"
            color="bluegrey500"
          >
            {t('pages.refresh')}
          </Button>
          <Button
            color="primary"
            variant="text"
            onClick={() => window.open(FACEBOOK_PAGE_CREATE)}
            size="medium"
          >
            {t('pages.create')}
          </Button>
        </ButtonWrapperStyled>
      </ModalStyled>
    </Modal>
  );
};

export default FacebookPagesModal;
